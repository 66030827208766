import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Box, Button, Flex } from '@chakra-ui/react';

import { PopupConstructor } from '@app/pages/PopupConstructor/types';
import ImgWithPlaceholder from '@app/pages/PopupConstructor/content/ImgWithPlaceholder';
import Player from '@app/components/10Dlc/Player';
import { isValidUrl } from '@app/utils/isUrl';
import { hasButton, hasImg, hasVideo } from '@app/pages/PopupConstructor/utils';

const DEFAULT_BTN_TEXT = 'There is a limit of 30 symbols';

export const PreviewBody: FC = () => {
  const {
    values: { description, template },
  } = useFormikContext<PopupConstructor>();

  const { imageSrc, videoSrc, buttonText, type } = template;

  const img =
    !!imageSrc || !videoSrc ? <ImgWithPlaceholder image={imageSrc} /> : false;

  const autoPlay = isValidUrl(videoSrc) ? false : !!img;
  const videoType = isValidUrl(videoSrc) ? 'iframe' : 'youtube';

  return (
    <>
      {hasVideo(type) && (
        <Box height="340px" position="relative">
          <Player
            key={videoSrc + imageSrc}
            autoplay={autoPlay}
            height="340px"
            image={img}
            type={videoType}
            video={videoSrc}
            width="100%"
          />
        </Box>
      )}

      {hasImg(type) && (
        <Box height="340px">
          <ImgWithPlaceholder image={imageSrc} />
        </Box>
      )}

      {description && (
        <Box
          dangerouslySetInnerHTML={{
            __html: description,
          }}
          mt="20px"
        />
      )}
      {hasButton(type) && (
        <Flex justify="center" pt="20px">
          <Button
            color={buttonText ? 'white' : 'secondary.500'}
            isDisabled={!buttonText}
            variant="primary"
            w="265px">
            {!!buttonText ? buttonText : DEFAULT_BTN_TEXT}
          </Button>
        </Flex>
      )}
    </>
  );
};
