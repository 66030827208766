import Bootstrap from '@app/types/Bootstrap';
import ROUTES, { PUBLIC_ROUTES } from '@app/utils/routes';
import { TAuthAction, TQueryActionParam } from '@app/types/AuthAction';
import { TStdBooleanValue } from '@app/types/QueryParam';
import { isAccountAbandoned } from '@app/utils/accountUtils';

enum SearchParams {
  OAUTH = 'code',
  ResetPassword = 'resetPassword',
  verifyEmail = 'verifyEmail',
}

export const getInitialRouteAction = (): Record<string, string> => {
  const { search } = location;
  const params = new URLSearchParams(search);
  const actions: Record<string, string> = {};
  const softPasswordReset = params.get(
    TQueryActionParam.enum.softPasswordReset,
  );
  const resetPassword = params.get(TAuthAction.enum.resetPassword);

  if (softPasswordReset || resetPassword) {
    actions[TAuthAction.enum.resetPassword] = TStdBooleanValue.enum.true;
  }

  return actions;
};

export const getInitialRoute = ({
  account,
  registered,
  phoneVerified,
  emailVerified,
  isSocialSignUp,
}: Bootstrap): string | undefined => {
  const { pathname, search } = location;
  const params = new URLSearchParams(search);
  const responseTypeParam = params.get('response_type') as SearchParams;
  const modeParam = params.get('mode') as SearchParams;

  // Redirect user to OAUTH
  if (responseTypeParam === SearchParams.OAUTH) {
    return registered
      ? `${ROUTES.oauth}/${search}`
      : `${ROUTES.login}/${search}`;
  }

  // Redirect user to change password
  if (modeParam === SearchParams.ResetPassword) {
    return `${ROUTES.changePassword}/${search}`;
  }

  // Skip public page
  if (!registered && PUBLIC_ROUTES.includes(pathname)) {
    return pathname + search;
  }

  if (!registered) {
    const actions = getInitialRouteAction();
    const queryString = new URLSearchParams(actions).toString();

    return `${ROUTES.login}?${queryString}`;
  }

  if (registered && !(phoneVerified && emailVerified)) {
    return isSocialSignUp ? ROUTES.securityPhoneVerification : ROUTES.signUp;
  }

  // Redirect user to questionnaire if it's not filled
  if (registered && account && isAccountAbandoned(account)) {
    return ROUTES.questionnaire;
  }

  // Redirect from questionnaire to messenger
  if (pathname === ROUTES.questionnaire) {
    return ROUTES.messenger;
  }

  // Set redirect after login
  if (
    [ROUTES.login, ROUTES.signUp, '/'].includes(pathname) &&
    registered &&
    account
  ) {
    if (params.get(TAuthAction.enum.resetPassword)) {
      const queryParams = new URLSearchParams({
        [TQueryActionParam.enum.softPasswordReset]: TStdBooleanValue.enum.true,
      }).toString();

      return `${ROUTES.settingsProfile}?${queryParams}`;
    }
    if (!emailVerified) {
      return ROUTES.signUp;
    }

    return ROUTES.messenger;
  }

  // Redirect user to messenger after verify email
  if (modeParam === SearchParams.verifyEmail && registered && account) {
    return ROUTES.messenger;
  }
};
