import React, { FC, useMemo } from 'react';
import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import {
  components,
  MenuProps,
  OptionProps,
  SingleValueProps,
} from 'react-select';
import { useFormikContext } from 'formik';

import SelectField from '@app/components/SelectField';

import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import { CUSTOM_THRESHOLD } from '@app/constants/configuration';
import { rechargeOptions } from './data';
import { RechargeOptionsType } from './types';
import { BonusLabel } from './BonusLabel';

interface ChargeSelectProps {
  fieldName?: string;
  isDisabled?: boolean;
  defaultValue?: RechargeOptionsType;
  isShowBonusLabel?: boolean;
}

const SelectLabel = <T extends RechargeOptionsType, M extends boolean = false>(
  props: SingleValueProps<T, M>,
) => {
  return (
    <>
      {props.data.label}
      {props?.selectProps?.isShowBonusLabel && props?.data?.bonusLabel && (
        <BonusLabel
          backgroundColor={props.data?.bonusBg}
          color={props.data?.color}
          label={props.data.bonusLabel}
        />
      )}
    </>
  );
};

const Menu = (props: MenuProps<RechargeOptionsType, false>) => {
  const handleClick = () => {
    props.setValue({ value: 5, label: 'Custom Amount' }, 'select-option');
    props.selectProps?.onCustomFieldSelect();
  };

  return (
    <components.Menu {...props}>
      <>
        <Box>{props.children}</Box>
        <Flex
          borderTop="1px"
          borderTopColor="primary.200"
          justifyContent="center"
          margin="5px 0 5px"
          padding="5px 8px 0">
          <Button
            _hover={{ backgroundColor: 'primary.200' }}
            borderRadius="20px"
            color="pressedAzure"
            fontSize="14px"
            fontWeight={700}
            p="10px"
            variant="ghostPlain"
            width="100%"
            onClick={handleClick}
            onTouchEnd={handleClick}>
            Custom Amount
          </Button>
        </Flex>
      </>
    </components.Menu>
  );
};

export const SingleValue = <
  T extends RechargeOptionsType,
  M extends boolean = false,
>({
  ...props
}: SingleValueProps<T, M>) => {
  return (
    <components.SingleValue {...props}>
      <Flex alignItems="center">
        {props?.data?.value > 5 && (
          <Text color="main.100" mr="3px">
            $
          </Text>
        )}{' '}
        <SelectLabel {...props} />
      </Flex>
    </components.SingleValue>
  );
};

export const Option = <
  T extends RechargeOptionsType,
  M extends boolean = false,
>(
  props: OptionProps<T, M>,
) => {
  return (
    <components.Option {...props}>
      <Flex alignItems="center">
        $ <SelectLabel {...props} />
      </Flex>
    </components.Option>
  );
};

const ChargeSelect: FC<ChargeSelectProps> = ({
  fieldName = 'amount',
  isDisabled = false,
  defaultValue = null,
  isShowBonusLabel = false,
}) => {
  const { isMessagePlanTrialing } = useCurrentAccountData();

  const { setFieldValue } = useFormikContext();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const rechargeOptionsList = useMemo(() => {
    if (isMessagePlanTrialing) {
      return rechargeOptions.filter((option) => option.value <= 50);
    }

    return rechargeOptions.filter((option) => option.value >= CUSTOM_THRESHOLD);
  }, [isMessagePlanTrialing]);

  return (
    <Box mb="18px">
      <SelectField
        components={{ Option, Menu, SingleValue }}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isShowBonusLabel={isShowBonusLabel}
        menuIsOpen={isOpen}
        options={rechargeOptionsList}
        placeholder="Please select"
        onChange={async (val: RechargeOptionsType) => {
          await setFieldValue('custom', false);
          await setFieldValue(fieldName, val.value);
        }}
        onCustomFieldSelect={async () => {
          await setFieldValue('custom', true);
          await setFieldValue(fieldName, CUSTOM_THRESHOLD);
          onClose();
        }}
        onMenuClose={onClose}
        onMenuOpen={onOpen}
      />
    </Box>
  );
};

export default ChargeSelect;
