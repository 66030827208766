import { Enum } from '@app/utils/enum';

export const TThemeSize = new Enum(
  'sm',
  'md',
  'lg',
  'xs',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
  '6xl',
  '7xl',
  '8xl',
  '9xl',
  '10xl',
);
