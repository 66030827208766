import React, { FC, useCallback } from 'react';
import { Box, Text, Image, UseDisclosureProps } from '@chakra-ui/react';

import YoutubeIcon from '@app/assets/images/trial-youtube-icon.png';
import Popup from '@app/components/Popup';
import video from '@app/utils/videoLinks';

import { useUpdateAccountMutation } from '@app/api/gql/generated-types';
import { AccountSettingsPopup } from '@app/api/gql/graphql';
import Player from '../10Dlc/Player';

export const FirstDayTrialPopup: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const [updateAccount] = useUpdateAccountMutation();

  const onSubmitPopup = useCallback(async () => {
    await updateAccount({
      variables: {
        data: {
          viewedPopup: AccountSettingsPopup.popupTrialFirstDay,
        },
      },
    });
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="455px"
      title=""
      titlePadding="40px 56px 50px"
      onClose={onSubmitPopup}>
      <Box p="0 40px 40px">
        <Box>
          <Text
            color="main.400"
            fontSize="22px"
            fontWeight="500"
            m="40px auto 20px"
            textAlign="center">
            Congrats on starting your trial! 🎉
          </Text>
        </Box>
        <Box cursor="pointer" mb="23px" position="relative">
          <Player
            image={<Image minHeight="211px" src={YoutubeIcon} />}
            type="loom"
            video={video.trial.welcome}
          />
        </Box>
        <Box lineHeight="22px" m="10px auto" maxW="369px" textAlign="center">
          <Text as="span" fontSize="14px">
            To get you started we have a custom video for your industry by a
            certified PRO. We{' '}
          </Text>
          <Text
            as="span"
            fontSize="14px"
            fontWeight="600"
            textDecoration="underline">
            HIGHLY RECOMMEND IT.{' '}
          </Text>
          <Text as="span" fontSize="14px">
            30 minutes long and LOADED with the steps you`ll need to find
            success 💪
          </Text>
        </Box>
      </Box>
    </Popup>
  );
};
