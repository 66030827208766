import { useCallback, useMemo } from 'react';

import {
  FeatureType,
  OwnPhoneType,
  Product,
  Scope,
  useGetOwnPhoneCountersQuery,
} from '@app/api/gql/generated-types';
import { useDlcStatus } from '@app/hooks/useDlcStatus';
import { useTollFreeStatus } from '@app/pages/Settings/content/BrandRegistration/hooks/useTollFreeStatus';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';

export const useMaxMessagingAllowedPhones = () => {
  const { features } = useCurrentAccountData();
  const maxAllowedPhones = useMemo(
    () => ({
      [OwnPhoneType.LOCAL]: features.find(
        (f) =>
          f.type === FeatureType.LOCAL_PHONE && f.product === Product.MESSAGING,
      )?.limit,
      [OwnPhoneType.TOLL_FREE]: features.find(
        (f) =>
          f.type === FeatureType.TOLL_FREE_PHONE &&
          f.product === Product.MESSAGING,
      )?.limit,
    }),
    [features],
  );

  const { data: { getOwnPhoneCounters: phones } = {} } =
    useGetOwnPhoneCountersQuery({
      variables: {
        scope: Scope.MESSAGE,
      },
    });

  const { isApproved } = useDlcStatus();
  const { isPending: isPendingTollFree, isApproved: isApprovedTollFree } =
    useTollFreeStatus();
  const local = phones?.local ?? 0;
  const tollFree = phones?.tollFree ?? 0;

  const phoneType = useMemo(
    () => ({
      [OwnPhoneType.LOCAL]: local,
      [OwnPhoneType.TOLL_FREE]: tollFree,
    }),
    [local, tollFree],
  );

  const canBuyLocalNumber = useMemo(
    () =>
      isApproved && (!local || local < maxAllowedPhones[OwnPhoneType.LOCAL]),
    [isApproved, local, maxAllowedPhones],
  );

  const canBuyTollFreeNumber = useMemo(
    () =>
      (isPendingTollFree || isApprovedTollFree) &&
      (!tollFree || tollFree < maxAllowedPhones[OwnPhoneType.TOLL_FREE]),
    [isApprovedTollFree, isPendingTollFree, tollFree, maxAllowedPhones],
  );

  const all = useMemo(
    () => canBuyLocalNumber || canBuyTollFreeNumber,
    [canBuyLocalNumber, canBuyTollFreeNumber],
  );

  const byType = useCallback(
    (type: OwnPhoneType) =>
      maxAllowedPhones[type] && phoneType?.[type] >= maxAllowedPhones[type],
    [phoneType, maxAllowedPhones],
  );

  return {
    all,
    byType,
    canBuyLocalNumber,
    canBuyTollFreeNumber,
  };
};
