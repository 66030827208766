import React, { FC } from 'react';
import { ButtonProps, IconButton } from '@chakra-ui/react';

import EditIcon from '@app/icons/edit-icon.svg?react';

const IconButtonEdit: FC<ButtonProps> = ({ ...props }) => {
  return (
    <IconButton
      isRound
      aria-label="Edit"
      size="xs"
      variant="iconButton"
      {...props}>
      <EditIcon />
    </IconButton>
  );
};

export default IconButtonEdit;
