/* eslint-disable react/jsx-props-no-spreading */
import { Box, BoxProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Loading } from './Loading';
import { Header } from './Header';

export const TableSkeleton: FC<BoxProps> = (props) => {
  return (
    <Box width="100%" {...props}>
      <Header />

      <Loading />
    </Box>
  );
};
