import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Center, useDisclosure } from '@chakra-ui/react';
import { DateTime } from 'luxon';

import { AccountSettingsPopup } from '@app/api/gql/graphql';
import ActivateAccountPopup from '@app/components/ActivateAccountPopup';
import { RegisterBrandModal } from '@app/components/10Dlc';
import { PendingBrandModal } from '@app/components/10Dlc/PendingBrandModal';
import ErrorModal from '@app/components/ErrorModal';
import ConfirmAlert from '@app/components/ConfirmAlert';
import { usePopupsProvider } from '@app/hooks/usePopupsProvider';
import {
  FirstDayTrialPopup,
  MobileAlertPopup,
  SecondDayTrialPopup,
  WelcomePopup,
} from '@app/components/BasicPopups';
import PopupsContext, {
  ConfirmStateType,
  TrialModalType,
} from '@app/contexts/PopupsContext';
import { CustomerFeedbackModal } from '@app/components/Modals/CustomerFeedbackModal';
import TrialLimitsPopup from '@app/components/BasicPopups/TrialLimitsPopup';
import PhoneFieldModal from '@app/pages/Settings/content/Profile/components/PhoneField/PhoneFieldModal';
import { UpdateBrandModal } from '@app/components/10Dlc/UpdateBrandModal';
import { MarketingPopupViewer } from '@app/widgets/MarketingPopupViewer';

import { CalendlyModal } from '@app/components/CalendlyModal';
import {
  AccountStatus,
  FeedbackSource,
  useAccountOutsideBusinessHoursRestrictionsSubscription,
  useFindLastFeedbackQuery,
  OutsideBusinessHoursRestrictionType,
} from '@app/api/gql/generated-types';
import ActivateMessageSubscriptionModal from '@app/components/ActivateMessageSubscriptionPopup';
import ActivateDialerSubscriptionModal from '@app/components/ActivateDialerSubscriptionPopup';
import { hasActiveMessageSubscription } from '@app/utils/subscriptionHelpers';
import ResetPasswordPopup from '@app/components/BasicPopups/ResetPasswordPopup';
import { isAccountActive } from '@app/utils/accountUtils';
import { calculateFeedbackDeadline } from '@app/utils/feedbackUtils';
import { CustomModal } from '@app/components/next/organisms/CustomModal';
import { NewTermsAndConditionsModal } from '@app/components/BasicPopups/NewTermsAndConditionsModal';
import { useOnboardingModal } from '@app/hooks/useOnboardingModal';
import { CurrentAccountContext } from './CurrentAccountProvider';

interface PopupsProviderProps {
  children: React.ReactChild | React.ReactChild[];
}

type ModalType =
  | 'activateAccountModal'
  | 'ActivateMessageSubscriptionModal'
  | 'ActivateDialerSubscriptionModal'
  | 'SubscriptionAccessDeniedModal'
  | 'dlcSubmitModal'
  | 'dlcPendingStatusModal'
  | 'trialLimitModal'
  | 'errorModal'
  | 'oldUserWelcomeModal'
  | 'newVersionModal'
  | 'customerFeedbackModal'
  | 'referralPromoModal'
  | 'mobileAlertModal'
  | 'marketingPopupViewer'
  | 'outsideBusinessHoursModal';

const initialConfirmState: ConfirmStateType = {
  title: '',
  description: null,
  confirmButtonText: '',
};

const PopupsProvider: FC<PopupsProviderProps> = ({ children }) => {
  const { accountData, canWelcomePopup, isToday } = usePopupsProvider();

  const { isImpersonate } = useContext(CurrentAccountContext);

  const {
    data: { findLastFeedback: feedback } = {},
    loading: isFeedbackLoading,
    error: isFeedbackError,
  } = useFindLastFeedbackQuery({
    variables: {
      source: FeedbackSource.WEB,
    },
  });

  const isFeedbackRequired = useMemo(() => {
    if (
      isFeedbackLoading ||
      !isAccountActive(accountData) ||
      !hasActiveMessageSubscription(accountData) ||
      !accountData?.subscription?.nextBillingDate ||
      !!isFeedbackError
    ) {
      return false;
    }

    const feedbackDeadline = calculateFeedbackDeadline(
      accountData.subscription.nextBillingDate,
    );
    const isProvidedInPeriod =
      feedback &&
      DateTime.fromISO(feedback.createdAt).valueOf() >= feedbackDeadline;
    const trialEndedAt = DateTime.fromISO(
      accountData?.subscription?.trialEndedAt,
    ).valueOf();
    const isTrialStatusInPeriod = Date.now() <= trialEndedAt;

    return (
      Date.now() >= feedbackDeadline &&
      !isProvidedInPeriod &&
      !isTrialStatusInPeriod
    );
  }, [accountData, isFeedbackLoading, feedback, isFeedbackError]);

  const {
    onShowModal,
    isOpen: isOpenOnboardingModal,
    onClose: onCloseOnboardingModal,
    url,
  } = useOnboardingModal();

  //REFACTOR we should pass the modal + the content to show inside the modal to the provider like we did in PermissionCustomModalContent
  // and standarize the behaviour of all modals using usePermissionAbility instead of guards.
  // we need to think how to remove the guards which are tightly coupled with this
  // Ideally we should cover all the cases with the new PermissionCustomModal, having the same shell design for all of them.
  const activateAccountModal = useDisclosure();
  const activateDialerSubscriptionModal = useDisclosure();
  const activateMessagingSubscriptionModal = useDisclosure();
  const subscriptionAccessDeniedModal = useDisclosure();
  const dlcSubmitModal = useDisclosure();
  const dlcPendingStatusModal = useDisclosure();
  const dlcUpdateAccountModal = useDisclosure();
  const trialLimitModal = useDisclosure();
  const errorModal = useDisclosure();
  const confirmModal = useDisclosure();
  const welcomeModal = useDisclosure();
  const firstDayTrialModal = useDisclosure();
  const secondDayTrialModal = useDisclosure();
  const customerFeedbackModal = useDisclosure();
  const mobilePhoneModal = useDisclosure();
  const mobileAlertModal = useDisclosure();
  const resetPasswordModal = useDisclosure();
  const newTermsAndConditionsModal = useDisclosure();
  const campaignOutsideBusinessHoursModal = useDisclosure();

  const checkIsAnyModalOpen = (currentModal: ModalType) => {
    const modals = {
      activateAccountModal: activateAccountModal.isOpen,
      activateDialerSubscriptionModal: activateDialerSubscriptionModal.isOpen,
      activateMessagingSubscriptionModal:
        activateMessagingSubscriptionModal.isOpen,
      subscriptionAccessDeniedModal: subscriptionAccessDeniedModal.isOpen,
      dlcSubmitModal: dlcSubmitModal.isOpen,
      dlcPendingStatusModal: dlcPendingStatusModal.isOpen,
      trialLimitModal: trialLimitModal.isOpen,
      errorModal: errorModal.isOpen,
      confirmModal: confirmModal.isOpen,
      welcomeModal: welcomeModal.isOpen,
      firstDayTrialModal: firstDayTrialModal.isOpen,
      secondDayTrialModal: secondDayTrialModal.isOpen,
      customerFeedbackModal: customerFeedbackModal.isOpen,
      mobilePhoneModal: mobilePhoneModal.isOpen,
      mobileAlertModal: mobileAlertModal.isOpen,
      resetPasswordModal: resetPasswordModal.isOpen,
      newTermsAndConditionsModal: newTermsAndConditionsModal.isOpen,
      outsideBusinessHoursModal: campaignOutsideBusinessHoursModal.isOpen,
    };

    return Object.keys(modals).some(
      (modal) => modals[modal as keyof typeof modals] && modal !== currentModal,
    );
  };

  useAccountOutsideBusinessHoursRestrictionsSubscription({
    skip: !accountData?.id,
    onData: ({
      data: {
        data: {
          outsideBusinessHoursRestrictions: { accountId, restriction },
        },
      },
    }) => {
      if (
        accountId === accountData.id &&
        restriction === OutsideBusinessHoursRestrictionType.CAMPAIGN
      ) {
        campaignOutsideBusinessHoursModal.onOpen();
      }
    },
  });

  useEffect(() => {
    if (!isImpersonate && isFeedbackRequired) {
      customerFeedbackModal.onOpen();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeedbackRequired]);

  useEffect(() => {
    const viewedPopups = accountData?.settings?.viewedPopups ?? [];

    if (!viewedPopups.includes(AccountSettingsPopup.newTermsAndConditions)) {
      newTermsAndConditionsModal.onOpen();
      return;
    }

    if (!viewedPopups.includes(AccountSettingsPopup.firstLogin)) {
      welcomeModal.onOpen();
      return;
    }

    if (
      !viewedPopups.includes(AccountSettingsPopup.popupTrialFirstDay) &&
      accountData.status === AccountStatus.TRIAL
    ) {
      firstDayTrialModal.onOpen();
      return;
    }

    if (
      isToday &&
      !viewedPopups.includes(AccountSettingsPopup.popupTrialSecondDay) &&
      accountData.status === AccountStatus.TRIAL
    ) {
      secondDayTrialModal.onOpen();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !accountData?.parentAccount &&
      !accountData?.settings?.viewedPopups?.includes(
        AccountSettingsPopup.popupOnboarding,
      ) &&
      accountData?.status === AccountStatus.ACTIVE
    ) {
      onShowModal();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData?.status]);

  useEffect(() => {
    if (
      accountData?.settings?.viewedPopups?.includes(
        AccountSettingsPopup.showPasswordResetPopup,
      ) &&
      !accountData?.settings?.viewedPopups?.includes(
        AccountSettingsPopup.passwordResetPopup,
      )
    ) {
      resetPasswordModal.onOpen();
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData?.settings?.viewedPopups]);

  const [error, setError] = useState<string>(null);
  const [confirmModalState, setConfirmModalState] =
    useState<ConfirmStateType>(initialConfirmState);

  const [mobilePhoneCloseState, setMobilePhoneCloseState] = useState(true);

  const [trialModalState, setTrialModalState] = useState<TrialModalType>();

  const resolveRef = useRef<(value?: unknown) => void>();
  const rejectRef = useRef<(value?: unknown) => void>();

  const showConfirmPopup = (data: ConfirmStateType) => {
    return new Promise((resolve, reject) => {
      setConfirmModalState(data);
      confirmModal.onOpen();
      resolveRef.current = resolve;
      rejectRef.current = reject;
    });
  };

  const showMobilePhonePopup = (canClose?: boolean) => {
    return new Promise((resolve, reject) => {
      setMobilePhoneCloseState(canClose);
      mobilePhoneModal.onOpen();
      resolveRef.current = resolve;
      rejectRef.current = reject;
    });
  };

  const showTrialPopup = (type: TrialModalType) => {
    setTrialModalState(type);
    trialLimitModal.onOpen();
  };

  const closeConfirmPopup = () => {
    confirmModal.onClose();
  };

  const closeMobilePhoneModal = () => {
    mobilePhoneModal.onClose();
  };

  return (
    <PopupsContext.Provider
      value={{
        activateAccountModal,
        activateDialerSubscriptionModal,
        activateMessagingSubscriptionModal,
        subscriptionAccessDeniedModal,
        dlcSubmitModal,
        dlcPendingStatusModal,
        dlcUpdateAccountModal,
        mobileAlertModal,
        errorModal,
        error,
        setError,
        confirmModal,
        showTrialPopup,
        showConfirmPopup,
        closeConfirmPopup,
        showMobilePhonePopup,
        closeMobilePhoneModal,
        outsideBusinessHoursModal: campaignOutsideBusinessHoursModal,
      }}>
      <>
        {children}
        <ActivateAccountPopup
          canClose={!accountData?.parentAccount}
          isOpen={activateAccountModal.isOpen}
          onClose={activateAccountModal.onClose}
        />
        <ActivateMessageSubscriptionModal
          isOpen={activateMessagingSubscriptionModal.isOpen}
          onClose={activateMessagingSubscriptionModal.onClose}
        />
        <ActivateDialerSubscriptionModal
          isOpen={activateDialerSubscriptionModal.isOpen}
          onClose={activateDialerSubscriptionModal.onClose}
        />
        <CustomModal
          //FIXME remove and call the acl permission feature
          isOpen={subscriptionAccessDeniedModal.isOpen}
          primaryAction={subscriptionAccessDeniedModal.onClose}
          primaryButtonText="Close"
          title="Access denied"
          onClose={subscriptionAccessDeniedModal.onClose}>
          <Center fontSize="14px" textAlign="center">
            To reactive your subscription and regain access to
          </Center>
          <Center fontSize="14px" mb="25px" textAlign="center">
            your account, contact primary account holder.
          </Center>
        </CustomModal>
        <RegisterBrandModal
          isOpen={dlcSubmitModal.isOpen}
          onClose={dlcSubmitModal.onClose}
        />
        <PendingBrandModal
          isOpen={dlcPendingStatusModal.isOpen}
          onClose={dlcPendingStatusModal.onClose}
        />
        <UpdateBrandModal
          isOpen={dlcUpdateAccountModal.isOpen}
          onClose={dlcUpdateAccountModal.onClose}
        />
        <ErrorModal
          error={error}
          isOpen={errorModal.isOpen}
          onClose={errorModal.onClose}
        />
        <ConfirmAlert
          cancelButtonWidth={confirmModalState?.cancelButtonWidth}
          confirmButtonWidth={confirmModalState?.confirmButtonWidth}
          confirmText={confirmModalState?.confirmButtonText}
          description={confirmModalState?.description}
          handler={() => {
            resolveRef.current();
          }}
          hideCancelButton={confirmModalState?.hideCancelButton}
          isOpen={confirmModal.isOpen}
          maxWidth={confirmModalState?.maxWidth}
          padding={confirmModalState?.padding}
          title={confirmModalState?.title}
          verticalActions={confirmModalState?.verticalActions}
          onClose={() => {
            rejectRef.current();
            confirmModal.onClose();
          }}
        />
        {mobilePhoneModal.isOpen && (
          <PhoneFieldModal
            canClose={mobilePhoneCloseState}
            handler={() => {
              resolveRef.current();
              closeMobilePhoneModal();
            }}
            isOpen={mobilePhoneModal.isOpen}
            onClose={() => {
              rejectRef.current();
              closeMobilePhoneModal();
            }}
          />
        )}

        {resetPasswordModal?.isOpen && (
          <ResetPasswordPopup
            isOpen={resetPasswordModal.isOpen}
            onClose={resetPasswordModal.onClose}
          />
        )}

        {canWelcomePopup && welcomeModal?.isOpen && (
          <WelcomePopup
            isOpen={welcomeModal.isOpen}
            onClose={welcomeModal.onClose}
          />
        )}
        {firstDayTrialModal?.isOpen && (
          <FirstDayTrialPopup
            isOpen={firstDayTrialModal.isOpen}
            onClose={firstDayTrialModal.onClose}
          />
        )}
        {secondDayTrialModal?.isOpen && (
          <SecondDayTrialPopup
            isOpen={secondDayTrialModal.isOpen}
            onClose={secondDayTrialModal.onClose}
          />
        )}
        {customerFeedbackModal.isOpen && (
          <CustomerFeedbackModal
            isOpen={customerFeedbackModal.isOpen}
            onClose={customerFeedbackModal.onClose}
          />
        )}
        {trialLimitModal?.isOpen && (
          <TrialLimitsPopup
            isOpen={trialLimitModal.isOpen}
            type={trialModalState}
            onClose={trialLimitModal.onClose}
          />
        )}
        {mobileAlertModal.isOpen && (
          <MobileAlertPopup
            isOpen={mobileAlertModal.isOpen}
            onClose={mobileAlertModal.onClose}
          />
        )}
        {isOpenOnboardingModal && (
          <CalendlyModal
            isOpen={isOpenOnboardingModal}
            url={url}
            onClose={onCloseOnboardingModal}
          />
        )}

        {newTermsAndConditionsModal.isOpen && (
          <NewTermsAndConditionsModal
            isOpen={newTermsAndConditionsModal.isOpen}
            onClose={newTermsAndConditionsModal.onClose}
          />
        )}
      </>
      {!isImpersonate && !checkIsAnyModalOpen('marketingPopupViewer') && (
        <MarketingPopupViewer />
      )}
      {/* TODO: this should be moved out from here in scope of refactoring.*/}
      <CustomModal
        isOpen={campaignOutsideBusinessHoursModal.isOpen}
        primaryAction={campaignOutsideBusinessHoursModal.onClose}
        primaryButtonText="Got it"
        title="Outside business hours"
        onClose={campaignOutsideBusinessHoursModal.onClose}>
        <Box mb="16px" textAlign="center">
          The campaign cannot be run outside business hours{' '}
          <strong>(8 am - 9 pm)</strong>
        </Box>
        <Box mb="24px" textAlign="center">
          You will be able to run the campaign when business hours begin
        </Box>
      </CustomModal>
    </PopupsContext.Provider>
  );
};

export default PopupsProvider;
