import React, { FC } from 'react';
import { Text } from '@chakra-ui/react';

type MessageTooltipProps = {
  content: string;
  title: string;
};

export const MessageTooltip: FC<MessageTooltipProps> = ({ content, title }) => (
  <>
    <Text lineHeight="20px" variant="secondary-text">
      {title}
    </Text>
    <Text lineHeight="20px" variant="heading-fifth" whiteSpace="pre-wrap">
      {content}
    </Text>
  </>
);
