import capitalize from 'lodash/capitalize';
import { upperFirst } from 'lodash/fp';
import {
  BillingInterval,
  Plan,
  PlanType,
  PromoCode,
} from '@app/api/gql/generated-types';
import { sortByPriceProperty } from '@app/utils/arrayUtils';
import {
  excludeBillingPeriod,
  TCurrencySymbol,
} from '@app/utils/priceFormatUtils';
import {
  getSubscriptionPeriodPrice,
  isProductDialer,
  isProductMessage,
} from '@app/utils/subscriptionHelpers';
import {
  centsToDollars,
  toMonthlyPrice,
  toQuarterlyMonthlyPrice,
} from '@app/utils/formatNumber';
import { formatNumber } from '@app/components/FormatNumber/utils';

/**
 * The label representing the most popular business plan.
 *
 * @type {string}
 * @constant
 */
const MOST_POPULAR_BUSINESS_PLAN_LABEL = 'Elite quarterly';
/**
 * The label for the most popular business yearly plan.
 *
 * @type {string}
 */
const MOST_POPULAR_BUSINESS_YEARLY_PLAN_LABEL = 'Elite yearly';
/**
 * The label for the most popular enterprise plan.
 *
 * @type {string}
 * @constant
 * @readonly
 */
const MOST_POPULAR_ENTERPRISE_PLAN_LABEL = 'Enterprise 100K';
/**
 * The label for the most popular enterprise quarterly plan.
 *
 * @type {string}
 * @constant
 * @readonly
 */
const MOST_POPULAR_ENTERPRISE_QUARTERLY_PLAN_LABEL =
  'Elite Plus 100k Quarterly';
/**
 * The default label for the Enterprise plan.
 *
 * @constant {string}
 */
const DEFAULT_ENTERPRISE_PLAN_LABEL = 'Enterprise 100K';
/**
 * Represents the label for the enterprise plan.
 *
 * @constant {string} ENTERPRISE_PLAN_LABEL
 * @description The value of this constant is 'enterprise'.
 */
const ENTERPRISE_PLAN_LABEL = 'enterprise';
/**
 * Represents the label indicating that a plan is obsolete.
 *
 * @type {string}
 */
const OBSOLETE_PLAN_LABEL = '(obsolete)';
/**
 * Represents the label used in the user interface for the enterprise plan.
 *
 * @const {string}
 */
const ENTERPRISE_PLAN_LABEL_UI = 'elite plus';
/**
 * Represents the label used for the Business Elite Yearly Plan in the user interface (UI).
 *
 * @type {string}
 */
const BUSSINES_ELITE_YEARLY_PLAN_LABEL_UI = 'elite yearly';
/**
 * Represents the exception thrown when a billing plan for marketing is encountered.
 *
 * @constant
 * @type {number}
 * @default 2000
 */
const BillingPlanExceptionForMarketing = 2000;
/**
 * Exception class to handle errors related to billing plan for Marketing Business Plan.
 *
 * @class
 * @throws {BillingPlanExceptionForMarketingBusinessPlan} Error with billing plan for Marketing Business Plan
 */
const BillingPlanExceptionForMarketingBusinessPlan = 379;
/**
 * Represents the exception error code for the Billing Yearly Plan Discount
 * for the Marketing Business Plan.
 *
 * @type {number}
 * @constant
 */
const BillingYearlyPlanDiscountExceptionForMarketingBusinessPlan = 1440;

/**
 * Sanitize the given plan name.
 *
 * @param {string} planName - The plan name to be sanitized.
 * @returns {string} - The sanitized plan name.
 */
export const sanitizePlanName = (planName: string): string =>
  excludeBillingPeriod(planName)
    .replace(OBSOLETE_PLAN_LABEL, '')
    .toLowerCase()
    .trim();

/**
 * Sanitize the given plan name no excluding billing period
 *
 * @param {string} planName - The plan name to be sanitized.
 * @returns {string} - The sanitized plan name.
 */
export const sanitizePlanNameNoExcludingBillingPeriod = (
  planName: string,
): string => planName.replace(OBSOLETE_PLAN_LABEL, '').toLowerCase().trim();

/**
 * Formats the enterprise plan name by sanitizing the subtitle, replacing labels, capitalizing each word, and joining them with spaces.
 *
 * @param {string} subTitle - The subtitle of the enterprise plan.
 * @returns {string} - The formatted enterprise plan name.
 */
export const formatEnterprisePlanName = (subTitle: string) => {
  return sanitizePlanName(subTitle)
    .replace(ENTERPRISE_PLAN_LABEL, ENTERPRISE_PLAN_LABEL_UI)
    .split(' ')
    .map(upperFirst)
    .join(' ');
};

/**
 * Formats the given business plan name.
 *
 * @param {string} planName - The original business plan name.
 * @returns {string} - The formatted business plan name.
 */
export const formatBusinessPlanName = (planName: string) =>
  capitalize(sanitizePlanName(planName));

/**
 * Formats the given business plan user title.
 *
 * @param {string} planName - The original business plan user title.
 * @returns {string} - The formatted business plan name.
 */
export const formatFullBusinessPlanUserTitle = (planName: string) =>
  capitalize(sanitizePlanNameNoExcludingBillingPeriod(planName));

/**
 * Checks if a given plan is a yearly plan.
 *
 * @param {Plan} plan - The plan to check.
 * @returns {boolean} - Returns `true` if the plan is a yearly plan, `false` otherwise.
 */
export const isYearlyPlan = (plan: Plan): boolean =>
  plan?.interval === BillingInterval.YEAR;

export const isQuarterlyPlan = (plan: Plan): boolean =>
  plan?.interval === BillingInterval.QUARTER;

/**
 * Finds a plan in an array of plans by its ID.
 *
 * @param {Array} plans - The array of plans to search in.
 * @param {string} id - The ID of the plan to find.
 *
 * @returns {Object|null} - The found plan object, or null if no plan was found.
 */
export const findPlanById = (plans: Plan[], id: string): Plan | null =>
  plans.find((plan) => plan.id === id) || null;
/**
 * Calculates the price of a given plan.
 *
 * @param {Plan} [plan] - The plan object for which the price needs to be calculated.
 * @returns {number} The price of the plan. If no plan is provided, returns 0.
 */
export const getPlanPrice = (plan?: Plan): number =>
  plan ? Number(plan.price) : 0;
/**
 * Determines if a given plan is an enterprise plan.
 *
 * @param {Plan} plan - The plan to check.
 * @returns {boolean} - True if the plan is an enterprise plan, false otherwise.
 */
export const isEnterprisePlan = (plan: Plan): boolean => plan?.enterprise;
/**
 * Checks if a given plan is a business plan.
 *
 * @param {Plan} plan - The plan to check.
 * @returns {boolean} - Returns true if the plan is a business plan, false otherwise.
 */
export const isBusinessPlan = (plan: Plan): boolean => !isEnterprisePlan(plan);

export const isMonthlyPlan = (plan: Plan): boolean =>
  plan?.interval === BillingInterval.MONTH;

/**
 * Extracts enterprise plans from the provided array of plans.
 *
 * @param {Plan[]} plans - The array of plans to extract from.
 * @returns {Plan[]} - The array of enterprise plans sorted by price.
 */
export const extractEnterprisePlans = (
  plan: Plan[],
  showBusinessYearly = false,
): Plan[] =>
  plan
    .filter((pl) =>
      showBusinessYearly ? isQuarterlyPlan(pl) : isMonthlyPlan(pl),
    )
    .filter(
      (p) =>
        isEnterprisePlan(p) &&
        p.type !== PlanType.BASIC &&
        !isProductDialer(p.product),
    )
    .sort(sortByPriceProperty);

/**
 * Filters an array of plan objects based on the specified billing interval.
 *
 * @param {Plan[]} plans - The array of plan objects to filter.
 * @param {BillingInterval} interval - The billing interval to filter by.
 * @returns {Plan[]} - An array of plan objects that match the specified billing interval.
 */
export const filterInterval = (
  plans: Plan[],
  interval: BillingInterval,
): Plan[] =>
  plans.filter(
    (item) => item.interval === interval && item.type !== PlanType.BASIC,
  );

/**
 * Extracts business plans from a list of plans.
 *
 * @param {Plan[]} plans - The list of plans to extract from.
 * @param {boolean} [showBusinessYearly=false] - Specifies whether to include only yearly business plans.
 * @returns {Plan[]} - The extracted business plans.
 */
export const extractBusinessPlans = (
  plans: Plan[],
  showBusinessYearly = false,
): Plan[] => {
  let plansByInterval = filterInterval(
    plans,
    showBusinessYearly ? BillingInterval.YEAR : BillingInterval.QUARTER,
  );
  if (showBusinessYearly) {
    plansByInterval = plansByInterval.filter(
      (p) => !p.enterprise && p.type !== PlanType.BASIC,
    );
  }

  return plansByInterval
    .filter((plan) => !isEnterprisePlan(plan))
    .sort(sortByPriceProperty);
};

/**
 * Retrieve the most popular business plan from a list of plans.
 *
 * @param {Plan[]} plans - The list of plans to search from.
 * @param {boolean} [yearly=false] - If true, only retrieve yearly plans.
 * @returns {Plan} The most popular business plan.
 */
export const getMostPopularBusinessPlan = (
  plans: Plan[],
  yearly = false,
): Plan => {
  const list = extractBusinessPlans(plans, yearly);
  const planTitle = yearly
    ? MOST_POPULAR_BUSINESS_YEARLY_PLAN_LABEL
    : MOST_POPULAR_BUSINESS_PLAN_LABEL;

  return list.find((plan) => plan.title === planTitle);
};

/**
 * Retrieves the most elite yearly business plan from the given array of plans.
 *
 * @param {Plan[]} plans - The array of plans from which to retrieve the most elite yearly business plan.
 * @returns {Plan} - The most elite yearly business plan, or undefined if none is found.
 */
export const getMostEliteYearlyBusinessPlan = (plans: Plan[]): Plan => {
  const list = extractBusinessPlans(plans, true);
  return list.find(
    (plan) => plan.title === MOST_POPULAR_BUSINESS_YEARLY_PLAN_LABEL,
  );
};

/**
 * Retrieves the most popular enterprise plan from the given array of plans.
 *
 * @param {Plan[]} plans - Array of plans to search from.
 * @param {boolean} [quarterly=false] - If true, only retrieve quarterly plans.
 * @returns {Plan | undefined} - The most popular enterprise plan found, or undefined if not found.
 */
export const getMostPopularEnterprisePlan = (
  plans: Plan[],
  quarterly = false,
): Plan | undefined => {
  const label = quarterly
    ? MOST_POPULAR_ENTERPRISE_QUARTERLY_PLAN_LABEL
    : MOST_POPULAR_ENTERPRISE_PLAN_LABEL;

  return plans.find((plan) => plan.title === label);
};

/**
 * Retrieves the default enterprise plan from a list of plans.
 * @param {Plan[]} plans - The array of plans to search from.
 * @param {boolean} yearly - Optional. Specifies whether the plan is yearly. Defaults to false.
 * @returns {Plan} - The default enterprise plan.
 */
export const getDefaultEnterprisePlan = (
  plans: Plan[],
  // add implementation for yearly when needed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  yearly = false,
): Plan => {
  const list = extractEnterprisePlans(plans);

  return list.find((p) => p.title === DEFAULT_ENTERPRISE_PLAN_LABEL);
};

/**
 * Calculates the patched billing plan price based on the given plan object.
 *
 * @param {Plan} plan - The plan object containing the price and other details.
 * @returns {number} - The patched billing plan price.
 */
export const patchBillingPlanPrice = (plan: Plan): number => {
  const yearlyFullPrice = Number(plan.price);
  let planPrice = plan.enterprise
    ? centsToDollars(yearlyFullPrice)
    : getSubscriptionPeriodPrice(plan);

  if (plan.title === MOST_POPULAR_BUSINESS_YEARLY_PLAN_LABEL) {
    planPrice = BillingPlanExceptionForMarketing;
  }

  return planPrice;
};

/**
 * Applies a discount to a billing plan and returns the formatted result.
 * If the plan meets certain conditions, a specific discount will be applied.
 * Otherwise, the provided format function will be used to format the discount value.
 *
 * @param {Plan} plan - The billing plan to apply the discount to.
 * @param {number} discount - The discount value to apply.
 * @param {function} formatFn - The function to use for formatting the discount value.
 *   The function should take a number as a parameter and return a formatted string.
 *
 * @returns {string} - The formatted result after applying the discount.
 *
 * @throws {Error} - If any of the parameters is not provided or not of the correct type.
 */
export const patchBillingPlanDiscount = (
  plan: Plan,
  discount: number,
  formatFn: (priceValue: number) => string,
): string | number => {
  if (
    isProductMessage(plan.product) &&
    !isEnterprisePlan(plan) &&
    isYearlyPlan(plan) &&
    discount > 0 &&
    plan.title.toLowerCase() === BUSSINES_ELITE_YEARLY_PLAN_LABEL_UI
  ) {
    const priceFormat = formatNumber({
      value: BillingYearlyPlanDiscountExceptionForMarketingBusinessPlan,
      fractionDigits: 0,
    });
    return `${TCurrencySymbol.enum.$}${priceFormat}`;
  }

  if (
    isProductMessage(plan.product) &&
    isEnterprisePlan(plan) &&
    isQuarterlyPlan(plan)
  ) {
    return `${TCurrencySymbol.enum.$}${Math.round(discount / 100)}`;
  }

  if (isProductDialer(plan.product)) {
    return `${TCurrencySymbol.enum.$}${Math.round(discount / 100)}`;
  }

  return formatFn(discount);
};

/**
 * Calculates the monthly price of a billing plan.
 *
 * @param {Plan} plan - The billing plan to calculate the price for.
 * @param {boolean} isYearly - Specifies whether the plan is yearly.
 * @returns {number} - The calculated monthly price.
 */
export const patchMonthlyPrice = (plan: Plan, isYearly: boolean) => {
  let price: number = patchBillingPlanPrice(plan);

  if (!plan.enterprise && isYearly) {
    price = toMonthlyPrice(centsToDollars(Number(plan.displayPrice)));
  }

  if (isEnterprisePlan(plan) && isYearly) {
    price = toQuarterlyMonthlyPrice(centsToDollars(Number(plan.displayPrice)));
  }

  if (plan.title === MOST_POPULAR_BUSINESS_YEARLY_PLAN_LABEL) {
    price = BillingPlanExceptionForMarketingBusinessPlan;
  }

  return price;
};

/**
 * Removes specific terms from the title of a plan.
 *
 * @param {Plan} plan - The plan object containing the title.
 * @returns {string} - The modified title string.
 */
export const getEnterprisePlanValue = (plan: Plan): string => {
  const replacementStrings = [
    capitalize(ENTERPRISE_PLAN_LABEL),
    OBSOLETE_PLAN_LABEL,
    'K',
    'k Quarterly',
    'Elite Plus',
    'k Yearly',
  ];

  return replacementStrings
    .reduce((title, term) => title.replace(term, ''), plan.title)
    .trim();
};

export const isBasicQuarterlyPlan = (plan: Plan): boolean =>
  plan?.type === PlanType.BASIC && plan?.interval === BillingInterval.QUARTER;

/**
 * Creates a promoCode object to show in the subscription modal based on the discount or promocode object of the account.subscription object
 *
 * @param {Discount} discount - The discount object
 * @returns {PromoCode} - Promocode.
 */
export const createPromoCode = (discount: {
  coupon: string;
  promoCode?: string;
  amount?: number;
  percent?: number;
}): PromoCode | null => {
  if (!discount.coupon) {
    return null;
  }

  return {
    code: discount.promoCode || discount.coupon,
    coupon: {
      id: discount.coupon,
      amountOff: discount.amount || null,
      percentOff: discount.percent || null,
    },
    id: discount.coupon,
  };
};
