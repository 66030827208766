import React, { FC, useMemo, SyntheticEvent } from 'react';
import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import ROUTES, { generateUrl } from '@app/utils/routes';
import CopyGroupIcon from '@app/icons/copy-group-icon.svg?react';
import { useDuplicateGroupsMutation } from '@app/api/gql/generated-types';

interface CloneGroupButtonProps {
  id: string;
  canBeCloned: boolean;
}

export const CloneGroupButton: FC<CloneGroupButtonProps> = ({
  id,
  canBeCloned,
}) => {
  const navigate = useNavigate();

  const [duplicateGroupsMutation, { loading: isLoading }] =
    useDuplicateGroupsMutation();
  // TODO(@Yurii07): check(cache) after push to campaign will be finished

  const isDisabled = useMemo(
    () => !canBeCloned || isLoading,
    [canBeCloned, isLoading],
  );

  const onCloneGroup = async (e: SyntheticEvent) => {
    e.stopPropagation();
    if (isDisabled) {
      return;
    } else {
      const { data } = await duplicateGroupsMutation({
        variables: {
          duplicateGroupsId: id,
        },
      });

      navigate(
        generateUrl(ROUTES.contactEdit, {
          contactId: data?.duplicateGroups?.id,
        }),
      );
    }
  };

  return (
    <Box role="button" onClick={onCloneGroup}>
      <IconButton
        aria-label="Clone Group"
        isDisabled={isDisabled}
        isLoading={isLoading}
        variant="ghostPlain">
        <Tooltip
          hasArrow
          label={
            canBeCloned
              ? 'Duplicate this group'
              : 'Cannot duplicate this group as it was created manually'
          }
          maxWidth="200px"
          placement="top">
          <CopyGroupIcon />
        </Tooltip>
      </IconButton>
    </Box>
  );
};
