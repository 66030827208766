import React, { FC, useCallback, useMemo } from 'react';
import { Box, Button, Center, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import Popup from '@app/components/Popup';
import ROUTES, { generateUrl } from '@app/utils/routes';
import { ListVerificationResult } from '@app/api/gql/generated-types';

import { AccordionInvalidNumbers } from './content/AccordionInvalidNumbers';

interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  contactListId: string;
  refetchRawContacts: () => void;
  verificationList: ListVerificationResult;
}

export const ResultModal: FC<ResultModalProps> = ({
  isOpen,
  onClose,
  title,
  verificationList,
  contactListId,
  refetchRawContacts,
}) => {
  const navigate = useNavigate();
  const client = useApolloClient();

  const goToPage = useCallback(() => {
    if (verificationList?.verified) {
      client.cache.evict({ fieldName: 'getGroup' });
      navigate(generateUrl(ROUTES.contactsGroups, { groupId: contactListId }), {
        state: contactListId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactListId, navigate, verificationList]);

  const isZeroValidNumbers = useMemo(
    () => verificationList?.verifiedPhones === 0,
    [verificationList?.verifiedPhones],
  );

  const onCloseWithRefetch = useCallback(() => {
    refetchRawContacts();
    onClose();
  }, [onClose, refetchRawContacts]);

  return (
    <Popup
      hideCloseIcon
      closeOnOverlayClick={false}
      isOpen={isOpen}
      title={title}
      width="350px"
      onClose={onClose}>
      <Box margin="15px 40px 20px">
        <Text
          color="osloGray"
          fontSize="12px"
          lineHeight="14px"
          mb="10px"
          mt="15px">
          This search has scrubbed numbers based on all of your previous
          filters. In addition, we have removed all duplicate phone numbers from
          this list.
        </Text>
        <Text
          borderBottom="1px dashed"
          borderBottomColor="secondary.400"
          color="main.400"
          fontSize="14px"
          padding="12px 0">
          Total phones:
          <strong> {verificationList?.totalPhones || 0}</strong>
        </Text>

        <Text
          borderBottom="1px dashed"
          borderBottomColor="secondary.400"
          color="main.400"
          fontSize="14px"
          padding="12px 0">
          Valid numbers:{' '}
          <strong>{verificationList?.verifiedPhones || 0}</strong>
        </Text>

        <AccordionInvalidNumbers verificationList={verificationList} />

        {isZeroValidNumbers && (
          <Text
            bg="primary.100"
            borderRadius="10px"
            mb="25px"
            mt="5px"
            padding="10px"
            variant="heading-fifth">
            There are no contacts in your file which are successfully verified
          </Text>
        )}

        <Center flexDirection="column" mt="10px">
          <Button
            mb="15px"
            variant="primary"
            width="170px"
            onClick={!isZeroValidNumbers ? goToPage : onCloseWithRefetch}>
            {!isZeroValidNumbers ? 'Next' : 'Back to upload'}
          </Button>
        </Center>
      </Box>
    </Popup>
  );
};
