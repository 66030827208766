import { Skeleton, Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC } from 'react';

import { Field, SkiptraceField } from '@app/api/gql/generated-types';
import { TableHeader } from '@app/api/queries/types';
import threeLines from '@app/icons/threeLines.svg';
import { Header } from '@app/types/Contact';

import { normalizeNameString } from '@app/utils/string';
import { colors } from '@app/theme/colors';
import HeaderSelect from './HeaderSelect';
import Row from './Row';

interface TableListDataProps {
  data: Map<string, string[]>;
  headers: TableHeader | Header[];
}

interface TableListProps {
  tableData: TableListDataProps;
  headerOptions: Field[][] | SkiptraceField[][];
  headerOptionsMap?: Record<string, Field>;
  setHeader: (label: string, key: number) => void;
  editable?: boolean;
  isSkiptracing?: boolean;
  loading?: boolean;
  contactListId: string;
}

const TableList: FC<TableListProps> = ({
  tableData,
  headerOptions,
  headerOptionsMap,
  setHeader,
  editable,
  isSkiptracing = false,
  loading = false,
  contactListId,
}) => {
  const headerLabel = (col: string, index: number) => {
    if (headerOptionsMap?.[col]?.name) {
      return normalizeNameString(headerOptionsMap?.[col]?.name);
    }
    if (isSkiptracing) {
      return `Head${index}`;
    }

    return `Head${index + 1}`;
  };

  return (
    <Table
      sx={{
        '&::-webkit-scrollbar': {
          width: '15px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '20px',
          backgroundColor: colors.secondary[100],
          backgroundPosition: 'center center',
          backgroundImage: `url(${threeLines})`,
          backgroundRepeat: 'no-repeat',
        },
        '&::-webkit-scrollbar-track ': {
          backgroundColor: 'white',
        },
      }}>
      <Thead>
        <Tr position="sticky" top="0" zIndex="10">
          {!isEmpty(tableData?.headers) &&
            tableData.headers?.map((col: string, index) => {
              return (
                <Th
                  key={`column-${index}-${col}`}
                  background={colors.notFound[2400]}
                  color="osloGray"
                  fontSize="14px"
                  fontWeight="400"
                  maxWidth="220px"
                  padding="5px 10px"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  width="210px">
                  {loading ? (
                    <Skeleton
                      endColor="cultured"
                      height="18px"
                      m="13px 0"
                      startColor="mystic"
                      width="90px"
                    />
                  ) : (
                    <HeaderSelect
                      key={`header-select-${index}-${col}`}
                      childrenOptions={headerOptions}
                      columnId={Number(index)}
                      editable={isSkiptracing ? editable : true}
                      headerLabel={headerLabel(col, index)}
                      headers={tableData.headers}
                      onSelect={setHeader}
                    />
                  )}
                </Th>
              );
            })}
          <Th
            background={colors.notFound[2400]}
            color="osloGray"
            fontSize="14px"
            fontWeight="400"
            maxWidth="220px"
            padding="18px 26px"
            textOverflow="ellipsis"
            textTransform="capitalize"
            whiteSpace="nowrap"
            width="210px"
          />
        </Tr>
      </Thead>
      <Tbody>
        {Array.from(tableData?.data).map(([id, row]) => (
          <Row
            key={id}
            editable={editable}
            listId={contactListId}
            row={row}
            rowId={id}
          />
        ))}
      </Tbody>
    </Table>
  );
};

export default TableList;
