import { colors } from '../colors';

const Table = {
  parts: ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'caption'],
  sizes: {
    medium: {
      th: {
        px: '20px',
      },
      td: {
        px: '20px',
        py: '10px',
      },
    },
  },
  variants: {
    bordered: {
      table: {
        borderCollapse: 'collapse',
        // overflow: 'hidden',
        borderRadius: '10px',
      },
      thead: {
        height: '50px',
        backgroundColor: colors.notFound[1900],
      },
      tbody: {
        tr: {
          _notLast: {
            borderBottom: `1px solid ${colors.mystic}`,
          },
        },
      },
      th: {
        height: '50px',
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: 'secondary.400',
        letterSpacing: 'normal',
        _first: {
          borderTopLeftRadius: '10px',
        },
        _last: {
          borderTopRightRadius: '10px',
        },
      },
      tr: {
        _last: {
          td: {
            _first: {
              borderBottomLeftRadius: '10px',
            },
            _last: {
              borderBottomRightRadius: '10px',
            },
          },
        },
      },
      td: {
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: 'darkCharcoal',
      },
    },
    templates: {
      table: {
        borderCollapse: 'separate',
        borderSpacing: '0 2px',
      },
      thead: {
        height: '50px',
        backgroundColor: colors.transparent,
        borderRadius: '4px',
      },
      tbody: {
        tr: {},
      },
      th: {
        height: '50px',
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: 'silver',
        padding: '14px 10px',
      },
      td: {
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: 'mineShaft',
        borderTop: `1px solid ${colors.secondary[200]}`,
        borderBottom: `1px solid ${colors.secondary[200]}`,
        padding: '14px 10px',
        _first: {
          borderBottomLeftRadius: '4px',
          borderTopLeftRadius: '4px',
          borderLeft: `1px solid ${colors.secondary[200]}`,
        },
        _last: {
          borderBottomRightRadius: '4px',
          borderTopRightRadius: '4px',
          borderRight: `1px solid ${colors.secondary[200]}`,
        },
      },
    },
    settingsPrimary: {
      table: {
        minHeight: '250px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: 'main.400',
        whiteSpace: 'nowrap',
      },
      thead: {
        backgroundColor: colors.primary[100],
        borderBottom: '1px',
        borderColor: 'mystic',
      },
      tbody: {
        tr: {
          _notLast: {
            borderBottom: '1px',
            borderColor: 'mystic',
          },
        },
      },
      th: {
        height: '50px',
        color: 'osloGray',
        fontSize: '14px',
        paddingTop: 0,
        paddingBottom: 0,
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: '16px',
        whiteSpace: 'nowrap',
        textTransform: 'capitalize',
        textAlign: 'left',
      },
      td: {
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: 'darkCharcoal',
        height: '50px',
      },
    },
    // TODO: Better naming and unify all table styles
    phones: {
      table: {
        borderCollapse: 'collapse',
        borderRadius: '13px',

        th: {
          letterSpacing: 'normal',
        },
      },
      thead: {
        height: '42px',
        backgroundColor: colors.primary[100],
      },
      'thead-circle': {
        control: {
          borderRadius: '6px',
          boxShadow: 'none',
        },
      },
      tbody: {
        tr: {
          _notLast: {
            borderBottom: '1px solid',
            borderColor: 'mystic',
          },
        },
      },
      th: {
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '16px',
        color: 'osloGray',
        textAlign: 'left',
      },
      td: {
        textTransform: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '16px',
        color: colors.main[400],
        textAlign: 'left',
      },
    },
    simple: {
      th: {
        textTransform: 'none',
        letterSpacing: 'normal',
        fontSize: '14px',
        fontWeight: 400,
        color: 'silver',
      },
    },
  },
};

export default Table;
