import React, { FC, ReactNode } from 'react';
import { Center, ChakraProps } from '@chakra-ui/react';
import { toPx } from '@app/utils/cssUtils';

interface Props {
  count: ReactNode;
  size?: number;
  empty?: boolean;
  style?: Omit<ChakraProps, never>;
  maxCount?: number;
}

const MAX_MESSAGES_COUNT = 9;
const DEFAULT_CIRCLE_SIZE = 24;

export const UnreadMessageCount: FC<Props> = ({
  count,
  empty,
  size = DEFAULT_CIRCLE_SIZE,
  style,
  maxCount = MAX_MESSAGES_COUNT,
}) => {
  const manyUnread = typeof count === 'number' && count > maxCount;
  const countValue = manyUnread ? `${maxCount}+` : count;

  const mergedStyle = {
    position: 'absolute',
    right: '-5px',
    bottom: '-3px',
    ...style,
  } as Omit<ChakraProps, never>;

  return (
    <Center
      backgroundColor="primary.600"
      borderRadius="100%"
      color="white"
      fontSize="10px"
      fontWeight="500"
      minH={toPx(size)}
      minW={toPx(size)}
      {...mergedStyle}>
      {empty ? '' : countValue}
    </Center>
  );
};
