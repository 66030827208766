import * as Yup from 'yup';

import { phoneNumberValidation } from '@app/components/PhoneNumberInput/validation';
import { createNameValidation } from '@app/schemas/name-schema';

export const addNewContactValidation = Yup.object().shape({
  firstName: createNameValidation('First Name').required(''),
  lastName: createNameValidation('Last Name').required(''),

  phone: phoneNumberValidation,
});
