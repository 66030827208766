import React, { FC, useEffect, useRef } from 'react';
import { DateTime as DT } from 'luxon';

import { TIME_FORMAT_HH_MM_A } from '@app/utils/formatDate';
import {
  getHoursInDay,
  getMinutesInHours,
  getTimeFormat,
} from '../DatePicker/utils/utils';

import { Mode } from '../types';
import { TimeElement } from './TimeElement';
import { SelectWrap, SelectColumn } from './styles';

interface TimePickerProps {
  mode: Mode;
  value: DT;
  disabledHours?: number[];
  disabledMinutes?: number[];
  disabledTimeFormat?: boolean;
  onChange: (value: DT) => void;
}

interface Time {
  hour?: string;
  minute?: string;
  disabled: boolean;
}

export const TimePicker: FC<TimePickerProps> = ({
  mode,
  value,
  disabledHours,
  disabledMinutes,
  disabledTimeFormat = false,
  onChange,
}) => {
  const time = value.toFormat(TIME_FORMAT_HH_MM_A);
  const hourSplit = time.split(':')[0];
  const minuteSplit = time.split(':')[1].split(' ')[0];
  const timeFormatSplit = time.split(' ')[1];
  const hoursRef = useRef<HTMLDivElement>();
  const minutesRef = useRef<HTMLDivElement>();

  const hours = (() => {
    if (disabledHours?.length) {
      const amHours = disabledHours.filter((item) => item < 12);
      const pmHours = disabledHours.filter((item) => item > 11);
      return getHoursInDay().map((item) => {
        let disabled = false;
        if (timeFormatSplit === 'PM') {
          disabled = pmHours.includes(parseInt(item, 10) + 12);
        }
        if (timeFormatSplit === 'AM') {
          disabled = amHours.includes(parseInt(item, 10));
        }
        return { hour: item, disabled };
      });
    }
    return getHoursInDay().map((hour) => ({ hour, disabled: false }));
  })();

  const minutes = (() => {
    if (disabledMinutes?.length) {
      return getMinutesInHours().map((item) => {
        let disabled = false;
        disabled = disabledMinutes.includes(parseInt(item, 10));
        return { minute: item, disabled };
      });
    }
    return getMinutesInHours().map((minute) => ({ minute, disabled: false }));
  })();

  const changeHours = (val: string) => {
    const format = value.toFormat('a');
    let hour = +val;
    if (format === 'PM') {
      hour += 12;
    }
    const settedTime = value.set({ hour });
    onChange(settedTime);
  };

  const changeMinutes = (val: string) => {
    const settedTime = value.set({ minute: +val });
    onChange(settedTime);
  };

  const changeTimeFormat = (val: string) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    let { hour } = value;
    if (val === 'PM' && hour < 12) {
      hour += 12;
    } else if (val === 'AM' && hour > 12) {
      hour -= 12;
    }

    const settedTime = value.set({ hour: +hour });
    onChange(settedTime);
  };

  const scrollTo = (hour: number, minute: number) => {
    const scrollPos = hour > 12 ? (hour - 13) * 32 : (hour - 1) * 32;
    const scrollPostMinutes = (minute - 1) * 32;
    hoursRef?.current?.scrollTo({
      top: scrollPos,
    });
    minutesRef?.current?.scrollTo({
      top: scrollPostMinutes,
    });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { hour, minute } = value;
    scrollTo(hour, minute);
  }, [value]);

  return (
    <SelectWrap mode={mode} role="menu">
      <SelectColumn ref={hoursRef} role="menu">
        {hours.map(({ hour, disabled }: Time) => (
          <TimeElement
            key={`hour-${hour}`}
            disabled={disabled}
            isSelected={hour === hourSplit}
            value={hour}
            onClick={changeHours}
          />
        ))}
      </SelectColumn>
      <SelectColumn ref={minutesRef} role="menu">
        {minutes.map(({ minute, disabled }: Time) => (
          <TimeElement
            key={`minutes-${minute}`}
            disabled={disabled}
            isSelected={minute === minuteSplit}
            value={minute}
            onClick={changeMinutes}
          />
        ))}
      </SelectColumn>
      <SelectColumn role="menu">
        {getTimeFormat().map((format: string) => (
          <TimeElement
            key={`format-${format}`}
            disabled={disabledTimeFormat}
            isSelected={format === timeFormatSplit}
            value={format}
            onClick={changeTimeFormat}
          />
        ))}
      </SelectColumn>
    </SelectWrap>
  );
};
