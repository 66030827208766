import React, { FC, useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  UseDisclosureProps,
  Image,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useBrandRegistrationRoute } from '@app/hooks/useBrandRegistrationRoute';
import Popup from '../Popup';

/**
 *  @deprecated Use the new permission Features & permission modal.
 * */
export const RegisterBrandModal: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();

  const { route } = useBrandRegistrationRoute();

  const goToPage = useCallback(() => {
    onClose();
    navigate(route);
  }, [navigate, onClose, route]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="566px"
      title="Please register your brand"
      onClose={onClose}>
      <Box p="30px 40px 40px">
        <Box mb="25px">
          <Image src="/images/brand_registration_modal.png" />
        </Box>

        <Text textAlign="center" variant="list-style">
          We are asking EVERY customer to register. The process is simple &
          we&apos;re committed to making things as smooth as possible.
        </Text>
        <Flex justifyContent="center" mt="20px">
          <Button variant="primary" w="197px" onClick={goToPage}>
            Complete the form
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};
