import React, {
  FC,
  MutableRefObject,
  SyntheticEvent,
  useCallback,
} from 'react';
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { Label } from '@app/api/gql/generated-types';
import { useLabelsQueryData } from '@app/api/queries/useLabelsQueryData';
import { LabelsCheckbox } from './LabelsCheckbox';

interface LabelViewContainerProps {
  selectedLabels: Label[];
  children: ({ isOpen }: { isOpen: boolean }) => JSX.Element;
  onChange: (labels: Label[]) => void;
  boundaryRef: MutableRefObject<HTMLDivElement>;
}

export const LabelViewContainer: FC<LabelViewContainerProps> = ({
  selectedLabels,
  children,
  onChange,
  boundaryRef,
}) => {
  const { data: labels, fetchNextPage } = useLabelsQueryData();

  const loadMore = useCallback(
    (e: SyntheticEvent) => {
      const isBottomReached =
        Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) >=
        e.currentTarget.scrollHeight;

      if (isBottomReached) {
        fetchNextPage();
      }
    },
    [fetchNextPage],
  );

  return (
    <Popover
      isLazy
      boundary={boundaryRef.current}
      closeOnBlur={true}
      offset={[1, 2]}
      placement="bottom"
      trigger="click">
      {({ isOpen }) => (
        <>
          <PopoverTrigger>
            <Box
              aria-label="Label container"
              role="button"
              tabIndex={0}
              onClick={(event) => event.stopPropagation()}>
              {children({ isOpen })}
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              _focus={{ outline: 'none' }}
              border="1px solid"
              borderColor="secondary.200"
              borderRadius="20px"
              cursor="default"
              maxHeight="332px"
              maxWidth="150px"
              minWidth="fit-content"
              outline="none"
              overflow="hidden"
              paddingRight="2px"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onScrollCapture={loadMore}>
              <LabelsCheckbox
                defaultValues={selectedLabels}
                options={labels as Label[]}
                onChange={onChange}
              />
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
