import { Box } from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { createPaginationVariables } from '@app/api/apollo-pagination';
import {
  Campaign,
  CampaignStatus,
  useFindListCampaignsLazyQuery,
} from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import MultiSelectField from '@app/components/MultiSelectField';
import { SelectedCampaign } from '../../../../types/Export';

interface ExportMultiselectCampaignProps {
  onChange: (items: SelectedCampaign[]) => void;
}

export const ExportMultiselectCampaign: FC<ExportMultiselectCampaignProps> = ({
  onChange,
}) => {
  const [
    getCampaigns,
    {
      data: { findCampaigns } = { findCampaigns: null },
      fetchMore,
      networkStatus,
    },
  ] = useFindListCampaignsLazyQuery({
    variables: {
      filter: {
        status: [
          CampaignStatus.ACTIVE,
          CampaignStatus.PAUSED,
          CampaignStatus.ENDED,
        ],
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const { isLoading } = useApolloLoadingStatus(networkStatus);

  const fetchNextPage = () =>
    fetchMore(createPaginationVariables(findCampaigns));

  const [campaignsState, setCampaignsState] = useState<SelectedCampaign[]>([]);

  const options = useMemo(() => {
    const selectedCampaigns = campaignsState ?? [];
    const campaigns = findCampaigns?.items ?? [];

    if (campaigns?.length < 1) {
      return [];
    }

    // @ts-expect-error Campaign and GroupView circular dependency
    const filteredCampaigns: SelectedCampaign[] = campaigns.filter(
      ({ id }) => !selectedCampaigns?.map((val) => val.id)?.includes(id),
    );

    return [
      ...(selectedCampaigns || []).map((campaign) => ({
        ...campaign,
        isChecked: true,
      })),
      ...filteredCampaigns,
    ];
  }, [campaignsState, findCampaigns?.items]);

  const handleChange = (newValue: readonly SelectedCampaign[]) => {
    const values: SelectedCampaign[] = cloneDeep([...newValue]);
    setCampaignsState(values);
    onChange(values);
  };

  return (
    <Box maxWidth="300px" width="100%">
      <MultiSelectField
        isMulti
        autoFocus={false}
        checkedItemSize={findCampaigns?.total}
        closeMenuOnSelect={false}
        getOptionLabel={(option: Campaign) => option.name}
        getOptionValue={(option) => option.id}
        hideSelectedOptions={false}
        isClearable={false}
        isLoading={isLoading}
        isSearchable={false}
        itemsLength={1}
        maxWidth="75%"
        options={options}
        placeholder="Select"
        value={campaignsState}
        onChange={handleChange}
        onMenuOpen={() => getCampaigns()}
        onMenuScrollToBottom={fetchNextPage}
      />
    </Box>
  );
};
