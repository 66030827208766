import React, { FC } from 'react';
import { PopupModal } from 'react-calendly';
import { UseDisclosureProps } from '@chakra-ui/react';

interface Props {
  isOpen: UseDisclosureProps['isOpen'];
  onClose?: UseDisclosureProps['onClose'];
  url: string;
}

export const CalendlyModal: FC<Props> = ({ isOpen, onClose, url }) => {
  return (
    <PopupModal
      open={isOpen}
      pageSettings={{
        hideEventTypeDetails: false,
        hideGdprBanner: false,
        hideLandingPageDetails: false,
      }}
      rootElement={document.getElementById('root')}
      url={url}
      onModalClose={onClose}
    />
  );
};
