import React, { FC, ReactNode } from 'react';
import { Flex, Text, Box, Grid, GridItem } from '@chakra-ui/react';

interface SettingsLayoutProps {
  titleLabel: string;
  childComponent: ReactNode;
  iconComponent?: ReactNode;
  alignItems?: string;
  marginTop: string;
  mobileAlertText?: string;
}

export const SettingsLayout: FC<SettingsLayoutProps> = ({
  titleLabel,
  childComponent,
  iconComponent,
  marginTop,
  alignItems,
  mobileAlertText,
}) => (
  <Grid alignItems={alignItems} mt={marginTop} templateColumns="212px 1fr">
    <GridItem w="212px">
      <Flex alignItems="center">
        <Text
          color="secondary.400"
          fontSize="14px"
          fontWeight={400}
          whiteSpace="nowrap">
          {titleLabel}
        </Text>
        {iconComponent && (
          <Box ml="5px" mr="20px">
            {iconComponent}
          </Box>
        )}
      </Flex>
    </GridItem>

    <GridItem>
      {childComponent}
      {mobileAlertText && (
        <Text
          as="span"
          color="secondary.400"
          fontSize="14px"
          fontWeight={400}
          ml="15px">
          {mobileAlertText}
        </Text>
      )}
    </GridItem>
  </Grid>
);
