import { Box } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useMemo } from 'react';

import { Campaign, DialerCampaign } from '@app/api/gql/generated-types';
import { CellTextTooltip } from '@app/pages/Contacts/components/TableList/CellTextTooltip';

interface CampaignCellProps {
  campaigns?: Campaign[];
  dialerCampaigns?: DialerCampaign[];
}

export const CampaignCell: FC<CampaignCellProps> = ({
  campaigns,
  dialerCampaigns,
}) => {
  const campaignNames = useMemo(
    () => campaigns?.map((cl) => cl?.name).filter((i) => !!i),
    [campaigns],
  );
  const dialerCampaignNames = useMemo(
    () => dialerCampaigns?.map((cl) => cl?.name).filter((i) => !!i),
    [dialerCampaigns],
  );
  const allCampaigns = campaignNames?.concat(dialerCampaignNames);
  return (
    <Box pr="10px">
      {isEmpty(allCampaigns) ? (
        '-'
      ) : (
        <CellTextTooltip text={allCampaigns.join(', ')} />
      )}
    </Box>
  );
};
