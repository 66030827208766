import { SubscriptionStatus } from '@app/api/gql/generated-types';
import { getMessageSubscription } from '@app/utils/subscriptionHelpers';
import {
  isBasicQuarterlyPlan,
  isBusinessPlan,
  isMonthlyPlan,
} from '@app/utils/enterprisePlanUtils';
import { useCurrentAccountData } from './useCurrentAccountData';

export const useAccountSubscription = () => {
  const account = useCurrentAccountData();

  const messageSubscription = getMessageSubscription(account);

  const isMessageSubscriptionTrialing =
    messageSubscription?.startedAt &&
    messageSubscription?.status === SubscriptionStatus.TRIALING;

  const inUnsubscribed =
    messageSubscription?.status !== SubscriptionStatus.ACTIVE;

  const inUnsubscribedProMonthly =
    inUnsubscribed &&
    isBusinessPlan(messageSubscription?.plan) &&
    isMonthlyPlan(messageSubscription?.plan);

  const inUnsubscribedProQuarterly =
    inUnsubscribed && isBasicQuarterlyPlan(messageSubscription?.plan);

  return {
    inUnsubscribedProMonthly,
    isMessageSubscriptionTrialing,
    inUnsubscribedProQuarterly,
    inUnsubscribed,
  };
};
