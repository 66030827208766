import React from 'react';
import { Text, Link } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';

import ROUTES from '@app/utils/routes';

export const UpdatePlanDialer = () => {
  return (
    <Text width="200px">
      Please{' '}
      <Link as={RouteLink} to={`${ROUTES.settingsMembership}`} variant="link">
        upgrade
      </Link>{' '}
      your Plan to access calls
    </Text>
  );
};
