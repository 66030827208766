import { Flex } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { isMobile } from 'react-device-detect';
import { colors } from '@app/theme/colors';

const SideBarLinkSubMenu = styled(NavLink)`
  font-size: 14px;
  line-height: 16px;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  align-items: flex-start;
  padding: 11px 13px 10px 26px;
  font-weight: 500;
  position: relative;
  color: ${colors.secondary[400]};
  transition: 0.5s;
  margin: 5px 0 5px;

  &.isDisabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &.showCounter {
    justify-content: space-between;
  }

  ${isMobile
    ? `
        padding-left: 25px;
        margin: 4px 20px 4px;`
    : ''}

  p {
    word-break: break-all;
  }

  span {
    border-radius: 9px;
    color: white;
    background-color: ${colors.primary[600]};
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 18px;
    height: 18px;
  }

  &:hover {
    background: ${colors.primary[100]};
    cursor: pointer;
  }

  svg {
    margin-right: 15px;
    width: 20px;
    height: 20px;
  }

  &.centered {
    align-items: center;
  }

  &.active {
    background: ${colors.primary[100]};
    color: ${colors.switchedBlue[500]};

    ${isMobile
      ? `
      background: ${colors.primary[100]};
      border-radius: 20px;`
      : ''}

    path {
      fill: ${colors.switchedBlue[500]};
    }

    #OptedOutIcon path {
      stroke: ${colors.switchedBlue[500]};
      fill: none;
    }

    &::before {
      ${!isMobile
        ? `
            border-radius: 0px 100px 100px 0px;
            background: ${colors.switchedBlue[500]};
            display: block;
            content: '';
            position: absolute;
            left: 0px;
            width: 2px;
            top: 0;
            height: 100%;`
        : ''}
    }

    span {
      border-radius: 9px;
      color: white;
      background-color: ${colors.primary[600]};
      padding: 1px 5px;
    }
  }
`;

const SideBarLinkLabelSubMenu = styled(SideBarLinkSubMenu)`
  &.active {
    path {
      fill: none;
      stroke: ${colors.switchedBlue[500]};
    }
  }
`;

const SideBarLinkGroupSubMenu = styled(SideBarLinkSubMenu)`
  height: auto;
  padding: 12px 12px 12px 25px;
  align-items: flex-start;

  p {
    max-width: max-content;
    white-space: normal;
  }

  &.active {
    height: auto;
    align-items: flex-start;

    &::before {
      border-radius: 0px 20px 20px 0px;
      left: 0px;
      top: 0;
      bottom: 0;
      height: auto;
    }
    circle {
      stroke: ${colors.primary[600]};
    }
  }
`;

const ListItemWrapper = styled(Flex)`
  color: ${colors.secondary[400]};
  padding: 12px 13px 12px 15px;
  height: 40px;
  font-size: 14px;
  margin-left: 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.isOpenAndNotMobile {
    background: ${colors.catskillWhite};
    border-radius: 20px;
  }

  &.isMobile {
    margin-right: 10px;
  }

  &[disabled] {
    pointer-events: none;
    opacity: 0.4;
  }
`;

export {
  SideBarLinkSubMenu,
  ListItemWrapper,
  SideBarLinkLabelSubMenu,
  SideBarLinkGroupSubMenu,
};
