import {
  Box,
  Center,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  Grid,
  Skeleton,
  useDisclosure,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, {
  ChangeEvent,
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Loading } from '@app/components/Table/Skeleton/Loading';
import { Features, PermissionActions, Permissions } from '@app/acl';
import { Export } from '@app/components/Filter/Export';
import FilterByLabel from '@app/components/Filter/FilterByLabel';
import SortBy from '@app/components/Filter/SortBy';
import { messagesSort } from '@app/components/Filter/SortBy/interface';
import { LabelFilterList } from '@app/components/LabelList/LabelFilterList';
import SearchField from '@app/components/SearchField';
import SecondaryUsersList from '@app/components/SecondaryUsersList';
import SideBarWrapper from '@app/components/SideBarMessenger';
import SideBarWrapperMobile from '@app/components/SideBarMessenger/index.mobile';
import { useDisableFeatureAbility } from '@app/hooks/useDisableFeatureAbility';
import { usePermissionAbility } from '@app/hooks/usePermissionAbility';
import { ContactGroupActions } from '@app/pages/Messenger/components/ContactGroupActions';
import { CreateConversation } from '@app/pages/Messenger/content/CreateConversation';
import { EmptyConversationSearchResults } from '@app/pages/Messenger/ui-states/EmptyConversationSearchResults';
import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';
import { getIdFromPath } from '@app/utils/getIdFromPath';
import ROUTES from '@app/utils/routes';
import DatePicker from '@app/widgets/DatePicker';
import { DateRangeType } from '@app/widgets/DatePicker/types';
import {
  ConversationFilter,
  ConversationFragment,
  SubscriptionStatus,
} from '@app/api/gql/generated-types';
import { LoadMessagesButton } from '@app/components/next/moleculas/LoadMessagesButton/LoadMessagesButton';
import { EmptyConversation } from '@app/pages/Messenger/ui-states/EmptyConversation';
import {
  MessengerContextProvider,
  useMessengerContext,
} from '@app/contexts/MessengerContext';
import { TStdSymbol } from '@app/utils/stringUtils';
import { InfiniteLoadingList } from '@app/components/next/organisms/InfiniteLoadingList';
import { toVh } from '@app/utils/cssUtils';
import { colors } from '@app/theme/colors';
import { MessengerFolderType } from '../../types';
import type { EntityType } from '../BreadCrumbs';
import { CreateConversationDialog } from '../CreateConversationDialog';
import { EmptyConversationFilterResult } from '../../ui-states/EmptyConversationFilterResult';
import { useInboxComponentConfiguration } from './useInboxComponentConfiguration';

interface InboxMessagesProps {
  path: string;
  title?: EntityType | string;
  type?: MessengerFolderType;
  queryParams?: ConversationFilter;
}

const InboxMessagesComponent: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { contactId, tabId } = useParams();

  const {
    memoizedValue: {
      path,
      title,
      type,
      queryParams,
      profileId,
      isFetchingNextPage,
      isReady,
      record,
      urlSearchParams,
      reloadMessagesHandlerVisible,
      listScrollToAlignment,
      date,
      labels,
      onListScroll,
      params,
      sort,
      hasNext,
      recordsCount,
      categoryUnread,
      selectedContact: selectedContacts,
      setSort,
      setSelectedContact: setSelectedContacts,
      handleFullReloadConversations,
      handleReloadConversations,
      setSearchValue,
      setLabels,
      setDate,
      fetchNextPage,
    },
  } = useMessengerContext();
  const [recordsList, setRecordsList] = useState<ConversationFragment[]>([]);

  useEffect(() => {
    setRecordsList(
      categoryUnread && record
        ? record?.filter((item) => !item.isRead)
        : record,
    );
  }, [categoryUnread, record]);
  const { can: canUpdateFavorite } = usePermissionAbility(
    PermissionActions.UPDATE,
    Permissions.Favorite,
  );
  const { can: canSubAccounts } = useDisableFeatureAbility(
    Features.SubAccounts,
  );
  const { can: showSubAccounts } = useDisableFeatureAbility(
    Features.ShowSubAccounts,
  );

  const { Item } = useInboxComponentConfiguration();

  const { isImpersonate, isActiveAccount, currentAccount } = useContext(
    CurrentAccountContext,
  );

  useEffect(() => {
    const baseUrl = `${ROUTES.messenger}/${path}`;

    if (profileId && contactId) {
      navigate(
        `${baseUrl}/${getIdFromPath(contactId)}/${tabId}?profileId=${profileId}`,
      );
    } else if (profileId) {
      navigate(`${baseUrl}?profileId=${profileId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, contactId, tabId, path]);

  const onCheckElement = useCallback(
    (
      id: string,
      selected: boolean,
      event: ChangeEvent<HTMLDivElement> | MouseEvent<HTMLDivElement>,
    ) => {
      event.preventDefault();
      event.stopPropagation();

      if (selected) {
        setSelectedContacts((contacts) =>
          contacts.filter((contact) => contact !== id),
        );
        return;
      }
      setSelectedContacts((contacts) => [...contacts, id]);
    },
    [setSelectedContacts],
  );

  const canRemoveContacts = useMemo(
    () =>
      !record
        ?.filter((contact) => selectedContacts.includes(contact.id))
        ?.some((contact) => !contact.canBeRemoved),
    [record, selectedContacts],
  );

  const onToggleCheckedAll = useCallback(() => {
    const allSelected = record.length === selectedContacts.length;

    if (allSelected) {
      setSelectedContacts([]);
    } else {
      const allContactIds = record?.map((contact) => contact.id);
      setSelectedContacts(allContactIds);
    }
  }, [record, selectedContacts, setSelectedContacts]);

  const onItemClick = useCallback(
    (contact: ConversationFragment) => {
      const contactPath = getIdFromPath(contact?.id);

      if (contactId === contact?.id) {
        navigate(`${ROUTES.messenger}/${path}${urlSearchParams}`);
        return;
      }

      navigate(
        `${ROUTES.messenger}/${path}/${contactPath}/messages${urlSearchParams}`,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isImpersonate,
      currentAccount?.settings?.openNewTab,
      contactId,
      navigate,
      path,
      urlSearchParams,
    ],
  );

  const navigateToList = () => {
    if (contactId) {
      let paths: string[] = location.pathname.split(TStdSymbol.SLASH);

      if (tabId) {
        paths = paths.slice(0, paths.length - 2);
      } else {
        paths.pop();
      }
      navigate(paths.join(TStdSymbol.SLASH));
    }
  };

  const handleContactsBatchUpdate = async () => {
    setSelectedContacts([]);
    await handleFullReloadConversations();
    navigateToList();
  };

  const handleContactsBatchDelete = () => {
    setRecordsList(
      recordsList.filter((contact) => !selectedContacts.includes(contact.id)),
    );
    navigateToList();
  };

  const handleSecondaryAccountChange = (selectedAccountId: string | null) => {
    if (selectedAccountId) {
      navigate(`${ROUTES.messenger}/${path}?profileId=${selectedAccountId}`);
    } else {
      navigate(`${ROUTES.messenger}/${path}`);
    }
  };

  const isEmptyFilters = !isEmpty(labels) || !!date?.start || !!profileId;
  const isConversationListEmpty =
    isReady && isEmpty(record) && !isEmptyFilters && !params?.filter?.profileId;
  const isSearchEmpty = !isEmpty(params?.filter?.fullSearch);
  const areFiltersVisible = !isConversationListEmpty;
  const isShowNoResults = isReady && isEmptyFilters && isEmpty(record);
  const loaderVisible = !isReady && isEmpty(record);

  return (
    <>
      <Flex direction="column" position="relative" width="100%">
        {reloadMessagesHandlerVisible && (
          <LoadMessagesButton onClick={handleReloadConversations} />
        )}
        <Flex
          justifyContent={['center', 'space-between']}
          p={['5px 15px 10px 15px', '22px 19px 0px 18px']}>
          <BrowserView>
            <CreateConversation path={path} title={title} onOpen={onOpen}>
              {isOpen && (
                <CreateConversationDialog isOpen={isOpen} onClose={onClose} />
              )}
            </CreateConversation>
          </BrowserView>
          {!(isEmpty(record) && isEmpty(params?.filter?.fullSearch)) ? (
            <SearchField onChange={setSearchValue} />
          ) : (
            <Box h="36px" />
          )}
        </Flex>
        <Flex height="100%" width="100%">
          <Box w="100%">
            <Flex direction="column">
              <BrowserView>
                {areFiltersVisible && (
                  <>
                    <Flex
                      alignItems="flex-start"
                      mb="15px"
                      minHeight="26px"
                      ml="18px"
                      mt="7px"
                      position="relative">
                      <Flex flexDirection="column">
                        {!isEmpty(selectedContacts) ? (
                          <ContactGroupActions
                            canRemoveContacts={canRemoveContacts}
                            handleContactsBatchDelete={
                              handleContactsBatchDelete
                            }
                            isAllSelected={
                              record?.length === selectedContacts?.length
                            }
                            params={params.filter}
                            selectedContacts={selectedContacts}
                            type={type}
                            onContactsBatchUpdate={handleContactsBatchUpdate}
                            onToggleCheckedAll={onToggleCheckedAll}
                          />
                        ) : (
                          <Flex>
                            <Checkbox
                              variant="primary"
                              onChange={onToggleCheckedAll}
                            />
                            <SortBy
                              labels={messagesSort}
                              value={sort}
                              onChange={setSort}
                            />
                            {!queryParams?.labels && (
                              <FilterByLabel
                                value={labels}
                                onChange={setLabels}
                              />
                            )}
                            <DatePicker
                              end={date?.end}
                              start={date?.start}
                              type={DateRangeType.FILTER}
                              onChange={setDate}
                              onClear={() => {
                                setDate({
                                  start: null,
                                  end: null,
                                });
                              }}
                            />
                            {showSubAccounts && (
                              <SecondaryUsersList
                                defaultValue={profileId}
                                isDisabled={!canSubAccounts}
                                onChange={handleSecondaryAccountChange}
                              />
                            )}
                            <Export />
                          </Flex>
                        )}
                        <Collapse animateOpacity in={!isEmpty(labels)}>
                          {!isEmpty(labels) && (
                            <Flex ml="23px" mt="16px">
                              <LabelFilterList
                                isMonochrome
                                labels={labels}
                                visibleItemsCount={Infinity}
                                onChange={setLabels}
                              />
                            </Flex>
                          )}
                        </Collapse>
                      </Flex>
                    </Flex>
                    <Divider />
                  </>
                )}
              </BrowserView>
              <Box
                h="100%"
                height={
                  isMobile
                    ? toVh(100)
                    : [
                        'auto',
                        isEmpty(labels)
                          ? 'calc(100vh - 197px)'
                          : 'calc(100vh - 260px)',
                      ]
                }>
                {loaderVisible ? (
                  <Loading />
                ) : (
                  <Flex
                    flexDirection="column"
                    height="100%"
                    position="relative"
                    width="100%">
                    <InfiniteLoadingList<ConversationFragment>
                      isNextPageLoading={isFetchingNextPage}
                      listLength={recordsCount}
                      loadNextPage={() => {
                        if (hasNext) {
                          fetchNextPage();
                        }

                        return Promise.resolve();
                      }}
                      renderLoading={(
                        key: string,
                        style: React.CSSProperties,
                      ) => (
                        <Box key={key} style={style}>
                          <Grid
                            gap="30px 40px"
                            gridTemplateColumns="repeat(8, 1fr)"
                            m="26px 0"
                            p="0 20px">
                            {Array(8)
                              .fill(0)
                              .map((__, _i) => (
                                <Skeleton
                                  key={`body-row-cell-${_i.toString()}`}
                                  endColor={colors.cultured}
                                  height="14px"
                                  startColor={colors.mystic}
                                  width="100%"
                                />
                              ))}
                          </Grid>
                        </Box>
                      )}
                      renderRow={(item: ConversationFragment) => (
                        <Item
                          key={`row-${item?.id}`}
                          accountId={profileId}
                          canUpdateFavorite={canUpdateFavorite}
                          categoryUnread={categoryUnread}
                          contactId={contactId}
                          contactItem={item}
                          isActive={item?.id === contactId}
                          isChecked={selectedContacts.includes(item?.id)}
                          isInactiveAccount={!isActiveAccount}
                          isPaymentFailure={
                            currentAccount?.subscription?.status ===
                            SubscriptionStatus.PAST_DUE
                          }
                          onClick={onItemClick}
                          onSelectChange={onCheckElement}
                        />
                      )}
                      rowHeight={isMobile ? 81 : 63}
                      rows={recordsList || []}
                      scrollToAlignment={listScrollToAlignment}
                      onScroll={onListScroll}
                    />
                    {(isConversationListEmpty || isShowNoResults) && (
                      <Center height="80vh">
                        {isSearchEmpty || isShowNoResults ? (
                          isEmptyFilters ? (
                            <EmptyConversationFilterResult />
                          ) : (
                            <EmptyConversationSearchResults />
                          )
                        ) : (
                          <EmptyConversation type={type} />
                        )}
                      </Center>
                    )}
                  </Flex>
                )}
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <BrowserView>
        <SideBarWrapper />
      </BrowserView>
      <MobileView>
        <SideBarWrapperMobile />
      </MobileView>
    </>
  );
};

export const InboxMessages = (props: InboxMessagesProps) => (
  <MessengerContextProvider {...props}>
    <InboxMessagesComponent />
  </MessengerContextProvider>
);
