import { Box } from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { createPaginationVariables } from '@app/api/apollo-pagination';
import { GroupView, useFindGroupsQuery } from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import MultiSelectField from '@app/components/MultiSelectField';
import { SelectedGroup } from '../../../../types/Export';

interface ExportMultiselectGroupProps {
  onChange: (items: SelectedGroup[]) => void;
}

export const ExportMultiselectGroup: FC<ExportMultiselectGroupProps> = ({
  onChange,
}) => {
  const {
    data: { findGroups } = {},
    fetchMore,
    networkStatus,
  } = useFindGroupsQuery({
    variables: {
      filter: {
        verified: true,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const { isLoading } = useApolloLoadingStatus(networkStatus);

  const fetchNextPage = () => fetchMore(createPaginationVariables(findGroups));

  const [groupsState, setGroupsState] = useState<SelectedGroup[]>([]);

  const options = useMemo(() => {
    const selectedGroups = groupsState ?? [];
    const groups = findGroups?.items ?? [];
    if (groups?.length < 1) {
      return [];
    }

    // @ts-expect-error Campaign and GroupView circular dependency
    const filteredCampaigns: SelectedGroup[] = groups.filter(
      ({ id }) => !selectedGroups?.map((val) => val.id)?.includes(id),
    );
    // TODO: These types are missing fields that are required
    // .map((group) => ({ ...group, value: group.id, label: group.name }));

    return [
      ...(selectedGroups || []).map((group) => ({
        ...group,
        isChecked: true,
      })),
      ...filteredCampaigns,
    ];
  }, [findGroups?.items, groupsState]);

  const handleChange = (newValue: readonly SelectedGroup[]) => {
    const values: SelectedGroup[] = cloneDeep([...newValue]);
    setGroupsState(values);
    onChange(values);
  };

  return (
    <Box maxWidth="300px" width="100%">
      <MultiSelectField
        isMulti
        autoFocus={false}
        checkedItemSize={findGroups?.total}
        closeMenuOnSelect={false}
        getOptionLabel={(option: GroupView) => option.name}
        getOptionValue={(option) => option.id}
        hideSelectedOptions={false}
        isClearable={false}
        isLoading={isLoading}
        isSearchable={false}
        itemsLength={1}
        maxWidth="75%"
        options={options}
        placeholder="Select"
        value={groupsState}
        onChange={handleChange}
        onMenuScrollToBottom={fetchNextPage}
      />
    </Box>
  );
};
