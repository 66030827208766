import React, { FC, useCallback } from 'react';
import {
  Box,
  Button,
  Center,
  HStack,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import ROUTES from '@app/utils/routes';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';

import { formatNumber } from '../FormatNumber/utils';

interface AddFundsMessageProps {
  onClose: UseDisclosureProps['onClose'];
  isOpenPaymentModal?: boolean;
  isAutoRecharge?: boolean;
  campaignCost?: number;
  onOpenPayment?: () => void;
  onOpenAutoRecharge?: () => void;
}

export const AddFundsMessage: FC<AddFundsMessageProps> = ({
  onClose,
  isOpenPaymentModal = false,
  isAutoRecharge = true,
  onOpenPayment,
  onOpenAutoRecharge,
  campaignCost,
}) => {
  const account = useCurrentAccountData();
  const navigate = useNavigate();

  const isSecondaryAccount = !!account?.isSubAccount;

  const goToPage = useCallback(() => {
    onClose();
    navigate(`${ROUTES.settingsMembership}`);
  }, [navigate, onClose]);

  if (!isAutoRecharge && isOpenPaymentModal) {
    return (
      <Box textAlign="center">
        <HStack alignItems="center" justifyContent="center" mt="15px">
          {isSecondaryAccount ? (
            <Text textAlign="center" variant="heading-fifth">
              To start, please contact your primary account.
            </Text>
          ) : (
            <Text variant="heading-fifth">
              To begin this campaign please have a minimum $
              {formatNumber({ value: campaignCost / 100 })} in{' '}
              <Button
                _hover={{ textDecoration: 'none' }}
                variant="link"
                onClick={onOpenPayment}>
                your wallet
              </Button>{' '}
              and{' '}
              <Button
                _hover={{ textDecoration: 'none' }}
                variant="link"
                onClick={onOpenAutoRecharge}>
                auto-recharge
              </Button>{' '}
              enabled{' '}
            </Text>
          )}
        </HStack>
      </Box>
    );
  }
  if (!isAutoRecharge) {
    return (
      <HStack
        alignItems="center"
        justifyContent="center"
        mt="15px"
        spacing="4px">
        {isSecondaryAccount ? (
          <Text textAlign="center" variant="heading-fifth">
            To start, please contact your primary account.
          </Text>
        ) : (
          <Text variant="heading-fifth">
            To begin this campaign please have{' '}
            <Button
              _hover={{ textDecoration: 'none' }}
              variant="link"
              onClick={onOpenAutoRecharge}>
              auto-recharge
            </Button>{' '}
            enabled
          </Text>
        )}
      </HStack>
    );
  }
  if (isOpenPaymentModal) {
    return (
      <HStack
        alignItems="center"
        justifyContent="center"
        mt="15px"
        spacing="4px">
        {isSecondaryAccount ? (
          <Text textAlign="center" variant="heading-fifth">
            To start, please contact your primary account.
          </Text>
        ) : (
          <Text textAlign="center" variant="heading-fifth">
            To begin this campaign please have a minimum $
            {formatNumber({ value: campaignCost / 100 })} in
            <Button
              _hover={{ textDecoration: 'none' }}
              variant="link"
              onClick={onOpenPayment}>
              your wallet
            </Button>
          </Text>
        )}
      </HStack>
    );
  }

  return (
    <>
      <Text lineHeight="18px" mt="15px" variant="heading-fifth">
        Insufficient funds on your balance. Please add funds in the{' '}
      </Text>
      <Center>
        <Button
          _hover={{ textDecoration: 'none' }}
          mt="5px"
          variant="link"
          onClick={goToPage}>
          Membership page.
        </Button>
      </Center>
    </>
  );
};
