import React from 'react';

import { Box, Flex, FlexProps } from '@chakra-ui/react';
import WarningIcon from '@app/icons/warning-triangle-icon.svg?react';

interface DisclaimerBoxProps extends FlexProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  iconColor?: string;
  iconSize?: string;
  marginTopIcon?: string;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
}

export const DisclaimerBox: React.FC<DisclaimerBoxProps> = ({
  children,
  style,
  iconColor,
  iconSize = '16px',
  icon = WarningIcon,
  marginTopIcon = '2px',
  ...flexChakraProps
}) => {
  const IconComponent = icon;
  return (
    <Flex
      alignItems="flex-start"
      borderRadius="10px"
      color="deepLemon"
      padding="9px 5px 9px 9px"
      style={style}
      {...flexChakraProps}>
      <Box sx={{ marginTop: marginTopIcon }}>
        <IconComponent color={iconColor} height={iconSize} width={iconSize} />
      </Box>
      {children}
    </Flex>
  );
};
