import React, { FC, ReactNode, useMemo } from 'react';
import {
  FormControl,
  FormErrorMessage,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import { FieldHookConfig, useField } from 'formik';

import Label from './Label';

interface FormItemTextAreaProps extends TextareaProps {
  label?: string | ReactNode;
  variant?: string;
  name: string;
  placeholder: string;
}

const FormItemTextArea: FC<FormItemTextAreaProps> = ({
  label,
  variant = 'standard',
  ...props
}) => {
  const { name, ...textareaProps } = props;
  const [field, meta] = useField({ name } as FieldHookConfig<any>);
  const isInvalid = useMemo(() => !!(meta?.touched && meta?.error), [meta]);

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <Label>{label}</Label>}
      <Textarea
        variant={variant}
        {...field}
        {...textareaProps}
        color="main.400"
      />
      {isInvalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormItemTextArea;
