import { FieldPolicy, Reference } from '@apollo/client/cache';

import { DEFAULT_LIMIT } from '@app/api/queries/utils';
import { getLogger } from '@app/utils/logger';
import { OrderPagination } from './gql/generated-types';

const LOG = getLogger('apollo-pagination');

interface ListResult<T> {
  items?: T[];
  hasNext?: boolean | null;
  nextId?: string | null;
  nextCreatedAt?: string | null;
  total?: number | null;
}
interface ListRowResult<T> {
  items: T[];
  hasNext?: boolean | null;
  nextRowIndex?: number | null;
  total?: number | null;
}
interface ListCursorResult<T> {
  items?: T[];
  nextPageToken?: string | null;
  total?: number | null;
}
interface MergeArgs {
  args: {
    pagination?: {
      moveTo: OrderPagination;
      limit: number;
      nextId?: string | null;
      nextCreatedAt?: string | null;
    };
  };
}

interface MergeCursorArgs {
  args: {
    pagination?: {
      limit: number;
      nextPageToken?: string | null;
    };
  };
}

export function cursorPagination<T extends Reference = Reference>(
  keyArgs: FieldPolicy['keyArgs'] = false,
): FieldPolicy<Readonly<ListResult<T>>, ListResult<T>> {
  return {
    keyArgs,
    merge(
      // eslint-disable-next-line @typescript-eslint/default-param-last
      existing = { items: [] },
      incoming,
      { args: { pagination } }: MergeArgs,
    ) {
      if (!pagination) {
        return incoming;
      }

      const incomingIds = incoming?.items?.map((x) => x.__ref) || [];

      LOG.debug('incomingIds', incomingIds);
      LOG.debug('existing.items', existing.items);

      const result = {
        ...incoming,
        items: [
          ...(existing?.items ?? []).filter(
            (item) => !incomingIds.includes(item.__ref),
          ),
        ],
      };

      if (incoming.items?.length) {
        if (pagination.moveTo === OrderPagination.PREV) {
          result.items.unshift(...incoming.items);
        } else {
          result.items.push(...incoming.items);
        }
      }

      return LOG.return(result);
    },
  };
}

export function paginationOverridden<T extends Reference = Reference>(
  keyArgs: FieldPolicy['keyArgs'] = false,
): FieldPolicy<Readonly<ListResult<T>>, ListResult<T>> {
  return {
    keyArgs,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    merge(incoming, existing = { items: [] }) {
      return { ...incoming };
    },
  };
}

export function paginationByCursor<T extends Reference = Reference>(
  keyArgs: FieldPolicy['keyArgs'] = false,
): FieldPolicy<Readonly<ListCursorResult<T>>, ListCursorResult<T>> {
  return {
    keyArgs,
    merge(
      // eslint-disable-next-line @typescript-eslint/default-param-last
      existing = { items: [] },
      incoming,
      { args: { pagination } }: MergeCursorArgs,
    ) {
      if (!pagination) {
        return incoming;
      }

      const incomingIds = incoming?.items?.map((x) => x.__ref) || [];
      const result = {
        ...incoming,
        items: [
          ...(existing?.items ?? []).filter(
            (item) => !incomingIds.includes(item.__ref),
          ),
        ],
      };

      result.items.push(...incoming.items);

      return result;
    },
  };
}

export function createPaginationVariables<T extends ListResult<unknown>>(
  data: T,
  limit = DEFAULT_LIMIT,
) {
  return {
    variables: {
      pagination: {
        moveTo: OrderPagination.NEXT,
        limit,
        nextId: data?.nextId,
        nextCreatedAt: data?.nextCreatedAt,
      },
    },
  };
}

export function createRowPaginationVariables<T extends ListRowResult<unknown>>(
  data: T,
  limit = 25,
) {
  return {
    variables: {
      pagination: {
        limit,
        nextRowIndex: data?.nextRowIndex,
      },
    },
  };
}

export function createCursorPaginationVariables<
  T extends ListCursorResult<unknown>,
>(data: T, limit = 25) {
  return {
    variables: {
      pagination: {
        limit,
        nextPageToken: data?.nextPageToken,
      },
    },
  };
}
