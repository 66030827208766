import { extendTheme } from '@chakra-ui/react';
import { components } from './components';
import { colors } from './colors';

const theme = extendTheme({
  initialColorMode: 'system',
  shadows: {
    outline: 'none',
  },
  colors,
  fontFamily: {
    sans: ['Roboto', 'sans-serif'],
  },
  fonts: {
    heading: "'Roboto', sans-serif",
    body: "'Roboto', sans-serif",
  },
  components,
});

export default theme;
