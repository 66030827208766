import { Enum } from '@app/utils/enum';
import { colors } from '../colors';

export const TInputVariant = new Enum('primary', 'auth', 'search');

const Input = {
  parts: ['field', 'addon'],
  variants: {
    [TInputVariant.enum.primary]: {
      field: {
        border: '1px',
        borderRadius: '22px',
        borderColor: 'secondary.200',
        fontSize: '14px',
        color: 'main.400',
        px: { base: 5, lg: 5 },
        _placeholder: { color: 'secondary.400' },
        _hover: {
          borderColor: 'secondary.300',
        },
        _focus: {
          borderColor: 'primary.700',
          color: 'main.400',
        },
        _disabled: {
          opacity: 1,
          borderColor: 'secondary.200',
          backgroundColor: colors.catskillWhite,
          color: 'main.100',
        },
        _invalid: {
          borderColor: colors.error,
        },
        _value: {
          color: 'red',
        },
      },
    },
    [TInputVariant.enum.auth]: {
      field: {
        border: '1px',
        borderRadius: '24px',
        borderColor: colors.notFound[1000],
        fontSize: '15px',
        height: '48px',
        color: 'darkCharcoal',
        p: '13px 20px',
        _placeholder: { color: 'dustyGray' },
        _hover: {
          borderColor: colors.notFound[400],
        },
        _focus: {
          borderColor: colors.notFound[400],
          color: 'darkCharcoal',
          backgroundColor: 'rgba(79, 140, 237, 0.05)',
        },
        _disabled: {
          borderColor: 'secondary.200',
          backgroundColor: colors.catskillWhite,
          color: 'main.100',
        },
        _invalid: {
          borderColor: colors.error,
        },
        _value: {
          color: 'red',
        },
      },
    },
    [TInputVariant.enum.search]: {
      field: {
        border: `1px solid ${colors.mystic}`,
        borderRadius: '34px',
        fontSize: '14px',
        lineHeight: '16px',
        height: ['40px', '36px'],
        width: '260px',
        color: colors.main[400],
        padding: '10px 40px 10px 20px',
        _placeholder: { color: colors.secondary[400] },
        _hover: {
          borderColor: colors.secondary[300],
        },
        _focus: {
          borderColor: colors.primary[600],
        },
      },
    },
  },
};

export default Input;
