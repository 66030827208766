import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { PhoneFragment } from '@app/utils/phoneNumberUtils';

import { SettingsLayout } from '@app/layouts/SettingsLayout';
import { TrialNumbers } from '../TrialNumbers';
import { BuyNumber } from './BuyNumber';
import { OwnedNumbersTable } from './OwnedNumbersTable';

interface IPhoneNumbersProps {
  hasEnoughPhoneNumbers: boolean;
  requiredUserPhonesNumbers: number;
  refetchUserPhones: () => void;
  ownedNumbers: PhoneFragment[];
  isTrialAccount: boolean;
  showTrialNumbers: boolean;
  isLoadingPhoneNumbers: boolean;
}

export const PhoneNumbers: FC<IPhoneNumbersProps> = ({
  hasEnoughPhoneNumbers,
  requiredUserPhonesNumbers,
  refetchUserPhones,
  ownedNumbers,
  isLoadingPhoneNumbers,
  showTrialNumbers,
  isTrialAccount,
}) => {
  if (showTrialNumbers) {
    return <TrialNumbers />;
  }

  return (
    <>
      {!isTrialAccount && (
        <Flex maxW="644px">
          <SettingsLayout
            childComponent={
              <BuyNumber
                hasEnoughPhoneNumbers={hasEnoughPhoneNumbers}
                refetchUserPhones={refetchUserPhones}
                requiredUserPhonesNumbers={requiredUserPhonesNumbers}
              />
            }
            iconComponent={null}
            marginTop="20px"
            titleLabel="New phone number"
          />
        </Flex>
      )}
      <Flex maxW="644px" mr="40px">
        <SettingsLayout
          alignItems="flex-start"
          childComponent={
            <OwnedNumbersTable
              data={ownedNumbers}
              isFetching={isLoadingPhoneNumbers}
              isTrial={isTrialAccount}
            />
          }
          marginTop="40px"
          titleLabel="Owned numbers"
        />
      </Flex>
    </>
  );
};
