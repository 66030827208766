import { Box, HStack, Text, UseDisclosureProps } from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { FC, useCallback, useState } from 'react';

import { useLocation } from 'react-router-dom';
import uniqueId from 'lodash/uniqueId';
import { ApolloError } from '@apollo/client';
import Popup from '@app/components/Popup';
import { catchErrorLog } from '@app/utils/logger';

import {
  FindOwnPhonesDocument,
  OwnPhoneType,
  useFindAvailablePhonesLazyQuery,
  usePurchaseOwnPhoneMutation,
  Scope,
} from '@app/api/gql/generated-types';
import { LackPhoneNumbersWarning } from '@app/components/next/moleculas/LackPhoneNumbersWarning/LackPhoneNumbersWarning';

import { wordPluralize } from '@app/utils/string';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import ROUTES from '@app/utils/routes';
import { useInitialValues } from './initialValues';
import { PhonesTable } from './PhonesTable';
import { buyNumberSchema } from './schema';
import { SelectPrefix } from './SelectPrefix';
import { SelectType } from './SelectType';
import { SubmitButton } from './SubmitButton';
import { BuyNumberForm, PhoneItem, PhoneItemStatus } from './types';
import { ContactSupportCallToAction } from './ContactSupportCallToAction';

const mapPhoneNumbers = (
  phones: PhoneItem[],
  item: PhoneItem,
  status: PhoneItemStatus,
) => {
  return phones.map((phone) => {
    if (phone.id === item.id) {
      return { ...item, status };
    }
    return phone;
  });
};

interface SearchNumbersModalProps extends UseDisclosureProps {
  hasEnoughPhoneNumbers: boolean;
  refetchUserPhones: () => void;
  requiredUserPhonesNumbers?: number;
  isLocalNumbers?: boolean;
  isBuyDisabled?: boolean;
}

export const SearchNumbersModal: FC<SearchNumbersModalProps> = ({
  isOpen,
  onClose,
  isLocalNumbers = false,
  isBuyDisabled = false,
  hasEnoughPhoneNumbers,
  requiredUserPhonesNumbers,
  refetchUserPhones,
}) => {
  const location = useLocation();
  const scope = location.pathname.match(ROUTES.settingsCallingSetup)
    ? Scope.DIALER
    : Scope.MESSAGE;

  const [phoneNumbers, setPhoneNumbers] = useState<PhoneItem[] | null>();
  const initialValues = useInitialValues(isLocalNumbers, scope);
  const [query, { loading: isLoading }] = useFindAvailablePhonesLazyQuery();
  const { phoneNumberPrice } = useCurrentAccountData(scope);

  const [mutate, { loading: isLoadingBuyNumber }] = usePurchaseOwnPhoneMutation(
    {
      refetchQueries: [FindOwnPhonesDocument],
      context: {
        notify: {
          success: () => 'The phone number was successfully bought.',
          error: (error: ApolloError[]) => error[0].message,
        },
      },
    },
  );

  const onBuyNumber = useCallback(
    async (item: PhoneItem, type: OwnPhoneType) => {
      try {
        setPhoneNumbers(
          mapPhoneNumbers(phoneNumbers, item, PhoneItemStatus.LOADING),
        );
        await mutate({
          variables: {
            input: {
              phone: item.phone,
              type,
              scope,
            },
          },
        });
        setPhoneNumbers(
          mapPhoneNumbers(phoneNumbers, item, PhoneItemStatus.DISALLOW),
        );
      } catch (error) {
        catchErrorLog(error, 'PhonesTable/onBuyNumber');
        setPhoneNumbers(
          mapPhoneNumbers(phoneNumbers, item, PhoneItemStatus.ALLOW),
        );
      } finally {
        void refetchUserPhones();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phoneNumbers, mutate],
  );
  const onSearch = useCallback(
    async (values: BuyNumberForm) => {
      const { data: { findAvailablePhones: phonesData } = {} } = await query({
        variables: {
          filter: {
            type: values.type,
            areaCode: values.areaCode,
          },
        },
      });
      const mappedData: PhoneItem[] =
        phonesData?.map((item) => ({
          phone: item.phone,
          status: PhoneItemStatus.ALLOW,
          id: uniqueId(item.phone),
        })) || [];
      setPhoneNumbers(mappedData);
    },
    [query],
  );

  const onClear = useCallback(() => {
    setPhoneNumbers(null);
  }, []);

  const onModalClose = useCallback(() => {
    onClear();
    onClose();
  }, [onClear, onClose]);

  return (
    <Popup
      closeOnOverlayClick
      isOpen={isOpen}
      maxW="630px"
      title="Search numbers"
      onClose={onModalClose}>
      <Box p="7px 40px 40px">
        <Formik
          initialValues={initialValues}
          validationSchema={buyNumberSchema}
          onSubmit={onSearch}>
          <>
            <HStack alignItems="flex-end" spacing="18px">
              <SelectType isDisabled={true} scope={scope} onClear={onClear} />
              <SelectPrefix
                isDisabled={isLoadingBuyNumber || isBuyDisabled}
                scope={scope}
                onClear={onClear}
              />
              <SubmitButton
                isDisabled={
                  isLoadingBuyNumber ||
                  isBuyDisabled ||
                  phoneNumbers?.length <= 0
                }
                isLoading={isLoading}
                scope={scope}
              />
            </HStack>
            {!hasEnoughPhoneNumbers && scope !== Scope.DIALER && (
              <LackPhoneNumbersWarning
                rounded
                customMessage={
                  <Text color="main.400" variant="secondary-text">
                    To optimize delivery and response rates, you`ll need a
                    minimum of {requiredUserPhonesNumbers} phone{' '}
                    {wordPluralize(requiredUserPhonesNumbers, 'number')} to
                    launch your campaigns.
                  </Text>
                }
              />
            )}
            <PhonesTable
              data={phoneNumbers}
              isDisabled={isBuyDisabled}
              isLoading={isLoadingBuyNumber}
              price={phoneNumberPrice}
              scope={scope}
              onSubmit={onBuyNumber}
            />
          </>
        </Formik>
        <ContactSupportCallToAction />
      </Box>
    </Popup>
  );
};
