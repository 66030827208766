import React, { useEffect, useRef } from 'react';

export function useOutsideClick<T extends HTMLElement>(
  callback: (event: MouseEvent | TouchEvent) => void,
  skipRefs: React.RefObject<HTMLElement>[] = [],
  skipIds: string[] = [],
) {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      const target = event.target as HTMLElement;

      // Check if the click is outside the ref element, not within any of the skipRefs elements, and not within any of the skipIds
      if (
        ref.current &&
        !ref.current.contains(target) &&
        !skipRefs.some((skipRef) => skipRef.current?.contains(target)) &&
        !skipIds.includes(target.id)
      ) {
        callback(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [callback, skipRefs, skipIds]);

  return ref;
}
