import { Td } from '@chakra-ui/react';
import React, { FC } from 'react';

import { FormatNumber } from '@app/components/FormatNumber';

interface SkiptraceInfoProps {
  mobiles: number;
  landlines: number;
}

export const SkiptraceInfo: FC<SkiptraceInfoProps> = ({
  mobiles = 0,
  landlines = 0,
}) => {
  return (
    <>
      <Td>
        <FormatNumber number={mobiles + landlines} />
      </Td>
      <Td>
        <FormatNumber number={mobiles} />
      </Td>
      <Td>
        <FormatNumber number={landlines} />
      </Td>
    </>
  );
};
