import { useAccountBillingSourcesQuery } from '../gql/generated-types';

export const useAccountBillingSources = () => {
  const { data: { account } = {} } = useAccountBillingSourcesQuery({
    notifyOnNetworkStatusChange: true,
  });
  const sources = account?.billingProfile?.sources?.length
    ? account?.billingProfile?.sources
    : [];

  return { sources };
};
