import { useEffect, useState } from 'react';

export function useIsVisible(elementRef: Element) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!elementRef) {
      return;
    }

    if (!(elementRef instanceof Node)) {
      console.warn(
        `useIsVisible expects the elementRef to be an instance of Node`,
      );
      return;
    }

    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );

    observer.observe(elementRef);
    return () => {
      observer.disconnect();
    };
  }, [elementRef]);

  return isIntersecting;
}
