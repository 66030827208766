import React, { FC } from 'react';
import { Box, Text, Grid, Flex, Tooltip } from '@chakra-ui/react';
import InfoToolTipIcon from '@app/icons/info-without-bg-icon.svg?react';
import { FormatNumber } from '@app/components/FormatNumber';
import DateFormat from '@app/components/DateFormat';
import { Skiptrace } from '@app/api/gql/generated-types';
import { colors } from '@app/theme/colors';

interface SkiptracedInfoProps {
  skiptraceData: Skiptrace;
}

export const SkiptracedInfo: FC<SkiptracedInfoProps> = ({ skiptraceData }) => {
  return (
    <Box margin="20px 30px 5px">
      <Grid
        gridGap="30px"
        gridTemplateColumns="repeat(3, minmax(0, 160px))"
        maxWidth="80%"
        minW="520px">
        <Box>
          <Text
            borderBottom={`1px dashed ${colors.secondary[400]}`}
            fontSize="14px"
            padding="15px 0">
            Contacts:
            <strong>{skiptraceData?.foundContacts}</strong>
          </Text>
          <Text
            borderBottom={`1px dashed ${colors.secondary[400]}`}
            fontSize="14px"
            padding="15px 0">
            New contacts:{' '}
            <strong>
              <FormatNumber
                number={
                  skiptraceData?.foundContacts - skiptraceData?.cachedContacts
                }
              />
            </strong>
          </Text>
          <Flex alignItems="center">
            <Text fontSize="14px" padding="15px 8px 15px 0">
              Existing contacts:{' '}
              <strong>{skiptraceData?.cachedContacts}</strong>
            </Text>
            <Tooltip
              hasArrow
              label="These contacts were free of charge because you searched them previously."
              maxW="189px"
              placement="top">
              <Box color="secondary.300">
                <InfoToolTipIcon />
              </Box>
            </Tooltip>
          </Flex>
        </Box>
        <Box>
          <Text
            borderBottom={`1px dashed ${colors.secondary[400]}`}
            fontSize="14px"
            padding="15px 0">
            Phone numbers:{' '}
            <strong>
              <FormatNumber
                number={
                  skiptraceData?.landlineContacts +
                  skiptraceData?.mobileContacts
                }
              />
            </strong>
          </Text>
          <Text
            borderBottom={`1px dashed ${colors.secondary[400]}`}
            fontSize="14px"
            padding="15px 0">
            Mobile: <strong>{skiptraceData?.mobileContacts}</strong>
          </Text>
          <Text fontSize="14px" padding="15px 0">
            Landline: <strong>{skiptraceData?.landlineContacts}</strong>
          </Text>
        </Box>
        <Box>
          <Text
            borderBottom={`1px dashed ${colors.secondary[400]}`}
            fontSize="14px"
            padding="15px 0">
            Created:{' '}
            <strong>
              <DateFormat date={new Date(skiptraceData?.createdAt)} />
            </strong>
          </Text>
          <Text fontSize="14px" padding="15px 0">
            Hitrate:{' '}
            <strong>
              <FormatNumber
                fractionDigits={2}
                number={
                  (skiptraceData?.foundContacts /
                    skiptraceData?.processedContacts) *
                  100
                }
              />
              %
            </strong>
          </Text>
        </Box>
      </Grid>
    </Box>
  );
};
