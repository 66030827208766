import { Box, Button } from '@chakra-ui/react';
import { Form, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import {
  CrmIntegrationOrderBy,
  CrmIntegrationStatus,
  OrderQuery,
  useFindCrmIntegrationsQuery,
} from '@app/api/gql/generated-types';
import Label from '@app/components/FormItem/Label';
import SelectField from '@app/components/SelectField';
import { DEFAULT_PAGE_SIZE } from '@app/constants/configuration';
import {
  ExportFormProps,
  ExportOption,
  SelectedCampaign,
  SelectedGroup,
  SelectedLabel,
  TExportTypes,
  categoryOptions,
} from '../../../../types/Export';
import { ExportItems } from '../ExportItems';

export const ExportForm: FC = () => {
  const { setFieldValue, values, isSubmitting, handleSubmit } =
    useFormikContext<ExportFormProps>();
  const [currentCategory, setCurrentCategory] =
    useState<typeof TExportTypes.type>(null);

  const [currentExportTo, setCurrentExportTo] =
    useState<ExportOption<string> | null>(null);

  const [exportToOptions, setExportToOptions] =
    useState<ExportOption<string>[]>();

  const [searchExportOption, setSearchExportOption] = useState('');

  const { data: { findCrmIntegrations } = {} } = useFindCrmIntegrationsQuery({
    variables: {
      filter: {
        status: CrmIntegrationStatus.ACTIVE,
      },
      pagination: {
        limit: DEFAULT_PAGE_SIZE,
      },
      order: {
        by: CrmIntegrationOrderBy.TYPE,
        direction: OrderQuery.ASC,
      },
    },
  });

  useEffect(() => {
    let validOptions: ExportOption<string>[] = [
      {
        label: 'File',
        value: 'file',
      },
    ];

    if (findCrmIntegrations?.items) {
      const crmOptions = findCrmIntegrations.items.map((app) => ({
        label: app.name,
        value: app.id,
      }));
      validOptions = [...validOptions, ...crmOptions];
    }

    if (currentCategory === TExportTypes.enum.Labels) {
      validOptions.push({
        label: 'Add to DNC',
        value: 'addToDNC',
      });
    }

    void setFieldValue('items', []);
    void setFieldValue('exportTo', '');
    setCurrentExportTo(null);
    setExportToOptions(validOptions);
  }, [findCrmIntegrations, currentCategory, setFieldValue]);

  const handleExportByChange = async (
    field: string,
    option: ExportOption<typeof TExportTypes.type> | null,
  ) => {
    const value = option ? option.value : null;
    await setFieldValue(field, value);
    setCurrentCategory(value);
  };

  const handleExportTo = async (
    field: string,
    option: ExportOption<string> | null,
  ) => {
    const optionFound = exportToOptions.find((e) => e.value === option?.value);
    if (optionFound) {
      setCurrentExportTo(optionFound);
      await setFieldValue(field, optionFound.value);
    } else {
      setCurrentExportTo(null);
      await setFieldValue(field, '');
    }
  };

  const handleCategoryChange = async (
    items: Array<SelectedCampaign | SelectedGroup | SelectedLabel>,
  ) => {
    const ids = items.map((item) => item.id);
    await setFieldValue('items', ids);
  };

  const searchHandler = debounce((query: string) => {
    setSearchExportOption(query);
  }, 50);

  const isButtonDisabled =
    !values.exportBy ||
    !values.items ||
    values.items?.length < 1 ||
    !values.exportTo;

  return (
    <Form>
      <Label>Export by</Label>
      <Box marginBottom="20px">
        <SelectField
          options={categoryOptions}
          placeholder="Select export by"
          onChange={(option) => handleExportByChange('exportBy', option)}
        />
      </Box>

      <Box marginBottom="20px">
        <Label>Export type</Label>
        <ExportItems
          contentType={currentCategory}
          onChange={handleCategoryChange}
        />
      </Box>

      <Box marginBottom="30px">
        <Label>Export to</Label>
        <SelectField
          isClearable
          isSearchable
          disabledCross={true}
          inputValue={searchExportOption}
          options={exportToOptions}
          placeholder="Select export option"
          value={currentExportTo}
          onChange={(option) => handleExportTo('exportTo', option)}
          onInputChange={searchHandler}
        />
      </Box>

      <Box marginBottom="30px">
        <Button
          isDisabled={isButtonDisabled}
          isLoading={isSubmitting}
          variant="primary"
          width="100%"
          onClick={() => handleSubmit()}>
          Export
        </Button>
      </Box>
    </Form>
  );
};
