import { ApolloError, useApolloClient } from '@apollo/client';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React, { FC, useCallback } from 'react';

import {
  FindCallScriptsDocument,
  useRemoveCallScriptMutation,
} from '@app/api/gql/generated-types';
import Popup from '@app/components/Popup';
import { catchErrorLog } from '@app/utils/logger';

interface RemoveModalProps {
  isOpen: boolean;
  onClose: () => void;
  idScript: string | null;
  nameScript: string | null;
}

export const RemoveModal: FC<RemoveModalProps> = ({
  isOpen,
  onClose,
  idScript,
  nameScript,
}) => {
  const client = useApolloClient();
  const [mutation, { loading }] = useRemoveCallScriptMutation({
    refetchQueries: [
      { query: FindCallScriptsDocument, fetchPolicy: 'network-only' },
    ],
  });

  const onRemove = useCallback(async () => {
    try {
      await mutation({
        variables: { removeCallScriptId: idScript },
        context: {
          notify: {
            success: () => 'Call script deleted successfully',
            error: (error: ApolloError[]) => error[0].message,
          },
        },
      });
    } catch (error) {
      catchErrorLog(error, 'handleRemoveCallScript');
    }

    client.cache.evict({ fieldName: 'findCallScripts' });

    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idScript, mutation, onClose]);

  return (
    <Popup
      closeOnOverlayClick
      isOpen={isOpen}
      maxW="374px"
      title="Delete"
      onClose={onClose}>
      <Box p="40px">
        <Text
          color="main.400"
          fontSize="14px"
          fontWeight={400}
          mb="30px"
          textAlign="center">
          Are you sure you want to delete{' '}
          <Box>
            <Text as="span" color="secondary.400">
              {nameScript}
            </Text>{' '}
            call script?
          </Box>
        </Text>
        <Flex flexDirection="row" justifyContent="center">
          <Button mr="20px" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button isLoading={loading} variant="primary" onClick={onRemove}>
            Delete
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};
