export const preparingForSendingPhoneNumber = (phoneNumber: string): string => {
  return `+1${phoneNumber.replace(/[()\-\s]+/g, '')}`;
};

export const cleanPhoneNumberCode = (phone: string) => {
  if (!phone) {
    return phone;
  }
  return phone.replace('+1', '');
};
