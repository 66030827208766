import { useApolloClient } from '@apollo/client';
import {
  MessagingProfile,
  MessagingProfileFragmentDoc,
  PhoneSettings,
  CallSettings,
  ResponseRemoval,
} from '../gql/generated-types';

interface UpdateFragmentInput {
  phoneSettings?: Partial<PhoneSettings>;
  callSettings?: Partial<CallSettings>;
  responseRemoval?: Partial<ResponseRemoval>;
  id: string;
}

export const useUpdateMessagingProfileFragment = () => {
  const client = useApolloClient();
  const updatePhoneSettingsFragment = (value: UpdateFragmentInput) => {
    client.cache.updateFragment<MessagingProfile>(
      {
        id: `MessagingProfile:${value.id}`,
        fragment: MessagingProfileFragmentDoc,
        fragmentName: 'MessagingProfile',
      },
      (data) => ({
        ...data,
        phoneSettings: {
          ...data.phoneSettings,
          ...value.phoneSettings,
        },
      }),
    );
  };
  const updateCallSettingsFragment = (value: UpdateFragmentInput) => {
    client.cache.updateFragment<MessagingProfile>(
      {
        id: `MessagingProfile:${value.id}`,
        fragment: MessagingProfileFragmentDoc,
        fragmentName: 'MessagingProfile',
      },
      (data) => ({
        ...data,
        callSettings: {
          ...data.callSettings,
          ...value.callSettings,
        },
      }),
    );
  };
  const updateResponseRemovalFragment = (value: UpdateFragmentInput) => {
    client.cache.updateFragment<MessagingProfile>(
      {
        id: `MessagingProfile:${value.id}`,
        fragment: MessagingProfileFragmentDoc,
        fragmentName: 'MessagingProfile',
      },
      (data) => ({
        ...data,
        responseRemoval: {
          ...data.responseRemoval,
          ...value.responseRemoval,
        },
      }),
    );
  };

  return {
    updatePhoneSettingsFragment,
    updateCallSettingsFragment,
    updateResponseRemovalFragment,
  };
};
