import React, { FC } from 'react';
import { Box, CloseButton, Flex, Text, useDisclosure } from '@chakra-ui/react';

import MessageIcon from '@app/icons/settings/content-icon.svg?react';
import MemoSvgImageIcon from '@app/utils/svgImageIcon';
import IconButtonEdit from '@app/components/IconButtonEdit';
import { colors } from '@app/theme/colors';

interface ContentPreviewProps {
  onEdit: () => void;
  onDelete: () => void;
  title: string;
  text: string;
  isDeleting: boolean;
}

export const ContentPreview: FC<ContentPreviewProps> = ({
  onEdit,
  onDelete,
  title,
  text,
  isDeleting,
}) => {
  const { onToggle, isOpen } = useDisclosure();

  return (
    <Box
      backgroundColor={colors.blackSqueeze}
      borderRadius="20px"
      width={['100%', '320px']}>
      <Flex
        alignItems="baseline"
        backgroundColor="primary.100"
        borderRadius="20px"
        color="main.300"
        cursor="pointer"
        flexDirection="row"
        fontSize="14px"
        justifyContent="space-between"
        lineHeight="20px"
        marginTop="20px"
        padding="4px 21px 4px 15px">
        <Flex alignItems="center">
          {title}
          <Box
            backgroundColor={isOpen ? 'primary.600' : null}
            borderRadius="20px"
            marginLeft="7px"
            p="3px"
            onClick={onToggle}>
            <MemoSvgImageIcon
              color={isOpen ? 'white' : 'primary.600'}
              svgIcon={MessageIcon}
            />
          </Box>
        </Flex>
        <CloseButton
          _hover={{
            backgroundColor: colors.transparent,
            opacity: '0.6',
          }}
          color="osloGray"
          isDisabled={isDeleting}
          size="sm"
          onClick={onDelete}
        />
      </Flex>
      {isOpen && (
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          p="10px 20px 10px 15px">
          <Text
            color="osloGray"
            flexBasis="80%"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="normal"
            lineHeight="20px">
            {text}
          </Text>
          <IconButtonEdit
            backgroundColor="primary.200"
            color="primary.600"
            marginTop="-4px"
            onClick={onEdit}
          />
        </Flex>
      )}
    </Box>
  );
};
