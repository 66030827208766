import { IconButton, Text, Tooltip } from '@chakra-ui/react';
import React, { FC, useRef } from 'react';
import { useLocation, useNavigate, useMatch } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import RemoveFromDNCIcon from '@app/icons/remove-from-dnc-icon.svg?react';
import AddToDNCIcon from '@app/icons/add-to-dnc-icon.svg?react';
import {
  GetContactDocument,
  SuppressionsSummaryDocument,
  useAddContactToDncMutation,
  useGetContactPhoneLazyQuery,
  useRemoveContactFromDncSuppressionsMutation,
} from '@app/api/gql/generated-types';
import { usePopupsContext } from '@app/hooks/usePopupsContext';
import ROUTES from '@app/utils/routes';
import { getContactNames } from '@app/utils/contact';
import { catchErrorLog } from '@app/utils/logger';
import { removeConversationFromCache } from '@app/pages/Messenger/utils/cacheHelpers';
import PhoneNumberFormat from '@app/shared/ui/PhoneNumberFormat';

interface ToggleContactDNCModalProps {
  contactId: string;
  isBlocked: boolean;
}

const confirmPopupDescription = ({
  fullName,
  phone,
  isBlocked,
}: {
  fullName: string;
  phone: string;
  isBlocked: boolean;
}) => (
  <Text color="main.400" fontSize="14px" mb="25px" textAlign="center">
    {isBlocked
      ? 'Are you sure you want to remove this contact from Do Not Call list?'
      : 'Are you sure you want to add this contact to Do Not Call list?'}
    <br />
    <Text as="span" color="secondary.400">
      {fullName}
    </Text>{' '}
    <PhoneNumberFormat value={phone} />
  </Text>
);

export const ToggleContactDNCModal: FC<ToggleContactDNCModalProps> = ({
  contactId,
  isBlocked,
}) => {
  const navigate = useNavigate();
  const client = useApolloClient();
  const location = useLocation();

  const match = useMatch({ path: ROUTES.messenger, end: false });

  const { showConfirmPopup, closeConfirmPopup } = usePopupsContext();

  const refetchQueries = [
    { query: GetContactDocument, variables: { id: contactId } },
    { query: SuppressionsSummaryDocument },
  ];

  const buttonRef = useRef<HTMLButtonElement>(null);

  const [addContactToDNC, { loading: addToDNCLoading }] =
    useAddContactToDncMutation({
      refetchQueries,
      update(cache) {
        removeConversationFromCache(cache, contactId);
      },
      context: {
        useApolloNetworkStatus: true,
        notify: { success: () => 'Successfully added new contact to DNC list' },
      },
    });

  const [removeContactFromDNC, { loading: removeContactFromDNCLoading }] =
    useRemoveContactFromDncSuppressionsMutation({
      refetchQueries,
      variables: { input: { contactId } },
      context: {
        useApolloNetworkStatus: true,
        notify: { success: () => 'Successfully removed contact from DNC list' },
      },
    });

  const [getContactPhone, { loading: phoneLoading }] =
    useGetContactPhoneLazyQuery();

  const onClose = () => {
    if (!!match) {
      let paths: string[] = location.pathname.split('/');
      paths = paths.slice(0, paths.length - 2);
      navigate(paths.join('/'));
    }
  };

  const handleDNC = async () => {
    buttonRef.current?.blur();

    try {
      const { data: { getContact: contact } = {} } = await getContactPhone({
        variables: { id: contactId },
      });
      const { fullName } = getContactNames(contact);

      await showConfirmPopup({
        title: isBlocked ? 'Remove from DNC list' : 'Add to DNC list',
        description: confirmPopupDescription({
          fullName,
          phone: contact?.phone,
          isBlocked,
        }),
        confirmButtonText: 'Confirm',
      });

      const input = { variables: { input: { contactId } } };
      if (isBlocked) {
        await removeContactFromDNC(input);
      } else {
        await addContactToDNC(input);
      }

      client.cache.evict({ fieldName: 'suppressionsSummary' });
      client.cache.evict({ fieldName: 'findSuppressions' });

      closeConfirmPopup();
      onClose();
    } catch (error) {
      catchErrorLog(error, 'ToggleContactDNCModal/handleDNC');
    }
  };

  return (
    <Tooltip
      hasArrow
      label={
        isBlocked ? 'Remove contact from DNC List' : 'Add contact to DNC List'
      }
      placement="top">
      <IconButton
        ref={buttonRef}
        isRound
        aria-label={
          isBlocked ? 'remove-from-dnc-icon-button' : 'add-to-dnc-icon-button'
        }
        icon={isBlocked ? <RemoveFromDNCIcon /> : <AddToDNCIcon />}
        isLoading={
          addToDNCLoading || removeContactFromDNCLoading || phoneLoading
        }
        size="sm"
        variant="iconButton"
        onClick={handleDNC}
      />
    </Tooltip>
  );
};
