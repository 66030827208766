import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { FirebaseError } from 'firebase/app';
import {
  AuthErrorCodes,
  linkWithCredential,
  PhoneAuthProvider,
  unlink,
} from 'firebase/auth';
import React, { FC, useCallback, useMemo, useReducer, useRef } from 'react';

import PhoneInput from '@app/components/PhoneInput';
import WarningCircleIcon from '@app/icons/warning-circle-icon.svg?react';
import { catchErrorLog } from '@app/utils/logger';

import { useUpdateAccountMutation } from '@app/api/gql/generated-types';
import { PHONE_VERIFICATION_CODE_LENGTH } from '@app/constants/configuration';
import {
  getRecaptchaVerifier,
  RECAPTCHA_NODE_ID,
} from '@app/utils/recaptchaUtils';
import { DisclaimerBox } from '@app/components/next/moleculas/DisclaimerBox';
import { colors } from '@app/theme/colors';
import { usePhoneProvider } from './hooks/usePhoneProvider';
import { initialState, phoneReducer } from './reducer';
import VerifyPhone from './VerifyPhone';

interface PhoneFieldProps {
  canEdit?: boolean;
  isShowConfirmCheckbox?: boolean;
  placeholder?: string;
}

const PhoneField: FC<PhoneFieldProps> = ({
  canEdit = false,
  isShowConfirmCheckbox = false,
  placeholder = '(___) ___-____',
}) => {
  const [state, dispatch] = useReducer(phoneReducer, initialState);
  const [update] = useUpdateAccountMutation();
  const recaptchaWrapperRef = useRef<HTMLDivElement | null>(null);

  const { auth, phoneProvider } = usePhoneProvider();

  const handlePhoneConfirm = useCallback(
    async () => {
      dispatch({ type: 'sentCode', isCodeSent: false });

      const recaptcha = getRecaptchaVerifier(auth);
      const provider = new PhoneAuthProvider(auth);

      try {
        const verifyId = await provider.verifyPhoneNumber(
          `+1${state.phone}`,
          recaptcha,
        );
        dispatch({ type: 'sentCode', isCodeSent: true, verifyId });
      } catch (error) {
        let message = 'Something went wrong';
        if (error instanceof FirebaseError) {
          message = error.message;

          switch (error.code) {
            case AuthErrorCodes.INVALID_PHONE_NUMBER:
              message = 'Invalid phone number';
              break;
            case AuthErrorCodes.INVALID_CODE:
              message = 'Invalid verification code';
              break;
            case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
              message = 'Too many attempts, try again later';
              break;
            default:
              break;
          }
        }
        dispatch({ type: 'onError', error: message });
      } finally {
        recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha-container"></div>`;
        recaptcha.clear();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state],
  );

  const verifyPhoneNumber = async (verifyCodeString: string) => {
    const phoneCredential = PhoneAuthProvider.credential(
      state.verifyId,
      verifyCodeString,
    );
    if (phoneProvider) {
      await unlink(auth.currentUser, phoneProvider.providerId);
    }
    await linkWithCredential(auth.currentUser, phoneCredential)
      .then(async () => {
        await update({
          variables: {
            data: {
              phone: `+1${state.phone}`,
            },
          },
        });
        dispatch({ type: 'setVerified' });
      })
      .catch((err) => {
        let message = 'Something went wrong';
        if (err instanceof FirebaseError) {
          message = err.message;
          switch (err.code) {
            case AuthErrorCodes.NEED_CONFIRMATION:
              message = 'Mobile number already exists in the system.';
              break;
            case AuthErrorCodes.INVALID_CODE:
              message = 'Wrong code. Try again.';
              break;
            default:
              break;
          }
        }
        dispatch({ type: 'onError', error: message });
      });
  };

  const handleResendCode = useCallback(async () => {
    dispatch({ type: 'sentCode', isCodeSent: true });
    try {
      await handlePhoneConfirm();
    } catch (error) {
      catchErrorLog(error, 'VerifyPhone/handleResendCode');
    }
  }, [handlePhoneConfirm]);

  const onConfirmPhone = useCallback(async () => {
    dispatch({ type: 'setLoading' });

    try {
      if (state.verifyId) {
        await handleResendCode();
        return;
      }
      await handlePhoneConfirm();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: 'setLoading' });
    }
  }, [handlePhoneConfirm, handleResendCode, state.verifyId]);

  const onChange = useCallback((phone: string) => {
    dispatch({ type: 'setPhone', phone });
  }, []);

  const errorPhone = useMemo(
    () => state.phone && !state.isCodeSent && state.error,
    [state],
  );
  const errorCode = useMemo(
    () => state.phone && state.isCodeSent && state.error,
    [state],
  );

  return (
    <Flex direction="column">
      <FormControl isInvalid={!!errorPhone}>
        <PhoneInput
          canEdit={canEdit && (state.edit || !!phoneProvider?.phoneNumber)}
          defaultValue={phoneProvider?.phoneNumber}
          isCodeSent={state.isCodeSent}
          isEditPhone={state.edit}
          isLoading={state.isLoading}
          isShowConfirmCheckbox={isShowConfirmCheckbox}
          placeholder={placeholder}
          status={state.status}
          value={state.phone || ''}
          onChange={onChange}
          onConfirm={onConfirmPhone}
        />
        <FormErrorMessage pt="10px">
          <DisclaimerBox
            alignItems="center"
            gap="10px"
            icon={WarningCircleIcon}
            iconColor={colors.error}
            marginTopIcon="0px"
            p="0">
            <Text as="span" color="portlandOrange" mr="10px">
              {state.error}
            </Text>
          </DisclaimerBox>
        </FormErrorMessage>
      </FormControl>

      <div ref={recaptchaWrapperRef}>
        <Box id={RECAPTCHA_NODE_ID} width="100%" />
      </div>
      {state.isCodeSent && !state.verifiedPhone && (
        <FormControl isInvalid={!!errorCode}>
          <VerifyPhone
            dispatch={dispatch}
            verifyCode={state.verifyCode}
            verifyPhoneNumber={verifyPhoneNumber}
          />

          <FormErrorMessage pt="10px">
            <DisclaimerBox
              alignItems="center"
              gap="10px"
              icon={WarningCircleIcon}
              iconColor={colors.error}
              marginTopIcon="0px"
              p="0">
              <Text as="span" color="portlandOrange" mr="10px">
                {state.error}
              </Text>
            </DisclaimerBox>
          </FormErrorMessage>

          {!errorCode && (
            <Text
              color="dustyGray"
              fontSize="12px"
              fontWeight="400"
              id="enter-6-digit-code-text"
              mt="10px"
              textAlign="left">
              Please enter the {PHONE_VERIFICATION_CODE_LENGTH} digit
              verification code we sent you.
            </Text>
          )}
        </FormControl>
      )}
    </Flex>
  );
};

export default PhoneField;
