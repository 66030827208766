import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import MessengerEmptyIcon from '@app/icons/messenger/empty-all.svg?react';
import CampaignsEmptyIcon from '@app/icons/messenger/empty-campaigns.svg?react';
import DeletedEmptyIcon from '@app/icons/messenger/empty-deleted.svg?react';
import GroupsEmptyIcon from '@app/icons/messenger/empty-groups.svg?react';
import LabelsEmptyIcon from '@app/icons/messenger/empty-labels.svg?react';
import OptedOutEmptyIcon from '@app/icons/messenger/empty-opted-out.svg?react';
import SendEmptyIcon from '@app/icons/messenger/empty-send.svg?react';
import UnreadEmptyIcon from '@app/icons/messenger/empty-unread.svg?react';
import MissedCallsIcon from '@app/icons/messenger/empty-missed-calls.svg?react';

import { MessengerFolderType } from '../types';

interface EmptyImageProps {
  type: MessengerFolderType;
}

export const EmptyImage = ({ type }: EmptyImageProps): JSX.Element => {
  switch (type) {
    case MessengerFolderType.ALL:
      return <MessengerEmptyIcon />;
    case MessengerFolderType.SENT:
      return (
        <Box>
          <SendEmptyIcon />
          <Text color="secondary.400" mt="20px" variant="tertiary-title">
            You have no Sent items
          </Text>
        </Box>
      );
    case MessengerFolderType.UNREAD:
      return (
        <Box>
          <UnreadEmptyIcon />
          <Text color="secondary.400" mt="20px" variant="tertiary-title">
            You have no Unread items
          </Text>
        </Box>
      );
    case MessengerFolderType.MISSED_CALLS:
      return (
        <Box>
          <MissedCallsIcon />
          <Text color="secondary.400" mt="20px" variant="tertiary-title">
            You have no Missed calls
          </Text>
        </Box>
      );
    case MessengerFolderType.OPTED_OUT:
      return (
        <Box>
          <OptedOutEmptyIcon />
          <Text color="secondary.400" mt="20px" variant="tertiary-title">
            You have no items in Opted Out tab
          </Text>
        </Box>
      );
    case MessengerFolderType.DELETED:
      return (
        <Box>
          <DeletedEmptyIcon />
          <Text color="secondary.400" mt="20px" variant="tertiary-title">
            You have no Deleted items
          </Text>
        </Box>
      );
    case MessengerFolderType.CAMPAIGNS:
      return (
        <Box>
          <CampaignsEmptyIcon />
          <Text color="secondary.400" mt="20px" variant="tertiary-title">
            You have no items in Campaigns tab
          </Text>
        </Box>
      );
    case MessengerFolderType.GROUPS:
      return (
        <Box>
          <GroupsEmptyIcon />
          <Text color="secondary.400" mt="20px" variant="tertiary-title">
            You have no items in Groups tab
          </Text>
        </Box>
      );
    case MessengerFolderType.LABELS:
      return (
        <Box>
          <LabelsEmptyIcon />
          <Text color="secondary.400" mt="20px" variant="tertiary-title">
            You have no Labelled items
          </Text>
        </Box>
      );

    default:
      return <MessengerEmptyIcon />;
  }
};
