import { Box } from '@chakra-ui/react';
import React, { useRef, useEffect, useMemo } from 'react';
import { VerticalResizerLine } from '@app/components/next/moleculas/VerticalResizerLine';
import { useResizableWidth } from '@app/hooks/useResizableWidth';
import { toPx } from '@app/utils/cssUtils';

interface IResizableBoxProps {
  storageKey?: string;
  minWidth?: number;
  maxWidth?: number;
  style?: React.CSSProperties;
  visible?: boolean;
  children: React.ReactNode;
  enabled?: boolean;
}

export const ResizableBox: React.FC<IResizableBoxProps> = ({
  storageKey,
  visible = true,
  maxWidth,
  minWidth,
  style,
  enabled = true,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width, mouseDownHandler } = useResizableWidth({
    ref,
    storageKey,
    minWidth,
    maxWidth,
  });

  useEffect(() => {
    if (enabled && ref.current) {
      ref.current.style.right = visible ? '0' : toPx(width * -1);
    }
  }, [visible, width, enabled]);

  const containerWidth = useMemo(() => {
    return visible ? (enabled ? toPx(width) : minWidth) : 0;
  }, [visible, width, enabled, minWidth]);

  return (
    <Box
      ref={ref}
      height="full"
      minWidth={containerWidth}
      position="relative"
      style={{ ...style, right: visible ? 0 : toPx(width * -1) }}
      width={containerWidth}>
      {visible && enabled && (
        <VerticalResizerLine handleMouseDown={mouseDownHandler} />
      )}
      {children}
    </Box>
  );
};
