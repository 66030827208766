import React, { FC } from 'react';
import { Flex, Skeleton, Center } from '@chakra-ui/react';
import SCLogo from '@app/icons/LogoSmarterContactIcon.svg?react';
import { BackgroundContainer } from '@app/layouts/BackgroundContainer';

const SkeletonBox: FC<{
  width?: number | string;
  height?: string;
  mb?: string;
}> = ({ width = '380px', height = '40px', mb = '34px' }) => (
  <Skeleton
    borderRadius="100px"
    endColor="cultured"
    height={height}
    mb={mb}
    startColor="mystic"
    width={width}
  />
);

export const AuthSkeleton: FC = () => (
  <BackgroundContainer>
    <Flex alignItems="center" justify="space-between" margin="30px 55px auto">
      <SCLogo />
      <SkeletonBox mb="unset" width="100px" />
    </Flex>

    <Center flexDirection="column" height="100%" margin="0 auto" maxW="380px">
      <SkeletonBox />

      <SkeletonBox />

      <SkeletonBox height="20px" width="311px" />

      <SkeletonBox />

      <SkeletonBox height="1px" />

      <SkeletonBox />

      <SkeletonBox />
    </Center>
  </BackgroundContainer>
);
