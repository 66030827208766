import { BoxProps, Flex, Icon } from '@chakra-ui/react';
import React, { FC, MouseEvent } from 'react';

import { UpdateContactMutationVariables } from '@app/api/gql/generated-types';
import StarIcon from '@app/icons/star-icon.svg?react';
import UnstarIcon from '@app/icons/unstar-icon.svg?react';

interface FavoriteCellProps extends BoxProps {
  id: string;
  isFavorite: boolean;
  onChangeFavorite: (value: UpdateContactMutationVariables) => Promise<void>;
  canUpdate?: boolean;
}

export const FavoriteCell: FC<FavoriteCellProps> = ({
  id,
  isFavorite,
  onChangeFavorite,
  canUpdate = true,
  ...props
}) => {
  const handleSetFavorite = async (event: MouseEvent<HTMLElement>) => {
    if (!canUpdate) {
      return;
    }
    event.stopPropagation();
    await onChangeFavorite({
      id,
      input: {
        favorite: !isFavorite,
      },
    });
  };

  return (
    <Flex
      _hover={{ opacity: 0.6 }}
      alignItems="center"
      cursor={canUpdate ? 'pointer' : 'default'}
      p="5px"
      transition="all 0.1s linear"
      onClick={handleSetFavorite}
      {...props}>
      {isFavorite ? <Icon as={StarIcon} /> : <Icon as={UnstarIcon} />}
    </Flex>
  );
};
