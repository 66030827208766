import React, { FC } from 'react';
import { Button, Center, Box, Text } from '@chakra-ui/react';

import Popup from '@app/components/Popup';

interface ChoosePhoneProps {
  isOpen: boolean;
  onClose: () => void;
  headers: string[];
}

export const ChoosePhone: FC<ChoosePhoneProps> = ({
  isOpen,
  onClose,
  headers,
}) => (
  <Popup
    closeOnOverlayClick
    isOpen={isOpen}
    title="Select headers"
    width="350px"
    onClose={onClose}>
    <Box margin="25px 40px 40px">
      <Text color="main.400" fontSize="14px" textAlign="center">
        Please specify {headers.sort().join(', ')} to add a contact list.
      </Text>
      <Center mt="30px">
        <Button height="40px" variant="primary" width="160px" onClick={onClose}>
          Close
        </Button>
      </Center>
    </Box>
  </Popup>
);
