import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { isSubmitDisabled } from '@app/utils/validation';

export const SubmitForm: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const formik = useFormikContext();
  const isFormReady = isSubmitDisabled(formik, false, []);

  return (
    <Button
      isDisabled={isFormReady}
      isLoading={isLoading}
      loadingText="&#43; Add new contact"
      m="20px 0 40px 0"
      variant="primary"
      width="100%"
      onClick={() => formik.handleSubmit()}>
      &#43; Add new contact
    </Button>
  );
};
