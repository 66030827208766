import {
  Box,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { useCallback, useMemo, useRef } from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';

import { CallScript } from '@app/api/gql/generated-types';
import { useMentionFields } from '@app/hooks/useMentionFields';
import {
  CUSTOM_MENTION_DROPDOWN_STYLES,
  TRIGGER,
} from '@app/utils/mentionStyles';
import { colors } from '@app/theme/colors';

const MentionField = chakra(MentionsInput, {
  baseStyle: (props) => ({
    textarea: {
      border: '1px solid',
      _placeholder: {
        color: 'secondary.400',
      },
      borderColor: props?.borderColor ?? 'secondary.200',
      overflowY: 'auto!important',
      '&:focus-visible ': {
        outline: 0,
        border: `1px solid ${colors.primary[600]}`,
      },
    },
  }),
});

function renderSuggestion(suggestion: SuggestionDataItem) {
  return <span>{suggestion.display}</span>;
}

export const ScriptTemplateContent = () => {
  const ref = useRef<HTMLTextAreaElement>();
  const { fields } = useMentionFields();
  const { values, touched, errors, setFieldValue, handleBlur } =
    useFormikContext<CallScript>();

  const isInvalid = useMemo(
    () => !!(touched?.content && errors?.content),
    [errors?.content, touched?.content],
  );

  const onChangeContent = useCallback(
    async (_: unknown, value: string) => {
      await setFieldValue('content', value.trimStart());
    },
    [setFieldValue],
  );

  return (
    <FormControl isInvalid={isInvalid} maxW="695px" mt="20px">
      <>
        <MentionField
          borderColor={
            touched?.content && errors?.content ? 'error' : 'secondary.200'
          }
          height="300px"
          inputRef={ref}
          name="content"
          placeholder="Script text..."
          style={CUSTOM_MENTION_DROPDOWN_STYLES}
          value={values.content}
          onBlur={handleBlur}
          onChange={onChangeContent}>
          <Mention
            appendSpaceOnAdd
            data={fields}
            displayTransform={(display: string) => `<${display}>`}
            markup="<__display__>"
            renderSuggestion={renderSuggestion}
            trigger={TRIGGER}
          />
        </MentionField>
        {isInvalid && <FormErrorMessage>{errors?.content}</FormErrorMessage>}
        <Flex
          alignItems="center"
          color="secondary.400"
          direction="row"
          fontSize="12px"
          fontWeight={700}
          justifyContent="space-between"
          mt="5px">
          <Box fontWeight={500}>For custom fields use &#34;{'<'}&#34;</Box>
        </Flex>
      </>
    </FormControl>
  );
};
