import React, { FC, useEffect, useState, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Box } from '@chakra-ui/react';

import { StripeForm } from '@app/pages/Settings/content/Membership/components/StripeForm';
import Popup from '@app/components/Popup';
import { stripeElementsOptions } from '@app/types/Stripe';
import { useAccountBillingSources } from '@app/api/queries/useAccountBillingSources';
import { UserInfoCard } from '@app/api/queries/types';

interface AddNewCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  openPaymentModalStep?: () => void;
  onConfirm?: (userInfoCard: UserInfoCard) => void;
}
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_API_KEY);

export const AddNewCardModal: FC<AddNewCardModalProps> = ({
  isOpen,
  onClose,
  openPaymentModalStep,
  onConfirm,
}) => {
  const [newPaymentSourceId, setNewPaymentSourceId] = useState<string>();
  const { sources } = useAccountBillingSources();

  const onOpenPaymentStep = () => {
    if (openPaymentModalStep) {
      openPaymentModalStep();
    }
    onClose();
  };

  const selectedPayment = useMemo(
    () => sources.find((s) => s.id === newPaymentSourceId),
    [sources, newPaymentSourceId],
  );

  useEffect(() => {
    if (onConfirm && selectedPayment) {
      onConfirm({
        last4: selectedPayment?.last4,
        brand: selectedPayment?.brand,
        id: selectedPayment?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPayment]);

  return (
    <Popup isOpen={isOpen} title="" width="420px" onClose={onClose}>
      <Box margin="35px 40px">
        <Elements options={stripeElementsOptions} stripe={stripePromise}>
          <StripeForm
            isModal
            title="Add new card"
            onConfirm={setNewPaymentSourceId}
            onModalClose={onOpenPaymentStep}
          />
        </Elements>
      </Box>
    </Popup>
  );
};
