import React from 'react';

import { TimeElementStyles } from './styles';

interface TimeElementProps {
  value: string;
  disabled?: boolean;
  onClick: (value: string) => void;
  isSelected: boolean;
}

export const TimeElement: React.FC<TimeElementProps> = ({
  value,
  disabled,
  onClick,
  isSelected,
}: TimeElementProps) => {
  const handleClick = () => {
    if (!disabled) onClick(value);
  };

  return (
    <TimeElementStyles
      disabled={disabled}
      isSelected={isSelected}
      role="menuitem"
      tabIndex={Number(value) ? Number(value) : 0}
      onClick={handleClick}
      onKeyPress={handleClick}>
      {value}
    </TimeElementStyles>
  );
};
