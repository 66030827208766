import React, { FC } from 'react';

import { ConversationCategory } from '@app/api/gql/generated-types';
import { InboxMessages } from '../components/InboxMessages';
import { MessengerFolderType } from '../types';

const InboxDeleted: FC = () => {
  return (
    <InboxMessages
      path="inbox/deleted"
      queryParams={{
        category: ConversationCategory.DELETED,
      }}
      title="Deleted"
      type={MessengerFolderType.DELETED}
    />
  );
};

export default InboxDeleted;
