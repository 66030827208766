import React, { FC } from 'react';
import { DateTime as DT } from 'luxon';

import { CurrentPeriodStyle } from '../styles';

interface BodyItemTemplateTypes {
  cursorDate: DT | null;
  mode?: string;
}

const CurrentPeriod: FC<BodyItemTemplateTypes> = ({ cursorDate }) => {
  return (
    <CurrentPeriodStyle>{cursorDate?.toFormat('MMMM yyyy')}</CurrentPeriodStyle>
  );
};

export default CurrentPeriod;
