import {
  MarketingPopupStatus,
  MarketingPopupTemplateType,
  MarketingPopupAudience,
} from '@app/api/gql/generated-types';

type TStatusOption = {
  label: string;
  value: MarketingPopupStatus;
};

type TAudienceOption = {
  label: string;
  value: MarketingPopupAudience;
};

type TTemplateOption = {
  label: string;
  value: MarketingPopupTemplateType;
};

export const statusOptions: TStatusOption[] = [
  { label: 'Inactive', value: MarketingPopupStatus.INACTIVE },
  { label: 'Active', value: MarketingPopupStatus.ACTIVE },
];

export const audienceOptions: TAudienceOption[] = [
  { label: 'All', value: MarketingPopupAudience.ALL },
  {
    label: 'Active messaging plan',
    value: MarketingPopupAudience.ACTIVE_MESSAGING,
  },
  {
    label: 'Trial messaging plan',
    value: MarketingPopupAudience.TRIAL_MESSAGING,
  },
  {
    label: 'Pending cancellation messaging plan',
    value: MarketingPopupAudience.PENDING_CANCELLATION_MESSAGING,
  },
  {
    label: 'Cancelled messaging plan',
    value: MarketingPopupAudience.CANCELLED_MESSAGING,
  },
  {
    label: 'Past due messaging plan',
    value: MarketingPopupAudience.PAST_DUE_MESSAGING,
  },
  {
    label: 'Incomplete messaging plan',
    value: MarketingPopupAudience.INCOMPLETE_MESSAGING,
  },
  {
    label: 'Active calling plan',
    value: MarketingPopupAudience.ACTIVE_DIALER,
  },
  {
    label: 'Pending cancellation calling plan',
    value: MarketingPopupAudience.PENDING_CANCELLATION_DIALER,
  },
  {
    label: 'Cancelled calling plan',
    value: MarketingPopupAudience.CANCELLED_DIALER,
  },
  {
    label: 'Past due calling plan',
    value: MarketingPopupAudience.PAST_DUE_DIALER,
  },
  { label: 'Affiliates', value: MarketingPopupAudience.AFFILIATES },
  { label: 'Skiptrace Used', value: MarketingPopupAudience.SKIPTRACE_USED },
  { label: 'Test users', value: MarketingPopupAudience.TEST },
];

export const audienceOptionsMap = {
  [MarketingPopupAudience.ALL]: 'All',
  [MarketingPopupAudience.ACTIVE_MESSAGING]: 'Active messaging plan',
  [MarketingPopupAudience.TRIAL_MESSAGING]: 'Trial messaging plan',
  [MarketingPopupAudience.PENDING_CANCELLATION_MESSAGING]:
    'Pending cancellation messaging plan',
  [MarketingPopupAudience.CANCELLED_MESSAGING]: 'Cancelled messaging plan',
  [MarketingPopupAudience.PAST_DUE_MESSAGING]: 'Past due messaging plan',
  [MarketingPopupAudience.INCOMPLETE_MESSAGING]: 'Incomplete messaging plan',
  [MarketingPopupAudience.AFFILIATES]: 'Affiliates',
  [MarketingPopupAudience.SKIPTRACE_USED]: 'Skiptrace Used',
  [MarketingPopupAudience.TEST]: 'Test users',
  [MarketingPopupAudience.ACTIVE_DIALER]: 'Active calling plan',
  [MarketingPopupAudience.PENDING_CANCELLATION_DIALER]:
    'Pending cancellation calling plan',
  [MarketingPopupAudience.CANCELLED_DIALER]: 'Cancelled calling plan',
  [MarketingPopupAudience.PAST_DUE_DIALER]: 'Past due calling plan',
};

export const templateOptions: TTemplateOption[] = [
  { label: 'Image', value: MarketingPopupTemplateType.IMAGE },
  {
    label: 'Image + Button',
    value: MarketingPopupTemplateType.IMAGE_WITH_BUTTON,
  },
  { label: 'Video', value: MarketingPopupTemplateType.VIDEO },
  {
    label: 'Video + Button',
    value: MarketingPopupTemplateType.VIDEO_WITH_BUTTON,
  },
];
