import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { InboxMessages } from '../components/InboxMessages';
import { MessengerFolderType } from '../types';

const CampaignMessages: FC = () => {
  const { campaignId } = useParams<{ campaignId: string }>();

  return (
    <InboxMessages
      path={`inbox/campaigns/${campaignId}`}
      queryParams={{
        campaigns: [campaignId],
      }}
      title="Campaigns"
      type={MessengerFolderType.CAMPAIGNS}
    />
  );
};

export default CampaignMessages;
