import { colors } from '../colors';

const Select = {
  parts: ['field', 'icon'],
  variants: {
    settings: {
      field: {
        border: `1px solid ${colors.secondary[200]}`,
        borderRadius: '20px',
        paddingLeft: '20px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: colors.baliHai,
      },
    },
  },
};

export default Select;
