import React, { FC, ReactNode } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';

import Popup from '@app/components/Popup';
import { useNetworkStatus } from '@app/hooks/useNetworkStatus';

interface ConfirmProps {
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  handler: () => void;
  confirmText: string;
  description: ReactNode;
  verticalActions?: boolean;
  hideCancelButton?: boolean;
  padding?: string;
  maxWidth?: string;
  confirmButtonWidth?: string;
  cancelButtonWidth?: string;
}

const ConfirmAlert: FC<ConfirmProps> = ({
  title,
  isOpen,
  onClose,
  handler,
  confirmText,
  description,
  verticalActions = false,
  hideCancelButton = false,
  padding = '30px',
  maxWidth = '374px',
  cancelButtonWidth = '100%',
  confirmButtonWidth = '100%',
}) => {
  const { status } = useNetworkStatus();

  return (
    <Popup
      closeOnOverlayClick
      isOpen={isOpen}
      maxW={maxWidth}
      title={title}
      onClose={onClose}>
      <Box margin="0 auto" p={padding} w="full">
        {description}
        <Flex direction="row" justifyContent="center">
          <Flex
            alignItems={verticalActions ? 'center' : 'initial'}
            flexDirection={verticalActions ? 'column-reverse' : 'row'}
            justifyContent="center"
            w={verticalActions && '100%'}>
            {!hideCancelButton && (
              <Button
                variant={verticalActions ? 'cancel' : 'outlined'}
                width={cancelButtonWidth}
                onClick={onClose}>
                Cancel
              </Button>
            )}
            <Button
              isLoading={status?.numPendingMutations > 0}
              ml={!(verticalActions || hideCancelButton) && '20px'}
              variant="primary"
              width={confirmButtonWidth}
              onClick={handler}>
              {confirmText}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Popup>
  );
};

export default ConfirmAlert;
