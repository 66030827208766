import React, {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, Flex, Input, Text } from '@chakra-ui/react';
import { TInputVariant } from '@app/theme/components/input';
import { TTextVariant } from '@app/theme/components/text';
import { TButtonKey } from '@app/types/DomEvent';
import WarningCircleIcon from '@app/icons/warning-circle-icon.svg?react';
import { PHONE_VERIFICATION_CODE_LENGTH } from '@app/constants/configuration';
import { isNumeric } from '@app/utils/string';
import { colors } from '@app/theme/colors';
import { DisclaimerBox } from '../../moleculas/DisclaimerBox';

const VERIFICATION_INPUTS_COUNT = 3;
const VERIFICATION_INPUT_VALUE_LENGTH = 2;

interface IShortCodeVerificationInputProps {
  placeholder?: string;
  onVerifyCode?: (code: string) => void;
  error?: string;
  loading?: boolean;
  codeVerificationId?: string;
}

export const ShortCodeVerificationInput: React.FC<
  IShortCodeVerificationInputProps
> = ({
  placeholder = 'XX',
  onVerifyCode,
  error,
  loading,
  codeVerificationId,
}) => {
  const [code, setCode] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState(error);

  useEffect(() => {
    if (loading) {
      setErrorMessage('');
    } else {
      setErrorMessage(error);
      setCode([]);
    }
  }, [error, loading, codeVerificationId]);

  const handleVerifyCodeChange = useCallback(
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const newVerifyCode = [...code];
      newVerifyCode[index] = e.target.value;

      if (e.target.value.length === VERIFICATION_INPUT_VALUE_LENGTH) {
        const nextElement = e.target.nextElementSibling as HTMLInputElement;

        if (nextElement) {
          nextElement.focus();
        } else {
          e.target.blur();
        }
      }

      setCode(newVerifyCode);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [code],
  );

  const handleCodeKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    if (
      (
        [TButtonKey.enum.Backspace, TButtonKey.enum.Delete] as string[]
      ).includes(event.key) &&
      target.value.length === 0
    ) {
      const prevElement = target.previousElementSibling as HTMLInputElement;

      if (prevElement) {
        prevElement.focus();
      }
    }
  };

  const handleCodeKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!isNumeric(event.key)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const validInputsCount = code.filter(
      (c) => c?.length === VERIFICATION_INPUT_VALUE_LENGTH,
    )?.length;

    if (validInputsCount === VERIFICATION_INPUTS_COUNT) {
      onVerifyCode?.(code.join(''));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <Box>
      <Flex
        direction="row"
        gap="20px"
        justifyContent="space-between"
        width="full">
        {Array(VERIFICATION_INPUTS_COUNT)
          .fill(0)
          .map((_, i) => (
            <Input
              key={i}
              autoComplete="off"
              border="1px"
              borderColor={error ? 'error' : 'secondary.200'}
              borderRadius="24px"
              disabled={loading}
              height="48px"
              id={`phone-verify-${i + 1}-input`}
              maxLength={VERIFICATION_INPUT_VALUE_LENGTH}
              placeholder={placeholder}
              style={{
                pointerEvents: loading ? 'none' : 'auto',
              }}
              textAlign="center"
              transition="max-height .3s"
              value={code[i] || ''}
              variant={TInputVariant.enum.auth}
              onChange={handleVerifyCodeChange(i)}
              onKeyDown={handleCodeKeyDown}
              onKeyPress={handleCodeKeyPress}
            />
          ))}
      </Flex>
      <Box mt="8px">
        {errorMessage ? (
          <DisclaimerBox
            alignItems="center"
            icon={WarningCircleIcon}
            iconColor={colors.error}
            p="0">
            <Text
              color="error"
              ml="10px"
              variant={TTextVariant.enum['dialog-text-silver']}>
              {errorMessage}
            </Text>
          </DisclaimerBox>
        ) : (
          <Text
            color="osloGray"
            variant={TTextVariant.enum['dialog-text-silver']}>
            Please enter the {PHONE_VERIFICATION_CODE_LENGTH} digit verification
            code we sent you
          </Text>
        )}
      </Box>
    </Box>
  );
};
