import React, { useState, FC, createRef, useEffect } from 'react';
import {
  Flex,
  Input,
  Box,
  FormErrorMessage,
  FormControl,
  IconButton,
} from '@chakra-ui/react';

import EditTextIcon from '@app/icons/edit-text-icon.svg?react';
import { entityNameSchema } from '@app/schemas/entity-name-schema';
import { useYupValidation } from '@app/hooks/useYupValidation';
import { useUpdateSkiptraceMutation } from '@app/api/gql/generated-types';
import { colors } from '@app/theme/colors';

interface HeaderProps {
  name: string;
  updateSkiptraceId: string;
  excludeFirstRow: boolean;
}

const Header: FC<HeaderProps> = ({
  updateSkiptraceId,
  name,
  excludeFirstRow,
}) => {
  const ref = createRef<HTMLInputElement>();
  const [entityName, setEntityName] = useState(name);
  const [isEdit, setIsEdit] = useState(false);

  const [updateSkiptraceMutation, { loading }] = useUpdateSkiptraceMutation();

  const { errors } = useYupValidation(entityName, entityNameSchema);

  useEffect(() => {
    setEntityName(name);
  }, [name]);

  const handleBlur = async () => {
    try {
      if (errors.entityName) {
        setEntityName(name);
        throw new Error(errors.entityName);
      }
      if (name !== entityName) {
        await updateSkiptraceMutation({
          variables: {
            updateSkiptraceId,
            input: {
              fileName: entityName,
              excludeFirstRow,
            },
          },
        });
        setIsEdit(false);
      }
    } catch (err) {
      console.error('🚀 ~ handleBlur ~ err', err);
    } finally {
      setIsEdit(false);
    }
  };

  const handleInputFocus = () => {
    setIsEdit(true);
    ref.current.focus();
  };

  return (
    <FormControl
      isInvalid={!!errors.entityName}
      margin="20px 0"
      pl="30px"
      pr="0"
      width="min-content">
      <Flex alignItems="center" flexDirection="row" position="relative">
        <Box
          fontSize="18px"
          fontWeight="500"
          height="2.5rem"
          visibility="hidden"
          whiteSpace="pre">
          {entityName}
        </Box>
        <Input
          ref={ref}
          _focus={{ shadow: 'none' }}
          _focusVisible={{ shadow: 'outline' }}
          border="none"
          color="main.400"
          errorBorderColor={colors.transparent}
          fontSize="18px"
          fontWeight="500"
          isDisabled={loading}
          left="0"
          p="0"
          position="absolute"
          top="0"
          value={entityName}
          width="100%"
          onBlur={handleBlur}
          onChange={(event) => setEntityName(event.target.value)}
          onFocus={handleInputFocus}
        />
        <IconButton
          _hover={{ color: 'primary.600' }}
          aria-label="Edit contact list name"
          color="main.100"
          ml="15px"
          variant="ghost"
          onClick={handleInputFocus}>
          <EditTextIcon />
        </IconButton>
      </Flex>
      {isEdit && errors.entityName && (
        <FormErrorMessage minWidth="230px">
          {errors.entityName}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default Header;
