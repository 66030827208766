import { PhoneSettingsVoiceType } from '@app/api/gql/generated-types';

export const voiceTypeOptions = [
  {
    label: 'Male voice',
    value: PhoneSettingsVoiceType.MALE,
  },
  {
    label: 'Female voice',
    value: PhoneSettingsVoiceType.FEMALE,
  },
];

export const voiceTypeMap: {
  [key in PhoneSettingsVoiceType]: string;
} = {
  [PhoneSettingsVoiceType.FEMALE]: 'Female voice',
  [PhoneSettingsVoiceType.MALE]: 'Male voice',
};
