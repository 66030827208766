import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import { isSubmitDisabled } from '@app/utils/validation';

import { AutoRechargeFormProps } from './types';

interface ActionsProps {
  isLoading: boolean;
  onClose: () => void;
}

const Actions: FC<ActionsProps> = ({ isLoading, onClose }) => {
  const formik = useFormikContext<AutoRechargeFormProps>();
  const isButtonDisabled = isSubmitDisabled(formik, false, []);
  return (
    <>
      <Button
        height="40px"
        isDisabled={isButtonDisabled || isLoading}
        isLoading={isLoading}
        minWidth="100%"
        variant="primary"
        width="100%"
        onClick={() => formik.handleSubmit()}>
        Confirm
      </Button>
      <Button
        height="40px"
        mt="3px"
        variant="cancel"
        width="100%"
        onClick={onClose}>
        Cancel
      </Button>
    </>
  );
};

export default Actions;
