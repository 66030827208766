import { ApolloError } from '@apollo/client';

export const RESET_PASSWORD_REQUIRED_CODE = 'Password reset required';
export const HTTP_CODE_401_UNAUTHORIZED = 'UNAUTHORIZED';

export const getGraphQLErrorCode = (error: ApolloError) =>
  error?.graphQLErrors?.[0]?.extensions?.code;

export const getGraphQLErrorMessage = (error: ApolloError) =>
  error?.graphQLErrors?.[0]?.message;

export const getGraphQLErrorException = <T = Record<string, any>>(
  error: ApolloError,
) => error?.graphQLErrors?.[0]?.extensions?.exception as T;
