import capitalize from 'lodash/capitalize';
import {
  Account,
  AccountStatus,
  BillingInterval,
  Plan,
  Product,
  SubscriptionFragment,
  SubscriptionStatus,
} from '@app/api/gql/generated-types';
import { isEmptyArray, isNonEmptyArray } from '@app/utils/arrayUtils';
import { centsToDollars } from '@app/utils/formatNumber';
import { DATE_FORMAT_LL_DD_YYYY, formatDate } from './formatDate';

export interface SubscriptionStatusColor {
  primary?: string;
  secondary?: string;
}

export const subscriptionType: BillingInterval = BillingInterval.QUARTER;

export const getSubscriptionPeriodPrice = (plan: Plan) => {
  if (subscriptionType === BillingInterval.QUARTER) {
    return centsToDollars(Number(plan.price)) / 3;
  }

  return centsToDollars(Number(plan.displayPrice));
};

export const canResumeSubscription = (endAt: Date) =>
  !!endAt && endAt > new Date();

export const hasPendingCancellationEnded = (endAt: Date) =>
  !!endAt && new Date(endAt) < new Date();

export const subscriptionPeriod: Partial<
  Record<
    BillingInterval,
    {
      periodName: string;
      upgradeName: string;
      planItemName: string;
      shortItemName: string;
    }
  >
> = {
  [BillingInterval.YEAR]: {
    periodName: 'year',
    upgradeName: 'Yearly',
    planItemName: 'yearly',
    shortItemName: 'yr',
  },
  [BillingInterval.MONTH]: {
    periodName: 'month',
    upgradeName: 'Monthly',
    planItemName: 'monthly',
    shortItemName: 'mon',
  },
  [BillingInterval.QUARTER]: {
    periodName: 'quarter',
    upgradeName: 'Quarterly',
    planItemName: 'quarterly',
    shortItemName: 'qtr',
  },
};

// ***** General subscription utils ********************************

const activeStatuses: SubscriptionStatus[] = [
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.PAST_DUE,
  SubscriptionStatus.TRIALING,
];

const inactiveStatuses: SubscriptionStatus[] = [
  SubscriptionStatus.CANCELED,
  SubscriptionStatus.INCOMPLETE,
];

export type SubscriptionStatusCheck =
  | SubscriptionStatus[]
  | 'never-subscribed'
  | 'any'
  | 'any-active'
  | 'any-inactive'
  | 'can-be-resumed';

export type SubscriptionStatusCheckRequirements = {
  /**
   * The subscription to check.
   * Leave blank to check statuses on either subscription.
   */
  subscription?: Omit<Product, 'BASE'>;

  /**
   * The required status of the subscription.
   * Leave undefined to accept any status.
   *
   * @default 'any'
   */
  status?: SubscriptionStatusCheck;

  /**
   * Limit whether the permission check requires the account to be a sub account (`true`),
   * or requires that the account is not a sub account (`false`).
   *
   * Leave it blank if the permission check is not concerned with whether this account is a sub-account.
   */
  mustBeSubAccount?: boolean;

  /**
   * Require a specific status on the overall account.
   * Leave undefined if the permission check is not concerned with the account status.
   *
   * Use an empty array to denote that account status doesn't matter.
   *
   * NOTE: Most status checks should be done on the subscription level, not the account level.
   *
   * @default [AccountStatus.ACTIVE]
   */
  accountStatus?: AccountStatus[] | 'any';
};

export type AccountForSubscriptionStatusCheck = Pick<
  Account,
  'subscriptions' | 'status' | 'isSubAccount'
>;

export const hasSubscriptionStatus = (
  account: AccountForSubscriptionStatusCheck,
  {
    status = 'any',
    mustBeSubAccount: isSubAccount,
    subscription,
    accountStatus = [AccountStatus.ACTIVE],
  }: SubscriptionStatusCheckRequirements,
) => {
  // If a specific subaccount status is required,
  // run this check first, and return false if failed.
  if (
    typeof isSubAccount === 'boolean' &&
    isSubAccount !== account.isSubAccount
  ) {
    return false;
  }

  // If they've defined a required Account status, check that next.
  if (
    Array.isArray(accountStatus) &&
    accountStatus.length > 0 &&
    !accountStatus.includes(account.status)
  ) {
    return false;
  }

  if (!status) {
    throw new Error(
      'Unable to determine subscription status. Required status was not provided.',
    );
  }

  // Filter the subscriptions based on the required subscription type.
  const acctSubs = !!subscription
    ? (account.subscriptions || []).filter((s) => s.product === subscription)
    : (account.subscriptions || []).filter((s) => s.product !== Product.BASE);

  // Check special cases
  if (status === 'never-subscribed') {
    return acctSubs.length === 0;
  }
  if (!status || status === 'any') {
    return acctSubs.length > 0;
  }
  if (!!subscription && !acctSubs.length) {
    return false;
  }

  if (status === 'can-be-resumed') {
    return acctSubs.some(
      (s) =>
        s.status === SubscriptionStatus.CANCELED ||
        (s.status === SubscriptionStatus.ACTIVE &&
          canResumeSubscription(new Date(s.endAt))),
    );
  }

  // Check for specific statuses
  const requiredStatuses: SubscriptionStatus[] =
    status === 'any-active'
      ? activeStatuses
      : status === 'any-inactive'
        ? inactiveStatuses
        : status;

  return acctSubs.some((s) => requiredStatuses.includes(s.status));
};

export const isSubscribed = (
  account: Pick<Account, 'subscriptions'>,
): boolean => {
  return isNonEmptyArray(account?.subscriptions);
};

export const isSubscriptionActive = (
  subscription: Pick<SubscriptionFragment, 'status'>,
): boolean => {
  return subscription?.status === SubscriptionStatus.ACTIVE;
};
export const hasActiveSubscription = (
  subscriptions: SubscriptionFragment[],
): boolean => {
  const activeSubscriptions = subscriptions?.filter(
    (sub) => isSubscriptionActive(sub) && !sub?.endAt,
  );
  return isNonEmptyArray(activeSubscriptions);
};

export const isSubscriptionInactive = (
  subscription: Pick<SubscriptionFragment, 'status'>,
): boolean => {
  return [SubscriptionStatus.CANCELED, SubscriptionStatus.INCOMPLETE].includes(
    subscription?.status,
  );
};

export const isSubscriptionTrialing = (
  subscription: Pick<SubscriptionFragment, 'status'>,
): boolean => {
  return subscription?.status === SubscriptionStatus.TRIALING;
};

export const isSubscriptionActiveEndDate = (
  subscription?: Pick<SubscriptionFragment, 'status' | 'endAt'>,
): boolean => {
  return (
    subscription?.status === SubscriptionStatus.ACTIVE && !!subscription.endAt
  );
};

export const canEndSubscriptionTrial = (
  subscriptions: SubscriptionFragment[],
  canChangeMembership: boolean,
): boolean => {
  const activeSubscriptions = subscriptions?.filter(
    (sub) => sub?.status === SubscriptionStatus.ACTIVE && !sub?.endAt,
  );
  const trialingSubscriptions = subscriptions?.filter(
    (sub) => sub?.status === SubscriptionStatus.TRIALING,
  );
  return (
    canChangeMembership &&
    isEmptyArray(activeSubscriptions) &&
    isNonEmptyArray(trialingSubscriptions)
  );
};
export const canUnsubscribeActiveSubscription = (
  subscriptions: SubscriptionFragment[],
  canChangeMembership: boolean,
): boolean => {
  const activeSubscriptions = subscriptions?.filter(
    (sub) => sub?.status === SubscriptionStatus.ACTIVE && !sub?.endAt,
  );
  return canChangeMembership && isNonEmptyArray(activeSubscriptions);
};

/**
 * Get the display text for the subscription status.
 * @param showDate whether to show the date for Trial and Pending Cancelation statuses.
 * @param subscription if not set, "None" will be returned.
 */
export const getSubscriptionStatusDisplay = (
  showDate: boolean,
  subscription?: Pick<
    SubscriptionFragment,
    'status' | 'trialEndedAt' | 'endAt'
  >,
  isSubAccount?: boolean,
): string => {
  let statusText = capitalize(subscription?.status || 'none').replace('_', ' ');

  if (subscription) {
    // Override for Trialing status
    if (isSubscriptionTrialing(subscription)) {
      statusText = `Trial`;
      if (showDate && subscription.trialEndedAt) {
        const displayDate = formatDate({
          date: new Date(subscription.trialEndedAt),
          format: DATE_FORMAT_LL_DD_YYYY,
        });
        return `${statusText} until ${displayDate}`;
      }
    }

    // Override for Pending cancelation status
    if (isSubscriptionActiveEndDate(subscription)) {
      statusText = `Pending cancelation`;

      if (showDate) {
        const displayDate = formatDate({
          date: new Date(subscription.endAt),
          format: DATE_FORMAT_LL_DD_YYYY,
        });
        return `${statusText} ${displayDate}`;
      }
    }
  }

  if (isSubAccount && statusText !== 'Active') {
    return (statusText = 'Inactive');
  }

  return statusText;
};

export const getSubAccountStatusColour = (
  status: AccountStatus,
): SubscriptionStatusColor => {
  if (status === AccountStatus.ACTIVE) {
    return {
      primary: 'apple',
      secondary: 'white',
    };
  }

  return {
    primary: 'tartOrange',
    secondary: 'white',
  };
};

export const getSubscriptionStatusColor = ({
  subscription,
  status,
}: {
  subscription?: Pick<SubscriptionFragment, 'status' | 'endAt'>;
  status?: SubscriptionStatus | 'PendingCancelation';
}): SubscriptionStatusColor => {
  const isPendingCancelation =
    status === 'PendingCancelation' ||
    isSubscriptionActiveEndDate(subscription);

  if (isPendingCancelation) {
    return { primary: 'tartOrange', secondary: 'white' };
  }

  switch (subscription?.status) {
    case SubscriptionStatus.ACTIVE:
      return { primary: 'mediumAquamarine', secondary: 'white' };
    case SubscriptionStatus.TRIALING:
      return { primary: 'maximumYellowRed', secondary: 'white' };
    case SubscriptionStatus.INCOMPLETE:
    case SubscriptionStatus.PAST_DUE:
    case SubscriptionStatus.CANCELED:
      return { primary: 'tartOrange', secondary: 'white' };
  }
};

// ***** Message subscription utils *****************************
export const isProductMessage = (product: Product) => {
  return product === Product.MESSAGING;
};
export const hasMessageSubscription = (account: Account): boolean => {
  return hasSubscriptionStatus(account, {
    subscription: Product.MESSAGING,
  });
  let messageSubscriptions: SubscriptionFragment[] = [];
  if (isSubscribed(account)) {
    const subscriptions = account?.subscriptions;
    messageSubscriptions = subscriptions?.filter(
      (s) => s?.product === Product.MESSAGING,
    );
  }
  return isNonEmptyArray(messageSubscriptions);
};
export const hasActiveMessageSubscription = (account: Account): boolean => {
  let messageSubscriptions: SubscriptionFragment[] = [];
  if (isSubscribed(account)) {
    const subscriptions = account?.subscriptions;
    messageSubscriptions = subscriptions?.filter(
      (s) =>
        s?.product === Product.MESSAGING &&
        s?.status === SubscriptionStatus.ACTIVE,
    );
  }
  return isNonEmptyArray(messageSubscriptions);
};
export const hasInactiveMessageSubscription = (account: Account): boolean => {
  let messageSubscriptions: SubscriptionFragment[] = [];
  if (isSubscribed(account)) {
    const subscriptions = account?.subscriptions;
    messageSubscriptions = subscriptions?.filter(
      (s) =>
        s?.product === Product.MESSAGING &&
        (s?.status === SubscriptionStatus.CANCELED ||
          s?.status === SubscriptionStatus.INCOMPLETE),
    );
  }
  return isNonEmptyArray(messageSubscriptions);
};
export const isMessageSubscriptionTrialing = (account: Account): boolean => {
  let messageSubscriptions: SubscriptionFragment[] = [];
  if (isSubscribed(account)) {
    const subscriptions = account?.subscriptions;
    messageSubscriptions = subscriptions?.filter(
      (s) =>
        s?.product === Product.MESSAGING &&
        s?.status === SubscriptionStatus.TRIALING,
    );
  }
  return isNonEmptyArray(messageSubscriptions);
};
export const getMessageSubscriptions = (
  account: Pick<Account, 'subscriptions'>,
): SubscriptionFragment[] => {
  const subscriptions = isSubscribed(account) ? account?.subscriptions : [];
  return subscriptions?.filter((s) => isProductMessage(s.product));
};
export const getMessageSubscription = (
  account: Pick<Account, 'subscriptions'>,
): SubscriptionFragment | null => {
  const messageSubscriptions = getMessageSubscriptions(account);
  const messageSubscription = isNonEmptyArray(messageSubscriptions)
    ? messageSubscriptions[0]
    : null;
  return messageSubscription;
};

// ***** Dialer subscription utils **********************************
export const isProductDialer = (product: Product) => {
  return product === Product.DIALER;
};
export const hasDialerSubscription = (account: Account): boolean => {
  let dialerSubscriptions: SubscriptionFragment[] = [];
  if (isSubscribed(account)) {
    const subscriptions = account?.subscriptions;
    dialerSubscriptions = subscriptions?.filter(
      (s) => s?.product === Product.DIALER,
    );
  }
  return isNonEmptyArray(dialerSubscriptions);
};
export const hasActiveDialerSubscription = (account: Account): boolean => {
  let dialerSubscriptions: SubscriptionFragment[] = [];
  if (isSubscribed(account)) {
    const subscriptions = account?.subscriptions;
    dialerSubscriptions = subscriptions?.filter(
      (s) =>
        s?.product === Product.DIALER &&
        s?.status === SubscriptionStatus.ACTIVE,
    );
  }
  return isNonEmptyArray(dialerSubscriptions);
};

export const hasInactiveDialerSubscription = (account: Account): boolean => {
  let dialerSubscriptions: SubscriptionFragment[] = [];
  if (isSubscribed(account)) {
    const subscriptions = account?.subscriptions;
    dialerSubscriptions = subscriptions?.filter(
      (s) =>
        s?.product === Product.DIALER &&
        (s?.status === SubscriptionStatus.CANCELED ||
          s?.status === SubscriptionStatus.INCOMPLETE),
    );
  }
  return isNonEmptyArray(dialerSubscriptions);
};

export const isDialerSubscriptionTrialing = (account: Account): boolean => {
  let dialerSubscriptions: SubscriptionFragment[] = [];
  if (isSubscribed(account)) {
    const subscriptions = account?.subscriptions;
    dialerSubscriptions = subscriptions?.filter(
      (s) =>
        s?.product === Product.DIALER &&
        s?.status === SubscriptionStatus.TRIALING,
    );
  }
  return isNonEmptyArray(dialerSubscriptions);
};

export const hasPastDueDialerSubscription = (
  account: Pick<Account, 'subscriptions'>,
): boolean => {
  if (isSubscribed(account)) {
    const subscriptions = account?.subscriptions;

    return subscriptions?.some(
      (s) =>
        s?.product === Product.DIALER &&
        s?.status === SubscriptionStatus.PAST_DUE,
    );
  }
  return false;
};

export const getDialerSubscriptions = (
  account: Pick<Account, 'subscriptions'>,
): SubscriptionFragment[] => {
  const subscriptions = isSubscribed(account) ? account?.subscriptions : [];
  return subscriptions?.filter((s) => isProductDialer(s.product));
};

export const getDialerSubscription = (
  account: Pick<Account, 'subscriptions'>,
): SubscriptionFragment | null => {
  const dialerSubscriptions = getDialerSubscriptions(account);
  const dialerSubscription = isNonEmptyArray(dialerSubscriptions)
    ? dialerSubscriptions[0]
    : null;
  return dialerSubscription;
};

export const getSubscriptionByProduct = (
  account: Pick<Account, 'subscriptions'>,
  product: Product,
) => {
  if (isProductMessage(product)) {
    return getMessageSubscription(account);
  }
  return getDialerSubscription(account);
};
