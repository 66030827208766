import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { colors } from '@app/theme/colors';

const MacrosTitleLink = styled(NavLink)`
  color: ${colors.notFound[1800]};
  font-weight: normal;
  font-size: 14px;
  text-decoration: none;
  transition: 0.5s;

  &:hover {
    color: ${colors.primary[600]};
  }
  &.active {
    color: ${colors.primary[600]};
  }
`;

const MacrosLink = styled(NavLink)`
  color: ${colors.primary[600]};
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  transition: 0.5s;
`;

export { MacrosTitleLink, MacrosLink };
