import { useDisclosure } from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { usePlanPermission } from '@app/hooks/usePlanPermission';
import {
  AccountSettingsPopup,
  useUpdateAccountMutation,
} from '@app/api/gql/generated-types';
import { onboardingCalls } from '@app/utils/hubspotMeeting';

export const useOnboardingModal = () => {
  const { isBasicPlan, isPro, isNewStarter, isElite, isEnterprisePlan } =
    usePlanPermission();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const url = useMemo(() => {
    if (isBasicPlan || isNewStarter) {
      return onboardingCalls.basicStarter;
    }

    if (isPro || (isElite && !isEnterprisePlan)) {
      return onboardingCalls.elite;
    }

    return onboardingCalls.enterprise;
  }, [isBasicPlan, isElite, isEnterprisePlan, isNewStarter, isPro]);

  const [updateAccount] = useUpdateAccountMutation();

  const onSubmitPopup = useCallback(async () => {
    await updateAccount({
      variables: {
        data: {
          viewedPopup: AccountSettingsPopup.popupOnboarding,
        },
      },
    });
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  return {
    onShowModal: onOpen,
    url,
    isOpen,
    onClose: onSubmitPopup,
  };
};
