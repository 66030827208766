import { FeatureType } from '@app/api/gql/generated-types';

export type ExtraFeatureLimitType =
  | FeatureType.A2P_10DLC
  | FeatureType.MESSAGING
  | FeatureType.CUSTOM_WEBSITE;

export const extraFeaturesTypes = [
  FeatureType.A2P_10DLC,
  FeatureType.MESSAGING,
  FeatureType.CUSTOM_WEBSITE,
];

export interface ExtraFeature {
  type: ExtraFeatureLimitType;
  title: string;
  price: number;
}

export type FeatureDescriptions = {
  [key in ExtraFeatureLimitType]: string;
};

export const featureTolltipPaymentDescriptions: FeatureDescriptions = {
  [FeatureType.A2P_10DLC]:
    "The Campaign Registry (TCR) charges this one-time fee to process your 10 DLC application and get your company 10 DLC approved. This agency has been retained by all carriers to review brand's prior to carrier's issuing 10 DLC approvals.",
  [FeatureType.MESSAGING]:
    "To get your brand 10 DLC approved, we're required by carriers to register your brand with the The Campaign Registry (TCR). This agency has been retained by all carriers to help brand's maintain a good sending reputation with them. The TCR charges this carrier messaging fee to provide these ongoing services to your company.",
  [FeatureType.CUSTOM_WEBSITE]:
    'Our team will create a custom compliant website for you to guarantee your brand’s 10 DLC approval.',
};
