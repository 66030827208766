import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';

interface BonusLabelProps {
  label: string;
  color?: string;
  backgroundColor?: string;
}

export const BonusLabel: FC<BonusLabelProps> = ({
  backgroundColor,
  label,
  color = 'white',
}) => {
  return (
    <Box
      backgroundColor={backgroundColor ?? 'primary.600'}
      borderRadius="10px"
      color={color}
      fontSize="12px"
      lineHeight="14px"
      ml="10px"
      padding="3px 10px">
      {label}
    </Box>
  );
};
