import React, { FC, useMemo } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { ApolloError } from '@apollo/client';
import { getGraphQLErrorCode } from '@app/utils/getGraphQLErrorCode';
import Popup from '@app/components/Popup';
import PhoneNumberFormat from '@app/shared/ui/PhoneNumberFormat';
import {
  ErrNoExceptionWithExtention,
  ErrorCode,
} from '@app/utils/errorMessage';
import { OwnPhoneReportFragment } from '@app/api/gql/generated-types';

interface ErrorModalProps {
  error: ErrNoExceptionWithExtention;
  isOpen: boolean;
  onClose: () => void;
  phone: OwnPhoneReportFragment;
}

const DeleteErrorModal: FC<ErrorModalProps> = ({
  error,
  isOpen,
  onClose,
  phone,
}) => {
  const errorText = useMemo(() => {
    if (
      (getGraphQLErrorCode(
        error as ApolloError,
      ) as ErrorCode.PHONE_CANNOT_BE_DELETED) ===
      ErrorCode.PHONE_CANNOT_BE_DELETED
    ) {
      return (
        <>
          This phone number{' '}
          <Text as="span" color="osloGray">
            <PhoneNumberFormat value={phone.number} />
          </Text>{' '}
          is associated with an active or paused campaign. To delete it, end
          these campaigns first.
        </>
      );
    }
    return error?.message;
  }, [error, phone]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="400px"
      title="Unable to delete"
      titlePadding="40px 80px 25px 80px"
      onClose={onClose}>
      <Box p="0px 40px 40px 40px">
        <Text textAlign="center" variant="heading-fifth">
          {errorText}
        </Text>
        <Flex justifyContent="center" mt="30px">
          <Button variant="primary" w="190px" onClick={onClose}>
            End campaigns
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};

export default DeleteErrorModal;
