import { useEffect, useState } from 'react';

/**
 * (Comment generated by AI, code written by human.)
 * A React hook that implements a watchdog timer mechanism.
 * It starts a timeout when `start()` is called, and if not reset within the specified time,
 * it marks `timedOut` as `true`. Calling `reset()` cancels the timeout.
 *
 * @param {number} timeoutMs - The duration (in milliseconds) before the timer times out.
 * @returns {{
 *   timedOut: boolean;
 *   start: () => void;
 *   reset: () => void;
 * }} An object containing:
 *   - `timedOut` (boolean): Indicates if the timer has expired.
 *   - `start` (function): Starts the timer.
 *   - `reset` (function): Stops and resets the timer.
 *
 * @example
 * const { timedOut, start, reset } = useWatchdogTimer(3000);
 *
 * useEffect(() => {
 *   start();
 * }, []);
 *
 * if (timedOut) {
 *   console.log("Timeout reached!");
 * }
 */
export const useWatchdogTimer = (
  timeoutMs: number,
): {
  timedOut: boolean;
  start: () => void;
  reset: () => void;
} => {
  const [started, setStarted] = useState(false);
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    if (started) {
      const timeout = setTimeout(() => {
        setTimedOut(true);
      }, timeoutMs);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [started, setTimedOut, timeoutMs]);

  const start = () => {
    // Allow calling it multiple times with no effect
    if (started) {
      return;
    }
    setStarted(true);
    setTimedOut(false);
  };

  const reset = () => {
    setStarted(false);
    setTimedOut(false);
  };

  return {
    timedOut,
    start,
    reset,
  };
};
