import { CustomModalProps } from '@app/components/next/organisms/CustomModal';
import { usePermissionModalContext } from '../contexts/providers/usePermissionModalProvider';

export const useConditionalCustomModal = (modalContent: CustomModalProps) => {
  const { openModal, closeModal } = usePermissionModalContext();

  const toggleModal = (shouldShow: boolean) => {
    if (shouldShow) {
      openModal(modalContent);
    } else {
      closeModal();
    }
  };

  return { toggleModal };
};
