import React, {
  createContext,
  useContext,
  FC,
  useMemo,
  PropsWithChildren,
} from 'react';
import {
  definePermissionAbilityFor,
  PermissionActionsType,
  PermissionFeatureKeys,
  SCAbility,
} from '@app/acl';
import { useTollFreeStatus } from '@app/pages/Settings/content/BrandRegistration/hooks/useTollFreeStatus';
import {
  AccountStatus,
  Product,
  SubscriptionStatus,
} from '@app/api/gql/generated-types';
import { useCurrentAccountContext } from '@app/providers/CurrentAccountProvider';
import { useCurrentAccountData } from '../../hooks/useCurrentAccountData';
import { useDlcStatus } from '../../hooks/useDlcStatus';

interface PermissionAbilityContextProps extends PropsWithChildren {
  abilityCannot: (
    action: PermissionActionsType,
    subject: PermissionFeatureKeys,
  ) => boolean;
  ability: SCAbility;
}

export const PermissionAbilityContext =
  createContext<PermissionAbilityContextProps | null>(null);

export const usePermissionContext = () => {
  const context = useContext(PermissionAbilityContext);

  if (!context) {
    throw new Error(
      'usePermissionContext must be used within an PermissionProvider',
    );
  }

  return context;
};

export const PermissionAbilityProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const currentAccount = useCurrentAccountData();
  const { hasSubscriptionStatus } = useCurrentAccountContext();

  const { isApproved: is10DlcApproved } = useDlcStatus();
  const { isApproved: isApprovedTollFree } = useTollFreeStatus();

  //start messaging subcription statuses
  const hasActiveMessagingSubscription = hasSubscriptionStatus({
    subscription: Product.MESSAGING,
    status: [SubscriptionStatus.ACTIVE],
    accountStatus: [
      AccountStatus.ABANDONED,
      AccountStatus.ACTIVE,
      AccountStatus.INACTIVE,
      AccountStatus.TRIAL,
    ],
  });
  const hasTrialingMessagingSubscription = hasSubscriptionStatus({
    subscription: Product.MESSAGING,
    status: [SubscriptionStatus.TRIALING],
    accountStatus: [AccountStatus.TRIAL, AccountStatus.ACTIVE],
  });
  const hasCancelledOrIncompleteMessagingSubscription = hasSubscriptionStatus({
    subscription: Product.MESSAGING,
    status: 'any-inactive',
    accountStatus: [
      AccountStatus.ABANDONED,
      AccountStatus.INACTIVE,
      AccountStatus.ACTIVE,
    ],
  });
  const hasNoneMessagingSubscription = hasSubscriptionStatus({
    subscription: Product.MESSAGING,
    status: 'never-subscribed',
    accountStatus: [
      AccountStatus.ABANDONED,
      AccountStatus.INACTIVE,
      AccountStatus.ACTIVE,
    ],
  });
  //finish messaging subcription statuses

  //start calling subcription statuses
  const hasActiveCallingSubscription = hasSubscriptionStatus({
    subscription: Product.DIALER,
    status: [SubscriptionStatus.ACTIVE],
    accountStatus: [
      AccountStatus.ABANDONED,
      AccountStatus.ACTIVE,
      AccountStatus.INACTIVE,
      AccountStatus.TRIAL,
    ],
  });

  const hasCancelledCallingSubscription = hasSubscriptionStatus({
    subscription: Product.DIALER,
    status: 'any-inactive',
    accountStatus: [
      AccountStatus.ABANDONED,
      AccountStatus.INACTIVE,
      AccountStatus.ACTIVE,
    ],
  });
  const hasNoneCallingSubscription = hasSubscriptionStatus({
    subscription: Product.DIALER,
    status: 'never-subscribed',
    accountStatus: [
      AccountStatus.ABANDONED,
      AccountStatus.INACTIVE,
      AccountStatus.ACTIVE,
      AccountStatus.TRIAL,
    ],
  });

  //finish calling subcription statuses
  const ability = useMemo(
    () =>
      definePermissionAbilityFor(
        hasActiveMessagingSubscription,
        hasTrialingMessagingSubscription,
        hasCancelledOrIncompleteMessagingSubscription,
        hasNoneMessagingSubscription,
        hasActiveCallingSubscription,
        hasCancelledCallingSubscription,
        hasNoneCallingSubscription,
        is10DlcApproved || isApprovedTollFree,
        currentAccount?.isSubAccount,
      ),
    [
      hasActiveMessagingSubscription,
      hasTrialingMessagingSubscription,
      hasCancelledOrIncompleteMessagingSubscription,
      hasNoneMessagingSubscription,
      hasActiveCallingSubscription,
      hasCancelledCallingSubscription,
      hasNoneCallingSubscription,
      is10DlcApproved,
      isApprovedTollFree,
      currentAccount,
    ],
  );

  const value = useMemo(
    () => ({
      abilityCannot: (
        action: PermissionActionsType,
        subject: PermissionFeatureKeys,
      ) => {
        return ability?.cannot(action, subject) || false;
      },
      ability,
    }),
    [ability],
  );

  return (
    <PermissionAbilityContext.Provider value={value}>
      {children}
    </PermissionAbilityContext.Provider>
  );
};
