import { Box, Td, Tr } from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Row } from 'react-table';

import DragIcon from '@app/icons/drag-field-icon.svg?react';

const ITEM_ROW_TYPE = 'row';

interface RowProps<T extends object> {
  row: Row<T>;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  onDropRow: (item: T) => void;
}

export const ScriptsRow = <T extends object>({
  row,
  index,
  moveRow,
  onDropRow,
}: RowProps<T>) => {
  const dropRef = useRef<HTMLTableRowElement>(null);
  const dragRef = useRef<HTMLTableCellElement>(null);

  const [, drop] = useDrop({
    accept: ITEM_ROW_TYPE,
    hover(
      item: {
        type: string;
        index: number;
      },
      monitor,
    ) {
      if (!dropRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = dropRef.current.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item: unknown, monitor) {
      const didDrop = monitor.didDrop();
      if (didDrop) {
        return;
      }

      onDropRow(item as T);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ITEM_ROW_TYPE,
    item: { ...row },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <Tr
      ref={dropRef}
      {...row.getRowProps()}
      background="white"
      transform="translate3d(0, 0, 0)">
      {isDragging ? (
        <Td
          backgroundColor="catskillWhite"
          colSpan={(row.cells?.length ?? 0) + 2}
        />
      ) : (
        <>
          <Td ref={dragRef} pl="10px" pr="0" w="10px">
            <DragIcon />
          </Td>
          {row.cells.map((cell) => {
            const isContentColumn = cell.column.id === 'content';
            const cellValue = isContentColumn
              ? (cell.value as string)
              : (cell.render('Cell') as string);

            return (
              <Td
                key={cell.getCellProps().key}
                color="main.400"
                fontSize="14px"
                {...cell.getCellProps({
                  style: {
                    minWidth: cell.column.minWidth,
                    maxWidth: cell.column.maxWidth,
                    width: cell.column.width,
                  },
                })}>
                <Box
                  sx={{
                    noOfLines: 2,
                    overflowY: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                  {cellValue}
                </Box>
              </Td>
            );
          })}
        </>
      )}
    </Tr>
  );
};
