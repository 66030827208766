import React, { FC, memo, useMemo } from 'react';
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
} from '@chakra-ui/react';

import { colors } from '@app/theme/colors';
import { WrapDateRangePicker } from './styles';
import { Trigger } from './Trigger';
import { DateRangeType } from './types';

export interface DateType {
  start: Date;
  end: Date;
}

interface DatePickerProps extends DateType {
  direction?: 'horizontal' | 'vertical';
  isCompact?: boolean;
  months?: number;
  onChange: (date: DateType) => void;
  type?: DateRangeType;
  onClear?: () => void;
}

interface DatePickerSelectionType {
  selection?: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
}

const datePickerWidth = 750;

const DatePicker: FC<DatePickerProps> = ({
  direction,
  months,
  isCompact,
  start,
  end,
  onChange,
  type = DateRangeType.SELECT,
  onClear,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const range = useMemo(
    () => ({
      startDate: start,
      endDate: end,
      key: 'selection',
    }),
    [start, end],
  );

  const handleSelect = ({ selection }: DatePickerSelectionType) => {
    onChange({
      start: selection?.startDate,
      end: selection?.endDate,
    });
  };

  return (
    <Box>
      <Popover
        isLazy
        matchWidth
        offset={[-600, 0]}
        placement="bottom-end"
        trigger={type === DateRangeType.SELECT ? 'click' : 'hover'}
        onClose={onClose}
        onOpen={onOpen}>
        <PopoverTrigger>
          <Box>
            <Trigger end={end} isOpen={isOpen} start={start} type={type} />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          _focus={{
            outline: '0',
            boxShadow: '0px 6px 18px rgba(139, 139, 139, 0.25)',
          }}
          border="1px solid transparent"
          borderRadius="20px"
          boxShadow="0px 6px 18px rgba(139, 139, 139, 0.25)"
          overflow="hidden"
          width={isCompact ? 500 : datePickerWidth}>
          <WrapDateRangePicker
            direction={direction ? direction : 'horizontal'}
            months={months ? months : 2}
            moveRangeOnFirstSelection={false}
            rangeColors={[colors.primary[200]]}
            ranges={[range]}
            showDateDisplay={false}
            showMonthAndYearPickers={false}
            onChange={handleSelect}
            onClear={onClear}
          />
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default memo(DatePicker);
