import React, { FC, PropsWithChildren } from 'react';
import { Text } from '@chakra-ui/react';

interface DefaultNameProps extends PropsWithChildren {
  title: string;
}

export const DefaultName: FC<DefaultNameProps> = ({ title, children }) => {
  return (
    <Text
      color="main.400"
      fontSize="14px"
      fontStyle="normal"
      fontWeight="500"
      id="inbox-create-new-conversation-button"
      lineHeight="10px"
      mr="5px">
      {!!children && children}
      {title}
    </Text>
  );
};
