import { theme } from '@chakra-ui/react';
import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { TThemeSize } from '@app/theme/components/size';
import { Enum } from '@app/utils/enum';
import { colors } from '../colors';

const ghostVariant = theme.components.Button.variants.ghost;

export const TButtonVariant = new Enum('primary');

export const Button = {
  sizes: {
    small: {
      h: '28px',
      minW: '28px',
      fontSize: TThemeSize.enum.xs,
      px: '2',
    },
    middle: {
      h: '30px',
      minW: '30px',
      fontSize: TThemeSize.enum.xs,
      px: '2',
    },
  },
  variants: {
    [TButtonVariant.enum.primary]: {
      backgroundColor: 'primary.600',
      borderRadius: '24px',
      color: 'white',
      fontSize: '14px',
      lineHeight: '16px',
      padding: '0 20px',
      fontWeight: 500,
      fontStyle: 'normal',
      _focus: {
        boxShadow: 'unset',
      },
      _hover: {
        backgroundColor: 'primary.700',
        _disabled: {
          backgroundColor: 'secondary.200',
        },
      },
      _active: {
        backgroundColor: 'primary.500',
      },
      _disabled: {
        backgroundColor: 'secondary.200',
        opacity: 'unset',
      },
    },

    link: {
      fontSize: '14px',
      lineHeight: '16px',
      backgroundColor: colors.transparent,
      color: 'primary.600',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'underline',
      textDecorationColor: 'primary.600',
      _hover: {
        color: 'primary.700',
        textDecorationColor: 'primary.700',
        _disabled: {
          backgroundColor: 'secondary.200',
        },
      },
      _active: {
        color: colors.pressedAzure,
        textDecorationColor: colors.pressedAzure,
      },
      _focus: {
        color: colors.pressedAzure,
        textDecorationColor: colors.pressedAzure,
      },
      _disabled: {
        color: 'secondary.200',
        opacity: 'unset',
        textDecorationColor: 'secondary.200',
      },
    },
    addCard: {
      fontSize: '14px',
      lineHeight: '16px',
      backgroundColor: colors.transparent,
      color: 'primary.600',
      borderRadius: '12px',
      justifyContent: 'flex-start',
      fontWeight: 'normal',
      fontStyle: 'normal',
      _hover: {
        color: 'primary.700',
        background: colors.primary[200],
        _disabled: {
          backgroundColor: 'secondary.200',
        },
      },
      _active: {
        color: 'primary.700',
        background: colors.primary[200],
      },

      _disabled: {
        color: 'secondary.200',
        opacity: 'unset',
      },
    },
    campaignSecondary: {
      fontSize: '14px',
      lineHeight: '16px',
      backgroundColor: colors.transparent,
      color: 'primary.600',
      borderRadius: '12px',
      justifyContent: 'flex-start',
      fontWeight: '500',
      fontStyle: 'normal',
      width: 'auto',
      padding: '12px 16px',
      _hover: {
        color: 'primary.700',
        background: colors.primary[200],
        _disabled: {
          color: 'secondary.200',
          opacity: 'unset',
        },
      },
      _active: {
        color: 'primary.700',
        background: colors.primary[200],
      },

      _disabled: {
        color: 'secondary.200',
        opacity: 'unset',
      },
    },
    linkSecondary: {
      fontSize: '14px',
      backgroundColor: colors.transparent,
      padding: 0,
      color: 'primary.600',
      display: 'inline',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecoration: 'none',
      _hover: {
        color: 'primary.700',
        textDecorationColor: 'none',
        _disabled: {
          backgroundColor: 'secondary.200',
        },
      },
      _active: {
        color: colors.pressedAzure,
        textDecorationColor: 'none',
      },
      _focus: {
        color: colors.pressedAzure,
        textDecorationColor: 'none',
        boxShadow: 'unset',
      },
      _disabled: {
        color: 'secondary.200',
        opacity: 'unset',
        textDecorationColor: 'secondary.200',
      },
    },

    goBack: {
      fontSize: '14px',
      lineHeight: '16px',
      backgroundColor: colors.transparent,
      color: 'primary.600',
      fontWeight: 'normal',
      fontStyle: 'normal',
      marginRight: '20px',
      height: 'auto',
      textDecorationColor: 'primary.600',
      padding: '0',
      _hover: {
        color: 'primary.700',
        textDecorationColor: 'primary.700',
        _disabled: {
          backgroundColor: 'secondary.200',
        },
      },
      _active: {
        color: colors.pressedAzure,
        textDecorationColor: colors.pressedAzure,
      },
      _focus: {
        color: colors.pressedAzure,
        textDecorationColor: colors.pressedAzure,
        boxShadow: 'unset',
      },
      _disabled: {
        color: 'secondary.200',
        opacity: 'unset',
        textDecorationColor: 'secondary.200',
      },
    },

    outlined: {
      border: '1px',
      borderColor: 'primary.600',
      borderRadius: '20px',
      backgroundColor: 'white',
      color: 'primary.600',
      fontWeight: 500,
      fontSize: '14px',
      fontStyle: 'normal',
      lineHeight: '16px',
      padding: '0 20px',

      _hover: {
        backgroundColor: 'zircon',
        color: 'primary.700',
        borderColor: 'primary.700',
        _disabled: {
          backgroundColor: colors.transparent,
          borderColor: 'secondary.200',
          color: 'secondary.200',
        },
      },
      _focus: {
        boxShadow: 'unset',
        color: 'primary.700',
        borderColor: 'primary.700',
      },
      _active: {
        backgroundColor: 'primary.200',
        color: 'primary.500',
      },
      _disabled: {
        backgroundColor: colors.transparent,
        borderColor: 'secondary.200',
        color: 'secondary.200',
        opacity: 'unset',
      },

      _loading: {
        backgroundColor: 'secondary.200',
        borderColor: 'secondary.200',
        color: 'white',
        opacity: 'unset',
      },
    },
    text: {
      fontSize: '14px',
      border: '0 none',
      backgroundColor: colors.transparent,
      borderRadius: '20px',
      color: 'secondary.400',
      fontWeight: 400,
      fontStyle: 'normal',
      width: '100%',
      padding: '0',
      textAlign: 'left',
      display: 'block',
      height: '28px',
      _hover: {
        backgroundColor: colors.transparent,
        color: 'secondary.400',
      },
      _active: {
        backgroundColor: colors.transparent,
        color: 'secondary.400',
      },
      _disabled: {
        backgroundColor: colors.transparent,
        borderColor: 'secondary.400',
      },
    },

    cancel: {
      color: 'secondary.400',
      backgroundColor: colors.transparent,
      borderRadius: 'unset',
      fontSize: '14px',
      lineHeight: '16px',
      padding: '0 20px',
      fontWeight: 500,
      fontStyle: 'normal',
      _hover: {
        color: 'primary.700',
      },
      _focus: {
        boxShadow: 'unset',
        color: 'pressedAzure',
      },
      _disabled: {
        color: 'secondary.200',
        _hover: {
          color: 'secondary.200',
        },
        opacity: 'unset',
      },
    },

    export: {
      color: 'primary.600',
      backgroundColor: colors.transparent,
      padding: '0',
      _hover: {
        color: 'primary.700',
      },
      _focus: {
        boxShadow: 'unset',
      },
    },

    inlineText: {
      color: 'primary.600',
      backgroundColor: colors.transparent,
      padding: '0',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 400,
      _hover: {
        color: 'primary.700',
      },
      _focus: {
        boxShadow: 'unset',
      },
      _disabled: {
        color: 'secondary.400',
      },
    },

    login: {
      backgroundColor: 'primary.600',
      borderRadius: '24px',
      color: 'white',
      fontSize: '17px',
      lineHeight: '22px',
      padding: '0 20px',
      fontWeight: 700,
      fontStyle: 'normal',
      _focus: {
        boxShadow: 'unset',
      },
      _hover: {
        backgroundColor: 'primary.700',
        _disabled: {
          backgroundColor: colors.secondary[200],
        },
      },
      _active: {
        backgroundColor: 'primary.500',
      },
      _disabled: {
        backgroundColor: colors.secondary[200],
        opacity: 1,
      },
    },

    loginConnect: {
      backgroundColor: 'primary.600',
      borderRadius: '24px',
      width: '380px',
      height: '48px',
      fontWeight: 'bold',
      fontSize: '17px',
      color: 'white',
      border: '1px',
      borderColor: 'primary.600',
      _hover: {
        backgroundColor: 'primary.700',
        borderColor: 'primary.700',
        color: 'white',
      },
    },

    loginDisconnect: {
      backgroundColor: 'white',
      borderRadius: '24px',
      width: '380px',
      height: '48px',
      fontWeight: 'bold',
      fontSize: '17px',
      color: 'primary.600',
      border: '1px',
      borderColor: 'primary.600',
      _hover: {
        backgroundColor: colors.notFound[4600],
        borderColor: colors.notFound[4600],
        color: 'white',
      },
    },
    controlIconButton: {
      color: 'primary.600',
      bg: 'primary.200',
      borderRadius: 'full',
      boxSize: '30px',
      _active: { background: 'unset' },
      _focus: { bg: 'unset' },
      _hover: {
        bg: 'primary.200',
        _disabled: {
          backgroundColor: 'secondary.200',
          color: 'white',
          opacity: 1,
        },
      },
      _disabled: {
        backgroundColor: 'secondary.200',
        color: 'white',
        opacity: 1,
      },
    },
    iconButton: {
      color: 'main.100',
      backgroundColor: colors.transparent,
      _hover: {
        color: 'primary.600',
        backgroundColor: 'primary.200',
      },
      _active: {
        color: 'pressedAzure',
        backgroundColor: 'primary.200',
      },
    },
    roundButtonSecondary: {
      color: 'primary.600',
      backgroundColor: 'primary.200',

      _hover: {
        color: 'primary.700',
        backgroundColor: 'primary.200',
        _disabled: {
          backgroundColor: 'secondary.200',
          color: 'white',
        },
      },
      _active: {
        color: 'primary.600',
        backgroundColor: 'primary.200',
        boxShadow: `0 0 0 2px ${colors.primary[300]}`,
      },
      _disabled: {
        backgroundColor: 'secondary.200',
        color: 'white',
        opacity: 1,
      },
    },
    flatIconButton: {
      color: 'primary.600',
      _hover: {
        color: 'primary.600',
        opacity: '0.8',
      },
      _active: {
        color: 'pressedAzure',
      },
    },
    messengerIcon: {
      color: 'secondary.400',
      _hover: {
        color: 'primary.600',
        _disabled: {
          cursor: 'pointer',
          color: 'secondary.200',
        },
      },
      _active: {
        color: 'primary.600',
      },
      _disabled: {
        opacity: 1,
        color: 'secondary.200',
      },
    },
    tab: {
      fontSize: '14px',
      border: '0 none',
      backgroundColor: colors.transparent,
      color: 'secondary.400',
      fontWeight: 500,
      fontStyle: 'normal',
      padding: 0,
      borderRadius: 0,
      height: '31px',
      borderBottom: '2px solid',
      borderColor: colors.transparent,
      minWidth: 'auto',
      _active: {
        backgroundColor: colors.transparent,
        color: 'main.400',
        borderColor: 'primary.600',
      },
    },
    ghostPlain: (props: StyleFunctionProps) => ({
      ...ghostVariant(props),
      padding: '0',
      color: 'main.100',
      height: 'auto',
      minWidth: 'auto',
      _disabled: {
        color: 'secondary.200',
      },
      _hover: {
        color: 'primary.600',
        _disabled: {
          color: 'secondary.200',
        },
      },
      _loading: {
        _hover: {
          color: 'main.100',
        },
      },
    }),
    rounded: (props: StyleFunctionProps) => ({
      ...ghostVariant(props),
      padding: '12px',
      borderRadius: '50%',
      border: '1px solid',
      backgroundColor: 'white',
      borderColor: 'primary.600',
      _hover: {
        color: 'primary.600',
        backgroundColor: 'zircon',
      },
      _loading: {
        _hover: {
          color: 'main.100',
        },
      },
    }),
  },
};
