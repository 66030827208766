import React, { useEffect } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowLeftIcon from '@app/icons/arrow-left-icon-lg.svg?react';
import SCLogo from '@app/icons/LogoSmarterContactIcon.svg?react';
import ROUTES from '@app/utils/routes';

import SideBarContentMobile from './Content.mobile';

const SideBarWrapperMobile = () => {
  const navigate = useNavigate();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { contactId } = useParams<{ contactId: string }>();

  useEffect(() => {
    if (contactId) {
      onOpen();
    }
  }, [contactId, onOpen]);

  const backHandler = () => {
    navigate(-2);
    onClose();
  };

  return (
    <Drawer
      autoFocus={false}
      blockScrollOnMount={false}
      isOpen={isOpen}
      placement="right"
      size="full"
      onClose={onClose}>
      <DrawerContent maxHeight="100%">
        <DrawerHeader pb="0px" px="0px">
          <Box left="16px" position="absolute" top="14px" onClick={backHandler}>
            <ArrowLeftIcon />
          </Box>
          <Flex
            justifyContent="center"
            onClick={() => navigate(`${ROUTES.messenger}`)}>
            <SCLogo />
          </Flex>
        </DrawerHeader>
        <DrawerBody pb="0" pt="20px" px="0px">
          {contactId && <SideBarContentMobile id={contactId} />}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default SideBarWrapperMobile;
