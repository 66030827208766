import { useGetCampaignQuery } from '../gql/generated-types';

export const useCampaignIdQueryData = (id?: string) => {
  const { data: { getCampaign: data } = {}, loading } = useGetCampaignQuery({
    variables: {
      id,
    },
    skip: !id,
  });

  return {
    data,
    isLoading: loading,
  };
};
