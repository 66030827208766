/* eslint-disable @typescript-eslint/unbound-method */
import { numberInputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(numberInputAnatomy.keys);

const primary = definePartsStyle({
  field: {
    border: '1px solid',
    borderColor: 'secondary.200',
    borderRadius: '22px',
    color: 'main.400',
    fontSize: '14px',
    backgroundColor: 'white',
    lineHeight: '16px',
    padding: '12px 20px',
    _disabled: {
      backgroundColor: 'catskillWhite',
      color: 'main.100',
    },
    _focus: {
      borderColor: 'primary.500',
    },
  },
});

export const NumberInput = defineMultiStyleConfig({
  variants: { primary },
});
