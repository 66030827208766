import { Box } from '@chakra-ui/react';
import React, { FC, useMemo, useRef } from 'react';
import isString from 'lodash/isString';

import { DateTime } from 'luxon';
import CalendarIcon from '@app/icons/calendar-icon.svg?react';
import TimeIcon from '@app/icons/time-icon.svg?react';
import {
  DATE_FORMAT_FULL,
  DATE_FORMAT_LLL_DD_YYYY,
  TIME_FORMAT_HH_MM_A,
} from '@app/utils/formatDate';
import { DateTimePickerProps, Mode } from './types';
import { InputStyles, InputIconStyles } from './styles';

export const ButtonTrigger: FC<Partial<DateTimePickerProps>> = ({
  mode,
  disabled,
  onOpen,
  isOpen,
  defaultValue,
  value,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const dateMode = (() => mode === Mode.DATETIME || mode === Mode.DATE)();

  const mainValue = useMemo(() => {
    switch (mode) {
      case Mode.DATE:
        return value.toFormat(DATE_FORMAT_LLL_DD_YYYY);
      case Mode.TIME:
        return value.toFormat(TIME_FORMAT_HH_MM_A);
      default:
        return (
          value?.toFormat(DATE_FORMAT_FULL) ||
          DateTime.local().toFormat(DATE_FORMAT_FULL)
        );
    }
  }, [mode, value]);

  return (
    <Box
      role="button"
      style={{ position: 'relative', width: '100%' }}
      tabIndex={0}
      onClick={disabled ? null : onOpen}
      onKeyPress={disabled ? null : onOpen}>
      <InputStyles
        ref={inputRef}
        readOnly
        disabled={disabled}
        id="DateTimePicker"
        isOpen={isOpen}
        placeholder={defaultValue}
        type="text"
        value={isString(defaultValue) ? '' : mainValue}
      />
      <InputIconStyles>
        {dateMode && (
          <Box color="secondary.200">
            <CalendarIcon />
          </Box>
        )}
        {mode === Mode.TIME && <TimeIcon />}
      </InputIconStyles>
    </Box>
  );
};
