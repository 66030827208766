import React, { FC, useMemo } from 'react';
import { Text, Link } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';

import ROUTES from '@app/utils/routes';

import { colors } from '@app/theme/colors';
import { TrialLimitsPopupProps } from './types';

/**
 *  @deprecated Use the new permission Features & permission modal. .waiting for new modal designs from design team
 * */
const ModalDescription: FC<TrialLimitsPopupProps> = ({ type, onClose }) => {
  const value = useMemo(
    () => ({
      limit: (
        <Text lineHeight="18px" variant="list-style">
          During your trial, we offer 100 <strong>SMS</strong> to test drive the
          software. To unlock full messaging capabilities, please{' '}
          <Link
            as={RouteLink}
            to={`${ROUTES.settingsMembership}`}
            variant="link"
            onClick={onClose}>
            subscribe
          </Link>
          <br />
          <br />
          Please note that this Campaign will only be active during the Trial
          period.
        </Text>
      ),
      attention: (
        <Text lineHeight="18px" variant="list-style">
          This campaign exceeds the <strong>100 SMS</strong> limit in your
          trial. To unlock full messaging capabilities, please{' '}
          <Link
            as={RouteLink}
            to={`${ROUTES.settingsMembership}`}
            variant="link"
            onClick={onClose}>
            subscribe
          </Link>
        </Text>
      ),
      used: (
        <Text lineHeight="18px" variant="list-style">
          You have used the <strong>100 SMS</strong> limit in your trial. To
          unlock full messaging capabilities, please{' '}
          <Link
            as={RouteLink}
            to={`${ROUTES.settingsMembership}`}
            variant="link"
            onClick={onClose}>
            subscribe
          </Link>
        </Text>
      ),
      noPhones: (
        <Text lineHeight="18px" variant="list-style">
          You have <strong>no available phone numbers</strong>. Go to the{' '}
          <Text as="span" color={colors.notFound}>
            Phone setup
          </Text>{' '}
          page to purchase one.
        </Text>
      ),
    }),
    [onClose],
  );

  return <Text textAlign="center">{value[type]}</Text>;
};

export default ModalDescription;
