import { Editor, Element, Text, Transforms } from 'slate';

import { CustomEditor, ParagraphElement } from '../config/custom-types';

const TEXT_ALIGN_TYPES = ['left', 'center', 'right'];

const isBlockActive = (
  editor: CustomEditor,
  format: string,
  blockType = 'type',
) => {
  const { selection } = editor;

  if (!selection) {
    return false;
  }

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        (n as { [key: string]: any })[blockType] === format,
    }),
  );

  return !!match;
};

const toggleBlock = (editor: CustomEditor, command: string, format: string) => {
  const isActive = isBlockActive(editor, format, command);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      Element.isElement(n) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<Element>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : (format as ParagraphElement['align']),
    };
  }
  Transforms.setNodes<Element>(editor, newProperties);
};

export const wysiwygCommands = {
  isBoldMarkActive(editor: CustomEditor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => Text.isText(n) && n.bold === true,
      universal: true,
    });

    return !!match;
  },
  toggleBoldMark(editor: CustomEditor) {
    const isActive = wysiwygCommands.isBoldMarkActive(editor);
    Transforms.setNodes(
      editor,
      { bold: isActive ? null : true },
      { match: (n) => Text.isText(n), split: true },
    );
  },
  isItalicMarkActive(editor: CustomEditor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => Text.isText(n) && n.italic === true,
      universal: true,
    });

    return !!match;
  },
  toggleItalicMark(editor: CustomEditor) {
    const isActive = wysiwygCommands.isItalicMarkActive(editor);
    Transforms.setNodes(
      editor,
      { italic: isActive ? null : true },
      { match: (n) => Text.isText(n), split: true },
    );
  },
  isUnderlineMarkActive(editor: CustomEditor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => Text.isText(n) && n.underline === true,
      universal: true,
    });

    return !!match;
  },
  toggleUnderlineMark(editor: CustomEditor) {
    const isActive = wysiwygCommands.isUnderlineMarkActive(editor);
    Transforms.setNodes(
      editor,
      { underline: isActive ? null : true },
      { match: (n) => Text.isText(n), split: true },
    );
  },
  isStrikethroughMarkActive(editor: CustomEditor) {
    const [match] = Editor.nodes(editor, {
      match: (n) => Text.isText(n) && n.strikethrough === true,
      universal: true,
    });

    return !!match;
  },
  toggleStrikethroughMark(editor: CustomEditor) {
    const isActive = wysiwygCommands.isStrikethroughMarkActive(editor);
    Transforms.setNodes(
      editor,
      { strikethrough: isActive ? null : true },
      { match: (n) => Text.isText(n), split: true },
    );
  },
  alignLeft(editor: CustomEditor) {
    toggleBlock(editor, 'align', 'left');
  },
  alignCenter(editor: CustomEditor) {
    toggleBlock(editor, 'align', 'center');
  },
  alignRight(editor: CustomEditor) {
    toggleBlock(editor, 'align', 'right');
  },
};
