import { AccountStatus } from '@app/api/gql/generated-types';
import { useCurrentAccountData } from './useCurrentAccountData';

export const useAccountAttention = () => {
  const account = useCurrentAccountData();
  const isInactiveAccount = account?.status === AccountStatus.INACTIVE;
  const userHasNoPlans = !account?.subscriptions;

  const userHasAttention = isInactiveAccount && userHasNoPlans;

  return userHasAttention;
};
