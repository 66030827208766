import React, { FC } from 'react';

import LeftIcon from '@app/widgets/DTPicker/DatePicker/icons/left-arrow-icon.svg?react';
import MemoSvgImageIcon from '@app/utils/svgImageIcon';

import { NavigationButtonStyle } from '../styles';

interface PrevPeriodTemplateTypes {
  onClick: () => void;
}

const PrevPeriod: FC<PrevPeriodTemplateTypes> = ({ onClick }) => {
  return (
    <NavigationButtonStyle onClick={onClick}>
      <MemoSvgImageIcon svgIcon={LeftIcon} />
    </NavigationButtonStyle>
  );
};

export default PrevPeriod;
