import React from 'react';
import {
  createStandaloneToast,
  Text,
  Icon,
  Flex,
  Spacer,
  ToastPosition,
  CircularProgress,
  ToastId,
} from '@chakra-ui/react';

import styled from '@emotion/styled';
import theme from '@app/theme';
import { colors } from '@app/theme/colors';
import CloseIcon from '@app/icons/toasts/close-icon.svg?react';
import CloseCircleIcon from '@app/icons/toasts/close-circle-icon.svg?react';
import CheckGreenIcon from '@app/icons/toasts/check-green-icon.svg?react';
import AlertCircleIcon from '@app/icons/toasts/alert-circle-icon.svg?react';
import AlertTriangleIcon from '@app/icons/toasts/alert-triangle-icon.svg?react';

type StatusTypes = 'info' | 'warning' | 'success' | 'error' | 'inProgress';

interface ToastNotifyTypes {
  title: string;
  status: StatusTypes;
  position?: ToastPosition;
  linkTo?: () => void;
  linkToText?: string;
  toastId?: ToastId;
  progressCompleted?: boolean;
}

const { toast } = createStandaloneToast({ theme });

export const NotificationStyledText = styled.span`
  white-space: pre-line;
  a {
    text-decoration: underline;
  }
`;

const ToastNotify = ({
  title,
  status,
  position = 'top-right',
  linkTo,
  linkToText,
  toastId,
  progressCompleted = false,
}: ToastNotifyTypes) => {
  const checkStatusBg = () => {
    switch (status) {
      case 'info':
        return colors.primary[200];
      case 'success':
        return colors.notFound[2500];
      case 'warning':
        return colors.notFound[2600];
      case 'error':
        return colors.notFound[2700];
      case 'inProgress':
        return colors.secondary[500];
      default:
        return colors.transparent;
    }
  };

  const checkStatusIcon = () => {
    switch (status) {
      case 'info':
        return <Icon as={AlertCircleIcon} h="16px" mr="10px" width="16px" />;
      case 'success':
        return <Icon as={CheckGreenIcon} />;
      case 'warning':
        return <Icon as={AlertTriangleIcon} mr="10px" />;
      case 'error':
        return <Icon as={CloseCircleIcon} h="16px" mr="10px" width="16px" />;
      case 'inProgress':
        return (
          <CircularProgress
            color="white"
            isIndeterminate={!progressCompleted}
            mr="10px"
            size="16px"
            thickness="10px"
            trackColor={colors.transparent}
            value={progressCompleted ? 100 : 0}
          />
        );
      default:
        return colors.transparent;
    }
  };

  const renderToast = ({ onClose }: { onClose: () => void }) => (
    <Flex
      alignItems="center"
      background={checkStatusBg()}
      borderRadius="3px"
      color={status !== 'inProgress' ? 'main.400' : 'white'}
      fontSize="11px"
      mt="25px"
      p="10px">
      <Flex mr="8px">{checkStatusIcon()}</Flex>
      <NotificationStyledText
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      {linkTo && (
        <Text
          color={colors.notFound[2800]}
          cursor="pointer"
          fontSize="11px"
          onClick={linkTo}>
          &nbsp; {linkToText}
        </Text>
      )}
      <Spacer />
      <Flex cursor="pointer" ml="8px" onClick={onClose}>
        <CloseIcon />
      </Flex>
    </Flex>
  );

  if (toastId) {
    toast.update(toastId, {
      position,
      render: renderToast,
    });
  } else {
    return toast({
      position,
      render: renderToast,
    });
  }
};

export default ToastNotify;
