import { useGetLabelQuery } from '../gql/generated-types';

export const useLabelData = (id?: string) => {
  const { data: { getLabel: data } = {}, loading } = useGetLabelQuery({
    variables: { id },
    skip: !id,
  });

  return {
    data,
    isLoading: loading,
  };
};
