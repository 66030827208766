export const UNKNOWN_FIELD_ID = 'this-is-not-an-id';

export const clearHeaders = (headers: string[]) => {
  return headers?.map((header) => {
    if (header === UNKNOWN_FIELD_ID) {
      return null;
    }

    return header;
  });
};
