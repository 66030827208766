import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

type UseQueryParams<T> = {
  [K in keyof T]: T[K] extends Array<any> ? string[] : string;
};

export const useQueryParams = <T extends Record<string, unknown>>(): [
  UseQueryParams<T>,
  string,
] => {
  const { search } = useLocation();

  const params: URLSearchParams = useMemo(
    () => new URLSearchParams(search),
    [search],
  );

  const paramsObj = {} as UseQueryParams<T>;

  for (const key of params.keys()) {
    const values = params.getAll(key);
    paramsObj[key as keyof T] = (
      values.length > 1 ? values : values[0]
    ) as UseQueryParams<T>[keyof T];
  }

  return [paramsObj, search];
};
