import { useCallback, useState } from 'react';

import { useGetMarketingPopupSignedUrlLazyQuery } from '@app/api/gql/generated-types';
import ToastNotify from '@app/components/ToastNotifier';
import { catchErrorLog } from '@app/utils/logger';

export const useUploadImageWithSignedUrl = () => {
  const [isUploading, setIsUploading] = useState(false);

  const [query, { loading }] = useGetMarketingPopupSignedUrlLazyQuery();

  const upload = useCallback(
    async (image: File) => {
      try {
        setIsUploading(true);
        const {
          data: {
            getMarketingPopupSignedUrl: { signedUrl, filePath },
          },
        } = await query({
          variables: {
            input: {
              fileName: image?.name,
              contentType: image.type,
            },
          },
        });
        await fetch(signedUrl, {
          method: 'PUT',
          body: image,
          headers: {
            'Content-Type': image.type,
          },
        });
        // TODO: https://github.com/smarter-contact-team/sc-web-app/pull/907/#discussion_r1017962122
        ToastNotify({
          title: 'The image has been uploaded',
          status: 'success',
        });

        return filePath;
      } catch (error) {
        catchErrorLog(error, 'admin/uploadImage');
        // TODO: https://github.com/smarter-contact-team/sc-web-app/pull/907/#discussion_r1017962122
        ToastNotify({
          title: "The image hasn't been uploaded. Please try again later",
          status: 'error',
        });
      } finally {
        setIsUploading(false);
      }
    },
    [query],
  );

  return {
    loading: loading || isUploading,
    upload,
  };
};
