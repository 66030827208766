import React from 'react';
import { Link, Text } from '@chakra-ui/react';

import video from '@app/utils/videoLinks';

import { SettingsLayout } from '@app/layouts/SettingsLayout';
import Tooltip from '../Tooltip';

import { SelectTrialNumber } from './SelectTrialNumber';

export const TrialNumbers = () => {
  return (
    <SettingsLayout
      alignItems="center"
      childComponent={<SelectTrialNumber />}
      iconComponent={
        <Tooltip
          text={
            <Text mr="5px">
              This number is for sending messages and expires once Trial is
              finished. Click{' '}
              <Link
                color="switchedBlue.600"
                href={video.settings.trial.explain}
                target="_blank">
                here
              </Link>{' '}
              for more info.
            </Text>
          }
          widthContainer="250px"
        />
      }
      marginTop="20px"
      titleLabel="Trial number"
    />
  );
};
