import { Box, Button, Center } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import omit from 'lodash/omit';

import Popup from '@app/components/Popup';
import { catchErrorLog } from '@app/utils/logger';

import {
  ListOptions,
  ListView,
  useUpdateListMutation,
} from '@app/api/gql/generated-types';
import { ContactFilters } from './ContactFilters';

export type ContactSettingsProps = {
  isOpen: boolean;
  onClose: () => void;
  contactList: ListView;
  startTimer: () => void;
  verifyList: () => void;
};

export const ContactSettings: FC<ContactSettingsProps> = ({
  isOpen,
  onClose,
  contactList,
  startTimer,
  verifyList,
}) => {
  const { contactListId } = useParams<{ contactListId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [updateListMutation] = useUpdateListMutation();

  const onSubmit = async (values: ListView['options']) => {
    try {
      startTimer();
      setIsLoading(true);

      await updateListMutation({
        variables: {
          id: contactListId,
          input: {
            options: values,
          },
        },
        optimisticResponse: {
          updateList: {
            ...contactList,
            options: values,
          },
        },
      });
    } catch (error) {
      catchErrorLog(error, 'ContactSettings');
    }

    verifyList();
  };

  // Reset so it doesn't stay on
  useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
    }
  }, [isOpen]);

  const initialValues: ListOptions = {
    ...omit(contactList?.options, ['__typename']),
  };

  return (
    <Popup
      closeOnOverlayClick
      isOpen={isOpen}
      maxW="475px"
      size="2xl"
      title="Contact management"
      onClose={onClose}>
      <Box p="40px" pt="27px">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <ContactFilters />

              <Center flexDirection="column" mt="32px">
                <Button
                  isLoading={isLoading}
                  type="submit"
                  variant="primary"
                  w="190px">
                  Apply
                </Button>
              </Center>
            </Form>
          )}
        </Formik>
      </Box>
    </Popup>
  );
};
