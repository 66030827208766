import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  SimpleGrid,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import _chunk from 'lodash/chunk';
import isEmpty from 'lodash/isEmpty';
import React, { FC, memo } from 'react';

import isNil from 'lodash/isNil';
import { useLabelsQueryData } from '@app/api/queries/useLabelsQueryData';
import { LoadMoreSecondaryButton } from '@app/components/LoadMoreSecondaryButton';

import RenderPopoverContent from '../RenderPopoverContent';

const MAX_SINGLE_COLUMN_LENGTH = 5;
const MAX_NUMBER_OF_COLUMNS = 2;

interface Item {
  value: string[];
  onChange: (item: string[]) => void;
}

const FilterByLabel: FC<Item> = ({ onChange, value }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: options = [],
    isLoading,
    fetchNextPage,
    nextPageToken,
  } = useLabelsQueryData();

  const labelsChunks = _chunk(
    options,
    options.length > MAX_SINGLE_COLUMN_LENGTH
      ? Math.round(options.length / MAX_NUMBER_OF_COLUMNS)
      : options.length,
  );

  return (
    <Flex
      align="center"
      bg="white"
      borderStyle="solid"
      color="gray.600"
      ml="15px"
      position="relative">
      <Flex alignItems="flex-start" direction="row" id="filter-box">
        <RenderPopoverContent
          filterTitle={
            <Text as="span" color="osloGray">
              Labels
            </Text>
          }
          filterValue={
            !!value.length && (
              <Text as="span" color="main.400" ml="5px">
                {value.length}
              </Text>
            )
          }
          isOpen={isOpen}
          placement="bottom-start"
          onClose={onClose}
          onOpen={onOpen}>
          {isEmpty(options) ? (
            <Text color="main.400" fontSize="14px">
              No labels yet
            </Text>
          ) : (
            <CheckboxGroup value={value} onChange={onChange}>
              <Box maxHeight="320px" overflow="scroll">
                <SimpleGrid columnGap={3} columns={labelsChunks?.length ?? 1}>
                  {labelsChunks.map((labelChunk) => (
                    <Flex key={`chunk-${labelChunk[0].id}`} direction="column">
                      {labelChunk.map((option) => (
                        <Checkbox
                          key={option?.id}
                          value={option?.id}
                          variant="filter">
                          {option?.title}
                        </Checkbox>
                      ))}
                    </Flex>
                  ))}
                </SimpleGrid>
                <Flex justifyContent="center">
                  <Box mt="20px" w="150px">
                    <LoadMoreSecondaryButton
                      isFullWidth
                      fetchNextPage={fetchNextPage}
                      hasNextPage={!isNil(nextPageToken)}
                      isLoading={isLoading}
                    />
                  </Box>
                </Flex>
              </Box>
            </CheckboxGroup>
          )}
        </RenderPopoverContent>
      </Flex>
    </Flex>
  );
};

export default memo(FilterByLabel);
