import { formatNumber } from '@app/components/FormatNumber/utils';
import { useAccountQuery } from '@app/api/gql/generated-types';
import { centsToDollars } from '@app/utils/formatNumber';
import { getLogger } from '@app/utils/loggerUtils';
import { STANDARD_FRACTION_DIGITS } from '@app/constants/configuration';

const LOG = getLogger('useAccountBalance');

export const useAccountBalance = () => {
  const {
    data: { account } = {},
    refetch,
    loading,
  } = useAccountQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const rawBalance = account?.billingProfile?.balance?.balance || 0;

  const balanceInDollars = centsToDollars(rawBalance);
  const balance = formatNumber({
    value: balanceInDollars,
    fractionDigits: STANDARD_FRACTION_DIGITS,
  });

  return LOG.return({
    rawBalance,
    balance,
    balanceInDollars,
    billingProfile: account?.billingProfile,
    subscription: account?.subscription,
    refetch,
    loading,
    featurePrices: Object.fromEntries(
      account?.features?.map(({ type, price }) => [type, price]) ?? [],
    ),
  });
};
