import { DateTime } from 'luxon';
import {
  Campaign,
  CampaignRuleStrategy,
  CampaignScheduleOptions,
  CampaignStatus,
  CampaignType,
  Frequency,
  MessageTemplateType,
  SequenceRule,
  SequenceType,
  SequenceView,
} from '@app/api/gql/generated-types';
import {
  MIN_TIME,
  MAX_TIME,
} from '@app/widgets/DTPicker/hooks/useDisabledDates';

export const NAME_MAX_VALUE = 51;
export const NAME_MAX_INPUT_VALUE = 50;
export const MESSAGES_CHUNK_SIZE = 500;

const MIN_PHONES_COUNT_WITHOUT_TEMPLATE = 50;

export const batchOptions = {
  perBatch: 100,
  interval: 1,
  frequency: Frequency.MINUTELY,
};

export const restrictOptions = {
  timeRange: {
    from: { hour: MIN_TIME, minute: 0 },
    to: { hour: MAX_TIME, minute: 0 },
  },
};

export const campaignRule: SequenceRule = {
  removeFromNextSequences: false,
  strategy: CampaignRuleStrategy.NO_REPLY,
  groupId: null,
  labelId: null,
};

export const campaignSequence: SequenceView = {
  type: SequenceType.SMS,
  templates: [],
  rule: null,
  content: undefined,
  delay: 0,
  hasCustomTimeRestrictions: false,
  days: [],
  progress: {
    sent: 0,
    total: 0,
  },
  ...restrictOptions,
};

export const scheduleOptions: CampaignScheduleOptions = {
  startAt: DateTime.fromJSDate(new Date()).toISO(),
  hasCustomStartDate: false,
  hasCustomBatchSize: false,
  ...batchOptions,
};

export const initialValues: Partial<Campaign> = {
  name: '',
  state: {
    status: CampaignStatus.DRAFT,
  },
  type: CampaignType.STANDARD,
  groups: [],
  sequences: [{ ...campaignSequence }],
  scheduleOptions,
  ownPhones: [],
};

export const getMessageTemplatesCount = (contacts: number) => {
  return Math.min(Math.ceil(contacts / MIN_PHONES_COUNT_WITHOUT_TEMPLATE), 8);
};

export const getMinMessagesCount = ({
  contacts,
  isMessagePlanTrialing,
  sequenceType,
  selectedTemplatesCount,
}: {
  contacts: number;
  isMessagePlanTrialing: boolean;
  sequenceType: SequenceType;
  selectedTemplatesCount: number;
}) => {
  const templatesCount =
    isMessagePlanTrialing || sequenceType === SequenceType.RVM
      ? 1
      : getMessageTemplatesCount(contacts);
  return Math.max(templatesCount - selectedTemplatesCount, 0);
};

export const campaignTypeToTemplateMap = {
  [SequenceType.SMS]: MessageTemplateType.SMS,
  [SequenceType.RVM]: MessageTemplateType.RVM,
};
