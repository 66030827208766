import React from 'react';
import { components, SingleValueProps } from 'react-select';
import { OwnPhoneFragment } from '@app/api/gql/generated-types';
import SelectLabel from './SelectLabel';

export const SingleValue = <
  T extends OwnPhoneFragment,
  M extends boolean = false,
>({
  ...props
}: SingleValueProps<T, M>) => (
  <components.SingleValue {...props}>
    <SelectLabel label={props.data.number} location={props.data.location} />
  </components.SingleValue>
);
