import Cookies from 'js-cookie';

export const SUPPORTED_CLICK_IDS = ['msclkid', 'fbclid', 'gclid'];

export function collectClid() {
  const params = new URLSearchParams(window.location.search);

  for (const clickId of SUPPORTED_CLICK_IDS) {
    if (params.has(clickId)) {
      Cookies.set(clickId, params.get(clickId));
    }
  }
}
