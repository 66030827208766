import { upperFirst, trimStart, isString } from 'lodash/fp';

export const normalizeNameString = (value: unknown): string => {
  if (typeof value !== 'string') {
    return '';
  }

  return isString(value) ? trimStart(upperFirst(value.toLowerCase())) : value;
};

export const wordPluralize = (
  count: number,
  word: string,
  suffix: string = 's',
): string => `${word}${Math.abs(count) === 1 ? '' : suffix}`;

/**
 * Removes emojis from a string.
 *
 * @param {string} name - The subject string.
 * @returns {string} - The string without emojis.
 */
export const removeEmoji = (name: string) => {
  return name.replace(
    /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/gu,
    '',
  );
};

/**
 * Checks if a given string consists solely of numeric digits.
 *
 * @param {string} value - The string to check.
 * @returns {boolean} - Returns true if the string contains only numeric digits, otherwise false.
 */
export const isNumeric = (value: string): boolean => /^[0-9]+$/.test(value);
