import { colors } from '../colors';

const Textarea = {
  variants: {
    primary: {
      border: '1px',
      borderRadius: '22px',
      borderColor: 'secondary.200',
      fontSize: '14px',
      color: 'secondary.400',
      px: { base: 3, lg: 5 },
      _focus: {
        borderColor: 'primary.700',
        color: 'main.400',
      },
      _disabled: {
        borderColor: 'secondary.200',
        backgroundColor: colors.catskillWhite,
        color: 'main.100',
      },
      _invalid: {
        borderColor: colors.error,
      },
      _placeholder: { color: 'secondary.400' },
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: 'white',
        margin: '18px 0px',
      },
      '::-webkit-scrollbar-thumb': {
        background: colors.main[100],
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: colors.main[100],
      },
    },
    standard: {
      border: '1px',
      borderRadius: '22px',
      borderColor: 'secondary.200',
      fontSize: '14px',
      color: 'main.400',
      minHeight: 'auto',
      px: { base: 3, lg: 5 },
      _focus: {
        borderColor: 'primary.700',
        color: 'main.400',
      },
      _disabled: {
        borderColor: 'secondary.200',
        backgroundColor: colors.catskillWhite,
        color: 'main.100',
      },
      _invalid: {
        borderColor: colors.error,
      },
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: 'white',
        margin: '18px 0px',
      },
      '::-webkit-scrollbar-thumb': {
        background: colors.main[100],
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: colors.main[100],
      },
    },
  },
};

export default Textarea;
