import { Box } from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import React, { FC, lazy, Suspense, useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';

import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useRoutes,
} from 'react-router-dom';
import { ErrorFallback } from '@app/components/ErrorFallback';
import { PageTitle } from '@app/components/PageTitle';
import { useBootstrap } from '@app/hooks/useBootstrap';
import { useUserTracker } from '@app/hooks/useUserTracker';
import CurrentAccountProvider from '@app/providers/CurrentAccountProvider';
import { DialerProvider } from '@app/providers/DialerProvider';
import PopupsProvider from '@app/providers/PopupsProvider';
import { lazyRetry } from '@app/utils/lazyRetry';
import { PowerDialerProvider } from '@app/providers/PowerDialerProvider';
import { savePromoCode } from '@app/utils/promoCodeUtils';
import { PROMO_CODE_KEY } from '@app/constants/configuration';
import { CurrentDialerProvider } from '@app/providers/CurrentDialerProvider';
import { getQueryParam } from '@app/utils/queryParamUtils';
import { PermissionModalProvider } from '@app/contexts/providers/usePermissionModalProvider';
import { PermissionAbilityProvider } from '@app/contexts/providers/usePermissionAbilityProvider';
import { PhoneNumberVerification } from '@app/pages/PhoneNumberVerification';
import { DefaultLayout } from '@app/layouts/DefaultLayout';
import { isAccountAbandoned } from '@app/utils/accountUtils';
import { AuthSkeleton } from '@app/components/Skeleton/AuthSkeleton';
import Skeleton from '../components/Skeleton';
import ROUTES, {
  isCurrentRouteLogin,
  isCurrentRouteLogout,
  isCurrentRouteSecurityPassword,
  isCurrentRouteSignUp,
} from '../utils/routes';

import UINavigationPanel from './UINavigationPanel';
import { getInitialRoute } from './utils';
import { routesConfig } from './routes';

const AuthNavigation = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "AuthNavigation" */ './AuthNavigation'),
  ),
);
const Questionnaire = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "Questionnaire" */ '@app/pages/Questionnaire'
      ),
  ),
);

const Dialer = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Dialer" */ '@app/features/Dialer'),
  ),
);

const Navigation: FC = () => {
  const location = useLocation();
  const data = useBootstrap();
  const initialRoute = getInitialRoute(data);

  const isLogout = isCurrentRouteLogin(location);
  const isLogin = isCurrentRouteLogout(location);
  const isSecurityPasswordRoute = isCurrentRouteSecurityPassword(location);
  const isSignUpRoute = isCurrentRouteSignUp(location);

  const [isPhoneVerificationInProgress, setIsPhoneVerificationInProgress] =
    useState(false);

  const showQuestionnaire =
    data?.phoneVerified && isAccountAbandoned(data?.account);
  const showPhoneNumberVerification =
    isPhoneVerificationInProgress ||
    (!data?.phoneVerified && data?.isSocialSignUp);
  const showApp =
    !isLogout && data?.account && data?.emailVerified && !showQuestionnaire;

  const routes = useRoutes(routesConfig);

  useUserTracker(data?.firebaseAuthUser);

  useEffect(() => {
    const promoCode = getQueryParam(PROMO_CODE_KEY);

    if (isLogin && promoCode) {
      savePromoCode(promoCode);
    }
  }, [location.search, isLogin]);

  const useNavigateComponent =
    initialRoute && !isSecurityPasswordRoute && !isPhoneVerificationInProgress;

  if (data.resetPasswordRequired && !isSecurityPasswordRoute) {
    return <Navigate to={ROUTES.securityResetPasswordInfo} />;
  }

  if (!data.bootstrapped && !isSignUpRoute) {
    return <Skeleton />;
  }

  return (
    <>
      {useNavigateComponent && <Navigate to={initialRoute} />}
      <PageTitle />

      <Suspense fallback={<AuthSkeleton />}>
        <AuthNavigation />
      </Suspense>

      {showPhoneNumberVerification && (
        <Suspense fallback={<Skeleton />}>
          <Routes>
            <Route
              element={
                <PhoneNumberVerification
                  setIsPhoneVerificationInProgress={
                    setIsPhoneVerificationInProgress
                  }
                />
              }
              path={ROUTES.securityPhoneVerification}
            />
          </Routes>
        </Suspense>
      )}
      {showQuestionnaire && (
        <Suspense fallback={<Skeleton />}>
          <Routes>
            <Route
              element={<Questionnaire user={data.firebaseAuthUser} />}
              path={ROUTES.questionnaire}
            />
          </Routes>
        </Suspense>
      )}
      {showApp && (
        <ErrorBoundary
          fallback={({ resetError }) => (
            <ErrorFallback resetError={resetError} />
          )}>
          <CurrentAccountProvider account={data.account} role={data.role}>
            <PopupsProvider>
              <PermissionAbilityProvider>
                <PermissionModalProvider>
                  <CurrentDialerProvider>
                    <DialerProvider>
                      <PowerDialerProvider>
                        <Suspense fallback={<Skeleton />}>
                          {isBrowser && <UINavigationPanel />}
                        </Suspense>
                        <Box mx={['0px', '20px']}>
                          <Suspense fallback={<DefaultLayout />}>
                            {routes}
                          </Suspense>
                          <Dialer />
                        </Box>
                      </PowerDialerProvider>
                    </DialerProvider>
                  </CurrentDialerProvider>
                </PermissionModalProvider>
              </PermissionAbilityProvider>
            </PopupsProvider>
          </CurrentAccountProvider>
        </ErrorBoundary>
      )}
    </>
  );
};

export default Navigation;
