import { FormControl, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { FC, useCallback, useMemo } from 'react';
import {
  components,
  MultiValue,
  OptionProps,
  SingleValueProps,
} from 'react-select';

import Label from '@app/components/FormItem/Label';
import SelectField from '@app/components/SelectField';
import {
  localPhoneCodes,
  PhoneCodesType,
  tollFreePhoneCodes,
} from '@app/utils/phoneCodes';

import { OwnPhoneType, Scope } from '@app/api/gql/generated-types';
import { BuyNumberForm } from './types';
import { useMaxAllowedPhones } from './useMaxAllowedPhones';

interface SelectPrefixProps {
  onClear: () => void;
  isDisabled: boolean;
  scope: Scope;
}

const SingleValue = <T extends PhoneCodesType, M extends boolean = false>({
  children,
  ...props
}: SingleValueProps<T, M>) => (
  <components.SingleValue {...props}>
    <Text color={props?.isDisabled && 'main-100'} variant="heading-fifth">
      {children}{' '}
      <Text as="span" color="secondary.400">
        ({props?.data?.areaCode})
      </Text>
    </Text>
  </components.SingleValue>
);

const Option = <T extends PhoneCodesType, M extends boolean = false>(
  props: OptionProps<T, M>,
) => {
  return (
    <components.Option {...props}>
      <Text variant="heading-fifth">
        {props?.label}{' '}
        <Text as="span" color="secondary.400">
          ({props?.data?.areaCode})
        </Text>
      </Text>
    </components.Option>
  );
};

export const SelectPrefix: FC<SelectPrefixProps> = ({
  onClear,
  isDisabled,
  scope,
}) => {
  const { values, setValues } = useFormikContext<BuyNumberForm>();
  const { byType } = useMaxAllowedPhones(scope);
  const options = useMemo(
    () =>
      values?.type === OwnPhoneType.TOLL_FREE
        ? tollFreePhoneCodes
        : localPhoneCodes,
    [values?.type],
  );

  const disabled = useMemo(() => byType(values.type), [byType, values.type]);

  const onChange = useCallback(
    (value: PhoneCodesType | MultiValue<PhoneCodesType>) => {
      const phoneCodesValue = value as PhoneCodesType;

      void setValues({ ...values, areaCode: phoneCodesValue?.areaCode });
      onClear();
    },
    [onClear, setValues, values],
  );

  const value = useMemo(() => {
    if (!values.areaCode) {
      return null;
    }
    if (values?.type === OwnPhoneType.TOLL_FREE) {
      return tollFreePhoneCodes.find(
        (phone) => phone.areaCode === values.areaCode,
      );
    }
    return localPhoneCodes.find((phone) => phone.areaCode === values.areaCode);
  }, [values.areaCode, values?.type]);

  return (
    <FormControl>
      <Label>Area and prefix</Label>
      <SelectField
        isSearchable
        components={{ Option, SingleValue }}
        getOptionLabel={(option) => option?.state}
        getOptionValue={(option) => option?.areaCode}
        isDisabled={isDisabled || disabled}
        options={options}
        placeholder="Select area and prefix"
        value={value}
        onChange={onChange}
      />
    </FormControl>
  );
};
