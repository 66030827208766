import React, { FC, PropsWithChildren, useRef } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useAuth } from 'reactfire';

import { initApolloClient } from '../api/apollo-client';

export const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();

  const apolloClient = useRef(initApolloClient({ auth }));

  return (
    <ApolloProvider client={apolloClient.current}>{children}</ApolloProvider>
  );
};
