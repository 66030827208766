import {
  Box,
  Checkbox,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, MouseEvent, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { DateTime } from 'luxon';
import CheckboxChecked from '@app/icons/checkbox-checked.svg?react';
import CheckboxPartial from '@app/icons/checkbox-partial.svg?react';

import DateFormat from '@app/components/DateFormat';
import { Skiptrace } from '@app/api/gql/generated-types';
import { SkiptraceInfo } from './SkiptraceInfo';
import { StylesTable } from './styles';
import TableListRowAction from './TableListRowAction';

interface TableListProps {
  contactListData: Skiptrace[];
  selectedLists: string[];
  onSelectedLists: (data: string[]) => void;
  onCheckboxChange: (id: string, event: MouseEvent<HTMLDivElement>) => void;
}

export const TableList: FC<TableListProps> = ({
  contactListData,
  selectedLists,
  onSelectedLists,
  onCheckboxChange,
}) => {
  const scrollRef = useRef<HTMLTableSectionElement>();
  const navigate = useNavigate();

  const goToPage = useCallback((urlRoute: string, e: MouseEvent) => {
    e.stopPropagation();
    navigate(`/skiptrace/edit/${urlRoute}`, { state: { urlRoute } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allSelected = useMemo(
    () => contactListData?.length === selectedLists?.length,
    [contactListData, selectedLists],
  );

  const onToggleSelectAll = useCallback(() => {
    if (allSelected) {
      onSelectedLists([]);
    } else {
      onSelectedLists(contactListData?.map((list) => list?.id));
    }
  }, [allSelected, onSelectedLists, contactListData]);

  return (
    <Box width="100%">
      <StylesTable ref={scrollRef}>
        <Table>
          <Thead>
            <Tr>
              <Th width="55px">
                {!isEmpty(contactListData) ? (
                  <Box ml="6px">
                    <Checkbox
                      icon={
                        !isEmpty(selectedLists) ? (
                          <CheckboxPartial />
                        ) : (
                          <CheckboxChecked />
                        )
                      }
                      isChecked={!isEmpty(selectedLists)}
                      variant={
                        !isEmpty(selectedLists) ? 'primaryPartial' : 'primary'
                      }
                      onChange={onToggleSelectAll}
                    />
                  </Box>
                ) : (
                  ''
                )}
              </Th>
              <Th minWidth="300px">Name</Th>
              <Th>Phone numbers</Th>
              <Th>Mobile</Th>
              <Th>Landline</Th>
              <Th>Created</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {contactListData?.map((item) => (
              <Tr
                key={item.id}
                _hover={{
                  backgroundColor: selectedLists.includes(item.id)
                    ? 'primary.100'
                    : 'zircon',
                }}
                background={selectedLists.includes(item.id) && 'primary.100'}
                cursor="pointer"
                id={item.id}
                onClick={(event) => goToPage(item.id, event)}>
                <Td width="55px">
                  <Box
                    ml="6px"
                    onClick={(event) => {
                      onCheckboxChange(item.id, event);
                    }}>
                    <Checkbox
                      icon={<CheckboxChecked />}
                      isChecked={selectedLists.includes(item.id)}
                      variant="primary"
                    />
                  </Box>
                </Td>
                <Td minWidth="300px">{item?.fileName || '-'} </Td>
                <SkiptraceInfo
                  landlines={item?.landlineContacts}
                  mobiles={item?.mobileContacts}
                />
                <Td>
                  <DateFormat
                    date={DateTime.fromISO(item?.createdAt).toJSDate()}
                  />
                </Td>
                <TableListRowAction
                  id={item.id}
                  onSelectedLists={onSelectedLists}
                />
              </Tr>
            ))}
          </Tbody>
          <Tfoot />
        </Table>
      </StylesTable>
    </Box>
  );
};
