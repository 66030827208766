import React, {
  CSSProperties,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  CloseButton,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { fillTemplate } from '@smarter-contact-team/messages';
import { Enum } from '@app/utils/enum';
import { TTextVariant } from '@app/theme/components/text';
import { MacrosLink } from '@app/pages/Messenger/styles';
import SearchIcon from '@app/icons/search-icon.svg?react';
import ROUTES from '@app/utils/routes';
import { TThemeSize } from '@app/theme/components/size';
import { TCloseButtonVariant } from '@app/theme/components/closeButton';
import { MacroModalSkeletonLoader } from '@app/components/next/moleculas/MacroModalSkeletonLoader';
import {
  MacroFragment,
  useFindMacrosLazyQuery,
  useGetContactQuery,
} from '@app/api/gql/generated-types';
import { customScrollbarCssProps } from '@app/utils/customScrollbar';
import EmptyMacros from '@app/icons/empty-macros.svg?react';
import { useOutsideClick } from '@app/hooks/useOutsideClick';
import { TInputVariant } from '@app/theme/components/input';
import { toPercentage, toPx } from '@app/utils/cssUtils';
import { DEFAULT_PAGE_SIZE } from '@app/constants/configuration';
import { createCursorPaginationVariables } from '@app/api/apollo-pagination';
import { useFields } from '@app/hooks/useFields';
import { getLogger } from '@app/utils/logger';
import { colors } from '@app/theme/colors';

export const TMacroFloatingModalPosition = new Enum('left', 'right', 'mobile');

const LOG = getLogger('MacroFloatingModal');

interface IMacroFloatingModalProps {
  ref?: React.RefObject<HTMLDivElement>;
  style?: CSSProperties;
  onClose?: () => void;
  handleScrollEnd?: () => void;
  onMacroSelect?: (macro: MacroFragment) => void;
  isLoading?: boolean;
  position?: typeof TMacroFloatingModalPosition.type;
  contactId: string;
  isOpen: boolean;
}

const EmptySearchResult: React.FC = () => (
  <Flex flexDirection="column" marginTop="70px" textAlign="center">
    <Text marginBottom="10px" variant="heading-5">
      No results found
    </Text>
    <Text color="main.400" fontSize="14px" padding="0 20px">
      Try rewording your search or entering a new keyword.
    </Text>
  </Flex>
);

const EmptyState: React.FC = () => (
  <Flex
    alignItems="center"
    flexDirection="column"
    height="full"
    justifyContent="center">
    <Box mb="26px" mt="35px">
      <EmptyMacros />
    </Box>
    <Flex alignItems="center" flexDirection="column">
      <Text variant={TTextVariant.enum['sub-heading']}>
        You don’t have any macros
      </Text>
      <Text mt="5px" variant={TTextVariant.enum['secondary-text']}>
        When you do, you’ll see them here.
      </Text>
    </Flex>
  </Flex>
);

const SearchInput: React.FC<{
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ handleSearch }) => (
  <Box p="0 20px 10px 10px">
    <InputGroup>
      <Input
        borderRadius="22px"
        focusBorderColor="primary.200"
        name="filter"
        placeholder="Search"
        style={{
          width: '100%',
        }}
        type="text"
        variant={TInputVariant.enum.search}
        onChange={handleSearch}
      />
      <InputRightElement>
        <Box mr="11px" mt="-4px">
          <SearchIcon
            style={{
              color: colors.main[400],
            }}
          />
        </Box>
      </InputRightElement>
    </InputGroup>
  </Box>
);

const MacroList: React.FC<{
  macrosList: MacroFragment[];
  onMacroSelect: (macro: MacroFragment) => void;
}> = ({ macrosList, onMacroSelect }) => (
  <>
    {macrosList.map((macro) => (
      <Box
        key={macro.id}
        _hover={{ background: 'primary.200', borderRadius: '10px' }}
        cursor="pointer"
        m="0 5px 0 0"
        p="5px 11px 2px"
        onClick={() => onMacroSelect(macro)}>
        <Text color="main.400" fontSize="14px">
          {macro.name}
        </Text>
        <Text color="secondary.400" fontSize="14px">
          {macro.content}
        </Text>
      </Box>
    ))}
  </>
);

export const MacroFloatingModal: React.FC<IMacroFloatingModalProps> = ({
  position = TMacroFloatingModalPosition.enum.left,
  style,
  onMacroSelect,
  onClose,
  contactId,
}) => {
  const ref = useOutsideClick<HTMLDivElement>(
    () => {
      onClose?.();
    },
    [],
    ['message-macro-trigger-button', 'message-macro-trigger-icon'],
  );
  const [searchPhrase, setSearchPhrase] = useState('');
  const positionStyles: CSSProperties = TMacroFloatingModalPosition.match(
    position,
    {
      [TMacroFloatingModalPosition.enum.left]: () => ({
        left: 0,
        top: 0,
        height: 'calc(-164px + 100vh)',
        zIndex: 9,
      }),
      [TMacroFloatingModalPosition.enum.right]: () => ({
        left: toPx(-430),
        top: toPx(107),
        height: 'calc(100vh - 198px)',
      }),
      [TMacroFloatingModalPosition.enum.mobile]: () => ({
        zIndex: 9,
        bottom: toPx(162),
        height: 'calc(-300px + 100vh)',
        left: toPx(9),
        width: toPercentage(95),
      }),
    },
    () => ({}),
  );
  const [
    fetchMacros,
    {
      data: { findMacros: macrosData } = {
        findMacros: { items: [], total: 0 },
      },
      loading: isLoading,
      fetchMore,
    },
  ] = useFindMacrosLazyQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      pagination: {
        limit: DEFAULT_PAGE_SIZE,
      },
      filter: {
        search: searchPhrase,
      },
    },
  });

  useEffect(() => {
    fetchMacros()
      .then(() => {
        LOG.info('Macros fetched');
      })
      .catch((error) => {
        LOG.error('Error fetching macros', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchNextPage = () => {
    if (macrosData?.nextPageToken && !isLoading) {
      fetchMore(
        createCursorPaginationVariables(macrosData, DEFAULT_PAGE_SIZE),
      ).catch((error) => {
        LOG.error('Error fetching macros', error);
      });
    }
  };

  const { data: { getContact: data } = {}, loading: contactLoading } =
    useGetContactQuery({
      variables: {
        id: contactId,
      },
    });

  const { mappedFields } = useFields(data);

  const macroList = useMemo(() => {
    return (
      macrosData.items?.map((macro) => ({
        ...macro,
        content: fillTemplate(macro?.content, mappedFields),
      })) || []
    );
  }, [macrosData, mappedFields]);

  const displayStates = {
    showLoading: (isLoading || contactLoading) && macroList.length === 0,
    showEmptyState:
      searchPhrase.length === 0 &&
      macroList.length === 0 &&
      !(isLoading || contactLoading),
    showSearch: !(
      searchPhrase.length === 0 &&
      macroList.length === 0 &&
      !isLoading
    ),
    showList: macroList.length > 0,
    showSearchEmptyState:
      searchPhrase.length > 0 &&
      macroList.length === 0 &&
      !(isLoading || contactLoading),
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(e.target.value);
  };

  const onScrollEnd = (event: SyntheticEvent) => {
    const isBottomReached =
      event.currentTarget.scrollHeight - 50 <=
      event.currentTarget.scrollTop + event.currentTarget.clientHeight;

    if (isBottomReached) {
      fetchNextPage();
    }
  };

  return (
    <Flex
      ref={ref}
      bg="white"
      border="1px solid"
      borderColor="secondary.200"
      borderRadius="20px"
      bottom="-28px"
      boxShadow="0px 1px 4px 0px rgba(0, 0, 0, 0.08)"
      flexDirection="column"
      position="absolute"
      style={{ ...style, ...positionStyles }}
      w="430px"
      zIndex={99}>
      <Flex
        alignItems="center"
        borderBottom="1px solid"
        borderBottomColor="secondary.200"
        justifyContent="space-between"
        p="8px 19px"
        position="relative">
        <Box>
          <Text variant={TTextVariant.enum['modal-title']}>Macro</Text>
        </Box>
        <Box>
          <MacrosLink to={ROUTES.messengerMacros}>
            Add new/edit macro
          </MacrosLink>
        </Box>
        <Box h="24px" w="24px">
          <CloseButton
            size={TThemeSize.enum.sm}
            style={{
              position: 'absolute',
              right: '10px',
            }}
            variant={TCloseButtonVariant.enum.modal}
            onClick={onClose}
          />
        </Box>
      </Flex>
      <Flex
        flexDirection="column"
        flexGrow="1"
        h="full"
        overflow="hidden"
        p="10px 0 10px 9px"
        w="full">
        {displayStates.showSearch && (
          <SearchInput handleSearch={handleSearch} />
        )}
        <Box
          __css={customScrollbarCssProps}
          flexGrow="1"
          margin="0 5px 0 0"
          overflowY={displayStates.showLoading ? 'hidden' : 'auto'}
          onScrollCapture={onScrollEnd}>
          {displayStates.showLoading && <MacroModalSkeletonLoader />}
          {displayStates.showSearchEmptyState && <EmptySearchResult />}
          {displayStates.showEmptyState && <EmptyState />}
          {displayStates.showList && (
            <MacroList macrosList={macroList} onMacroSelect={onMacroSelect} />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
