/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DateTime } from 'luxon';

const MONTH = 'MONTH';
const WEEK = 'WEEK';
const DAY = 'DAY';

const getDaysInMonthMode = (date: DateTime) => {
  const startDateOfMonth = date.startOf('month');
  const endDateOfMonth = date.endOf('month');
  const startDateOfCalendar = startDateOfMonth.startOf('week');
  const endDateOfCalendar = endDateOfMonth.endOf('month');

  const days =
    endDateOfCalendar.diff(startDateOfCalendar, 'days').days <= 35 ? 35 : 42;
  return Array(days)
    .fill(0)
    .map((_, i) => date.startOf('month').startOf('week').plus({ days: i }));
};

const getDifferenceByMode = (mode: string) => {
  if (mode === MONTH) {
    return { months: 1 };
  }
  if (mode === WEEK) {
    return { week: 1 };
  }
  if (mode === DAY) {
    return { days: 1 };
  }

  return { days: 0 };
};

const getHoursInDay = () =>
  Array(12)
    .fill(0)
    .map((_, i) => {
      if (i < 9) return `0${i + 1}`;
      return (i + 1).toString();
    });

const getMinutesInHours = () =>
  Array(60)
    .fill(0)
    .map((_, i) => {
      if (i < 1) return '00';
      if (i < 10) return `0${i}`;
      return i.toString();
    });

const getTimeFormat = () => ['AM', 'PM'];

export {
  getDifferenceByMode,
  getDaysInMonthMode,
  getHoursInDay,
  getMinutesInHours,
  getTimeFormat,
  MONTH,
  WEEK,
  DAY,
};
