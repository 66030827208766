import { useEffect, useState } from 'react';
import { useSigninCheck, useAuth } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { signInWithCustomToken, inMemoryPersistence } from 'firebase/auth';
import ROUTES from '@app/utils/routes';

export const CLAIMS_KEY: unique symbol = Symbol('claims');

const useImpersonateStyles = (token: string) => {
  useEffect(() => {
    if (token) {
      document.body.classList.add('impersonate');
    }
  }, [token]);
};

export const useInitialUserDetection = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const { status, data } = useSigninCheck({
    // This is the only way to get the claims from the auth library synchronously.
    validateCustomClaims: (claims) => {
      return {
        hasRequiredClaims: true,
        claims,
        errors: {
          [CLAIMS_KEY]: claims,
        },
      };
    },
  });
  const impersonate = location.pathname.match(/impersonate\/(.*)/);
  const token = impersonate?.[1];

  const [impersonateLoading, setImpersonateLoading] = useState(
    () => !!impersonate,
  );

  useImpersonateStyles(token);

  useEffect(() => {
    const run = async () => {
      try {
        await auth.setPersistence(inMemoryPersistence);
        await signInWithCustomToken(auth, token);

        navigate(`${ROUTES.root}`, { replace: true });
      } catch (error) {
        console.error(error);
      } finally {
        setImpersonateLoading(false);
      }
    };

    if (token) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      run();
    }
  }, [token, auth, navigate]);

  return {
    status: impersonateLoading ? 'loading' : status,
    data,
  };
};
