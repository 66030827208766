import React, { FC, useCallback, useState } from 'react';
import { Box, Divider, Flex } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';

import { Label } from '@app/api/gql/generated-types';

import { useQueryParams } from '@app/hooks/useQueryParams';
import { customScrollbarSecondaryCssProps } from '@app/utils/customScrollbar';
import { LabelListItem } from './LabelListItem';

interface LabelListProps {
  options: Label[];
  defaultValues: Label[];
  onChange: (labels: Label[]) => void;
}

export const LabelsCheckbox: FC<LabelListProps> = ({
  options,
  defaultValues,
  onChange,
}) => {
  const [{ accountId }] = useQueryParams<{
    accountId: string;
  }>();
  const isMasterAccount = !!accountId;
  const [selected, setSelected] = useState(defaultValues ?? []);

  const selectedIds = selected.map((option) => option.id);
  const restOptions = options.filter(
    (label) => !selectedIds.includes(label.id),
  );

  const handleChange = useCallback(
    (label: Label) => {
      let newLabels = [...selected];

      const index = selected.findIndex((option) => option.id === label.id);

      if (index !== -1) {
        newLabels.splice(index, 1);
      } else {
        newLabels = [...newLabels, label];
      }

      onChange(newLabels);
      setSelected(newLabels);
    },
    [selected, onChange],
  );

  return (
    <Box
      __css={customScrollbarSecondaryCssProps}
      marginY="16px"
      overflowY="auto"
      paddingX="16px">
      <Flex direction="column">
        {selected.map((item) => (
          <LabelListItem
            key={item.id}
            isChecked={true}
            isMasterAccount={isMasterAccount}
            label={item}
            onChange={handleChange}
          />
        ))}
      </Flex>
      {!isEmpty(restOptions) && <Divider margin="16px 0" width="100%" />}
      <Flex direction="column">
        {restOptions.map((item) => (
          <LabelListItem
            key={item.id}
            isChecked={false}
            isMasterAccount={isMasterAccount}
            label={item}
            onChange={handleChange}
          />
        ))}
      </Flex>
    </Box>
  );
};
