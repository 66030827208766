import { ChakraProvider } from '@chakra-ui/react';
import React, { FC, ReactNode, useRef } from 'react';
import theme from './theme';

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <ChakraProvider
      resetCSS
      portalZIndex={40}
      theme={theme}
      toastOptions={{
        portalProps: {
          containerRef: ref,
        },
      }}>
      {children}
      {
        // Allow toast to be in front of modal
        <div ref={ref} />
      }
    </ChakraProvider>
  );
};
