import { Enum } from '@app/utils/enum';

/**
 * Represents a utility class for dealing with CSS units.
 * @class
 */
export const TCssUtils = new Enum('px', 'em', 'rem', '%', 'vh');

/**
 * Converts a numerical value to a CSS unit string.
 *
 * @param {number} value - The numerical value to convert.
 * @param {typeof TCssUtils.type} unit - The CSS unit to append to the value.
 * @returns {string} - The converted CSS unit string.
 */
export const toCssUnit = (value: number, unit: typeof TCssUtils.type): string =>
  `${value}${unit}`;

/**
 * Converts a number value to a string representation with "px" unit.
 *
 * @param {number} value - The number value to convert.
 * @returns {string} - The converted value with "px" unit.
 */
export const toPx = (value: number): string =>
  toCssUnit(value, TCssUtils.enum.px);

/**
 * Converts a number value to a string representation with "em" unit.
 *
 * @param {number} value - The number value to convert.
 * @returns {string} - The converted value with "em" unit.
 */
export const toEm = (value: number): string =>
  toCssUnit(value, TCssUtils.enum.em);

/**
 * Converts a number value to a string representation with "rem" unit.
 *
 * @param {number} value - The number value to convert.
 * @returns {string} - The converted value with "rem" unit.
 */
export const toRem = (value: number): string =>
  toCssUnit(value, TCssUtils.enum.rem);

/**
 * Converts a number value to a string representation with "%" unit.
 *
 * @param {number} value - The number value to convert.
 * @returns {string} - The converted value with "%" unit.
 */
export const toPercentage = (value: number): string =>
  toCssUnit(value, TCssUtils.enum['%']);

export const toVh = (value: number): string =>
  toCssUnit(value, TCssUtils.enum.vh);
