import styled from '@emotion/styled';
import { colors } from '@app/theme/colors';
import { Mode } from './types';

interface DateTimeWrapStyleTypes {
  mode: Mode;
  isMobile?: boolean;
}

interface InputStylesProps {
  isOpen?: boolean;
}

const DateTimeWrapStyle = styled.div<DateTimeWrapStyleTypes>`
  display: grid;
  width: ${({ mode }) =>
    mode === Mode.TIME ? '172px' : mode === Mode.DATE ? '315px' : '500px'};
  height: ${({ isMobile, mode }) =>
    isMobile && mode === Mode.TIME ? '235px' : '300px'};
  grid-template-columns: ${({ mode }) =>
    mode === Mode.DATETIME ? '1fr 1fr' : '1fr'};
  z-index: 1000;
  background: ${colors.white};
  border: 1px solid ${colors.secondary[200]};
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 22px;
`;

const InputStyles = styled.input<InputStylesProps>`
  background: ${({ disabled }) =>
    disabled ? colors.catskillWhite : colors.white};
  border: ${({ disabled }) =>
    disabled
      ? `1px solid ${colors.secondary[200]}`
      : `1px solid ${colors.secondary[200]}`};
  border-color: ${({ isOpen }) => isOpen && colors.primary[600]};
  box-sizing: border-box;
  border-radius: 22px;
  height: 40px;
  width: 100%;
  line-height: 36px;
  padding: 4px 35px 4px 20px;
  color: ${({ disabled }) => (disabled ? colors.main[100] : colors.main[400])};
  font-size: 14px;
`;

const InputIconStyles = styled.div`
  position: absolute;
  right: 15px;
  top: 11px;
  width: 16px;
  height: 16px;
`;

export { DateTimeWrapStyle, InputStyles, InputIconStyles };
