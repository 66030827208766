import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import React, { FC } from 'react';

import VerifyContactsIcon from '@app/icons/contacts/verify-contacts-list.svg?react';

export const VerifyBadge: FC = () => (
  <Tag
    bg="primary.100"
    borderRadius="10px"
    lineHeight="1"
    ml="15px"
    p="3px 6px">
    <TagLeftIcon as={VerifyContactsIcon} />
    <TagLabel
      color="secondary.400"
      fontSize="12px"
      fontStyle="normal"
      fontWeight="normal"
      lineHeight="14px">
      Unverified
    </TagLabel>
  </Tag>
);
