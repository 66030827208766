import { createContext, ReactNode } from 'react';
import { UseDisclosureProps } from '@chakra-ui/react';

export type ConfirmStateType = {
  title: ReactNode;
  description: ReactNode;
  confirmButtonText: string;
  hideCancelButton?: boolean;
  verticalActions?: boolean;
  maxWidth?: string;
  confirmButtonWidth?: string;
  cancelButtonWidth?: string;
  padding?: string;
};

export enum TrialModalType {
  LIMIT = 'limit',
  ATTENTION = 'attention',
  USED = 'used',
  NO_PHONES = 'noPhones',
}

interface PopupsContextProps {
  activateAccountModal: UseDisclosureProps;
  activateDialerSubscriptionModal: UseDisclosureProps;
  activateMessagingSubscriptionModal: UseDisclosureProps;
  subscriptionAccessDeniedModal: UseDisclosureProps;
  dlcSubmitModal: UseDisclosureProps;
  dlcPendingStatusModal: UseDisclosureProps;
  dlcUpdateAccountModal: UseDisclosureProps;
  mobileAlertModal: UseDisclosureProps;
  confirmModal: UseDisclosureProps;
  errorModal: UseDisclosureProps;
  error: string;
  setError: (error?: string) => void;

  /**
   *  @deprecated use useCustomModal with  the content you need to add similar to permissionCustomodalContent
   **/
  showTrialPopup: (type: TrialModalType) => void;
  /**
   *  @deprecated use useCustomModal with  the content you need to add similar to permissionCustomodalContent
   **/
  showConfirmPopup: (data: ConfirmStateType) => Promise<unknown>;
  /**
   *  @deprecated use useCustomModal with  the content you need to add similar to permissionCustomodalContent
   **/
  closeConfirmPopup: () => void;
  /**
   *  @deprecated use useCustomModal with  the content you need to add similar to permissionCustomodalContent
   **/
  showMobilePhonePopup: (canClose?: boolean) => Promise<unknown>;
  /**
   *  @deprecated use useCustomModal with  the content you need to add similar to permissionCustomodalContent
   **/
  closeMobilePhoneModal: () => void;
  outsideBusinessHoursModal: UseDisclosureProps;
}

const PopupsContext = createContext<PopupsContextProps>(null);

export default PopupsContext;
