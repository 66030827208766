import React, { FC, ReactNode } from 'react';
import { Flex } from '@chakra-ui/react';

const TabIcon: FC<{ isActive?: boolean; children: ReactNode }> = ({
  isActive = false,
  children,
}) => {
  return (
    <Flex
      alignItems="center"
      background={isActive ? 'primary.600' : 'white'}
      border="1px solid"
      borderColor={isActive ? 'primary.600' : 'mystic'}
      borderRadius="full"
      color={isActive ? 'white' : 'primary.600'}
      h="24px"
      justifyContent="center"
      w="24px">
      {children}
    </Flex>
  );
};

export default TabIcon;
