import React, { FC } from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';

import MarkAsReadIcon from '@app/icons/messenger/mark-as-read-icon.svg?react';
import MarkAsUnreadIcon from '@app/icons/messenger/mark-as-unread-icon.svg?react';
import {
  Conversation,
  ConversationFragmentDoc,
  useMarkConversationsReadMutation,
} from '@app/api/gql/generated-types';
import * as typenames from '@app/api/typenames';
import { colors } from '@app/theme/colors';
import type { BulkActionButtonProps } from './types';

interface MarkAsButtonProps extends BulkActionButtonProps {
  type: 'read' | 'unread';
}

export const MarkAsButton: FC<MarkAsButtonProps> = ({
  type,
  selectedContacts,
  onComplete,
}) => {
  const [mutateSetRead, { loading }] = useMarkConversationsReadMutation();

  const handleClick = () => {
    void mutateSetRead({
      variables: {
        input: {
          ids: selectedContacts,
          isRead: type === 'read',
        },
      },
      update(cache, _result, { variables }) {
        variables?.input?.ids.forEach((id) => {
          cache.updateFragment<Conversation>(
            {
              id: `${typenames.Conversation}:${id}`,
              fragment: ConversationFragmentDoc,
              fragmentName: typenames.Conversation,
            },
            (data) => ({
              ...data,
              isRead: variables.input.isRead,
            }),
          );
        });
      },
      context: {
        notify: {
          success: () => `Conversation(s) marked as ${type}`,
          error: () => `Conversation(s) failed to mark as ${type}`,
        },
      },
    });

    onComplete();
  };

  return (
    <Tooltip hasArrow label={`Mark as ${type}`} placement="top">
      <IconButton
        isRound
        _active={{ bg: colors.transparent }}
        _focus={{ outline: 'none' }}
        _hover={{ color: 'primary.600', bgColor: 'primary.200' }}
        aria-label={`Mark as ${type}`}
        bg={colors.transparent}
        color="main.100"
        isLoading={loading}
        size="xs"
        onClick={handleClick}>
        {type === 'read' ? <MarkAsReadIcon /> : <MarkAsUnreadIcon />}
      </IconButton>
    </Tooltip>
  );
};
