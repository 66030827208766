import { Flex, IconButton, Td, Tr } from '@chakra-ui/react';
import React, { FC, useCallback, useState } from 'react';

import { ApolloError, useApolloClient } from '@apollo/client';
import EditDoneIcon from '@app/icons/edit-done-icon.svg?react';
import EditTextIcon from '@app/icons/edit-text-icon.svg?react';

import {
  FindListItemsDocument,
  useRemoveListItemMutation,
  useUpdateListItemMutation,
} from '@app/api/gql/generated-types';
import { colors } from '@app/theme/colors';
import IconButtonRemove from '../IconButtonRemove';

import Cell from './Cell';
import { EditSkeletonRow } from './EditSkeletonRow';

interface RowProps {
  rowId: string;
  row: string[];
  editable: boolean;
  listId: string;
}

const Row: FC<RowProps> = ({ rowId, row, editable, listId }) => {
  const client = useApolloClient();
  const [lineId, setLineId] = useState<string>(null);
  const [rowEdit, setRowEdit] = useState<string[]>([]);
  const [mutateRemove, { loading: isLoadingRemove }] =
    useRemoveListItemMutation({
      refetchQueries: [
        {
          query: FindListItemsDocument,
          variables: {
            listId,
          },
        },
      ],
      awaitRefetchQueries: true,
    });
  const [mutateUpdate, { loading: isLoadingUpdate }] =
    useUpdateListItemMutation({
      refetchQueries: [
        {
          query: FindListItemsDocument,
          variables: {
            listId,
          },
        },
      ],
      awaitRefetchQueries: true,
    });

  const onEditCell = useCallback(
    (indexCell: number, valueCell: string) => {
      const newArr = rowEdit?.map((val, index) => {
        if (index === indexCell) {
          return valueCell;
        }
        return val;
      });
      setRowEdit(newArr);
    },
    [setRowEdit, rowEdit],
  );

  const removeContact = useCallback(async () => {
    await mutateRemove({
      variables: {
        id: rowId,
      },
      context: {
        notify: {
          success: () => 'Removed from group successfully',
          error: (error: ApolloError[]) => error[0].message,
        },
      },
    });
    client.cache.evict({ fieldName: 'getSkiptrace' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateRemove, rowId]);

  const rowSave = useCallback(async () => {
    await mutateUpdate({
      variables: {
        id: rowId,
        input: {
          data: rowEdit,
        },
      },
      context: {
        notify: {
          success: () => 'The data was successfully updated',
          error: (error: ApolloError[]) => error[0].message,
        },
      },
    });
    setLineId(null);
    setRowEdit(null);
  }, [mutateUpdate, rowEdit, rowId]);

  const onEditRow = useCallback(
    (id: string, rowData: string[]) => {
      setLineId(id);
      setRowEdit(rowData);
    },
    [setRowEdit, setLineId],
  );

  return (
    <Tr key={`row-${rowId}`}>
      {row.map((cell, indexCell) =>
        isLoadingUpdate ? (
          <EditSkeletonRow
            key={`row-${rowId}-cell-${cell?.toString()}-${indexCell}`}
          />
        ) : (
          <Cell
            key={`row-${rowId}-cell-${cell?.toString()}-${indexCell}`}
            cell={cell}
            indexCell={indexCell}
            isEdit={lineId === rowId}
            onEditCell={onEditCell}
          />
        ),
      )}
      <Td
        border-bottom={`1px solid ${colors.notFound[1900]}`}
        color={colors.main[400]}
        fontFamily="'Roboto', sans-serif;"
        fontSize="14px"
        height="16px"
        lineHeight="16px"
        maxWidth="220px"
        padding="20px 26px"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        width="210px">
        <Flex flexDirection="row" justifyContent="end">
          {editable && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {lineId === rowId ? (
                <IconButton
                  _hover={{ color: 'primary.600' }}
                  aria-label="Save contact list row fields"
                  color="main.100"
                  ml="20px"
                  mr="4px"
                  variant="ghostPlain"
                  onClick={rowSave}>
                  <EditDoneIcon />
                </IconButton>
              ) : (
                <IconButton
                  _hover={{ color: 'primary.600' }}
                  aria-label="Edit contact list row fields"
                  color="main.100"
                  ml="20px"
                  mr="4px"
                  variant="ghostPlain"
                  onClick={() => onEditRow(rowId, row)}>
                  <EditTextIcon />
                </IconButton>
              )}
            </>
          )}
          <IconButtonRemove
            isDisabled={isLoadingUpdate || isLoadingRemove}
            isLoading={isLoadingRemove}
            ml="20px"
            onClick={removeContact}
          />
        </Flex>
      </Td>
    </Tr>
  );
};

export default Row;
