import React, { FC } from 'react';
import { Box, Button, Center, Flex } from '@chakra-ui/react';
import type { UseDisclosureProps } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import isNumber from 'lodash/isNumber';

import Popup from '@app/components/Popup';
import FormItemTextArea from '@app/components/FormItem/FormItemTextArea';
import { catchErrorLog } from '@app/utils/logger';

import {
  CreateFeedbackInput,
  useCreateFeedbackMutation,
} from '@app/api/gql/generated-types';
import { feedbackSchema } from './schema';
import { FeedbackControl } from './FeedbackControl';

const initialValues: CreateFeedbackInput = {
  score: null,
  message: '',
};

export const CustomerFeedbackModal: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const [createFeedbackMutation, { loading }] = useCreateFeedbackMutation();

  const handleSubmit = async (values: CreateFeedbackInput) => {
    try {
      await createFeedbackMutation({
        variables: {
          input: {
            score: values.score,
            ...(values.message && { message: values.message }),
          },
        },
      });
      onClose();
    } catch (error) {
      catchErrorLog(error, 'CustomerFeedbackModal/handleSubmit');
    }
  };

  const handleClose = async () => {
    try {
      onClose();
      await createFeedbackMutation({
        variables: {
          input: {
            score: 0,
          },
        },
      });
    } catch (error) {
      catchErrorLog(error, 'CustomerFeedbackModal/handleClose');
      onClose();
    }
  };

  return (
    <Popup
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      maxW="440px"
      title="How do you rate our service?"
      width="100%"
      onClose={handleClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={feedbackSchema}
        onSubmit={handleSubmit}>
        {({ values, errors }) => (
          <Form>
            <Box p="23px 40px 40px">
              <Flex justifyContent="space-between" marginBottom="25px">
                <FeedbackControl isLoading={loading} value={1} />
                <FeedbackControl isLoading={loading} value={2} />
                <FeedbackControl isLoading={loading} value={3} />
                <FeedbackControl isLoading={loading} value={4} />
                <FeedbackControl isLoading={loading} value={5} />
              </Flex>

              {isNumber(values.score) && (
                <Box marginBottom="25px">
                  <FormItemTextArea
                    name="message"
                    placeholder="We’d love to hear your thoughts.."
                    resize="none"
                  />
                </Box>
              )}

              <Center>
                <Button
                  isDisabled={
                    loading ||
                    !!Object.keys(errors).length ||
                    !isNumber(values.score)
                  }
                  isLoading={loading}
                  loadingText="Submitting…"
                  type="submit"
                  variant="primary">
                  Submit
                </Button>
              </Center>
            </Box>
          </Form>
        )}
      </Formik>
    </Popup>
  );
};
