import React, { FC } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import isString from 'lodash/';

export const LastMessageText: FC<
  TextProps & { read: boolean; hasBlur?: boolean }
> = ({ children, read, hasBlur = false, ...props }) => {
  let blurredText = children;

  if (hasBlur && isString(children)) {
    blurredText = `Hey, I'm interested to visit the property we talked about. Can we do it tomorrow`;
  }

  return (
    <Text
      filter={hasBlur ? 'blur(3.5px)' : 'none'}
      fontSize="14px"
      fontWeight={read ? '400' : '700'}
      minWidth="17px"
      mr="10px"
      overflow="hidden"
      textOverflow="ellipsis"
      userSelect={hasBlur ? 'none' : 'text'}
      variant="heading-fifth"
      whiteSpace="nowrap"
      {...props}>
      {blurredText}
    </Text>
  );
};
