import React, { useState } from 'react';

import { ButtonGroup, Text, useDisclosure } from '@chakra-ui/react';
import { Column } from 'react-table';
import { CallScript } from '@app/api/gql/generated-types';

import IconButtonEdit from '@app/components/IconButtonEdit';
import IconButtonRemove from '@app/components/IconButtonRemove';
import useActivateDialerSubscriptionGuard from '@app/hooks/useActivateDialerSubscriptionGuard';

export const useTableColumns = () => {
  const editModal = useDisclosure();
  const removeModal = useDisclosure();

  const [script, setScript] = useState<CallScript>();

  const openModalRemove = (item: CallScript) => {
    removeModal.onOpen();
    setScript(item);
  };

  const guardActionEdit = useActivateDialerSubscriptionGuard(
    (item: CallScript) => {
      setScript(item);
      editModal.onOpen();
    },
  );

  const columns: Column<CallScript>[] = [
    {
      Header: 'Name',
      accessor: 'name',
      maxWidth: 150,
      minWidth: 60,
      width: 140,
      Cell: (cell) => (
        <Text color="main.400" fontSize="14px">
          {cell.row.original.name}
        </Text>
      ),
    },
    {
      Header: 'Content',
      accessor: 'content',
      minWidth: 500,
      Cell: (cell) => (
        <Text
          color="main.400"
          fontSize="14px"
          maxW="600px"
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 2, // Limits to 2 lines
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}>
          {cell?.row?.original?.content}
        </Text>
      ),
    },
    {
      Header: 'Action',
      id: 'action',
      accessor: 'id',
      maxWidth: 70,
      minWidth: 50,
      width: 60,
      Cell: (cell) => (
        <ButtonGroup
          alignItems="center"
          color="main.400"
          fontSize="14px"
          width="40px">
          <IconButtonEdit onClick={() => guardActionEdit(cell.row.original)} />
          <IconButtonRemove
            onClick={() => openModalRemove(cell.row.original)}
          />
        </ButtonGroup>
      ),
    },
  ];
  return {
    columns,
    script,
    removeModal,
    editModal,
  };
};
