import { Campaign, GroupView, Label } from '@app/api/gql/generated-types';
import { Enum } from '@app/utils/enum';

export const TExportTypes = new Enum('Campaigns', 'Groups', 'Labels');

export interface ExportOptionProps {
  label: string;
  value: string;
}

export interface ExportFormProps {
  exportBy: typeof TExportTypes.type;
  items: string[];
  exportTo: string;
}

export interface SelectedCampaign extends Campaign {
  value: Campaign['id'];
  label: string;
  isChecked?: boolean;
}

export interface SelectedGroup extends GroupView {
  value: GroupView['id'];
  label: string;
  isChecked?: boolean;
}

export interface SelectedLabel extends Label {
  value: Label['id'];
  label: string;
  isChecked?: boolean;
}

export type ExportOption<T> = { label: T; value: T };

export const categoryOptions: ExportOption<typeof TExportTypes.type>[] = [
  { label: TExportTypes.enum.Campaigns, value: TExportTypes.enum.Campaigns },
  { label: TExportTypes.enum.Groups, value: TExportTypes.enum.Groups },
  { label: TExportTypes.enum.Labels, value: TExportTypes.enum.Labels },
];
