import { Box, Switch } from '@chakra-ui/react';
import React, { FC } from 'react';

import isEmpty from 'lodash/isEmpty';
import { Features } from '@app/acl';
import {
  AccountQuery,
  FeatureType,
  MessagingProfile,
  PhoneSettingsFilter,
  QuotaType,
  useUpdateMessagingProfileMutation,
} from '@app/api/gql/generated-types';
import { useDisableFeatureAbility } from '@app/hooks/useDisableFeatureAbility';

import { useUpdateMessagingProfileFragment } from '@app/api/hooks/useUpdateMessagingProfileFragment';
import { getMessageSubscription } from '@app/utils/subscriptionHelpers';
import { SettingsLayout } from '@app/layouts/SettingsLayout';

import { Campaigns } from './Campaigns';
import { Groups } from './Groups';
import { Labels } from './Labels';
import Tooltip from './Tooltip';

interface MobileTextAlertsProps {
  account: AccountQuery['account'];
  messagingProfile?: MessagingProfile;
}

export const MobileTextAlerts: FC<MobileTextAlertsProps> = ({
  account,
  messagingProfile,
}) => {
  const { updatePhoneSettingsFragment } = useUpdateMessagingProfileFragment();
  const [updateMessagingProfile] = useUpdateMessagingProfileMutation();

  const { can: showMobileTextAlerts } = useDisableFeatureAbility(
    Features.ShowMobileTextAlerts,
  );

  const { can: canMobileTextAlerts } = useDisableFeatureAbility(
    Features.MobileTextAlerts,
  );

  const currentMessageSubscription = getMessageSubscription(account);

  const isNotEmpty = !isEmpty(messagingProfile?.phoneSettings?.alertFilter);

  const handleSwitch = async (val: React.ChangeEvent<HTMLInputElement>) => {
    const alertFilter: PhoneSettingsFilter = val.target.checked
      ? {
          campaigns: [],
          groups: [],
          labels: [],
        }
      : null;
    updatePhoneSettingsFragment({
      id: messagingProfile?.id,
      phoneSettings: {
        alertFilter,
      },
    });
    await updateMessagingProfile({
      variables: {
        input: {
          phoneSettings: {
            alertFilter,
          },
        },
      },
    });
  };

  const mobileTextAlertUsed =
    account?.quotasUsage?.find((quota) => quota.type === QuotaType.SMS_ALERT)
      ?.used ?? 0;

  const mobileTextAlertLimit =
    currentMessageSubscription?.plan?.features?.find(
      (limit) => limit.type === FeatureType.SMS_ALERT,
    )?.tier ?? 100;

  const mobileTextAlertText =
    mobileTextAlertUsed >= mobileTextAlertLimit
      ? '$0.01 per SMS'
      : `(${
          mobileTextAlertUsed ?? 0
        } of ${mobileTextAlertLimit} free messages)`;

  return (
    <>
      {showMobileTextAlerts && (
        <SettingsLayout
          childComponent={
            <Switch
              colorScheme="switchedBlue"
              h="20px"
              isChecked={isNotEmpty}
              isDisabled={!canMobileTextAlerts}
              w="38px"
              onChange={handleSwitch}
            />
          }
          iconComponent={
            <Tooltip
              text="Mobile text alerts sends a copy of your incoming messages to your mobile phone. The first 100 per month are free and charges $.01/sms after"
              widthContainer="262px"
            />
          }
          marginTop="30px"
          mobileAlertText={mobileTextAlertText}
          titleLabel="Mobile text alert"
        />
      )}

      {isNotEmpty && (
        <Box>
          <SettingsLayout
            childComponent={<Campaigns />}
            marginTop="30px"
            titleLabel="Alert campaigns"
          />
          <SettingsLayout
            childComponent={<Groups />}
            marginTop="20px"
            titleLabel="Alert groups"
          />
          <SettingsLayout
            childComponent={<Labels />}
            marginTop="20px"
            titleLabel="Alert labels"
          />
        </Box>
      )}
    </>
  );
};
