import {
  useDisclosure,
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerBody,
  Icon,
} from '@chakra-ui/react';
import React, { FC, FunctionComponent, ReactNode } from 'react';

import FilterIcon from '@app/icons/filter-icon.svg?react';
import { colors } from '@app/theme/colors';

interface BottomSheetModalProps {
  title?: string;
  children: ReactNode;
  icon?: FunctionComponent;
}

export const BottomSheetModal: FC<BottomSheetModalProps> = ({
  title,
  children,
  icon = FilterIcon,
}) => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <Flex>
      <Flex ml="10px" onClick={onToggle}>
        <Icon
          as={icon}
          color={isOpen ? colors.primary[600] : colors.mystic}
          h="40px"
          w="40px"
        />
      </Flex>
      <Drawer
        autoFocus={false}
        blockScrollOnMount={false}
        isOpen={isOpen}
        placement="bottom"
        size="full"
        onClose={onClose}>
        <DrawerOverlay background="rgba(36, 47, 87, 0.24)">
          <DrawerContent borderRadius="20px 20px 0px 0px" maxHeight="82vh">
            <DrawerCloseButton color="secondary.400" m="5px 0px" size="lg" />
            <DrawerHeader color="main.400" p="20px 0px 0px 25px">
              {title}
            </DrawerHeader>
            <DrawerBody overflow="scroll" pb="0" pt="7px" px="0px">
              {children}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  );
};
