import React, { lazy, FC } from 'react';
import { lazyRetry } from '@app/utils/lazyRetry';

const Popup = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Popup" */ '@app/components/Popup'),
  ),
);

interface LoomPlayerTypes {
  isOpenModal: boolean;
  onCloseModal: () => void;
  link: string;
}

const LoomPlayer: FC<LoomPlayerTypes> = ({
  isOpenModal,
  onCloseModal,
  link,
}) => {
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon
      isOpen={isOpenModal}
      size="2xl"
      title={null}
      onClose={onCloseModal}>
      <iframe
        allowFullScreen
        frameBorder="0"
        height="500px"
        src={link}
        width="700"
      />
    </Popup>
  );
};

export default LoomPlayer;
