import {
  MIN_LENGTH_INPUT_FULL_SEARCH,
  MIN_LENGTH_INPUT_SHORT_FULL_SEARCH,
} from '@app/constants/configuration';

export const fullTextInputValidation = (searchValue: string) =>
  searchValue?.length >= MIN_LENGTH_INPUT_FULL_SEARCH;

export const fullTextShortInputValidation = (searchValue: string) =>
  searchValue?.length >= MIN_LENGTH_INPUT_SHORT_FULL_SEARCH;
