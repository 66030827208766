import React, { FC } from 'react';

import { BodyMonthGridItem } from '../styles';

interface BodyMonthElementTemplateTypes {
  children?: string;
  onSelect: () => void;
  isOffRange?: boolean;
  isToday?: boolean;
  isSelectedDate: boolean;
  isDisabledStartDate?: boolean;
  isDisabledEndDate?: boolean;
}

export const BodyMonthElement: FC<BodyMonthElementTemplateTypes> = ({
  children,
  isOffRange,
  isToday,
  onSelect,
  isSelectedDate,
  isDisabledStartDate,
  isDisabledEndDate,
}) => {
  return (
    <BodyMonthGridItem
      isDisabled={isDisabledStartDate || isDisabledEndDate}
      isOffRange={isOffRange}
      isSelectedDate={isSelectedDate}
      isToday={isToday}
      onClick={onSelect}>
      {children}
    </BodyMonthGridItem>
  );
};
