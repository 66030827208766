import { Tooltip, Box, Image, Flex, Text } from '@chakra-ui/react';
import React, { useRef, useState, useEffect, FC } from 'react';

import MessageIcon from '@app/icons/campaign-message-icon.svg?react';

import { MessageTooltip } from './MessageTooltip';

type OverflownTextProps = {
  children: string;
  title: string;
  color?: string;
  width?: string;
};

export const OverflownText: FC<OverflownTextProps> = ({
  children,
  title,
  ...props
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    const element = ref.current;
    setIsOverflown(element.scrollWidth > element.clientWidth);
  }, []);

  return (
    <Flex>
      <Box ref={ref} noOfLines={1} {...props}>
        <Text
          dangerouslySetInnerHTML={{
            __html: children,
          }}
        />
      </Box>
      {isOverflown && (
        <Tooltip
          hasArrow
          label={<MessageTooltip content={children} title={title} />}
          maxW="270px"
          p="10px"
          placement="right">
          <Image as={MessageIcon} cursor="pointer" />
        </Tooltip>
      )}
    </Flex>
  );
};
