import { object, string } from 'yup';

export const MAX_INPUT_LENGTH_NAME = 35;

import { stdTitleSpecialCharactersSupportedRegex } from '@app/schemas/common';

const nameSchema = string()
  .trim()
  .required('')
  .max(
    MAX_INPUT_LENGTH_NAME,
    `Name can have maximum ${MAX_INPUT_LENGTH_NAME} characters`,
  )
  .matches(stdTitleSpecialCharactersSupportedRegex, {
    message: "Special characters supported: ?#@%^*-',.",
  });

export const useValidationSchemaScript = () => {
  return object().shape({
    name: nameSchema,
    content: string().required(''),
  });
};
