import React, { FC } from 'react';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { CustomerFeedbackValues } from './types';

interface FeedbackControlProps {
  value: number;
  isLoading?: boolean;
}

const IconMap: {
  [key: number]: string;
} = {
  1: '/images/feedback/1.png',
  2: '/images/feedback/2.png',
  3: '/images/feedback/3.png',
  4: '/images/feedback/4.png',
  5: '/images/feedback/5.png',
};

const TitleMap: {
  [key: number]: string;
} = {
  1: 'Terrible',
  2: 'Bad',
  3: 'Normal',
  4: 'Good',
  5: 'Awesome',
};

export const FeedbackControl: FC<FeedbackControlProps> = ({
  value,
  isLoading,
}) => {
  const {
    values: { score },
    setFieldValue,
  } = useFormikContext<CustomerFeedbackValues>();

  const handleClick = async () => {
    if (isLoading) {
      return;
    }
    await setFieldValue('score', value);
  };

  const isSelected = score === value;

  return (
    <Box
      _last={{
        marginRight: 0,
      }}
      cursor="pointer"
      marginRight="15px"
      textAlign="center"
      onClick={handleClick}>
      <Flex
        alignItems="center"
        backgroundColor={isSelected ? 'primary.600' : 'primary.100'}
        borderRadius="35px"
        height="60px"
        justifyContent="center"
        marginBottom="5px"
        transition="all 100ms ease-in"
        width="60px">
        <Image height="37.85px" src={IconMap[value]} width="35px" />
      </Flex>
      <Text fontSize="12px" fontWeight={isSelected ? 700 : 400}>
        {TitleMap[value]}
      </Text>
    </Box>
  );
};
