import React, { FC, UIEvent } from 'react';
import { Center, Divider, Flex, PopoverBody, Text } from '@chakra-ui/react';
import { NotificationMetaItem } from '../NotificationsPopoverContent/utils';
import { NotificationsPopoverContentItem } from '../NotificationsPopoverContentItem/NotificationsPopoverContentItem';

interface NotificationsPopoverBodyProps {
  filteredItems: NotificationMetaItem[];
  clickedUnread: boolean;
  isShowNoResults: boolean;
  isShowNoResultsAll: boolean;
  isShowNoResultsUnread: boolean;
  handleScroll: (e: UIEvent<HTMLDivElement>) => void;
  popoverBodyRef: React.MutableRefObject<HTMLDivElement>;
  isLastPage: boolean;
  itemsUnreadLength: number;
}

export const NotificationsPopoverBody: FC<NotificationsPopoverBodyProps> = ({
  filteredItems,
  clickedUnread,
  isShowNoResults,
  isShowNoResultsAll,
  isShowNoResultsUnread,
  handleScroll,
  popoverBodyRef,
  isLastPage,
  itemsUnreadLength,
}) => {
  const showThatsAllNotifications = () => {
    //for all tab items we have the paginations
    //for unread tab we need to compare to the counter beacause of filtering in FE
    // filtering in FE to avoid hit the BE twice. for each type of notification ALL/UNREAD
    return (
      (!clickedUnread && isLastPage) ||
      (itemsUnreadLength === filteredItems.length && clickedUnread)
    );
  };

  return (
    <PopoverBody
      ref={popoverBodyRef}
      maxHeight="472px"
      overflowX="hidden"
      overflowY="scroll"
      paddingLeft="8px"
      paddingRight="0px"
      sx={{
        '&::-webkit-scrollbar': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '20px',
          backgroundColor: `main.100`,
        },
        '&::-webkit-scrollbar-track ': {
          backgroundColor: 'white',
        },
      }}
      onScrollCapture={handleScroll}>
      {isShowNoResultsUnread && (
        <Text
          align="center"
          color="secondary.400"
          fontSize="14px"
          fontWeight="400"
          lineHeight="normal"
          pb="37px"
          pt="37px">
          You have no unread notifications
        </Text>
      )}
      {isShowNoResultsAll && (
        <Text
          align="center"
          color="secondary.400"
          fontSize="14px"
          fontWeight="400"
          lineHeight="normal"
          pb="37px"
          pt="37px">
          You have no notifications
        </Text>
      )}
      {((!isShowNoResults && !clickedUnread) ||
        (!isShowNoResultsUnread && clickedUnread)) && (
        <>
          <Flex direction="column" paddingLeft="0px" paddingRight="0px">
            {filteredItems.map((item) => (
              <NotificationsPopoverContentItem
                key={item.id}
                clickedUnread={clickedUnread}
                item={item}
              />
            ))}
          </Flex>
          {showThatsAllNotifications() && (
            <>
              <Divider
                background="mystic"
                height="1px"
                margin={['12px 0', '12px 0']}
                width={['100%', '298px']}
              />
              <Center
                fontSize="12px"
                fontWeight="400"
                lineHeight="normal"
                pb="12px">
                {`That's all your notifications from the last 7 days.`}
              </Center>
            </>
          )}
        </>
      )}
    </PopoverBody>
  );
};
