import isNil from 'lodash/isNil';
import isFinite from 'lodash/isFinite';
import { formatNumber } from '@app/components/FormatNumber/utils';
import { TCurrencySymbol } from '@app/utils/priceFormatUtils';

export const getPercent = (count: number, overallCount = 1, fraction = 2) => {
  const result = (count / overallCount) * 100;

  return (isNil(result) || !isFinite(result) ? 0 : result).toFixed(fraction);
};

export const centsToDollars = (cents: number): number => cents / 100;

/**
 * Converts a price to a monthly price.
 *
 * @param {number} price - The yearly price.
 * @returns {number} - The converted monthly price.
 */
export const toMonthlyPrice = (yearlyPrice: number): number => yearlyPrice / 12;

/**
 * Converts a price to a quarterly price.
 *
 * @param {number} price - The display price with discount.
 * @returns {number} - The converted quarterly price.
 */
export const toQuarterlyMonthlyPrice = (price: number): number => price / 3;
export const formatPrice = (amount: number): string => {
  return formatNumber({
    value: centsToDollars(amount),
    fractionDigits: 0,
  });
};

export const roundPriceToNearestHundreds = <T>(
  price: number,
  props: { format: true; predicate: typeof TCurrencySymbol.type } = {
    format: true,
    predicate: TCurrencySymbol.enum.$,
  },
): T => {
  const res = Math.ceil(centsToDollars(price) / 100) * 100;

  if (props.format) {
    const priceFormat = formatNumber({
      value: res,
      fractionDigits: 0,
    });

    return `${TCurrencySymbol.enum.$}${priceFormat}` as T;
  }

  return res as T;
};

export const toFormatedStringNumber = (value: string): number =>
  Number(value.replace(/[^0-9.-]+/g, ''));

export const formatNumberWithCommas = (input: number | string): string => {
  const numStr = typeof input === 'number' ? input.toString() : input;
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
