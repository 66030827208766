import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import ArrowIcon from '@app/icons/arrow-icon.svg?react';

interface ILoadMessagesButtonProps {
  onClick: () => void;
}

export const LoadMessagesButton: React.FC<ILoadMessagesButtonProps> = ({
  onClick,
}) => {
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <Box
      style={{
        position: 'absolute',
        top: '142px',
        left: '50%',
        zIndex: 100,
        borderRadius: '32px',
        transform: 'translate(-50%, -50%)',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      }}>
      <Button
        height="30px"
        leftIcon={
          <ArrowIcon
            style={{
              width: '12px',
              height: '12px',
            }}
          />
        }
        padding="unset"
        variant="primary"
        width="108px"
        onClick={handleOnClick}>
        <Text fontSize="12" fontWeight="700" ml="-4px">
          New activity
        </Text>
      </Button>
    </Box>
  );
};
