import React, { FC } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import ROUTES from '@app/utils/routes';
import { usePlanPermission } from '@app/hooks/usePlanPermission';

export const ProtectedRouteGuard: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isBasicPlan } = usePlanPermission();

  if (!isBasicPlan) {
    return <Outlet />;
  } else {
    navigate(ROUTES.notFound, { state: { from: location } });
    return null;
  }
};
