import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AccountSettingsPopup,
  useUpdateAccountMutation,
} from '@app/api/gql/generated-types';
import WelcomePopupImage from '@app/assets/images/welcome_popup.png';
import WelcomePopupImageSecStep from '@app/assets/images/welcome_popup_sec_step.png';
import Player from '@app/components/10Dlc/Player';
import Popup from '@app/components/Popup';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import ROUTES from '@app/utils/routes';
import video from '@app/utils/videoLinks';
import { getMessageSubscription } from '@app/utils/subscriptionHelpers';
import { colors } from '@app/theme/colors';

enum Steps {
  First,
  Second,
}

const WelcomePopup: FC<Partial<UseDisclosureProps>> = ({ isOpen, onClose }) => {
  const [updateAccount, { loading: isLoading }] = useUpdateAccountMutation();
  const navigate = useNavigate();

  const account = useCurrentAccountData();
  const messageSubscription = getMessageSubscription(account);

  const hasPromoCode =
    !!messageSubscription?.discount?.coupon &&
    !!messageSubscription?.discount?.expiresAt &&
    new Date(messageSubscription?.discount?.expiresAt) < new Date();

  const [isActiveStep, setActiveStep] = useState<Steps>(Steps.First);

  const onSubmitPopup = useCallback(async () => {
    await updateAccount({
      variables: {
        data: {
          viewedPopup: AccountSettingsPopup.firstLogin,
        },
      },
    });

    navigate(ROUTES.settingsMembership);
    onClose();
  }, [navigate, onClose, updateAccount]);

  const stepAction = useCallback(async () => {
    if (hasPromoCode) {
      await onSubmitPopup();
      return;
    }

    if (isActiveStep === Steps.First) {
      setActiveStep(Steps.Second);
    } else {
      await onSubmitPopup();
    }
  }, [hasPromoCode, isActiveStep, onSubmitPopup]);

  return (
    <Popup
      closeOnOverlayClick={false}
      hideCloseIcon={isActiveStep === Steps.First}
      isOpen={isOpen}
      maxW="630px"
      title=""
      titlePadding="40px 56px 50px"
      onClose={onSubmitPopup}>
      <Box p="40px">
        <Flex justifyContent="center">
          <Text
            color="main.400"
            fontSize="22px"
            fontWeight="500"
            lineHeight="26px"
            mb="25px">
            Welcome to Smarter Contact
          </Text>
        </Flex>
        {isActiveStep === Steps.First ? (
          <Box mb="25px">
            <Player
              height="305px"
              image={
                <Image height="305px" src={WelcomePopupImage} width="545px" />
              }
              type="iframe"
              video={video.welcomeVideo}
              width="545px"
            />
          </Box>
        ) : (
          <>
            <Image pt="5px" src={WelcomePopupImageSecStep} />
            <Flex alignItems="center" direction="column" mt="17px">
              <Text
                color="main.400"
                fontSize="14px"
                lineHeight="19px"
                maxW="512px"
                textAlign="center">
                The first step to set up your account will be to go to update
                your info in the{' '}
                <Button variant="link" onClick={onSubmitPopup}>
                  membership page
                </Button>
                .
              </Text>
              <Box maxW="500px" my="25px" textAlign="center">
                <Text
                  as="span"
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="19px">
                  Start your trial today and we`ll match up to $10 on your first
                  deposit,{' '}
                </Text>
                <Text as="span" fontSize="14px">
                  getting you off to a running start 💪
                </Text>
              </Box>
            </Flex>
          </>
        )}
        {!hasPromoCode && (
          <Flex
            alignItems="center"
            flexDirection="row"
            justifyContent="center"
            mb="30px">
            {Array(2)
              .fill(0)
              .map((_, i: Steps) => (
                <Box
                  key={`step-${i}`}
                  background={
                    isActiveStep === i
                      ? colors.notFound[400]
                      : colors.notFound[300]
                  }
                  borderRadius="50px"
                  display="block"
                  height="7px"
                  margin="0 5px"
                  width="7px"
                />
              ))}
          </Flex>
        )}

        <Flex
          flexDirection="row"
          justifyContent="space-around"
          m="0 auto"
          w="275px">
          <Button
            isDisabled={isLoading}
            variant="primary"
            w="150px"
            onClick={stepAction}>
            {isActiveStep === Steps.First && !hasPromoCode ? 'Next' : 'Close'}
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};

export default WelcomePopup;
