import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  ChakraProps,
  Popover,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import NotificationsIcon from '@app/icons/navigation/notifications-icon.svg?react';
import { UnreadMessageCount } from '@app/pages/Messenger/components/MessageItem/UnreadMessageCount';
import {
  FindNotificationsDocument,
  FindNotificationsQuery,
  useGetUnreadNotificationCounterQuery,
  useMarkNotificationsSeenMutation,
  useNotificationUnreadCounterUpdatedSubscription,
} from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import { NotificationsPopoverContent } from '../NotificationsPopoverContent/NotificationsPopoverContent';

const MAX_NOTIFICATION_COUNT = 99;

const counterStyle = {
  color: 'white',
  backgroundColor: 'error',
  padding: '0.5px 3px',
  borderRadius: '18px',
  left: '10px',
  bottom: '',
  top: '-3px',
  right: '',
  fontSize: '11px',
  lineHeight: '11.7px',
  fontWeight: '400',
} as Omit<ChakraProps, never>;

export const NotificationsSection: FC = () => {
  const notificationsProps = useDisclosure();
  const [showCounter, setShowCounter] = useState(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const location = useLocation();

  const [mutateSetSeen] = useMarkNotificationsSeenMutation();

  const { data: { getUnreadNotificationCounter } = {}, networkStatus } =
    useGetUnreadNotificationCounterQuery({
      variables: {},
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  const { isReady } = useApolloLoadingStatus(networkStatus);

  useNotificationUnreadCounterUpdatedSubscription({
    onData: ({ data: { data: notificationData } = {} }) => {
      const counterUpdated =
        notificationData?.notificationUnreadCounterUpdated ?? null;
      setCounter(counterUpdated.unreadCount);
      //if user has already seen his notifications they turned off but
      //it it received a new one they sould be switched on
      setShowCounter(true);
    },
  });

  const closeHandler = () => {
    setIsPopoverOpen(false);
    notificationsProps.onClose();
  };

  const openHandler = () => {
    notificationsProps.onOpen();
    setIsPopoverOpen(true);
    setShowCounter(false);
    void mutateSetSeen({
      variables: {
        input: {
          id: null,
        },
      },
      update(cache) {
        const cacheData = cache.readQuery<FindNotificationsQuery>({
          query: FindNotificationsDocument,
        });
        if (!cacheData) {
          return;
        }

        const { findNotifications } = cacheData;
        cache.writeQuery<FindNotificationsQuery>({
          query: FindNotificationsDocument,
          data: {
            findNotifications: {
              ...findNotifications,
              items: findNotifications.items?.map((notification) => {
                return { ...notification, isSeen: true };
              }),
            },
          },
        });
      },
    });
  };

  useEffect(() => {
    if (getUnreadNotificationCounter?.unreadCount) {
      setCounter(getUnreadNotificationCounter.unreadCount);
    }
  }, [getUnreadNotificationCounter]);

  useEffect(() => {
    notificationsProps.onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]); // Close the popover when the location changes

  return (
    <Box mr="22px" zIndex="40">
      <Popover
        closeOnBlur
        matchWidth
        isOpen={notificationsProps.isOpen}
        offset={[-219, 15]}
        placement="bottom"
        onClose={closeHandler}
        onOpen={openHandler}>
        <PopoverTrigger>
          <Box cursor="pointer" position="relative">
            <NotificationsIcon />
            {showCounter && isReady && counter > 0 && (
              <UnreadMessageCount
                count={counter}
                empty={false}
                maxCount={MAX_NOTIFICATION_COUNT}
                size={13}
                style={counterStyle}
              />
            )}
          </Box>
        </PopoverTrigger>
        <NotificationsPopoverContent
          isOpen={isPopoverOpen}
          itemsUnreadLength={counter}
        />
      </Popover>
    </Box>
  );
};
