import { Grid, Box, Skeleton } from '@chakra-ui/react';
import React from 'react';
import { colors } from '@app/theme/colors';

export const Loading = () => {
  const { innerHeight: height } = window;

  const elementSize = Math.max(Math.trunc((height - 364) / 54) + 1, 0);
  return (
    <Grid>
      {Array(elementSize)
        .fill(0)
        .map((_, i) => (
          <Box
            key={`body-row-${i.toString()}`}
            borderBottom={`1px solid ${colors.notFound[1900]}`}
            padding="20px 30px"
            width="100%">
            <Grid
              columnGap="30px"
              gridTemplateColumns="repeat(8, 1fr)"
              rowGap="40px">
              {Array(8)
                .fill(0)
                .map((__, _i) => (
                  <Skeleton
                    key={`body-row-cell-${_i.toString()}`}
                    endColor={colors.cultured}
                    height="14px"
                    startColor={colors.mystic}
                    width="100%"
                  />
                ))}
            </Grid>
          </Box>
        ))}
    </Grid>
  );
};
