import { Box, Center, Flex } from '@chakra-ui/react';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { DEFAULT_LIMIT } from '@app/api/queries/utils';
import TableList from '@app/components/ContactTableList';
import LoadMoreButton from '@app/components/LoadMoreButton';
import { getContactListHeader } from '@app/utils/getHeader';

import {
  FindListItemsQuery,
  useFindListItemsQuery,
  useGetGroupQuery,
  useGetListQuery,
  useUpdateGroupMutation,
  useUpdateListMutation,
} from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import { TableSkeleton } from '@app/components/Table/Skeleton';
import { catchErrorLog } from '@app/utils/logger';
import { TemplateControls } from '@app/pages/Contacts/components/TemplateControls';
import { SelectPageLimit } from '@app/pages/Contacts/components/SelectPageLimit';
import { ApplyContactsToListButton } from '@app/pages/Contacts/components/ApplyContactsToListButton';
import { clearHeaders, UNKNOWN_FIELD_ID } from '../../utils/prepareHeaders';
import { ContactHeader } from '../ContactHeader';
import { useHeaders } from './useHeaders';

const ContactList: FC = () => {
  const { contactListId } = useParams<{ contactListId: string }>();
  const [pageLimit, setPageLimit] = useState<number>(DEFAULT_LIMIT);

  const [mutateUpdateGroup] = useUpdateGroupMutation();
  const [mutateUpdateList] = useUpdateListMutation();
  const { data: { getGroup: groupData } = {} } = useGetGroupQuery({
    variables: {
      id: contactListId,
    },
  });

  const { data: { getList: contactListData } = {} } = useGetListQuery({
    variables: {
      id: contactListId,
    },
  });

  const {
    data: { findListItems: contactsData } = {},
    fetchMore,
    refetch,
    networkStatus,
  } = useFindListItemsQuery({
    variables: {
      listId: contactListId,
      pagination: {
        limit: pageLimit,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  const { isLoading, isLoadingData, isFetchingNextPage } =
    useApolloLoadingStatus(networkStatus);

  const fetchNextPage = () => {
    if (
      contactsData?.hasNext &&
      !(contactsData?.items?.length && isLoadingData)
    ) {
      void fetchMore({
        variables: {
          listId: contactListId,
          pagination: {
            limit: DEFAULT_LIMIT,
            nextRowIndex: contactsData?.nextRowIndex,
          },
        },
        updateQuery: (prevResult: FindListItemsQuery, { fetchMoreResult }) => {
          fetchMoreResult.findListItems.items = [
            ...prevResult.findListItems.items,
            ...fetchMoreResult.findListItems.items,
          ];
          return fetchMoreResult;
        },
      });
    }
  };

  const {
    headersMap,
    headerOptions,
    loading,
    headers: headersState,
    setHeaders,
    predefinedHeadersMap,
  } = useHeaders();

  const rawContacts = useMemo(() => contactsData?.items ?? [], [contactsData]);

  const headers = useMemo(() => {
    const headerArr = headersState?.length
      ? headersState
      : contactListData?.fields?.map((field) => field.id);

    return (
      headerArr?.map((item: string) => {
        if (item === UNKNOWN_FIELD_ID) {
          return null;
        }
        if (predefinedHeadersMap?.[item]?.id) {
          return predefinedHeadersMap?.[item].id;
        }
        return item;
      }) ?? []
    );
  }, [contactListData?.fields, headersState, predefinedHeadersMap]);

  const onSaveHeaders = useCallback(
    async (headersArray?: string[]) => {
      try {
        const headersData = headersArray?.length ? headersArray : headers;
        const preparedData = clearHeaders(headersData);

        await mutateUpdateList({
          variables: {
            id: contactListId,
            input: {
              fields: preparedData,
            },
          },
        });
      } catch (error) {
        catchErrorLog(error, 'ContactList/onSaveHeaders');
      }
    },
    [contactListId, headers, mutateUpdateList],
  );

  const handleHeaderChanged = useCallback(
    async (val: string, index: number) => {
      const headerArr = getContactListHeader(headers, headersMap, val, index);

      setHeaders(headerArr);
      await onSaveHeaders(headerArr);
    },
    [headers, headersMap, onSaveHeaders, setHeaders],
  );

  const handleNameChanged = useCallback(
    async (name: string) => {
      try {
        await mutateUpdateGroup({
          variables: {
            id: contactListId,
            input: {
              name,
            },
          },
          optimisticResponse: {
            updateGroup: {
              ...groupData,
              name,
            },
          },
          context: {
            notify: {
              success: () => 'Group successfully renamed',
              error: () => 'Failed rename group',
            },
          },
        });
      } catch (error) {
        catchErrorLog(error, 'ContactList/handleNameChanged');
      }
    },
    [contactListId, groupData, mutateUpdateGroup],
  );

  const handleChangePageLimit = useCallback(
    async ({ value }: { value: number }) => {
      setPageLimit(value);
      await refetch({
        pagination: {
          limit: value,
        },
      });
    },
    [refetch],
  );

  const tableData = {
    headers,
    data: new Map(rawContacts.map((contact) => [contact.id, contact.data])),
  };

  return (
    <Flex flexDirection="column" w="100%">
      <Flex flexDirection="column" p="20px 15px 30px 30px">
        <ContactHeader
          contactList={contactListData}
          group={groupData}
          isLoading={isLoading}
          onChange={handleNameChanged}
        />

        <Flex justifyContent="space-between" w="100%">
          <Flex alignItems="center" gap="20px">
            <TemplateControls headers={headers} setHeader={setHeaders} />

            <ApplyContactsToListButton
              contactList={contactListData}
              contactListId={contactListId}
              group={groupData}
              headers={headers}
              headersMap={predefinedHeadersMap}
              refetchRawContacts={refetch}
              totalContacts={contactsData?.total}
              onSaveHeaders={onSaveHeaders}
            />
          </Flex>

          <Flex>
            <SelectPageLimit
              handleChangePageLimit={handleChangePageLimit}
              pageLimit={pageLimit}
            />
          </Flex>
        </Flex>
      </Flex>
      {isLoading || isLoadingData ? (
        <TableSkeleton />
      ) : (
        <Box height="calc(100vh - 250px)" overflowY="auto">
          <TableList
            contactListId={contactListId}
            headerOptions={headerOptions}
            headerOptionsMap={headersMap}
            loading={loading}
            setHeader={handleHeaderChanged}
            tableData={tableData}
          />
          <Center>
            <LoadMoreButton
              fetchNextPage={fetchNextPage}
              hasNextPage={contactsData?.hasNext}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoading}
            />
          </Center>
        </Box>
      )}
    </Flex>
  );
};

export default ContactList;
