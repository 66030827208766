import {
  Box,
  Button,
  Center,
  Flex,
  Link,
  Text,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useMemo } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import debounce from 'lodash/debounce';

import { createPaginationVariables } from '@app/api/apollo-pagination';
import {
  FindCallScriptsDocument,
  CallScript,
  useFindCallScriptsQuery,
  useRankCallScriptMutation,
} from '@app/api/gql/generated-types';
import LoadMoreButton from '@app/components/LoadMoreButton';
import PageSkeleton from '@app/components/PageSkeleton';
import EmptyIcon from '@app/icons/call-scripts-empty.svg?react';
import PlusIcon from '@app/icons/plus-white-icon.svg?react';
import { ScriptsTable } from '@app/pages/Campaigns/components/ScriptsTable';
import { useDragAndDrop } from '@app/pages/Campaigns/hooks/useDragAndDrop';
import { catchErrorLog } from '@app/utils/logger';
import { removeNil } from '@app/utils/removeNil';
import { SUPPORT_LINK } from '@app/utils/routes';

import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import { DEFAULT_PAGE_SIZE } from '@app/constants/configuration';
import useActivateDialerSubscriptionGuard from '@app/hooks/useActivateDialerSubscriptionGuard';
import { CallScriptModal } from '../../../CallScripts/components/CallScriptModal';
import { useTableColumns } from '../../hooks/useTableColumns';
import { EditScriptModal } from '../EditScriptModal';
import { RemoveModal } from '../RemoveModal';

export const CallScriptTable: FC = () => {
  const { columns, script, removeModal, editModal } = useTableColumns();

  const {
    data: { findCallScripts: callScriptsData } = {},
    networkStatus,
    fetchMore,
  } = useFindCallScriptsQuery({
    variables: {
      pagination: {
        limit: DEFAULT_PAGE_SIZE,
      },
    },
  });

  const { isLoading, isLoadingData, isFetchingNextPage } =
    useApolloLoadingStatus(networkStatus);

  const fetchNextPage = () =>
    fetchMore(createPaginationVariables(callScriptsData, DEFAULT_PAGE_SIZE));

  const items = useMemo(
    () => callScriptsData?.items ?? [],
    [callScriptsData?.items],
  );

  const { data, moveRow, onDrop } = useDragAndDrop(items);
  const [mutate] = useRankCallScriptMutation({
    refetchQueries: [FindCallScriptsDocument],
  });

  const updateScript = (item: CallScript) => {
    const { beforeId, afterId, id } = onDrop(item);
    try {
      void mutate({
        variables: {
          input: removeNil({
            beforeId,
            afterId,
          }),
          rankCallScriptId: id,
        },
      });
    } catch (error) {
      console.error('🚀 ~ onDropRow ~ error:', error);
    }
  };

  const onDropRow = debounce((item: CallScript) => {
    try {
      updateScript(item);
    } catch (error) {
      catchErrorLog(error, 'MessageTemplateTable/TemplatesTable/onDropRow');
    }
  }, 100);

  const createModal = useDisclosure();
  const videoModal = useDisclosure();

  const guardActionCreate = useActivateDialerSubscriptionGuard(
    createModal.onOpen,
  );

  if (isLoading || isLoadingData) {
    return <PageSkeleton />;
  }

  if (isEmpty(items)) {
    return (
      <Flex align="center" direction="column" h="100%" justifyContent="center">
        <EmptyIcon />
        <Text color="main.400" fontSize="22px" fontWeight={500} mt="20px">
          Create your first call script
        </Text>
        <Text
          color="main.400"
          fontSize="14px"
          fontWeight={400}
          mt="15px"
          textAlign="center"
          w="285px">
          Not sure where to start?{' '}
          <Button
            as={Link}
            color="switchedBlue.600"
            variant="linkSecondary"
            onClick={videoModal.onOpen}>
            Watch this video
          </Button>{' '}
          or visit our{' '}
          <Link
            as={RouteLink}
            color="switchedBlue.600"
            target="_blank"
            to={SUPPORT_LINK}>
            Help Center
          </Link>
        </Text>
        <Button
          borderRadius="22px"
          color="white"
          height="40px"
          mt="20px"
          variant="primary"
          width="159px"
          onClick={guardActionCreate}>
          Add new call script
        </Button>

        {createModal.isOpen && (
          <CallScriptModal
            isOpenModal={createModal.isOpen}
            onCloseModal={createModal.onClose}
          />
        )}
      </Flex>
    );
  }

  return (
    <Flex direction="column" height="100%">
      <Box flex={0} mb="30px" ml="30px">
        <Button
          borderRadius="22px"
          color="white"
          height="40px"
          variant="primary"
          width="159px"
          onClick={guardActionCreate}>
          <HStack spacing="10px">
            <PlusIcon />
            <Text>Add new script</Text>
          </HStack>
        </Button>
      </Box>
      <Box
        flex={1}
        height="100%"
        overflowY="auto"
        transform="translate3d(0,0,0)">
        <ScriptsTable<CallScript>
          columns={columns}
          items={data}
          moveRow={moveRow}
          onDrop={onDropRow}
        />
        <Center>
          <LoadMoreButton
            fetchNextPage={fetchNextPage}
            hasNextPage={callScriptsData?.hasNext}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
          />
        </Center>
      </Box>

      {removeModal.isOpen && script && (
        <RemoveModal
          idScript={script.id}
          isOpen={removeModal.isOpen}
          nameScript={script.name}
          onClose={removeModal.onClose}
        />
      )}
      {editModal.isOpen && script && (
        <EditScriptModal
          isOpen={editModal.isOpen}
          script={script}
          onClose={editModal.onClose}
        />
      )}

      {createModal.isOpen && (
        <CallScriptModal
          isOpenModal={createModal.isOpen}
          onCloseModal={createModal.onClose}
        />
      )}
    </Flex>
  );
};
