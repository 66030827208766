import React, { FC } from 'react';
import {
  Box,
  Text,
  Image,
  useDisclosure,
  Button,
  Center,
  Link,
} from '@chakra-ui/react';

import Popup from '@app/components/Popup';
import Player from '@app/components/10Dlc/Player';
import { isValidUrl } from '@app/utils/isUrl';
import { colors } from '@app/theme/colors';

interface GenericModalProps {
  title: string;
  description: string;
  image: string;
  video?: string;
  id: string;
  isOpen: boolean;
  buttonText?: string;
  buttonLink?: string;
  onClose: (id: string) => void;
}

export const GenericModal: FC<GenericModalProps> = ({
  title,
  description,
  image,
  video,
  buttonText,
  buttonLink,
  id,
  isOpen,
  onClose,
}) => {
  const { onClose: onCloseModal } = useDisclosure();
  const videoType = isValidUrl(video) ? 'iframe' : 'youtube';

  const handleClose = () => {
    onCloseModal();
    onClose(id);
  };

  return (
    <Popup
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="590px"
      title={title}
      titlePadding="40px 56px 50px"
      width="100%"
      onClose={handleClose}>
      <Box p="0 40px 40px">
        <Box
          border={`1px solid ${colors.notFound[600]}`}
          borderRadius="12px"
          mb="15px"
          minH="340px"
          overflow="hidden"
          position="relative">
          <Center>
            {video ? (
              <Player
                autoplay
                height="340px"
                image={<Image minH="340px" src={image} width="100%" />}
                type={videoType}
                video={video}
                width="510"
              />
            ) : (
              <Image alt={title} height="340px" objectFit="cover" src={image} />
            )}
          </Center>
        </Box>
        {description && (
          <Box lineHeight="22px" mb="20px" textAlign="center">
            <Text
              as="span"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
              fontSize="14px"
            />
          </Box>
        )}
        <Center>
          {buttonText ? (
            <Button
              as={buttonLink ? Link : Button}
              minW="160px"
              variant="primary"
              onClick={handleClose}
              {...(buttonLink && {
                href: buttonLink,
                isExternal: true,
              })}>
              {buttonText}
            </Button>
          ) : (
            <Button minW="160px" variant="primary" onClick={handleClose}>
              Close
            </Button>
          )}
        </Center>
      </Box>
    </Popup>
  );
};
