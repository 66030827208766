import React, { FC } from 'react';
import { components, GroupBase, MenuProps } from 'react-select';
import { Box, Button, Flex } from '@chakra-ui/react';
import { OwnPhoneFragment } from '@app/api/gql/generated-types';
import { colors } from '@app/theme/colors';

interface SelectCustomMenuProps {
  menuProps: MenuProps<OwnPhoneFragment, false, GroupBase<OwnPhoneFragment>>;
  buttonText: string;
  requiredInfoMessages?: React.ReactNode;
  containerStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
}

export const SelectCustomMenu: FC<SelectCustomMenuProps> = ({
  menuProps,
  requiredInfoMessages,
  buttonText,
  containerStyle = {},
  buttonStyle = {},
}) => {
  return (
    <components.Menu {...menuProps}>
      <>
        <Box>{menuProps.children}</Box>
        {requiredInfoMessages}
        <Flex
          borderTop="1px"
          borderTopColor="primary.200"
          direction="column"
          padding="6px 8px"
          style={{ ...containerStyle }}>
          <Button
            _hover={{ bg: colors.primary[200], borderRadius: '20px' }}
            fontSize="14px"
            fontWeight="500"
            p="11px"
            style={{ ...buttonStyle }}
            variant="goBack"
            width="100%"
            onClick={menuProps.selectProps.onCreateNewEntity}>
            {buttonText}
          </Button>
        </Flex>
      </>
    </components.Menu>
  );
};
