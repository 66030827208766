import { Formik, FormikHelpers } from 'formik';
import React, { FC, useCallback } from 'react';

import { CallScript } from '@app/api/gql/generated-types';
import { FormItemInput } from '@app/components/FormItem/FormItemInput';
import { apolloErrorsMapHelper } from '@app/utils/apolloErrorsMapHelper';
import { catchErrorLog } from '@app/utils/logger';

import { useCreateScript } from '../../../hooks/useCreateScript';
import { FormSubmit } from '../FormSubmit';
import { messageTemplateErrors } from '../types';
import {
  MAX_INPUT_LENGTH_NAME,
  useValidationSchemaScript,
} from '../useValidationSchema';

import { ScriptTemplateContent } from './TextTemplateContent';

interface ScriptTemplateProps {
  onCloseModal: (id: string) => void;
  initialValues?: CallScript;
  onEditSubmit?: () => void;
}

export const ScriptTemplate: FC<ScriptTemplateProps> = ({
  onCloseModal,
  initialValues = null,
  onEditSubmit,
}) => {
  const { mutation } = useCreateScript();

  const validationSchema = useValidationSchemaScript();

  const initialValuesForm: CallScript = {
    name: '',
    content: '',
    ...initialValues,
  };

  const onSubmit = useCallback(
    async (values: CallScript, helpers: FormikHelpers<CallScript>) => {
      try {
        const data = await mutation({
          ...values,
          name: values.name.trim(),
          content: values.content.trim(),
          onError: (errors) => {
            const errorsMap = apolloErrorsMapHelper(
              errors.graphQLErrors,
              messageTemplateErrors,
            );
            helpers.setErrors(errorsMap);
          },
        });
        onEditSubmit?.();
        if (data?.id) {
          onCloseModal(data.id);
        }
      } catch (error) {
        catchErrorLog(error, 'TextTemplate/onSubmit');
      }
    },
    [mutation, onCloseModal, onEditSubmit],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValuesForm}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {({ handleChange }) => (
        <>
          <FormItemInput
            data-cy="script-name-input"
            maxLength={MAX_INPUT_LENGTH_NAME}
            mt="20px"
            name="name"
            placeholder="Script name"
            onChange={handleChange}
          />

          <ScriptTemplateContent />
          <FormSubmit />
        </>
      )}
    </Formik>
  );
};
