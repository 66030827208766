import React, { FC } from 'react';
import { Center, Flex, Button, Box, Text } from '@chakra-ui/react';

import Popup from '@app/components/Popup';
import ListIcon from '@app/icons/list-icon.svg?react';
import { colors } from '@app/theme/colors';

interface ValidationModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  validationErrors: string[];
}

const ValidationModal: FC<ValidationModalProps> = ({
  isOpen,
  onClose,
  title,
  validationErrors,
}: ValidationModalProps) => (
  <Popup isOpen={isOpen} title={title} width="450px" onClose={onClose}>
    <Box margin="35px 40px">
      <Text
        color={colors.main[400]}
        fontSize="14px"
        marginBottom="10px"
        mb="18px">
        Please tag all address fields before submitting your list
      </Text>
      <Box>
        {validationErrors?.map((err) => (
          <Flex
            key={`error-${err.toString()}`}
            alignItems="center"
            flexDirection="row"
            mb="18px">
            <ListIcon />
            <Text
              color={colors.main[400]}
              fontSize="14px"
              fontWeight="700"
              ml="12px">
              {err}
            </Text>
          </Flex>
        ))}
      </Box>
      <Center mt="25px">
        <Button variant="primary" width="127px" onClick={onClose}>
          Confirm
        </Button>
      </Center>
    </Box>
  </Popup>
);

export default ValidationModal;
