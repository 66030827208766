import React, { FC, ReactNode, useMemo } from 'react';
import {
  Box,
  FormControl,
  FormErrorMessage,
  Text,
  Textarea,
  TextareaProps,
} from '@chakra-ui/react';
import ResizeTextarea from 'react-textarea-autosize';
import { useField } from 'formik';

import Label from '@app/components/FormItem/Label';

interface CountableTextareaProps extends TextareaProps {
  name: string;
  label?: ReactNode;
}

export const CountableTextarea: FC<CountableTextareaProps> = ({
  label,
  ...props
}) => {
  const [field, meta] = useField<string>(props.name);

  const isInvalid = useMemo(() => !!(meta.touched && meta?.error), [meta]);

  const characterCount = useMemo(() => field.value?.length || 0, [field.value]);

  return (
    <>
      <FormControl isInvalid={isInvalid}>
        {label && <Label>{label}</Label>}
        <Textarea
          {...field}
          {...props}
          as={ResizeTextarea}
          resize="none"
          variant="primary"
        />
        {isInvalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      </FormControl>
      <Box lineHeight="20px" marginTop="7px" mr="20px" pr="20px">
        <Text color="secondary.400" fontSize="12px" fontWeight={700}>
          {characterCount} / {props.maxLength}
        </Text>
      </Box>
    </>
  );
};
