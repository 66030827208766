import React, { useMemo } from 'react';
// eslint-disable-next-line import/default
import Select, {
  CSSObjectWithLabel,
  CommonPropsAndClassName,
  GroupBase,
  Props,
  StylesConfig,
} from 'react-select';
import { colors } from '@app/theme/colors';

enum SelectPrefix {
  TAGS = 'multiply-select',
}

// Alias for brevity.
type SConfig = StylesConfig<{ value: string; label: string }, false>;

export interface SelectProps<T, M extends boolean, G extends GroupBase<T>>
  extends Props<T, M, G> {
  error?: string;
  color?: string;
  menuHeight?: number;
  disabledCross?: boolean;
  selectProps?: {
    color: string;
    disabledCross?: boolean;
  };
}

export interface SelectOption {
  label: string;
  value: string | null;
}

function evalOverrides(
  key: keyof SConfig,
  base: CSSObjectWithLabel,
  state: CommonPropsAndClassName<any, any, any>,
  overrides?: SConfig,
): CSSObjectWithLabel {
  return overrides && typeof overrides[key] === 'function'
    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      overrides[key](base, state as any)
    : {};
}

export function getSelectCustomStylesFn(overrides?: SConfig) {
  return (error?: string): SConfig => ({
    control: (base, state) => {
      const prefix = state?.selectProps?.classNamePrefix;

      let border = state.isFocused
        ? `1px solid  ${colors.primary[600]}  !important`
        : `1px solid ${colors.secondary[200]} !important`;

      if (error) {
        border = '1px solid red !important';
      }
      return {
        ...base,
        cursor: 'pointer',
        backgroundColor: state.isDisabled ? colors.catskillWhite : colors.white,
        borderRadius: '20px',
        boxShadow: '0px 0px 0px transparent',
        display: 'flex',
        justifyContent: 'space-between',
        padding:
          (prefix as SelectPrefix) === SelectPrefix.TAGS
            ? '2px 6px'
            : '1px 10px 1px 20px',
        fontSize: '14px',
        color: state.isDisabled ? colors.main[100] : colors.main[400],
        minHeight: '40px',
        border,

        '&:hover': {
          border: `1px solid ${colors.secondary[300]} !important`,
        },
        ...evalOverrides('control', base, state, overrides),
      };
    },
    option: (base, state) => {
      let color = colors.main[400];

      if (state.isSelected) {
        color = colors.main[400];
      } else if (state.isFocused && !state.isDisabled) {
        color = colors.primary[700];
      } else if (state.isDisabled) {
        color = colors.secondary[200];
      }
      if (state.isSelected && state.isDisabled) {
        color = colors.secondary[200];
      }

      return {
        ...base,
        borderRadius: '20px',
        fontSize: '14px',
        cursor: 'pointer',
        background:
          state.isFocused && !state.isDisabled
            ? colors.primary[200]
            : colors.transparent,
        color,
        padding: '8px 12px',
        '&:hover': {
          cursor: state.isDisabled ? 'not-allowed' : 'pointer',
          backgroundColor: state.isDisabled
            ? colors.transparent
            : colors.primary[200],
          borderRadius: '20px',
          color: !state.isDisabled ? colors.primary[700] : null,
        },
        ...evalOverrides('option', base, state, overrides),
      };
    },
    placeholder: (base, state) => ({
      ...base,
      whiteSpace: 'nowrap',
      ...evalOverrides('placeholder', base, state, overrides),
      color: colors.secondary[400],
    }),
    menu: (base, state) => ({
      ...base,
      borderRadius: '20px',
      marginTop: '5px',
      overflow: 'hidden',

      minWidth: state.selectProps?.menuWidth ?? '100%',
      zIndex: state.selectProps?.zIndex ?? 100,
      ...evalOverrides('menu', base, state, overrides),
    }),
    menuList: (base, state) => ({
      ...base,
      padding: '8px 5px 8px 8px',
      marginRight: '5px',
      maxHeight: state.selectProps?.menuHeight ?? base.maxHeight,
      '::-webkit-scrollbar': {
        width: '5px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: 'white',
        margin: '12px 0',
      },
      '::-webkit-scrollbar-thumb': {
        background: colors.main[100],
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: colors.main[100],
      },
      ...evalOverrides('menuList', base, state, overrides),
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      display: 'none',
      ...evalOverrides('indicatorSeparator', base, state, overrides),
    }),
    dropdownIndicator: (base, state) => {
      const readOnly = !!state.selectProps?.readOnly || state.isDisabled;

      return {
        ...base,
        color: readOnly ? colors.main[100] : colors.main[400],
        transition: 'all .3s ease',
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        padding: '8px 5px',
        svg: {
          width: '14px',
          height: '14px',
        },
        ...evalOverrides('dropdownIndicator', base, state, overrides),
      };
    },
    clearIndicator: (base, state) => ({
      ...base,
      // @ts-expect-error inconsistency between styles and types
      display: state.selectProps?.disabledCross ? 'none' : 'flex',
    }),
    noOptionsMessage: (base, state) => ({
      ...base,
      padding: '0',
      fontSize: '14px',
      ...evalOverrides('noOptionsMessage', base, state, overrides),
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: '0px !important',
      ...evalOverrides('valueContainer', base, state, overrides),
    }),
    multiValue: (base, state) => ({
      ...base,
      backgroundColor: colors.primary[700],
      borderRadius: '16px',
      marginRight: '6px',
      padding: '0px 4px',
      ...evalOverrides('multiValue', base, state, overrides),
    }),
    multiValueLabel: (base, state) => ({
      ...base,
      color: 'white',
      fontSize: '14px',
      ...evalOverrides('multiValueLabel', base, state, overrides),
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      // @ts-expect-error inconsistency between styles and types
      display: state.selectProps?.disabledCross ? 'none' : 'flex',
      color: 'white',
      background: colors.transparent,
      ':hover': {
        background: colors.transparent,
        color: 'white',
      },
      ...evalOverrides('multiValueRemove', base, state, overrides),
    }),
    singleValue: (base, state) => {
      const color = state?.selectProps?.color
        ? state.selectProps.color
        : state.isDisabled
          ? colors.main[100]
          : colors.main[400];

      return {
        ...base,
        color,
        ...evalOverrides('singleValue', base, state, overrides),
      };
    },
  });
}

const SelectField = <
  T = {
    label: string;
    value: string | boolean | number;
    isShowBonusLabel?: boolean;
    onCustomFieldSelect?: void;
  },
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>({
  isSearchable,
  error,
  ...props
}: SelectProps<T, M, G>) => {
  const customStyles: Record<string, object | string> = useMemo(
    () => getSelectCustomStylesFn()(error),
    [error],
  );

  return (
    <Select isSearchable={!!isSearchable} styles={customStyles} {...props} />
  );
};

export default SelectField;
