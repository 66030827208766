import React from 'react';
import { Link, Text } from '@chakra-ui/react';
import { Link as RouteLink, useNavigate } from 'react-router-dom';

import ROUTES from '@app/utils/routes';

import { useGetContactsCountersLazyQuery } from '@app/api/gql/generated-types';
import { useCurrentAccountData } from './useCurrentAccountData';
import { usePopupsContext } from './usePopupsContext';

/**
 *  @deprecated Use the new permission Features & permission modal. .waiting for new modal designs from design team
 * */
export const useTrialMaxContactsGuard = () => {
  const [request, { loading }] = useGetContactsCountersLazyQuery();

  const navigate = useNavigate();

  const { isMessagePlanTrialing } = useCurrentAccountData();

  const { showConfirmPopup, closeConfirmPopup } = usePopupsContext();

  const showTrialAccountPopup = async () => {
    try {
      await showConfirmPopup({
        title: 'Trial account',
        hideCancelButton: true,
        confirmButtonWidth: '120px',
        padding: '40px',
        description: (
          <Text fontSize="14px" mb="25px" textAlign="center">
            You can create no more than <strong>100</strong> contacts on trial.
            To unlock full messaging capabilities, please{' '}
            <Link
              as={RouteLink}
              to={`${ROUTES.settingsMembership}`}
              variant="link"
              onClick={closeConfirmPopup}>
              subscribe
            </Link>
          </Text>
        ),
        confirmButtonText: 'Subscribe',
      });

      closeConfirmPopup();

      navigate(`${ROUTES.settingsMembership}`);
    } catch (error) {
      console.error(error);
    }
  };

  const checkAccountContacts = async (
    handler: (...args: any) => void | Promise<void>,
  ) => {
    if (!isMessagePlanTrialing) {
      void handler();

      return;
    }
    const counters = await request();

    if (counters?.data?.getContactsCounters?.activeContacts >= 100) {
      await showTrialAccountPopup();
    } else {
      void handler();
    }
  };

  return {
    checkAccountContacts,
    showTrialAccountPopup,
    closeTrialAccountPopup: closeConfirmPopup,
    isValidating: loading,
  };
};
