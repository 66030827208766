import React from 'react';
import { Text } from '@chakra-ui/react';

import PhoneNumberFormat from '@app/shared/ui/PhoneNumberFormat';
import { Contact } from '@app/api/gql/generated-types';
import { getContactNames } from '@app/utils/contact';

export const ConfirmRemoveContactDescription = (contactItem: Contact) => {
  const { fullName } = getContactNames(contactItem);

  return (
    <Text mb="30px" textAlign="center" variant="list-style">
      Are you sure you want to delete this contact?
      <br />
      {contactItem && (
        <>
          <Text as="span" color="secondary.400">
            {fullName}
          </Text>{' '}
          <PhoneNumberFormat value={contactItem?.phone} />
        </>
      )}
    </Text>
  );
};

export const ConfirmRemoveContactsDescription = () => {
  return (
    <Text mb="30px" textAlign="center" variant="list-style">
      Are you sure you want to delete these contacts?
    </Text>
  );
};

export const ConfirmRemoveContactFromGroupDescription = (
  contactItem: Contact,
) => {
  const { fullName } = getContactNames(contactItem);

  return (
    <Text mb="30px" textAlign="center" variant="list-style">
      Are you sure you want to remove
      <br />
      {contactItem && (
        <>
          <Text as="span" color="secondary.400">
            {fullName}
          </Text>{' '}
          <PhoneNumberFormat value={contactItem?.phone} />
        </>
      )}
      <br />
      from this group?
    </Text>
  );
};

export const ConfirmRemoveContactsFromGroupDescription = () => {
  return (
    <Text mb="30px" textAlign="center" variant="list-style">
      Are you sure you want to remove these <br /> contacts from the group?
    </Text>
  );
};
