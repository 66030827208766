import React, { ChangeEvent, FC } from 'react';
import { Flex, Checkbox, Text } from '@chakra-ui/react';
import { PopoverWithLink } from '../../PopoverWithLink';

interface CheckboxWithPopoverProps {
  defaultChecked?: boolean;
  isDisabled?: boolean;
  isChecked: boolean;
  name: string;
  text: string;
  popoverText: string;
  width?: string;
  popoverLinkText?: string;
  onChange?: (e: ChangeEvent) => void;
}

export const CheckboxWithPopover: FC<CheckboxWithPopoverProps> = ({
  defaultChecked = true,
  isDisabled,
  isChecked,
  name,
  text,
  popoverText,
  width,
  popoverLinkText,
  onChange,
}) => {
  return (
    <Flex alignItems="center" height="14px">
      <Checkbox
        defaultChecked={defaultChecked}
        id={name}
        isChecked={isChecked}
        isDisabled={isDisabled}
        name={name}
        variant="primary"
        onChange={onChange}>
        <Text color="main.400" lineHeight="16px" variant="heading-5">
          {text}
        </Text>
      </Checkbox>
      <PopoverWithLink
        linkText={popoverLinkText}
        text={popoverText}
        width={width}
      />
    </Flex>
  );
};
