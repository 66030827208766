import { ApolloLink, Observable } from '@apollo/client';
import { GraphQLError, GraphQLFormattedError } from 'graphql';

import isNull from 'lodash/isNull';
import { ToastId } from '@chakra-ui/react';
import { isUndefined } from 'lodash';
import ToastNotify from '@app/components/ToastNotifier';
import { ExceptionCode } from '@app/types/ApolloErrors';

interface NotifyConfig {
  error?: (error: unknown) => string;
  success?: (data: unknown) => string;
  inProgress?: () => string;
  filterErrorCodes?: ExceptionCode[];
}

type GraphQLErrors = GraphQLError | GraphQLFormattedError;

const defaultError = (
  error: Error | Error[] | readonly GraphQLErrors[],
): string => {
  if (Array.isArray(error)) {
    return error.map((e) => e.message).join('\n');
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Something went wrong';
};

const defaultSuccess = () => 'The data was successfully updated';

export const notifierLink = new ApolloLink((operation, forward) => {
  const operationContext = operation.getContext();
  const notify = operationContext?.notify as NotifyConfig;

  return new Observable((observer) => {
    let subscription: { unsubscribe: () => void };
    let inProgressToastId: ToastId = null;
    if (
      notify &&
      !isUndefined(notify.inProgress) &&
      !isNull(notify.inProgress)
    ) {
      inProgressToastId = ToastNotify({
        status: 'inProgress',
        position: 'top-right',
        title: notify.inProgress(),
      });
    }

    try {
      subscription = forward(operation).subscribe({
        next: (result) => {
          if (!isNull(inProgressToastId)) {
            ToastNotify({
              status: 'inProgress',
              position: 'top-right',
              title: notify.inProgress(),
              progressCompleted: true,
              toastId: inProgressToastId,
            });
          }

          if (notify && result.errors && !isNull(notify.error)) {
            const filteredErrors = result.errors.filter(
              (error) =>
                !notify.filterErrorCodes?.includes(
                  error.extensions?.code as ExceptionCode,
                ),
            );
            const errorMessage = notify.error
              ? notify.error(result.errors)
              : defaultError(result.errors);
            if (filteredErrors.length > 0) {
              ToastNotify({
                status: 'error',
                position: 'top-right',
                title: errorMessage,
              });
            }
          }

          if (notify && !result.errors && !isNull(notify.success)) {
            ToastNotify({
              status: 'success',
              position: 'top-right',
              title: notify.success
                ? notify.success(result.data)
                : defaultSuccess(),
            });
          }

          observer.next(result);
        },
        error: (networkError) => {
          if (notify) {
            ToastNotify({
              status: 'error',
              position: 'top-right',
              title: notify.error
                ? notify.error(networkError)
                : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  defaultError(networkError),
            });
          }
          observer.error(networkError);
        },
        complete: () => {
          observer.complete.bind(observer)();
        },
      });
    } catch (e) {
      observer.error(e);
    }

    return () => {
      subscription?.unsubscribe();
    };
  });
});
