import { Box, chakra } from '@chakra-ui/react';
import { NavLink as _NavLink } from 'react-router-dom';
import { colors } from '@app/theme/colors';

// main navigation links
const NavLink = chakra(_NavLink, {
  baseStyle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    color: colors.secondary[400],
    textDecoration: 'none',
    width: '100px',
    transition: '0.3s',
    textAlign: 'center',
    '&:hover': {
      color: 'secondary.600',
    },
    '&.active': {
      color: colors.main[400],
      _after: {
        content: `""`,
        position: 'relative',
        top: '-10px',
        width: '0',
        height: '0',
        borderLeft: `85px solid ${colors.transparent}`,
        borderRight: `85px solid ${colors.transparent}`,
        borderBottom: `85px solid ${colors.white}`,
        left: '50%',
        marginLeft: '-100px',
      },
    },
    '&.activeFull': {
      _after: {
        borderBottomColor: colors.zircon,
      },
    },
  },
});

const DialerNav = chakra(_NavLink, {
  baseStyle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    color: colors.secondary[400],
    textDecoration: 'none',
    width: '100px',
    transition: '0.3s',
    textAlign: 'center',
    '&:hover': {
      color: 'secondary.600',
    },
    '&.active': {
      color: colors.main[400],
      _after: {
        content: `""`,
        position: 'relative',
        top: '-10px',
        width: '0',
        height: '0',
        borderLeft: `85px solid ${colors.transparent}`,
        borderRight: `85px solid ${colors.transparent}`,
        borderBottom: `85px solid ${colors.white}`,
        left: '50%',
        marginLeft: '-84px',
      },
    },
    '&.activeFull': {
      _after: {
        borderBottomColor: colors.zircon,
      },
    },
  },
});

const LinkSettings = chakra(_NavLink, {
  baseStyle: {
    width: '20px',
    height: '20px',
    '&.active': {
      _after: {
        content: `""`,
        position: 'relative',
        top: '-10px',
        width: '0',
        height: '0',
        borderLeft: '35px solid transparent',
        borderRight: '35px solid transparent',
        borderBottom: `35px solid ${colors.white}`,
        left: '50%',
        marginLeft: '-35px',
      },
    },
  },
});

const PopoverLinkProfile = chakra(Box, {
  baseStyle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.notFound[3400],
    textDecoration: 'none',
    marginBottom: '18px',
    cursor: 'pointer',
  },
});

const LinkSubRoutes = chakra(_NavLink, {
  baseStyle: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: colors.main[400],
    textDecoration: 'none',
    cursor: 'pointer',
    letterSpacing: '0.1px',
    padding: '10px',

    '&.disabled': {
      color: 'americanSilver',
      pointerEvents: 'none',
    },

    '&.active': {
      color: 'primary.700',
      bg: 'primary.200',
      borderRadius: '20px',
    },

    '&:hover': {
      color: 'primary.700',
    },
  },
});

const SidebarLink = chakra(_NavLink, {
  baseStyle: {
    fontSize: '14px',
    borderRadius: '0px 20px 20px 0px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '30px',
    fontWeight: 500,
    position: 'relative',
    color: colors.secondary[400],
    transition: '0.5s',
    marginY: '2px',
    width: '220px',

    '&:hover': {
      background: colors.primary[100],
    },

    '&.active': {
      background: colors.primary[100],
      color: colors.primary[600],

      _before: {
        borderRadius: '0px 100px 100px 0px',
        background: colors.primary[600],
        display: 'block',
        content: '""',
        position: 'absolute',
        left: 0,
        width: '2px',
        height: '100%',
      },
    },
  },
});

// settings links
const SideBarLinkSubMenu = chakra(_NavLink, {
  baseStyle: {
    fontSize: '14px',
    lineHeight: '16px',
    borderRadius: '0px 20px 20px 0px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '26px',
    fontWeight: 500,
    position: 'relative',
    color: colors.secondary[400],
    transition: '0.5s',
    margin: '7px 0 7px',

    p: {
      width: '155px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre',
    },

    '&:hover': {
      background: colors.notFound[3500],
      cursor: 'pointer',
    },

    '&.active': {
      background: colors.notFound[3500],
      color: colors.switchedBlue[500],

      _before: {
        borderRadius: '0px 100px 100px 0px',
        background: colors.switchedBlue[500],
        display: 'block',
        content: '""',
        position: 'absolute',
        left: 0,
        width: '2px',
        height: '100%',
      },
    },
  },
});

export {
  NavLink,
  PopoverLinkProfile,
  LinkSettings,
  SidebarLink,
  LinkSubRoutes,
  SideBarLinkSubMenu,
  DialerNav,
};
