import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, memo, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import InfoIcon from '@app/icons/InfoIcon.svg?react';
import ROUTES from '@app/utils/routes';

import { getContactNames } from '@app/utils/contact';
import RenderPopoverContent from '../Filter/RenderPopoverContent';
import Popover from '../Popover';

interface SecondaryUsersListProps {
  isDisabled?: boolean;
  defaultValue: string;
  onChange: (profileId: string | null) => void;
}

const SecondaryUsersAccess = () => (
  <Popover
    borderRadius="5px"
    offset={[0, 5]}
    p="10px"
    placement="right"
    popoverText={
      <Text>
        This feature is accessible with the{' '}
        <Text as="span" color="primary.600">
          <Link target="_blank" to={`${ROUTES.settingsMembership}`}>
            Elite plan
          </Link>
        </Text>
      </Text>
    }
    trigger="hover"
    triggerElement={
      <Box p="3px">
        <InfoIcon />
      </Box>
    }
    widthContainer="305px"
  />
);

const SecondaryUsersList: FC<SecondaryUsersListProps> = ({
  isDisabled,
  defaultValue,
  onChange,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const account = useCurrentAccountData();
  const { id, subAccounts } = account;

  const users = useMemo(() => {
    if (isEmpty(subAccounts)) {
      return [];
    }
    return [account, ...subAccounts];
  }, [subAccounts, account]);

  const isHideUsersFilter = useMemo(
    () => isEmpty(users) && !isDisabled,
    [isDisabled, users],
  );

  const value = useMemo(
    () => [users.find((item) => item.id === defaultValue)?.id ?? id],
    [defaultValue, id, users],
  );

  const selectedUser = useMemo(() => {
    const user = users?.find((subAccount) => subAccount?.id === value?.[0]);

    if (isEmpty(user) || isDisabled) {
      return { name: { firstName: '', lastName: '' } };
    }

    return user;
  }, [isDisabled, users, value]);

  const handleChange = (profileId: string) => {
    const isMasterAccount = id === profileId;

    onChange(isMasterAccount ? null : profileId);
  };

  const { fullName } = getContactNames(selectedUser?.name);

  if (isHideUsersFilter) {
    return null;
  }

  return (
    <Flex
      align="center"
      borderStyle="solid"
      color="main.400"
      ml="15px"
      position="relative">
      <Flex alignItems="flex-start" direction="row" id="filter-box">
        <RenderPopoverContent
          filterTitle={isDisabled ? 'Users' : 'Users:'}
          filterValue={fullName}
          isDisabled={isDisabled}
          isOpen={isOpen}
          tooltip={<SecondaryUsersAccess />}
          onClose={onClose}
          onOpen={onOpen}>
          <CheckboxGroup value={value}>
            {users?.map((user) => (
              <Checkbox
                key={user.id}
                value={user?.id}
                variant="filterList"
                onChange={() => handleChange(user.id)}>
                {user.name?.firstName} {user.name?.lastName}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </RenderPopoverContent>
      </Flex>
    </Flex>
  );
};

export default memo(SecondaryUsersList);
