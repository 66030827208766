import { Descendant, Text } from 'slate';
import escapeHTML from 'escape-html';

export const serialize = (value: Descendant | Descendant[]): string => {
  const transformNode = (node: Descendant) => {
    if (Text.isText(node)) {
      let content = escapeHTML(node?.text);

      if (node?.bold) {
        content = `<strong>${content}</strong>`;
      }

      if (node?.italic) {
        content = `<em>${content}</em>`;
      }

      if (node?.underline) {
        content = `<u>${content}</u>`;
      }

      if (node?.strikethrough) {
        content = `<s>${content}</s>`;
      }

      return content;
    }

    const children = node.children
      .map((n: Descendant) => serialize(n))
      .join('');

    switch (node.type) {
      case 'paragraph': {
        const { align } = node;

        if (align) {
          return `<p style="text-align: ${align}">${children}</p>`;
        }
        return `<p>${children}</p>`;
      }
      default:
    }
  };

  if (Array.isArray(value)) {
    return value.map((n) => transformNode(n)).join('');
  } else {
    return transformNode(value);
  }
};
