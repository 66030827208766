import React, { FC, PropsWithChildren } from 'react';
import { IconButton } from '@chakra-ui/react';

import PlusInCircleIcon from '@app/icons/plus-in-circle-icon.svg?react';
import { PermissionFeatures, PermissionActions } from '@app/acl';
import { BreadCrumbs } from '@app/pages/Messenger/components/BreadCrumbs';
import { PermissionFlex } from '@app/components/next/atoms/PermissionsComponents/WithPermission';

interface CreateConversationTypes extends PropsWithChildren {
  title: string;
  path?: string;
  onOpen: () => void;
}

export const CreateConversation: FC<CreateConversationTypes> = ({
  onOpen,
  children,
  title,
  path,
}) => {
  const entityId = path.split('/')?.[2];

  return (
    <PermissionFlex
      action={PermissionActions.CREATE}
      alignItems="center"
      cursor="pointer"
      justifyContent="flex-start"
      subject={PermissionFeatures.messenger.conversation}
      onSuccess={onOpen}>
      <BreadCrumbs entityId={entityId} title={title} />
      <IconButton
        isRound
        aria-label="Create"
        color="secondary.300"
        icon={<PlusInCircleIcon />}
        size="sm"
        variant="ghost"
      />
      {children}
    </PermissionFlex>
  );
};
