import tinyColor from 'tinycolor2';

const toneColor = (color: string) => {
  if (!color) {
    return 'white';
  }
  const isDark = tinyColor(color).isDark();
  return isDark
    ? tinyColor(color).lighten(70).toString()
    : tinyColor(color).darken(50).toString();
};

export { tinyColor, toneColor };
