import { InputFieldStatus } from '@app/components/PhoneInput';

interface InitialStateType {
  phone: string;
  isCodeSent: boolean;
  verifyCode: string[];
  verifyId: string;
  status: InputFieldStatus;
  verifiedPhone: boolean;
  edit: boolean;
  isLoading: boolean;
  error: string;
}

export const initialState: InitialStateType = {
  phone: '',
  isCodeSent: false,
  verifyCode: [''],
  verifyId: '',
  status: InputFieldStatus.NONE,
  verifiedPhone: false,
  edit: false,
  isLoading: false,
  error: '',
};

export type PhoneActions =
  | { type: 'setPhone'; phone: string }
  | { type: 'sentCode'; isCodeSent: boolean; verifyId?: string }
  | { type: 'setCode'; verifyCode: string[] }
  | { type: 'setStatus'; status: InputFieldStatus }
  | { type: 'setVerified' }
  | { type: 'setLoading' }
  | { type: 'onError'; error?: string };

export const phoneReducer = (state: InitialStateType, action: PhoneActions) => {
  switch (action.type) {
    case 'setPhone':
      return { ...state, phone: action.phone, isCodeSent: false, error: '' };
    case 'sentCode':
      return {
        ...state,
        isCodeSent: action.isCodeSent,
        verifyId: action.verifyId ?? '',
        verifyCode: [''],
        error: '',
      };
    case 'setCode':
      return { ...state, verifyCode: action.verifyCode, error: '' };
    case 'setVerified':
      return {
        ...state,
        verifiedPhone: true,
        isCodeSent: false,
        edit: false,
        isLoading: false,
        status: InputFieldStatus.PASSED,
        error: '',
      };
    case 'setLoading':
      return {
        ...state,
        isLoading: !state.isLoading,
      };

    case 'onError':
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};
