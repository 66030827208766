export enum Header {
  PHONE = 'Phone',
  PHONE_TYPE = 'Phone Type',
  EMAIL = 'Email',
  FIRST_NAME = 'First Name',
  LAST_NAME = 'Last Name',
  ADDRESS = 'Address',
  CITY = 'City',
  STATE = 'State',
  ZIP = 'Zip',
  PROPERTY_ADDRESS = 'Property Address',
  PROPERTY_CITY = 'Property City',
  PROPERTY_STATE = 'Property State',
  PROPERTY_ZIP = 'Property Zip',
  MAILING_ADDRESS = 'Mailing Address',
  MAILING_CITY = 'Mailing City',
  MAILING_STATE = 'Mailing State',
  MAILING_ZIP = 'Mailing Zip',
}
