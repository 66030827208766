import React, { FC } from 'react';
import { Box, Link, Text } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';

import ROUTES from '@app/utils/routes';

import Tooltip from '../../Tooltip';

interface TooltipOwnedNumbersProps {
  isTrial: boolean;
}

const TooltipOwnedNumbers: FC<TooltipOwnedNumbersProps> = ({ isTrial }) => {
  if (isTrial) {
    return (
      <Box ml="5px" overflow="hidden">
        <Tooltip
          text={
            <Text>
              Your temporary trial number that is only used during the trial. To
              receive a permanent number please{' '}
              <Link
                as={RouteLink}
                textDecoration="underline"
                to={`${ROUTES.settingsMembership}`}
                variant="inline">
                activate your subscription.
              </Link>
            </Text>
          }
          widthContainer="235px"
        />
      </Box>
    );
  }
  return (
    <Box ml="5px" overflow="hidden">
      <Tooltip
        text={
          <Text p="5px" variant="heading-fifth">
            <strong>Approved</strong> &mdash; This phone number is approved and
            ready to be used for messaging
            <br />
            <br />
            <strong>Pending</strong> &mdash; This phone number is pending
            approval to be used for messaging
            <br />
            <br />
            <strong>Inactive</strong> &mdash; This phone number is not approved
            for messaging. We recommend deleting it.
          </Text>
        }
        widthContainer="305px"
      />
    </Box>
  );
};

export default TooltipOwnedNumbers;
