/* eslint-disable no-nested-ternary */
/* eslint-disable no-redeclare */
import styled from '@emotion/styled';
import { colors } from '@app/theme/colors';

interface DayElementTemplateTypes {
  isOffRange?: boolean;
  isToday?: boolean;
  isSelectedDate: boolean;
  isDisabled: boolean;
}

const DatePickerWrapStyle = styled.div`
  width: 315px;
  padding: 0 24px;
  height: auto;
`;

const NavigationWrapStyle = styled.div`
  overflow: hidden;
  width: 100%;
  padding: 13px 0;
  display: grid;
  grid-template-columns: 18px 1fr 18px;
  margin: auto;
`;

const BodyWrapStyle = styled.div`
  overflow-y: auto;
`;

const BodyWrapItemStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  background: ${colors.white};
  border-radius: 0px 0px 17px 17px;
  align-content: flex-start;
`;

const BodyMonthGridItem = styled.div<DayElementTemplateTypes>`
  color: ${({ isSelectedDate, isOffRange, isToday, isDisabled }) =>
    isSelectedDate
      ? colors.primary[600]
      : isOffRange || isDisabled
        ? colors.secondary[400]
        : isToday
          ? colors.primary[600]
          : 'inherit'};
  border: ${({ isSelectedDate }) =>
    isSelectedDate ? `1px solid ${colors.primary[600]}` : 'none'};
  border-radius: ${({ isSelectedDate }) => (isSelectedDate ? '50%' : 'none')};
  position: relative;
  width: 32px;
  height: 32px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2px;
  background: ${colors.white};
  font-size: 14px;
  user-select: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

const HeaderWrapStyle = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  background: ${colors.white};
  align-content: flex-start;
  border-top: 1px dashed ${colors.secondary[200]};
  padding: 16px 0;
`;

const HeaderItemStyle = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${colors.secondary[400]};
  font-size: 14px;
  text-transform: uppercase;
`;

const CurrentPeriodStyle = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: ${colors.main[400]};
  font-size: 16px;
  line-height: 18px;
`;

const NavigationButtonStyle = styled.div`
  overflow: hidden;
  width: 100%;
  padding: 3px;
  display: grid;
  justify-items: center;
  align-items: center;

  &:hover svg {
    stroke: ${colors.primary[600]};
    transition: 0.5s;
  }
`;

export {
  DatePickerWrapStyle,
  NavigationWrapStyle,
  BodyMonthGridItem,
  BodyWrapStyle,
  BodyWrapItemStyle,
  HeaderWrapStyle,
  HeaderItemStyle,
  CurrentPeriodStyle,
  NavigationButtonStyle,
};
