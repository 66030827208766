import React, { FC } from 'react';
import { Flex, Progress, Text, Image } from '@chakra-ui/react';
import Popup from '@app/components/Popup';
import CheckDoneBlueIcon from '@app/icons/check-done-blue.svg';
import { DisclaimerBox } from '@app/components/next/moleculas/DisclaimerBox';

interface ContactVerificationModalProps {
  isOpen: boolean;
  contactsCount: number;
  progress: number;
}

/**
 * @deprecated This will be unified into a global shared "waiting" component at some point
 */
export const ContactVerificationModal: FC<ContactVerificationModalProps> = ({
  isOpen,
  contactsCount,
  progress,
}) => {
  const isFinished = progress === 100;

  return (
    <Popup
      hideCloseIcon
      isOpen={isOpen}
      title="Verifying your list"
      width="464px"
      onClose={() => {}}>
      <Flex
        alignItems="center"
        flexDirection="column"
        gap="16px"
        mb="40px"
        mt="16px"
        px="40px"
        textAlign="center">
        <Text color="main.300" fontSize="14px">
          Verifying {contactsCount} contacts. This might take a while&hellip;
        </Text>

        <Flex alignItems="center" flexDirection="column" width="100%">
          {isFinished ? (
            <Flex alignItems="center" flexDirection="row" mb="6px">
              <Text
                color="primary.600"
                fontSize="12px"
                fontWeight="400"
                mr="6px">
                Done
              </Text>
              <Image alt="check done" src={CheckDoneBlueIcon} />
            </Flex>
          ) : (
            <Flex alignItems="center" flexDirection="row" mb="6px">
              <Text
                color="primary.600"
                fontSize="11px"
                lineHeight="13px"
                mr="6px">
                {progress}%
              </Text>
            </Flex>
          )}
          <Progress
            isAnimated
            borderRadius="4px"
            colorScheme="primary"
            h="4px"
            mb="9px"
            size="xs"
            value={progress}
            width="100%"
          />
        </Flex>

        <DisclaimerBox backgroundColor="paleGamboge" gap="8px" p="8px 16px">
          <Text color="main.400" fontSize="14px">
            Please don&apos;t close or refresh this page
          </Text>
        </DisclaimerBox>
      </Flex>
    </Popup>
  );
};
