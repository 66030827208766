import { Flex } from '@chakra-ui/react';
import React, { FC, PropsWithChildren } from 'react';
import { isMobile } from 'react-device-detect';

import { useApolloClient } from '@apollo/client';
import { useCampaignUpsertedSubscription } from '@app/api/gql/generated-types';
import { useDefaultLayoutInMobile } from '@app/hooks/useDefaultLayoutInMobile';

export const DefaultLayout: FC<PropsWithChildren> = ({ children }) => {
  const client = useApolloClient();
  const defaultLayoutInMobile = useDefaultLayoutInMobile();

  useCampaignUpsertedSubscription({
    onData: () => {
      client.cache.evict({ fieldName: 'getCampaignsCounters' });
      client.cache.evict({ fieldName: 'findCampaigns' });
    },
  });

  if (!defaultLayoutInMobile && isMobile) {
    return <Flex backgroundColor="white">{children}</Flex>;
  }

  return (
    <Flex
      backgroundColor="white"
      borderRadius="18px"
      direction="row"
      height={['100%', 'calc(100vh - 90px)']}
      mb="20px"
      overflow="hidden"
      position="relative"
      sx={{
        '&::-webkit-scrollbar': {
          width: '0',
        },
      }}
      width="100%">
      {children}
    </Flex>
  );
};
