import React, { FC } from 'react';

import { DateRangeType, TriggerProps } from '../types';

import { Filter } from './Filter';
import { Select } from './Select';

interface Props extends TriggerProps {
  type: DateRangeType;
}

export const Trigger: FC<Props> = ({ type, ...props }) => {
  switch (type) {
    case DateRangeType.SELECT:
      return <Select {...props} />;
    case DateRangeType.FILTER:
      return <Filter {...props} />;
    default:
      return null;
  }
};
