import { Kind, OperationTypeNode } from 'graphql';
import {
  ApolloClient,
  from,
  split,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'firebase/auth';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

import { isProduction } from '@app/utils/envUtils';
import { getLogger } from '@app/utils/logger';
import { link as networkStatusLink } from './network-status-link';
import {
  cursorPagination,
  paginationByCursor,
  paginationOverridden,
} from './apollo-pagination';
import { notifierLink } from './apollo-notifier-link';

const LOG = getLogger('apollo-client');

export const initApolloClient = ({ auth }: { auth?: Auth }) => {
  const httpLink = new HttpLink({
    uri: import.meta.env.VITE_GRAPHQL_URL || '',
  });
  const authLink = setContext(async (_, { headers }) => {
    const token = await auth?.currentUser?.getIdToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      } as Record<string, string>,
    };
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: import.meta.env.VITE_WSS_URL,
      keepAlive: 60000,

      retryAttempts: 10,
      shouldRetry: (eventOrError) => {
        LOG.debug('WS: Retrying connection:', eventOrError);

        return true;
      },

      connectionParams: async () => {
        const token = await auth?.currentUser?.getIdToken();

        if (!token) {
          return {};
        }

        return LOG.return({
          authorization: token ? `Bearer ${token}` : '',
        });
      },
    }),
  );

  // Omit __typename from variables
  const sanitizeLink = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: string, value: unknown) =>
        key === '__typename' ? undefined : value;

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename,
      );
    }
    return forward(operation).map((data) => {
      return data;
    });
  });

  const links = from([authLink, notifierLink, sanitizeLink, networkStatusLink]);

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      LOG.debug('Operation definition', definition);

      return (
        definition.kind === Kind.OPERATION_DEFINITION &&
        definition.operation === OperationTypeNode.SUBSCRIPTION
      );
    },
    links.concat(wsLink),
    links.concat(httpLink),
  );

  return new ApolloClient({
    link,
    connectToDevTools: !isProduction,
    cache: new InMemoryCache({
      addTypename: true,
      typePolicies: {
        ContactRealEstateAttribute: {
          keyFields: false,
        },
        Query: {
          fields: {
            findAffiliates: cursorPagination(['filter']),
            findGroups: cursorPagination(['filter', 'order']),
            findLabels: paginationByCursor(['filter']),
            findMacros: paginationByCursor(['filter']),
            findMarketingPopups: cursorPagination(),
            findMessageTemplates: cursorPagination(['filter']),
            findCallScripts: cursorPagination(['filter']),
            findKeywordTemplates: cursorPagination(['filter']),
            findScheduledMessages: paginationOverridden(['filter', 'type']),
            findContacts: cursorPagination(['filter', 'order']),
            findContactsPhones: cursorPagination(['filter']),
            findListItems: cursorPagination(['listId']),
            findSkiptraces: cursorPagination(['order']),
            findConversations: cursorPagination(['filter', 'order']),
            findCampaigns: cursorPagination(['filter']),
            findDialerCampaigns: cursorPagination(['filter', 'order']),
            findSuppressions: cursorPagination(['filter']),
            findAccounts: cursorPagination(['filter']),
            findFields: cursorPagination(['filter']),
            findCrmIntegrations: cursorPagination(['filter']),
            findConversationMessages: cursorPagination(['filter']),
            findCampaignMessages: paginationOverridden(['filter', 'type']),
            findContactNotes: cursorPagination(['filter']),
            findNotifications: cursorPagination(),
          },
        },
      },
    }),
  });
};
