import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import React, { FC, useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import Popup from '@app/components/Popup';
import {
  FindSkiptracesDocument,
  useRemoveSkiptracesMutation,
} from '@app/api/gql/generated-types';

interface RemoveContactListModalProps {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  onSelectedLists: (items: string[]) => void;
}

export const RemoveContactListModal: FC<RemoveContactListModalProps> = ({
  id,
  isOpen,
  onClose,
  onSelectedLists,
}) => {
  const [removeSkiptracesMutation, { loading }] = useRemoveSkiptracesMutation({
    refetchQueries: [FindSkiptracesDocument],
  });

  const onConfirm = useCallback(async () => {
    await removeSkiptracesMutation({
      variables: {
        input: {
          ids: [id],
        },
      },
      context: {
        useApolloNetworkStatus: true,
        notify: {
          success: () => `Successfully deleted`,
          error: (error: ApolloError[]) => error[0].message,
        },
      },
    });
    onSelectedLists([]);
    onClose();
  }, [removeSkiptracesMutation, id, onSelectedLists, onClose]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="370px"
      size="3xl"
      title="Delete"
      onClose={onClose}>
      <Flex alignItems="center" direction="column">
        <Text mt="25px" variant="confirm-text" width="270px">
          Are you sure you want to delete the list?
        </Text>
        <Stack direction="row" mt="30px" spacing={4}>
          <Button mb="40px" variant="outlined" width="83px" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={loading}
            variant="primary"
            width="83px"
            onClick={onConfirm}>
            Delete
          </Button>
        </Stack>
      </Flex>
    </Popup>
  );
};
