import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripeError } from '@stripe/stripe-js';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { FC, useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';

import ToastNotify from '@app/components/ToastNotifier';
import { addCardSchema } from '@app/schemas/add-card-schema';
import ROUTES from '@app/utils/routes';

import {
  AccountBillingSourcesDocument,
  AccountDocument,
  useAddPaymentSourceMutation,
} from '@app/api/gql/generated-types';

import CardBrandIcon from '@app/components/SelectPayment/CardBrandIcon';
import { colors } from '@app/theme/colors';
import { FormSubmit } from './FormSubmit';

interface StripeFormProps {
  title: string;
  isModal?: boolean;
  onModalClose?: () => void;
  onConfirm?: (id: string) => void;
}

const cardElementOptions = {
  style: {
    base: {
      fontFamily: 'Roboto',
      height: '40px',
      fontSize: '14px',
      padding: '0 20px',
      color: colors.main[400],
      fontWeight: '600',
      '::placeholder': {
        fontFamily: 'Roboto',
        fontWeight: '400',
        color: colors.secondary[400],
      },
    },
    invalid: {
      color: colors.error,
    },
  },
};

const initialValues = {
  name: '',
  address_line1: '',
  address_city: '',
  address_state: '',
  address_zip: '',
};

type Values = typeof initialValues;

export const StripeForm: FC<StripeFormProps> = ({
  title,
  onModalClose,
  isModal,
  onConfirm,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();

  const hasDiscountRef = useRef(
    !!(location?.state as { hasDiscount: boolean })?.hasDiscount,
  );

  const [addCard, { loading: isLoading }] = useAddPaymentSourceMutation({
    refetchQueries: [AccountDocument, AccountBillingSourcesDocument],
  });

  const [brand, setBrand] = useState<string>(null);

  const onAddCard = useCallback(
    async (tokenId: string) => {
      await addCard({
        variables: {
          input: {
            id: tokenId,
          },
        },
      });

      if (hasDiscountRef.current) {
        navigate(`${ROUTES.settingsMembership}`, {
          state: { startSubscription: hasDiscountRef.current },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addCard],
  );

  const onSubmit = useCallback(
    async (values: Values, formikHelpers: FormikHelpers<Values>) => {
      if (!stripe) {
        ToastNotify({
          status: 'error',
          position: 'top-right',
          title: 'Payment gateway error',
        });

        return;
      }
      const card = elements.getElement(CardNumberElement);
      const expiry = elements.getElement(CardExpiryElement);
      const cvc = elements.getElement(CardCvcElement);
      try {
        const { token, error } = await stripe.createToken(card, { ...values });
        if (error) {
          ToastNotify({
            status: 'error',
            position: 'top-right',
            title: error?.message,
          });
          throw new Error(error?.message);
        }
        await onAddCard(token?.id);
        onConfirm(token?.card?.id);

        if (onModalClose) {
          onModalClose();
        }
        card.clear();
        cvc.clear();
        expiry.clear();
        formikHelpers.resetForm();
      } catch (error) {
        ToastNotify({
          status: 'error',
          position: 'top-right',
          title: (error as StripeError)?.message,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elements, onAddCard, onModalClose, stripe],
  );

  const handleBrandChange = (event: {
    brand: string;
    error: { message: string };
  }) => {
    setBrand(event.brand);
    if (event?.error) {
      ToastNotify({
        status: 'error',
        position: 'top-right',
        title: `${event.error.message.toString()}`,
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={addCardSchema}
      onSubmit={onSubmit}>
      {({ errors, values, handleChange, handleBlur, touched }) => {
        return (
          <Form>
            <Box maxWidth="360px" w="100%">
              <Text
                color="main.400"
                fontSize={isModal || isMobile ? '20px' : '16px'}
                fontWeight="500"
                mb={['18px', '30px']}
                mt={['26px', '0px']}
                textAlign={isModal || isMobile ? 'center' : 'left'}>
                {title}
              </Text>
              <FormControl
                isInvalid={!!touched.name && !!errors.name}
                mb={['18px', '20px']}>
                <Input
                  isDisabled={isLoading}
                  name="name"
                  placeholder="Full name"
                  style={{
                    fontWeight: '500',
                    // @ts-expect-error autofill is not in types
                    _autofill: {
                      color: colors.main[400],
                    },
                  }}
                  value={values.name}
                  variant="primary"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.name}</FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!touched.address_line1 && !!errors.address_line1}
                mb={['18px', '20px']}>
                <Input
                  isDisabled={isLoading}
                  name="address_line1"
                  placeholder="Billing address"
                  style={{
                    fontWeight: '500',
                    // @ts-expect-error autofill is not in types
                    _autofill: {
                      color: colors.main[400],
                    },
                  }}
                  value={values.address_line1}
                  variant="primary"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.address_line1}</FormErrorMessage>
              </FormControl>
              <SimpleGrid
                columnGap="15px"
                columns={isMobile ? 1 : 3}
                mb={['18px', '20px']}
                rowGap="18px">
                <FormControl
                  isInvalid={!!touched.address_city && !!errors.address_city}>
                  <Input
                    isDisabled={isLoading}
                    name="address_city"
                    placeholder="City"
                    style={{
                      fontWeight: '500',
                      // @ts-expect-error autofill is not in types
                      _autofill: {
                        color: colors.main[400],
                      },
                    }}
                    value={values.address_city}
                    variant="primary"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.address_city}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!touched.address_state && !!errors.address_state}>
                  <Input
                    isDisabled={isLoading}
                    maxLength={8}
                    name="address_state"
                    placeholder="State"
                    style={{
                      fontWeight: '500',
                      // @ts-expect-error autofill is not in types
                      _autofill: {
                        color: colors.main[400],
                      },
                    }}
                    value={values.address_state}
                    variant="primary"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.address_state}</FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!touched.address_zip && !!errors.address_zip}>
                  <Input
                    isDisabled={isLoading}
                    maxLength={8}
                    name="address_zip"
                    placeholder="Zip"
                    style={{
                      fontWeight: '500',
                      // @ts-expect-error autofill is not in types
                      _autofill: {
                        color: colors.main[400],
                      },
                    }}
                    value={values.address_zip}
                    variant="primary"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{errors.address_zip}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>
              <Flex
                _hover={{
                  border: `1px solid ${colors.primary[700]}`,
                }}
                border={`1px solid ${colors.secondary[200]}`}
                borderRadius="22px"
                flexDirection="row"
                justifyContent="space-between"
                mb={['18px', '20px']}
                p="12px 10px 8px 20px">
                <Box flex="1 0 auto">
                  <CardNumberElement
                    options={{
                      ...cardElementOptions,
                      placeholder: 'Card number',
                    }}
                    onChange={handleBrandChange}
                  />
                </Box>
                <Box flex="0 0 auto">
                  <CardBrandIcon brand={brand} />
                </Box>
              </Flex>
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                mb={['18px', '20px']}>
                <Box
                  _hover={{
                    border: `1px solid ${colors.primary[700]}`,
                  }}
                  border={`1px solid ${colors.secondary[200]}`}
                  borderRadius="22px"
                  height="40px"
                  p="12px 20px 8px"
                  width="calc(50% - 10px)">
                  <CardExpiryElement options={cardElementOptions} />
                </Box>
                <Box
                  _hover={{
                    border: `1px solid ${colors.primary[700]}`,
                  }}
                  border={`1px solid ${colors.secondary[200]}`}
                  borderRadius="22px"
                  height="40px"
                  p="12px 20px 8px"
                  width="calc(50% - 10px)">
                  <CardCvcElement options={cardElementOptions} />
                </Box>
              </Flex>
            </Box>
            <FormSubmit
              isLoading={isLoading}
              isModal={isModal}
              title={hasDiscountRef.current ? 'Start Subscription' : 'Add card'}
              width={hasDiscountRef.current ? '154px' : undefined}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
