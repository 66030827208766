import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Column, useTable } from 'react-table';
import { CallScript } from '@app/api/gql/generated-types';
import { ScriptsRow } from './ScriptsRow';
interface ScriptsTableProps<T extends object> {
  items: T[];
  columns: Column<T>[];
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  onDrop: (item: T) => void;
}

export const ScriptsTable = <T extends CallScript>({
  items,
  columns,
  moveRow,
  onDrop,
}: ScriptsTableProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: items,
      getRowId: (row) => row.id,
    });
  return (
    <DndProvider backend={HTML5Backend}>
      <Table {...getTableProps()} variant="simple">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr
              key={headerGroup.getHeaderGroupProps().key}
              {...headerGroup.getHeaderGroupProps()}>
              <Th pl="10px" px="0" />
              {headerGroup.headers.map((column) => (
                <Th
                  key={column.getHeaderProps().key}
                  {...column.getHeaderProps()}>
                  {column.render('Header')}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <ScriptsRow
                key={row.getRowProps().key}
                index={index}
                moveRow={moveRow}
                row={row}
                onDropRow={onDrop}
              />
            );
          })}
        </Tbody>
      </Table>
    </DndProvider>
  );
};
