import React, { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { colors } from '@app/theme/colors';

interface EditableErrorProps {
  error: string;
}

const EditableError: FC<EditableErrorProps> = ({ error }) => {
  if (!error) {
    return null;
  }
  return (
    error && (
      <Text color={colors.error} fontSize="14px">
        {error}
      </Text>
    )
  );
};

export default EditableError;
