import { useMemo } from 'react';

import { MultiValue } from 'react-select';
import { Label, LabelFragment } from '@app/api/gql/generated-types';
import { useLabelsQueryData } from '@app/api/queries/useLabelsQueryData';

interface SelectedLabel extends Label {
  value: Label['id'];
  label: string;
  color: string;
  isChecked?: boolean;
}

export const useFilterLabels = (labels: MultiValue<LabelFragment> = []) => {
  const { data, isLoading, fetchNextPage } = useLabelsQueryData();

  const selectedIds = useMemo(
    () => labels?.map(({ id }) => id) ?? [],
    [labels],
  );

  const options = useMemo(() => {
    const selectedLabels = labels ?? [];
    const filteredLabels = data.filter(({ id }) => !selectedIds.includes(id));
    return [
      ...selectedLabels.map((label) => ({
        ...label,
        isChecked: true,
      })),
      ...filteredLabels,
    ];
  }, [data, labels, selectedIds]) as SelectedLabel[];

  return {
    options,
    fetchMore: fetchNextPage,
    isLoading,
    selected: labels,
  };
};
