import { StringSchema, addMethod, string as yupString } from 'yup';

function testNonWhitespace(this: StringSchema, message?: string) {
  return this.test('requiredNonWhitespace', message, (val, ctx) => {
    const isValid = typeof val === 'string' && val.trim().length > 0;
    return isValid
      ? true
      : ctx.createError({
          message: ({ label }) =>
            typeof message === 'string' // allow empty error message
              ? message
              : `${label} requires non-whitespace characters`,
        });
  });
}

// Avoid TS problems -- declare the validation method.
declare module 'yup' {
  interface StringSchema {
    /**
     * Ensure the string consists of more than just whitespace and line terminators.
     * @param message The error message to use if the value is empty or whitespace. NOTE: `undefined` will result in default message; and `""` will result in an error on the form, but not on the field.
     */
    requiredNonWhitespace(message?: string): StringSchema;
  }
}

export function registerRequiredNonWhitespaceValidator() {
  addMethod(yupString, 'requiredNonWhitespace', testNonWhitespace);
}
