import { ApolloCache } from '@apollo/client';
import { FindConversationsQuery } from '@app/api/gql/generated-types';

export const removeConversationFromCache = (
  cache: ApolloCache<any>,
  conversationId: string,
) => {
  cache.modify({
    fields: {
      findConversations(existingConversations, { readField }) {
        return {
          ...(existingConversations as FindConversationsQuery['findConversations']),
          items: (
            existingConversations as FindConversationsQuery['findConversations']
          ).items.filter((item) => conversationId !== readField('id', item)),
        };
      },
    },
  });
};
