import { Box, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import EmptyFilter from '@app/icons/messenger/empty-labels-filtering.svg?react';

export const EmptyConversationFilterResult: FC = () => {
  return (
    <Box textAlign="center">
      <EmptyFilter />
      <Box my="15px">
        <Text color="secondary.400" fontSize="22px" fontWeight="500">
          No results found
        </Text>
      </Box>
    </Box>
  );
};
