import React, { FC, useCallback } from 'react';
import { Box, Button, Flex, Text, UseDisclosureProps } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { useBrandRegistrationRoute } from '@app/hooks/useBrandRegistrationRoute';
import Popup from '../Popup';

/**
 *  @deprecated Use the new permission Features & permission custom modal.
 * */
export const PendingBrandModal: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();
  const { route } = useBrandRegistrationRoute();

  const goToPage = useCallback(() => {
    onClose();
    navigate(route);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, route]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="566px"
      title="Your registration is in progress..."
      onClose={onClose}>
      <Box p="30px 40px 40px">
        <Text textAlign="center" variant="list-style">
          Our team is getting your business registered and we&apos;re awaiting
          final approval. We will inform you by email when you can start sending
          SMS 🚀
        </Text>
        <Text mt="10px" textAlign="center" variant="list-style">
          You can also view your account status in the{' '}
          <Button variant="link" onClick={goToPage}>
            Settings menu
          </Button>
        </Text>
        <Flex justifyContent="center" mt="25px">
          <Button variant="primary" w="145px" onClick={onClose}>
            Close
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};
