import { string } from 'yup';
import {
  nameRegex,
  STD_TITLE_MAX_LENGTH,
  stdTitleMaxLengthMessage,
  stdTitleSpecialCharactersSupportedMessage,
  stdTitleSpecialCharactersSupportedRegex,
} from '@app/schemas/common';

export const templateNameSchema = string()
  .trim()
  .required('')
  .max(STD_TITLE_MAX_LENGTH, stdTitleMaxLengthMessage())
  .matches(stdTitleSpecialCharactersSupportedRegex, {
    excludeEmptyString: true,
    message: stdTitleSpecialCharactersSupportedMessage,
  });

export const createNameValidation = (fieldName: string) =>
  string()
    .trim()
    .matches(
      nameRegex,
      `${fieldName} should contain only letters and start with a capital one`,
    )
    .max(30, `${fieldName} cannot be more than 30 characters`)
    .test(
      'not-only-spaces',
      `${fieldName} cannot contain only spaces`,
      (value) => value && value.trim().length > 0,
    );
