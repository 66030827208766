import React, { FC, Fragment, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { usePermissionAbility } from '@app/hooks/usePermissionAbility';
import { PermissionActions, Permissions } from '@app/acl';
import NotFound from '@app/components/NotFound';

export const PrivateRoute: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const location = useLocation();

  const checkPermissionAndRender = (canSubmit: boolean) => {
    return canSubmit ? (
      <Fragment key={location.key}>{children}</Fragment>
    ) : (
      <Navigate replace state={{ from: location }} to="/*" />
    );
  };

  const { can: canSubmitDlc } = usePermissionAbility(
    PermissionActions.CREATE,
    Permissions.Dlc,
  );

  const { can: canSubmitBrandRegistration } = usePermissionAbility(
    PermissionActions.CREATE,
    Permissions.BrandRegistration,
  );

  if (canSubmitDlc) {
    return checkPermissionAndRender(canSubmitDlc);
  }

  if (canSubmitBrandRegistration) {
    return checkPermissionAndRender(canSubmitBrandRegistration);
  }

  return <NotFound />;
};
