import { DateTime } from 'luxon';

const selectedTimeIsToday = (
  today: DateTime,
  selectedTime: DateTime,
): boolean => {
  return (
    selectedTime.hasSame(today, 'year') &&
    selectedTime.hasSame(today, 'month') &&
    selectedTime.hasSame(today, 'day')
  );
};

export default function disabledTimeToday(
  today: DateTime,
  selectedTime: DateTime,
) {
  const disabledHours = [];
  const disabledMinutes = [];
  let disabledTimeFormat = false;
  if (selectedTimeIsToday(selectedTime, today)) {
    disabledTimeFormat = today.hour >= 12;
    for (let i = 0; i !== today.hour; i++) {
      disabledHours.push(i);
    }
    if (selectedTime.hour === today.hour) {
      for (let i = 0; i !== today.minute; i++) {
        disabledMinutes.push(i);
      }
    }
  }
  return { disabledHours, disabledMinutes, disabledTimeFormat };
}

export const getDisabledTime = (date: DateTime, nextDate: DateTime) => {
  let disabledTimeFormat = false;

  const hours = [];

  if (selectedTimeIsToday(date, nextDate)) {
    disabledTimeFormat = date.hour >= 12;
    for (let i = 0; i < date.hour; i++) {
      hours.push(i);
    }
  }

  const minutes = [];

  if (
    selectedTimeIsToday(date, nextDate) &&
    (date.hour === nextDate.hour || date.hour > nextDate.hour)
  ) {
    for (let i = 0; i <= date.minute; i++) {
      minutes.push(i);
    }
  }

  const disabledTime = {
    disabledHours: hours,
    disabledMinutes: minutes,
    disabledTimeFormat,
  };

  return disabledTime;
};
