import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { isProduction } from '@app/utils/envUtils';
import { App } from './App';
import { initGTM } from './tagManagerTracker';
import initSourceBuster from './setupSourceBuster';
import UpdateApp from './components/UpdateApp';
import { collectClid } from './collect-clid';
import { initFP } from './setupFP';
import { ThemeProvider } from './ThemeProvider';
import initSentry from './setupSentry';

initGTM();

if (isProduction) {
  initSentry();
  initFP();
  initSourceBuster();
  collectClid();
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <UpdateApp />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);

export { phoneNumberStatus } from '@app/utils/phoneNumberUtils';
export { getPhoneNumberTypeText } from '@app/utils/phoneNumberUtils';
