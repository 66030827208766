import React, { FC, useRef } from 'react';
import { Button, IconButton, Tooltip, useDisclosure } from '@chakra-ui/react';
import BucketIcon from '@app/icons/bucket-icon.svg?react';
import { RemoveNotPossibleModal } from '@app/components/Modals/RemoveNotPossibleModal';
import { wordPluralize } from '@app/utils/string';
import { colors } from '@app/theme/colors';
import { RemoveBatchMessagesModal } from '../RemoveModals/RemoveBatchMessages';
import { BulkActionButtonProps } from './types';

interface DeleteButtonProps extends BulkActionButtonProps {
  type: 'forever' | 'basket';
  canRemoveContacts?: boolean;
}

export const DeleteButton: FC<DeleteButtonProps> = ({
  selectedContacts,
  type,
  onComplete,
  params,
  canRemoveContacts = true,
}) => {
  const removeButtonRef = useRef<HTMLButtonElement>(null);
  const removeBatchMessages = useDisclosure();
  const removeNotPossibleModal = useDisclosure();

  const handleRemove = () => {
    removeButtonRef?.current?.blur();

    if (!canRemoveContacts) {
      removeNotPossibleModal.onOpen();
      return;
    }
    removeBatchMessages.onOpen();
  };

  return (
    <>
      {type === 'forever' && (
        <Button
          leftIcon={<BucketIcon />}
          size="sm"
          variant="outlined"
          onClick={handleRemove}>
          Delete forever
        </Button>
      )}

      {type === 'basket' && (
        <Tooltip
          closeOnClick
          hasArrow
          label="Delete conversation(s) from your inbox"
          placement="top">
          <IconButton
            ref={removeButtonRef}
            isRound
            _active={{ bg: colors.transparent }}
            _focus={{ outline: 'none' }}
            _hover={{ color: 'primary.600', bgColor: 'primary.200' }}
            aria-label="Open message remove modal"
            bg={colors.transparent}
            color="main.100"
            size="xs"
            onClick={handleRemove}>
            <BucketIcon />
          </IconButton>
        </Tooltip>
      )}

      <RemoveBatchMessagesModal
        ids={selectedContacts}
        params={params}
        removeBatchMessagesDisclosure={removeBatchMessages}
        onDelete={onComplete}
        onRemoveError={removeNotPossibleModal.onOpen}
      />

      {removeNotPossibleModal.isOpen && (
        <RemoveNotPossibleModal
          isOpen={removeNotPossibleModal.isOpen}
          text={
            selectedContacts.length > 1
              ? 'You cannot delete these selected contacts because they are part of an active or paused campaign.'
              : 'You cannot delete this contact because it is part of an active or paused campaign.'
          }
          title={`Delete ${wordPluralize(selectedContacts.length, 'contact')}`}
          onClose={removeNotPossibleModal.onClose}
        />
      )}
    </>
  );
};
