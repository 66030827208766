import React, { FC } from 'react';
import { Button, Image } from '@chakra-ui/react';

import { colors } from '@app/theme/colors';
import { WysiwygControlProps } from '../config/types';

export const WysiwygControl: FC<WysiwygControlProps> = ({
  onCommandClick,
  action,
  disabled,
}) => {
  const handleCommandClick = () => {
    onCommandClick(action.command);
  };

  return (
    <Button
      _last={{ marginRight: 0 }}
      alignItems="center"
      aria-label={action.label}
      backgroundColor={disabled ? 'secondary.100' : colors.notFound[6000]}
      borderRadius="8px"
      display="flex"
      height="24px"
      isDisabled={disabled}
      justifyContent="center"
      marginRight="6px"
      minWidth="24px"
      padding="0 8px"
      variant="ghost"
      onClick={handleCommandClick}>
      {action.icon && <Image src={`/images/icons/wysiwyg/${action.icon}`} />}
      {action.content}
    </Button>
  );
};
