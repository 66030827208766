import { Link, Switch, Text, useDisclosure } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import { Features } from '@app/acl';
import {
  useAccountQuery,
  useUpdateMessagingProfileMutation,
} from '@app/api/gql/generated-types';
import { useDisableFeatureAbility } from '@app/hooks/useDisableFeatureAbility';
import { usePopupsContext } from '@app/hooks/usePopupsContext';
import { ContentPreview } from '@app/widgets/ContentPreview';

import { usePlanPermission } from '@app/hooks/usePlanPermission';
import ROUTES from '@app/utils/routes';
import { useUpdateMessagingProfileFragment } from '@app/api/hooks/useUpdateMessagingProfileFragment';
import { SettingsLayout } from '@app/layouts/SettingsLayout';
import Tooltip from '../Tooltip';

import { voiceTypeMap } from '../../lib/config';
import { CreateAutoReply } from './CreateAutoReply';
import { CallAutoReplyFormValues } from './lib/types';

interface CallAutoReplyProps {
  isDialerPlanInactive: boolean;
}

export const CallAutoReply: FC<CallAutoReplyProps> = ({
  isDialerPlanInactive,
}) => {
  const autoReplyModal = useDisclosure();
  const { data: { account } = {} } = useAccountQuery();
  const { messagingProfile } = account;
  const { updateCallSettingsFragment } = useUpdateMessagingProfileFragment();
  const { isPrimitivePlans } = usePlanPermission();
  const { can: canCallAutoReply } = useDisableFeatureAbility(
    Features.CallAutoReply,
  );
  const { showConfirmPopup, closeConfirmPopup } = usePopupsContext();

  const [updateMessagingProfile, { loading }] =
    useUpdateMessagingProfileMutation();

  const [isChecked, setIsChecked] = useState(
    canCallAutoReply &&
      !!messagingProfile?.callSettings?.callAutoReply?.enabled,
  );

  const handleSwitch = async () => {
    const enabled = !isChecked;
    setIsChecked(enabled);

    if (!messagingProfile?.callSettings?.callAutoReply?.payload) {
      autoReplyModal.onOpen();
      return;
    }
    await updateMessagingProfile({
      variables: {
        input: {
          callSettings: {
            callAutoReply: {
              enabled,
            },
          },
        },
      },
    });
  };

  const handleSave = async ({ voiceType, text }: CallAutoReplyFormValues) => {
    updateCallSettingsFragment({
      id: messagingProfile?.id,
      callSettings: {
        callAutoReply: {
          enabled: true,
          voiceType,
          payload: text,
        },
      },
    });
    await updateMessagingProfile({
      variables: {
        input: {
          callSettings: {
            callAutoReply: {
              enabled: true,
              voiceType,
              payload: text,
            },
          },
        },
      },
    });

    autoReplyModal.onClose();
  };

  const handleDelete = async () => {
    try {
      await showConfirmPopup({
        title: 'Delete',
        description: (
          <Text marginBottom="20px" variant="confirm-text">
            Are you sure you want to delete this Auto-Reply record? Once
            deleted, it cannot be restored.
          </Text>
        ),
        confirmButtonText: 'Confirm',
        confirmButtonWidth: '160px',
        hideCancelButton: true,
      });

      closeConfirmPopup();

      setIsChecked(false);

      await updateMessagingProfile({
        variables: {
          input: {
            callSettings: {
              callAutoReply: null,
            },
          },
        },
      });
      updateCallSettingsFragment({
        id: messagingProfile?.id,
        callSettings: {
          callAutoReply: null,
        },
      });
    } catch (error) {
      console.error('🚀 ~ handleDelete ~ error:', error);
    }
  };

  const handleEdit = () => {
    autoReplyModal.onOpen();
  };

  const handleClose = () => {
    autoReplyModal.onClose();
    if (!messagingProfile?.callSettings?.callAutoReply?.payload) {
      setIsChecked(false);
    }
  };

  useEffect(() => {
    if (messagingProfile?.callSettings?.callAutoReply?.enabled) {
      setIsChecked(true);
    }
  }, [messagingProfile?.callSettings?.callAutoReply?.enabled]);

  return (
    <>
      <SettingsLayout
        alignItems="flex-start"
        childComponent={
          <>
            <Switch
              colorScheme="switchedBlue"
              disabled={isDialerPlanInactive}
              h="20px"
              isChecked={isChecked}
              name="callAutoReply"
              w="38px"
              onChange={handleSwitch}
            />
            {isChecked &&
              !isDialerPlanInactive &&
              messagingProfile?.callSettings?.callAutoReply?.payload && (
                <ContentPreview
                  isDeleting={loading}
                  text={messagingProfile.callSettings.callAutoReply.payload}
                  title={`Call auto-reply — ${
                    voiceTypeMap[
                      messagingProfile.callSettings.callAutoReply.voiceType
                    ]
                  }`}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              )}
          </>
        }
        iconComponent={
          <Tooltip
            text={
              isPrimitivePlans ? (
                <Text width="200px">
                  Please{' '}
                  <Link
                    as={RouteLink}
                    to={`${ROUTES.settings}/membership`}
                    variant="link">
                    upgrade
                  </Link>{' '}
                  your Plan to enable call auto-reply
                </Text>
              ) : (
                'Custom text that will be automatically responded to a contact when you are unavailable.'
              )
            }
            widthContainer={isPrimitivePlans ? '200px' : '260px'}
          />
        }
        marginTop="30px"
        titleLabel="Call auto-reply"
      />
      {canCallAutoReply && autoReplyModal.isOpen && (
        <CreateAutoReply
          defaultValues={{
            text: messagingProfile?.callSettings?.callAutoReply?.payload,
            voiceType: messagingProfile?.callSettings?.callAutoReply?.voiceType,
          }}
          isOpen={autoReplyModal.isOpen}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
    </>
  );
};
