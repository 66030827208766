import { DateTime as DT } from 'luxon';
import { useMemo } from 'react';
import uniq from 'lodash/uniq';

import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';

export const MIN_TIME = 8;
export const MAX_TIME = 21;

export const disabledHoursArray = [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23, 24];

export const useDisabledDates = ({
  startDate = DT.fromJSDate(new Date()),
  customTimeZone,
}: { startDate?: DT; customTimeZone?: string } = {}) => {
  const account = useCurrentAccountData();
  const timezone = customTimeZone || account?.timeZone;
  const defaultTime = DT.fromJSDate(new Date()).setZone(timezone);

  const isDisabledStartDate = (day: DT, disabledStartDate: DT) => {
    if (disabledStartDate) {
      return (
        day.startOf('day').toMillis() <
        disabledStartDate.startOf('day').toMillis()
      );
    }
    return false;
  };
  const isDisabledEndDate = (day: DT, disabledEndDate: DT) => {
    if (disabledEndDate) {
      return (
        day.endOf('day').toMillis() > disabledEndDate.endOf('day').toMillis()
      );
    }
    return false;
  };
  const enabledHour = useMemo(() => {
    if (startDate.startOf('hour').toMillis() < defaultTime.hour) return true;
    return false;
  }, [defaultTime.hour, startDate]);

  const disabledScheduleHours = useMemo(() => {
    const enabledDays = defaultTime.toMillis() < startDate.toMillis();
    const hours = [...disabledHoursArray];
    if (enabledDays) return hours;
    for (let i = 0; i < defaultTime.hour; i++) {
      hours.push(i);
    }

    return uniq(hours);
  }, [defaultTime, startDate]);

  const disabledScheduleMinutes = useMemo(() => {
    const minutes: number[] = [];
    const enabledDays = defaultTime.toMillis() < startDate.toMillis();
    if (startDate.hour >= MAX_TIME) {
      return [...Array(60).keys()];
    }
    if (enabledDays || enabledHour) return minutes;
    for (let i = 0; i < defaultTime.minute; i++) {
      minutes.push(i);
    }

    return minutes;
  }, [defaultTime, enabledHour, startDate]);
  return {
    defaultTime,
    disabledHoursArray,
    isDisabledStartDate,
    isDisabledEndDate,
    disabledScheduleHours,
    disabledScheduleMinutes,
  };
};
