import React, { FC } from 'react';
import { Center, Flex, Progress, Text, Image } from '@chakra-ui/react';
import Popup from '@app/components/Popup';
import CheckDoneBlueIcon from '@app/icons/check-done-blue.svg';

interface SkiptraceVerificationModalProps {
  contacts: number;
  progress: number;
  verificationModal: {
    isOpen: boolean;
    onClose: () => void;
  };
}

export const SkiptraceVerificationModal: FC<
  SkiptraceVerificationModalProps
> = ({ contacts, progress, verificationModal }) => {
  return (
    <Popup
      hideCloseIcon
      closeOnOverlayClick={false}
      isOpen={verificationModal.isOpen}
      maxW="496px"
      title="Skiptracing your list"
      titlePadding="20px"
      width="100%"
      onClose={verificationModal.onClose}>
      <hr />
      <Center flexDirection="column" mb="45px">
        <Text py="21px" variant="page-title">
          Processing {contacts} records. This might take a while…
        </Text>

        <Flex alignItems="center" flexDirection="column">
          {progress === 100 ? (
            <Flex alignItems="center" flexDirection="row" mb="6px">
              <Text
                color="primary.600"
                fontSize="12px"
                fontWeight="400"
                mr="6px">
                Done
              </Text>
              <Image alt="check done" src={CheckDoneBlueIcon} />
            </Flex>
          ) : (
            <Flex alignItems="center" flexDirection="row" mb="6px">
              <Text
                color="primary.600"
                fontSize="11px"
                lineHeight="13px"
                mr="6px">
                {Math.round(progress)}%
              </Text>
            </Flex>
          )}

          <Progress
            isAnimated
            borderRadius="4px"
            colorScheme="primary"
            h="4px"
            mb="10px"
            size="xs"
            value={progress}
            width="415px"
          />
        </Flex>
      </Center>
    </Popup>
  );
};
