import { Box } from '@chakra-ui/react';
import React from 'react';

const TabBadge = () => {
  return (
    <Box
      backgroundColor="primary.800"
      borderRadius="full"
      h="4px"
      position="absolute"
      right="-5px"
      top="4px"
      w="4px"
    />
  );
};

export default TabBadge;
