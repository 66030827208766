import { Avatar, Box, Flex, Tabs, Text, useDisclosure } from '@chakra-ui/react';
import React, { FC, lazy, Suspense, useEffect, useState } from 'react';

import throttle from 'lodash/throttle';
import { useLocation, useMatch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useQueryParams } from '@app/hooks/useQueryParams';
import ROUTES from '@app/utils/routes';

import {
  CampaignMessageStatus,
  CrmIntegrationOrderBy,
  CrmIntegrationStatus,
  MacroFragment,
  OrderQuery,
  useConversationUpdatedSubscription,
  useFindCrmIntegrationsQuery,
  useFindScheduledMessagesQuery,
  useGetContactQuery,
} from '@app/api/gql/generated-types';
import { lazyRetry } from '@app/utils/lazyRetry';
import { getContactNames } from '@app/utils/contact';
import { colors } from '@app/theme/colors';
import {
  MacroFloatingModal,
  TMacroFloatingModalPosition,
} from 'components/next/organisms/MacroFloatingModal';
import { ExportToCRM } from '../ExportToCRM';
import { OpenConversationInNewTab } from '../next/moleculas/OpenNewTab';
import { PageTitle } from '../PageTitle';
import Skeleton from '../Skeleton';
import { ToggleContactDNCModal } from '../Modals/ToggleContactDNCModal';
import { ContactFavorite } from './ContactFavorite';
import Tab from './Tab';
import { useTabs } from './Tab/useTabs';
import TabBadge from './Tab/TabBadge';
import { SideBarTab } from './types';

const ContactChat = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ContactChat" */ '@app/pages/Messenger/components/ContactChat'
      ),
  ),
);
const InfoTab = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "InfoTab" */ '@app/pages/Messenger/components/InfoTab'
      ),
  ),
);
const NotesTab = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "NotesTab" */ '@app/pages/Messenger/components/NotesTab'
      ),
  ),
);
const ScheduledTab = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ScheduledTab" */ '@app/pages/Messenger/components/ContactChat/ScheduledTab'
    ).then((module) => ({
      default: module.ScheduledTab,
    })),
  ),
);

interface SideBarContentProps {
  id: string;
  tabs?: SideBarTab[];
  contentBackgroundColor?: string;
  wide?: boolean;
  hideNewTabConversation?: boolean;
}

const SideBarContent: FC<SideBarContentProps> = ({
  id,
  tabs = [
    SideBarTab.MESSAGES,
    SideBarTab.INFO,
    SideBarTab.NOTES,
    SideBarTab.SCHEDULED,
  ],
  contentBackgroundColor = colors.zircon,
  wide = false,
  hideNewTabConversation = false,
}) => {
  const [{ profileId }] = useQueryParams<{
    profileId: string;
  }>();

  const [initialChatMessage, setInitialChatMessage] = useState<string | null>(
    null,
  );

  const match = useMatch(ROUTES.messenger);
  const pathame = useLocation()?.pathname;
  const isFullMessengerActive = pathame?.startsWith('/messenger-full');

  const { data: { getContact: contact } = {}, loading: isLoading } =
    useGetContactQuery({
      variables: {
        id,
      },
      fetchPolicy: 'cache-and-network',
    });

  const { data: { findCrmIntegrations } = {} } = useFindCrmIntegrationsQuery({
    variables: {
      filter: {
        status: CrmIntegrationStatus.ACTIVE,
      },
      pagination: {
        limit: 50,
      },

      order: {
        by: CrmIntegrationOrderBy.TYPE,
        direction: OrderQuery.ASC,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const { fullName } = getContactNames(contact);

  const integrations = findCrmIntegrations?.items ?? [];

  const {
    data,
    loading: scheduledMessagesIsLoading,
    refetch,
  } = useFindScheduledMessagesQuery({
    variables: {
      filter: {
        contactId: contact?.id,
        profileId,
        status: CampaignMessageStatus.ACTIVE,
      },
    },
    skip: !contact?.id,
    fetchPolicy: 'cache-and-network',
  });

  const throttledRefetch = throttle(refetch, 3000);

  useConversationUpdatedSubscription({
    onData: () => {
      void throttledRefetch();
    },
  });

  const scheduledMessages = data?.findScheduledMessages?.items;

  const { setActive, isActive } = useTabs();

  const handleScheduledClick = () => setActive(SideBarTab.SCHEDULED);

  const messagesEnabled = tabs.includes(SideBarTab.MESSAGES);
  const infoEnabled = tabs.includes(SideBarTab.INFO);
  const notesEnabled = tabs.includes(SideBarTab.NOTES);
  const scheduledEnabled = tabs.includes(SideBarTab.SCHEDULED);
  const messagesActive = isActive(SideBarTab.MESSAGES);
  const infoActive = isActive(SideBarTab.INFO);
  const notesActive = isActive(SideBarTab.NOTES);
  const scheduledActive = isActive(SideBarTab.SCHEDULED);

  const isMasterAccount = !!profileId;

  const {
    onClose: onCloseMacroModal,
    isOpen: isOpenMacroModal,
    onToggle: onToggleMacroModal,
  } = useDisclosure();

  const onMacroTriggerClick = () => {
    onToggleMacroModal();
  };

  const onMacroSelect = (macro: MacroFragment) => {
    setInitialChatMessage(macro.content);
    onToggleMacroModal();
  };

  useEffect(() => {
    setInitialChatMessage(null);
  }, [contact?.id]);

  if (isLoading || !contact?.id) {
    return null;
  }

  return (
    <>
      <PageTitle title={fullName || 'Lead'} />
      <Flex flexDirection="column" position="relative">
        {isOpenMacroModal && (
          <MacroFloatingModal
            contactId={contact.id}
            isOpen={isOpenMacroModal}
            position={
              isMobile
                ? TMacroFloatingModalPosition.enum.mobile
                : TMacroFloatingModalPosition.enum.right
            }
            onClose={onCloseMacroModal}
            onMacroSelect={onMacroSelect}
          />
        )}

        {/* Header */}
        <Box
          background="white"
          borderBottom={`1px solid ${colors.mystic}`}
          p="21px 20px 0px 20px"
          width="100%">
          <Box mb="19px">
            <Flex
              alignItems="center"
              direction="row"
              justifyContent="flex-start"
              width="100%">
              <Avatar name={fullName} />
              <Box ml="10px">
                <ContactFavorite conversation={contact} />
              </Box>
              <Box margin="0 10px">
                <Text as="span" size="lg">
                  {fullName}
                </Text>
              </Box>
              {!isMasterAccount && (
                <ToggleContactDNCModal
                  contactId={contact?.id}
                  isBlocked={contact?.blocked}
                />
              )}
              {!!integrations?.length && (
                <ExportToCRM contactId={id} data={integrations} />
              )}
              {!isFullMessengerActive && !hideNewTabConversation && (
                <OpenConversationInNewTab id={id} profileId={profileId} />
              )}
            </Flex>
          </Box>
          <Tabs>
            {messagesEnabled && (
              <Tab
                isActive={messagesActive}
                onClick={() => setActive(SideBarTab.MESSAGES)}>
                Messages
              </Tab>
            )}

            {infoEnabled && (
              <Tab
                isActive={infoActive}
                onClick={() => setActive(SideBarTab.INFO)}>
                Info
              </Tab>
            )}

            {notesEnabled && (
              <Tab
                isActive={notesActive}
                onClick={() => setActive(SideBarTab.NOTES)}>
                Notes
              </Tab>
            )}

            {!!scheduledMessages?.length && scheduledEnabled && (
              <Tab
                isActive={scheduledActive}
                onClick={() => setActive(SideBarTab.SCHEDULED)}>
                <Flex alignItems="flex-start">
                  Scheduled
                  <TabBadge />
                </Flex>
              </Tab>
            )}
          </Tabs>
        </Box>

        {/* Content */}
        {/* 90px for navigation, 110px for the of the sidebar here */}
        {/* The dialer bar takes an additional 60px in combination with the above */}
        <Flex
          backgroundColor={contentBackgroundColor}
          height={wide ? 'calc(100vh - 260px)' : 'calc(100vh - 200px)'}>
          {messagesEnabled && messagesActive && (
            <Suspense fallback={<Skeleton />}>
              <ContactChat
                key={contact.id}
                contactId={contact.id}
                initialChatMessage={initialChatMessage}
                isBlocked={contact?.blocked || contact?.unsubscribed}
                isMacroModalOpen={isOpenMacroModal}
                isMasterAccount={isMasterAccount}
                scheduledMessages={scheduledMessages}
                setInitialChatMessage={setInitialChatMessage}
                wide={wide}
                onMacroButtonClick={onMacroTriggerClick}
                onScheduledClick={handleScheduledClick}
              />
            </Suspense>
          )}

          {infoEnabled && infoActive && (
            <Suspense fallback={<Skeleton />}>
              <InfoTab
                contactId={id}
                isMasterAccount={isMasterAccount}
                wide={wide}
              />
            </Suspense>
          )}

          {notesEnabled && notesActive && (
            <Suspense fallback={<Skeleton />}>
              <NotesTab
                contactId={contact?.id}
                isMasterAccount={isMasterAccount}
                wide={wide}
              />
            </Suspense>
          )}

          {scheduledEnabled && scheduledActive && (
            <Suspense fallback={<Skeleton />}>
              <ScheduledTab
                contactId={contact?.id}
                isLoading={scheduledMessagesIsLoading}
                isMasterAccount={isMasterAccount}
                items={scheduledMessages}
                onClose={() =>
                  setActive(match ? SideBarTab.MESSAGES : SideBarTab.INFO)
                }
              />
            </Suspense>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default SideBarContent;
