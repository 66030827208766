import { UseDisclosureProps } from '@chakra-ui/react';
import { DateTime } from 'luxon';

export enum Mode {
  DATE = 0,
  TIME = 1,
  DATETIME = 2,
}

export interface DateTimePickerProps extends UseDisclosureProps {
  mode: Mode;
  value: DateTime;
  defaultValue?: string;
  onChange: (val: DateTime) => void;
  onPickerClose?: () => void;
  disabledHours?: number[];
  disabledMinutes?: number[];
  disabledTimeFormat?: boolean;
  disabledStartDate?: DateTime;
  disabledEndDate?: DateTime;
  disabled?: boolean;
  isForCampaign?: boolean;
  timeZone?: string;
  title?: string;
}
