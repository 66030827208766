import React, { FC } from 'react';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Flex,
  FlexProps,
  IconButton,
  IconButtonProps,
  MenuItem,
  MenuItemProps,
} from '@chakra-ui/react';
import { useCustomModalPermissionFeatureCheck } from '@app/hooks/useCustomModalPermissionFeatureCheck';
import { PermissionFeatureKeys, PermissionActionsType } from '@app/acl';
import { BodyMonthElementGridItem } from '@app/pages/Calendar/components/CalendarWrapper/event/styles';
import { CustomModalProps } from '../../organisms/CustomModal';

interface WithPermissionProps {
  action: PermissionActionsType;
  subject: PermissionFeatureKeys;
  onSuccess: () => void;
  deniedContent?: CustomModalProps;
  style?: React.CSSProperties;
  leftIcon?: React.ReactElement;
}

const withPermission = <P extends object>(
  ComponentToGivePermission: React.ComponentType<P>,
): FC<P & WithPermissionProps> => {
  const WithPermissionComponent: FC<P & WithPermissionProps> = ({
    action,
    subject,
    onSuccess,
    deniedContent,
    style,
    ...props
  }) => {
    const { checkPermission } = useCustomModalPermissionFeatureCheck();

    const handleClick = () => {
      checkPermission(action, subject, onSuccess, deniedContent);
    };

    return (
      <ComponentToGivePermission
        style={style}
        onClick={handleClick}
        {...(props as P)}
      />
    );
  };

  // Set display name for better debugging
  WithPermissionComponent.displayName = `WithPermission(${ComponentToGivePermission.displayName || ComponentToGivePermission.name || 'Component'})`;

  return WithPermissionComponent;
};

export const PermissionButton = withPermission<ButtonProps>(Button);
export const PermissionFlex = withPermission<FlexProps>(Flex);
export const PermissionMenuItem = withPermission<MenuItemProps>(MenuItem);
export const PermissionIconButton = withPermission<IconButtonProps>(IconButton);
export const PermissionBox = withPermission<BoxProps>(Box);
export const PermissionBodyMonthElementGridCalendarItem = withPermission(
  BodyMonthElementGridItem,
);
