import React, { FC } from 'react';
import { useMatch } from 'react-router-dom';

import SettingsAttentionActiveIcon from '@app/icons/navigation/settings-attention-active-icon.svg?react';
import SettingsAttentionIcon from '@app/icons/navigation/settings-attention-icon.svg?react';
import SettingsActiveIcon from '@app/icons/navigation/settings-active-icon.svg?react';
import SettingsIcon from '@app/icons/navigation/settings-icon.svg?react';
import { useAccountAttention } from '@app/hooks/useAccountAttention';

export const SettingsSection: FC = () => {
  const match = useMatch({
    path: '/settings',
  });

  const userHasAttention = useAccountAttention();

  if (match) {
    return userHasAttention ? (
      <SettingsAttentionActiveIcon />
    ) : (
      <SettingsActiveIcon />
    );
  }

  return userHasAttention ? <SettingsAttentionIcon /> : <SettingsIcon />;
};
