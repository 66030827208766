import React, { FC } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

interface ExportButtonProps extends ButtonProps {
  name: string;
  onClick: () => void;
}

const ExportButton: FC<ExportButtonProps> = ({ name, onClick, ...props }) => {
  return (
    <Button
      _hover={{
        color: 'primary.600',
      }}
      color="main.400"
      mt="6px"
      overflow="hidden"
      textOverflow="ellipsis"
      variant="text"
      onClick={onClick}
      {...props}>
      {name}
    </Button>
  );
};

export default ExportButton;
