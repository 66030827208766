import React, { FC } from 'react';
import { Flex, SimpleGrid } from '@chakra-ui/react';
import { Formik } from 'formik';
import { ApolloError } from '@apollo/client';
import { ExceptionCode } from '@app/types/ApolloErrors';
import { CreateContactParams } from '@app/api/queries/types';
import { FormItemInput } from '@app/components/FormItem/FormItemInput';
import FormItemPhoneInput from '@app/components/FormItem/FormItemPhoneInput';
import { preparingForSendingPhoneNumber } from '@app/components/PhoneNumberInput';
import Popup from '@app/components/Popup';
import { catchErrorLog } from '@app/utils/logger';
import { normalizeNameString } from '@app/utils/string';
import {
  FindContactsDocument,
  GetContactsCountersDocument,
  useCreateContactMutation,
} from '@app/api/gql/generated-types';
import { addNewContactValidation } from '@app/pages/Contacts/utils/addNewContactValidation';

import { getGraphQLErrorCode } from '@app/utils/getGraphQLErrorCode';
import { SubmitForm } from './SubmitForm';

interface AddNewContactProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  maxW: string;
}

export const AddNewContact: FC<AddNewContactProps> = ({
  isOpen,
  onClose,
  title,
  maxW,
}) => {
  const [mutate, { loading: isLoading }] = useCreateContactMutation({
    refetchQueries: [FindContactsDocument, GetContactsCountersDocument],
    context: {
      notify: {
        success: () => 'Successfully created new contact',
        error: (error: ApolloError['graphQLErrors']) => {
          if (
            error?.[0]?.extensions?.code ===
            ExceptionCode.GRAPHQL_VALIDATION_FAILED
          ) {
            return 'Invalid phone number';
          }
          return error?.[0]?.message;
        },
      },
    },
  });

  const onSubmit = async (values: CreateContactParams) => {
    try {
      await mutate({
        variables: {
          input: {
            phone: preparingForSendingPhoneNumber(values.phone),
            firstName: values.firstName,
            lastName: values.lastName,
          },
        },
      });
      onClose();
    } catch (err) {
      const errContext = 'AddNewContact/handleSubmit';
      if (err instanceof ApolloError) {
        const errCode = getGraphQLErrorCode(err) as string as ExceptionCode;
        const isExpectedCode = [
          ExceptionCode.GRAPHQL_VALIDATION_FAILED,
          ExceptionCode.CONFLICT,
        ].includes(errCode);

        if (!isExpectedCode) {
          catchErrorLog(err, errContext);
        }
      } else {
        catchErrorLog(err, errContext);
      }
    }
  };
  const initialValues: CreateContactParams = {
    firstName: '',
    lastName: '',
    phone: '',
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={addNewContactValidation}
      onSubmit={onSubmit}>
      {({ resetForm, handleChange }) => (
        <Popup
          closeOnOverlayClick
          hideCloseIcon={false}
          isOpen={isOpen}
          maxW={maxW}
          title={title}
          onClose={() => {
            resetForm();
            onClose();
          }}>
          <SimpleGrid columnGap="15px" columns={2} p="35px 40px 0 40px">
            <FormItemInput
              name="firstName"
              placeholder="First name"
              onChange={(e) => {
                e.target.value = normalizeNameString(e.target.value);
                handleChange(e);
              }}
            />
            <FormItemInput
              name="lastName"
              placeholder="Last name"
              onChange={(e) => {
                e.target.value = normalizeNameString(e.target.value);
                handleChange(e);
              }}
            />
          </SimpleGrid>

          <Flex alignItems="center" direction="column" p="20px 40px 0 40px">
            <FormItemPhoneInput name="phone" />
            <SubmitForm isLoading={isLoading} />
          </Flex>
        </Popup>
      )}
    </Formik>
  );
};
