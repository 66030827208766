import { Box, Skeleton } from '@chakra-ui/react';
import React from 'react';

interface IMacroModalSkeletonLoaderProps {
  rowsCount?: number;
}

export const MacroModalSkeletonLoader: React.FC<
  IMacroModalSkeletonLoaderProps
> = ({ rowsCount = 20 }) => {
  return (
    <Box w="100%">
      {Array(rowsCount)
        .fill(0)
        .map((_, i) => (
          <Box key={i}>
            <Skeleton
              endColor="cultured"
              height="21px"
              startColor="mystic"
              width="130px"
            />
            <Skeleton
              endColor="cultured"
              height="42px"
              mb="10px"
              mt="5px"
              startColor="mystic"
              width="100%"
            />
          </Box>
        ))}
    </Box>
  );
};
