/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { Skeleton, Td, Tfoot, Tr } from '@chakra-ui/react';
import { colors } from '@app/theme/colors';

interface SkeletonRowProps {
  isFetching: boolean;
  columns: number;
  rows: number;
}

const SkeletonRow: FC<SkeletonRowProps> = (props) => {
  const { isFetching, columns, rows } = props;
  return (
    <Tfoot backgroundColor="white">
      {Array(rows)
        .fill(0)
        .map((elRow, keyRow) => (
          <Tr
            key={keyRow.toString()}
            borderBottom={`1px solid ${colors.mystic}`}>
            {Array(columns)
              .fill(0)
              .map((elCol, keyCol) => (
                <Td key={keyCol.toString()}>
                  <Skeleton
                    endColor={colors.cultured}
                    height="15px"
                    isLoaded={!isFetching}
                    startColor={colors.mystic}
                  />
                </Td>
              ))}
          </Tr>
        ))}
    </Tfoot>
  );
};

export default SkeletonRow;
