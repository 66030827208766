import React, { FC, PropsWithChildren } from 'react';
import {
  AuthProvider,
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
  AnalyticsProvider,
  AppCheckProvider,
} from 'reactfire';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { FirebaseOptions, initializeApp } from 'firebase/app';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import {
  connectFirestoreEmulator,
  initializeFirestore,
} from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

import ROUTES from '@app/utils/routes';
import { isDevelopment } from '@app/utils/envUtils';

declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

const firebaseAppName = window.location.pathname.includes(
  `${ROUTES.impersonate}`,
)
  ? 'impersonate'
  : undefined;

const firebaseConfig = JSON.parse(
  import.meta.env.VITE_FIREBASE_CONFIG,
) as FirebaseOptions;

export const firebaseProjectId = firebaseConfig.projectId;

// Disable Firebase App Check for localhost (https://firebase.google.com/docs/app-check/web/debug-provider)
if (isDevelopment) {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN =
    import.meta.env.VITE_FIREBASE_APP_CHECK_DEBUG_TOKEN;
}

const firebaseApp = initializeApp(firebaseConfig, firebaseAppName);

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(
    import.meta.env.VITE_FIREBASE_APP_CHECK_RECAPTCHA,
  ),
  isTokenAutoRefreshEnabled: true,
});

const authInstance = getAuth(firebaseApp);
const storageInstance = getStorage(firebaseApp);

const firestoreInstance = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true,
});

const functionsInstance = getFunctions(firebaseApp);
const analyticsInstance = getAnalytics(firebaseApp);

const emulatorEnabled = import.meta.env.MODE === 'test';

if (emulatorEnabled) {
  connectAuthEmulator(authInstance, 'http://localhost:9099');
  connectFunctionsEmulator(functionsInstance, 'localhost', 5001);
  connectFirestoreEmulator(firestoreInstance, 'localhost', 8080);
  connectStorageEmulator(storageInstance, 'localhost', 9199);
}

const FirebaseProvider: FC<PropsWithChildren> = ({ children }) => (
  <FirebaseAppProvider firebaseApp={firebaseApp}>
    <AppCheckProvider sdk={appCheck}>
      <AuthProvider sdk={authInstance}>
        <FirestoreProvider sdk={firestoreInstance}>
          <StorageProvider sdk={storageInstance}>
            <FunctionsProvider sdk={functionsInstance}>
              <AnalyticsProvider sdk={analyticsInstance}>
                {children}
              </AnalyticsProvider>
            </FunctionsProvider>
          </StorageProvider>
        </FirestoreProvider>
      </AuthProvider>
    </AppCheckProvider>
  </FirebaseAppProvider>
);

export default FirebaseProvider;
