import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { FC, useMemo } from 'react';

import { isSubmitDisabled } from '@app/utils/validation';

import { Scope } from '@app/api/gql/generated-types';
import { useMaxAllowedPhones } from './useMaxAllowedPhones';
import { BuyNumberForm } from './types';

interface SubmitButtonProps {
  scope: Scope;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  isLoading,
  isDisabled,
  scope,
}) => {
  const formik = useFormikContext<BuyNumberForm>();
  const { byType } = useMaxAllowedPhones(scope);
  const {
    handleSubmit,
    isSubmitting,
    values: { type },
  } = formik;
  const disabled = useMemo(() => byType(type), [byType, type]);

  const isDisableSend = isSubmitDisabled(formik, false, [
    isLoading,
    isSubmitting,
  ]);

  return (
    <Button
      isDisabled={isDisableSend || isDisabled || disabled}
      isLoading={isLoading}
      minW="84px"
      variant="primary"
      onClick={() => handleSubmit()}>
      Search
    </Button>
  );
};
