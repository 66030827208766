export const stripeElementsFontName = 'Roboto';

export const stripeElementsFont = [
  {
    family: stripeElementsFontName,
    weight: '400',
    src: 'url("https://cdn-dev-02-ab51.web.app/roboto-v30-latin-regular.woff2") format("woff2"), url("https://cdn-dev-02-ab51.web.app/roboto-v30-latin-regular.woff") format("woff")',
  },
  {
    family: stripeElementsFontName,
    weight: '600',
    src: 'url("https://cdn-dev-02-ab51.web.app/roboto-v30-latin-500.woff2") format("woff2"), url("https://cdn-dev-02-ab51.web.app/roboto-v30-latin-500.woff") format("woff")',
  },
];

export const stripeElementsOptions = {
  fonts: stripeElementsFont,
  appearance: {
    variables: {
      fontFamily: stripeElementsFontName,
      fontDisplay: 'swap',
    },
  },
};
