import React, { FC, useState, useEffect } from 'react';
import {
  Center,
  Stack,
  Radio,
  Button,
  Box,
  RadioGroup,
  Text,
  Flex,
} from '@chakra-ui/react';

import Popup from '@app/components/Popup';
import { ExportSkiptraceType, Skiptrace } from '@app/api/gql/generated-types';
import { TRadioVariant } from '@app/theme/components/radio';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  confirm: (exportOptions: ExportSkiptraceType) => Promise<void>;
  title: string;
  isLoading: boolean;
  isDownloadExport?: boolean;
  hideAllOption?: boolean;
  skiptraceData: Skiptrace;
  disableCancel?: boolean;
}

const ExportModal: FC<ExportModalProps> = ({
  isOpen,
  onClose,
  confirm,
  title,
  isLoading,
  isDownloadExport,
  skiptraceData,
  hideAllOption,
  disableCancel = false,
}) => {
  const [exportOptions, setExportOptions] = useState<ExportSkiptraceType>(
    ExportSkiptraceType.ALL,
  );

  useEffect(() => {
    if (isOpen) {
      setExportOptions(ExportSkiptraceType.MOBILE_ONLY);
    }
  }, [isOpen]);

  const allNumbersCount =
    skiptraceData?.mobileContacts + skiptraceData?.landlineContacts;

  const width = isDownloadExport ? '287px' : '276px';
  return (
    <Popup isOpen={isOpen} title={title} width={width} onClose={onClose}>
      <Box margin="25px 40px 40px 40px">
        <RadioGroup
          display="flex"
          justifyContent="center"
          value={exportOptions}
          onChange={(nextValue: ExportSkiptraceType) =>
            setExportOptions(nextValue)
          }>
          <Stack
            alignItems="flex-start"
            direction="column"
            display="inline-flex"
            fontSize="14px"
            gap="19px"
            width="auto">
            <Radio
              height="16px"
              value={ExportSkiptraceType.MOBILE_ONLY}
              variant={TRadioVariant.EXPORT_RADIO}
              width="auto">
              <Flex marginTop="1px">
                <Text color="main.400" fontSize="14px" pr="2px">
                  Mobile phone numbers{' '}
                </Text>
                <Text color="secondary.400" fontSize="14px">
                  ({skiptraceData?.mobileContacts})
                </Text>
              </Flex>
            </Radio>
            {isDownloadExport && (
              <Radio
                height="16px"
                value={ExportSkiptraceType.LANDLINE_ONLY}
                variant={TRadioVariant.EXPORT_RADIO}
                width="auto">
                <Flex>
                  <Text
                    color="main.400"
                    fontSize="14px"
                    marginTop="1px"
                    pr="2px">
                    Landline phone numbers{' '}
                  </Text>
                  <Text color="secondary.400" fontSize="14px">
                    ({skiptraceData?.landlineContacts})
                  </Text>
                </Flex>
              </Radio>
            )}
            {!hideAllOption && (
              <Radio
                height="16px"
                value={ExportSkiptraceType.ALL}
                variant={TRadioVariant.EXPORT_RADIO}
                width="auto">
                <Flex marginTop="1px">
                  <Text color="main.400" fontSize="14px" pr="2px">
                    All phone numbers{' '}
                  </Text>
                  <Text color="secondary.400" fontSize="14px">
                    ({allNumbersCount})
                  </Text>
                </Flex>
              </Radio>
            )}
          </Stack>
        </RadioGroup>
        <Center mt="25px">
          <Button
            height="40px"
            isLoading={isLoading}
            variant="primary"
            width="127px"
            onClick={() => confirm(exportOptions)}>
            Confirm
          </Button>
        </Center>
        {!disableCancel && (
          <Center>
            <Button variant="cancel" width="50px" onClick={onClose}>
              Cancel
            </Button>{' '}
          </Center>
        )}
      </Box>
    </Popup>
  );
};

export default ExportModal;
