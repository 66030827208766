import React, { FC } from 'react';
import { DateTime as DT } from 'luxon';

import { BodyWrapStyle, BodyWrapItemStyle } from '../styles';
import { MONTH, getDaysInMonthMode } from '../utils/utils';
import { useDisabledDates } from '../../hooks/useDisabledDates';

import { BodyMonthElement } from './BodyMonthElement';

interface BodyWrapTemplateTypes {
  mode: string;
  cursorDate: DT;
  onSelect: (value: DT) => void;
  selectedDate: DT;
  disabledStartDate?: DT;
  disabledEndDate?: DT;
}

const BodyWrap: FC<BodyWrapTemplateTypes> = ({
  mode,
  cursorDate,
  onSelect,
  selectedDate,
  disabledStartDate,
  disabledEndDate,
}) => {
  const isToday = (day: DT) =>
    day.toFormat('yyyyMMdd') === DT.fromJSDate(new Date()).toFormat('yyyyMMdd');

  const { isDisabledStartDate, isDisabledEndDate } = useDisabledDates();

  return (
    <BodyWrapStyle>
      <BodyWrapItemStyle>
        {mode === MONTH &&
          getDaysInMonthMode(cursorDate).map((day: DT) => (
            <BodyMonthElement
              key={day.toLocaleString()}
              isDisabledEndDate={isDisabledEndDate(day, disabledEndDate)}
              isDisabledStartDate={isDisabledStartDate(day, disabledStartDate)}
              isOffRange={day.month !== cursorDate.month}
              isSelectedDate={
                day &&
                day.toFormat('yyyyMMdd') === selectedDate.toFormat('yyyyMMdd')
              }
              isToday={isToday(day)}
              onSelect={() => {
                onSelect(day);
                if (onSelect) onSelect(day);
              }}>
              {day.toFormat('d')}
            </BodyMonthElement>
          ))}
      </BodyWrapItemStyle>
    </BodyWrapStyle>
  );
};

export default BodyWrap;
