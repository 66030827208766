import { Box, Center, Flex, Text, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, MouseEvent, useCallback, useState } from 'react';

import SortBy from '@app/components/Filter/SortBy';
import {
  groupSort,
  sortDefaultForRequest,
} from '@app/components/Filter/SortBy/interface';
import { useSortState } from '@app/components/Filter/SortBy/useSortState';
import IconButtonRemove from '@app/components/IconButtonRemove';
import LoadMoreButton from '@app/components/LoadMoreButton';
import PageSkeleton from '@app/components/PageSkeleton';
import { useFindSkiptracesQuery } from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import { createPaginationVariables } from '@app/api/apollo-pagination';
import EmptyTable from '@app/components/EmptyTable';
import { RemoveContactListBatchModal } from '../components/Modal/RemoveContactListBatch';
import { TableList } from '../components/TableList/TableList';

const SkiptraceList: FC = () => {
  const batchDeletionModal = useDisclosure();
  const [sort, setSort] = useSortState();
  const [selectedLists, setSelectedLists] = useState<string[]>([]);

  const params = {
    order: {
      direction: sortDefaultForRequest[sort],
    },
  };

  const {
    data: { findSkiptraces: skiptraceData } = {},
    networkStatus,
    fetchMore,
  } = useFindSkiptracesQuery({
    variables: params,
    notifyOnNetworkStatusChange: true,
  });

  const { isLoading, isLoadingData, isFetchingNextPage } =
    useApolloLoadingStatus(networkStatus);

  const fetchNextPage = () => {
    void fetchMore(createPaginationVariables(skiptraceData));
  };

  const onCheckElement = useCallback(
    (id: string, event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (selectedLists.includes(id)) {
        const arr = selectedLists.filter(
          (contactListId) => contactListId !== id,
        );
        setSelectedLists([...arr]);
        return;
      }
      setSelectedLists([...selectedLists, id]);
    },
    [selectedLists, setSelectedLists],
  );

  return (
    <Flex direction="column" overflow="hidden" pt="19px" width="100%">
      <Text mb="20px" p="14px 30px 0" variant="sub-heading">
        Skiptrace all files
      </Text>
      <Box h="26px" mb="20px">
        {!isEmpty(selectedLists) ? (
          <IconButtonRemove
            ml="24px"
            size="small"
            onClick={batchDeletionModal.onOpen}
          />
        ) : (
          <Flex ml="14px" position="relative">
            <SortBy
              isDisabled={isEmpty(skiptraceData?.items)}
              labels={groupSort}
              value={sort}
              onChange={setSort}
            />
          </Flex>
        )}
      </Box>

      <Flex h="100%" width="100%">
        {isLoading ? (
          <PageSkeleton showControls={false} />
        ) : (
          <Box w="100%">
            <Flex
              direction="column"
              height="calc(100vh - 190px)"
              overflowY="auto">
              <TableList
                contactListData={skiptraceData?.items}
                selectedLists={selectedLists}
                onCheckboxChange={onCheckElement}
                onSelectedLists={setSelectedLists}
              />
              {!isEmpty(skiptraceData?.items) && (
                <Center mb="10px">
                  <LoadMoreButton
                    fetchNextPage={fetchNextPage}
                    hasNextPage={skiptraceData?.hasNext}
                    isFetchingNextPage={isFetchingNextPage}
                    isLoading={isLoadingData}
                  />
                </Center>
              )}
              {isEmpty(skiptraceData?.items) && (
                <Flex h="100%" position="relative" top="0">
                  <EmptyTable description="There is no data to show you right now" />
                </Flex>
              )}
            </Flex>
          </Box>
        )}
      </Flex>
      <RemoveContactListBatchModal
        ids={selectedLists}
        isOpen={batchDeletionModal.isOpen}
        onClose={batchDeletionModal.onClose}
        onSelectedLists={setSelectedLists}
      />
    </Flex>
  );
};
export default SkiptraceList;
