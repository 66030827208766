import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/default
import Select, { OptionProps, SingleValueProps } from 'react-select';
import { TTextVariant } from '@app/theme/components/text';

import Popover from '@app/components/Popover';
import { colors } from '@app/theme/colors';

interface Option {
  value: boolean;
  label: string;
}

const options: Option[] = [
  { value: true, label: 'Online' },
  { value: false, label: 'Offline' },
];

const Dot = ({ active }: { active: boolean }) => (
  <Box
    backgroundColor={active ? 'mediumAquamarine' : 'error'}
    borderRadius="full"
    height="10px"
    width="10px"
  />
);

// Custom styles for react-select
const customStyles = {
  menu: (provided: { [key: string]: any }) => ({
    ...provided,
    background: 'white',
    borderRadius: '10px',
    boxShadow: ' 0px 6px 18px 0px rgba(139, 139, 139, 0.25)',
    padding: '10px',
    color: colors.secondary[600],
    width: '100%',
    marginLeft: '-1.5%',
    top: '85%',
  }),
  menuList: (provided: { [key: string]: any }) => ({
    ...provided,
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: '0',
  }),
  control: (
    provided: { [key: string]: any },
    state: { [key: string]: any },
  ) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'borderColor' : 'secondary.200',
    borderRadius: '20px',
    paddingLeft: '12px',
    paddingRight: '7px',
    paddingTop: '4px',
    paddingBottom: '4px',
    minHeight: '0px',
    height: '24px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justify: 'center',
    alignItems: 'center',
    width: '99px',
    color: colors.secondary[600],
    ':hover': {
      cursor: 'pointer',
    },
  }),
  valueContainer: (provided: { [key: string]: any }) => ({
    ...provided,
    padding: '0px',
    // So that elements are aligned horizontally, so that the ghost Input component
    // doesn't break alignment of SingleValue component
    display: 'flex',
    flexWrap: 'nowrap' as const,
  }),
  indicatorsContainer: (
    provided: { [key: string]: any },
    state: { selectProps: { menuIsOpen: boolean } },
  ) => {
    return {
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transform: state.selectProps.menuIsOpen
        ? 'rotate(180deg)'
        : 'rotate(0deg)',
    };
  },
  dropdownIndicator: (provided: { [key: string]: any }) => ({
    ...provided,
    padding: '0px',
    width: '16px',
    color: colors.secondary[600],
  }),
  indicatorSeparator: (provided: { [key: string]: any }) => ({
    ...provided,
    display: 'none',
  }),
};

const SingleValueComponent = ({
  innerProps,
  data,
}: SingleValueProps<Option>) => (
  <Flex {...innerProps} alignItems="center" gap="6px">
    <Dot active={data.value} />
    <Box>
      <Text variant={TTextVariant.enum.dropdownOption}>{data.label}</Text>
    </Box>
  </Flex>
);

const OptionComponent = ({ innerProps, data }: OptionProps<Option>) => {
  const isLast = options[1].label === data.label;
  return (
    <Flex
      {...innerProps}
      _hover={{
        backgroundColor: 'primary.100',
        borderRadius: '7px',
        cursor: 'pointer',
        color: 'primary.600',
      }}
      alignItems="center"
      justifyContent="start"
      marginTop={isLast ? '4px' : '0px'}
      paddingBottom="7px"
      paddingLeft="10px"
      paddingRight="17px"
      paddingTop="7px"
      sx={{
        '&:hover p': {
          color: 'primary.600',
        },
      }}>
      <Box mr="6px">
        <Dot active={data.value} />
      </Box>
      <Box>
        <Text variant={TTextVariant.enum.dropdownOption}>{data.label}</Text>
      </Box>
    </Flex>
  );
};

export const DialerStatusDropdown = ({
  isConnectedToProvider = false,
  isDisabled = false,
  isOnline,
  onChange,
}: {
  isConnectedToProvider: boolean;
  isDisabled?: boolean;
  isOnline: boolean;
  onChange: (isOnline: boolean) => void;
}) => (
  <Popover
    borderRadius="8px"
    offset={[0, 10]}
    placement="bottom"
    popoverText={
      !isConnectedToProvider && (
        <Text fontSize="14px" fontWeight="400">
          The agent presence status controls dialing behavior. During service
          initialization this selector is disabled.
        </Text>
      )
    }
    trigger="hover"
    triggerElement={
      <Box cursor={!isConnectedToProvider ? 'not-allowed' : 'default'}>
        <Select
          components={{
            Option: OptionComponent,
            SingleValue: SingleValueComponent,
          }}
          isClearable={false}
          isDisabled={!isConnectedToProvider || isDisabled}
          isMulti={false}
          isSearchable={false}
          options={options}
          styles={{
            ...customStyles,
            control: (...args) => ({
              ...customStyles.control(...args),
              backgroundColor: !isConnectedToProvider
                ? colors.secondary[200]
                : isOnline
                  ? colors.white
                  : colors.zircon,
              borderColor: colors.secondary[200],
            }),
          }}
          value={options.find((a) => a.value === isOnline)}
          onChange={({ value }) => onChange(value)}
        />
      </Box>
    }
    width="auto"
    widthContainer="254px"
  />
);
