import {
  Box,
  Flex,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useState } from 'react';
import { Link as RouteLink, useNavigate } from 'react-router-dom';

import { ConfirmDeletionModal } from '@app/components/ConfirmDeletionModal';
import EmptyTable from '@app/components/EmptyTable';
import IconButtonRemove from '@app/components/IconButtonRemove';
import DateCell from '@app/components/Table/Cells/DateCell';
import SkeletonRow from '@app/components/Table/Rows/SkeletonRow';
import PhoneNumberFormat from '@app/shared/ui/PhoneNumberFormat';
import ROUTES, { SUPPORT_LINK } from '@app/utils/routes';

import {
  FindOwnPhonesDocument,
  GetOwnPhoneCountersDocument,
  OwnPhone,
  OwnPhoneReportFragment,
  Scope,
  useRemoveOwnPhoneMutation,
} from '@app/api/gql/generated-types';

import DeleteErrorModal from '@app/pages/Settings/content/MessagingSetup/components/DeleteErrorModal';
import { TooltipOwnedNumbers } from './TooltipOwnedNumbers';

interface OwnedNumbersTableProps {
  isFetching: boolean;
  data: OwnPhoneReportFragment[];
}

const getPhoneStatus = (
  phoneScope: OwnPhone['scope'],
  callingEnabled: OwnPhone['callingEnabled'],
  phoneStatus: OwnPhone['status'],
) => {
  if (phoneScope === Scope.DIALER) {
    if (callingEnabled === true) {
      return 'Active';
    }
    return 'Blocked';
  }
  return phoneStatus;
};
export const OwnedNumbersTable: FC<OwnedNumbersTableProps> = ({
  isFetching,
  data,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const errorModal = useDisclosure();
  const navigate = useNavigate();

  const [remove, { loading: isLoading, error: mutateError }] =
    useRemoveOwnPhoneMutation({
      refetchQueries: [FindOwnPhonesDocument, GetOwnPhoneCountersDocument],
    });

  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>();

  const handlePhoneDelete = (phoneId: string) => {
    setSelectedPhoneNumber(phoneId);
    onOpen();
  };

  const resetSelected = () => {
    setSelectedPhoneNumber(undefined);
  };

  const handleDeletePhones = async () => {
    try {
      await remove({
        variables: {
          id: selectedPhoneNumber,
        },
      });

      onClose();
      resetSelected();
    } catch {
      onClose();
      errorModal.onOpen();
    }
  };

  return (
    <>
      <Box
        border="1px solid"
        borderColor="mystic"
        borderRadius="10px"
        overflow="hidden"
        w="900px">
        <Table size="sm" variant="phones">
          <Thead>
            <Tr h="43px">
              <Th>Phone number</Th>
              <Th>Type</Th>
              <Th>
                <Flex alignItems="center">
                  Status <TooltipOwnedNumbers />
                </Flex>
              </Th>
              <Th>Added</Th>
              <Th>Outbound</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody w="100%">
            {data?.map((phone) => {
              return (
                <Tr key={phone.id}>
                  <Td>
                    <PhoneNumberFormat value={phone?.number} />
                  </Td>
                  <Td>
                    <Text color="secondary.400">
                      {'Dialer'}{' '}
                      {phone?.location?.abbreviation && (
                        <Text as="span">{phone?.location?.abbreviation}</Text>
                      )}
                    </Text>
                  </Td>
                  <Td>
                    {getPhoneStatus(
                      phone.scope,
                      phone.callingEnabled,
                      phone.status,
                    )}
                  </Td>
                  <DateCell date={new Date(phone.createdAt)} />
                  <Td>{phone?.reportDialer?.outboundCalls}</Td>
                  <Td>
                    <IconButtonRemove
                      onClick={() => handlePhoneDelete(phone.id)}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
          {isFetching && (
            <SkeletonRow columns={6} isFetching={isFetching} rows={3} />
          )}
        </Table>
        {!isFetching && isEmpty(data) && (
          <EmptyTable
            description={
              <Text fontSize="14px" mb="20px">
                Not sure how to buy a number? <br /> Visit our{' '}
                <Link
                  as={RouteLink}
                  target="_blank"
                  to={SUPPORT_LINK}
                  variant="inline">
                  Help Center
                </Link>
              </Text>
            }
            title={
              <Text color="secondary.400" fontSize="14px">
                You have no active phone numbers
              </Text>
            }
          />
        )}
      </Box>
      <ConfirmDeletionModal
        isLoading={isLoading || isFetching}
        isOpen={isOpen}
        title="Delete phone number"
        width={382}
        onClose={onClose}
        onConfirm={handleDeletePhones}>
        Are you sure you want to delete this number? You will no longer be able
        to use it to make calls.
      </ConfirmDeletionModal>
      <DeleteErrorModal
        error={mutateError}
        isOpen={errorModal.isOpen}
        phone={data.find((phone) => phone?.id === selectedPhoneNumber)}
        onClose={() => {
          navigate(ROUTES.dialerAll);
          errorModal.onClose();
          resetSelected();
        }}
      />
    </>
  );
};
