import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { useDisclosure } from '@chakra-ui/react';
import { ApolloClient, OnDataOptions, useApolloClient } from '@apollo/client';
import { ScrollParams } from 'react-virtualized';
import { useQueryParams } from '@app/hooks/useQueryParams';
import { DateType } from '@app/widgets/DatePicker';
import { prepareDatesForRequest } from '@app/utils/prepareDatesForRequest';
import {
  Conversation,
  ConversationCategory,
  ConversationFilter,
  ConversationFragment,
  ConversationFragmentDoc,
  FindConversationsQueryVariables,
  IncomingMessageReceivedSubscription,
  MessageType,
  useFindConversationsQuery,
  useGetConversationLazyQuery,
  useIncomingMessageReceivedSubscription,
} from '@app/api/gql/generated-types';
import {
  SortLabel,
  sortMessagesForRequest,
} from '@app/components/Filter/SortBy/interface';
import { useContacts } from '@app/pages/Contacts/hooks/useContacts';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import { MessengerFolderType } from '@app/pages/Messenger/types';
import { createPaginationVariables } from '@app/api/apollo-pagination';
import { EntityType } from '@app/pages/Messenger/components/BreadCrumbs';
import { getLogger } from '@app/utils/logger';
import {
  isMissedCall,
  isNewMessageDelivered,
} from '@app/utils/incomingMessageUtils';
import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';
import * as typenames from '@app/api/typenames';
import { TScrollToAlignment } from '@app/types/Virtualize';
import {
  DEFAULT_PAGE_SIZE,
  MIN_LENGTH_INPUT_FULL_SEARCH,
} from '@app/constants/configuration';

interface MessengerContextType {
  memoizedValue: {
    path: string;
    title?: EntityType | string;
    type?: MessengerFolderType;
    queryParams?: ConversationFilter;
    profileId: string;
    isLoading: boolean;
    isFetchingNextPage: boolean;
    isReady: boolean;
    isEmptyFilters: boolean;
    record: ConversationFragment[];
    recordsCount: number;
    urlSearchParams: string;
    onListScroll: (params: ScrollParams) => void;
    listScrollToAlignment: typeof TScrollToAlignment.type;
    reloadMessagesHandlerVisible: boolean;
    date: DateType;
    labels: string[];
    params: FindConversationsQueryVariables;
    sort: SortLabel;
    hasNext: boolean;
    handleFullReloadConversations: () => Promise<void>;
    categoryUnread: boolean;
    selectedContact: string[];
    setSort: React.Dispatch<React.SetStateAction<SortLabel>>;
    setSelectedContact: React.Dispatch<React.SetStateAction<string[]>>;
    handleReloadConversations: () => void;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>;
    setLabels: React.Dispatch<React.SetStateAction<string[]>>;
    setDate: React.Dispatch<React.SetStateAction<DateType>>;
    fetchNextPage: () => void;
  };
}

const updateConversationWithIncomingCall = (
  client: ApolloClient<object>,
  incomingMessage: IncomingMessageReceivedSubscription['incomingMessageReceived'],
) => {
  client.cache.updateFragment<Conversation>(
    {
      id: `${typenames.Conversation}:${incomingMessage?.conversationId}`,
      fragment: ConversationFragmentDoc,
      fragmentName: typenames.Conversation,
    },
    (conversationsData) => ({
      ...conversationsData,
      lastMessageAt: new Date().toString(),
      updatedAt: new Date().toString(),
      lastMessage: {
        ...conversationsData?.lastMessage,
        id: incomingMessage.id,
        type: MessageType.CALL,
        contentType: incomingMessage.contentType,
      },
    }),
  );
};

const MessengerContext = createContext<MessengerContextType | undefined>(
  undefined,
);

const LOG = getLogger('InboxMessages');

export const MessengerContextProvider: React.FC<{
  children: React.ReactNode;
  path: string;
  title?: EntityType | string;
  type?: MessengerFolderType;
  queryParams?: ConversationFilter;
}> = ({ children, queryParams, path, title, type }) => {
  const apolloClient = useApolloClient();
  const [{ profileId }, urlSearchParams] = useQueryParams();
  const { currentAccount } = useContext(CurrentAccountContext);
  const {
    isOpen: reloadMessagesHandlerVisible,
    onOpen: showReloadMessagesHandler,
    onClose: hideReloadMessagesHandler,
  } = useDisclosure();

  const [date, setDate] = useState<DateType>();
  const [listScrollTop, setListScrollTop] = useState(0);
  const [listScrollToAlignment, setListScrollToAlignment] = useState<
    typeof TScrollToAlignment.type
  >(TScrollToAlignment.enum.auto);
  const [labels, setLabels] = useState<string[]>([]);

  const {
    sort,
    setSort,
    selectedContact,
    setSelectedContact,
    searchValue,
    setSearchValue,
  } = useContacts();

  const isEmptyFilters = !isEmpty(labels) || !!date?.start || !!profileId;
  const categoryUnread = queryParams?.category === ConversationCategory.UNREAD;

  const prepareDates = useMemo(
    () => (date?.start ? prepareDatesForRequest(date) : null),
    [date],
  );

  const onListScroll = (params: ScrollParams) => {
    setListScrollTop(params.scrollTop || 0);
  };

  const params: FindConversationsQueryVariables = {
    filter: {
      ...(labels.length && { labels }),
      profileId,
      startAt: prepareDates?.from,
      endAt: prepareDates?.to,
      ...(searchValue?.length >= MIN_LENGTH_INPUT_FULL_SEARCH && {
        fullSearch: searchValue,
      }),
      ...queryParams,
    },
    order: {
      ...sortMessagesForRequest[sort],
    },
  };

  const {
    data: { findConversations } = {},
    fetchMore,
    refetch,
    networkStatus,
  } = useFindConversationsQuery({
    variables: params,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [fetchConversation] = useGetConversationLazyQuery({
    fetchPolicy: 'network-only',
  });

  const { isLoading, isLoadingData, isFetchingNextPage, isReady } =
    useApolloLoadingStatus(networkStatus);

  const handleReloadConversations = async () => {
    hideReloadMessagesHandler();
    setListScrollToAlignment(TScrollToAlignment.enum.start);
    const refetchResult = await refetch();

    setListScrollToAlignment(TScrollToAlignment.enum.auto);

    LOG.debug('handleReloadConversations refetch', refetchResult);
  };

  const handleFullReloadConversations = async () => {
    const result = apolloClient.cache.evict({
      fieldName: 'findConversations',
    });

    LOG.debug('handleFullReloadConversations cache evict', result);

    await handleReloadConversations();
  };

  useEffect(() => {
    if (reloadMessagesHandlerVisible && listScrollTop === 0) {
      handleReloadConversations()
        .then(() => {
          LOG.debug('handleReloadConversations success');
        })
        .catch((error) => LOG.error('handleReloadConversations error', error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadMessagesHandlerVisible, listScrollTop]);

  useIncomingMessageReceivedSubscription({
    onData: async ({
      data: {
        data: { incomingMessageReceived },
      },
      client,
    }: OnDataOptions<IncomingMessageReceivedSubscription>) => {
      const currentProfileId = profileId || currentAccount.id;

      LOG.debug('Incoming message received', incomingMessageReceived);

      // TODO: remove in scope of https://smartercontact.atlassian.net/browse/SC-9630 and update the cache directly
      // need to populate cache with updated record to render the updated outbound own phone from field resolver
      await fetchConversation({
        variables: {
          id: incomingMessageReceived.conversationId,
        },
      });

      if (incomingMessageReceived.type === MessageType.CALL) {
        updateConversationWithIncomingCall(client, incomingMessageReceived);
      }

      if (
        currentProfileId !== incomingMessageReceived?.profileId ||
        !findConversations?.items.length
      ) {
        LOG.debug('profileId does not match or no conversations found');
        return;
      }

      const isNewIncomingActivity =
        isNewMessageDelivered(incomingMessageReceived) ||
        isMissedCall(incomingMessageReceived);

      if (!isNewIncomingActivity) {
        LOG.debug('skip action for incoming message');
        return;
      }

      if (
        findConversations.items.length > DEFAULT_PAGE_SIZE &&
        listScrollTop !== 0
      ) {
        LOG.debug('start showing reload messages button');

        showReloadMessagesHandler();
      } else {
        LOG.debug('start fetching new messages');
        await refetch();
      }
    },
  });

  const fetchNextPage = () =>
    findConversations?.hasNext &&
    !(findConversations?.items?.length && isLoading)
      ? fetchMore(createPaginationVariables(findConversations))
      : {};

  const memoizedValue = useMemo(
    () => ({
      path,
      title,
      type,
      queryParams,
      profileId,
      isLoading,
      isFetchingNextPage,
      isReady,
      isEmptyFilters,
      record: findConversations?.items,
      urlSearchParams,
      onListScroll,
      reloadMessagesHandlerVisible,
      handleFullReloadConversations,
      date,
      labels,
      params,
      sort,
      hasNext: findConversations?.hasNext,
      recordsCount: findConversations?.total,
      categoryUnread,
      selectedContact,
      setSort,
      setSelectedContact,
      handleReloadConversations,
      setSearchValue,
      listScrollToAlignment,
      setLabels,
      setDate,
      fetchNextPage,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      path,
      title,
      type,
      queryParams,
      profileId,
      isLoading,
      isLoadingData,
      isFetchingNextPage,
      isReady,
      findConversations,
      date,
      labels,
      params,
    ],
  );

  return (
    <MessengerContext.Provider
      value={{
        memoizedValue,
      }}>
      {children}
    </MessengerContext.Provider>
  );
};

export const useMessengerContext = () => {
  const context = useContext(MessengerContext);

  if (!context) {
    throw new Error(
      'useMessengerContext must be used within the MessengerContextProvider',
    );
  }

  return context;
};
