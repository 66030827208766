import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { ErrorBoundary } from '@sentry/react';
import { createEditor, Descendant } from 'slate';
import {
  Slate,
  Editable,
  withReact,
  RenderLeafProps,
  RenderElementProps,
} from 'slate-react';
import { useFormikContext } from 'formik';

import { ErrorFallback } from '@app/components/ErrorFallback';

import { WysiwygCommand, WysiwygProps } from './config/types';
import { WysiwygHeader } from './ui/WysiwygHeader';
import { wysiwygCommands } from './lib/commands';
import { WysiwygLeaf } from './ui/WysiwygLeaf';
import { WysiwygElement } from './ui/WysiwygElement';
import { serialize } from './lib/serialize';
import { deserialize } from './lib/deserialize';

const emptyParagraph: Descendant[] = [
  { type: 'paragraph', children: [{ text: '' }] },
];

export const Wysiwyg: FC<WysiwygProps> = ({
  name,
  initialContent,
  disabled = false,
}) => {
  const { setFieldValue } = useFormikContext();

  const [initialValue] = useState<Descendant[]>(
    initialContent
      ? (deserialize(
          new DOMParser().parseFromString(initialContent, 'text/html').body,
        ) as Descendant[])
      : emptyParagraph,
  );

  const editor = useMemo(() => withReact(createEditor()), []);
  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <WysiwygLeaf {...props} />,
    [],
  );
  const renderElement = useCallback(
    (props: RenderElementProps) => <WysiwygElement {...props} />,
    [],
  );

  const handleCommandClick = useCallback(
    (command: WysiwygCommand) => {
      wysiwygCommands[command](editor);
    },
    [editor],
  );

  const handleChange = useCallback(
    async (value: Descendant[]) => {
      const isAstChange = editor.operations.some(
        (op) => 'set_selection' !== op.type,
      );
      if (isAstChange) {
        const parsedContent = serialize(value);
        await setFieldValue(name, parsedContent);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name],
  );

  useEffect(() => {
    void setFieldValue(name, serialize(initialValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary fallback={() => <ErrorFallback height="170px" />}>
      <Slate
        editor={editor}
        initialValue={initialValue}
        onChange={handleChange}>
        <Box
          _focusWithin={{
            borderColor: 'primary.500',
          }}
          border="1px solid"
          borderColor="secondary.200"
          borderRadius="19px"
          overflow="hidden"
          width="345px">
          <WysiwygHeader
            disabled={disabled}
            onCommandClick={handleCommandClick}
          />
          <Box
            as={Editable}
            backgroundColor={disabled ? 'catskillWhite' : 'white'}
            border="none"
            color="main.400"
            fontSize="14px"
            height="130px"
            outline="none"
            overflowX="hidden"
            overflowY="scroll"
            padding="20px"
            placeholder="Type here…"
            readOnly={disabled}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            width="100%"
          />
        </Box>
      </Slate>
    </ErrorBoundary>
  );
};
