import React, { FC } from 'react';
import { Box, Checkbox, Text } from '@chakra-ui/react';

import { Label } from '@app/api/gql/graphql';

interface LabelListItemProps {
  label: Label;
  isChecked: boolean;
  onChange: (label: Label) => void;
  isMasterAccount: boolean;
}

export const LabelListItem: FC<LabelListItemProps> = ({
  label: item,
  isChecked,
  isMasterAccount,
  onChange,
}) => {
  return (
    <Box
      _last={{
        marginBottom: 0,
      }}
      lineHeight="14px"
      marginBottom="17px"
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        if (isMasterAccount) {
          return;
        }
        void onChange(item);
      }}>
      <Checkbox
        cursor="pointer"
        isChecked={isChecked}
        isDisabled={isMasterAccount}
        variant="labelsList"
        w="100%">
        <Text as="span" fontSize="12px" whiteSpace="nowrap">
          {item.title}
        </Text>
      </Checkbox>
    </Box>
  );
};
