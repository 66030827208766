import { Box } from '@chakra-ui/react';
import React, { FC, ReactNode, useState } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';

import PlayIcon from './PlayIcon';

const playerVars = {
  controls: 1,
  rel: 0,
  showinfo: 0,
  mute: 0,
  loop: 1,
} as YouTubeProps;

interface PlayerProps {
  video: string;
  image?: ReactNode;
  width?: string;
  height?: string;
  type?: string;
  fullWidth?: boolean;
  autoplay?: boolean;
  isButtonShadow?: boolean;
}

const Player: FC<PlayerProps> = ({
  video,
  image,
  width = '375',
  height = '235',
  fullWidth = false,
  type = 'youtube',
  autoplay = true,
  isButtonShadow = false,
}) => {
  const [showVideo, setShowVideo] = useState(!image);

  const componentMap: Record<string, ReactNode> = {
    youtube: (
      <YouTube
        opts={{
          playerVars: { ...playerVars, autoplay: autoplay ? 1 : 0 },
          width,
          height,
        }}
        videoId={video}
      />
    ),
    iframe: (
      <iframe
        allowFullScreen={true}
        height={height}
        src={video}
        width={fullWidth ? '100%' : width}
      />
    ),
    loom: (
      <iframe
        allowFullScreen={true}
        height={height}
        src={video}
        width={fullWidth ? '100%' : width}
      />
    ),
  };

  if (showVideo) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{componentMap[type]}</>;
  }

  return (
    <Box height="100%" position="relative">
      {image}
      <PlayIcon
        isButtonShadow={isButtonShadow}
        onClick={() => setShowVideo(true)}
      />
    </Box>
  );
};

export default Player;
