import { isEmpty } from 'lodash/fp';
import { getLogger } from '@app/utils/logger';

const LOG = getLogger('utils/localStorageUtils');

/**
 * Sets a value in the localStorage object.
 *
 * @param {string} key - The key to use for storing the value.
 * @param {string} value - The value to be stored.
 * @returns {void}
 */
export const setLocalStorageItem = (key: string, value: unknown): void => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    LOG.error(`Error setting item in localStorage`, err);
  }
};

/**
 * Removes an item from the localStorage.
 *
 * @param {string} key - The key of the item to be removed.
 * @returns {void}
 *
 * @throws {Error} - If an error occurs while removing the item from localStorage.
 */
export const unsetLocalStorageItem = (key: string): void => {
  try {
    window.localStorage.removeItem(key);
  } catch (error) {
    LOG.error(`Error removing item from localStorage`, error);
  }
};

/**
 * Returns the value associated with the specified key in the localStorage.
 * If the value is not found, it returns the defaultValue.
 *
 * @param {string} key - The key to look for in the localStorage.
 * @param {T} defaultValue - The default value to return if the key is not found.
 * @returns {T | string | null} The value associated with the key, or the defaultValue if not found.
 */
export const getLocalStorageItem = <T>(
  key: string,
  defaultValue: T,
): T | string | null => {
  try {
    const value = window.localStorage.getItem(key);

    return isEmpty(value)
      ? defaultValue || null
      : (JSON.parse(value) as T | string | null);
  } catch (error) {
    LOG.error(`Error getting item from localStorage`, error);
    return defaultValue || null;
  }
};
