import { DateTime } from 'luxon';

import { DateType } from '@app/widgets/DatePicker';

export const prepareDatesForRequest = (dates: DateType) => {
  const { start, end } = dates;

  return {
    from: DateTime.fromJSDate(start)
      ?.startOf('day')
      .toFormat('yyyy-MM-dd HH:mm:ss'),
    to: DateTime.fromJSDate(end)?.endOf('day').toFormat('yyyy-MM-dd HH:mm:ss'),
  };
};
