import React, { FC, PropsWithChildren } from 'react';
import { Flex } from '@chakra-ui/react';

interface ContetWrapperProps extends PropsWithChildren {
  isActive: boolean;
}

const ContentWrapper: FC<ContetWrapperProps> = ({ isActive, children }) => {
  return <Flex display={isActive ? 'flex' : 'none'}>{children}</Flex>;
};

export default ContentWrapper;
