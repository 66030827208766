import React, { useRef, useCallback, useMemo } from 'react';
import { DateTime, HourNumbers } from 'luxon';
import findLast from 'lodash/findLast';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
  Box,
} from '@chakra-ui/react';

import { isMobile } from 'react-device-detect';
import { TimePicker } from './TimePicker';
import { DatePicker } from './DatePicker';
import { DateTimeWrapStyle } from './styles';
import { useDisabledDates } from './hooks/useDisabledDates';
import { DateTimePickerProps, Mode } from './types';
import { ButtonTrigger } from './ButtonTrigger';

const hoursArray = Array(24)
  .fill(0)
  .map((_, i) => i + 1) as HourNumbers[];

export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  value = DateTime.fromJSDate(new Date()),
  defaultValue = null,
  onChange,
  onPickerClose,
  disabledHours,
  disabledMinutes,
  disabledTimeFormat = false,
  disabledStartDate,
  disabledEndDate,
  disabled = false,
  mode = Mode.DATETIME,
  isForCampaign = false,
  timeZone,
}) => {
  const { defaultTime } = useDisabledDates({
    customTimeZone: timeZone,
  });

  const parsedValue = useMemo(() => {
    if (timeZone) {
      return value.setZone(timeZone);
    }
    return value;
  }, [value, timeZone]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const contentWrapperWidth = useMemo(() => {
    switch (mode) {
      case Mode.TIME:
        return '168px';
      case Mode.DATE:
        return '315px';
      case Mode.DATETIME:
        return '500px';
      default: {
        return '315px';
      }
    }
  }, [mode]);

  const dateMode = (() => mode === Mode.DATETIME || mode === Mode.DATE)();
  const timeMode = (() => mode === Mode.DATETIME || mode === Mode.TIME)();

  const onModalClose = useCallback(() => {
    onClose();
    if (onPickerClose) {
      onPickerClose();
    }
  }, [onClose, onPickerClose]);

  const getNearestTime = (val: DateTime) => {
    let { hour, minute } = val;

    if (
      isForCampaign &&
      val?.startOf('minute').toSeconds() <
        defaultTime.startOf('minute').toSeconds()
    ) {
      const time = DateTime.fromMillis(Date.now());
      hour = time.hour;
      minute = time.minute;
    }
    const disabledHour = disabledHours?.find((item) => item === hour);

    const enabledHours = hoursArray.filter((i) => !disabledHours?.includes(i));

    if (disabledHour < 12) {
      hour = enabledHours.find((item) => item < 12);
    } else if (disabledHour > 12) {
      hour = findLast(enabledHours, (item) => item > 12);
    }

    return { hour, minute };
  };

  const onSelect = (val: DateTime) => {
    const { hour, minute } = getNearestTime(val);
    const selectedTime = val.set({ hour, minute });

    onChange(selectedTime);
  };

  return (
    <Popover
      closeOnBlur
      isLazy
      isOpen={isOpen}
      placement="bottom-start"
      returnFocusOnClose={false}
      onClose={onModalClose}>
      <PopoverTrigger>
        <Box maxWidth="100%" position="relative">
          <ButtonTrigger
            defaultValue={defaultValue}
            disabled={disabled}
            isOpen={isOpen}
            mode={mode}
            value={value}
            onOpen={onOpen}
          />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        _focus={{ borderColor: 'inherit' }}
        border="none"
        borderRadius="22px"
        width={contentWrapperWidth}>
        <DateTimeWrapStyle ref={wrapperRef} isMobile={isMobile} mode={mode}>
          {dateMode && (
            <DatePicker
              date={parsedValue}
              disabledEndDate={disabledEndDate}
              disabledStartDate={disabledStartDate}
              selectedDate={parsedValue}
              onSelect={onSelect}
            />
          )}
          {timeMode && (
            <TimePicker
              disabledHours={disabledHours}
              disabledMinutes={disabledMinutes}
              disabledTimeFormat={disabledTimeFormat}
              mode={mode}
              value={parsedValue}
              onChange={onSelect}
            />
          )}
        </DateTimeWrapStyle>
      </PopoverContent>
    </Popover>
  );
};
