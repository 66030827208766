import { DateTime } from 'luxon';

import { PopupConstructor } from '@app/pages/PopupConstructor/types';
import { OptionData } from '@app/components/SelectButtonsField/OptionData';
import {
  MarketingPopupAudience,
  MarketingPopupStatus,
  MarketingPopupTemplateType,
} from '@app/api/gql/generated-types';
import { getDisabledTime } from '@app/widgets/DTPicker/DatePicker/utils/disabledTimeToday';
import { GetMarketingPopupQuery } from '@app/api/gql/graphql';

import {
  audienceOptionsMap,
  statusOptions,
  templateOptions,
} from './content/selectOptions';

export const initialValues: PopupConstructor = {
  status: undefined,
  audiences: [],
  title: '',
  description: '',
  startDate: undefined,
  endDate: undefined,
  template: {
    type: undefined,
    buttonText: '',
    buttonLink: '',
    imageSrc: undefined,
    videoSrc: '',
  },
};

export const hasVideo = (template?: OptionData): boolean => {
  const value = template?.value as MarketingPopupTemplateType;
  return (
    template &&
    (value === MarketingPopupTemplateType.VIDEO ||
      value === MarketingPopupTemplateType.VIDEO_WITH_BUTTON)
  );
};

export const hasButton = (template?: OptionData): boolean => {
  const value = template?.value as MarketingPopupTemplateType;
  return (
    template &&
    (value === MarketingPopupTemplateType.IMAGE_WITH_BUTTON ||
      value === MarketingPopupTemplateType.VIDEO_WITH_BUTTON)
  );
};

export const hasImg = (template?: OptionData): boolean => {
  const value = template?.value as MarketingPopupTemplateType;
  return (
    template &&
    (value === MarketingPopupTemplateType.IMAGE ||
      value === MarketingPopupTemplateType.IMAGE_WITH_BUTTON)
  );
};

export const disabledDeps = (values: PopupConstructor): boolean[] => {
  const result: boolean[] = [
    !values.status,
    !values.audiences.length,
    !values.template.type,
    !values.title,
    !values.description,
    !values.startDate,
    !values.endDate,
  ];

  if (hasImg(values.template.type)) {
    result.push(!values.template.imageSrc);
  }

  if (hasButton(values.template.type)) {
    result.push(!values.template.buttonLink, !values.template.buttonText);
  }

  if (hasVideo(values.template.type)) {
    result.push(!values.template.videoSrc);
  }

  return result;
};

export const prepareAudience = (
  audience: PopupConstructor['audiences'],
): MarketingPopupAudience[] => {
  if (
    audience.some(
      (a) => (a.value as MarketingPopupAudience) === MarketingPopupAudience.ALL,
    )
  ) {
    return [MarketingPopupAudience.ALL];
  }
  return audience.map((a) => a.value as MarketingPopupAudience);
};

export const disabledTimes = ({
  startDate = DateTime.local(),
  endDate = DateTime.local(),
  fallBackDate = DateTime.local(),
  timeZone,
}: {
  startDate?: DateTime;
  endDate?: DateTime;
  fallBackDate?: DateTime;
  timeZone?: string;
} = {}) => {
  let correctedStartDate = startDate;
  let correctedEndDate = endDate;
  let correctedFallBackDate = fallBackDate;

  if (timeZone) {
    correctedStartDate = startDate.setZone(timeZone);
    correctedEndDate = endDate.setZone(timeZone);
    correctedFallBackDate = fallBackDate.setZone(timeZone);
  }

  const disabledTimesNext = getDisabledTime(
    startDate.isValid ? correctedStartDate : correctedFallBackDate,
    endDate.isValid
      ? correctedEndDate
      : correctedFallBackDate.plus({ days: 1 }),
  );

  return disabledTimesNext;
};

export const prepareDataForForm = (
  data: GetMarketingPopupQuery['getMarketingPopup'],
) => {
  if (!data) {
    return initialValues;
  }

  const { audiences, template, ...rest } = data;

  return {
    ...rest,
    audiences: audiences.map((item) => ({
      value: item,
      label: audienceOptionsMap[item],
    })),
    status: statusOptions.find((item) => item.value === data.status),
    template: {
      ...template,
      type: templateOptions.find((item) => item.value === template.type),
    },
    startDate:
      (data.status as MarketingPopupStatus) === MarketingPopupStatus.INACTIVE
        ? initialValues.startDate
        : new Date(data.startDate),
    endDate:
      (data.status as MarketingPopupStatus) === MarketingPopupStatus.INACTIVE
        ? initialValues.endDate
        : new Date(data.endDate),
  };
};
