import React, { ChangeEvent, FC, ReactNode, useMemo } from 'react';
import { FormControl, FormErrorMessage, InputProps } from '@chakra-ui/react';
import { useField } from 'formik';

import { PhoneNumberInput } from '@app/components/PhoneNumberInput';

import Label from './Label';

interface FormItemPhoneInputProps extends InputProps {
  label?: string | ReactNode;
  onBlur?: (e: ChangeEvent) => void;
}

const FormItemPhoneInput: FC<FormItemPhoneInputProps> = ({
  label,
  onBlur,
  ...props
}) => {
  const [field, meta] = useField(props.name);
  const isInvalid = useMemo(() => !!(meta.touched && meta?.error), [meta]);

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <Label>{label}</Label>}
      <PhoneNumberInput
        {...field}
        {...props}
        onBlur={field.onBlur}
        onChange={field.onChange}
      />
      {isInvalid && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </FormControl>
  );
};

export default FormItemPhoneInput;
