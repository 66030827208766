import { array, object, string } from 'yup';

export const exportSchema = object().shape({
  exportBy: string().required('Please, select an option'),
  items: array()
    .of(string().required('Item is required'))
    .min(1, 'Please, select at least one category.')
    .required('Please, select a category'),
  exportTo: string().required('Please, select an option to export'),
});
