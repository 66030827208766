import { useContext } from 'react';

import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';
import { Account, Scope } from '@app/api/gql/generated-types';
import { getPhoneNumberPrice } from '@app/utils/accountUtils';
import { centsToDollars } from '@app/utils/formatNumber';

interface CurrentAccountData extends Account {
  isTrial: boolean;
  isMessagePlanTrialing: boolean;
  isDialerPlanTrialing: boolean;
  isActiveAccount: boolean;
  phoneNumberPrice: number;
  balance: number;
}

export const useCurrentAccountData = (scope?: Scope): CurrentAccountData => {
  const {
    currentAccount: accountSnap,
    isTrial,
    isMessagePlanTrialing,
    isDialerPlanTrialing,
    isActiveAccount,
  } = useContext(CurrentAccountContext);
  return {
    ...accountSnap,
    isTrial,
    isMessagePlanTrialing,
    isDialerPlanTrialing,
    isActiveAccount,
    phoneNumberPrice: getPhoneNumberPrice(accountSnap, scope),
    balance: centsToDollars(accountSnap.billingProfile?.balance?.balance),
  };
};
