import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';

import Popup from '@app/components/Popup';
import { DragAndDrop } from '@app/components/DragAndDrop';

interface DropModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

export const DropModal: FC<DropModalProps> = ({ isOpen, onClose, title }) => (
  <Popup
    closeOnOverlayClick
    hideCloseIcon
    isOpen={isOpen}
    maxW="830px"
    size="lg"
    title={title}
    width="830px"
    onClose={onClose}>
    <Box margin="35px 40px">
      <DragAndDrop />
    </Box>
  </Popup>
);
