import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Text,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { ListVerificationResult } from '@app/api/gql/generated-types';

type ListVerificationResultProps = {
  verificationList: ListVerificationResult;
};

export const AccordionInvalidNumbers: FC<ListVerificationResultProps> = ({
  verificationList,
}) => {
  const {
    totalPhones,
    verifiedPhones,
    nonSmsCapable,
    litigators,
    nationalDnc,
    internalDnc,
    unsubscribed,
    filtered,
    existing,
    globalUnsubscribed,
    carrierBlocked,
    undeliverable,
  } = verificationList || {};

  const invalidNumber = totalPhones - verifiedPhones;

  const listVerificationResult = [
    {
      name: 'Unverified phones',
      value: nonSmsCapable,
    },
    {
      name: 'Opted out',
      value: unsubscribed,
    },
    {
      name: 'Global opted out',
      value: globalUnsubscribed,
    },
    {
      name: 'Litigators',
      value: litigators,
    },
    {
      name: 'National DNC list',
      value: nationalDnc,
    },
    {
      name: 'Internal DNC list',
      value: internalDnc,
    },
    {
      name: 'Response filtration',
      value: filtered,
    },
    {
      name: 'Existing contacts',
      value: existing,
    },
    {
      name: 'Carrier blocked',
      value: carrierBlocked,
    },
    {
      name: 'Undeliverable',
      value: undeliverable,
    },
  ];

  return (
    <Accordion allowToggle={true} pb="10px">
      <AccordionItem border="none">
        <AccordionButton _hover={{ bg: 'none' }} p="0">
          <Text color="main.400" fontSize="14px" padding="10px 0 5px">
            Invalid numbers: <strong>{invalidNumber}</strong>
            <AccordionIcon />
          </Text>
        </AccordionButton>

        <AccordionPanel p={0}>
          {listVerificationResult.map((item, index) => (
            <Text
              key={index}
              color="main.400"
              flex="1"
              fontSize="14px"
              textAlign="left">
              {item?.name}: {item?.value}
            </Text>
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
