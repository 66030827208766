import React, { FC } from 'react';
import {
  useEditableControls,
  ButtonGroup,
  IconButton,
  Icon,
  Box,
} from '@chakra-ui/react';
import type { PropGetter } from '@chakra-ui/react-utils';

import CheckDoneBlueIcon from '@app/icons/check-done-current.svg?react';
import EditTextIcon from '@app/icons/edit-text-icon.svg?react';
import CrossIcon from '@app/icons/CrossIcon.svg?react';
import { colors } from '@app/theme/colors';

interface EditableControlsProps {
  isSubmitDisabled: boolean;
  isEditing: boolean;
  getEditButtonProps: PropGetter;
}

export const EditableControls: FC<EditableControlsProps> = ({
  isSubmitDisabled,
  isEditing,
  getEditButtonProps,
}) => {
  const { getSubmitButtonProps, getCancelButtonProps } = useEditableControls();

  return (
    <Box ml="15px">
      {isEditing ? (
        <ButtonGroup spacing="5px">
          <IconButton
            isRound
            aria-label="Save"
            color={isSubmitDisabled ? 'white' : colors.primary[600]}
            icon={<Icon as={CheckDoneBlueIcon} />}
            isDisabled={isSubmitDisabled}
            size="middle"
            variant="controlIconButton"
            {...(!isSubmitDisabled && { ...getSubmitButtonProps() })}
          />

          <IconButton
            isRound
            aria-label="Cancel"
            icon={<Icon as={CrossIcon} height="10px" width="10px" />}
            isDisabled={isSubmitDisabled}
            maxW="30px"
            size="middle"
            variant="controlIconButton"
            {...getCancelButtonProps()}
          />
        </ButtonGroup>
      ) : (
        <IconButton
          aria-label="Edit"
          bg="transparent !important"
          color="main.100"
          height="unset"
          icon={<EditTextIcon />}
          mb="7px"
          variant="flatIconButton"
          {...getEditButtonProps()}
        />
      )}
    </Box>
  );
};
