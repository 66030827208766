import React from 'react';
import { RouteObject } from 'react-router-dom';

import CampaignMessages from '@app/pages/Messenger/content/CampaignMessages';
import InboxAll from '@app/pages/Messenger/content/InboxAll';
import InboxDeleted from '@app/pages/Messenger/content/InboxDeleted';
import InboxOptedOut from '@app/pages/Messenger/content/InboxOptedOut';
import InboxSent from '@app/pages/Messenger/content/InboxSent';
import InboxUnread from '@app/pages/Messenger/content/InboxUnread';
import LabelMessages from '@app/pages/Messenger/content/LabelMessages';
import MissedCalls from '@app/pages/Messenger/content/MissedCalls';
import { GroupMessages } from '@app/pages/Messenger/content/GroupMessages';

import { CampaignStatus, CampaignType } from '@app/api/gql/generated-types';
import SkiptraceEntity from '@app/pages/Skiptrace/content/SkiptraceEntity';
import SkiptraceList from '@app/pages/Skiptrace/content/SkiptraceList';
import SkiptraceEmpty from '@app/pages/Skiptrace/ui-states/SkiptraceEmpty';

import SideBarWrapper from '@app/components/SideBarMessenger';
import ContactList from '@app/pages/Contacts/components/ContactList';
import AllContacts from '@app/pages/Contacts/content/AllContacts';
import DeletedContacts from '@app/pages/Contacts/content/DeletedContacts';
import { AllGroupContacts } from '@app/pages/Contacts/content/GroupsContacts/AllGroupContacts';
import PopupCreate from '@app/pages/PopupConstructor/content/PopupCreate';
import PopupList from '@app/pages/PopupConstructor/content/PopupList';

import { CallScript } from '@app/pages/Campaigns/content/CallScripts';
import CallingSetup from '@app/pages/Settings/content/CallingSetup';
import { CampaignsProvider } from '@app/pages/Campaigns/providers/CampaignsProvider';
import Notifications from '@app/pages/Settings/content/Notifications';
import AllGroups from '@app/pages/Contacts/content/GroupsContacts/AllGroups';
import { DEFAULT_WIDTH_CONTACTS_SLIDE_WRAPPER } from '@app/constants/configuration';
import MessagingSetup from '@app/pages/Settings/content/MessagingSetup';
import {
  AddNewCardPage,
  Affiliate,
  AffiliateProgram,
  ApiSettings,
  BrandRegistration,
  Calendar,
  Campaigns,
  CampaignsList,
  DialerCampaignsList,
  ContactManagement,
  Contacts,
  CreateCampaign,
  CreateDialerCampaign,
  CreateKeywordCampaign,
  DialerCallPage,
  DialerCampaigns,
  DlcRegistration,
  EnvInfo,
  GeneralSettings,
  HelpSupport,
  KeywordTemplate,
  Logout,
  Macros,
  Membership,
  MessageTemplate,
  Messenger,
  MessengerFull,
  NotFound,
  OAuth,
  PopupConstructor,
  Profile,
  Reporting,
  Resources,
  Settings,
  Skiptrace,
  UserInfoPage,
  UserSetup,
  MobileApp,
} from './index';
import { AdminRouteGuard } from './AdminRouteGuard';
import { PrivateRoute } from './PrivateRoute';
import { ProtectedRouteGuard } from './ProtectedRouteGuard';

export const routesConfig: RouteObject[] = [
  {
    path: '/',
    children: [
      {
        path: 'messenger',
        element: <Messenger />,
        children: [
          {
            path: 'inbox/all/:contactId?/:tabId?',
            element: <InboxAll />,
          },
          {
            path: 'inbox/unread/:contactId?/:tabId?',
            element: <InboxUnread />,
          },
          {
            path: 'inbox/missed-calls/:contactId?/:tabId?',
            element: <MissedCalls />,
          },
          {
            path: 'inbox/sent/:contactId?/:tabId?',
            element: <InboxSent />,
          },
          {
            path: 'inbox/opted-out/:contactId?/:tabId?',
            element: <InboxOptedOut />,
          },
          {
            path: 'inbox/deleted/:contactId?/:tabId?',
            element: <InboxDeleted />,
          },
          {
            path: 'inbox/campaigns/:campaignId?/:contactId?/:tabId?',
            element: <CampaignMessages />,
          },
          {
            path: 'inbox/labels/:labelId?/:contactId?/:tabId?',
            element: <LabelMessages />,
          },
          {
            path: 'inbox/groups/:groupId?/:contactId?/:tabId?',
            element: <GroupMessages />,
          },
          {
            path: 'macros',
            element: <Macros />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
      {
        path: 'messenger-full/:contactId?/:tabId?',
        element: <MessengerFull />,
      },
      {
        path: 'campaigns',
        element: <Campaigns />,
        children: [
          {
            path: 'all',
            element: <CampaignsList key="ALL" />,
          },
          {
            path: 'active',
            element: (
              <CampaignsList
                key={CampaignStatus.ACTIVE}
                status={CampaignStatus.ACTIVE}
              />
            ),
          },
          {
            path: 'draft',
            element: (
              <CampaignsList
                key={CampaignStatus.DRAFT}
                status={CampaignStatus.DRAFT}
              />
            ),
          },
          {
            path: 'deleted',
            element: (
              <CampaignsList
                key={CampaignStatus.DELETED}
                status={CampaignStatus.DELETED}
              />
            ),
          },
          {
            element: <ProtectedRouteGuard />,
            children: [
              {
                path: 'keyword/all',
                element: (
                  <CampaignsList
                    key={CampaignType.KEYWORD}
                    type={CampaignType.KEYWORD}
                  />
                ),
              },
              {
                path: 'keyword/active',
                element: (
                  <CampaignsList
                    key={CampaignType.KEYWORD + '_' + CampaignStatus.ACTIVE}
                    status={CampaignStatus.ACTIVE}
                    type={CampaignType.KEYWORD}
                  />
                ),
              },
              {
                path: 'keyword/draft',
                element: (
                  <CampaignsList
                    key={CampaignType.KEYWORD + '_' + CampaignStatus.DRAFT}
                    status={CampaignStatus.DRAFT}
                    type={CampaignType.KEYWORD}
                  />
                ),
              },
              {
                path: 'keyword/deleted',
                element: (
                  <CampaignsList
                    key={CampaignType.KEYWORD + '_' + CampaignStatus.DELETED}
                    status={CampaignStatus.DELETED}
                    type={CampaignType.KEYWORD}
                  />
                ),
              },
            ],
          },
          {
            path: 'message-template',
            element: <MessageTemplate />,
          },
          {
            path: 'keyword-template',
            element: <KeywordTemplate />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
      {
        path: 'dialer',
        element: <DialerCampaigns />,
        children: [
          {
            path: 'all',
            element: <DialerCampaignsList type={CampaignType.STANDARD} />,
          },
          {
            path: 'active',
            element: (
              <DialerCampaignsList
                status={CampaignStatus.ACTIVE}
                type={CampaignType.STANDARD}
              />
            ),
          },
          {
            path: 'draft',
            element: (
              <DialerCampaignsList
                status={CampaignStatus.DRAFT}
                type={CampaignType.STANDARD}
              />
            ),
          },
          {
            path: 'deleted',
            element: (
              <DialerCampaignsList
                status={CampaignStatus.DELETED}
                type={CampaignType.STANDARD}
              />
            ),
          },
          {
            path: 'call-scripts',
            element: <CallScript />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
      {
        path: 'dialer/*',
        children: [
          {
            path: 'create',
            element: <CreateDialerCampaign />,
          },
          {
            path: 'edit/:id',
            element: <CreateDialerCampaign key={window.location.pathname} />,
          },
        ],
      },
      {
        path: 'dialer/:campaignId',
        element: <DialerCallPage />,
        children: [
          {
            path: 'all/:contactId?/:tabId?',
            element: <DialerCallPage />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
      {
        path: 'campaign/*',
        children: [
          {
            path: 'create',
            element: (
              <CampaignsProvider>
                <CreateCampaign key={window.location.pathname} />
              </CampaignsProvider>
            ),
          },
          {
            path: 'edit/:id',
            element: (
              <CampaignsProvider>
                <CreateCampaign key={window.location.pathname} />
              </CampaignsProvider>
            ),
          },
          {
            path: 'duplicate/:id',
            element: (
              <CampaignsProvider>
                <CreateCampaign key={window.location.pathname} />
              </CampaignsProvider>
            ),
          },
          {
            path: 'keyword/*',
            children: [
              {
                path: 'create',
                element: (
                  <CampaignsProvider>
                    <CreateKeywordCampaign key={window.location.pathname} />
                  </CampaignsProvider>
                ),
              },
              {
                path: 'edit/:id',
                element: (
                  <CampaignsProvider>
                    <CreateKeywordCampaign key={window.location.pathname} />
                  </CampaignsProvider>
                ),
              },
              {
                path: 'duplicate/:id',
                element: (
                  <CampaignsProvider>
                    <CreateKeywordCampaign key={window.location.pathname} />
                  </CampaignsProvider>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'contacts',
        element: <Contacts />,
        children: [
          {
            path: 'groups',
            element: <AllGroups />,
          },
          {
            path: 'all-contacts/:contactId?/:tabId?',
            element: (
              <>
                <AllContacts />
                <SideBarWrapper
                  minWidth={DEFAULT_WIDTH_CONTACTS_SLIDE_WRAPPER}
                  resizable={false}
                />
              </>
            ),
          },
          {
            path: 'deleted/:contactId?/:tabId?',
            element: (
              <>
                <DeletedContacts />
                <SideBarWrapper
                  minWidth={DEFAULT_WIDTH_CONTACTS_SLIDE_WRAPPER}
                  resizable={false}
                />
              </>
            ),
          },
          {
            path: 'groups/:contactListId/:contactId?/:tabId?',
            element: (
              <>
                <AllGroupContacts />
                <SideBarWrapper
                  minWidth={DEFAULT_WIDTH_CONTACTS_SLIDE_WRAPPER}
                  resizable={false}
                />
              </>
            ),
          },
          {
            path: 'edit/:contactListId',
            element: <ContactList />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
      {
        path: 'calendar/:view?',
        element: <Calendar />,
      },
      {
        path: 'skiptrace',
        element: <Skiptrace />,
        children: [
          {
            path: 'add-new',
            element: <SkiptraceEmpty />,
          },
          {
            path: 'all-files',
            element: <SkiptraceList />,
          },
          {
            path: 'edit/:id',
            element: <SkiptraceEntity />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
      {
        path: 'reporting',
        element: <Reporting />,
      },
      {
        path: 'user-setup',
        element: (
          <AdminRouteGuard>
            <UserSetup />
          </AdminRouteGuard>
        ),
      },
      {
        path: 'affiliates',
        element: (
          <AdminRouteGuard>
            <Affiliate />
          </AdminRouteGuard>
        ),
      },
      {
        path: 'popup-constructor/*',
        element: (
          <AdminRouteGuard>
            <PopupConstructor />
          </AdminRouteGuard>
        ),
        children: [
          {
            index: true,
            element: <PopupList />,
          },
          {
            path: 'create',
            element: <PopupCreate />,
          },
          {
            path: 'edit/:id',
            element: <PopupCreate />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
      {
        path: 'settings',
        element: <Settings />,
        children: [
          {
            path: 'profile',
            element: <Profile />,
          },
          {
            path: 'phone-setup',
            element: <MessagingSetup />,
          },
          {
            path: 'calling-setup',
            element: <CallingSetup />,
          },
          {
            path: 'dlc-registration',
            element: (
              <PrivateRoute>
                <DlcRegistration />
              </PrivateRoute>
            ),
          },
          {
            path: 'brand-registration',
            element: (
              <PrivateRoute>
                <BrandRegistration />
              </PrivateRoute>
            ),
          },
          {
            path: 'api',
            element: <ApiSettings />,
          },
          {
            path: 'membership/*',
            element: <Membership />,
            children: [
              {
                index: true,
                element: <UserInfoPage />,
              },
              {
                path: 'add-card',
                element: <AddNewCardPage />,
              },
            ],
          },
          {
            path: 'settings',
            element: <GeneralSettings />,
          },
          {
            path: 'contact-management',
            element: <ContactManagement />,
          },
          {
            path: 'notifications',
            element: <Notifications />,
          },
          {
            path: 'mobile-app',
            element: <MobileApp />,
          },
          {
            path: 'help-support',
            element: <HelpSupport />,
          },
          {
            path: 'affiliate-program',
            element: <AffiliateProgram />,
          },
          {
            path: 'resources',
            element: <Resources />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
      {
        path: 'oauth',
        element: <OAuth />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
      {
        path: 'env',
        element: <EnvInfo />,
      },
    ],
  },
];
