import React from 'react';
import { CloseButton, Flex, Text } from '@chakra-ui/react';

import { GroupBase, OptionProps, components } from 'react-select';
import { FieldsTemplate } from '@app/api/gql/generated-types';
import { colors } from '@app/theme/colors';

export const CustomOption = <
  T extends FieldsTemplate & { label?: string },
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>(
  props: OptionProps<T, M, G>,
) => {
  const { data, innerRef, innerProps } = props;

  const handleRemoveTemplate = () => {
    props.selectProps.onRemoveTemplate(data.id);
  };

  return (
    <components.Option {...props}>
      <Flex
        _hover={{
          cursor: 'pointer',
          backgroundColor: 'primary.200',
          borderRadius: '20px',
        }}
        alignItems="center"
        justifyContent="space-between">
        <Text
          ref={innerRef}
          {...innerProps}
          color={colors.notFound[3400]}
          fontSize="14px"
          lineHeight="20px">
          {data.label}
        </Text>
        <CloseButton
          _hover={{
            borderRadius: '25px',
            color: colors.primary[600],
          }}
          color={colors.main[100]}
          size="sm"
          onClick={handleRemoveTemplate}
        />
      </Flex>
    </components.Option>
  );
};
