import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { InboxMessages } from '../components/InboxMessages';
import { MessengerFolderType } from '../types';

const LabelMessages: FC = () => {
  const { labelId } = useParams<{ labelId: string }>();

  return (
    <InboxMessages
      path={`inbox/labels/${labelId}`}
      queryParams={{
        labels: [labelId],
      }}
      title="Labels"
      type={MessengerFolderType.LABELS}
    />
  );
};

export default LabelMessages;
