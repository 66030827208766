import React, { FC, ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';

import { ContactsEmpty } from '@app/pages/Contacts/ui-state/ContactsEmpty';

interface AllContactBodyProps {
  isEmpty: boolean;
  children: ReactElement;
}

export const AllContactBody: FC<AllContactBodyProps> = ({
  isEmpty,
  children,
}) => {
  return isEmpty ? (
    <Flex direction="column" height="100%" justifyContent="center">
      <ContactsEmpty />
    </Flex>
  ) : (
    children
  );
};
