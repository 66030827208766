import debug from 'debug';
import { captureException } from '@sentry/react';
import {
  addMiddleware,
  getLogger as getLoggerBase,
} from '@app/utils/loggerUtils';
import { isProduction } from './envUtils';

addMiddleware(debug);

/**
 * Logs an error with contextual information.
 *
 * @param {unknown} error - The error to be logged.
 * @param {string} [context='unknown'] - The contextual information for the error.
 * @returns {void}
 */
/**
 * Retrieves the logger for the specified name.
 *
 * @param {string} name - The name of the logger.
 * @return {Logger} The logger object for the specified name.
 */
export function getLogger(name: string): Record<
  'trace' | 'debug' | 'log' | 'info' | 'warn' | 'error',
  (..._: unknown[]) => void
> & {
  return: <T>(obj: T, ...args: unknown[]) => T;
} {
  return getLoggerBase(name, 'sc');
}

/**
 * Logs an error with contextual information.
 *
 * @param {unknown} error - The error to be logged.
 * @param {string} [context='unknown'] - The contextual information for the error.
 * @returns {void}
 */
export const catchErrorLog = (error: unknown, context = 'unknown') => {
  if (isProduction) {
    captureException(error);
  } else {
    const LOG = () => getLogger(context);
    LOG().error(error);
  }
};
