import {
  Box,
  Flex,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useState } from 'react';
import { Link as RouteLink, useNavigate } from 'react-router-dom';
import { ConfirmDeletionModal } from '@app/components/ConfirmDeletionModal';
import EmptyTable from '@app/components/EmptyTable';
import IconButtonRemove from '@app/components/IconButtonRemove';
import DateCell from '@app/components/Table/Cells/DateCell';
import SkeletonRow from '@app/components/Table/Rows/SkeletonRow';
import PhoneNumberFormat from '@app/shared/ui/PhoneNumberFormat';
import ROUTES, { SUPPORT_LINK } from '@app/utils/routes';

import {
  FindOwnPhonesReportDocument,
  GetOwnPhoneCountersDocument,
  OwnPhoneReportFragment,
  Scope,
  useRemoveOwnPhoneMutation,
} from '@app/api/gql/generated-types';
import { useDlcStatus } from '@app/hooks/useDlcStatus';
import { UnableDeleteModal } from '@app/components/next/moleculas/UnableDeleteModal/UnableDeleteModal';
import { BuyNumber } from '@app/pages/Settings/content/MessagingSetup/components/PhoneNumbers/BuyNumber';
import { wordPluralize } from '@app/utils/string';

import {
  canDeletePhoneNumberThroughLimits,
  getPhoneNumberTypeText,
  phoneNumberStatus,
} from '@app/utils/phoneNumberUtils';
import { getLogger } from '@app/utils/loggerUtils';
import { useUserPhoneNumbersReport } from '@app/hooks/useUserPhoneNumbersReport';
import { EMPTY_VALUE_PLACEHOLDER } from '@app/constants/configuration';
import DeleteErrorModal from '../../DeleteErrorModal';

import TooltipOwnedNumbers from './TooltipOwnedNumbers';

interface OwnedNumbersTableProps {
  isTrial: boolean;
  isFetching: boolean;
  data: OwnPhoneReportFragment[];
}

const LOG = getLogger('OwnedNumbersTable');

export const OwnedNumbersTable: FC<OwnedNumbersTableProps> = ({
  isTrial,
  isFetching,
  data,
}) => {
  const { isApproved } = useDlcStatus();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const errorModal = useDisclosure();
  const navigate = useNavigate();

  const {
    phoneNumbersCount,
    minimumPhoneNumbersCount,
    hasEnoughPhoneNumbers,
    refetch: fetchPhoneNumbers,
  } = useUserPhoneNumbersReport(Scope.MESSAGE);

  const [unableDeletePhoneNumberVisible, setUnableDeletePhoneNumberVisible] =
    useState(false);

  const [remove, { loading: isLoading, error: mutateError }] =
    useRemoveOwnPhoneMutation({
      refetchQueries: [
        FindOwnPhonesReportDocument,
        GetOwnPhoneCountersDocument,
      ],
    });

  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>();

  const handlePhoneDelete = (phoneId: string) => {
    const phoneNumbersCountNext = phoneNumbersCount - 1;
    const phoneNumberData = data?.find((phone) => phone.id === phoneId);

    if (!phoneNumberData) {
      LOG.debug('Phone number not found', { phoneId });
      return;
    }

    const status = phoneNumberStatus(phoneNumberData);

    if (
      phoneNumbersCountNext < minimumPhoneNumbersCount &&
      !canDeletePhoneNumberThroughLimits(status)
    ) {
      LOG.debug('Phone number cannot be deleted', { phoneId, status });
      setUnableDeletePhoneNumberVisible(true);
      return;
    }

    LOG.debug('Phone number can be deleted', { phoneId });
    setSelectedPhoneNumber(phoneId);
    onOpen();
  };

  const resetSelected = () => {
    LOG.debug('Reset selected phone number');
    setSelectedPhoneNumber(undefined);
  };

  const handleDeletePhones = async () => {
    try {
      LOG.debug('Deleting phone number', { selectedPhoneNumber });
      await remove({
        variables: {
          id: selectedPhoneNumber,
        },
      });

      onClose();
      resetSelected();
    } catch (e) {
      LOG.error('Failed to delete phone number', { selectedPhoneNumber }, e);
      onClose();
      errorModal.onOpen();
    }
  };

  return (
    <>
      <Box
        border="1px solid"
        borderColor="mystic"
        borderRadius="10px"
        overflow="hidden"
        w="900px">
        <Table size="sm" variant="phones">
          <Thead>
            <Tr h="43px">
              <Th>Phone number</Th>
              <Th>Type</Th>
              <Th>
                <Flex alignItems="center">
                  {isTrial ? 'Type' : 'Status'}{' '}
                  <TooltipOwnedNumbers isTrial={isTrial} />
                </Flex>
              </Th>
              {isTrial && <Th>Limitation</Th>}
              <Th>Added</Th>
              <Th>{isTrial ? 'Expires' : 'Last used'}</Th>
              <Th>SMS sent</Th>
              <Th>Block rate</Th>
              <Th>Active chats</Th>
              {!isTrial && <Th>Action</Th>}
            </Tr>
          </Thead>
          <Tbody w="100%">
            {data?.map((phone) => {
              return (
                <Tr key={phone.id}>
                  <Td>
                    <PhoneNumberFormat value={phone?.number} />
                  </Td>
                  <Td>
                    <Text color="secondary.400">
                      {getPhoneNumberTypeText(phone.type)}{' '}
                      {phone?.location?.abbreviation && (
                        <Text as="span">{phone?.location?.abbreviation}</Text>
                      )}
                    </Text>
                  </Td>
                  <Td>{isTrial ? 'Trial' : phoneNumberStatus(phone)}</Td>
                  {isTrial && <Td>100 SMS</Td>}

                  {isTrial ? (
                    <DateCell date={new Date(phone.createdAt)} />
                  ) : (
                    <DateCell date={new Date(phone.createdAt)} />
                  )}

                  {isTrial ? (
                    <DateCell date={new Date(phone.createdAt)} />
                  ) : (
                    <DateCell
                      date={
                        phone.reportMessage?.lastUsedAt &&
                        new Date(phone.reportMessage?.lastUsedAt)
                      }
                    />
                  )}

                  <Td>
                    <Text color="main.400" fontSize="14px">
                      {phone.reportMessage?.outgoingMessages ?? 0}
                    </Text>
                  </Td>
                  <Td>
                    {phone.reportMessage
                      ? phone.reportMessage?.blockedRateMessages + '%'
                      : EMPTY_VALUE_PLACEHOLDER}
                  </Td>

                  <Td>{phone.reportMessage?.activeConversations ?? 0}</Td>
                  {!isTrial && (
                    <Td>
                      <IconButtonRemove
                        isDisabled={!isApproved}
                        onClick={() => handlePhoneDelete(phone.id)}
                      />
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
          {isFetching && (
            <SkeletonRow columns={9} isFetching={isFetching} rows={3} />
          )}
        </Table>
        {!isFetching && isEmpty(data) && (
          <EmptyTable
            description={
              <Text fontSize="14px" mb="20px">
                Not sure how to buy a number? <br /> Visit our{' '}
                <Link
                  as={RouteLink}
                  target="_blank"
                  to={`${SUPPORT_LINK}`}
                  variant="inline">
                  Help Center
                </Link>
              </Text>
            }
            title={
              <Text color="secondary.400" fontSize="14px">
                You have no active phone numbers
              </Text>
            }
          />
        )}
      </Box>
      <UnableDeleteModal
        action={
          <BuyNumber
            actionTitle="Buy a phone number"
            hasEnoughPhoneNumbers={hasEnoughPhoneNumbers}
            refetchUserPhones={fetchPhoneNumbers}
            requiredUserPhonesNumbers={minimumPhoneNumbersCount}
          />
        }
        content={
          <Text lineHeight="19px" textAlign="center" variant="list-style">
            You need to have at least {minimumPhoneNumbersCount} phone{' '}
            {wordPluralize(minimumPhoneNumbersCount, 'number')}. If you want to
            delete this phone number please buy another one.
          </Text>
        }
        isOpen={unableDeletePhoneNumberVisible}
        onClose={() => setUnableDeletePhoneNumberVisible(false)}
      />
      <ConfirmDeletionModal
        isLoading={isLoading || isFetching}
        isOpen={isOpen}
        title="Delete phone number"
        width={378}
        onClose={onClose}
        onConfirm={handleDeletePhones}>
        Are you sure you want to delete this number? You will no longer be able
        to send or receive any messages from it.
      </ConfirmDeletionModal>
      <DeleteErrorModal
        error={mutateError}
        isOpen={errorModal.isOpen}
        phone={data.find((phone) => phone?.id === selectedPhoneNumber)}
        onClose={() => {
          navigate(ROUTES.campaigns);
          errorModal.onClose();
          resetSelected();
        }}
      />
    </>
  );
};
