import { Box, Button, UseDisclosureProps } from '@chakra-ui/react';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { FC, useState } from 'react';

import Popup from '@app/components/Popup';
import SelectField from '@app/components/SelectField';
import { CountableTextarea } from '@app/widgets/CountableTextarea';
import { catchErrorLog } from '@app/utils/logger';

import { voiceTypeOptions } from '../../lib/config';
import { formInitialValues } from './lib/form';
import { voicemailSchema } from './lib/schema';
import { VoicemailFormValues } from './lib/types';

interface CreateVoicemailProps extends UseDisclosureProps {
  defaultValues?: VoicemailFormValues;
  onSave: (values: VoicemailFormValues) => Promise<void>;
}

export const CreateVoicemail: FC<CreateVoicemailProps> = ({
  defaultValues,
  isOpen,
  onClose,
  onSave,
}) => {
  const [localValues] = useState(defaultValues);

  const handleSubmit = async (
    values: VoicemailFormValues,
    { setSubmitting }: FormikHelpers<VoicemailFormValues>,
  ) => {
    setSubmitting(true);
    try {
      await onSave(values);
    } catch (error) {
      catchErrorLog(error, 'CreateAutoReply/handleSubmit');
      setSubmitting(false);
    }
  };

  return (
    <Popup
      blockScrollOnMount
      closeOnOverlayClick
      isOpen={isOpen}
      maxW="400px"
      title={localValues.text ? 'Edit voicemail' : 'Create voicemail'}
      onClose={onClose}>
      <Box margin="20px 40px">
        <Formik
          initialValues={{ ...formInitialValues, ...defaultValues }}
          validationSchema={voicemailSchema}
          onSubmit={handleSubmit}>
          {({
            dirty,
            values,
            initialValues,
            errors,
            setFieldValue,
            isSubmitting,
          }) => (
            <Form>
              <Box marginBottom="20px">
                <SelectField
                  name="voiceType"
                  options={voiceTypeOptions}
                  placeholder="Select voice"
                  value={voiceTypeOptions.find(
                    ({ value }) => value === values.voiceType,
                  )}
                  onChange={async ({ value }) => {
                    await setFieldValue('voiceType', value);
                  }}
                />
              </Box>

              <Box marginBottom="20px">
                <CountableTextarea
                  maxHeight="260px"
                  maxLength={500}
                  name="text"
                  placeholder="Write a Voicemail greeting here..."
                />
              </Box>

              <Button
                isDisabled={
                  !dirty || !!Object.keys(errors).length || isSubmitting
                }
                isLoading={isSubmitting}
                type="submit"
                variant="primary"
                width="100%">
                {initialValues.text ? 'Confirm' : 'Create record'}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Popup>
  );
};
