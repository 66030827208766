import { HStack, Skeleton } from '@chakra-ui/react';
import React from 'react';
import { colors } from '@app/theme/colors';

export const SkeletonPrepare = () => {
  return (
    <HStack alignItems="center" m="20px 30px" spacing={6}>
      <Skeleton
        endColor={colors.cultured}
        height="40px"
        startColor={colors.mystic}
        width="70px"
      />
      <Skeleton
        endColor={colors.cultured}
        height="20px"
        startColor={colors.mystic}
        width="190px"
      />
    </HStack>
  );
};
