import React, { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { Button, Flex, Switch, Text, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { Header } from '@app/types/Contact';
import useActivateAccountGuard from '@app/hooks/useActivateAccountGuard';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';

import { ChargeModal } from '@app/components/ChargeModal';
import {
  GetSkiptraceQuery,
  RunSkiptraceMutationFn,
  SkiptracePredefinedField,
  useUpdateSkiptraceMutation,
} from '@app/api/gql/generated-types';
import { AddNewCardModal } from '@app/components/next/moleculas/AddNewCardModal/AddNewCardModal';
import PaymentModal from '../Modal/PaymentModal';
import ValidationModal from '../Modal/ValidationModal';
import ConfirmSkiptrace from '../Modal/ConfirmSkiptrace';
import LessContactsModal from '../Modal/LessContactsModal';

interface PrepareSkiptraceActionsProps {
  contactListHeader: string[];
  contactsCount: number;
  contactListId: string;
  openVerificationModal: () => void;
  skiptraceData: GetSkiptraceQuery['getSkiptrace'];
  runSkiptraceMutation: RunSkiptraceMutationFn;
}

export const PrepareSkiptraceActions: FC<PrepareSkiptraceActionsProps> = ({
  contactListHeader,
  contactsCount,
  contactListId,
  skiptraceData,
  runSkiptraceMutation,
  openVerificationModal,
}) => {
  const account = useCurrentAccountData();
  const [arrayErrors, setArrayErrors] = useState<string[]>([]);

  const [useCache, setUseCache] = useState(true);

  const paymentModalProps = useDisclosure();
  const rechargeModal = useDisclosure();
  const validationModalProps = useDisclosure();
  const confirmModalProps = useDisclosure();
  const lessContactsModalProps = useDisclosure();
  const addCardModalProps = useDisclosure();

  const [updateSkiptraceMutation] = useUpdateSkiptraceMutation();

  const onChangeExcludeFirstRow = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const { checked } = e.target;
      await updateSkiptraceMutation({
        variables: {
          updateSkiptraceId: contactListId,
          input: {
            fileName: skiptraceData?.fileName,
            excludeFirstRow: checked,
          },
        },
        optimisticResponse: {
          updateSkiptrace: {
            ...skiptraceData,
            excludeFirstRow: checked,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactListId, skiptraceData],
  );

  const validationHeader = useCallback(() => {
    const errorsArr = [];

    if (!contactListHeader.includes(SkiptracePredefinedField.FIRST_NAME)) {
      errorsArr.push(Header.FIRST_NAME);
    }

    if (!contactListHeader.includes(SkiptracePredefinedField.LAST_NAME)) {
      errorsArr.push(Header.LAST_NAME);
    }

    if (
      !contactListHeader.includes(SkiptracePredefinedField.MAILING_ADDRESS) &&
      !contactListHeader.includes(SkiptracePredefinedField.PROPERTY_ADDRESS)
    ) {
      errorsArr.push(`${Header.MAILING_ADDRESS} or ${Header.PROPERTY_ADDRESS}`);
    }

    if (isEmpty(errorsArr)) {
      // await onListsApplyHeaders();
      paymentModalProps.onOpen();
    } else {
      setArrayErrors(errorsArr);
      validationModalProps.onOpen();
    }
  }, [contactListHeader, paymentModalProps, validationModalProps]);

  const onConfirmSkiptrace = useCallback(() => {
    paymentModalProps.onClose();
    confirmModalProps.onOpen();
  }, [confirmModalProps, paymentModalProps]);

  const contactsCountWithExcludeRow = useMemo(
    () =>
      skiptraceData?.excludeFirstRow ? contactsCount - 1 : contactsCount || 0,
    [contactsCount, skiptraceData?.excludeFirstRow],
  );

  const onSubmit = useCallback(
    () =>
      contactsCountWithExcludeRow >= 5
        ? validationHeader()
        : lessContactsModalProps.onOpen(),
    [contactsCountWithExcludeRow, lessContactsModalProps, validationHeader],
  );

  const onOpenPayment = useCallback(() => {
    paymentModalProps.onClose();
    rechargeModal.onOpen();
  }, [paymentModalProps, rechargeModal]);

  const onRechargeClose = useCallback(() => {
    paymentModalProps.onOpen();
    rechargeModal.onClose();
  }, [paymentModalProps, rechargeModal]);

  const guardActive = useActivateAccountGuard(onSubmit);
  const guardAction = account?.isSubAccount ? guardActive : onSubmit;

  return (
    <>
      <Flex mb="20px" pl="30px">
        <Button
          height="40px"
          minWidth="70px"
          variant="primary"
          onClick={guardAction}>
          Next
        </Button>
        <Flex alignItems="center" justify="space-start" ml="32px" w="100%">
          <Switch
            isChecked={skiptraceData?.excludeFirstRow ?? false}
            variant="general"
            onChange={onChangeExcludeFirstRow}
          />
          <Text ml="10px">Exclude the first row</Text>
        </Flex>
      </Flex>
      {paymentModalProps.isOpen && (
        <PaymentModal
          contacts={contactsCountWithExcludeRow}
          isOpen={paymentModalProps.isOpen}
          nextModalStep={onConfirmSkiptrace}
          setUseCache={setUseCache}
          title="Payment"
          useCache={useCache}
          onClose={paymentModalProps.onClose}
          onOpenPayment={onOpenPayment}
        />
      )}
      <ChargeModal
        isOpen={rechargeModal.isOpen}
        title="Recharge"
        onClose={onRechargeClose}
      />
      <AddNewCardModal
        isOpen={addCardModalProps.isOpen}
        openPaymentModalStep={paymentModalProps.onOpen}
        onClose={addCardModalProps.onClose}
      />
      <ValidationModal
        isOpen={validationModalProps.isOpen}
        title="Notification"
        validationErrors={arrayErrors}
        onClose={validationModalProps.onClose}
      />
      <ConfirmSkiptrace
        contactExcludeFirstRow={skiptraceData?.excludeFirstRow}
        contactListId={contactListId}
        contacts={contactsCountWithExcludeRow}
        isOpen={confirmModalProps.isOpen}
        openVerificationModal={openVerificationModal}
        runSkiptraceMutation={runSkiptraceMutation}
        title="Confirmation"
        useCache={useCache}
        onClose={confirmModalProps.onClose}
      />

      <LessContactsModal
        isOpen={lessContactsModalProps.isOpen}
        onClose={lessContactsModalProps.onClose}
      />
    </>
  );
};
