import { isEmpty } from 'lodash/fp';
import {
  AFFILIATE_ID_COOKIE_PARAM,
  AFFILIATE_ID_QUERY_PARAM,
} from '@app/constants/configuration';
import {
  getCookieParam,
  removeCookieParam,
  setCookie,
} from '@app/utils/cookieStorageUtils';
import { getLogger } from '@app/utils/logger';
import { getQueryParam } from '@app/utils/queryParamUtils';

const LOG = getLogger('utils/affiliateUtils');

/**
 * The base URL for referral links.
 *
 * @type {string}
 */
export const REFERRAL_LINK_BASE = import.meta.env.VITE_BASE_REFERER_LINK;
/**
 * STD_REFERRAL_CODE is a variable that represents a referral code.
 *
 * @type {string}
 * @constant
 * @description This variable stores the value 'customcode', which is used as a referral code.
 */
const STD_REFERRAL_CODE: string = 'customcode';

/**
 * Generates an affiliate link based on the provided referral code.
 *
 * @param {string} referralCode - The referral code associated with the affiliate.
 * @returns {string} - The generated affiliate link.
 */
export const generateAffiliateLink = (referralCode: string): string => {
  const code = isEmpty(referralCode) ? STD_REFERRAL_CODE : referralCode;

  return `${REFERRAL_LINK_BASE}${code}`;
};

/**
 * Prepares referral link for clipboard by encoding it.
 *
 * @param {string} referralLink - The referral link to be encoded.
 * @return {string} - The encoded referral link.
 */
export const prepareReferralLinkForClipboard = (referralLink: string): string =>
  window.encodeURI(referralLink);

/**
 * Removes the protocol from a referral link.
 *
 * @param {string} referralId - The referral link with the protocol.
 * @returns {string} - The referral link without the protocol.
 */
export const referralLinkWithoutProtocol = (referralId: string): string =>
  referralId.replace('https://', '');

/**
 * Retrieves the referral code from the URL query parameters.
 *
 * @returns {string | null} Referral code if available, otherwise null.
 */
export const getReferralCode = (): string | null => {
  try {
    const params = new URLSearchParams(window.location.search);
    const cookieReferralCode = getCookieParam(AFFILIATE_ID_COOKIE_PARAM);
    const queryReferralCode = params.get(AFFILIATE_ID_QUERY_PARAM) || null;

    return queryReferralCode || cookieReferralCode || null;
  } catch (e) {
    LOG.error('Error getting referral code', e);

    return null;
  }
};

/**
 * Sets the referral code in the affiliate ID cookie.
 *
 * @param {string} referralCode - The referral code to be set.
 * @returns {void}
 */
export const setReferralCode = (referralCode: string): void => {
  try {
    setCookie(AFFILIATE_ID_COOKIE_PARAM, referralCode);
  } catch (e) {
    LOG.error('Error setting referral code', e);
  }
};

/**
 * Updates the referral code if it exists.
 *
 * @returns {void}
 */
export const upsertReferralCode = (): void => {
  const code = getQueryParam(AFFILIATE_ID_QUERY_PARAM);

  if (!isEmpty(code)) {
    LOG.debug('Setting referral code', code);
    setReferralCode(code);

    return;
  }

  LOG.debug('No referral code found');
};

/**
 * Removes the referral code from the affiliate ID cookie.
 *
 * @function
 * @name unsetReferralCode
 * @returns {void}
 *
 * @throws {Error} If an error occurs while unsetting the referral code.
 */
export const unsetReferralCode = (): void => {
  try {
    removeCookieParam(AFFILIATE_ID_COOKIE_PARAM);
    LOG.debug('Unset referral code');
  } catch (e) {
    LOG.error('Error unsetting referral code', e);
  }
};
