import React, { FC, useMemo, useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { FileRejection, useDropzone } from 'react-dropzone';

import {
  baseStyle,
  acceptStyle,
  rejectStyle,
  disabledStyle,
} from '@app/components/DragAndDrop/dndStyle';
import Loader from '@app/components/Loader';
import { AcceptedImageFormats } from '@app/types/fileExtensions';

const MAX_FILE_SIZE = 1000000;

interface Props {
  onDrop: (files: File[]) => void;
  fileName?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const DragAndDropInput: FC<Props> = ({
  onDrop,
  fileName = '',
  loading = false,
  disabled = false,
}) => {
  const [name, setName] = useState(fileName);

  const handleDrop = (files: File[], errors: FileRejection[]) => {
    if (errors.length) {
      setName('');
    } else {
      setName(files[0].name);
      onDrop(files);
    }
  };

  const {
    getRootProps,
    getInputProps,
    open,
    fileRejections,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: handleDrop,
    accept: {
      [AcceptedImageFormats.ALL]: [],
    },
    maxSize: MAX_FILE_SIZE,
    noClick: false,
    disabled,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(disabled ? disabledStyle : {}),
    }),
    [disabled, isDragAccept, isDragReject],
  );

  return (
    <Flex
      border="1px dashed"
      borderRadius="22px"
      direction="column"
      h="60px"
      px="20px"
      py="12px"
      w="345px"
      onClick={open}
      {...getRootProps(style)}>
      <input type="file" {...getInputProps()} />
      {name ? (
        <Flex justify="space-between" w="100%">
          <Flex>
            <Box maxWidth="230px">
              <Text
                overflow="hidden"
                textOverflow="ellipsis"
                variant="secondary-text"
                whiteSpace="nowrap">
                {name} &nbsp;
              </Text>
            </Box>
            <Button variant="link">change</Button>
          </Flex>
          {loading && <Loader size="sm" />}
        </Flex>
      ) : (
        <>
          <Flex w="100%">
            <Text variant="secondary-text">
              Drag & drop your files here or&nbsp;
            </Text>
            <Button isDisabled={disabled} variant="link">
              browse
            </Button>
          </Flex>
          <Text
            color={fileRejections[0] ? 'error' : 'silver'}
            mb="0px"
            mt="6px"
            mx="0px"
            variant="label"
            w="100%">
            Maximum upload file size: 1 MB.
          </Text>
        </>
      )}
    </Flex>
  );
};
