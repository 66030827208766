import React, { FC } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';

import {
  FindMarketingPopupsDocument,
  MarketingPopup,
  useRemoveMarketingPopupMutation,
} from '@app/api/gql/generated-types';
import Popup from '@app/components/Popup';
interface RemoveMarketingPopupTypes {
  removeConfirmModal: {
    isOpen: boolean;
    onClose: () => void;
  };
  entity: MarketingPopup;
}

export const RemoveMarketingPopup: FC<RemoveMarketingPopupTypes> = ({
  removeConfirmModal,
  entity,
}) => {
  const [mutate, { loading }] = useRemoveMarketingPopupMutation({
    refetchQueries: [FindMarketingPopupsDocument],
  });

  const onSubmit = async () => {
    await mutate({
      variables: {
        removeMarketingPopupId: entity.id,
      },
    });
    removeConfirmModal.onClose();
  };

  return (
    <Popup
      closeOnOverlayClick
      isOpen={removeConfirmModal.isOpen}
      maxW="374px"
      title="Delete Popup"
      onClose={removeConfirmModal.onClose}>
      <Box p="25px">
        <Text
          color="main.400"
          fontSize="14px"
          fontWeight={400}
          mb="20px"
          textAlign="center">
          Are you sure you want to delete this popup{' '}
          <Text as="span" color="secondary.400">
            {entity?.title}
          </Text>
          ?
        </Text>
        <Flex flexDirection="row" justifyContent="center">
          <Button
            mr="20px"
            variant="outlined"
            onClick={removeConfirmModal.onClose}>
            Cancel
          </Button>
          <Button isLoading={loading} variant="primary" onClick={onSubmit}>
            Delete
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};
