import React, { FC, ReactNode } from 'react';
import {
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  IconButton,
  PlacementWithLogical,
  PopoverProps,
  Text,
  Portal,
} from '@chakra-ui/react';

import FilterArrowIcon from '@app/icons/filter-arrow-icon.svg?react';
import { colors } from '@app/theme/colors';

interface PopoverContentProps extends PopoverProps {
  filterTitle: string | ReactNode;
  filterValue: string | ReactNode;
  isOpen: boolean;
  isDisabled?: boolean;
  tooltip?: ReactNode;
  offset?: [number, number];
  placement?: PlacementWithLogical;
  onClose: () => void;
  onOpen: () => void;
  showArrow?: boolean;
}

const RenderPopoverContent: FC<PopoverContentProps> = ({
  filterTitle,
  filterValue,
  children,
  isOpen,
  onOpen,
  onClose,
  tooltip = null,
  offset = [0, 0],
  isDisabled = false,
  placement = 'bottom-end',
  showArrow = false,
}) => {
  return (
    <Popover
      autoFocus
      computePositionOnMount
      arrowShadowColor="0px 6px 18px rgba(139, 139, 139, 0.25)"
      arrowSize={15}
      isLazy={true}
      isOpen={!isDisabled && isOpen}
      offset={offset}
      openDelay={200}
      placement={placement}
      trigger="hover"
      onClose={onClose}
      onOpen={onOpen}>
      <PopoverTrigger>
        <Flex
          alignItems="center"
          color={isDisabled ? 'secondary.300' : 'main.400'}
          cursor={isDisabled ? 'default' : 'pointer'}
          fontSize="14px"
          fontWeight={400}
          transition="all .3s ease"
          userSelect="none"
          width="100%">
          <Text
            color={isDisabled ? 'secondary.300' : 'osloGray'}
            marginRight="2px">
            {filterTitle}
          </Text>{' '}
          {filterValue}
          {isDisabled && tooltip}
          <IconButton
            _active={{ bg: colors.transparent }}
            _focus={{ outline: 'none' }}
            _hover={{ bg: colors.transparent }}
            aria-label="open popover"
            bg={colors.transparent}
            color={isDisabled ? 'secondary.300' : colors.main[400]}
            cursor={isDisabled ? 'default' : 'pointer'}
            icon={<FilterArrowIcon />}
            ml="0px"
            mt="2px"
            size="xs"
            transform={
              !isDisabled && isOpen ? 'rotate(270deg)' : 'rotate(90deg)'
            }
            transition="all .3s ease"
          />
        </Flex>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          _focus={{ boxShadow: '(0px 6px 18px rgba(139, 139, 139, 0.25))' }}
          border={0}
          borderRadius="9px"
          boxShadow="0px 6px 18px rgba(139, 139, 139, 0.25)"
          outline="0"
          p="12px"
          rounded="xl"
          width="auto">
          <>
            {showArrow && <PopoverArrow />}
            {children}
          </>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default RenderPopoverContent;
