import React, { FC } from 'react';
import { Box, Link, UseDisclosureProps } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import ROUTES from '@app/utils/routes';
import { DisclaimerBox } from '../next/moleculas/DisclaimerBox';

export const TrialWarning: FC<Partial<UseDisclosureProps>> = ({ onClose }) => {
  const { isMessagePlanTrialing } = useCurrentAccountData();

  const handleClose = () => {
    const element = document.getElementById('planInfo');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    onClose();
  };

  if (!isMessagePlanTrialing) {
    return null;
  }
  return (
    <Box marginBottom="20px">
      <DisclaimerBox
        backgroundColor="zircon"
        color="secondary.400"
        fontSize="12px"
        maxWidth="315">
        <Box fontSize="12px" lineHeight="14px" ml="8px">
          Your trial includes the ability to upload and text{' '}
          <strong>100 contacts</strong>. To unlock the full ability to deliver
          messages{' '}
          <Link
            as={RouteLink}
            to={{
              pathname: `${ROUTES.settingsMembership}`,
            }}
            variant="link"
            onClick={handleClose}>
            please upgrade your plan
          </Link>
          .
        </Box>
      </DisclaimerBox>
    </Box>
  );
};
