export const stickyHeadStyles = {
  position: 'sticky',
  top: '0',
  zIndex: '9',
  backgroundColor: 'catskillWhite',
  borderTop: '0px',
  _last: {
    left: 'auto',
    right: '0',
  },
};

export const stickyRowStyles = {
  left: '-2px',
  position: 'sticky',
  backgroundColor: 'catskillWhite',
  zIndex: '8',
  _last: {
    left: 'auto',
    right: '0',
  },
};
