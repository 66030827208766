import { Box } from '@chakra-ui/react';
import isBoolean from 'lodash/isBoolean';
import React, { FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import { MultiValue } from 'react-select';
import { createPaginationVariables } from '@app/api/apollo-pagination';
import {
  AccountDocument,
  GroupFragmentFragment,
  GroupView,
  useAccountQuery,
  useFindAlertGroupsLazyQuery,
  useFindAlertGroupsQuery,
  useUpdateMessagingProfileMutation,
} from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import MultiSelectField from '@app/components/MultiSelectField';

interface SelectedGroup extends GroupView {
  value: GroupView['id'];
  label: string;
  isChecked?: boolean;
}

export const Groups: FC = () => {
  const { data: { account } = {} } = useAccountQuery();
  const { messagingProfile } = account;

  const [updateMessagingProfile] = useUpdateMessagingProfileMutation({
    refetchQueries: [
      {
        query: AccountDocument,
      },
    ],
    context: {
      useApolloNetworkStatus: true,
    },
  });

  const alertGroups = useMemo(() => {
    return isBoolean(messagingProfile?.phoneSettings?.alertFilter?.groups)
      ? []
      : messagingProfile?.phoneSettings?.alertFilter?.groups;
  }, [messagingProfile?.phoneSettings?.alertFilter?.groups]);

  const {
    data: { findGroups: findGroupsByIds } = { findGroups: null },
    loading: selectedIsLoading,
  } = useFindAlertGroupsQuery({
    variables: {
      filter: {
        ids: alertGroups ?? [],
      },
    },
    skip: !alertGroups?.length,
  });

  const [
    getGroups,
    { data: { findGroups } = { findGroups: null }, fetchMore, networkStatus },
  ] = useFindAlertGroupsLazyQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
  });

  const { isLoading } = useApolloLoadingStatus(networkStatus);

  const fetchNextPage = () => fetchMore(createPaginationVariables(findGroups));

  const [groupsState, setGroupsState] = useState<
    Partial<GroupFragmentFragment>[]
  >([]);

  useEffect(() => {
    if (findGroupsByIds?.total) {
      setGroupsState(findGroupsByIds?.items);
    }
  }, [findGroupsByIds?.items, findGroupsByIds?.total]);

  const options = useMemo(() => {
    const selectedGroups = groupsState ?? [];
    const groups = findGroups?.items ?? [];
    if (!groups) {
      return [];
    }

    const filteredCampaigns = groups.filter(
      ({ id }) => !selectedGroups?.map((val) => val.id)?.includes(id),
    );

    return [
      ...(selectedGroups || []).map((group) => ({
        ...group,
        isChecked: true,
      })),
      ...filteredCampaigns,
    ];
  }, [findGroups?.items, groupsState]) as SelectedGroup[];

  const selectedGroups = useMemo(
    () => options?.filter((campaign) => campaign.isChecked),
    [options],
  );
  const onSubmit = () => {
    void updateMessagingProfile({
      variables: {
        input: {
          phoneSettings: {
            alertFilter: {
              groups: groupsState?.map((item) => item.id),
            },
          },
        },
      },
    });
  };
  const onChange = (values: MultiValue<SelectedGroup>) => {
    setGroupsState(values as SetStateAction<Partial<SelectedGroup>[]>);
  };

  return (
    <Box maxWidth="408px" width="100%">
      <MultiSelectField
        isMulti
        autoFocus={false}
        closeMenuOnSelect={false}
        getOptionLabel={(option: GroupView) => option.name}
        getOptionValue={(option) => option.id}
        hideSelectedOptions={false}
        isClearable={false}
        isLoading={isLoading || selectedIsLoading}
        isSearchable={false}
        options={options}
        placeholder="Enter a group"
        value={selectedGroups}
        onChange={onChange}
        onMenuClose={onSubmit}
        onMenuOpen={() => getGroups()}
        onMenuScrollToBottom={fetchNextPage}
      />
    </Box>
  );
};
