import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Checkbox, Flex, Text } from '@chakra-ui/react';

const termsLink = 'https://smartercontact.com/terms-and-conditions';

export const TosConfirmCheckbox: FC = () => {
  const { handleChange, values } = useFormikContext<{
    isAgree: boolean;
  }>();

  return (
    <Flex direction="row" justifyContent="left" mb="24px" width="100%">
      <Checkbox
        checked={values.isAgree}
        name="isAgree"
        variant="primary"
        onChange={handleChange}>
        <Text as="span" color="main.400" data-cy="i-agree-terms-of-use-button">
          I have read and agree to the
        </Text>
        <Text as="a" color="primary.600" href={termsLink} target="_blank">
          {' '}
          Terms of Service
        </Text>
      </Checkbox>
    </Flex>
  );
};
