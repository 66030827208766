import { isEmpty } from 'lodash/fp';
import {
  OwnPhoneType,
  useFindOwnPhonesReportQuery,
  Scope,
} from '@app/api/gql/generated-types';
import { processNumbers } from './useUserPhoneNumbers';

const MIN_PHONE_NUMBERS_COUNT = 3;
const MIN_PHONE_NUMBERS_DIALER_COUNT = 5;
const PHONE_PAGINATION_SIZE = 50;

export const useUserPhoneNumbersReport = (scope: Scope) => {
  const {
    data: { findOwnPhones } = {},
    loading,
    refetch,
  } = useFindOwnPhonesReportQuery({
    variables: {
      pagination: {
        limit: PHONE_PAGINATION_SIZE,
      },
      filter: {
        type: OwnPhoneType.LOCAL,
        scope,
      },
    },
  });

  const {
    ownedNumbers,
    isAccountHasPhones,
    lackPhoneNumbersCount,
    lackOwnedPhoneNumbersCount,
    phoneNumbersCount,
  } = processNumbers(findOwnPhones?.items);

  return {
    refetch,
    loading,
    ownedNumbers,
    isAccountHasPhones,
    lackPhoneNumbersCount,
    lackOwnedPhoneNumbersCount,
    phoneNumbersCount,
    ownedNumbersCount: ownedNumbers.length,
    minimumPhoneNumbersCount: MIN_PHONE_NUMBERS_COUNT,
    minimunPhoneNumberDialerCount: MIN_PHONE_NUMBERS_DIALER_COUNT,
    hasEnoughPhoneNumbers: phoneNumbersCount >= MIN_PHONE_NUMBERS_COUNT,
    hasEnoughPhoneNumbersDialer:
      phoneNumbersCount >= MIN_PHONE_NUMBERS_DIALER_COUNT,
    hasEnoughOwnedPhoneNumbers: ownedNumbers.length >= MIN_PHONE_NUMBERS_COUNT,
    hasPhoneNumbers: !isEmpty(ownedNumbers),
  };
};
