import { getLogger } from '@app/utils/loggerUtils';

const LOG = getLogger('hubSpotConversationUtils');

const fallback = () => {
  LOG.debug('Falling back to email');
  const email = 'support@smartercontact.com';
  const mailtoLink = `mailto:${email}`;
  window.location.href = mailtoLink;
};

export const showHubSpotConversationAgent = () => {
  if (!window.HubSpotConversations) {
    LOG.error('HubSpotConversations widget is not available');
    fallback();
    return;
  }

  if (typeof window?.HubSpotConversations?.widget?.open !== 'function') {
    fallback();
    return;
  }
  if (typeof window?.HubSpotConversations?.widget?.status !== 'function') {
    fallback();
    return;
  }
  const status = window.HubSpotConversations.widget.status();
  if (!status.loaded) {
    LOG.error('HubSpotConversations widget is not loaded');
    fallback();
    return;
  }
  window.HubSpotConversations.widget.open();
};
