const video = {
  calendar: 'https://www.loom.com/embed/a816e6a6717b4cb2bd74bc454cb7e6c0',
  contacts: {
    phoneVerification:
      'https://www.loom.com/embed/9227cb9f9c8d4b8d9a798c46f02e0441',
    groups: 'https://www.loom.com/embed/d78fba01752c493b9b6396b2f3ab8c16',
  },
  dialer: {
    getStarted: 'https://www.loom.com/embed/0f6e3388a5f147f4b23d6b838db25ce2',
  },
  campaigns: {
    creation: 'https://www.loom.com/embed/8749b1c4cc1848b6a030e7acecc2db54',
    createConfirmation:
      'https://www.youtube.com/watch?v=3yAO1UOXdGU&ab_channel=SmarterContact',
    messageTemplatesPreview: 'https://www.youtube.com/watch?v=O9_G1BNCPTA',
    outgoingNumber:
      'https://www.loom.com/embed/a6ba95b0206e48eaae293eb28546bfec?autoplay=1',
    creatingInitialMessages:
      'https://www.loom.com/embed/7a7264a74b2b4ccdb7f68a8a16b46a12',
  },
  keywordCampaigns: {
    explain: 'https://www.loom.com/embed/2b9066a73f4645b9b812570b2b3143bb',
  },
  settings: {
    zapier: 'KD-c5rvk0CA',
    REISift: 'https://www.loom.com/embed/5032f91a85434d21944ba994a93ae4f7',
    trial: {
      explain: 'https://rumble.com/v1ksian-sending-sms-during-your-trial.html',
    },
    messageFlow: 'https://www.loom.com/embed/23744364cbbc42d2ac3ed7a985e1d311',
    brandRegistrationSubAccount:
      'https://www.loom.com/embed/f85220ff27404c4ea96122020a0eb8fc', //need new video
    brandRegistration:
      'https://www.loom.com/embed/7a52899e0ef44ca6b065be821b7d4ea4',
    DLCStatus: {
      approved: 'https://www.loom.com/embed/49c04d6dc75c4cada130ccce1aa2171e',
      pendingAndReview: '9Vv4tnYxwhg',
      secondary: 'https://www.loom.com/embed/6f7769d64c8d419483928cb224b5eb2b',
    },
    resources: {
      clientSpotlights:
        'https://www.youtube.com/watch?v=mlKtBu681PA&list=PLOnAvnM2HmJUbgiCcKGP-Vw6BfP3TkZ1l',
      wistiaDirectory: 'https://fast.wistia.com/embed/channel/1x1nlztqpj',
    },
  },
  macros: {
    emptyState: 'O9_G1BNCPTA',
  },
  skiptrace: {
    emptyState: 'https://www.loom.com/embed/572cd719d2ab49aba5f48e399962ca0e',
    payment: 'https://www.loom.com/share/dd3d117ed7c54d069c234beea9bbd1ac',
  },
  affiliate: {
    program: 'kByb5hWviMk',
  },
  welcomeVideo:
    'https://www.loom.com/embed/6119c28722614b19a8691f8156f0e548?autoplay=1',
  newRelease: 'https://www.loom.com/embed/30103c1ea6cc457d846592bb1a90efff',
  trial: {
    welcome: 'https://www.loom.com/embed/bae23092600545b1bd9e037acbe364b2',
  },
};
export default video;
