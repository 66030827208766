import React, { FC } from 'react';
import { Container } from '@chakra-ui/react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useProcessInitialQueryParams } from '@app/hooks/useProcessInitialQueryParams';
import Navigation from './navigation/Navigation';
import CypressBindings from './components/CypressBindings';
import OfflineMode from './components/OfflineMode';
import FirebaseProvider from './providers/FirebaseProvider';
import { useOnlineStatus } from './hooks/useOnlineStatus';
import { ApiProvider } from './providers/ApiProvider';
import { registerCustomValidators } from './schemas/custom-methods';
import { BannerProvider } from './providers/BannerProvider';

const Root: React.FC = () => {
  const isOffline = !useOnlineStatus();

  if (isOffline) {
    return <OfflineMode />;
  }

  return (
    <>
      <CypressBindings.ExportFirebase />
      <CypressBindings.ExportRouter />

      <Container
        backgroundColor="catskillWhite"
        height={['100%', 'calc( 100vh)']}
        maxWidth="100%"
        overflow="hidden"
        p="0">
        <Navigation />
      </Container>
    </>
  );
};

const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

export const App: FC = () => {
  useProcessInitialQueryParams();
  registerCustomValidators();

  return (
    // <MaintenanceModeProvider> //maintenance mode
    <FirebaseProvider>
      <ApiProvider>
        <HelmetProvider>
          <BannerProvider>
            <RouterProvider router={router} />
          </BannerProvider>
        </HelmetProvider>
      </ApiProvider>
    </FirebaseProvider>
    // </MaintenanceModeProvider>
  );
};
