const checkFileNameHasExtension = (fileName: string, fileExtension: string) => {
  return fileName.endsWith(fileExtension);
};

export const appendFileExtension = (
  fileName: string,
  fileExtension: string,
) => {
  if (checkFileNameHasExtension(fileName, fileExtension)) {
    return fileName;
  }
  return `${fileName}.${fileExtension}`;
};
