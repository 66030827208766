import React, { FC } from 'react';
import { Flex, Box, Text, Link, useDisclosure, Button } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';

import { SUPPORT_LINK } from '@app/utils/routes';
import EmptyListIcon from '@app/icons/contacts/empty-list-icon.svg?react';
import LoomPlayer from '@app/components/LoomPlayer';
import video from '@app/utils/videoLinks';
import { AllContactsTopSection } from '../content/AllContacts/GroupButtons';

export const GroupsEmpty: FC = () => {
  const videoModal = useDisclosure();

  return (
    <Flex
      alignItems="center"
      direction="column"
      height="100%"
      justifyContent="center">
      <Box data-cy="all-contacts-empty-icon-button">
        <EmptyListIcon />
      </Box>
      <Box textAlign="center">
        <Box mb="15px" mt="30px">
          <Text
            color="main.400"
            fontSize="22px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="26px">
            Upload your groups
          </Text>
        </Box>
        <Box width="325px">
          <Text
            as="p"
            color="main.400"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="normal"
            line-height="20px"
            textAlign="center">
            Not sure where to start?&nbsp;
            <Button
              as={Link}
              variant="linkSecondary"
              onClick={videoModal.onOpen}>
              Watch this video
            </Button>
            &nbsp;or <br />
            visit our&nbsp;
            <Link
              as={RouteLink}
              target="_blank"
              to={SUPPORT_LINK}
              variant="inline">
              Help Center
            </Link>
          </Text>
        </Box>
        <AllContactsTopSection isContactsEmpty />
      </Box>
      <LoomPlayer
        isOpenModal={videoModal.isOpen}
        link={video.contacts.groups}
        onCloseModal={videoModal.onClose}
      />
    </Flex>
  );
};
