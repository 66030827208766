import React, { FC } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';

import { DragAndDrop } from '@app/components/DragAndDrop';

const SkiptraceEmpty: FC = () => {
  return (
    <Box h="100%" overflow="scroll">
      <Text color="main.400" fontSize="18px" fontWeight="500" p="30px 30px 0">
        Skiptrace
      </Text>
      <Flex
        alignItems="center"
        direction="column"
        justifyContent="center"
        padding="16px">
        <DragAndDrop goToSkiptrace />
      </Flex>
    </Box>
  );
};

export default SkiptraceEmpty;
