import { lazy, object, string } from 'yup';

import { phoneNumberValidation } from '@app/components/PhoneNumberInput';
import { createNameValidation } from '@app/schemas/name-schema';

const validationSchema = object().shape({
  firstName: createNameValidation('First Name').required(''),
  lastName: createNameValidation('Last Name').required(''),
  phoneNumber: phoneNumberValidation,
});

const validationSchemaExistentContact = object().shape({
  firstName: string().required(''),
  id: string().required(),
});

export const contactCreateSchema = (isNewContact: boolean) => {
  return lazy(() => {
    if (isNewContact) {
      return validationSchema;
    }
    return validationSchemaExistentContact;
  });
};
