import TagManager, { DataLayerProps, EventProps } from 'react-gtm-module';

export enum EventTypes {
  FIRST_PAYMENT_SUCCESSFUL = 'firstPaymentSuccessful',
}

//FIXME: should be great to have env variables for this
const GTMID: string = 'GTM-TZ53GGC9';
const environments = {
  production: {
    gtmId: GTMID,
  },
  staging: {
    gtmId: GTMID,
    preview: 'env-15',
    auth: 'VWYlLHdWxBLSG12jfs3djA',
  },
  development: {
    gtmId: GTMID,
    preview: 'env-12',
    auth: '_eolXqnWAixK1D10xt5PLA',
  },
};

const env = import.meta.env.MODE || 'development';

export const initGTM = () => {
  const config = environments[env as keyof typeof environments];
  TagManager.initialize(config);
};

export const trackEventTagManager = (event: EventProps) => {
  const dataLayer: DataLayerProps = {
    dataLayer: event,
  };
  TagManager.dataLayer(dataLayer);
};
