import { Operation } from '@apollo/client';

import { useApolloNetworkStatus } from '@app/api/network-status-link';

export const useNetworkStatus = () => {
  const status = useApolloNetworkStatus({
    shouldHandleOperation: (operation: Operation) =>
      operation.getContext().useApolloNetworkStatus === true,
  });
  return {
    status,
  };
};
