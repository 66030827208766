import ROUTES from './routes';

export enum AccountStatusModal {
  IS_INACTIVE = 'isInactive',
  IS_INACTIVE_PLAN = 'isInActivePlan',
  SECONDARY_ACCOUNT = 'secondaryAccount',
}

interface AccountModalTextType {
  title: string;
  description: string;
  buttonText: string;
  route: string;
  modalWidth: number;
}

/**
 * @deprecated Use the new accesbilityFeatures instead or should be migrated to permissions.
 */
export const accountModalText = {
  isInactive: {
    title: 'Select your plan',
    description:
      'To gain access to this feature you must first select your desired subscription plan.',
    buttonText: 'Choose a plan',
    route: `${ROUTES.settingsMembership}`,
    modalWidth: 320,
  },
  isInActivePlan: {
    title: 'Activate your subscription',
    description:
      'To gain this access to this feature you must first activate a Messaging plan suscription.',
    buttonText: 'Activate Subscription',
    route: `${ROUTES.settingsMembership}`,
    modalWidth: 340,
  },
  isInActiveWithDialerPlan: {
    title: 'Activate your subscription',
    description: 'To keep using Smarter Contact, activate the subscription',
    buttonText: 'Activate Subscription',
    route: `${ROUTES.settingsMembership}`,
    modalWidth: 340,
  },
  secondaryAccount: {
    title: 'Notification',
    description:
      'To reactivate your subscription and regain access to your account, contact the primary account holder',
    buttonText: 'Close',
    route: ROUTES.messenger,
    modalWidth: 372,
  },
} as Record<AccountStatusModal, AccountModalTextType>;
