import { Box } from '@chakra-ui/react';
import React, { FC } from 'react';
import MultiSelectField from '@app/components/MultiSelectField';
import {
  SelectedCampaign,
  SelectedGroup,
  SelectedLabel,
  TExportTypes,
} from '../../../../types/Export';
import { ExportMultiselectCampaign } from '../ExportMultiselectCampaign';
import { ExportMultiselectGroup } from '../ExportMultiselectGroup';
import { ExportMultiselectLabel } from '../ExportMultiselectLabel';

interface ExportItemsProps {
  contentType: typeof TExportTypes.type;
  onChange: (
    items: Array<SelectedCampaign | SelectedGroup | SelectedLabel>,
  ) => void;
}

export const ExportItems: FC<ExportItemsProps> = ({
  contentType,
  onChange,
}) => {
  return TExportTypes.match(
    contentType,
    {
      [TExportTypes.enum.Campaigns]: () => (
        <ExportMultiselectCampaign onChange={onChange} />
      ),
      [TExportTypes.enum.Groups]: () => (
        <ExportMultiselectGroup onChange={onChange} />
      ),
      [TExportTypes.enum.Labels]: () => (
        <ExportMultiselectLabel onChange={onChange} />
      ),
    },
    () => (
      <Box maxWidth="300px" width="100%">
        <MultiSelectField
          isMulti
          autoFocus={false}
          closeMenuOnSelect={false}
          hideSelectedOptions={true}
          isClearable={false}
          isDisabled={true}
          isSearchable={false}
          options={[]}
          placeholder="Select export by"
        />
      </Box>
    ),
  );
};
