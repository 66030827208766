import { DateTime, Interval } from 'luxon';
import { getLogger } from '@app/utils/logger';
import { wordPluralize } from './string';

interface FormatDateOptions {
  date: Date;
  format?: string;
  timeZone?: string;
}

const LOG = getLogger('formatDate');

/**
 * The format string for date formatting in MM/dd/yyyy format.
 *
 * Example: 12/15/2024
 */
export const DATE_FORMAT_MM_DD_YYYY = 'MM/dd/yyyy';

/**
 The format string for date formatting in LLL dd, yyyy format.

 Example: Jan 01, 2024
 */
export const DATE_FORMAT_LLL_DD_YYYY = 'LLL dd, yyyy';

/**
 * The format string for date formatting in LL/dd/yyyy format.
 *
 * Example: 12/15/2024
 */
export const DATE_FORMAT_LL_DD_YYYY = 'LL/dd/yyyy';

/**
 * The format string for date formatting in LL/dd/yy format.
 *
 * Example: 12/15/24
 */
export const DATE_FORMAT_LL_DD_YY = 'LL/dd/yy';

/**
 * The format string for date formatting in MMMM dd, yyyy format.
 *
 * Example: December 26, 2023
 */
export const DATE_FORMAT_MMMM_DD_YYYY = 'MMMM dd, yyyy';

/**
 * The format string for date formatting in "MMM dd" format.
 *
 * Example: Dec 26
 */
export const DATE_FORMAT_MMM_DD = 'MMM dd';

/**
 * The format string for date formatting in "MMM dd," format.
 *
 * Example: Dec 26,
 */
export const DATE_FORMAT_MMM_DD_COMMA = 'MMM dd,';

/**
 * The format string for date formatting in "LLL dd, yyyy hh:mm a" format.
 *
 * Example: Apr 29, 2024 12:18 PM
 */
export const DATE_FORMAT_FULL = 'LLL dd, yyyy hh:mm a';

/**
 * Formats a given date into a string using the specified format.
 * If a timeZone is provided, the date is converted to that timeZone before formatting.
 * If no timeZone is provided, the date is formatted in the local time zone.
 * @param date The date to format.
 * @param format The format string to use. Default is 'LL/dd/yy'.
 * @param timeZone The timeZone to use for formatting. Optional.
 * @returns The formatted date string.
 */
export const formatDate = ({
  date,
  format = DATE_FORMAT_LL_DD_YY,
  timeZone,
}: FormatDateOptions): string | null => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    LOG.error('Invalid date provided.');
    return null;
  }

  try {
    if (timeZone) {
      return DateTime.fromJSDate(date, { zone: timeZone }).toFormat(format);
    }

    return DateTime.fromJSDate(date).toFormat(format);
  } catch (error) {
    LOG.error('Error formatting date:', error);
    return null;
  }
};

export const formatDateFromMillis = ({
  date,
  format = DATE_FORMAT_LL_DD_YY,
  timeZone = 'system',
}: {
  date: number;
  format?: string;
  timeZone?: string;
}) => {
  if (!date) return null;

  return DateTime.fromMillis(date, { zone: timeZone }).toFormat(format);
};

export const convertToRelativeDate = (date: DateTime): string => {
  const now = DateTime.now();
  const interval = Interval.fromDateTimes(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    { day: now.startOf('day').day },
    // eslint-disable-next-line @typescript-eslint/unbound-method
    { day: now.startOf('day').plus({ days: 2 }).day },
  );
  if (interval.isBefore(date.startOf('day'))) {
    return date.toFormat(DATE_FORMAT_LL_DD_YY);
  }
  return date.setLocale('en').toRelativeCalendar({ unit: 'days' });
};

/**
 * The format string for time formatting in "hh:mm a" format.
 *
 * Example: 02:17 PM
 */
export const TIME_FORMAT_HH_MM_A = 'hh:mm a';

/**
 * Converts a date to a relative format such as '1 minute ago' or '10 minutes ago' or '5 hours ago'.
 * it is being used for the last 24 hours
 * @param date The date to convert.
 * @returns The relative formatted date string. if the hour is greater than 24 hours it calls the formatDate function
 */
export const convertToPastRelativeDate = (pastDate: Date): string => {
  const now = DateTime.now();

  const diff = now.diff(DateTime.fromJSDate(pastDate));

  const minutes = diff.as('minutes');
  const hours = diff.as('hours');

  // Determine which unit to use based on the duration
  if (hours > 24) {
    return formatDate({
      date: pastDate,
      format: DATE_FORMAT_MM_DD_YYYY,
    });
  } else if (hours >= 1) {
    const hoursPrinted = Math.floor(hours);
    return `${hoursPrinted} ${wordPluralize(hoursPrinted, 'hour')} ago`;
  } else if (minutes < 1) {
    return `Just now`;
  } else {
    const minutesPrinted = Math.floor(minutes);
    return `${minutesPrinted} ${wordPluralize(minutesPrinted, 'minute')} ago`;
  }
};
