import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';

import { PermissionActions, Permissions } from '@app/acl';
import { usePermissionAbility } from '@app/hooks/usePermissionAbility';

import { FeatureType, QuotaType } from '@app/api/gql/generated-types';
import { getMessageSubscription } from '@app/utils/subscriptionHelpers';
import { useCurrentAccountData } from './useCurrentAccountData';
import { useAccountSubscription } from './useAccountSubscription';

export const usePopupsProvider = () => {
  const { isMessageSubscriptionTrialing } = useAccountSubscription();

  const { can: canWelcomePopup } = usePermissionAbility(
    PermissionActions.INTERACTION,
    Permissions.WelcomePopup,
  );
  const account = useCurrentAccountData();
  const messageSubscription = getMessageSubscription(account);

  const currentPlan = useMemo(
    () => messageSubscription?.plan,
    [messageSubscription?.plan],
  );

  const messagesPerMonthLimit = useMemo(
    () =>
      currentPlan?.features?.find(
        (limit) => limit.type === FeatureType.CAMPAIGN_SMS,
      )?.limit ?? 0,
    [currentPlan?.features],
  );
  const monthSms = useMemo(
    () =>
      account?.quotasUsage?.find(
        (quota) => quota.type === QuotaType.CAMPAIGN_SMS,
      )?.used ?? 0,
    [account?.quotasUsage],
  );

  const percent = useMemo(
    () =>
      monthSms && messagesPerMonthLimit
        ? Math.round((monthSms * 100) / messagesPerMonthLimit)
        : 0,
    [messagesPerMonthLimit, monthSms],
  );

  const isToday = useMemo(() => {
    if (isMessageSubscriptionTrialing) {
      return (
        DateTime.fromJSDate(new Date()) >=
        DateTime.fromISO(messageSubscription?.startedAt).set({
          hour: 24,
          minute: 0,
        })
      );
    }
    return false;
  }, [messageSubscription?.startedAt, isMessageSubscriptionTrialing]);

  const isNextDayAfterRegister = useMemo(
    () =>
      !(
        DateTime.fromJSDate(new Date(account?.createdAt))
          .set({
            hour: 24,
            minute: 0,
          })
          .diffNow('seconds').seconds > 0
      ),
    [account?.createdAt],
  );

  const checkDaysSinceRegister = useCallback(
    (days: number) => {
      const { days: daysSinceRegister } = DateTime.fromJSDate(
        new Date(account?.createdAt),
      )
        .set({
          hour: 24,
          minute: 0,
        })
        .diffNow('days');

      return Math.abs(daysSinceRegister) >= days;
    },
    [account?.createdAt],
  );

  return {
    accountData: account,
    userPlan: currentPlan,
    percent,
    canWelcomePopup,
    isToday,
    isNextDayAfterRegister,
    checkDaysSinceRegister,
  };
};
