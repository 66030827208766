import React, { FC } from 'react';

import { InboxMessages } from '../components/InboxMessages';
import { MessengerFolderType } from '../types';

const InboxAll: FC = () => {
  return (
    <InboxMessages
      path="inbox/all"
      title="Inbox"
      type={MessengerFolderType.ALL}
    />
  );
};

export default InboxAll;
