import React, { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';

import Tooltip from '../../Tooltip';

export const TooltipOwnedNumbers: FC = () => {
  return (
    <Box ml="5px" overflow="hidden" w="20px">
      <Tooltip
        text={
          <Text p="5px" variant="heading-fifth">
            <strong>Active</strong> &mdash; This phone number is approved and
            ready to be used for calling.
            <br />
            <br />
            <strong>Blocked</strong> &mdash; This phone number is blocked by the
            carriers. Delete it and buy a new one.
          </Text>
        }
        widthContainer="305px"
      />
    </Box>
  );
};
