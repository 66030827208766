import React, { FC, ReactNode } from 'react';
import { Flex, Text, Image } from '@chakra-ui/react';
import NoDataIcon from '@app/icons/no-data.svg';

interface EmptyTableProps {
  image?: string;
  imageMargin?: string;
  title?: string | ReactNode;
  description?: string | ReactNode;
}

const EmptyTable: FC<EmptyTableProps> = ({
  image = NoDataIcon,
  imageMargin = '25px 0 15px 0',
  title = (
    <Text color="secondary.400" fontSize="14px">
      No data
    </Text>
  ),
  description = (
    <Text color="darkCharcoal" fontSize="14px" mt="5px">
      There is no data to show you right now
    </Text>
  ),
}) => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      h="100%"
      justifyContent="center"
      textAlign="center"
      w="100%">
      <Image alt="No Data Icon" margin={imageMargin} src={image} />
      {title}
      {description}
    </Flex>
  );
};

export default EmptyTable;
