import {
  Box,
  Checkbox,
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Tfoot,
  Center,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { DateTime } from 'luxon';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadMoreButton from '@app/components/LoadMoreButton';
import DateFormat from '@app/components/DateFormat';
import { FormatNumber } from '@app/components/FormatNumber';
import IconButtonRemove from '@app/components/IconButtonRemove';
import CheckboxChecked from '@app/icons/checkbox-checked.svg?react';
import CheckboxPartial from '@app/icons/checkbox-partial.svg?react';
import DownloadIcon from '@app/icons/download-icon.svg?react';
import StepDoneIcon from '@app/icons/step-done-icon.svg?react';
import StepDoneUncheckedIcon from '@app/icons/step-done-unchecked-icon.svg?react';
import { CloneGroupButton } from '@app/pages/Contacts/components/ActionButtons/CloneGroupButton';
import { downloadFile } from '@app/pages/Skiptrace/utils/downloadFile';
import ROUTES, { generateUrl } from '@app/utils/routes';

import {
  GroupView,
  QueryFindGroupsArgs,
  useExportGroupLazyQuery,
  useExportListLazyQuery,
} from '@app/api/gql/generated-types';
import EmptyTable from '@app/components/EmptyTable';
import { CampaignCell } from '@app/components/Table/Cells/CampaignCell';
import { TableWrapper } from '../../utils/styles';

import { CellTextTooltip } from './CellTextTooltip';
import { EmptyTableFilter } from './EmptyTableFilter';

interface GroupsTableListProps {
  items: GroupView[];
  selectedItems: string[];
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  isLoadingGroups: boolean;
  params: QueryFindGroupsArgs['filter'];
  fetchNextPage: () => void;
  onRemoveContactsList: (
    id: string,
    name: string,
    canBeRemoved: boolean,
  ) => void;
  onSelectAll: () => void;
  onSelectItem: (val: string) => void;
}

export const GroupsTableList: FC<GroupsTableListProps> = ({
  items,
  selectedItems,
  hasNextPage,
  isFetchingNextPage,
  params,
  isLoadingGroups,
  fetchNextPage,
  onRemoveContactsList,
  onSelectAll,
  onSelectItem,
}) => {
  const navigate = useNavigate();

  const [request, { loading: isLoading, variables }] = useExportGroupLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [requestList] = useExportListLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const handleExportContacts = async (
    id: string,
    name: string,
    verified: boolean,
  ) => {
    if (verified) {
      const data = await request({
        variables: {
          exportGroupId: id,
        },
      });
      downloadFile(name, data?.data?.exportGroup?.signedUrl);
    } else {
      const data = await requestList({
        variables: {
          input: {
            id,
          },
        },
      });
      downloadFile(name, data?.data?.exportList?.signedUrl);
    }
  };

  const goToPage = useCallback(
    (id: string, verified: boolean) => {
      if (verified) {
        navigate(generateUrl(ROUTES.contactsGroups, { groupId: id }), {
          state: id,
        });
      } else {
        navigate(generateUrl(ROUTES.contactEdit, { contactId: id }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <TableWrapper height="calc(100vh - 270px)">
      <Table>
        <Thead bg="white" position="sticky" top="0" zIndex="10">
          <Tr>
            <Th p="0 15px 0 30px" width="45px">
              {!isEmpty(items) ? (
                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between">
                  <Checkbox
                    icon={
                      !isEmpty(selectedItems) ? (
                        <CheckboxPartial />
                      ) : (
                        <CheckboxChecked />
                      )
                    }
                    isChecked={!isEmpty(selectedItems)}
                    variant={
                      !isEmpty(selectedItems) ? 'primaryPartial' : 'primary'
                    }
                    onChange={onSelectAll}
                  />
                </Flex>
              ) : (
                ''
              )}
            </Th>
            <Th pl="0" pr="15" />
            <Th pl="0">Group name</Th>
            <Th>Contacts</Th>
            <Th>Active chats</Th>
            <Th>Date created</Th>
            <Th>Campaigns</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {items?.map((item) => (
            <Tr
              key={item?.id}
              background={
                selectedItems.includes(item?.id) ? 'primary.100' : null
              }
              cursor="pointer"
              onClick={() => {
                if (!isLoading) {
                  goToPage(item?.id, item?.verified);
                }
              }}>
              <Td p="0 15px 0 30px">
                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onSelectItem(item?.id);
                  }}>
                  <Checkbox
                    isChecked={selectedItems.includes(item?.id)}
                    variant="primary"
                  />
                </Flex>
              </Td>
              <Td pl="0" pr="15" w="15px">
                {item?.verified ? (
                  <StepDoneIcon height="15px" width="15px" />
                ) : (
                  <StepDoneUncheckedIcon height="15px" width="15px" />
                )}
              </Td>
              <Td pl="0" width={300}>
                <CellTextTooltip text={item?.name} textLength={40} />
              </Td>
              <Td>
                <Box
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  width="50px">
                  <FormatNumber preventNegative number={item?.contacts} />
                </Box>
              </Td>
              <Td>
                <Box
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  width="50px">
                  <FormatNumber preventNegative number={item?.activeChats} />
                </Box>
              </Td>
              <Td>
                <Box
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  width="90px">
                  <DateFormat
                    date={DateTime.fromISO(item?.createdAt).toJSDate()}
                  />
                </Box>
              </Td>
              <Td>
                <Box
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  width="100px">
                  <CampaignCell
                    campaigns={item?.campaigns}
                    dialerCampaigns={item?.dialerCampaigns}
                  />
                </Box>
              </Td>
              <Td width="100px">
                <Flex
                  alignItems="center"
                  flexDirection="row"
                  justifyContent="flex-end">
                  {item.verified && (
                    <CloneGroupButton
                      canBeCloned={item.canBeCloned}
                      id={item.id}
                    />
                  )}

                  <IconButton
                    aria-label="Export Group"
                    isDisabled={
                      variables.exportGroupId === item.id && isLoading
                    }
                    isLoading={variables.exportGroupId === item.id && isLoading}
                    marginLeft={item?.verified ? '20px' : '36px'}
                    variant="ghostPlain"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await handleExportContacts(
                        item.id,
                        item.name,
                        item.verified,
                      );
                    }}>
                    <DownloadIcon />
                  </IconButton>
                  <IconButtonRemove
                    marginLeft="20px"
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveContactsList(
                        item.id,
                        item.name,
                        item?.canBeRemoved,
                      );
                    }}
                  />
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot />
      </Table>

      {isEmpty(items) && (
        <Flex
          alignItems="center"
          h="100%"
          justifyContent="center"
          left="0"
          position="absolute"
          top="0"
          w="100%">
          {!isEmpty(params?.name) ? (
            <EmptyTableFilter />
          ) : (
            <EmptyTable description="There is no data to show you right now" />
          )}
        </Flex>
      )}

      {!isEmpty(items) && (
        <Center>
          <LoadMoreButton
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoadingGroups}
          />
        </Center>
      )}
    </TableWrapper>
  );
};
