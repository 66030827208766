import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import React, {
  FC,
  lazy,
  MouseEvent,
  Suspense,
  useEffect,
  useState,
} from 'react';

import { useQueryParams } from '@app/hooks/useQueryParams';
import InfoTabIcon from '@app/icons/info-tab-icon.svg?react';
import MessageTabIcon from '@app/icons/messages-tab-icon.svg?react';
import NotesTabIcon from '@app/icons/notes-tab-icon.svg?react';
import ScheduleIcon from '@app/icons/schedule-tab-icon.svg?react';
import StarIcon from '@app/icons/star-icon.svg?react';
import UnstarIcon from '@app/icons/unstar-icon.svg?react';
import { getContactNames } from '@app/utils/contact';

import {
  CampaignMessageStatus,
  MacroFragment,
  useFindScheduledMessagesQuery,
  useGetContactQuery,
  useUpdateContactMutation,
} from '@app/api/gql/generated-types';
import { useUpdateContactFragment } from '@app/api/hooks/useUpdateContactFragment';
import { lazyRetry } from '@app/utils/lazyRetry';
import {
  MacroFloatingModal,
  TMacroFloatingModalPosition,
} from 'components/next/organisms/MacroFloatingModal';
import Skeleton from '../Skeleton';
import { useTabs } from './Tab/useTabs';

import ContentWrapper from './ContentWrapper';
import TabIcon from './TabIcon';
import { SideBarTab } from './types';

const ContactChat = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ContactChat" */ '@app/pages/Messenger/components/ContactChat'
      ),
  ),
);
const InfoTab = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "InfoTab" */ '@app/pages/Messenger/components/InfoTab'
      ),
  ),
);
const NotesTab = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "NotesTab" */ '@app/pages/Messenger/components/NotesTab'
      ),
  ),
);
const ScheduledTab = lazy(() =>
  lazyRetry(() =>
    import(
      /* webpackChunkName: "ScheduledTab" */ '@app/pages/Messenger/components/ContactChat/ScheduledTab'
    ).then((module) => ({
      default: module.ScheduledTab,
    })),
  ),
);
interface Props {
  id: string;
}

const SideBarContentMobile: FC<Props> = ({ id }) => {
  const { updateContactFragment } = useUpdateContactFragment();
  const [mutate] = useUpdateContactMutation({
    context: {
      useApolloNetworkStatus: true,
    },
  });

  const [{ profileId }] = useQueryParams<{
    profileId: string;
  }>();
  const { data: { getContact: contact } = {}, loading: isLoading } =
    useGetContactQuery({
      variables: {
        id,
      },
    });

  const { data, loading: scheduledMessagesIsLoading } =
    useFindScheduledMessagesQuery({
      variables: {
        filter: {
          contactId: contact?.id,
          profileId,
          status: CampaignMessageStatus.ACTIVE,
        },
      },
    });

  const scheduledMessages = data?.findScheduledMessages?.items;
  const [initialChatMessage, setInitialChatMessage] = useState<string | null>(
    null,
  );
  const { isActive, setActive } = useTabs();
  const {
    onClose: onCloseMacroModal,
    isOpen: isOpenMacroModal,
    onToggle: onToggleMacroModal,
  } = useDisclosure();
  const onMacroTriggerClick = () => {
    onToggleMacroModal();
  };
  const onMacroSelect = (macro: MacroFragment) => {
    setInitialChatMessage(macro.content);
    onToggleMacroModal();
  };

  useEffect(() => {
    setInitialChatMessage(null);
  }, [contact?.id]);

  const handleScheduledClick = () => setActive(SideBarTab.SCHEDULED);

  const { fullName } = getContactNames(contact);

  const isMasterAccount = !!profileId;

  const handleSetFavorite = async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    updateContactFragment({
      contactId: contact.id,
      favorite: !contact.favorite,
    });
    await mutate({
      variables: {
        id: contact.id,
        input: {
          favorite: !contact.favorite,
        },
      },
    });
  };

  if (isLoading || !contact?.id) {
    return null;
  }

  return (
    <Flex direction="column" height="100%">
      {isOpenMacroModal && (
        <MacroFloatingModal
          contactId={contact.id}
          isOpen={isOpenMacroModal}
          position={TMacroFloatingModalPosition.enum.mobile}
          onClose={onCloseMacroModal}
          onMacroSelect={onMacroSelect}
        />
      )}
      <Box borderBottom="1px solid" borderColor="mystic" p="0px 15px">
        <Box mb="16px">
          <Flex
            alignItems="center"
            borderBottom="1px solid mystic"
            direction="row"
            justifyContent="space-between"
            width="100%">
            <Flex
              alignItems="center"
              direction="row"
              justifyContent="flex-start"
              width="100%">
              <Box onClick={handleSetFavorite}>
                {contact.favorite ? <StarIcon /> : <UnstarIcon />}
              </Box>
              <Box margin="0 10px">
                <Text as="span" color="main.400" fontWeight={500} size="lg">
                  {fullName}
                </Text>
              </Box>
            </Flex>
            <Flex>
              <Box onClick={() => setActive(SideBarTab.MESSAGES)}>
                <TabIcon isActive={isActive(SideBarTab.MESSAGES)}>
                  <MessageTabIcon />
                </TabIcon>
              </Box>
              <Box mx="15px" onClick={() => setActive(SideBarTab.INFO)}>
                <TabIcon isActive={isActive(SideBarTab.INFO)}>
                  <InfoTabIcon />
                </TabIcon>
              </Box>
              <Box onClick={() => setActive(SideBarTab.NOTES)}>
                <TabIcon isActive={isActive(SideBarTab.NOTES)}>
                  <NotesTabIcon />
                </TabIcon>
              </Box>
              {!!scheduledMessages?.length && (
                <Box ml="15px" onClick={() => setActive(SideBarTab.SCHEDULED)}>
                  <TabIcon isActive={isActive(SideBarTab.SCHEDULED)}>
                    <ScheduleIcon />
                  </TabIcon>
                </Box>
              )}
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box
        backgroundColor="zircon"
        flex="1"
        overflowY="scroll"
        pb="10px"
        pt="10px">
        <ContentWrapper isActive={isActive(SideBarTab.MESSAGES)}>
          <Suspense fallback={<Skeleton />}>
            <ContactChat
              contactId={contact.id}
              initialChatMessage={initialChatMessage}
              isBlocked={contact?.blocked || contact?.unsubscribed}
              isMacroModalOpen={isOpenMacroModal}
              isMasterAccount={isMasterAccount}
              scheduledMessages={scheduledMessages}
              setInitialChatMessage={setInitialChatMessage}
              onMacroButtonClick={onMacroTriggerClick}
              onScheduledClick={handleScheduledClick}
            />
          </Suspense>
        </ContentWrapper>
        <ContentWrapper isActive={isActive(SideBarTab.INFO)}>
          {isActive(SideBarTab.INFO) && (
            <Suspense fallback={<Skeleton />}>
              <InfoTab
                contactId={contact.id}
                isMasterAccount={isMasterAccount}
              />
            </Suspense>
          )}
        </ContentWrapper>

        <ContentWrapper isActive={isActive(SideBarTab.NOTES)}>
          {isActive(SideBarTab.NOTES) && (
            <Suspense fallback={<Skeleton />}>
              <NotesTab
                contactId={contact.id}
                isMasterAccount={isMasterAccount}
              />
            </Suspense>
          )}
        </ContentWrapper>
        <ContentWrapper isActive={isActive(SideBarTab.SCHEDULED)}>
          {isActive(SideBarTab.SCHEDULED) && (
            <Suspense fallback={<Skeleton />}>
              <ScheduledTab
                contactId={contact.id}
                isLoading={scheduledMessagesIsLoading}
                isMasterAccount={isMasterAccount}
                items={scheduledMessages}
                onClose={() => setActive(SideBarTab.MESSAGES)}
              />
            </Suspense>
          )}
        </ContentWrapper>
      </Box>
    </Flex>
  );
};

export default SideBarContentMobile;
