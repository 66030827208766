import { GetContactPhoneQuery, Name } from '@app/api/gql/generated-types';
import { concatenateStrings } from './stringUtils';

export const getContactNames = (
  contact: GetContactPhoneQuery['getContact'] | Name,
): {
  firstName: string;
  lastName: string;
  fullName: string;
} => {
  const MAX_FULLNAME_LENGTH = 30;
  if (!contact) {
    return {
      firstName: '',
      lastName: '',
      fullName: '',
    };
  }

  let { firstName, lastName } = contact;
  firstName = firstName || '';
  lastName = lastName || '';
  const fullName = concatenateStrings(' ', firstName, lastName).substring(
    0,
    MAX_FULLNAME_LENGTH,
  );

  return {
    firstName,
    lastName,
    fullName,
  };
};
