import { OwnPhoneType } from '@app/api/gql/generated-types';

export enum PhoneItemStatus {
  ALLOW = 'allow',
  DISALLOW = 'disallow',
  LOADING = 'loading',
}

export interface PhoneItem {
  phone: string;
  status: PhoneItemStatus;
  id: string;
}

export interface BuyNumberForm {
  type: OwnPhoneType;
  areaCode: string;
}

export interface SelectPhoneTypeOption {
  label: string;
  value: OwnPhoneType;
  isDisabled?: boolean;
}
