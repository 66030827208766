import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { useDisclosure } from '@chakra-ui/react';
import {
  CustomModal,
  CustomModalProps,
} from '@app/components/next/organisms/CustomModal';

interface PermissionModalContextProps extends PropsWithChildren {
  openModal: (content: CustomModalProps) => void;
  closeModal: () => void;
  isOpen: boolean;
}

const PermissionModalContext =
  createContext<PermissionModalContextProps | null>(null);

export const usePermissionModalContext = (): PermissionModalContextProps => {
  const context = useContext(PermissionModalContext);
  if (!context) {
    throw new Error(
      'usePermissionModalContext must be used within PermissionModalProvider',
    );
  }
  return context;
};

export const PermissionModalProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalContent, setModalContent] = useState<CustomModalProps>(null);

  const openModal = (content: CustomModalProps) => {
    setModalContent(content);
    onOpen();
  };

  const closeModal = () => {
    setModalContent(null);
    onClose();
  };

  return (
    <PermissionModalContext.Provider value={{ openModal, closeModal, isOpen }}>
      {modalContent && (
        <CustomModal
          isOpen={isOpen}
          primaryAction={modalContent.primaryAction}
          primaryButtonText={modalContent.primaryButtonText}
          secondaryButtonText={modalContent.secondaryButtonText}
          showSecondayAction={modalContent.showSecondayAction}
          title={modalContent.title}
          onClose={closeModal}>
          {modalContent.children}
        </CustomModal>
      )}
      {children}
    </PermissionModalContext.Provider>
  );
};
