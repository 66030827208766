import {
  Checkbox,
  Flex,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, MouseEvent, useCallback } from 'react';

import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import DateFormat from '@app/components/DateFormat';
import EmptyTable from '@app/components/EmptyTable';
import LoadMoreButton from '@app/components/LoadMoreButton';
import IconButtonRemove from '@app/components/IconButtonRemove';
import { useUpdateContactMutation } from '@app/api/mutations/useUpdateContactMutation';
import { LabelList } from '@app/components/LabelList';
import { GroupCell } from '@app/components/Table/Cells/GroupCell';
import CheckboxChecked from '@app/icons/checkbox-checked.svg?react';
import CheckboxPartial from '@app/icons/checkbox-partial.svg?react';
import PhoneNumberFormat from '@app/shared/ui/PhoneNumberFormat';
import {
  Contact,
  QueryFindContactsArgs,
  UpdateContactMutationVariables,
} from '@app/api/gql/generated-types';
import { useUpdateContactFragment } from '@app/api/hooks/useUpdateContactFragment';
import { TableWrapper } from '../../utils/styles';

import { CellTextTooltip } from './CellTextTooltip';
import { EmptyTableFilter } from './EmptyTableFilter';
import { FavoriteCell } from './FavoriteCell';

interface TableListProps {
  contactListData: Contact[];
  handleSelectAll: () => void;
  removeContactFromList: (
    event: MouseEvent<HTMLElement>,
    entity: Contact,
  ) => void;
  selectedContacts: string[];
  onSelectChange: (id: string) => void;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  params: QueryFindContactsArgs;
  handleContactClick: (item: Contact) => void;
  labels?: string[];
}

export const TableList: FC<TableListProps> = ({
  contactListData,
  selectedContacts,
  labels,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  params,
  handleSelectAll,
  removeContactFromList,
  onSelectChange,
  fetchNextPage,
  handleContactClick,
}) => {
  const { contactId } = useParams<{
    contactId?: string;
  }>();

  const { updateContactFragment } = useUpdateContactFragment();
  const wrapperHeight = `calc(100vh - ${isEmpty(labels) ? 285 : 320}px)`;
  const getCellBg = (item: Contact) => {
    let background = null;

    if (item?.id === contactId && !selectedContacts?.includes(item?.id)) {
      background = 'zircon';
    } else if (selectedContacts?.includes(item?.id)) {
      background = 'primary.100';
    }

    return background;
  };

  const [mutate] = useUpdateContactMutation();

  const onContactUpdate = useCallback(
    async (values: UpdateContactMutationVariables) => {
      updateContactFragment({
        contactId,
        favorite: values.input?.favorite,
      });
      await mutate({
        variables: values,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactId],
  );

  return (
    <TableWrapper height={wrapperHeight}>
      <Table>
        <Thead bg="white" position="sticky" top="0" zIndex="10">
          <Tr>
            <Th p="0 15px 0 30px" width="45px">
              {!isEmpty(contactListData) ? (
                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between">
                  <Checkbox
                    icon={
                      !isEmpty(selectedContacts) ? (
                        <CheckboxPartial />
                      ) : (
                        <CheckboxChecked />
                      )
                    }
                    isChecked={!isEmpty(selectedContacts)}
                    variant={
                      !isEmpty(selectedContacts) ? 'primaryPartial' : 'primary'
                    }
                    onChange={() => handleSelectAll()}
                  />
                </Flex>
              ) : (
                ''
              )}
            </Th>
            <Th p="0" />
            <Th pl="15px">First name</Th>
            <Th>Last name</Th>
            <Th>Phone</Th>
            <Th>Groups</Th>
            <Th display={contactId && 'none'}>Date created</Th>
            <Th display={contactId && 'none'}>Labels</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {contactListData?.map((item) => (
            <Tr
              key={`contact-${item.id}-row-${item.email}`}
              background={getCellBg(item)}
              cursor="pointer"
              id={item.id}
              onClick={() => handleContactClick(item)}>
              <Td p="0 15px 0 30px">
                <Flex
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onSelectChange(item?.id);
                  }}>
                  <Checkbox
                    isChecked={selectedContacts.includes(item?.id)}
                    variant="primary"
                  />
                </Flex>
              </Td>
              <Td p="0">
                <FavoriteCell
                  id={item.id}
                  isFavorite={item?.favorite}
                  onChangeFavorite={onContactUpdate}
                />
              </Td>
              <Td pl="15px">
                <CellTextTooltip text={item?.firstName} />
              </Td>
              <Td>
                <CellTextTooltip text={item?.lastName} />
              </Td>
              <Td whiteSpace="nowrap">
                <PhoneNumberFormat value={item?.phone} />
              </Td>
              <Td>
                <GroupCell groups={item?.groups} />
              </Td>
              <Td display={contactId && 'none'}>
                <DateFormat
                  date={DateTime.fromISO(item?.createdAt).toJSDate()}
                />
              </Td>
              <Td
                display={contactId && 'none'}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}>
                {isEmpty(item?.labels) ? (
                  '-'
                ) : (
                  <Flex h="26px">
                    <LabelList
                      editable
                      contactId={item?.id}
                      justifyContent="flex-start"
                      labels={item?.labels}
                      visibleItemsCount={2}
                    />
                  </Flex>
                )}
              </Td>
              <Td>
                <IconButtonRemove
                  onClick={(event) => {
                    removeContactFromList(event, item);
                  }}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Tfoot />
      </Table>

      {isEmpty(contactListData) && (
        <Flex
          alignItems="center"
          h="100%"
          justifyContent="center"
          left="0"
          position="absolute"
          top="0"
          w="100%">
          {!isEmpty(params?.filter?.labels) ||
          !isEmpty(params?.filter?.fullSearch) ? (
            <EmptyTableFilter />
          ) : (
            <EmptyTable description="There is no data to show you right now" />
          )}
        </Flex>
      )}
      {!isEmpty(contactListData) && (
        <Flex justifyContent="center">
          <LoadMoreButton
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
          />
        </Flex>
      )}
    </TableWrapper>
  );
};
