import { Box, Flex, Text, useDisclosure, Button, Link } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import { SUPPORT_LINK } from '@app/utils/routes';
import LoomPlayer from '@app/components/LoomPlayer';
import video from '@app/utils/videoLinks';
import { EmptyImage } from '@app/pages/Messenger/ui-states/EmptyImage';

import { MessengerFolderType } from '../types';

interface EmptyConversationProps {
  type: MessengerFolderType;
}

export const EmptyConversation: FC<EmptyConversationProps> = ({ type }) => {
  const videoModal = useDisclosure();

  return (
    <Flex
      alignItems="center"
      direction="column"
      height="100%"
      justifyContent="center"
      textAlign="center">
      <EmptyImage type={type} />
      {type === MessengerFolderType.ALL && (
        <>
          <Box textAlign="center">
            <Box mb="15px" mt="30px">
              <Text fontSize="22px" fontWeight="500">
                Start your first conversation
              </Text>
            </Box>
            <Box width="300px">
              <Text fontSize="14px" size="lg">
                Not sure where to start?&nbsp;
                <Button
                  as={Link}
                  variant="linkSecondary"
                  onClick={videoModal.onOpen}>
                  Watch this video
                </Button>
                &nbsp;or visit our&nbsp;
                <Link
                  as={RouteLink}
                  target="_blank"
                  to={SUPPORT_LINK}
                  variant="inline">
                  Help Center
                </Link>
              </Text>
            </Box>
          </Box>

          <LoomPlayer
            isOpenModal={videoModal.isOpen}
            link={video.welcomeVideo}
            onCloseModal={videoModal.onClose}
          />
        </>
      )}
    </Flex>
  );
};
