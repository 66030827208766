import React, { FC } from 'react';
import { Text } from '@chakra-ui/react';

import { QueryResult } from '@apollo/client';
import { Campaign, ContactList, Label } from '@app/api/gql/generated-types';

import { DefaultName } from './DefaultName';

interface DataResultProps {
  name?: string;
  title?: string;
}

interface EntityCrumbNameProps {
  entityId: string;
  title: string;
  queryHook: (
    id: string,
  ) => Partial<QueryResult<Campaign | Label | ContactList>>;
}

export const EntityCrumbName: FC<EntityCrumbNameProps> = ({
  entityId,
  title,
  queryHook,
}) => {
  const { data } = queryHook(entityId);
  const dataResult = data as DataResultProps;
  return (
    <DefaultName title={dataResult?.name ?? dataResult?.title}>
      <Text as="span" color="secondary.400" mr="4px">
        {title}
      </Text>
    </DefaultName>
  );
};
