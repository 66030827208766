import React, { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { TrialModalType } from '@app/contexts/PopupsContext';

import Popup from '../../Popup';

import { TrialLimitsPopupProps } from './types';
import ModalDescription from './ModalDescription';
import SendButton from './SendButton';

/**
 *  @deprecated Use the new permission Features & permission custom modal.
 *  this is not even right . it sends the user to buy a phone and the user is no allowed to buy a phone
 *  according to https://docs.google.com/spreadsheets/d/1k8TbI8Eod8KhBUG_BqEXwHS0ckaAH7z4o8cIBDUvnTw/edit?gid=2086662141#gid=2086662141
 * */
const TrialLimitsPopup: FC<TrialLimitsPopupProps> = ({
  isOpen,
  onClose,
  type = TrialModalType.LIMIT,
}) => {
  return (
    <Popup
      closeOnOverlayClick
      isOpen={isOpen}
      maxW={type === TrialModalType.NO_PHONES ? '396px' : '356px'}
      title={
        type === TrialModalType.NO_PHONES ? 'Buy Phone Number' : 'Trial Account'
      }
      onClose={onClose}>
      <Box p="0 40px 40px">
        <Box mt="25px">
          <ModalDescription type={type} onClose={onClose} />
        </Box>
        <Flex justifyContent="center" mt="30px">
          <SendButton type={type} onClose={onClose} />
        </Flex>
      </Box>
    </Popup>
  );
};

export default TrialLimitsPopup;
