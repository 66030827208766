import { string } from 'yup';

import { onlyAlphabetRegex, onlyAlphabetWithSpacesRegex } from './common';

export const citySchema = string()
  .trim()
  .matches(onlyAlphabetWithSpacesRegex, 'Please use alpha characters only')
  .min(1)
  .max(100)
  .label('City');

export const stateSchema = string()
  .trim()
  .min(1)
  .matches(onlyAlphabetRegex, 'Please use alpha characters only')
  .label('State');

export const streetSchema = string()
  .trim()
  .min(1)
  .max(100)
  .label('Address/Street');

export const zipSchema = string()
  .min(1)
  .matches(/^[0-9]{5}$/, 'ZIP code must be 5 digits')
  .label('Zip');
