import { useState, useEffect, useCallback } from 'react';
import { Schema, ValidationError } from 'yup';
import { set } from 'lodash';

type Values = {
  [field: string]: any;
};

type ValidationErrors<T> = {
  [K in keyof T]?: T[K] extends object ? ValidationErrors<T[K]> : string;
};

type ValidationResult<T> = {
  errors: ValidationErrors<T>;
  isValid: boolean;
};

type UseYupOptions = {
  validateOnChange?: boolean;
};

/**
 * Transform Yup errors to a ValidationErrors object
 */
function yupToValidationErrors<T extends Values>(
  yupError: ValidationError,
): ValidationErrors<T> {
  const errors: ValidationErrors<T> = {};
  if (yupError.inner.length === 0) {
    set(errors, yupError.path, yupError.message);
    return errors;
  }
  for (const err of yupError.inner) {
    set(errors, err.path, err.message);
  }
  return errors;
}

function useYup<T extends Values>(
  values: T,
  validationSchema: Schema<any>,
  options: UseYupOptions = {},
) {
  const [errors, setErrors] = useState<ValidationErrors<T>>({});
  const isValid = Object.keys(errors).length === 0;

  const validate = useCallback(
    () =>
      validationSchema
        .validate(values, { abortEarly: false })
        .then(() => {
          return {} as ValidationErrors<T>;
        })
        .catch((error: ValidationError) => {
          return yupToValidationErrors<T>(error);
        })
        .then((newErrors) => {
          setErrors(newErrors);
          return {
            errors: newErrors,
            isValid: Object.keys(newErrors).length === 0,
          } as ValidationResult<T>;
        }),
    [validationSchema, values],
  );

  useEffect(() => {
    if (options.validateOnChange) {
      void validate();
    }
  }, [values, validationSchema, options, validate]);

  return {
    validate,
    errors,
    isValid,
  };
}

export default useYup;
