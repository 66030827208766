import { concatenateStrings, TStdSymbol } from '@app/utils/stringUtils';

export type QueryParams =
  | string[][]
  | Record<string, string>
  | string
  | URLSearchParams;

export const collectQueryExistingQueryParams = () => {
  const searchParams = new URLSearchParams(location.search);
  const queryParams: QueryParams = {};
  searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });
  return new URLSearchParams(queryParams).toString();
};

export const extendWithCurrentQueryParams = (
  url: string,
  additionalQueryParams?: QueryParams,
) => {
  const existingParams = new URLSearchParams(collectQueryExistingQueryParams());
  const newParams = new URLSearchParams(additionalQueryParams);

  newParams.forEach((value, key) => {
    existingParams.set(key, value);
  });

  return concatenateStrings(
    TStdSymbol.QUESTION,
    url,
    existingParams.toString(),
  );
};
