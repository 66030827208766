import { ButtonGroup, Checkbox, Divider, Flex } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useMemo } from 'react';

import CheckboxChecked from '@app/icons/checkbox-checked.svg?react';
import CheckboxPartial from '@app/icons/checkbox-partial.svg?react';

import { ConversationFilter } from '@app/api/gql/generated-types';
import { MessengerFolderType, UpdateMultiLabels } from '../types';

import { DeleteButton } from './BulkActions/DeleteButton';
import { MarkAsButton } from './BulkActions/MarkAsButton';
import { RecoverButton } from './BulkActions/RecoverButton';
import { UpdateLabelsButton } from './BulkActions/UpdateLabelsButton';

interface ContactGroupActionsProps {
  selectedContacts: string[];
  onContactsBatchUpdate: (deletedContactIds?: string[]) => void;
  handleContactsBatchDelete: (deletedContactIds?: string[]) => void;
  onToggleCheckedAll: () => void;
  isAllSelected: boolean;
  type: MessengerFolderType;
  params: ConversationFilter;
  canRemoveContacts?: boolean;
}

export const ContactGroupActions: FC<ContactGroupActionsProps> = ({
  selectedContacts,
  onContactsBatchUpdate,
  handleContactsBatchDelete,
  onToggleCheckedAll,
  isAllSelected,
  type,
  params,
  canRemoveContacts = true,
}) => {
  const partialChecked = useMemo(
    () => !isAllSelected && !!selectedContacts.length,
    [isAllSelected, selectedContacts.length],
  );

  return (
    <Flex alignItems="center" height="26px">
      <Checkbox
        icon={partialChecked ? <CheckboxPartial /> : <CheckboxChecked />}
        isChecked={!!isAllSelected}
        variant={partialChecked ? 'primaryPartial' : 'primary'}
        onChange={onToggleCheckedAll}
      />

      {!isEmpty(selectedContacts) && type !== MessengerFolderType.DELETED && (
        <ButtonGroup height="26px" ml="17px">
          <DeleteButton
            canRemoveContacts={canRemoveContacts}
            params={params}
            selectedContacts={selectedContacts}
            type="basket"
            onComplete={handleContactsBatchDelete}
          />

          <Divider orientation="vertical" />

          <MarkAsButton
            params={params}
            selectedContacts={selectedContacts}
            type="unread"
            onComplete={onContactsBatchUpdate}
          />
          <MarkAsButton
            params={params}
            selectedContacts={selectedContacts}
            type="read"
            onComplete={onContactsBatchUpdate}
          />

          <Divider orientation="vertical" />

          <UpdateLabelsButton
            params={params}
            selectedContacts={selectedContacts}
            type={UpdateMultiLabels.ADD}
            onComplete={onContactsBatchUpdate}
          />

          <UpdateLabelsButton
            params={params}
            selectedContacts={selectedContacts}
            type={UpdateMultiLabels.REMOVE}
            onComplete={onContactsBatchUpdate}
          />
        </ButtonGroup>
      )}

      {!isEmpty(selectedContacts) && type === MessengerFolderType.DELETED && (
        <ButtonGroup ml="12px">
          <RecoverButton
            params={params}
            selectedContacts={selectedContacts}
            onComplete={onContactsBatchUpdate}
          />
        </ButtonGroup>
      )}
    </Flex>
  );
};
