import { useMemo } from 'react';
import {
  getDialerSubscription,
  getMessageSubscription,
} from '@app/utils/subscriptionHelpers';
import { PlanType } from '@app/api/gql/generated-types';
import { useCurrentAccountData } from './useCurrentAccountData';

export type PlanPermissionType = Record<string, boolean>;

interface UsePlanPermissionProps {
  isBasicPlan: boolean;
  isNewStarter: boolean;
  isOldStarter: boolean;
  isPro: boolean;
  isNewPro: boolean;
  isOldPro: boolean;
  isElite: boolean;
  isNewElite: boolean;
  isOldElite: boolean;
  isAdvanced: boolean;
  isMiddleAdvanced: boolean;
  isAllNewPlans: boolean;
  isEnterprisePlan: boolean;
  isPrimitivePlans: boolean;
  isDialerPlan: boolean;
}

export const usePlanPermission = (): UsePlanPermissionProps => {
  const account = useCurrentAccountData();
  const messageSubscription = getMessageSubscription(account);

  const dialerSubscription = getDialerSubscription(account);

  const accountPlan = useMemo(
    () => messageSubscription?.plan?.type,
    [messageSubscription?.plan?.type],
  );

  const isOldStarter = useMemo(
    () => accountPlan === PlanType.BASIC,
    [accountPlan],
  );

  const isNewStarter = useMemo(
    () => accountPlan === PlanType.STANDARD,
    [accountPlan],
  );

  const isBasicPlan = useMemo(
    () => accountPlan === PlanType.BASIC,
    [accountPlan],
  );

  const isOldPro = useMemo(() => accountPlan === PlanType.PRO, [accountPlan]);
  const isNewPro = useMemo(() => accountPlan === PlanType.PRO, [accountPlan]);
  const isPro = useMemo(() => isOldPro || isNewPro, [isNewPro, isOldPro]);

  const isNewElite = useMemo(
    () => accountPlan === PlanType.ELITE,
    [accountPlan],
  );
  const isOldElite = useMemo(
    () => accountPlan === PlanType.ELITE_OLD,
    [accountPlan],
  );
  const isElite = useMemo(
    () => isNewElite || isOldElite,
    [isNewElite, isOldElite],
  );

  const isAdvanced = useMemo(() => isPro || isElite, [isElite, isPro]);

  const isMiddleAdvanced = useMemo(
    () => isElite || isNewPro,
    [isElite, isNewPro],
  );

  const isAllNewPlans = useMemo(
    () => isBasicPlan || isNewStarter || isNewPro || isNewElite,
    [isNewElite, isNewPro, isNewStarter, isBasicPlan],
  );

  const isEnterprisePlan = !!messageSubscription?.plan?.enterprise;
  const isDialerPlan = !!dialerSubscription;

  const isPrimitivePlans = isBasicPlan;

  return {
    isBasicPlan,
    isNewStarter,
    isOldStarter,
    isPro,
    isNewPro,
    isOldPro,
    isElite,
    isNewElite,
    isOldElite,
    isAdvanced,
    isMiddleAdvanced,
    isAllNewPlans,
    isEnterprisePlan,
    isPrimitivePlans,
    isDialerPlan,
  };
};
