import React, { ChangeEventHandler, FC, useCallback, useState } from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { isMobile } from 'react-device-detect';

import SearchIcon from '@app/icons/search-icon.svg?react';
import CloseIcon from '@app/icons/close-icon.svg?react';
import { Menu } from '@app/pages/Messenger/components/Menu';
import { DEFAULT_DEBOUNCE_TIME } from '@app/api/queries/utils';

import { MIN_LENGTH_INPUT_FULL_SEARCH } from '@app/constants/configuration';
import { BottomSheetModal } from '../BottomSheet';

interface SearchFieldProps {
  onChange: (value: string) => void;
}

const SearchField: FC<SearchFieldProps> = ({ onChange }) => {
  const [searchValue, setSearchValue] = useState<string>('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce((value: string) => {
      onChange(value);
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const { value } = event.target;
      setSearchValue(value);

      if (value.length === 0 || value.length >= MIN_LENGTH_INPUT_FULL_SEARCH) {
        handleChange(value);
      }
    },
    [handleChange],
  );

  const handleReset = useCallback(() => {
    setSearchValue('');
    onChange('');
  }, [setSearchValue, onChange]);

  return (
    <>
      <InputGroup w={['100%', '260px']}>
        <Input
          placeholder="Search…"
          value={searchValue}
          variant="search"
          w="100%"
          onChange={handleInputChange}
        />
        <InputRightElement
          color="secondary.400"
          height={['40px', '36px']}
          pointerEvents="none"
          width="auto">
          {searchValue ? (
            <IconButton
              isRound
              aria-label="Remove"
              icon={<CloseIcon />}
              mr="3px"
              pointerEvents="painted"
              size="sm"
              onClick={handleReset}
            />
          ) : (
            <Box mr="19px">
              <SearchIcon />
            </Box>
          )}
        </InputRightElement>
      </InputGroup>
      {isMobile && (
        <BottomSheetModal title="Filters">
          <Menu />
        </BottomSheetModal>
      )}
    </>
  );
};

export default SearchField;
