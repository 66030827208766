import React, { FC } from 'react';
import {
  Box,
  TagLabel,
  TagCloseButton,
  Flex,
  Tag,
  Tooltip,
} from '@chakra-ui/react';
import truncate from 'lodash/truncate';

import CloseIcon from '@app/icons/close-icon.svg?react';
import ArrowUp from '@app/icons/arrow-up-icon.svg?react';
import ArrowDown from '@app/icons/arrow-down-icon.svg?react';
import { useLabelIdQueryData } from '@app/api/queries/useLabelIdQueryData';
import { tinyColor, toneColor } from '@app/utils/toneColor';

interface LabelViewProps {
  id: string;
  isGroup?: boolean;
  isMonochrome?: boolean;
  editable?: boolean;
  editVisible?: boolean;
  onDelete?: (id: string) => void;
}

const LabelView: FC<LabelViewProps> = ({
  id,
  isGroup,
  isMonochrome,
  editable,
  editVisible,
  onDelete,
}) => {
  const { data: labelData, isLoading } = useLabelIdQueryData(id);

  if (isLoading || !labelData) {
    return (
      // TODO: Add Skeleton
      <Box />
    );
  }

  const labelColor = isMonochrome ? 'main.300' : toneColor(labelData?.color);

  return (
    <Tag
      bg={
        isMonochrome
          ? 'secondary.100'
          : labelData?.color || tinyColor(labelData?.color).toHex8String()
      }
      borderRadius="12px"
      justifyContent="center"
      margin={isGroup ? '0px 5px' : '3px 3px'}
      maxWidth={isGroup || isMonochrome ? '100%' : '99px'}
      minWidth="auto"
      padding={isMonochrome ? '2px 10px' : '4px 12px'}
      size="md"
      variant="solid"
      width={isGroup ? '100%' : 'auto'}>
      <Flex alignItems="center">
        <Tooltip
          hasArrow
          isDisabled={!(isMonochrome && labelData?.title?.length > 15)}
          label={labelData?.title}
          offset={[0, 15]}
          placement="top">
          <TagLabel
            color={labelColor}
            fontSize={isMonochrome ? '14px' : '12px'}
            fontWeight="500"
            lineHeight="16px"
            maxW={isMonochrome ? '120px' : '70px'}
            noOfLines={1}
            overflow="hidden"
            textOverflow="ellipsis"
            userSelect="none"
            whiteSpace="nowrap">
            {truncate(labelData?.title, { length: isMonochrome ? 15 : 10 })}
          </TagLabel>
        </Tooltip>
        {onDelete && (
          <TagCloseButton color="secondary.300" onClick={() => onDelete(id)}>
            <CloseIcon />
          </TagCloseButton>
        )}
        {editable && (
          <Box color={labelColor} ml="8px">
            {editVisible ? <ArrowUp /> : <ArrowDown />}
          </Box>
        )}
      </Flex>
    </Tag>
  );
};

export default LabelView;
