import { Box, Button, Flex, Progress, Text } from '@chakra-ui/react';
import axios from 'axios';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

import { useApolloClient } from '@apollo/client';
import {
  FindGroupsDocument,
  FindSkiptraceFieldsDocument,
  ListType,
  SignedUrlAction,
  useCreateListMutation,
  useGetListUploadSignedUrlLazyQuery,
  useListUploadCompletedSubscription,
  useListUploadProgressSubscription,
} from '@app/api/gql/generated-types';
import CheckDoneBlueIcon from '@app/icons/check-done-blue.svg?react';
import DocumentIcon from '@app/icons/document.svg?react';
import { catchErrorLog } from '@app/utils/logger';
import { useContactsUploadContext } from '@app/providers/ContactsUploadProvider';
import { AcceptedCsvFormat } from '@app/types/fileExtensions';
import { colors } from '@app/theme/colors';
import ToastNotify from '../ToastNotifier';

interface DragAndDropTypes {
  goToSkiptrace?: boolean;
  onClearState?: () => void;
}

const MAX_FILE_SIZE = 52428800;

export const DragAndDrop: FC<DragAndDropTypes> = ({
  goToSkiptrace = false,
  onClearState,
}) => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { dropModal } = useContactsUploadContext();

  const [fileName, setFileName] = useState<string>();
  const [progress, setProgress] = useState<number>(0);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [listId, setListId] = useState<string>('');
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);

  const [requestSignedUrl, { loading: isFileLoading }] =
    useGetListUploadSignedUrlLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const [createListMutation, { loading: isMutationLoading }] =
    useCreateListMutation({
      refetchQueries: [FindSkiptraceFieldsDocument, FindGroupsDocument],
      awaitRefetchQueries: true,
      onCompleted: () => {
        client.cache.evict({ fieldName: 'getContactsCounters' });
      },
    });

  const isLoading = isFileLoading || isMutationLoading;

  useListUploadProgressSubscription({
    onData: ({ data }) => {
      if (data?.data?.listUploadProgress.listId === listId) {
        setProgress(data?.data?.listUploadProgress?.progress);
      }
    },
  });

  useListUploadCompletedSubscription({
    onData: ({ data }) => {
      if (data?.data?.listUploadCompleted?.listId === listId) {
        setProgress(100);
        setIsComplete(true);
      }
    },
  });

  const uploadData = useCallback(
    async (file: File) => {
      if (!file) {
        return;
      }

      setFileName(file.name);
      setIsUploadingFile(true);
      const { data } = await requestSignedUrl({
        variables: {
          input: {
            fileName: file.name,
            contentType: file.type,
            action: SignedUrlAction.WRITE,
          },
        },
      });
      try {
        await axios.put(data?.getListUploadSignedUrl?.signedUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
        });

        setIsUploadingFile(false);

        const { data: createListData } = await createListMutation({
          variables: {
            input: {
              filePath: data?.getListUploadSignedUrl?.filePath,
              fileName: file.name,
              type: goToSkiptrace ? ListType.SKIPTRACE : ListType.GROUP,
            },
          },
        });

        client.cache.evict({ fieldName: 'findSkiptraces' });
        setListId(createListData?.createList?.id);
      } catch (error) {
        ToastNotify({
          status: 'error',
          position: 'top-right',
          title:
            'There was an error uploading the file. Please reload the page and try again',
        });
        catchErrorLog(error, 'DragAndDrop/uploadData');

        setProgress(0);
        setListId('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createListMutation, requestSignedUrl, goToSkiptrace],
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      void uploadData(acceptedFiles[0]);
    },
    [uploadData],
  );

  const goToPage = () => {
    client.cache.evict({ fieldName: 'findListItems' });

    dropModal.onClose();

    if (goToSkiptrace) {
      onClearState?.();
      navigate(`/skiptrace/edit/${listId}`, { state: listId });
    } else {
      navigate(`/contacts/edit/${listId}`, { state: listId });
    }
  };

  const {
    isDragActive,
    fileRejections,
    getRootProps,
    getInputProps,
    inputRef,
    open,
  } = useDropzone({
    onDrop,
    accept: { [AcceptedCsvFormat.CSV]: ['.csv'] },
    maxSize: MAX_FILE_SIZE,
    noClick: true,
  });

  const background = useMemo(() => {
    if (isDragActive) {
      return 'primary.100';
    }
    if (progress === 0) {
      return 'zircon';
    }
    return 'white';
  }, [isDragActive, progress]);

  const fileRejectionItems = fileRejections.map(({ errors }, i) => {
    return (
      <Text
        key={i}
        color={colors.notFound[2000]}
        fontSize="14px"
        pb="35px"
        textAlign="center">
        {errors?.[0]?.code.includes(ErrorCode.FileTooLarge) ? (
          <>Please upload a file under 50MB</>
        ) : (
          <>
            Wrong file type. <br /> Please upload a file with the following
            extension: .csv
          </>
        )}
      </Text>
    );
  });

  return (
    <Flex
      alignItems="center"
      background={background}
      border="1px dashed"
      borderColor={isDragActive ? 'primary.700' : 'primary.600'}
      borderRadius="5px"
      flexDirection="column"
      overflow="hidden"
      p={goToSkiptrace ? '35px 150px' : '80px 150px'}
      width="100%"
      {...getRootProps()}>
      <Box mb="25px">
        <DocumentIcon />
      </Box>
      <Box mb="20px">
        {!fileName && (
          <>
            <Text textAlign="center" variant="sub-heading">
              Drag & drop your files here or browse.
            </Text>
            <Text
              color="secondary.400"
              fontSize="12px"
              mt="10px"
              textAlign="center">
              Maximum upload file size: 50 MB.
            </Text>
          </>
        )}
        {fileName && (
          <>
            <Text maxWidth="420px" textAlign="center" variant="sub-heading">
              {isComplete
                ? `${fileName} uploaded`
                : 'Uploading your list. This might take a while…'}
            </Text>

            {!isComplete &&
              (isUploadingFile ? (
                <Text
                  color="secondary.400"
                  fontSize="12px"
                  lineHeight="13px"
                  mt="10px"
                  textAlign="center">
                  Step 1 of 2: Uploading file to storage
                </Text>
              ) : (
                <Text
                  color="secondary.400"
                  fontSize="12px"
                  lineHeight="13px"
                  mt="10px"
                  textAlign="center">
                  Step 2 of 2: Saving data to the database
                </Text>
              ))}
          </>
        )}
      </Box>

      {fileRejectionItems && fileRejectionItems}

      {fileName && (
        <Flex alignItems="center" flexDirection="column">
          {isComplete ? (
            <Flex alignItems="center" flexDirection="row" mb="6px">
              <Text
                color="primary.600"
                fontSize="12px"
                fontWeight="400"
                mr="6px">
                Done
              </Text>
              <CheckDoneBlueIcon />
            </Flex>
          ) : (
            <Flex alignItems="center" flexDirection="row" mb="6px">
              <Text color="primary.600" fontSize="11px" mr="6px">
                {Math.round(progress)}%
              </Text>
            </Flex>
          )}

          <Progress
            isAnimated
            borderRadius="4px"
            colorScheme="primary"
            h="5px"
            size="xs"
            value={progress}
            width="200px"
          />
        </Flex>
      )}

      {!isComplete ? (
        <>
          <input
            ref={inputRef}
            {...getInputProps()}
            hidden
            accept=".csv"
            type="file"
          />

          <Button
            iconSpacing={0}
            isDisabled={fileName && !isComplete}
            isLoading={fileName && !isComplete}
            loadingText="Browse "
            mt="20px"
            spinner={null}
            variant="primary"
            width="85px"
            onClick={open}>
            Browse
          </Button>
        </>
      ) : (
        <Button
          isDisabled={!listId || isLoading}
          isLoading={!isComplete || isLoading}
          mt="20px"
          variant="primary"
          width="85px"
          onClick={goToPage}>
          Next
        </Button>
      )}
      {!fileName && (
        <Text color="secondary.400" fontSize="12px" mt="10px">
          .csv
        </Text>
      )}
    </Flex>
  );
};
