export const hasUrlLikeString = (content: string): boolean => {
  const indicators = [
    'http://',
    'https://',
    'www.',
    'ftp://',
    'ssh://',
    'sftp://',
    'mailto:',
    'tel:',
    'file://',
    'data:',
  ];
  const lowerCaseContent = content.toLowerCase();

  return indicators.some((indicator) => lowerCaseContent.includes(indicator));
};

export const isValidUrl = (urlString: string) => {
  // Remove leading and trailing invalid characters except for a single trailing dot
  const sanitizedUrl = urlString
    .trim()
    .replace(/^[\[\(]+|[\]\)]+$/g, '')
    .replace(/\.+$/, '')
    .toLowerCase();

  // Allow a single trailing dot for domain names
  const trailingDotSanitizedUrl = sanitizedUrl.endsWith('.')
    ? sanitizedUrl.slice(0, -1)
    : sanitizedUrl;

  const urlPattern = new RegExp(
    '^(' +
      '(https?:\\/\\/|ftp:\\/\\/|ssh:\\/\\/|sftp:\\/\\/|www\\.)' +
      '(([a-z\\d]([a-z\\d-]*[a-z\\d])+)\\.)+' + // Domain with subdomains
      '([a-z]{2,})|' + // Valid TLD
      '((\\d{1,3}\\.){3}\\d{1,3})|' + // IPv4 address
      'mailto:[\\w._%+-]+@[a-z\\d.-]+\\.[a-z]{2,}|' + // Email validation
      'tel:\\+?[\\d-]+|' + // Telephone numbers
      'file:\\/\\/\\/(\\S+)?|' + // Local file paths
      'data:[a-z]+\\/[-a-z0-9.+]+;base64,[a-z0-9+/=]+)' + // Base64 data URI
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Optional port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // Optional query string
      '(\\#[-a-z\\d_]*)?$',
    'i', // Case-insensitive
  );

  // Return true only if the sanitized URL matches the pattern
  const patternResult = !!urlPattern.test(trailingDotSanitizedUrl);
  const hasUrlLikeStringResult = hasUrlLikeString(trailingDotSanitizedUrl);

  return patternResult || hasUrlLikeStringResult;
};
