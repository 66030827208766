import React, { FC } from 'react';
import { AbsoluteCenter, Flex } from '@chakra-ui/react';

import Icon from '@app/icons/play-icon-blue.svg?react';

const PlayIcon: FC<{ onClick: () => void; isButtonShadow: boolean }> = ({
  onClick,
  isButtonShadow,
}) => {
  return (
    <AbsoluteCenter>
      <Flex
        _hover={{
          opacity: 0.9,
          boxShadow: isButtonShadow && '0px 7px 31px rgba(68, 68, 68, 0.22)',
        }}
        alignItems="center"
        bg="whiteAlpha.900"
        borderRadius="full"
        boxShadow={isButtonShadow && '0px 7px 31px rgba(68, 68, 68, 0.52)'}
        cursor="pointer"
        h="72px"
        justifyContent="center"
        transition="all 0.1s linear"
        w="72px"
        zIndex="10"
        onClick={onClick}>
        <Icon />
      </Flex>
    </AbsoluteCenter>
  );
};

export default PlayIcon;
