import { useFindLabelsQuery } from '../gql/generated-types';

export const useLabelIdQueryData = (id?: string) => {
  const { data: { findLabels } = {}, loading } = useFindLabelsQuery({
    variables: { filter: { ids: [id] } },
    skip: !id,
  });
  const data = findLabels?.total ? findLabels?.items : [];
  return {
    data: data?.[0],
    isLoading: loading,
  };
};
