import { StylesConfig } from 'react-select';

import { tinyColor, toneColor } from '@app/utils/toneColor';
import { colors } from '@app/theme/colors';

const getColor = ({
  defaultColor,
  disabled,
  propsColor,
}: {
  defaultColor: string;
  disabled: string;
  propsColor: string;
}) => {
  let color = defaultColor;

  if (propsColor) {
    color = propsColor;
  }

  if (disabled) {
    color = disabled;
  }

  return color;
};

export const customStyles = (
  error?: string,
  maxWidth = '26%',
): StylesConfig<{ value: string; label: string; color?: string }, false> => ({
  control: (base, state) => {
    let border = state.isFocused
      ? `1px solid ${colors.primary[600]}  !important`
      : `1px solid ${colors.secondary[200]} !important`;

    if (error) {
      border = '1px solid red !important';
    }

    return {
      ...base,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      backgroundColor: state.isDisabled ? colors.catskillWhite : colors.white,
      borderRadius: '20px',
      boxShadow: '0px 0px 0px transparent',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1px 6px',
      fontSize: '14px',
      color: colors.main[400],
      minHeight: '40px',
      border,

      '&:hover': {
        border: `1px solid ${colors.secondary[300]} !important`,
      },
    };
  },
  option: (base) => ({
    ...base,

    fontSize: '14px',
    cursor: 'pointer',
    background: 'transparent !important',
    padding: '0',
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '20px',
    marginTop: '5px',
    overflow: 'hidden',
  }),
  menuList: (base) => ({
    ...base,
    padding: '8px 5px 8px 8px',
    marginRight: '5px',
    '::-webkit-scrollbar': {
      width: '5px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: 'white',
      margin: '12px 0',
    },
    '::-webkit-scrollbar-thumb': {
      background: colors.main[100],
      borderRadius: '3px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: colors.main[100],
    },
  }),

  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled ? colors.secondary[400] : colors.main[400],
    transition: 'all .3s ease',
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
    svg: {
      width: '14px',
      height: '14px',
      fill: state.isDisabled ? colors.secondary[400] : colors.main[400],
    },
  }),
  noOptionsMessage: (base) => ({
    ...base,
    padding: '0',
    fontSize: '14px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0px !important',
  }),
  multiValue: (base, { data, isDisabled }) => {
    return {
      ...base,
      backgroundColor: getColor({
        defaultColor: colors.primary[100],
        disabled: isDisabled && colors.zircon,
        propsColor: data?.color && tinyColor(data.color).toHex8String(),
      }),
      borderRadius: '16px',
      marginRight: '6px',
      padding: '0px 4px',
      maxWidth,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  },
  multiValueLabel: (base, { data, isDisabled }) => {
    return {
      ...base,
      color: getColor({
        defaultColor: colors.primary[600],
        disabled: isDisabled && colors.primary[300],
        propsColor: data?.color && toneColor(data.color),
      }),
      fontSize: '14px',
    };
  },
  multiValueRemove: (base, { data, isDisabled }) => ({
    ...base,
    color: getColor({
      defaultColor: colors.primary[600],
      disabled: isDisabled && colors.primary[300],
      propsColor: data?.color && toneColor(data.color),
    }),
    background: colors.transparent,
    ':hover': {
      background: colors.transparent,
      color: data?.color ? toneColor(data.color) : colors.primary[600],
      opacity: '0.8',
    },
  }),
  placeholder: (base, { hasValue }) => {
    return {
      ...base,
      color: colors.secondary[400],
      margin: '0',
      padding: hasValue ? '0' : '0 14px',
    };
  },
});
