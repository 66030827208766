import { Box, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

import NoResultsImage from '@app/icons/messenger/no-results-image.svg?react';

export const EmptyConversationSearchResults: FC = () => {
  return (
    <Box textAlign="center">
      <NoResultsImage />
      <Box my="15px">
        <Text color="secondary.400" fontSize="22px" fontWeight="500">
          No results found
        </Text>
      </Box>
    </Box>
  );
};
