import { Box, Center, Skeleton, useDisclosure } from '@chakra-ui/react';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  FindListItemsDocument,
  FindListItemsQuery,
  GetSkiptraceDocument,
  useFindListItemsQuery,
  useGetSkiptraceQuery,
  useRunSkiptraceMutation,
  useSkiptraceCompletedSubscription,
  useSkiptraceProgressSubscription,
} from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import { DEFAULT_LIMIT } from '@app/api/queries/utils';
import LoadMoreButton from '@app/components/LoadMoreButton';
import PageSkeleton from '@app/components/PageSkeleton';
import { UNKNOWN_FIELD_ID } from '@app/pages/Contacts/utils/prepareHeaders';
import TableSection from '@app/pages/Skiptrace/components/TableSection';
import Header from '../components/Header';
import { SkiptracedInfo } from '../components/SkiptracedInfo';
import { PrepareSkiptraceActions } from '../components/Steps/PrepareSkiptraceActions';
import { ResultActions } from '../components/Steps/ResultActions';
import { SkeletonPrepare } from '../components/Steps/SkeletonPrepare';
import { useSkiptraceHeaders } from '../components/TableSection/useSkiptraceHeaders';
import { SkiptraceVerificationModal } from '../components/Modal/SkiptraceVerificationModal';

const SkiptraceEntity: FC = () => {
  const [progress, setProgress] = useState<number>(0);
  const verificationModal = useDisclosure();

  const { id: contactListId } = useParams<Record<string, string>>();

  const {
    data: { getSkiptrace: skiptraceData } = {},
    networkStatus: skiptraceNetworkStatus,
    loading: isLoadingGetSkiptrace,
    refetch: refetchSkipraceData,
  } = useGetSkiptraceQuery({
    variables: {
      id: contactListId,
    },
  });

  const {
    data: { findListItems: contactsData } = {},
    fetchMore,
    networkStatus,
    refetch,
  } = useFindListItemsQuery({
    variables: {
      listId: contactListId,
      pagination: {
        limit: DEFAULT_LIMIT,
        nextRowIndex: skiptraceData?.excludeFirstRow ? 0 : null,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    isLoading: isInitialLoading,
    isLoadingData,
    isFetchingNextPage,
  } = useApolloLoadingStatus(networkStatus);

  const { isLoading: isSkiptraceInitLoading } = useApolloLoadingStatus(
    skiptraceNetworkStatus,
  );

  const rawContacts = useMemo(() => contactsData?.items ?? [], [contactsData]);

  const fetchNextPage = () => {
    if (
      contactsData?.hasNext &&
      !(contactsData?.items?.length && isLoadingData)
    ) {
      void fetchMore({
        variables: {
          listId: contactListId,
          pagination: {
            limit: DEFAULT_LIMIT,
            nextRowIndex: contactsData?.nextRowIndex,
          },
        },
        updateQuery: (prevResult: FindListItemsQuery, { fetchMoreResult }) => {
          fetchMoreResult.findListItems.items = [
            ...prevResult.findListItems.items,
            ...fetchMoreResult.findListItems.items,
          ];
          return fetchMoreResult;
        },
      });
    }
  };

  const hasProcessedContacts = skiptraceData?.processedContacts > 0;

  const {
    headersMap,
    headerOptions,
    loading,
    headers: headersState,
    setHeaders,
  } = useSkiptraceHeaders();

  const headers = useMemo(() => {
    const headerArr = headersState?.length
      ? headersState
      : skiptraceData?.fields?.map((field) => field.id);

    return (
      headerArr?.map((item: string) => {
        if (item === UNKNOWN_FIELD_ID) {
          return null;
        }
        if (headersMap?.[item]?.id) {
          return headersMap?.[item]?.id;
        }
        return item;
      }) ?? []
    );
  }, [skiptraceData?.fields, headersState, headersMap]);

  const convertToTableData = new Map(
    rawContacts.map((contact) => [contact.id, contact.data]),
  );

  const tableData = {
    headers,
    data: convertToTableData,
  };

  const [runSkiptraceMutation, { loading: isRunSkiptracing }] =
    useRunSkiptraceMutation({
      refetchQueries: [
        {
          query: GetSkiptraceDocument,
          variables: {
            id: contactListId,
          },
        },
        {
          query: FindListItemsDocument,
          variables: {
            listId: contactListId,
          },
        },
      ],
      onCompleted() {
        setHeaders([]);
      },
    });

  const onClearState = useCallback(() => {
    setProgress(0);
  }, []);

  useSkiptraceProgressSubscription({
    skip: !contactListId,

    onData: ({
      data: {
        data: {
          skiptraceProgress: { skiptraceId, progress: progressValue },
        },
      },
    }) => {
      if (skiptraceId === contactListId) {
        setProgress(progressValue);
      }
    },
  });

  useSkiptraceCompletedSubscription({
    skip: !contactListId,

    onData: async ({
      data: {
        data: {
          skiptraceCompleted: { skiptraceId },
        },
      },
    }) => {
      if (skiptraceId === contactListId) {
        setHeaders([]);

        await refetchSkipraceData();
        await refetch();
        verificationModal.onClose();
      }
    },
  });

  const skiptracingStarted = progress > 0 || hasProcessedContacts;

  const isLoading =
    isSkiptraceInitLoading || isLoadingGetSkiptrace || isInitialLoading;

  return (
    <Box height="full">
      {isLoading ? (
        <Skeleton
          endColor="cultured"
          height="20px"
          mb="35px"
          ml="30px"
          mt="25px"
          startColor="mystic"
          width="300px"
        />
      ) : (
        <Header
          excludeFirstRow={skiptraceData?.excludeFirstRow}
          name={skiptraceData?.fileName}
          updateSkiptraceId={contactListId}
        />
      )}

      {skiptraceData?.processedContacts <= 0 &&
        !progress &&
        !isRunSkiptracing && (
          <PrepareSkiptraceActions
            contactListHeader={headers}
            contactListId={contactListId}
            contactsCount={contactsData?.total}
            openVerificationModal={() => verificationModal.onOpen()}
            runSkiptraceMutation={runSkiptraceMutation}
            skiptraceData={skiptraceData}
          />
        )}

      {isRunSkiptracing && !progress && <SkeletonPrepare />}

      {hasProcessedContacts && (
        <ResultActions
          contactListId={contactListId}
          skiptraceData={skiptraceData}
          onClearState={onClearState}
        />
      )}

      {skiptracingStarted && <SkiptracedInfo skiptraceData={skiptraceData} />}

      {isLoading ? (
        <PageSkeleton />
      ) : (
        <Box
          height={
            hasProcessedContacts ? 'calc(100vh - 390px)' : 'calc(100vh - 220px)'
          }
          overflowY="auto">
          <TableSection
            isSkiptracing
            contactListId={contactListId}
            headerOptions={headerOptions}
            headerOptionsMap={headersMap}
            headers={headers}
            loading={loading}
            setHeaders={setHeaders}
            skiptracingStarted={skiptracingStarted}
            tableData={tableData}
          />

          <Center mb="10px">
            <LoadMoreButton
              fetchNextPage={fetchNextPage}
              hasNextPage={contactsData?.hasNext}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isFetchingNextPage}
            />
          </Center>
        </Box>
      )}
      <SkiptraceVerificationModal
        contacts={contactsData?.total}
        progress={progress}
        verificationModal={verificationModal}
      />
    </Box>
  );
};

export default SkiptraceEntity;
