import { Box } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useMemo } from 'react';

import { Campaign } from '@app/api/gql/generated-types';
import { CellTextTooltip } from '@app/pages/Contacts/components/TableList/CellTextTooltip';

interface GroupCellProps {
  groups?:
    | { __typename?: 'GroupView'; id: string; name: string }[]
    | Campaign[];
}

export const GroupCell: FC<GroupCellProps> = ({ groups }) => {
  const names = useMemo(
    () => groups?.map((cl) => cl?.name).filter((i) => !!i),
    [groups],
  );

  return (
    <Box pr="10px">
      {isEmpty(groups) ? '-' : <CellTextTooltip text={names?.join(', ')} />}
    </Box>
  );
};
