import { formatNumber } from '@app/components/FormatNumber/utils';
import { Enum } from '@app/utils/enum';

/**
 * Enum representing the payment period options.
 *
 * @enum {string}
 * @readonly
 */
export const TPaymentPeriod = new Enum(
  'yearly',
  'monthly',
  'quarterly',
  'annually',
);

/**
 * Represents a currency symbol.
 * @class
 * @constructor
 * @param {string} symbol - The currency symbol.
 */
export const TCurrencySymbol = new Enum('$');

/**
 * Formats the given number value in cents as a string.
 *
 * @param {number} value - The value to format, representing the amount in cents.
 * @returns {string} - The formatted value as a string.
 */
export const formatInCents = (value: number): string =>
  formatNumber({ value, fractionDigits: 2 }).replace(/^0/, '');

/**
 * Converts cents to dollars.
 *
 * @param {number} cents - The amount in cents to be converted.
 * @returns {number} The amount in dollars.
 */
const centsToDollars = (cents: number): number => cents / 100;

/**
 * Converts a yearly price to a monthly price.
 *
 * @param {number} yearlyPrice - The yearly price to be converted.
 * @param {typeof TCurrencySymbol.type} [leadingSymbol=$] - The leading currency symbol.
 * @returns {string} - The converted monthly price with the leading currency symbol.
 */
export const convertYearlyToMonthlyPrice = (
  yearlyPrice: number,
  leadingSymbol: typeof TCurrencySymbol.type = TCurrencySymbol.enum.$,
  quarterly: boolean = false,
): string => {
  const monthlyPrice = centsToDollars(yearlyPrice / 12);
  const quarterlyPrice = centsToDollars(yearlyPrice / 3);

  return `${leadingSymbol}${formatNumber({
    value: quarterly ? quarterlyPrice : monthlyPrice,
    fractionDigits: 0,
  }).replace(/^0/, '')}`;
};

/**
 * Removes the billing period from the given title.
 *
 * @param {string} title - The title that may contain the billing period.
 * @returns {string} The title with the billing period removed.
 */
export const excludeBillingPeriod = (title: string): string =>
  title.replace(/(monthly|annually|quarterly|yearly)/gi, '');

/**
 * Calculate the discount amount based on the given price and discount percentage.
 * @param price The original price before discount.
 * @param discountPercentage The discount percentage to be applied.
 * @returns The calculated discount amount.
 */
export const calculateDiscountAmount = (
  price: number,
  discountPercentage: number,
): number => {
  return (price * discountPercentage) / 100;
};

/**
 * Calculate the price with discount based on the plan price, discount amount, and dollar discount.
 * If the discount amount is provided, it will be used. Otherwise, the dollar discount will be used.
 * @param planPrice The original price of the plan.
 * @param discountAmount The calculated discount amount.
 * @param dollarDiscount The dollar discount amount.
 * @returns The price with discount.
 */
export const calculatePriceWithDiscount = (
  planPrice: number,
  discountAmount: number | undefined,
  dollarDiscount: number,
): number => {
  return Math.trunc(
    planPrice - (discountAmount || centsToDollars(dollarDiscount)),
  );
};
