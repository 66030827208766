import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import isNil from 'lodash/isNil';
import React, { useCallback, useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client';
import SelectField from '@app/components/SelectField';
import {
  FieldsTemplate,
  FindFieldsTemplatesDocument,
  useFindFieldsTemplatesQuery,
  useRemoveFieldsTemplateMutation,
} from '@app/api/gql/generated-types';
import { CustomOption } from '@app/pages/Contacts/components/ApplyContactsToListButton/CustomOption';
import { AddTemplateModal } from '@app/pages/Contacts/components/Modal/AddTemplateModal';
import { catchErrorLog } from '@app/utils/logger';
import { usePopupsContext } from '@app/hooks/usePopupsContext';
import PlusIcon from '@app/icons/plus-icon-no-color.svg?react';

const UNKNOWN_ID = 'this-is-not-an-id';

export interface TemplateControlsProps {
  headers: string[];
  setHeader: (value: string[]) => void;
}

export const TemplateControls = ({
  headers,
  setHeader,
}: TemplateControlsProps) => {
  const [templateOptionsId, setTemplateOptionsId] = useState('');
  const { showConfirmPopup, closeConfirmPopup } = usePopupsContext();
  const [removeMutation] = useRemoveFieldsTemplateMutation({
    refetchQueries: [FindFieldsTemplatesDocument],
  });
  const { data: fieldsTemplates } = useFindFieldsTemplatesQuery();

  const items = useMemo(
    () =>
      fieldsTemplates?.findFieldsTemplates?.items?.map((item) => ({
        ...item,
        value: item.id,
        label: item.name,
      })) ?? [],
    [fieldsTemplates],
  );
  const selectedItem = useMemo(
    () => items?.find((val) => val?.id === templateOptionsId) ?? null,
    [items, templateOptionsId],
  );

  const addTemplateModal = useDisclosure();

  const onRemove = useCallback(
    async (id: string) => {
      try {
        await showConfirmPopup({
          title: 'Remove',
          description: (
            <Text fontSize="14px" mb="30px" px="20px" textAlign="center">
              Are you sure you want to remove this template?
            </Text>
          ),
          confirmButtonText: 'Remove',
          confirmButtonWidth: '90px',
          cancelButtonWidth: '90px',
        });
        await removeMutation({
          variables: {
            id,
          },
          context: {
            notify: {
              success: () => 'Successfully deleted template',
              error: (error: ApolloError[]) => error[0].message,
            },
          },
        });
        setTemplateOptionsId(null);
        closeConfirmPopup();
      } catch (error) {
        catchErrorLog(error, 'ApplyContactsToListButton/onRemove');
      }
    },
    [closeConfirmPopup, removeMutation, showConfirmPopup],
  );

  const onSubmit = useCallback(
    (item: FieldsTemplate) => {
      setTemplateOptionsId(item.id);
      const fields = item.fields.map((field) => {
        if (field.id === UNKNOWN_ID) {
          return null;
        }

        return field?.predefined ? field.name : field.id;
      });
      setHeader(fields);
    },
    [setHeader],
  );

  const canAddTemplate = !headers.every(isNil);

  return (
    <>
      <Flex alignItems="center" gap="20px">
        <Box minW="180px">
          <SelectField<FieldsTemplate>
            components={{ Option: CustomOption }}
            options={items}
            placeholder="Template"
            value={selectedItem}
            // @ts-expect-error "react-select" mismatch types
            width="290px"
            onChange={onSubmit}
            onRemoveTemplate={onRemove}
          />
        </Box>
        <Button
          isDisabled={!canAddTemplate}
          leftIcon={<PlusIcon />}
          minW="130px"
          variant="outlined"
          onClick={addTemplateModal.onOpen}>
          New template
        </Button>
      </Flex>

      <AddTemplateModal
        fields={headers}
        isOpen={addTemplateModal.isOpen}
        title="Add new template"
        onClose={addTemplateModal.onClose}
        onCreate={setTemplateOptionsId}
      />
    </>
  );
};
