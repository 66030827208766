import { Link, Switch, Text, useDisclosure } from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import { Features } from '@app/acl';
import {
  useAccountQuery,
  useUpdateMessagingProfileMutation,
} from '@app/api/gql/generated-types';
import { useDisableFeatureAbility } from '@app/hooks/useDisableFeatureAbility';
import { usePlanPermission } from '@app/hooks/usePlanPermission';
import { usePopupsContext } from '@app/hooks/usePopupsContext';
import ROUTES from '@app/utils/routes';
import { ContentPreview } from '@app/widgets/ContentPreview';

import { useUpdateMessagingProfileFragment } from '@app/api/hooks/useUpdateMessagingProfileFragment';
import { SettingsLayout } from '@app/layouts/SettingsLayout';
import { voiceTypeMap } from '../../lib/config';

import Tooltip from '../Tooltip';

import { CreateVoicemail } from './CreateVoicemail';
import { VoicemailFormValues } from './lib/types';

interface VoiceMailProps {
  isDialerPlanInactive: boolean;
}

export const VoiceMail: FC<VoiceMailProps> = ({ isDialerPlanInactive }) => {
  const { data: { account } = {} } = useAccountQuery();
  const { messagingProfile } = account;
  const { updateCallSettingsFragment } = useUpdateMessagingProfileFragment();

  const autoReplyModal = useDisclosure();
  const { isPrimitivePlans } = usePlanPermission();
  const { can: canVoicemail } = useDisableFeatureAbility(Features.Voicemail);
  const { showConfirmPopup, closeConfirmPopup } = usePopupsContext();

  const [updateMessagingProfile, { loading }] =
    useUpdateMessagingProfileMutation();

  const [isChecked, setIsChecked] = useState(
    canVoicemail && !!messagingProfile?.callSettings?.voiceMail?.enabled,
  );

  const handleSwitch = async () => {
    const enabled = !isChecked;
    setIsChecked(enabled);

    if (!messagingProfile?.callSettings?.voiceMail?.payload) {
      autoReplyModal.onOpen();
      return;
    }

    await updateMessagingProfile({
      variables: {
        input: {
          callSettings: {
            voiceMail: {
              enabled,
            },
          },
        },
      },
    });
  };

  const handleSave = async ({ voiceType, text }: VoicemailFormValues) => {
    updateCallSettingsFragment({
      id: messagingProfile?.id,
      callSettings: {
        voiceMail: {
          enabled: true,
          voiceType,
          payload: text,
        },
      },
    });
    await updateMessagingProfile({
      variables: {
        input: {
          callSettings: {
            voiceMail: {
              enabled: true,
              voiceType,
              payload: text,
            },
          },
        },
      },
    });

    autoReplyModal.onClose();
  };

  const handleDelete = async () => {
    try {
      await showConfirmPopup({
        title: 'Delete',
        description: (
          <Text marginBottom="20px" variant="confirm-text">
            Are you sure you want to delete this voicemail record? Once deleted,
            it cannot be restored.
          </Text>
        ),
        confirmButtonText: 'Confirm',
        confirmButtonWidth: '160px',
        hideCancelButton: true,
      });

      closeConfirmPopup();

      setIsChecked(false);

      await updateMessagingProfile({
        variables: {
          input: {
            callSettings: {
              voiceMail: null,
            },
          },
        },
      });
      updateCallSettingsFragment({
        id: messagingProfile?.id,
        callSettings: {
          voiceMail: null,
        },
      });
    } catch (error) {
      console.error('🚀 ~ handleDelete ~ error:', error);
    }
  };

  const handleEdit = () => {
    autoReplyModal.onOpen();
  };

  const handleClose = () => {
    autoReplyModal.onClose();
    if (!messagingProfile?.callSettings?.voiceMail?.payload) {
      setIsChecked(false);
    }
  };

  useEffect(() => {
    if (messagingProfile?.callSettings?.voiceMail?.enabled) {
      setIsChecked(true);
    }
  }, [messagingProfile?.callSettings?.voiceMail?.enabled]);

  return (
    <>
      <SettingsLayout
        alignItems="flex-start"
        childComponent={
          <>
            <Switch
              colorScheme="switchedBlue"
              disabled={isDialerPlanInactive}
              h="20px"
              isChecked={isChecked}
              name="voiceMail"
              w="38px"
              onChange={handleSwitch}
            />
            {isChecked &&
              !isDialerPlanInactive &&
              messagingProfile?.callSettings?.voiceMail?.payload && (
                <ContentPreview
                  isDeleting={loading}
                  text={messagingProfile?.callSettings?.voiceMail.payload}
                  title={`Voicemail — ${
                    voiceTypeMap[
                      messagingProfile?.callSettings?.voiceMail.voiceType
                    ]
                  }`}
                  onDelete={handleDelete}
                  onEdit={handleEdit}
                />
              )}
          </>
        }
        iconComponent={
          <Tooltip
            text={
              isPrimitivePlans ? (
                <Text width="200px">
                  Please{' '}
                  <Link
                    as={RouteLink}
                    to={`${ROUTES.settings}/membership`}
                    variant="link">
                    upgrade
                  </Link>{' '}
                  your Plan to enable voicemail
                </Text>
              ) : (
                'Allow contacts to leave a voice message when you are unavailable. You can listen to those in Messenger'
              )
            }
            widthContainer={isPrimitivePlans ? '200px' : '255px'}
          />
        }
        marginTop="30px"
        titleLabel="Voicemail"
      />
      {canVoicemail && autoReplyModal.isOpen && (
        <CreateVoicemail
          defaultValues={{
            text: messagingProfile?.callSettings?.voiceMail?.payload,
            voiceType: messagingProfile?.callSettings?.voiceMail?.voiceType,
          }}
          isOpen={autoReplyModal.isOpen}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
    </>
  );
};
