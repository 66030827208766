import { useCallback, useEffect, useRef } from 'react';

export function useDebounce<T extends (...args: any[]) => void>(
  callback: T,
  delayMs: number,
  dependencies: any[],
) {
  const latestCallbackRef = useRef<T>(callback);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    latestCallbackRef.current = callback;
  }, [callback]);

  return useCallback(
    (...args: Parameters<T>) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        latestCallbackRef.current(...args);
      }, delayMs);
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,react-hooks/exhaustive-deps
    [delayMs, ...dependencies],
  );
}
