import React, { FC, PropsWithChildren } from 'react';
import { Flex } from '@chakra-ui/react';

const MenuContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="flex-end"
      width={['100%', '300px']}>
      {children}
    </Flex>
  );
};

export default MenuContainer;
