import { Box, Grid } from '@chakra-ui/react';
import React, { FC } from 'react';

import { CallScriptFragment } from '@app/api/gql/generated-types';
import Popup from '@app/components/Popup';
import { ScriptTemplate } from '@app/pages/Campaigns/content/CallScripts/components/CallScriptModal/TextTemplate';
interface EditScriptModalProps {
  isOpen: boolean;
  onClose: () => void;
  script: CallScriptFragment;
  onSubmit?: () => void;
}

export const EditScriptModal: FC<EditScriptModalProps> = ({
  isOpen,
  onClose,
  script,
  onSubmit,
}) => {
  return (
    <Popup
      closeOnOverlayClick
      isOpen={isOpen}
      maxW="770px"
      title="Edit script"
      width="770px"
      onClose={onClose}>
      <Grid
        gap={3}
        justifyContent="center"
        p="25px 40px 40px"
        templateColumns={{
          base: 'repeat(1, 1fr)',
        }}>
        <Box>
          <ScriptTemplate
            initialValues={{
              ...script,
            }}
            onCloseModal={onClose}
            onEditSubmit={onSubmit}
          />
        </Box>
      </Grid>
    </Popup>
  );
};
