import React, { FC } from 'react';
import { Box, Button, Center, Text } from '@chakra-ui/react';
import type { UseDisclosureProps } from '@chakra-ui/react';

import Popup from '@app/components/Popup';

interface RemoveNotPossibleModalProps extends UseDisclosureProps {
  title: string;
  text: string;
}

export const RemoveNotPossibleModal: FC<RemoveNotPossibleModalProps> = ({
  title,
  text,
  isOpen,
  onClose,
}) => {
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="375px"
      title={title}
      width="100%"
      onClose={onClose}>
      <Box p="35px 40px 40px">
        <Text
          color="main.400"
          fontSize="14px"
          marginBottom="30px"
          textAlign="center">
          {text}
        </Text>
        <Center>
          <Button
            maxWidth="160px"
            variant="primary"
            width="100%"
            onClick={onClose}>
            Close
          </Button>
        </Center>
      </Box>
    </Popup>
  );
};
