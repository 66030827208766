import React, { FC, ReactNode, useState } from 'react';
import { Box, ListItem, SlideFade, Text } from '@chakra-ui/react';

import { isMobile } from 'react-device-detect';
import { ListItemWrapper } from '@app/pages/Messenger/components/Menu/styles';
import ArrowUpIcon from '@app/icons/arrow-up.svg?react';
import ArrowDownIcon from '@app/icons/arrow-down.svg?react';

import LoadMoreButton from '../LoadMoreButton';
import { LoadMoreButtonProps } from '../LoadMoreButton/types';

interface Props extends Partial<LoadMoreButtonProps> {
  isOpen?: boolean;
  title: string;
  isAnimate?: boolean;
  isLoadMore?: boolean;
  children: ReactNode;
}

const Details: FC<Props> = ({
  isOpen: initialIsOpen,
  title,
  isAnimate = false,
  children,
  isLoadMore = false,
  isFetchingNextPage,
  fetchNextPage,
  hasNextPage,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen || false);

  return (
    <Box w={['100%', '220px']}>
      <ListItem>
        <ListItemWrapper
          className={`${isOpen && !isMobile ? 'isOpenAndNotMobile' : ''} ${isMobile ? 'isMobile' : ''}`}
          onClick={() => setIsOpen(!isOpen)}>
          <Text color={isOpen && 'secondary.600'} fontWeight="500">
            {title}
          </Text>
          {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </ListItemWrapper>
      </ListItem>
      {isAnimate ? (
        <SlideFade in={isOpen} offsetY={-50}>
          {isOpen && children}
        </SlideFade>
      ) : (
        isOpen && children
      )}
      {isOpen && isLoadMore && (
        <Box m="0 16px 0 26px">
          <LoadMoreButton
            isFullWidth
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
          />
        </Box>
      )}
    </Box>
  );
};

export default Details;
