import { useEffect } from 'react';
import { ObjectSchema } from 'yup';
import useYup from './useYup';

declare type ValidationErrors<T> = {
  [K in keyof T]?: string;
};
declare type ValidationResult<T> = {
  errors: ValidationErrors<T>;
  isValid: boolean;
};
declare type UseYupOptions = {
  validateOnChange?: boolean;
};

export const useYupValidation = <T>(
  entityName: string,
  schema: ObjectSchema<T>,
  options: UseYupOptions = { validateOnChange: false },
): {
  validate: () => Promise<ValidationResult<T>>;
  errors: ValidationErrors<T>;
  isValid: boolean;
} => {
  const { errors, validate, isValid } = useYup(
    {
      entityName,
    },
    schema,
    options,
  );

  useEffect(() => {
    void (async () => {
      await validate();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityName]);

  return {
    validate,
    errors,
    isValid,
  };
};
