import {
  MessageType,
  IncomingMessageReceivedSubscription,
  MessageStatus,
  MessageStateReasonType,
} from '@app/api/gql/graphql';

export type TIncomingMessage =
  IncomingMessageReceivedSubscription['incomingMessageReceived'];

export const isNewMessageDelivered = (message: TIncomingMessage): boolean =>
  [MessageType.MMS, MessageType.SMS, MessageType.RVM].includes(message.type) &&
  message.status === MessageStatus.DELIVERED;

export const isMissedCall = (message: TIncomingMessage): boolean =>
  message.type === MessageType.CALL &&
  message.status === MessageStatus.CALL_MISSED;

export const isOptOutActivity = (message: TIncomingMessage): boolean =>
  message.statusReason === MessageStateReasonType.OPTED_OUT;
