import { Enum } from '@app/utils/enum';
import { colors } from '../colors';

export const TTextVariant = new Enum(
  'sub-heading',
  'secondary-text',
  'tertiary-title',
  'heading-fifth',
  'page-title',
  'list-style',
  'heading-5',
  'confirm-text',
  'label',
  'modal-title',
  'arsenic-60',
  'primary-azure',
  'dropdownOption',
  'dialog-text',
  'dialog-text-silver',
);

export const Text = {
  variants: {
    [TTextVariant.enum['modal-title']]: {
      fontSize: '14px',
      lineHeight: '16px',
      color: colors.main[400],
    },
    [TTextVariant.enum.dropdownOption]: {
      fontSize: '12px',
      lineHeight: '14px',
      color: colors.secondary[600],
    },
    [TTextVariant.enum['sub-heading']]: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '21px',
      color: colors.main[400],
    },
    [TTextVariant.enum['secondary-text']]: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: colors.secondary[400],
    },
    [TTextVariant.enum['tertiary-title']]: {
      fontWeight: 500,
      fontSize: '22px',
      lineHeight: '26px',
      color: colors.main[400],
    },
    [TTextVariant.enum['heading-fifth']]: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: colors.main[400],
    },
    [TTextVariant.enum['arsenic-60']]: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: colors.osloGray,
    },
    [TTextVariant.enum['primary-azure']]: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '16px',
      color: colors.primary[600],
    },
    'page-title': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: colors.main[400],
    },
    'list-style': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      color: colors.main[400],
    },
    'heading-5': {
      fontSize: '14px',
      fontWeight: 500,
    },
    'confirm-text': {
      color: colors.main[400],
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [TTextVariant.enum['dialog-text']]: {
      color: colors.main[400],
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
    },
    [TTextVariant.enum['dialog-text-silver']]: {
      color: colors.silver,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '14px',
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '14px',
      textTransform: 'none',
      margin: '5px 12px',
      color: 'rgba(0, 0, 0, 0.3)',
    },
  },
};
