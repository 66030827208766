import { IconButton, Tooltip, Link } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import LinkIcon from '@app/icons/link-icon.svg?react';
import ROUTES, { generateUrl } from '@app/utils/routes';
import { colors } from '@app/theme/colors';

interface OpenConversationInNewTabProps {
  id: string;
  profileId?: string;
}

export const OpenConversationInNewTab: FC<OpenConversationInNewTabProps> = ({
  id,
  profileId,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const completePath = generateUrl(
    ROUTES.messengerFullInfo,
    { id },
    profileId ? { profileId } : undefined,
  );

  return (
    <Tooltip
      hasArrow
      isOpen={isOpenModal}
      label="Open conversation in a new tab"
      placement="top">
      <Link
        href={completePath}
        target="_blank"
        onClick={() => {
          setIsOpenModal(false);
        }}
        onMouseOut={() => setIsOpenModal(false)}
        onMouseOver={() => setIsOpenModal(true)}>
        <IconButton
          isRound
          aria-label="ad-to-dnc-icon-button"
          icon={<LinkIcon />}
          size="sm"
          sx={{
            color: 'main.100',
            backgroundColor: colors.transparent,
          }}
          variant={isOpenModal ? 'iconButton' : ''}
        />
      </Link>
    </Tooltip>
  );
};
