import React, { FC } from 'react';
import { Td, Text } from '@chakra-ui/react';

import DateFormat from '@app/components/DateFormat';
import { SkeletonCell } from '@app/components/Table/Skeleton/SkeletonCell';

import { TIME_FORMAT_HH_MM_A } from '@app/utils/formatDate';

interface DateCellProps {
  date: Date;
  loading?: boolean;
}

const DateCell: FC<DateCellProps> = (props) => {
  const { date, loading } = props;

  if (!props?.date) {
    return (
      <Td>
        <SkeletonCell isLoading={loading}>&mdash;</SkeletonCell>
      </Td>
    );
  }
  return (
    <Td>
      <SkeletonCell isLoading={loading}>
        <Text color="main.400" fontSize="12px">
          <DateFormat date={date} />
        </Text>
        <Text color="cadetBlue" fontSize="12px">
          <DateFormat date={date} format={TIME_FORMAT_HH_MM_A} />
        </Text>
      </SkeletonCell>
    </Td>
  );
};

export default DateCell;
