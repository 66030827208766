import { Field } from '@app/api/gql/generated-types';
import { TableHeader } from '@app/api/queries/types';
import { Header } from '@app/types/Contact';

export const getHeader = (headers: TableHeader, val: string, index: number) => {
  const headerArr = [...headers];
  const isExists = headerArr[index] === val;
  const headerIndex = index + 1;

  const selected =
    (val as Header) !== Header.PHONE && headerArr.find((item) => item === val);
  const selectedPhones =
    (val as Header) === Header.PHONE &&
    headerArr.filter((item) => (item as Header) === Header.PHONE)?.length > 3;

  headerArr.splice(
    index,
    1,
    selectedPhones || selected || isExists ? `Head${headerIndex}` : val,
  );

  return headerArr;
};

export const getContactListHeader = (
  headers: TableHeader,
  headersMap: Record<string, Field>,
  val: string,
  index: number,
) => {
  const headerArr = [...headers];
  const isExists = headerArr[index] === val;
  const phoneVal = headersMap?.[val]?.name as Header;
  const selected =
    phoneVal !== Header.PHONE && headerArr.find((item) => item === val);
  const selectedPhones =
    phoneVal === Header.PHONE &&
    headerArr.filter(
      (item) => (headersMap?.[item]?.name as Header) === Header.PHONE,
    )?.length > 3;

  headerArr.splice(
    index,
    1,
    selectedPhones || selected || isExists ? null : val,
  );

  return headerArr;
};
