import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { Label } from '@app/api/gql/generated-types';

import { useQueryParams } from '@app/hooks/useQueryParams';
import { LabelListItem } from './LabelListItem';

interface LabelListProps {
  options: Label[];
  defaultValues: Label[];
  onChange: (labels: Label) => void;
}

export const MultiLabelsCheckbox: FC<LabelListProps> = ({
  options,
  defaultValues,
  onChange,
}) => {
  const [{ accountId }] = useQueryParams<{
    accountId: string;
  }>();
  const isMasterAccount = !!accountId;

  const selectedIds = defaultValues.map((option) => option.id);

  return (
    <Flex direction="column">
      {options.map((item) => (
        <LabelListItem
          key={item.id}
          isChecked={selectedIds.includes(item.id)}
          isMasterAccount={isMasterAccount}
          label={item}
          onChange={onChange}
        />
      ))}
    </Flex>
  );
};
