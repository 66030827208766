import React, { FC, useRef, useState } from 'react';
import { IconButton, ToastId, Tooltip, useToast } from '@chakra-ui/react';

import AddLabelsIcon from '@app/icons/messenger/add-labels.svg?react';
import RemoveLabelsIcon from '@app/icons/messenger/remove-labels.svg?react';
import {
  Conversation,
  ConversationFragmentDoc,
  Label,
  useAddLabelsToContactsMutation,
  useRemoveLabelsFromContactsMutation,
} from '@app/api/gql/generated-types';
import * as typenames from '@app/api/typenames';
import { useLabelsQueryData } from '@app/api/queries/useLabelsQueryData';
import { MultiLabelViewContainer } from '@app/components/LabelList/MultiLabelViewContainer';
import ToastNotify from '@app/components/ToastNotifier';
import { colors } from '@app/theme/colors';
import { UpdateMultiLabels } from '../../types';
import type { BulkActionButtonProps } from './types';

interface UpdateLabelsButtonProps extends BulkActionButtonProps {
  type: UpdateMultiLabels;
}

export const UpdateLabelsButton: FC<UpdateLabelsButtonProps> = ({
  type,
  selectedContacts,
  onComplete,
}) => {
  const [labels, setLabels] = useState<Label[]>([]);
  const isAdd = type === UpdateMultiLabels.ADD;

  const toast = useToast();
  const toastIdRef = useRef<ToastId>();

  const { data: labelsData, fetchNextPage } = useLabelsQueryData();
  const allLabels = labelsData as Label[];

  const [mutateAddLabels, { loading: addLabelsLoading }] =
    useAddLabelsToContactsMutation();

  const [mutateRemoveLabels, { loading: removeLabelsLoading }] =
    useRemoveLabelsFromContactsMutation();

  const handleSelectLabel = (selectedLabels: Label) => {
    if (labels.includes(selectedLabels)) {
      setLabels(labels.filter((label) => label.id !== selectedLabels.id));
      return;
    }
    setLabels((prev) => [...prev, selectedLabels]);
  };

  const closeToast = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  };

  const handleOnSave = async () => {
    const labelIds = labels.map((label) => label.id);
    toastIdRef.current = ToastNotify({
      title: 'Updating...',
      status: 'info',
    });
    setLabels([]);
    onComplete();
    if (isAdd) {
      await mutateAddLabels({
        variables: {
          input: {
            ids: selectedContacts,
            labelIds,
          },
        },
        update(cache, _result, { variables }) {
          variables?.input?.ids.forEach((id) => {
            cache.updateFragment<Conversation>(
              {
                id: `${typenames.Conversation}:${id}`,
                fragment: ConversationFragmentDoc,
                fragmentName: typenames.Conversation,
              },
              (data) => ({
                ...data,
                labels:
                  (_result.data?.addLabelsToContacts?.find(
                    (item) => item.id === id,
                  )?.labels as Label[]) || [],
              }),
            );
          });
        },
        context: {
          notify: {
            success: () => `The data is successfully updated`,
            error: () => `Labels failed to add to selected contacts`,
          },
        },
        onCompleted: () => {
          closeToast();
          setLabels([]);
        },
      });
    } else {
      await mutateRemoveLabels({
        variables: {
          input: {
            ids: selectedContacts,
            labelIds,
          },
        },
        update(cache, _result, { variables }) {
          variables?.input?.ids.forEach((id) => {
            cache.updateFragment<Conversation>(
              {
                id: `${typenames.Conversation}:${id}`,
                fragment: ConversationFragmentDoc,
                fragmentName: typenames.Conversation,
              },
              (data) => ({
                ...data,
                labels:
                  (_result.data?.removeLabelsFromContacts?.find(
                    (item) => item.id === id,
                  )?.labels as Label[]) || [],
              }),
            );
          });
        },
        context: {
          notify: {
            success: () => `The data is successfully updated`,
            error: () => `Labels failed to remove from selected contacts`,
          },
        },
        onCompleted: () => {
          closeToast();
          setLabels([]);
        },
      });
    }
    setLabels([]);
    onComplete();
  };

  const handleOnClose = () => {
    setLabels([]);
  };

  const toolTipText = isAdd
    ? 'Add labels to selected contacts'
    : 'Remove labels from selected contacts';

  return (
    <MultiLabelViewContainer
      fetchNextPage={fetchNextPage}
      isLoading={isAdd ? addLabelsLoading : removeLabelsLoading}
      labels={allLabels}
      selectedLabels={labels}
      type={type}
      onChange={handleSelectLabel}
      onClose={handleOnClose}
      onSave={handleOnSave}>
      {({ isOpen }) => {
        return (
          <Tooltip hasArrow label={toolTipText} placement="top">
            <IconButton
              isRound
              _hover={{ color: 'primary.600', bgColor: 'primary.200' }}
              aria-label={toolTipText}
              bgColor={isOpen ? 'primary.200' : colors.transparent}
              color={isOpen ? 'primary.600' : 'main.100'}
              isLoading={isAdd ? addLabelsLoading : removeLabelsLoading}
              size="xs">
              {isAdd ? <AddLabelsIcon /> : <RemoveLabelsIcon />}
            </IconButton>
          </Tooltip>
        );
      }}
    </MultiLabelViewContainer>
  );
};
