import { Enum } from '@app/utils/enum';
import { getLogger } from '@app/utils/logger';
import { TStdBooleanValue } from '@app/types/QueryParam';

const LOG = getLogger('envUtils');

export const LOCALHOST = 'localhost';
export const DEV_ENV_ROOT_DOMAIN = 'dev.smartercontact.com';
export const STAGE_ENV_ROOT_DOMAIN = 'stg.smartercontact.com';
export const PRO_ENV_ROOT_DOMAIN = 'app.smartercontact.com';
const NODE_ENV = { ...import.meta.env };

/**
 * Represents the environment in which the application is running.
 * This is an enumeration with three possible values:
 * - development: Represents the development environment.
 * - production: Represents the production environment.
 *
 * @class
 */
export const TEnvironment = new Enum('development', 'production');

/**
 * Retrieves the value of a configuration key.
 *
 * @template T - The type of the configuration value. Default type is string.
 *
 * @param {string} key - The key of the configuration value.
 * @param {boolean} [isMandatory=true] - Indicates whether the configuration value is mandatory.
 *
 * @throws {Error} - If the mandatory configuration value is not found.
 *
 * @returns {T | undefined} - The value of the configuration key, or undefined if not found.
 */
export const getConfigValue = <T = string>(
  key: string,
  isMandatory: boolean = true,
): T | undefined => {
  const envValue = (
    typeof NODE_ENV !== 'undefined' ? import.meta.env.MODE : undefined
  ) as T | undefined;

  const windowValue =
    typeof window !== 'undefined'
      ? (window[key as keyof Window] as T)
      : undefined;

  const value = windowValue || envValue;

  if (!value && isMandatory) {
    throw new Error(`Config value not found for key: ${key}`);
  } else if (!value) {
    LOG.warn(`Config value not found for key: ${key}`);
  }

  return value;
};

/**
 * Retrieves the current environment variable.
 *
 * @returns {string} The current environment variable.
 */
export const getEnv = (): string => {
  return LOG.return(
    TEnvironment.matchPartial(
      getConfigValue(import.meta.env.MODE),
      {
        [TEnvironment.enum.development]: () => TEnvironment.enum.development,
        [TEnvironment.enum.production]: () => TEnvironment.enum.production,
      },
      TEnvironment.enum.development,
    ),
  );
};

/**
 * Determines if the current environment is development.
 *
 * @type {boolean}
 */
export const isDevelopment: boolean = import.meta.env.DEV;

/**
 * Determines if the current environment is production.
 *
 * @returns {boolean} True if the environment is production, false otherwise.
 */
export const isProduction: boolean = import.meta.env.PROD;

/**
 * Checks if the current hostname is localhost.
 *
 * @type {boolean}
 * @name isLocalhost
 * @readonly
 */
export const isLocalhost: boolean =
  window.location.hostname.includes(LOCALHOST);

/**
 * A boolean flag indicating whether the application is in maintenance mode.
 * This flag is determined by the value of the VITE_MAINTENANCE_MODE environment variable.
 *
 * @constant
 * @type {boolean}
 */
export const isApplicationInMaintenanceMode: boolean =
  getConfigValue(import.meta.env.VITE_MAINTENANCE_MODE, false) ===
  TStdBooleanValue.enum.true;

/**
 * Retrieves the root domain based on the environment.
 *
 * @returns {string} The root domain based on the environment.
 */
export const getEnvRootDomain = (): string => {
  if (isLocalhost) {
    return LOCALHOST;
  } else if (isDevelopment) {
    return DEV_ENV_ROOT_DOMAIN;
  } else if (isProduction) {
    return PRO_ENV_ROOT_DOMAIN;
  } else {
    return STAGE_ENV_ROOT_DOMAIN;
  }
};

LOG.debug(
  `environment: ${getEnv()}, isDevelopment: ${isDevelopment}, isProduction: ${isProduction}, isLocalhost: ${isLocalhost}, rootDomain: ${getEnvRootDomain()}`,
);
