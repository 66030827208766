import React, { FC } from 'react';
import { Button, Text } from '@chakra-ui/react';

import { LoadMoreButtonProps } from './types';

const LoadMoreButton: FC<LoadMoreButtonProps> = ({
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  isFullWidth = false,
}) => {
  if (hasNextPage && !isLoading) {
    return (
      <Button
        isDisabled={isFetchingNextPage}
        isLoading={isLoading}
        loadingText="Load more"
        mb="18px"
        mt={!isFullWidth && '18px'}
        variant={hasNextPage ? 'outlined' : 'link'}
        width={isFullWidth ? 'full' : '260px'}
        onClick={fetchNextPage}>
        Load more
      </Button>
    );
  }

  if (!isFullWidth && !hasNextPage && !isLoading) {
    return (
      <Text color="bombay" fontSize="sm" fontWeight={500} mb="20px" mt="20px">
        You&apos;ve reached the end of the list
      </Text>
    );
  }

  return null;
};

export default LoadMoreButton;
