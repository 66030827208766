import { Call as TwilioCall, Device } from '@twilio/voice-sdk';
import { IncomingApplicationCall, InfobipRTC } from 'infobip-rtc';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import DialerContext from '@app/contexts/DialerContext';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import { Call, DialerStatus } from '@app/types/Dialer';
import {
  CallRecordingAction,
  MessagingProviderType,
  useGetCurrentCallLazyQuery,
  useManageCallRecordingMutation,
} from '@app/api/gql/generated-types';
import { getLogger } from '@app/utils/logger';

const LOG = getLogger('DialerProvider');

export const DialerProvider: FC<PropsWithChildren> = ({ children }) => {
  const { messagingProfile } = useCurrentAccountData();
  const [manageCallRecordingMutation] = useManageCallRecordingMutation();

  const twilioRef = useRef<Device>(null);
  const twilioCallInstance = useRef<TwilioCall>(null);

  const infobipRef = useRef<InfobipRTC>(null);
  const infobipCallInstance = useRef<IncomingApplicationCall>(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentCallDetails, setCurrentCallDetails] = useState<Call>(null);
  const [dialerStatus, setDialerStatus] = useState<DialerStatus>(
    DialerStatus.ONCALL,
  );
  const [isMicMuted, setIsMicMuted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const toggleMic = useCallback(async () => {
    if (
      messagingProfile?.provider?.type === MessagingProviderType.TWILIO ||
      messagingProfile?.provider?.type === MessagingProviderType.COMMIO
    ) {
      if (isMicMuted) {
        twilioCallInstance.current.mute(false);
      } else {
        twilioCallInstance.current.mute(true);
      }
    }

    if (messagingProfile?.provider?.type === MessagingProviderType.INFOBIP) {
      await infobipCallInstance.current.mute(!isMicMuted);
    }

    setIsMicMuted((prevState) => !prevState);
  }, [isMicMuted, messagingProfile?.provider?.type]);

  const [getCurrentCallId] = useGetCurrentCallLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const toggleRecording = useCallback(async () => {
    if (!currentCallDetails) return;

    if (!currentCallDetails.contactId) {
      LOG.error(`Missing contactId in currentCallDetails!`);
      return;
    }

    const callId = await getCurrentCallId({
      variables: {
        contactId: currentCallDetails?.contactId,
      },
    });

    await manageCallRecordingMutation({
      variables: {
        input: {
          id: callId.data?.getCurrentCall?.id,
          action: isRecording
            ? CallRecordingAction.STOP
            : CallRecordingAction.START,
        },
      },
    });

    if (isRecording) {
      setIsRecording(false);
    } else {
      setIsRecording(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCallDetails, isRecording]);

  const stopRecording = useCallback(async () => {
    if (!currentCallDetails || !isRecording) {
      setIsRecording(false);
      return;
    }

    if (!currentCallDetails.contactId) {
      LOG.error(`Missing contactId in currentCallDetails!`);
      return;
    }

    const callId = await getCurrentCallId({
      variables: {
        contactId: currentCallDetails?.contactId,
      },
    });

    await manageCallRecordingMutation({
      variables: {
        input: {
          id: callId.data?.getCurrentCall?.id,
          action: CallRecordingAction.STOP,
        },
      },
    });

    setIsRecording(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCallDetails]);

  const providerValue = useMemo(
    () => ({
      twilioInstance: twilioRef,
      twilioCallInstance,
      infobipInstance: infobipRef,
      infobipCallInstance,
      isMicMuted,
      isLoggedIn,
      currentCall: currentCallDetails,
      status: dialerStatus,
      toggleMic,
      isRecording,
      toggleRecording,
      stopRecording,
      setCurrentCallDetails,
      setIsLoggedIn,
      setDialerStatus,
      providerType: messagingProfile?.provider?.type,
    }),
    [
      isMicMuted,
      isLoggedIn,
      currentCallDetails,
      dialerStatus,
      toggleMic,
      isRecording,
      toggleRecording,
      stopRecording,
      messagingProfile?.provider?.type,
    ],
  );

  return (
    <DialerContext.Provider value={providerValue}>
      {children}
    </DialerContext.Provider>
  );
};

export const useDialerContext = () => {
  const context = useContext(DialerContext);

  return context;
};
