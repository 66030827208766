import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { isSubmitDisabled } from '@app/utils/validation';

export const FormSubmit: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const formik = useFormikContext();
  const isDisabled = isSubmitDisabled(formik, false, []);
  return (
    <Button
      isDisabled={isDisabled || isLoading}
      isLoading={isLoading}
      loadingText="Create group"
      type="submit"
      variant="primary"
      width="100%">
      Create group
    </Button>
  );
};
