import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import upperFirst from 'lodash/upperFirst';
import { ApolloError } from '@apollo/client';
import { Features, PermissionActions, PermissionFeatures } from '@app/acl';
import {
  Field,
  GroupViewFragment,
  ListType,
  ListView,
  useVerifyListMutation,
  ListVerificationResult,
  useListVerificationProgressSubscription,
  useListVerificationCompletedSubscription,
} from '@app/api/gql/generated-types';
import ToastNotify from '@app/components/ToastNotifier';
import { useDisableFeatureAbility } from '@app/hooks/useDisableFeatureAbility';
import { Header } from '@app/types/Contact';
import { PermissionButton } from '@app/components/next/atoms/PermissionsComponents/WithPermission';
import { useWatchdogTimer } from '@app/hooks/useWatchdogTimer';
import { PreparingListModal } from '@app/pages/Contacts/components/Modal/PreparingListModal';
import { ContactSettings } from '../Modal/ContactSettings';
import { ChoosePhone } from '../Modal/ChoosePhone';
import { PaymentModal } from '../Modal/PaymentModal';
import { ResultModal } from '../Modal/ResultModal';
import { ContactVerificationModal } from '../Modal/ContactVerificationModal';

interface ApplyContactTemplateProps {
  contactList: ListView;
  group: GroupViewFragment;
  headers: string[];
  contactListId: string;
  totalContacts: number;
  headersMap: Record<string, Field>;
  onSaveHeaders: () => Promise<void>;
  refetchRawContacts: () => void;
}

export const ApplyContactsToListButton: FC<ApplyContactTemplateProps> = ({
  contactList,
  group,
  headers,
  contactListId,
  headersMap,
  onSaveHeaders,
  refetchRawContacts,
  totalContacts,
}) => {
  const { can: canFreeLookup } = useDisableFeatureAbility(Features.FreeLookup);

  const choosePhoneModal = useDisclosure();
  const contactSettingsModal = useDisclosure();
  const resultModal = useDisclosure();
  const preparingListModal = useDisclosure();
  const verifyingListModal = useDisclosure();
  const paymentModal = useDisclosure();

  const [verificationList, setVerificationList] =
    useState<ListVerificationResult>();

  const [verifyListMutation] = useVerifyListMutation();

  const requiredHeaders = useMemo(
    () =>
      [
        headersMap?.[Header.PHONE],
        headersMap?.[Header.FIRST_NAME],
        headersMap?.[Header.LAST_NAME],
      ]
        .filter((field) => headers.indexOf(field?.id) === -1)
        ?.map((field) => upperFirst(field?.name?.toLowerCase())) ?? [],
    [headers, headersMap],
  );

  /**
   * The behaviour we want here is that when we start verifying, on the first
   * update the ContactVerificationModal.
   * If that takes longer than 3 seconds, we show the PreparingListModal.
   * Refs: https://smartercontact.atlassian.net/browse/SC-9987
   */

  const timer = useWatchdogTimer(3000);
  const [progress, setProgress] = useState(0);

  const closeAllModals = () => {
    choosePhoneModal.onClose();
    contactSettingsModal.onClose();
    resultModal.onClose();
    preparingListModal.onClose();
    verifyingListModal.onClose();
    paymentModal.onClose();
  };

  useListVerificationProgressSubscription({
    onData: ({ data }) => {
      if (data?.data.listVerificationProgress?.listId === contactListId) {
        setProgress(data?.data.listVerificationProgress?.progress || 0);
        timer.reset();

        closeAllModals();
        verifyingListModal.onOpen();
      }
    },
  });

  useListVerificationCompletedSubscription({
    onData: ({ data }) => {
      if (data.data.listVerificationCompleted.listId === contactListId) {
        setVerificationList(data?.data?.listVerificationCompleted);
        timer.reset();

        closeAllModals();
        resultModal.onOpen();
      }
    },
  });

  const verifyList = async () => {
    timer.start();

    try {
      await verifyListMutation({
        variables: { id: contactListId },
        context: {
          notify: {
            error: (error: ApolloError[]) => error?.[0]?.message,
          },
        },
      });
    } catch {
      timer.reset();
      closeAllModals();
    }
  };

  useEffect(() => {
    if (timer.timedOut) {
      contactSettingsModal.onClose();
      preparingListModal.onOpen();
    }
  }, [timer.timedOut, contactSettingsModal, preparingListModal]);

  const handleRequiredHeaders = async () => {
    if (requiredHeaders.length) {
      choosePhoneModal.onOpen();
      return false;
    }
    contactSettingsModal.onOpen();
    await onSaveHeaders();
    return true;
  };

  const confirmApplyListToContact = async () => {
    try {
      const hasRequiredHeaders = await handleRequiredHeaders();
      if (!hasRequiredHeaders) {
        return;
      }
    } catch (error) {
      ToastNotify({
        status: 'error',
        position: 'top-right',
        title: 'Oops something went wrong',
      });
    }
  };

  const isSkiptracing = contactList?.type === ListType.SKIPTRACE;
  const handleSuccess = isSkiptracing
    ? contactSettingsModal.onOpen
    : confirmApplyListToContact;

  return (
    <>
      <PermissionButton
        action={PermissionActions.UPLOAD}
        subject={PermissionFeatures.contacts.contacts}
        variant="primary"
        onSuccess={handleSuccess}>
        Apply
      </PermissionButton>

      <ChoosePhone
        headers={requiredHeaders}
        isOpen={choosePhoneModal.isOpen}
        onClose={choosePhoneModal.onClose}
      />
      <ContactSettings
        contactList={contactList}
        isOpen={contactSettingsModal.isOpen}
        startTimer={timer.start}
        verifyList={canFreeLookup ? verifyList : paymentModal.onOpen}
        onClose={contactSettingsModal.onClose}
      />
      <PaymentModal
        contacts={group?.contacts || 0}
        isOpen={paymentModal.isOpen}
        onClose={paymentModal.onClose}
        onConfirmLookup={verifyList}
      />
      <PreparingListModal
        contactsCount={totalContacts}
        isOpen={preparingListModal.isOpen}
      />
      <ContactVerificationModal
        contactsCount={totalContacts}
        isOpen={verifyingListModal.isOpen}
        progress={progress}
      />
      <ResultModal
        contactListId={contactListId}
        isOpen={resultModal.isOpen}
        refetchRawContacts={refetchRawContacts}
        title="Lookup results"
        verificationList={verificationList}
        onClose={resultModal.onClose}
      />
    </>
  );
};
