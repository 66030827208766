import React from 'react';
import { Image } from '@chakra-ui/react';

import DefaultCard from '@app/icons/defaultCard.svg?react';
import Visa from '@app/icons/visa.svg?react';
import Discover from '@app/icons/discover.svg?react';
import JCB from '@app/icons/jcb.svg?react';
import MasterCard from '@app/icons/masterCard.svg?react';
import UnionPay from '@app/icons/unionPay.svg?react';
import AmericanExpress from '@app/icons/americanExpress.svg?react';

interface CardBrandIconProps {
  brand: string;
  width?: string;
  height?: string;
}

const CardType = {
  VISA: 'visa',
  DINERS_CLUB: 'diners club',
  DISCOVER: 'discover',
  JCB: 'jcb',
  MASTERCARD: 'mastercard',
  UNIONPAY: 'unionpay',
  AMERICAN_EXPRESS: 'american express',
  AMEX: 'amex',
  DEFAULT: 'default',
};

const CardBrandIcon = ({
  brand,
  width = '20px',
  height = '20px',
}: CardBrandIconProps): JSX.Element => {
  switch (brand?.toLowerCase()) {
    case CardType.VISA:
      return <Visa height={height} width={width} />;
    case CardType.DINERS_CLUB:
      return (
        <Image
          height={height}
          src="/images/icons/cardsBrandIcon/diners-club.svg"
          width={width}
        />
      );
    case CardType.DISCOVER:
      return <Discover height={height} width={width} />;
    case CardType.JCB:
      return <JCB height={height} width={width} />;
    case CardType.MASTERCARD:
      return <MasterCard height={height} width={width} />;
    case CardType.UNIONPAY:
      return <UnionPay height={height} width={width} />;
    case CardType.AMEX:
    case CardType.AMERICAN_EXPRESS:
      return <AmericanExpress height={height} width={width} />;
    default:
      return <DefaultCard height={height} width={width} />;
  }
};

export default CardBrandIcon;
