import { Flex } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';

import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import { useUserPhoneNumbersReport } from '@app/hooks/useUserPhoneNumbersReport';
import { Scope } from '@app/api/gql/generated-types';
import { SettingsLayout } from '@app/layouts/SettingsLayout';
import { TrialNumbers } from '../TrialNumbers';
import { BuyNumber } from './BuyNumber';
import { OwnedNumbersTable } from './OwnedNumbersTable';

export interface PhoneNumbersProps {
  isDialerPlanInactive: boolean;
}

export const PhoneNumbers: FC<PhoneNumbersProps> = ({
  isDialerPlanInactive,
}) => {
  const { isDialerPlanTrialing } = useCurrentAccountData();

  const { ownedNumbers: data, loading: isFetching } = useUserPhoneNumbersReport(
    Scope.DIALER,
  );

  const isShowTrialNumbersSelect = useMemo(
    () => isDialerPlanTrialing && !data?.length,
    [data, isDialerPlanTrialing],
  );

  if (isShowTrialNumbersSelect) {
    return <TrialNumbers />;
  }

  return (
    <>
      {!isDialerPlanTrialing && (
        <Flex maxW="644px">
          <SettingsLayout
            childComponent={
              <BuyNumber isDialerPlanInactive={isDialerPlanInactive} />
            }
            iconComponent={null}
            marginTop="20px"
            titleLabel="State #"
          />
        </Flex>
      )}
      <Flex maxW="644px" mr="40px">
        <SettingsLayout
          alignItems="flex-start"
          childComponent={
            <OwnedNumbersTable data={data} isFetching={isFetching} />
          }
          marginTop="40px"
          titleLabel="Owned numbers"
        />
      </Flex>
    </>
  );
};
