export const baseStyle = {
  borderColor: 'primary.500',
  backgroundColor: 'zircon',
};

export const acceptStyle = {
  borderColor: 'apple',
  backgroundColor: 'aquaSpring',
};

export const rejectStyle = {
  borderColor: 'error',
  backgroundColor: 'soapstone',
};

export const disabledStyle = {
  cursor: 'not-allowed',
  borderColor: 'silver',
  backgroundColor: 'zircon',
};
