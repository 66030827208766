import React from 'react';
import {
  AutoSizer,
  IndexRange,
  InfiniteLoader,
  List,
  ListRowProps,
  ScrollParams,
} from 'react-virtualized';
import { Box } from '@chakra-ui/react';
import { getLogger } from '@app/utils/loggerUtils';
import { TScrollToAlignment } from '@app/types/Virtualize';

interface IInfiniteLoadingProps<T> {
  isNextPageLoading: boolean;
  listLength: number;
  rowHeight: number;
  rows: T[];
  onScroll?: (params: ScrollParams) => void;
  loadNextPage: ({ startIndex, stopIndex }: IndexRange) => Promise<void>;
  renderRow: (row: T) => React.ReactNode;
  renderLoading: (key: string, style: React.CSSProperties) => React.ReactNode;
  scrollToAlignment?: typeof TScrollToAlignment.type;
}

const LOG = getLogger('InfiniteLoading');

export const InfiniteLoadingList = <T,>({
  isNextPageLoading,
  rows,
  loadNextPage,
  listLength,
  renderRow,
  renderLoading,
  onScroll,
  rowHeight,
  scrollToAlignment = TScrollToAlignment.enum.auto,
}: IInfiniteLoadingProps<T>) => {
  const isRowLoaded = ({ index }: { index: number }) => index < rows.length;

  const loadMoreRows = (range: IndexRange) => {
    if (!isNextPageLoading) {
      LOG.return('loading next page', range);
      return loadNextPage(range);
    }

    return Promise.resolve();
  };

  const rowRenderer = ({ index, style, key }: ListRowProps) =>
    rows[index] ? (
      <Box key={key} style={style}>
        {renderRow(rows[index])}
      </Box>
    ) : (
      renderLoading?.(key, style) || null
    );

  const scrollTop = TScrollToAlignment.matchPartial(
    scrollToAlignment,
    {
      [TScrollToAlignment.enum.start]: () => 0,
    },
    undefined,
  );

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={listLength}>
      {({
        onRowsRendered,
        registerChild,
      }: {
        onRowsRendered: (params: IndexRange) => void;
        registerChild: React.Ref<List>;
      }) => (
        <AutoSizer disableHeight={!listLength}>
          {({ height, width }: { height: number; width: number }) => {
            LOG.return('AutoSizer', { height, width });

            return (
              <List
                ref={registerChild}
                height={height}
                rowCount={rows.length}
                rowHeight={rowHeight}
                rowRenderer={rowRenderer}
                scrollTop={scrollTop}
                width={width}
                onRowsRendered={onRowsRendered}
                onScroll={onScroll}
              />
            );
          }}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};
