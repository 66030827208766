/* eslint-disable react/jsx-key */
import React, { RefObject } from 'react';
import { useTable, Column, UseTableRowProps, Cell } from 'react-table';
import isFunction from 'lodash/isFunction';

import { colors } from '@app/theme/colors';
import { stickyHeadStyles, stickyRowStyles } from './styles';
import { BaseTable } from './BaseTable';
import { BaseTableProps } from './types';

interface TableListProps<D extends object> {
  data: D[];
  columns: Column<D>[];
  tableHeadBackground?: string;
  // TODO: Better typings
  getTrProps?: (props: UseTableRowProps<D>) => {
    backgroundColor?: string;
    isHoverRow?: boolean;
  };
  isStickyHead?: boolean;
  isInfiniteList?: boolean;
  isLoadingNextPage?: boolean;
  selectedItems?: string[];
  tableRef?: RefObject<HTMLTableElement>;
}

const TableList = <T extends object>({
  columns,
  data = [],
  getTrProps,
  tableHeadBackground = 'none',
  isStickyHead = false,
  isLoadingNextPage = false,
  tableRef = null,
  selectedItems,
}: TableListProps<T>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<T>({
      columns,
      data,
    });

  const getTableRowProps: BaseTableProps<T>['getTableRowProps'] = (props) => {
    if (isFunction(getTrProps)) {
      return getTrProps(props);
    }
    return {};
  };

  const tableHeadProps = isStickyHead ? stickyHeadStyles : {};

  const getCellBg = (cell: Cell<T>): string => {
    const rowProps = getTableRowProps(cell.row);
    if (rowProps?.backgroundColor) {
      return rowProps?.backgroundColor;
    }
    // @ts-expect-error Tables sticky props field
    if (cell.column?.sticky) {
      return stickyRowStyles.backgroundColor;
    }
    if (
      selectedItems?.includes(cell?.row?.original?.id as string | undefined)
    ) {
      return 'primary.100';
    }
    return colors.transparent;
  };

  return (
    <BaseTable
      getCellBg={getCellBg}
      getTableBodyProps={getTableBodyProps}
      getTableProps={getTableProps}
      getTableRowProps={getTableRowProps}
      headerGroups={headerGroups}
      isLoadingNextPage={isLoadingNextPage}
      prepareRow={prepareRow}
      rows={rows}
      tableHeadBackground={tableHeadBackground}
      tableHeadProps={tableHeadProps}
      tableRef={tableRef}
    />
  );
};

export default TableList;
