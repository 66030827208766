import { Enum } from '@app/utils/enum';
import { colors } from '../colors';

export const ModalVariant = new Enum('meeting');

export const Modal = {
  parts: ['content'],
  baseStyle: {
    dialog: {
      borderRadius: '20px',
    },
  },
  variants: {
    [ModalVariant.enum.meeting]: {
      dialog: {
        bg: colors.transparent,
        boxShadow: 'none',
        width: '950px',
        maxW: '950px',
      },
      overlay: {
        bg: 'rgba(255, 255, 255, 0.8)',
      },
    },
  },
};
