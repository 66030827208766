import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { getColorVar } from '@chakra-ui/theme-tools';
import { Enum } from '@app/utils/enum';
import { TThemeSize } from '@app/theme/components/size';

export const TTabsVariant = new Enum('arsenic', 'arsenicMobile', 'blue');

const multiStyleConfigHelpers = createMultiStyleConfigHelpers(parts.keys);

const variantArsenic = multiStyleConfigHelpers.definePartsStyle(
  ({ theme }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    tablist: {
      justifyContent: 'center',
      borderRadius: 'full',
      bg: getColorVar(theme, 'mystic') as string,
    },
    tab: {
      color: getColorVar(theme, 'secondary.400') as string,
      borderRadius: 'full',
      fontSize: TThemeSize.enum.sm,
      _selected: {
        color: getColorVar(theme, 'white') as string,
        bg: getColorVar(theme, 'main.400') as string,
      },
    },
  }),
);

const variantArsenicMobile = multiStyleConfigHelpers.definePartsStyle(
  ({ theme }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    tablist: {
      borderRadius: 'full',
      bg: getColorVar(theme, 'mystic') as string,
      justifyContent: 'center',
    },
    tabpanel: {
      padding: '0',
    },
    tab: {
      width: '100%',
      color: getColorVar(theme, 'secondary.400') as string,
      borderRadius: 'full',
      fontSize: TThemeSize.enum.sm,
      _selected: {
        color: getColorVar(theme, 'white') as string,
        bg: getColorVar(theme, 'main.400') as string,
      },
    },
  }),
);

const variantBlue = multiStyleConfigHelpers.definePartsStyle((theme) => ({
  root: {
    width: '100%',
  },
  tablist: {
    padding: '0 23px',
    borderBottom: '1px solid',
    borderBottomColor: getColorVar(theme, 'mystic') as string,
    color: getColorVar(theme, 'secondary.400') as string,
  },
  tab: {
    fontSize: '14px',
    padding: '17px 0 11px',
    margin: '0 10px',
    borderBottom: '2px solid',
    borderBottomColor: getColorVar(theme, 'white') as string,
    _selected: {
      borderBottom: '2px solid',
      borderBottomColor: getColorVar(theme, 'primary.600') as string,
      color: getColorVar(theme, 'main.400') as string,
    },
  },
  tabpanel: {
    padding: '0',
  },
}));

export const Tabs = {
  variants: {
    [TTabsVariant.enum.arsenic]: variantArsenic,
    [TTabsVariant.enum.arsenicMobile]: variantArsenicMobile,
    [TTabsVariant.enum.blue]: variantBlue,
  },
};
