import React, { FC, useMemo } from 'react';
import {
  Box,
  Flex,
  Text,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import ArrowUpIcon from '@app/icons/arrow-up-icon.svg?react';
import ArrowDownIcon from '@app/icons/arrow-down-icon.svg?react';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import {
  CUSTOM_THRESHOLD,
  TRAIL_THRESHOLD,
} from '@app/constants/configuration';
import { colors } from '@app/theme/colors';
import { BonusLabel } from './BonusLabel';

const bonusLabelVariants = {
  20: {
    label: 'Get $20 bonus',
    color: 'orangeBlaze',
    backgroundColor: 'linen',
  },
  50: {
    label: 'Get $50 bonus',
    color: 'orangeBlaze',
    backgroundColor: 'linen',
  },
};

interface ChargeCustomProps {
  fieldName?: string;
  isDisabled?: boolean;
  showBonusLabel?: boolean;
}

const ChargeCustom: FC<ChargeCustomProps> = ({
  fieldName = 'amount',
  isDisabled = false,
  showBonusLabel = false,
}) => {
  const { isMessagePlanTrialing } = useCurrentAccountData();
  const { values, setFieldValue } = useFormikContext();
  const amount: number = (values as Record<string, number>)[fieldName];

  const bonusLabelConfig = useMemo(() => {
    if (amount >= 300 && amount < 500) {
      return bonusLabelVariants[20];
    }
    if (amount >= 500) {
      return bonusLabelVariants[50];
    }

    return null;
  }, [amount]);

  const handleCharge = async (val: string) => {
    if (isMessagePlanTrialing && Number(val) > TRAIL_THRESHOLD) {
      await setFieldValue(fieldName, TRAIL_THRESHOLD);
      return;
    }

    await setFieldValue(fieldName, Number(val));
  };
  const isAmountBelowThreshold = amount < CUSTOM_THRESHOLD;

  return (
    <NumberInput
      _focus={{
        color: 'main.400',
      }}
      height="40px"
      isDisabled={isDisabled}
      isInvalid={isAmountBelowThreshold && false}
      mb="20px"
      min={CUSTOM_THRESHOLD}
      position="relative"
      step={5}
      value={amount}
      width="100%"
      onChange={handleCharge}>
      <Flex
        alignItems="center"
        color="secondary.400"
        fontSize="14px"
        h="40px"
        left="22px"
        position="absolute">
        $
      </Flex>
      <NumberInputField
        _focus={{
          borderColor: 'primary.600',
          boxShadow: '0 0 0 transparent',
        }}
        borderColor="secondary.200"
        borderRadius="20px"
        fontSize="14px"
        pl="32px"
      />
      {showBonusLabel && bonusLabelConfig && (
        <Box
          left={`calc(10% + ${amount.toString().length}ch)`}
          position="absolute"
          top="50%"
          transform="translateY(-50%)">
          <BonusLabel
            backgroundColor={bonusLabelConfig.backgroundColor}
            color={bonusLabelConfig.color}
            label={bonusLabelConfig.label}
          />
        </Box>
      )}
      {isAmountBelowThreshold && (
        <Text color={colors.error} fontSize="12px">
          The minimum required recharge amount is ${CUSTOM_THRESHOLD}.
        </Text>
      )}
      <NumberInputStepper mr="14px">
        <NumberIncrementStepper border="none">
          <ArrowUpIcon />
        </NumberIncrementStepper>
        <NumberDecrementStepper border="none">
          <ArrowDownIcon />
        </NumberDecrementStepper>
      </NumberInputStepper>
    </NumberInput>
  );
};

export default ChargeCustom;
