import React from 'react';
import { isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { Badge, Box } from '@chakra-ui/react';
import ROUTES, { generateUrl } from '@app/utils/routes';
import CampaignStartedIcon from '@app/icons/notifications/campaign-started-icon.svg?react';
import CampaignEndedIcon from '@app/icons/notifications/campaign-ended-icon.svg?react';
import PaymentFailedIcon from '@app/icons/notifications/payment-failed-icon.svg?react';
import AIInsightIcon from '@app/icons/notifications/ai-insight-icon.svg?react';
import CalendarNotificationIcon from '@app/icons/notifications/calendar-icon.svg?react';
import { Notification, NotificationType } from '@app/api/gql/generated-types';
import { formatPhone } from '@app/utils/formatPhone';
import { colors } from '@app/theme/colors';

export interface NotificationMetaItem {
  id: string;
  isRead: boolean;
  type: NotificationType;
  title: string | React.ReactNode;
  description: string;
  createdAt: Date;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  linkTo?: string;
}

interface NotificationLink {
  relationId?: string;
  isKeywordCampaign?: boolean;
  date?: string;
  id?: string;
}

interface NotificationDescription {
  description?: string;
  eventName?: string;
}

export interface NotificationFilter {
  isSubAccount: boolean;
}

const generateLinkToCampaign = ({
  relationId,
  isKeywordCampaign,
}: NotificationLink): string => {
  return isKeywordCampaign
    ? generateUrl(ROUTES.campaignKeywordEdit, { id: relationId })
    : generateUrl(ROUTES.campaignEdit, { id: relationId });
};

const linkToPayment = (): string => {
  return ROUTES.settingsMembershipAddCard;
};

const linkToCampaigns = (): string => {
  return ROUTES.campaigns;
};

const linkToCalendar = ({ date, id }: NotificationLink): string => {
  const linkDate = DateTime.fromJSDate(new Date(date)).toFormat('MM-dd-yyyy');
  return `${ROUTES.calendar}/day?date=${linkDate}&eventId=${id}`;
};

const formatPhoneDescription = ({
  description,
}: NotificationDescription): string => {
  const phoneMatch = description.match(/\+?\d{10,}/);
  if (phoneMatch) {
    const formattedPhone = formatPhone(phoneMatch[0]);
    return description.replace(phoneMatch[0], formattedPhone);
  }
  return description;
};

export interface NotificationMetaData {
  type: NotificationType;
  toTitle?: ({ name }: { name?: string }) => string | React.ReactNode;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  description?: string;
  isSubAccount?: boolean;
  toDescription?: ({ description }: NotificationDescription) => string;
  linkTo?: ({
    relationId,
    isKeywordCampaign,
    date,
  }: NotificationLink) => string;
}

export const notificationMetaData: NotificationMetaData[] = [
  {
    type: NotificationType.CAMPAIGN_STARTED,
    toTitle: () => 'Campaign started',
    icon: CampaignStartedIcon,
    linkTo: generateLinkToCampaign,
  },
  {
    type: NotificationType.CAMPAIGN_ENDED,
    toTitle: () => 'Campaign ended',
    icon: CampaignEndedIcon,
    linkTo: generateLinkToCampaign,
  },
  {
    type: NotificationType.PAYMENT_FAILED,
    toTitle: () => 'Subscription payment has failed',
    toDescription: () =>
      'Please update your payment method to restore all services',
    linkTo: linkToPayment,
    icon: PaymentFailedIcon,
    isSubAccount: false,
  },
  {
    type: NotificationType.PAYMENT_FAILED,
    toTitle: () => 'Subscription payment has failed',
    toDescription: () =>
      'Please contact the primary account holder to update payment and restore all services',
    linkTo: undefined,
    icon: PaymentFailedIcon,
    isSubAccount: true,
  },
  {
    type: NotificationType.PHONE_EXHAUSTED,
    toTitle: () => (
      <Box>
        Smarter Contact
        <Box as="sup" position="relative" top="-5px">
          AI
        </Box>{' '}
        Insights
        <Badge
          background="inherit"
          border={`1px solid ${colors.orangeBlaze}`}
          borderRadius="18px"
          color="orangeBlaze"
          fontSize="12px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
          ml="4px"
          padding="2px 10px"
          textTransform="capitalize">
          Beta
        </Badge>
      </Box>
    ),
    linkTo: linkToCampaigns,
    toDescription: formatPhoneDescription,
    icon: AIInsightIcon,
  },
  {
    type: NotificationType.BLOCKED_CAMPAIGN,
    toTitle: () => (
      <Box>
        Smarter Contact
        <Box as="sup" position="relative" top="-5px">
          AI
        </Box>{' '}
        Insights
        <Badge
          background="inherit"
          border={`1px solid ${colors.orangeBlaze}`}
          borderRadius="18px"
          color="orangeBlaze"
          fontSize="12px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="normal"
          ml="4px"
          padding="2px 10px"
          textTransform="capitalize">
          Beta
        </Badge>
      </Box>
    ),
    linkTo: generateLinkToCampaign,
    icon: AIInsightIcon,
  },
  {
    type: NotificationType.CALENDAR_EVENT_CREATED,
    toTitle: ({ name }) => `Event with ${name} in 10 minutes`,
    linkTo: linkToCalendar,
    toDescription: ({ eventName }) => eventName,
    icon: CalendarNotificationIcon,
  },
];

/**
 * Get the metadata of items to render from the backend. this data will be added to
 * the data coming from the backend. this function will filter the wording depending the filter
 * of the user.
 * for example : if the account is a subaccount will return different wording than master account
 *
 *
 * @param filter filter to know what kind of metada should be added to the items from the backend
 * @param metadata the metadata wording and icons
 * @returns Items transformed ready to render
 */
export const getNotificationMetadataBy = (
  filter: NotificationFilter,
): NotificationMetaData[] => {
  return notificationMetaData.filter(
    (item) =>
      item.isSubAccount === filter.isSubAccount ||
      isUndefined(item.isSubAccount),
  );
};

/**
 * Transform a notification item coming from the backend and add to that item
 * specific wording which frontend manages, plus icon and formatted dates.
 *
 * @param item the item from the backend
 * @param metadata the metadata wording and icons
 * @returns Items transformed ready to render
 */
export const transformItemToMetaItem = (
  item: Notification,
  metadata: NotificationMetaData[],
): NotificationMetaItem => {
  const metaItem = metadata.find((mitem) => mitem.type === item.type);

  const getLinkTo = (): string => {
    if (metaItem.linkTo) {
      return metaItem.linkTo({
        relationId: item.details?.relationId,
        isKeywordCampaign: item.details?.isKeywordCampaign,
        date: item.details?.calendarEventDate,
        id: item.details?.calendarEventId,
      });
    }
    //subaccount does not have a linkto
    return undefined;
  };

  return {
    ...metaItem,
    description: metaItem.toDescription
      ? metaItem.toDescription({
          description: item?.details?.name,
          eventName: item?.details?.calendarEventName,
        })
      : (item.details?.name ?? metaItem.description),
    createdAt: new Date(item.createdAt),
    id: item.id,
    isRead: item.isRead,
    title: metaItem.toTitle({ name: item?.details?.name }),
    linkTo: getLinkTo(),
  };
};
