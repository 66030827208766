import {
  FormControl,
  FormErrorMessage,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { FC, ReactNode } from 'react';

import Label from './Label';

interface FormItemInputProps extends InputProps {
  label?: string | ReactNode;
}

export const FormItemInput: FC<FormItemInputProps> = ({
  label,
  variant = 'primary',
  ...props
}) => {
  const [field, meta] = useField(props.name);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  const isInvalid = meta.touched && !!meta?.error;

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <Label>{label}</Label>}
      <Input variant={variant} {...field} {...props} />
      {isInvalid && <FormErrorMessage>{meta?.error}</FormErrorMessage>}
    </FormControl>
  );
};
