import { Button } from '@chakra-ui/react';
import React, { FC } from 'react';
import RecoverIcon from '@app/icons/messenger/recover-icon.svg?react';
import {
  ConversationFilter,
  useRecoverContactsMutation,
} from '@app/api/gql/generated-types';
import { removeConversationFromCache } from '../../utils/cacheHelpers';

export interface RecoverButtonProps {
  selectedContacts: string[];
  onComplete: (deletedContactIds?: string[]) => void;
  params: ConversationFilter;
}

export const RecoverButton: FC<RecoverButtonProps> = ({
  selectedContacts,
  onComplete,
}) => {
  const [mutate, { loading: isLoading }] = useRecoverContactsMutation();

  const handleMessagesRecover = async () => {
    await mutate({
      variables: {
        input: {
          ids: selectedContacts,
        },
      },
      update(cache, _result, { variables }) {
        variables?.input?.ids.forEach((id) => {
          removeConversationFromCache(cache, id);
        });
      },
      context: {
        notify: {
          success: () => 'Messages recovered successfully',
          error: () => 'Messages failed to recover',
        },
      },
    });
    onComplete(selectedContacts);
  };

  return (
    <Button
      isLoading={isLoading}
      leftIcon={<RecoverIcon />}
      size="sm"
      variant="outlined"
      onClick={handleMessagesRecover}>
      Recover
    </Button>
  );
};
