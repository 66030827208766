import React, { FC } from 'react';
import { Button, Stack, Text } from '@chakra-ui/react';
import OfflineBackground from '@app/icons/image-offline.svg?react';
import { BackgroundContainer } from '@app/layouts/BackgroundContainer';

const OfflineMode: FC = () => (
  <BackgroundContainer>
    <Stack
      alignItems="center"
      direction="column"
      height="80%"
      justifyContent="center">
      <OfflineBackground />
      <Text color="darkCharcoal" fontSize="24px" mt="26px">
        No internet connection available
      </Text>
      <Button variant="outlined" onClick={() => window.location.reload()}>
        Refresh page
      </Button>
    </Stack>
  </BackgroundContainer>
);

export default OfflineMode;
