import React, { useEffect, useState } from 'react';

import { Link } from '@chakra-ui/react';
import './termsandconditions-styles.css';

export const TermsAndConditions = () => {
  const [topRef, setTopRef] = useState<Element>(null);
  useEffect(() => {
    if (!topRef) {
      return;
    }
    topRef.scrollIntoView({
      block: 'end',
      inline: 'nearest',
      behavior: 'instant',
    });
  }, [topRef]);

  return (
    <div id="termsandconditions">
      {/* Solves a weird bug where it always scrolls to middle of terms on load. */}
      <div ref={setTopRef} />
      <p>Last updated: January 09, 2025</p>
      <h2 id="interpretation">Interpretation</h2>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        plural.
      </p>
      <h2 id="definitions">Definitions</h2>
      <p>For the purposes of these Terms and Conditions:</p>
      <ul>
        <li>
          Account means a unique account created for the User to access our
          Service or parts of our Service.
        </li>
        <li>
          Affiliate means an entity that controls is controlled by or is under
          common control with a party, where &quot;control&quot; means ownership
          of 50% or more of the shares, equity interest, or other securities
          entitled to vote for the election of directors or other managing
          authority.
        </li>
        <li>
          AI Features refer to functionality within the Service that utilizes
          artificial intelligence, including but not limited to message template
          generation and automated communications assistance.
        </li>
        <li>
          Application (also referred to as “App”) refers to the software
          platform provided by the Company to the User
        </li>
        <li>
          Company (also referred to as &quot;the Company&quot;, &quot;We&quot;,
          &quot;Us&quot; or &quot;Our&quot;) refers to Smarter Contact Inc.,
          1802 N Howard Ave Tampa, FL 33607.
        </li>
        <li>Country refers to: Florida, United States</li>
        <li>
          Device means any device that can access the Service, such as a
          computer, a cellphone, or a digital tablet.
        </li>
        <li>
          Free Trial refers to a limited period of time when limited
          functionality of Smarter Contact’s products is free prior to
          purchasing a Subscription. The intended purposed of the Free Trial is
          to familiarize Users with the application’s interface without the
          ability to send communications via text or voice prior to the User’s
          brand being registered and 10 DLC approved to legally do so under
          TCPA.
        </li>
        <li>
          Service refers to the Website or other services rendered by the
          Company to the User.
        </li>
        <li>
          Subscription(s) refer to the services or access to the Service offered
          on a subscription basis by the Company to the User.
        </li>
        <li>
          Subscription Plan refers to product plans and/or service plans offered
          by the Company to the User.
        </li>
        <li>
          Subscription Term refers to the term of the User&#39;s Subscription
          offered by the Company to the User. This term can be monthly,
          quarterly, yearly, or customized.
        </li>
        <li>
          Terms and Conditions (also referred to as &quot;Terms&quot;) mean
          these Terms and Conditions that form the entire agreement between the
          User and the Company regarding the use of the Service.
        </li>
        <li>
          Third-party Service means any services or content (including data,
          information, products, or services) provided by a third party that may
          be displayed, included, or made available by the Service.
        </li>
        <li>
          User(s) means the individual accessing or using the Service, or the
          company, or other legal entity on behalf of which such individual is
          accessing or using the Service, as Applicable.
        </li>
        <li>
          Website refers to Smarter Contact, accessible from{' '}
          <Link
            href="https://smartercontact.com/"
            rel="noreferrer"
            target="_blank"
            variant="link">
            https://smartercontact.com/
          </Link>
        </li>
      </ul>
      <h2 id="acknowledgment">Acknowledgment</h2>
      <p>
        These are the Terms and Conditions governing the use of this Service and
        the agreement between the User and the Company. They set out the rights
        and obligations of all users regarding the use of the Service.
      </p>
      <p>
        User’s access to and use of the Service is conditioned on User’s
        acceptance of and compliance with these Terms and Conditions. These
        Terms and Conditions apply to all visitors, users, and others who access
        or use the Service.
      </p>
      <p>
        By accessing or using the Service User agrees to be bound by these Terms
        and Conditions. If the User disagrees with any part of these Terms and
        Conditions, then the User may not access the Service. User represents
        that User is over the age of 18. The Company does not permit those under
        18 to use the Service.
      </p>
      <p>
        User access to and use of the Service is also conditioned on the User’s
        acceptance of and compliance with the Privacy Policy of the Company. Our
        Privacy Policy describes our policies and procedures on the collection,
        use, and disclosure of the User’s personal information when the User
        utilizes the Application or the Website, including processing by
        artificial intelligence services, and tells the User about the User’s
        privacy rights and how the law protects the User. Please read our{' '}
        <Link
          href="https://smartercontact.com/wp-content/uploads/2022/10/privacy_policy.pdf"
          rel="noreferrer"
          target="_blank"
          variant="link">
          privacy policy
        </Link>{' '}
        in full before using our Service.
      </p>
      <h2 id="subscription-term">Subscription Term</h2>
      <p>
        The Service or some parts of the Service are available only with a paid
        Subscription. The User will be billed in advance on a recurring basis
        according to the Subscription Plan and Subscription Term the User
        subscribed to.
      </p>
      <h2 id="subscription-renewal">Subscription Renewal</h2>
      <p>
        The User’s Subscription Term will automatically renew for the period
        indicated on the User’s chosen Subscription Plan unless the User cancels
        their Subscription renewal in the App at their discretion at any time.
      </p>
      <p>
        {' '}
        Upon the User’s Subscription Renewal, we may increase the fees to
        reflect future changes to our list prices. Additionally, the User’s
        recurring and usage fees are subject to increase to reflect future
        changes to our list prices. If these increases apply to the User, we
        will notify the User at least fourteen (14) days in advance of the
        User’s Subscription Renewal.
      </p>
      <p>
        If the User chooses to cancel the User’s Subscription prior to the
        User’s Subscription Renewal, the User will not be billed for increased
        fees.
      </p>
      <p>
        Upon the User’s Subscription Renewal, after the fee changes have become
        effective, the User will be billed with the increased fees according to
        their Subscription Plan and Subscription Term.
      </p>
      <h2 id="subscription-cancellations">Subscription Cancellations</h2>
      <p>
        During the Subscription Term and any Subscription Renewal terms, the
        User may choose to cancel their Subscription early, provided that we
        will not provide any refunds and the User promptly pays all unpaid fees
        due through the end of the Subscription Term. The user may cancel the
        User&#39;s Subscription Plan in the App at their discretion at any time.
        The Smarter Contact customer-facing teams are not responsible for nor
        authorized to process Subscription cancellations on behalf of Users.
      </p>
      <h2 id="refunds">Refunds</h2>
      <p>
        All purchases of products and services rendered by the Company to the
        User are final.
      </p>
      <p>
        All Subscription fees, usage fees, one-time purchases, or User in-app
        account credits are non-refundable.
      </p>
      <p>
        We do not provide full or partial refunds if the User cancels their
        Subscription early, stops using the Subscription during the Subscription
        Term, forgets to cancel the Subscription, or for any other reason deemed
        the User’s responsibility.
      </p>
      <h2 id="billing">Billing</h2>
      <p>
        User shall provide the Company with accurate and complete billing
        information, including full name, street address, state, zip code,
        telephone number, email, and a valid payment method.
      </p>
      <p>
        Should automatic billing fail to occur for any reason, the Company will
        issue an electronic invoice indicating the User must proceed manually,
        within 14 days of the payment being due, with the full payment
        corresponding to the billing period as indicated on the invoice.
      </p>
      <p>
        In addition to our list prices, we will bill the User for any applicable
        sales taxes for products and services purchased. Sales tax laws vary by
        state and local jurisdiction, and they are automatically calculated by
        our payment processor at checkout.
      </p>
      <p>
        {' '}
        Upon a failed payment or the inability to collect payment, the Company
        retains the right to limit or restrict access to and pause all products
        and services until all unpaid invoices are paid in full.
      </p>
      <h2 id="free-trial">Free Trial</h2>
      <p>
        The Company may, at its sole discretion, offer a limited version of its
        applications with a free trial for a limited period of time.
      </p>
      <p>
        Users may be required to enter their billing information in order to
        sign up for the Free Trial. If the User enters their billing information
        when signing up for a Free Trial, the Company will not collect payment
        from the User until the Free Trial has expired. On the last day of the
        Free Trial period, unless the User cancels their Free Trial, the User
        will be automatically charged the applicable Subscription fees according
        to the User’s chosen Subscription Plan and Subscription Term.
      </p>
      <p>
        At any time and without notice, the company reserves the right to (i)
        modify the terms and conditions of the Free Trial offer or (ii) cancel
        its existing Free Trial offer.
      </p>
      <h2 id="a2p-10-dlc-application">A2P 10 DLC Application</h2>
      <p>
        According to the new 10 DLC regulations issued by the TCR, starting
        April 1st, 2022, all Users must provide their accurate business
        information in order to fulfill carrier compliance requirements. Below
        is a list of the information required to start and continue services
        without interruption.
      </p>
      <ul>
        <li>Name</li>
        <li>Contact Number</li>
        <li>Address</li>
        <li>Email</li>
        <li>EIN</li>
        <li>Legal Company Name</li>
        <li>Sample Message</li>
      </ul>
      <p>
        Subscriptions will not be automatically canceled if the User&#39;s 10
        DLC application is not submitted or approved.
      </p>
      <p>
        Subscriptions will not be automatically canceled if the User’s phone
        numbers are not approved for texting and/or calling.
      </p>
      <p>
        Users should submit their 10 DLC applications right after they start
        their Subscription and purchase phone numbers immediately after their
        brand has been 10 DLC approved.
      </p>
      <p>
        The Users&#39; approved brands, campaigns, and phone numbers remain
        active as long as their Smarter Contact account Subscription is active.
      </p>
      <p>
        After 14 days of a user canceling their Subscription, we release their
        phone numbers back to the carriers.
      </p>
      <p>
        {' '}
        After 30 days of a user canceling their Subscription, we delete the
        User&#39;s approved Brand and Campaign from the TCR.
      </p>
      <p>
        If a subscription is reactivated after 30 days of a user canceling their
        subscription, the user will need to resubmit their 10 DLC application to
        reregister their brand and get reapproved, and new phone numbers must be
        purchased.
      </p>
      <h2 id="user-content">User Content</h2>
      <p>
        Due to the nature of the Service and the volume of information
        submitted, we cannot and do not monitor all of the Materials posted or
        transmitted by you and other third-party information providers via the
        Service, including, without limitation, any Materials posted via the
        Service. User expressly agrees that we will not be liable for Materials.
        We reserve the right but are not obligated, to remove content from the
        Service for any reason, including content that we believe violates these
        Terms &amp; Conditions mentioned in this document.
      </p>
      <h2 id="ai-generated-content-and-third-party-ai-services">
        AI-Generated Content and Third-Party AI Services
      </h2>
      <p>
        The Service utilizes artificial intelligence (&quot;AI&quot;) services
        provided by third-party providers to enhance certain features such as
        message template generation, automated communications, and other
        capabilities (&quot;AI Features&quot;). By using these AI Features, you
        acknowledge and agree to specific terms and conditions. You authorize
        the Company to process and transmit message templates, contact
        information, and message content to third-party AI service providers for
        the purpose of generating and optimizing communications. If you choose
        to include any sensitive personal information in your messages or
        templates, you do so at your own risk and take full responsibility for
        any consequences. While we implement reasonable security measures, data
        transmitted to third-party AI providers is subject to their respective
        privacy policies and terms of service. You agree to indemnify and hold
        the Company harmless from any claims arising from your inclusion of
        sensitive personal information in messages or templates.
      </p>
      <p>
        AI-generated content is provided as a suggestion or template only. All
        AI-generated content must undergo human review before use. After review
        and acceptance, it is identical to user content (User Content, as
        defined above). The Company makes no representations or warranties
        regarding the accuracy, appropriateness, or effectiveness of
        AI-generated content. You must ensure all AI-generated content complies
        with applicable laws, regulations, and industry standards before use.
        The Company reserves the right to require human review for certain
        message types or scenarios.
      </p>
      <p>
        AI Features are an integral part of the Service but must be actively
        initiated by you. You agree not to use AI Features to generate content
        that is illegal, harmful, discriminatory, or violates any third
        party&#39;s rights. The Company reserves the right to monitor, modify,
        or terminate access to AI Features anytime. AI Features may be subject
        to usage limits or additional terms as communicated by the Company. You
        acknowledge that AI Features are provided as tools to assist in content
        creation and messaging, but their use is at your discretion.
      </p>
      <p>
        {' '}
        The use of AI Features is subject to third-party providers&#39; terms of
        service and policies. You agree to indemnify and hold the Company
        harmless from any claims arising from your use of AI-generated content.
        The Company is not liable for any damages resulting from the use,
        inability to use, or outcomes of AI-generated content.
      </p>
      <h2 id="user-accounts">User Accounts</h2>
      <p>
        When a User creates an account with us, the User must provide us with
        accurate, complete, and current information at all times. Failure to do
        so constitutes a breach of the Terms, which may result in immediate
        termination of the User’s account on our Service.
      </p>
      <p>
        The User is responsible for safeguarding the password used to access the
        Service and for any activities or actions under the User password,
        whether the User password is with our Service or a Third-Party
        application.
      </p>
      <p>
        The User agrees not to disclose the User&#39;s password to any third
        party. Users must notify us immediately upon becoming aware of any
        breach of security or unauthorized use of the User account.
      </p>
      <p>
        User may not use as a username the name of another person or entity, or
        that is not lawfully available for use, a name or trademark that is
        subject to any rights of another person or entity other than User
        without appropriate authorization, or a name that is otherwise
        offensive, vulgar or obscene.
      </p>
      <h2 id="call-recording">Call Recording</h2>
      <p>
        Smarter Contact offers the option to record calls. If you choose to use
        this service, you must comply with all state and federal laws,
        regulations, and rules prior to recording any telephone calls. You
        expressly warrant and represent to Smarter Contact that you shall comply
        at all times.
      </p>
      <p>
        We make no representations or warranties with respect to call recording
        and recommend that you always secure consent before recording any calls.
        User acknowledges that these representations and obligations are
        essential to the ability of Smarter Contact to provide you with access
        to call recordings, and you further agree to indemnify, defend, and hold
        Smarter Contact and its officers, directors, owners, employees, agents,
        consultants, and vendors harmless from and against any and all
        liabilities, losses, claims, damages, causes of action, costs and
        expenses (including attorneys’ fees) that may be incurred by Smarter
        Contact arising out of or related to User&#39;s acts or omissions in
        connection with call recordings, whether such claims arise under
        contract, tort, statute or other legal theory.
      </p>
      <h2 id="compliance">Compliance</h2>
      <p>
        The Users&#39; use of the Service must comply with all applicable laws
        and regulations, including the Telephone Consumer Protection Act (TCPA).
        By using the Service, the User agrees to: Ensure that all recipients of
        messages have provided the necessary consent as defined by the TCPA and
        related regulations.
      </p>
      <p>
        {' '}
        Maintain clear, accurate, and up-to-date records of consent for each
        recipient, including the date, time, and method by which consent was
        obtained.
      </p>
      <p>
        The Company will not verify, obtain, store, or produce consent records
        on behalf of the User. The User is solely responsible for maintaining
        compliance with TCPA regulations, including but not limited to consent
        rules. The Company assumes no liability for any violations of TCPA or
        related laws arising from the User’s use of the Service.
      </p>
      <p>
        If the User fails to comply with these requirements, the Company
        reserves the right to suspend or terminate access to the Service without
        notice. The user agrees to indemnify, defend, and hold the Company
        harmless from any claims, damages, or penalties arising out of their
        non-compliance with TCPA regulations.
      </p>
      <h2 id="intellectual-property">Intellectual Property</h2>
      <p>
        The Service and its original content (excluding Content provided by User
        or other users), features, and functionality are and will remain the
        exclusive property of the Company and its licensors.
      </p>
      <p>
        The Service is protected by copyright, trademark, and other laws of both
        the Country and foreign countries.
      </p>
      <p>
        Our trademarks and trade dress may not be used in connection with any
        product or service without the prior written consent of the Company.
      </p>
      <h2 id="links-to-other-websites">Links to Other Websites</h2>
      <p>
        Our Service may contain links to third-party websites or services that
        are not owned or controlled by the Company.
      </p>
      <p>
        The Company has no control over and assumes no responsibility for the
        content, privacy policies, or practices of any third-party websites or
        services. User further acknowledges and agrees that the Company shall
        not be responsible or liable, directly or indirectly, for any damage or
        loss caused or alleged to be caused by or in connection with the use of
        or reliance on any such content, goods, or services available on or
        through any such websites or services.
      </p>
      <p>
        We strongly advise User to read the terms and conditions and privacy
        policies of any third-party websites or services that Users visit.
      </p>
      <h2 id="termination">Termination</h2>
      <p>
        We may terminate or suspend the User&#39;s account immediately, without
        prior notice or liability, for any reason whatsoever, including, without
        limitation, a breach of these terms and conditions. It is prohibited to
        use Smarter Contact for any illegal activity restricted by the laws of
        the USA.
      </p>
      <p>
        {' '}
        Upon termination, the User&#39;s right to use the Service will cease
        immediately. If the User wishes to terminate the User Account, the User
        may request the Company to do so.
      </p>
      <h2 id="limitation-of-liability">Limitation of Liability</h2>
      <p>
        Notwithstanding any damages that User might incur, the entire liability
        of the Company and any of its suppliers under any provision of these
        terms and User&#39;s exclusive remedy for all of the foregoing shall be
        limited to the amount actually paid by you through the service if you
        haven&#39;t purchased anything through the service.
      </p>
      <p>
        To the maximum extent permitted by applicable law, in no event shall the
        Company or its suppliers be liable for any special, incidental,
        indirect, or consequential damages whatsoever (including, but not
        limited to, damages for loss of profits, loss of data or other
        information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability
        to use the Service, third-party software and/or third-party hardware
        used with the Service, or otherwise in connection with any provision of
        this Terms), even if the Company or any supplier has been advised of the
        possibility of such damages and even if the remedy fails of its
        essential purpose. Some states do not allow the exclusion of implied
        warranties or limitation of liability for incidental or consequential
        damages, which means that some of the above limitations may not apply.
        In these states, each party&#39;s liability will be limited to the
        greatest extent permitted by law.
      </p>
      <h2 id="-as-is-and-as-available-disclaimer">
        &quot;AS IS&quot; and &quot;AS AVAILABLE&quot; Disclaimer
      </h2>
      <p>
        The Service is provided to the User &quot;AS IS&quot; and &quot;AS
        AVAILABLE&quot; and with all faults and defects without warranty of any
        kind. To the maximum extent permitted under applicable law, the Company,
        on its own behalf and on behalf of its Affiliates and their respective
        licensors and service providers, expressly disclaims all warranties,
        whether express, implied, statutory or otherwise, with respect to the
        Service, including all implied warranties of merchantability, fitness
        for a particular purpose, title and non-infringement, and warranties
        that may arise out of course of dealing, course of performance, usage or
        trade practice. Without limitation to the foregoing, the Company
        provides no warranty or undertaking and makes no representation of any
        kind that the Service will meet the User’s requirements, achieve any
        intended results, be compatible or work with any other software,
        applications, systems, or services, operate without interruption, meet
        any performance or reliability standards or be error-free or that any
        errors or defects can or will be corrected.
      </p>
      <p>
        Without limiting the foregoing, neither the Company nor any of the
        Company&#39;s provider makes any representation or warranty of any kind,
        express or implied: (i) as to the operation or availability of the
        Service, or the information, content, and materials or products included
        thereon; (ii) that the Service will be uninterrupted or error-free;
        (iii) as to the accuracy, reliability, or currency of any information or
        content provided through the Service; or (iv) that the service, its
        servers, the content, or e-mails sent from or on behalf of the Company
        are free of viruses, scripts, trojan horses, worms, malware, timebombs
        or other harmful components.
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of certain types of
        warranties or limitations on applicable statutory rights of a consumer,
        so some or all of the above exclusions and limitations may not apply to
        the User. However, in such a case, the exclusions and limitations set
        forth in this section shall be applied to the greatest extent
        enforceable under applicable law.
      </p>
      <h2 id="governing-law">Governing Law</h2>
      <p>
        The laws of the Country, excluding its conflicts of law rules, shall
        govern these terms and the User&#39;s use of the service. The User&#39;s
        application use may also be subject to other local, state, national, or
        international laws.
      </p>
      <h2 id="disputes-resolution">Disputes Resolution</h2>
      <p>
        If you have any concerns or disputes about the service, the User agrees
        to first try to resolve the dispute in a fair and equitable way by
        contacting the company.
      </p>
      <h2 id="for-european-union-eu-users">For European Union (EU) Users</h2>
      <p>
        If the User is a European Union consumer, you will benefit from any
        mandatory provisions of the law of the country in which you are resident
        of.
      </p>
      <h2 id="united-states-federal-government-end-use-provisions">
        United States Federal Government End-Use Provisions
      </h2>
      <p>
        If the User is a U.S. federal government end user, our Service is a
        &quot;Commercial Item&quot; as that term is defined at 48 C.F.R. §2.101.
      </p>
      <h2 id="united-states-legal-compliance">
        United States Legal Compliance
      </h2>
      <p>
        User represents, and warrants that (i) User is not located in a country
        that is subject to the United States government embargo or that has been
        designated by the United States government as a &quot;terrorist
        supporting&quot; country, and (ii) User is not listed on any United
        States government list of prohibited or restricted parties.
      </p>
      <h2 id="severability">Severability</h2>
      <p>
        If any provision of these Terms is held to be unenforceable or invalid,
        such provision will be changed and interpreted to accomplish the
        objectives of such provision to the greatest extent possible under
        applicable law and the remaining provisions will continue in full force
        and effect.
      </p>
      <h2 id="severability">Waiver</h2>
      <p>
        Except as provided herein, the failure to exercise a right or to require
        the performance of an obligation under these terms shall not affect a
        party&#39;s ability to exercise such right or require such performance
        at any time thereafter, nor shall be the waiver of a breach constitute a
        waiver of any subsequent breach.
      </p>
      <h2 id="translation-interpretation">Translation Interpretation</h2>
      <p>
        These Terms and Conditions may have been translated if we have made them
        available to the User on our Service. The User agrees that the original
        English text shall prevail in the case of a dispute.
      </p>
      <h2 id="changes-to-these-terms-and-conditions">
        Changes to These Terms and Conditions
      </h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        terms at any time. If a revision is material, we will make reasonable
        efforts to provide at least seven days&#39; notice prior to any new
        terms taking effect. What constitutes a material change will be
        determined at our sole discretion. By continuing to access or use our
        Service after those revisions become effective, the User agrees to be
        bound by the revised terms that are agreed upon whenever the User logs
        into their User account.
      </p>
      <h2 id="contact-us">Contact Us</h2>
      <p>
        If you have any questions about these terms and conditions, User can
        contact us by email at support@smartercontact.com
      </p>
    </div>
  );
};
