import { HStack, Skeleton } from '@chakra-ui/react';
import React from 'react';
import { colors } from '@app/theme/colors';

export const SkeletonHeader = () => {
  return (
    <HStack flexDirection="row" mb="30px" spacing="20px">
      <Skeleton
        endColor={colors.cultured}
        height="40px"
        startColor={colors.mystic}
        width="240px"
      />
      <Skeleton
        endColor={colors.cultured}
        height="40px"
        startColor={colors.mystic}
        width="140px"
      />
    </HStack>
  );
};
