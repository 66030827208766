import { Box, Popover, useDisclosure } from '@chakra-ui/react';
import React, { FC, useCallback, useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
  CrmIntegrationFieldsFragment,
  useExportConversationsToCrmIntegrationLazyQuery,
} from '@app/api/gql/generated-types';
import { extractErrorMessage } from '@app/utils/errorMessageUtils';
import { ExportPopoverContent } from './ExportPopoverContent';
import { ExportPopoverTrigger } from './ExportPopoverTrigger';

interface ExportToCRMProps {
  data: CrmIntegrationFieldsFragment[];
  contactId: string;
}

export const ExportToCRM: FC<ExportToCRMProps> = ({ data, contactId }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [appId, setAppId] = useState<string>();

  const [exportMutation, { loading: isLoading }] =
    useExportConversationsToCrmIntegrationLazyQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const onExport = useCallback(
    async (app: CrmIntegrationFieldsFragment) => {
      setAppId(app.id);
      await exportMutation({
        variables: {
          input: {
            conversationId: contactId,
            crmIntegrationId: app.id,
          },
        },
        context: {
          notify: {
            success: () => `Successfully exported to ${app.name}`,
            error: (error: ApolloError[]) => extractErrorMessage(error),
          },
        },
      });
      setAppId(null);
    },
    [contactId, exportMutation],
  );

  const onSubmit = useCallback(
    async (app: CrmIntegrationFieldsFragment) => {
      onClose();
      await onExport(app);
    },
    [onClose, onExport],
  );

  return (
    <Box>
      <Popover
        matchWidth
        isOpen={isOpen}
        offset={[0, 3]}
        placement="bottom-start"
        variant="responsive"
        onClose={onClose}
        onOpen={onOpen}>
        <ExportPopoverTrigger />
        <ExportPopoverContent
          data={data}
          isLoading={isLoading && appId}
          onSubmit={onSubmit}
        />
      </Popover>
    </Box>
  );
};
