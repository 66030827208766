import React, { createContext, useContext, useEffect, useState } from 'react';
import { DateTime, Interval } from 'luxon';

const webinarTime = DateTime.fromObject(
  { year: 2024, month: 12, day: 24 },
  { zone: 'America/New_York' },
).endOf('day');

const bannerVisibilityIntervals = [
  Interval.fromDateTimes(
    DateTime.fromObject(
      { year: 2024, month: 12, day: 23 },
      { zone: 'America/New_York' },
    ),
    DateTime.fromObject(
      { year: 2024, month: 12, day: 25 },
      { zone: 'America/New_York' },
    ),
  ),
];

interface BannerContextProps {
  isVisible: boolean;
  handleTimerComplete: () => void;
  webinarTimestamp: number;
  setIsVisible: (isVisible: boolean) => void;
}

const BannerContext = createContext<BannerContextProps | undefined>(undefined);

export const useBannerContext = (): BannerContextProps => {
  const context = useContext(BannerContext);
  if (!context) {
    throw new Error('useBannerContext must be used within a BannerProvider');
  }
  return context;
};

export const BannerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const getVisibility = () => {
    const now = DateTime.now().setZone('America/New_York');

    return bannerVisibilityIntervals.some((interval) => interval.contains(now));
  };

  useEffect(() => {
    setIsVisible(getVisibility());
  }, []);

  const handleTimerComplete = () => {
    setIsVisible(false);
  };

  return (
    <BannerContext.Provider
      value={{
        handleTimerComplete,
        webinarTimestamp: webinarTime.toMillis(),
        isVisible,
        setIsVisible,
      }}>
      {children}
    </BannerContext.Provider>
  );
};
