import { Enum } from '@app/utils/enum';

/**
 * Represents the status of a phone number.
 * @enum {string}
 */
export const TPhoneNumberStatus = new Enum(
  'submitted',
  'inactive',
  'active',
  'declined',
  'none',
);
