import { isMobile } from 'react-device-detect';

import { useDefaultLayoutInMobile } from '@app/hooks/useDefaultLayoutInMobile';
import { MessageItem } from '@app/pages/Messenger/components/MessageItem';
import { MessageItemMobile } from '@app/pages/Messenger/components/MessageItem/index.mobile';

const INBOX_COMPONENTS = {
  Item: MessageItem,
  MobileItem: MessageItemMobile,
};

export const useInboxComponentConfiguration = () => {
  const isMobileLayout = useDefaultLayoutInMobile();

  return {
    Item:
      isMobileLayout && isMobile
        ? INBOX_COMPONENTS.MobileItem
        : INBOX_COMPONENTS.Item,
  };
};
