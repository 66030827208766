import { ApolloError, useApolloClient } from '@apollo/client';
import omit from 'lodash/omit';
import { useCallback } from 'react';

import {
  CallScript,
  FindCallScriptsDocument,
  useCreateCallScriptMutation,
  useUpdateCallScriptMutation,
} from '@app/api/gql/generated-types';

interface UseCreateScriptMutation extends CallScript {
  onError?: (error: ApolloError) => void;
}

export const useCreateScript = () => {
  const client = useApolloClient();
  const [createMutation] = useCreateCallScriptMutation();
  const [updateMutation] = useUpdateCallScriptMutation();

  const mutation = useCallback(
    async ({ id, onError, ...values }: UseCreateScriptMutation) => {
      const submitValues = {
        ...omit(values, '__typename'),
      };
      if (id) {
        const { data: { updateCallScript } = {} } = await updateMutation({
          variables: {
            input: {
              name: submitValues.name.trim(),
              content: submitValues.content,
            },
            updateCallScriptId: id,
          },
          refetchQueries: [FindCallScriptsDocument],
          context: {
            notify: {
              success: () => 'Call script was successfully updated',
              error: null,
            },
          },
          onError,
        });
        client.cache.evict({ fieldName: 'findCallScripts' });
        return updateCallScript;
      }
      const { data: { createCallScript } = {} } = await createMutation({
        variables: {
          input: {
            ...submitValues,
            name: submitValues.name.trim(),
          },
        },
        refetchQueries: [FindCallScriptsDocument],
        context: {
          notify: {
            success: () => 'Call script was successfully created',
            error: null,
          },
        },
        onError,
      });
      client.cache.evict({ fieldName: 'findCallScripts' });
      return createCallScript;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createMutation, updateMutation],
  );
  return {
    mutation,
  };
};
