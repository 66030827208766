import React, { FC, ReactNode, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import {
  Box,
  Popover as P,
  PopoverArrow,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';

interface PopoverTypes extends PopoverProps {
  triggerElement: ReactNode;
  popoverText: ReactNode;
  widthContainer: string;
  width?: string;
  arrowPadding?: number;
  borderRadius?: string;
  p?: string;
}

const Popover: FC<PopoverTypes> = ({
  triggerElement,
  popoverText,
  placement,
  widthContainer = '200px',
  width = 'auto',
  trigger = 'click',
  arrowPadding = 8,
  borderRadius = '18px',
  p = '12px',
  offset = [0, 0],
}) => {
  const fieldRef = useRef(null);

  return (
    <P
      computePositionOnMount
      isLazy
      arrowPadding={arrowPadding}
      arrowShadowColor="0px 6px 18px rgba(139, 139, 139, 0.25)"
      closeOnBlur={trigger === 'click' ? true : false}
      initialFocusRef={fieldRef}
      offset={offset}
      placement={placement}
      trigger={trigger}>
      <PopoverTrigger>
        <Box cursor="pointer" width={width}>
          {triggerElement}
        </Box>
      </PopoverTrigger>
      {popoverText && (
        <Portal>
          <PopoverContent
            _focus={{
              outline: '0 none',
            }}
            bgColor="white"
            border={0}
            borderRadius={borderRadius}
            boxShadow="0px 6px 18px rgba(139, 139, 139, 0.25)"
            color="main.400"
            fontSize="14px"
            lineHeight="20px"
            minWidth="auto"
            outline="0 none"
            p={p}
            width={isMobile ? '20rem' : widthContainer}>
            {popoverText}
            <PopoverArrow />
          </PopoverContent>
        </Portal>
      )}
    </P>
  );
};

export default Popover;
