import { colors } from '../colors';

export const Link = {
  variants: {
    inline: {
      textDecoration: 'none',
      color: colors.switchedBlue[600],
      _hover: {
        textDecoration: 'none',
      },
    },
    primary: {
      color: 'primary.600',
    },
    link: {
      textDecoration: 'underline',
      color: 'primary.600',
      _hover: {
        textDecoration: 'none',
      },
    },
    textLink: {
      textDecoration: 'none',
      color: 'main.400',
      _hover: {
        textDecoration: 'none',
        color: 'primary.600',
      },
    },
    file: {
      textDecoration: 'underline',
      color: 'main.300',
      _hover: {
        color: 'primary.600',
      },
      _active: {
        color: 'pressedAzure',
      },
      _disabled: {
        color: 'secondary.200',
      },
    },
  },
};
