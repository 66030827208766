import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { InboxMessages } from '../components/InboxMessages';
import { MessengerFolderType } from '../types';

export const GroupMessages: FC = () => {
  const { groupId } = useParams<{ groupId: string }>();

  return (
    <InboxMessages
      path={`inbox/groups/${groupId}`}
      queryParams={{
        groups: [groupId],
      }}
      title="Groups"
      type={MessengerFolderType.GROUPS}
    />
  );
};
