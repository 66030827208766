import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Link,
  Text,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { head } from 'lodash/fp';
import { FormatNumber } from '@app/components/FormatNumber';
import { formatInCents } from '@app/components/FormatNumber/utils';
import { AddFundsMessageSkiptrace } from '@app/components/Payment/AddFundsMessageSkiptrace';
import Popup from '@app/components/Popup';
import { useAccountBalance } from '@app/hooks/useAccountBalance';
import CardBrandIcon from '@app/components/SelectPayment/CardBrandIcon';
import { useAccountPlansDetails } from '@app/hooks/useAccountPlansDetails';
import Loader from '@app/components/Loader';
import { colors } from '@app/theme/colors';
import video from '@app/utils/videoLinks';

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  contacts: number;
  nextModalStep: () => void;
  useCache: boolean;
  setUseCache: (value: boolean) => void;
  onOpenPayment: () => void;
}

const PaymentModal: FC<PaymentModalProps> = ({
  isOpen,
  onClose,
  title,
  contacts,
  nextModalStep,
  onOpenPayment,
  useCache,
  setUseCache,
}) => {
  const { balanceInDollars, loading } = useAccountBalance();
  const { skiptracePlan } = useAccountPlansDetails();
  const totalPrice = contacts * skiptracePlan;

  const paymentListOptions = [
    {
      balance: balanceInDollars,
    },
  ];

  const insufficientFunds = !loading && balanceInDollars < totalPrice;
  const isPaymentSourceValid =
    !loading && head(paymentListOptions)?.balance && balanceInDollars > 0;

  const isPaymentConfirmationPossible =
    !isPaymentSourceValid || insufficientFunds;

  return (
    <Popup
      isOpen={isOpen}
      maxW="470px"
      title={title}
      width="470px"
      onClose={onClose}>
      <Box margin="35px 40px">
        <Text color="main.400" marginBottom="15px" variant="secondary-text">
          Skip trace data against cache:
        </Text>
        <Checkbox
          isChecked={useCache}
          mb="14px"
          variant="primary"
          onChange={() => setUseCache(!useCache)}>
          <Text fontWeight={500}>
            Dedupe previously skipped records (Recommended)
          </Text>
        </Checkbox>
        <Box bg="catskillWhite" borderRadius="12px" p="15px">
          <Text color="main.400" variant="secondary-text">
            You paid to have your leads skiptraced once. We store them in our
            database so you never get charged for them again 🏅 <br />
            <br />
            Watch a quick video{' '}
            <Link href={video.skiptrace.payment} target="_blank" variant="link">
              here
            </Link>
          </Text>
        </Box>
        <Text
          borderBottom={`1px dashed ${colors.secondary[400]}`}
          fontSize="14px"
          padding="15px 0">
          Records: <strong>{contacts}</strong>
        </Text>
        <Text
          borderBottom={`1px dashed ${colors.secondary[400]}`}
          fontSize="14px"
          padding="15px 0">
          Rate: <strong>${formatInCents(skiptracePlan)}</strong>
        </Text>
        <Text
          borderBottom={`1px dashed ${colors.secondary[400]}`}
          fontSize="14px"
          padding="15px 0">
          Total:{' '}
          <strong>
            $<FormatNumber fractionDigits={2} number={totalPrice} />
          </strong>
        </Text>
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          mt="15px">
          <Text color="main.400" fontSize="14px">
            Payment method
          </Text>
          <Box
            style={{
              border: `1px solid ${colors.secondary[200]}`,
              borderRadius: '22px',
              padding: '9px 10px 9px 19px',
            }}
            width="243px">
            <Flex width="100%">
              <CardBrandIcon brand={null} />
              <Text
                color="main.400"
                fontSize="14px"
                margin="0 0 0 10px"
                position="relative"
                width="180px">
                Current balance: $
                {loading ? (
                  <Box position="absolute" right="25px" top="2px">
                    <Loader size="sm" thickness="2px" />
                  </Box>
                ) : (
                  <FormatNumber fractionDigits={2} number={balanceInDollars} />
                )}{' '}
              </Text>
            </Flex>
          </Box>
        </Flex>
        {insufficientFunds && (
          <AddFundsMessageSkiptrace
            cost={totalPrice}
            onOpenPayment={onOpenPayment}
          />
        )}
        <Center margin="25px 0 5px">
          <Button
            isDisabled={isPaymentConfirmationPossible}
            variant="primary"
            width="160px"
            onClick={nextModalStep}>
            Confirm
          </Button>
        </Center>
        <Center>
          <Button variant="cancel" onClick={onClose}>
            Cancel
          </Button>
        </Center>
      </Box>
    </Popup>
  );
};

export default PaymentModal;
