import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { PageTitle } from '@app/components/PageTitle';

import { CallScriptTable } from './components/CallScriptTable';

export const CallScript: FC = () => {
  return (
    <Flex direction="column" height="100%">
      <PageTitle title="Call scripts" />
      <Box>
        <Text
          color="main.400"
          fontSize="18px"
          fontWeight={500}
          mb="30px"
          mt="25px"
          pl="30px">
          Call scripts
        </Text>

        <Box mt="25px" pr="30px" width="260px" />
      </Box>

      <Box flex={1} overflow="hidden">
        <CallScriptTable />
      </Box>
    </Flex>
  );
};
