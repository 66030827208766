import React, { FC } from 'react';
import { Box } from '@chakra-ui/react';

import { DragAndDrop } from '@app/components/DragAndDrop';
import Popup from '@app/components/Popup';

interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  onClearState: () => void;
}

const UploadModal: FC<UploadModalProps> = ({
  isOpen,
  onClose,
  title,
  onClearState,
}) => (
  <Popup
    isOpen={isOpen}
    maxW="800px"
    size="lg"
    title={title}
    width="800px"
    onClose={onClose}>
    <Box margin="35px 40px">
      <DragAndDrop goToSkiptrace onClearState={onClearState} />
    </Box>
  </Popup>
);

export default UploadModal;
