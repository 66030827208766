import { Box, Text } from '@chakra-ui/react';
import { Formik, FormikHelpers } from 'formik';
import React, { FC } from 'react';

import { ApolloError } from '@apollo/client';
import {
  ExportConversationsToCrmIntegrationResultView,
  ExportType,
  SuppressionsSummaryDocument,
  useBlockContactsMutation,
  useExportConversationsToCrmIntegrationLazyQuery,
  useExportConversationsToFileLazyQuery,
} from '@app/api/gql/generated-types';
import Popup from '@app/components/Popup';
import { usePopupsContext } from '@app/hooks/usePopupsContext';
import { exportSchema } from '@app/schemas/export-schema';
import { downloadFileFromUrl } from '@app/utils/downloadFileFromUrl';
import { Enum } from '@app/utils/enum';
import { catchErrorLog, getLogger } from '@app/utils/logger';

import { ExportFormProps, TExportTypes } from '@app/types/Export';
import { appendFileExtension } from '@app/utils/ExportUtils';
import { ExportForm } from '../ExportForm';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  setInProgress: (value: boolean) => void;
}

const LOG = getLogger('ExportModal');

export const ExportModal: FC<ExportModalProps> = ({
  isOpen,
  onClose,
  setInProgress,
}) => {
  const TExportFileExt = new Enum('csv');

  const initialValues: ExportFormProps = {
    exportBy: null,
    items: [],
    exportTo: '',
  };

  const { showConfirmPopup, closeConfirmPopup } = usePopupsContext();

  const [exportDataCSV] = useExportConversationsToFileLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setInProgress(false);
    },
    onError: () => {
      setInProgress(false);
    },
  });

  const [exportToCrmMutation] = useExportConversationsToCrmIntegrationLazyQuery(
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setInProgress(false);
      },
      onError: () => {
        setInProgress(false);
      },
    },
  );

  const [blockContacts] = useBlockContactsMutation({
    notifyOnNetworkStatusChange: true,
    context: {
      notify: {
        success: () => 'Contacts added to DNC list successfully',
        error: (error: ApolloError[]) => error[0].message,
      },
    },
    refetchQueries: [
      {
        query: SuppressionsSummaryDocument,
      },
    ],
    update(cache) {
      cache.evict({ fieldName: 'findConversations' });
      cache.evict({ fieldName: 'findSuppressions' });
    },
    onCompleted: () => {
      setInProgress(false);
    },
    onError: () => {
      setInProgress(false);
    },
  });

  const exportToCRM = async (
    exportType: ExportType,
    data: string[],
    appId: string,
  ) => {
    await exportToCrmMutation({
      variables: {
        input: {
          exportType,
          crmIntegrationId: appId,
          entityIds: data,
        },
      },
      context: {
        notify: {
          success: ({
            exportConversationsToCrmIntegration,
          }: {
            exportConversationsToCrmIntegration: ExportConversationsToCrmIntegrationResultView;
          }) => {
            return `${exportConversationsToCrmIntegration.success} out of ${exportConversationsToCrmIntegration.total} successfully exported`;
          },
          error: (error: ApolloError[]) => error[0].message,
        },
      },
    });
  };

  const getFile = async (exportType: ExportType, ids: string[]) => {
    const { data: { exportConversationsToFile } = {} } = await exportDataCSV({
      variables: {
        input: {
          exportType,
          entityIds: ids,
        },
      },
    });
    downloadFileFromUrl(
      exportConversationsToFile?.signedUrl,
      appendFileExtension('Export', TExportFileExt.enum.csv),
    );
  };

  const handleFileExport = (
    exportBy: typeof TExportTypes.type,
    itemIds: string[],
  ) => {
    TExportTypes.match(
      exportBy,
      {
        [TExportTypes.enum.Campaigns]: () =>
          getFile(ExportType.CAMPAIGNS, itemIds),
        [TExportTypes.enum.Groups]: () => getFile(ExportType.GROUPS, itemIds),
        [TExportTypes.enum.Labels]: () => getFile(ExportType.LABELS, itemIds),
      },
      () => null,
    );
  };

  const handleCRMExport = (
    appId: string,
    exportBy: typeof TExportTypes.type,
    itemIds: string[],
  ) => {
    TExportTypes.match(
      exportBy,
      {
        [TExportTypes.enum.Campaigns]: () =>
          void exportToCRM(ExportType.CAMPAIGNS, itemIds, appId),
        [TExportTypes.enum.Groups]: () =>
          void exportToCRM(ExportType.GROUPS, itemIds, appId),
        [TExportTypes.enum.Labels]: () =>
          void exportToCRM(ExportType.LABELS, itemIds, appId),
      },
      () => LOG.error('Unrecognized export type:', exportBy),
    );
  };

  const handleAddToDNCExport = async (itemIds: string[]) => {
    try {
      await showConfirmPopup({
        title: 'Add to DNC list',
        description: (
          <Text color="main.400" fontSize="14px" mb="25px" textAlign="center">
            Are you sure you want to add these contacts to Do Not Call list?
          </Text>
        ),
        confirmButtonText: 'Confirm',
      });
      await blockContacts({
        variables: {
          input: {
            labelIds: itemIds,
          },
        },
      }).catch((error) => LOG.error('Add to DNC Error', error));
      closeConfirmPopup();
      onClose();
    } catch (error) {
      catchErrorLog(error, 'SideBarContent/handleAddToDNCExport');
      LOG.error('Add to DNC Error', error);
    }
  };

  const handleExport = async (
    values: ExportFormProps,
    { setSubmitting }: FormikHelpers<ExportFormProps>,
  ) => {
    setSubmitting(true);
    setInProgress(true);

    try {
      const itemIds = values.items;
      switch (values.exportTo) {
        case 'file':
          handleFileExport(values.exportBy, itemIds);
          break;
        case 'addToDNC':
          await handleAddToDNCExport(itemIds);
          break;
        default:
          void handleCRMExport(values.exportTo, values.exportBy, itemIds);
          break;
      }
    } catch (error) {
      LOG.error('Export error:', error);
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Popup
      blockScrollOnMount
      closeOnOverlayClick
      isOpen={isOpen}
      maxW="380px"
      modalOverplayProps={{ backdropFilter: 'blur(10px)' }}
      title="Export"
      onClose={onClose}>
      <Box margin="20px 40px">
        <Formik
          initialValues={initialValues}
          validationSchema={exportSchema}
          onSubmit={handleExport}>
          {() => <ExportForm />}
        </Formik>
      </Box>
    </Popup>
  );
};
