import React, { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FormatOptionLabelContext } from 'react-select';

import SelectField from '@app/components/SelectField';

import {
  LimitOptionProps,
  tablePageLimitOption,
} from '../../utils/tableSelectLimits';

interface SelectPageLimitProps {
  pageLimit: number;
  handleChangePageLimit: ({ value }: { value: number }) => void;
}

export const SelectPageLimit: FC<SelectPageLimitProps> = ({
  pageLimit,
  handleChangePageLimit,
}) => {
  const formatLimitOptions = (
    data: LimitOptionProps,
    formatOptionLabelMeta: {
      context: FormatOptionLabelContext;
      selectValue: readonly LimitOptionProps[];
    },
  ) => {
    const isSelectedValue =
      formatOptionLabelMeta?.selectValue?.[0]?.value === data.value;
    return formatOptionLabelMeta?.context === 'value' ? (
      <Flex>
        <Text color="secondary.400">Display:</Text>
        <Text as="span" color="main.400" ml="3px">
          {data?.label}
        </Text>
      </Flex>
    ) : (
      <Text color={isSelectedValue ? 'primary.600' : 'main.400'} ml="3px">
        {data?.label}
      </Text>
    );
  };

  return (
    <SelectField
      formatOptionLabel={formatLimitOptions}
      options={tablePageLimitOption}
      placeholder="Select limit"
      value={tablePageLimitOption.find((limit) => limit.value === pageLimit)}
      onChange={handleChangePageLimit}
    />
  );
};
