import { FC, ReactElement } from 'react';
import { useEditableControls } from '@chakra-ui/react';
import type { PropGetter } from '@chakra-ui/react-utils';

interface GroupNameWrapperProps {
  children: ({
    isEditing,
    getEditButtonProps,
  }: {
    isEditing: boolean;
    getEditButtonProps: PropGetter;
  }) => ReactElement;
}

export const GroupNameWrapper: FC<GroupNameWrapperProps> = ({ children }) => {
  const { isEditing, getEditButtonProps } = useEditableControls();

  return children({ isEditing, getEditButtonProps });
};
