import ROUTES from '@app/utils/routes';

const INBOX_PREFIX = '/inbox';

const INBOX = {
  all: `${ROUTES.messenger}${INBOX_PREFIX}/all`,
  unread: `${ROUTES.messenger}${INBOX_PREFIX}/unread`,
  missedCalls: `${ROUTES.messenger}${INBOX_PREFIX}/missed-calls`,
  sent: `${ROUTES.messenger}${INBOX_PREFIX}/sent`,
  deleted: `${ROUTES.messenger}${INBOX_PREFIX}/deleted`,
  campaigns: `${ROUTES.messenger}${INBOX_PREFIX}/campaigns`,
  labels: `${ROUTES.messenger}${INBOX_PREFIX}/labels`,
  groups: `${ROUTES.messenger}${INBOX_PREFIX}/groups`,
  optedOut: `${ROUTES.messenger}${INBOX_PREFIX}/opted-out`,
};

export { INBOX };
