import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '@app/utils/localStorageUtils';
import { getLogger } from '@app/utils/logger';

interface IResizableBoxProps {
  ref: React.MutableRefObject<HTMLDivElement>;
  minWidth?: number;
  maxWidth?: number;
  storageKey?: string;
}

const MIN_WIDTH = 200;
const LOG = getLogger('useResizableWidth');

export const useResizableWidth = ({
  ref,
  minWidth = MIN_WIDTH,
  maxWidth = Infinity,
  storageKey,
}: IResizableBoxProps) => {
  const getInitialWidth = () => {
    if (storageKey) {
      const storedWidth = getLocalStorageItem<string>(
        storageKey,
        `${MIN_WIDTH}`,
      );

      LOG.debug('getInitialWidth storedWidth', storedWidth);
      if (storedWidth) {
        return Math.max(
          minWidth,
          Math.min(maxWidth, parseInt(storedWidth, 10)),
        );
      }
    }

    LOG.debug('getInitialWidth', minWidth);

    return minWidth;
  };

  const [width, setWidth] = useState(getInitialWidth);
  const mouseMoveHandlerRef = useRef<(e: MouseEvent) => void>();
  const mouseUpHandlerRef = useRef<() => void>();

  const mouseDownHandler = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();

      const x = e.clientX;
      const sbWidth = window.getComputedStyle(ref.current).width;
      const initialWidth = parseInt(sbWidth, 10);

      LOG.debug('mouseDownHandler initialWidth', initialWidth);

      mouseMoveHandlerRef.current = (mouseEvent: MouseEvent) => {
        const dx = x - mouseEvent.clientX; // Resize from left to right
        let newWidth = initialWidth + dx;

        if (newWidth < minWidth) {
          newWidth = minWidth;
        } else if (newWidth > maxWidth) {
          newWidth = maxWidth;
        }

        LOG.debug('mouseMoveHandler newWidth', newWidth);

        setWidth(newWidth);

        if (storageKey) {
          setLocalStorageItem(storageKey, newWidth.toString());
        }
      };

      mouseUpHandlerRef.current = () => {
        document.removeEventListener('mouseup', mouseUpHandlerRef.current);
        document.removeEventListener('mousemove', mouseMoveHandlerRef.current);
        LOG.debug('mouseUpHandler event listeners removed');
      };

      document.addEventListener('mousemove', mouseMoveHandlerRef.current);
      document.addEventListener('mouseup', mouseUpHandlerRef.current);
      LOG.debug('mouseDownHandler event listeners added');
    },
    [ref, minWidth, maxWidth, storageKey],
  );

  useEffect(() => {
    LOG.debug('useEffect cleanup');
    return () => {
      if (mouseMoveHandlerRef.current) {
        document.removeEventListener('mousemove', mouseMoveHandlerRef.current);
      }
      if (mouseUpHandlerRef.current) {
        document.removeEventListener('mouseup', mouseUpHandlerRef.current);
      }
    };
  }, []);

  return { width, mouseDownHandler };
};
