/**
 * Comparator function for sorting an array of objects by their 'name' property.
 * The 'name' is expected to be a string. The function can be used directly with
 * the array sort method.
 *
 * @param {T extends { name: string }} a - The first object for comparison.
 * @param {T extends { name: string }} b - The second object for comparison.
 * @returns {number} - The comparison result: -1 if 'a' should come before 'b',
 *                      1 if 'a' should come after 'b', or 0 if they are equal.
 * @template T - A type that extends an object with at least a 'name' property of type string.
 */
export const sortByPriceProperty = <T extends { price: number }>(
  a: T,
  b: T,
): number => {
  if (a.price > b.price) {
    return 1;
  }
  if (a.price < b.price) {
    return -1;
  }
  return 0;
};

/**
 * Determines whether a given index is the last index in an array.
 *
 * @template T
 * @param {number} index - The index to check.
 * @param {T[]} array - The array to check against.
 * @returns {boolean} - Returns true if the index is the last index, false otherwise.
 */
export const isLastIndex = <T>(index: number, array: T[]): boolean =>
  index === array.length - 1;

/**
 * Determines if the given index is the second to last index in the array.
 *
 * @param {number} index - The index to check.
 * @param {Array} array - The array to check against.
 * @returns {boolean} - True if the index is the second to last index in the array, false otherwise.
 */
export const isPreLastIndex = <T>(index: number, array: T[]): boolean =>
  index === array.length - 2;

/**
 * Determines if an argument is a non-empty array
 *
 * @param {any} arg - Any argument.
 * @returns {boolean} - True if arg is non empty array.
 */
export const isNonEmptyArray = (arg: any): boolean => {
  return arg && Array.isArray(arg) && arg.length > 0;
};

/**
 * Determines if an argument is an empty array
 *
 * @param {any} arg - Any argument.
 * @returns {boolean} - True if the arg is empty array.
 */
export const isEmptyArray = (arg: any): boolean => {
  return arg && Array.isArray(arg) && arg.length === 0;
};
