import React, { useCallback, useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import Popup from '@app/components/Popup';
import { useAcceptTermsAndConditionsMutation } from '@app/api/gql/generated-types';
import { useIsVisible } from '@app/hooks/useIsVisible';
import { customScrollbarCssProps } from '@app/utils/customScrollbar';
import { TermsAndConditions } from './TermsAndConditions';

export const NewTermsAndConditionsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [bottomElement, setBottomElement] = useState<Element | null>(null);
  const atBottomOfTerms = useIsVisible(bottomElement);
  const [accept] = useAcceptTermsAndConditionsMutation();

  const handleClick = useCallback(async () => {
    await accept();
    onClose();
  }, [accept, onClose]);

  return (
    <Popup
      hideCloseIcon
      isOpen={isOpen}
      maxW="600px"
      title="Terms and Conditions update"
      titlePadding="40px 40px 30px"
      onClose={onClose}>
      <Box pb="40px" px="40px">
        <Text color="main.400" fontSize="14px" mb="30px">
          We have updated our Terms and Conditions. Please read through them
          carefully and confirm your acknowledgment of these updates.
        </Text>

        <Box
          __css={customScrollbarCssProps}
          borderColor="secondary.200"
          borderRadius="6px"
          borderWidth="1px"
          color="main.400"
          fontSize="14px"
          height="432px"
          lineHeight="16px"
          mb="20px"
          overflowY="scroll"
          p="17px">
          <TermsAndConditions />
          {/*
           * Important that the <Text> (or some other element) is above this
           * one, otherwise the useIsVisible doesn't work.
           */}
          <Box ref={setBottomElement} h="1px" opacity={0.01}>
            .
          </Box>
        </Box>

        <Flex justify="end">
          <Button
            isDisabled={!atBottomOfTerms}
            variant="primary"
            onClick={handleClick}>
            Accept & Continue
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};
