import { useEffect, useState } from 'react';
import update from 'immutability-helper';
import { getLogger } from '@app/utils/logger';
import { onDropStatic } from '@app/utils/dragDropUtils';

const LOG = getLogger('useDragAndDrop');

export const useDragAndDrop = <T extends { id: string }>(items: T[] = []) => {
  const [data, setData] = useState<T[]>([]);

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRecord = data[dragIndex];
    setData(
      update(data, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      }),
    );
  };

  const onDrop = (item: T) => onDropStatic(data, item);

  useEffect(() => {
    setData(items);
    LOG.debug('items updated', items);
  }, [items]);

  return {
    data,
    moveRow,
    onDrop,
  };
};
