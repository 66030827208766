import { Box, Button, Text } from '@chakra-ui/react';
import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import Popup from '@app/components/Popup';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import {
  accountModalText,
  AccountStatusModal,
} from '@app/utils/accountModalText';

interface ActivateAccountPopupProps {
  isOpen: boolean;
  onClose: () => void;
  canClose?: boolean;
}

/**
 * @deprecated Use the new accesbilityFeatures instead or should be migrated to permissions.
 */
const ActivateAccountPopup: FC<ActivateAccountPopupProps> = ({
  isOpen,
  onClose,
  canClose = false,
}) => {
  const navigate = useNavigate();
  const account = useCurrentAccountData();

  const message = useMemo(
    () =>
      account?.isSubAccount
        ? accountModalText[AccountStatusModal.SECONDARY_ACCOUNT]
        : accountModalText[AccountStatusModal.IS_INACTIVE],
    [account?.isSubAccount],
  );

  const goToPage = useCallback(() => {
    navigate(message.route);
    onClose();
  }, [message.route, navigate, onClose]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={!canClose}
      isOpen={isOpen}
      maxW="340px"
      title={message.title}
      width="340px"
      onClose={onClose}>
      <Box margin="21px 30px 25px 30px">
        <Text fontSize="14px" mb="25px" textAlign="center" width="270px">
          {message.description}
        </Text>

        <Button
          minWidth="100%"
          mr="20px"
          variant="primary"
          width="100%"
          onClick={account?.isSubAccount ? onClose : goToPage}>
          {message.buttonText}
        </Button>
        {canClose && (
          <Button height="40px" variant="cancel" width="100%" onClick={onClose}>
            Cancel
          </Button>
        )}
      </Box>
    </Popup>
  );
};

export default ActivateAccountPopup;
