import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import ROUTES from '@app/utils/routes';
import { CustomModalProps } from './CustomModal';

export const PermissionModalPropsMessagingPlanActivate: CustomModalProps = {
  title: 'Activate messaging subscription',
  primaryAction: ROUTES.settingsMembership,
  primaryButtonText: 'See messaging plans',
  children: (
    <Box mb="30px" textAlign="center">
      To gain access to this feature you must first activate a Messaging plan
      subscription
    </Box>
  ),
};

export const PermissionModalPropsCallingPlanActivate: CustomModalProps = {
  title: 'Activate calling subscription',
  primaryAction: ROUTES.settingsMembership,
  primaryButtonText: 'See calling plans',
  children: (
    <Box mb="30px" textAlign="center">
      To gain access to this feature you must first activate a Calling plan
      subscription
    </Box>
  ),
};

export const PermissionModalPropsSendMessagePhoneNumber: CustomModalProps = {
  title: 'Activate messaging subscription',
  primaryAction: ROUTES.settingsMembership,
  primaryButtonText: 'See messaging plans',
  children: (
    <Box mb="30px" textAlign="center">
      Dialer phone numbers are not 10DLC enabled for SMS messaging. To send SMS
      you must first activate a messaging plan subscription
    </Box>
  ),
};

export const PermissionModalPropsBrandRegistration: CustomModalProps = {
  title: '10 DLC brand registration',
  primaryAction: ROUTES.settings10DlcRegistration,
  primaryButtonText: 'Go to brand registration',
  children: (
    <Box mb="30px" textAlign="center">
      10 DLC brand registration must be approved before sending SMS messages.
      Submit your registration or check pending status
    </Box>
  ),
};

export const PermissionModalPropsSelectYourPlan: CustomModalProps = {
  title: 'Select your plan',
  primaryAction: ROUTES.settingsMembership,
  primaryButtonText: 'Choose a plan',
  children: (
    <Box mb="30px" textAlign="center">
      To gain access to this feature you must first select your desired
      subscription plan
    </Box>
  ),
};

export const PermissionModalPropsSubaccountsNotification: CustomModalProps = {
  title: 'Notification',
  primaryAction: ROUTES.messenger,
  primaryButtonText: 'Close',
  children: (
    <Box mb="30px" textAlign="center">
      To reactivate your subscription and regain access to your account, contact
      the primary account holder
    </Box>
  ),
};

export const ModalAccessMicrophoneContent: CustomModalProps = {
  title: 'Microphone access needed',
  //this will be overriden from outside in this case.
  primaryAction: ROUTES.settingsMembership,
  primaryButtonText: 'Got it',
  children: (
    <Text mb="30px" textAlign="center" variant="heading-fifth">
      Your browser&apos;s microphone access is currently disabled. To make
      calls, please enable it in your browser settings
    </Text>
  ),
};
