import React, { ChangeEvent, FC } from 'react';
import { Input } from '@chakra-ui/react';
import type { InputProps } from '@chakra-ui/react';
import InputMask from 'react-input-mask';

interface PhoneNumberInputProp extends InputProps {
  value: string;
  hasError?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent) => void;
  placeholder?: string;
}

const PhoneNumberInput: FC<PhoneNumberInputProp> = ({
  value,
  hasError,
  onChange,
  onBlur,
  placeholder = 'Phone number',
  ...props
}) => {
  return (
    <Input
      as={InputMask}
      borderColor={hasError ? 'error' : 'secondary.200'}
      id="phoneNumber"
      mask="(999) 999-9999"
      placeholder={placeholder}
      value={value}
      variant="primary"
      onBlur={onBlur}
      onChange={onChange}
      {...props}
    />
  );
};

export default PhoneNumberInput;
