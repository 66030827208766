import React from 'react';
import { Text } from '@chakra-ui/react';

interface ConfirmModalProps {
  message: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ message }) => {
  return (
    <Text
      color="main.400"
      fontSize="14px"
      lineHeight="normal"
      maxWidth={['100%', '300px']}
      mb="25px"
      textAlign="center">
      {message}
    </Text>
  );
};

export default ConfirmModal;
