import { getLogger } from '@app/utils/logger';

const LOG = getLogger('utils/queryParamUtils');

/**
 * Retrieves a query parameter from the current URL's search parameters.
 * If the parameter is not found or is empty, the default value is returned.
 *
 * @template T - The type of the query parameter and default value.
 * @param {T} param - The name of the query parameter to retrieve.
 * @param {T} defaultValue - The default value to return if the query parameter is not found or is empty.
 * @return {T | null} - The value of the query parameter, or the default value if not found or empty, or null if an error occurs.
 */
export const getQueryParam = <T extends string>(
  param: T,
  defaultValue?: T,
): T | null => {
  try {
    const params = new URLSearchParams(window.location.search);
    let value = params.get(param) as T;

    if (value) {
      value = value.replace(' ', '+') as T;
    }

    LOG.debug(`Get query param`, {
      param,
      value,
    });

    return value || defaultValue || null;
  } catch (e) {
    LOG.error(`Error getting query param: ${param}`, e);
    return defaultValue || null;
  }
};
