import { useGetGroupQuery } from '../gql/generated-types';

export const useGroupsData = (id?: string) => {
  const { data: { getGroup: data } = {}, loading } = useGetGroupQuery({
    variables: {
      id,
    },
    skip: !id,
  });

  return {
    data,
    isLoading: loading,
  };
};
