import {
  ContactOrder,
  ContactOrderBy,
  ConversationOrder,
  ConversationOrderBy,
  OrderQuery,
} from '@app/api/gql/generated-types';

export enum SortLabel {
  NEWEST = 'Newest',
  OLDEST = 'Oldest',
  FAVORITE = 'Favorite',
  CLOSED_DEALS = 'Closed deals',
}

export const defaultSort: SortLabel[] = [
  SortLabel.NEWEST,
  SortLabel.OLDEST,
  SortLabel.FAVORITE,
];

export const groupSort: SortLabel[] = [SortLabel.NEWEST, SortLabel.OLDEST];

export const messagesSort: SortLabel[] = [
  SortLabel.NEWEST,
  SortLabel.OLDEST,
  SortLabel.FAVORITE,
  SortLabel.CLOSED_DEALS,
];

export const sortDefaultForRequest: { [sortLabel: string]: OrderQuery } = {
  [SortLabel.NEWEST]: OrderQuery.DESC,
  [SortLabel.OLDEST]: OrderQuery.ASC,
} as const;

export const sortMessagesForRequest: {
  [sortLabel: string]: ConversationOrder;
} = {
  [SortLabel.NEWEST]: {
    by: ConversationOrderBy.UPDATED_AT,
    direction: OrderQuery.DESC,
  },
  [SortLabel.OLDEST]: {
    by: ConversationOrderBy.UPDATED_AT,
    direction: OrderQuery.ASC,
  },
  [SortLabel.FAVORITE]: {
    by: ConversationOrderBy.FAVORITE,
    direction: OrderQuery.DESC,
  },
  [SortLabel.CLOSED_DEALS]: {
    by: ConversationOrderBy.DEAL_CLOSED_AT,
    direction: OrderQuery.DESC,
  },
} as const;

export const sortContactsForRequest: {
  [sortLabel: string]: ContactOrder;
} = {
  [SortLabel.NEWEST]: {
    by: ContactOrderBy.CREATED_AT,
    direction: OrderQuery.DESC,
  },
  [SortLabel.OLDEST]: {
    by: ContactOrderBy.CREATED_AT,
    direction: OrderQuery.ASC,
  },
  [SortLabel.FAVORITE]: {
    by: ContactOrderBy.FAVORITE,
    direction: OrderQuery.DESC,
  },
} as const;
