import { Box } from '@chakra-ui/react';
import isBoolean from 'lodash/isBoolean';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { cloneDeep } from 'lodash';
import {
  AccountDocument,
  Campaign,
  CampaignStatus,
  FindListCampaignsQuery,
  useAccountQuery,
  useFindListCampaignsLazyQuery,
  useFindListCampaignsQuery,
  useUpdateMessagingProfileMutation,
} from '@app/api/gql/generated-types';

import { createPaginationVariables } from '@app/api/apollo-pagination';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import MultiSelectField from '@app/components/MultiSelectField';

interface SelectedCampaign extends Campaign {
  value: Campaign['id'];
  label: string;
  isChecked?: boolean;
}

export const Campaigns: FC = () => {
  const { data: { account } = {} } = useAccountQuery();
  const { messagingProfile } = account;
  const [updateMessagingProfile] = useUpdateMessagingProfileMutation({
    refetchQueries: [
      {
        query: AccountDocument,
      },
    ],
    context: {
      useApolloNetworkStatus: true,
    },
  });

  const alertCampaigns = useMemo(() => {
    return isBoolean(messagingProfile?.phoneSettings?.alertFilter?.campaigns)
      ? []
      : messagingProfile?.phoneSettings?.alertFilter?.campaigns;
  }, [messagingProfile?.phoneSettings?.alertFilter?.campaigns]);

  const {
    data: { findCampaigns: findCampaignsByIds } = { findCampaigns: null },
    loading: selectedIsLoading,
  } = useFindListCampaignsQuery({
    variables: {
      filter: {
        ids: alertCampaigns ?? [],
      },
    },
    skip: !alertCampaigns?.length,
  });

  const [
    getCampaigns,
    {
      data: { findCampaigns } = { findCampaigns: null },
      fetchMore,
      networkStatus,
    },
  ] = useFindListCampaignsLazyQuery({
    variables: {
      filter: {
        status: [
          CampaignStatus.ACTIVE,
          CampaignStatus.PAUSED,
          CampaignStatus.DRAFT,
          CampaignStatus.ENDED,
        ],
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const { isLoading } = useApolloLoadingStatus(networkStatus);

  const fetchNextPage = () =>
    fetchMore(createPaginationVariables(findCampaigns));

  const [campaignsState, setCampaignsState] = useState<
    FindListCampaignsQuery['findCampaigns']['items']
  >([]);

  useEffect(() => {
    if (findCampaignsByIds?.total) {
      setCampaignsState(findCampaignsByIds?.items);
    }
  }, [findCampaignsByIds?.items, findCampaignsByIds?.total]);

  const options = useMemo(() => {
    const selectedCampaigns = campaignsState ?? [];
    const campaigns = findCampaigns?.items ?? [];

    if (!campaigns) {
      return [];
    }

    const filteredCampaigns = campaigns.filter(
      ({ id }) => !selectedCampaigns?.map((val) => val.id)?.includes(id),
    );

    return [
      ...(selectedCampaigns || []).map((campaign) => ({
        ...campaign,
        isChecked: true,
      })),
      ...filteredCampaigns,
    ];
  }, [campaignsState, findCampaigns?.items]) as SelectedCampaign[];

  const onSubmit = async () => {
    await updateMessagingProfile({
      variables: {
        input: {
          phoneSettings: {
            alertFilter: {
              campaigns: campaignsState?.map((item) => item.id),
            },
          },
        },
      },
    });
  };

  const onChange = (newValues: readonly Campaign[]) => {
    const values: Campaign[] = cloneDeep([...newValues]);
    setCampaignsState(values);
  };

  return (
    <Box maxWidth="408px" width="100%">
      <MultiSelectField
        isMulti
        autoFocus={false}
        closeMenuOnSelect={false}
        getOptionLabel={(option: Campaign) => option.name}
        getOptionValue={(option) => option.id}
        hideSelectedOptions={false}
        isClearable={false}
        isLoading={isLoading || selectedIsLoading}
        isSearchable={false}
        options={options}
        placeholder="Enter a campaign"
        // @ts-expect-error Campaign and GroupView circular dependency
        value={campaignsState}
        onChange={onChange}
        onMenuClose={onSubmit}
        onMenuOpen={() => getCampaigns()}
        onMenuScrollToBottom={fetchNextPage}
      />
    </Box>
  );
};
