import React, { FC } from 'react';
import { useCampaignIdQueryData } from '@app/api/queries/useCampaignIdQueryData';
import { useLabelData } from '@app/api/queries/useLabelData';
import { useGroupsData } from '@app/api/queries/useGroupsData';
import { DefaultName } from './DefaultName';
import { EntityCrumbName } from './EntityCrumbName';

interface BreadCrumbsProps {
  title: EntityType | string;
  entityId: string;
}

export enum EntityType {
  CAMPAIGNS = 'Campaigns',
  LABELS = 'Labels',
  GROUPS = 'Groups',
}

export const BreadCrumbs: FC<BreadCrumbsProps> = ({ title, entityId }) => {
  const queryHooks: Record<EntityType, () => unknown> = {
    [EntityType.CAMPAIGNS]: useCampaignIdQueryData,
    [EntityType.LABELS]: useLabelData,
    // eslint-disable-next-line react-hooks/rules-of-hooks
    [EntityType.GROUPS]: () => useGroupsData(entityId),
  };

  if (entityId) {
    return (
      <EntityCrumbName
        entityId={entityId}
        queryHook={queryHooks[title as EntityType]}
        title={title}
      />
    );
  }

  return <DefaultName title={title} />;
};
