// TODO: Check why it's rerender after upload

import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import isNil from 'lodash/isNil';
import { DEFAULT_LIMIT } from '@app/api/queries/utils';
import SortBy from '@app/components/Filter/SortBy';
import {
  groupSort,
  sortDefaultForRequest,
} from '@app/components/Filter/SortBy/interface';
import { useSortState } from '@app/components/Filter/SortBy/useSortState';
import IconButtonRemove from '@app/components/IconButtonRemove';
import { RemoveNotPossibleModal } from '@app/components/Modals/RemoveNotPossibleModal';
import { PageTitle } from '@app/components/PageTitle';
import { useEntitiesPicker } from '@app/hooks/useEntitiesPicker';
import { usePopupsContext } from '@app/hooks/usePopupsContext';
import PlusIcon from '@app/icons/plus-icon.svg?react';
import PlusInCircleIcon from '@app/icons/plus-in-circle-icon.svg?react';
import { useDialerContext } from '@app/providers/DialerProvider';
import { getLogger } from '@app/utils/logger';
import ROUTES from '@app/utils/routes';

import { createPaginationVariables } from '@app/api/apollo-pagination';
import {
  FindGroupsDocument,
  FindGroupsQuery,
  GetContactsCountersDocument,
  MessagingProviderType,
  useFindGroupsQuery,
  useRemoveGroupsMutation,
} from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import SearchField from '@app/components/SearchField';
import { TableSkeleton } from '@app/components/Table/Skeleton';
import use10DlcAccountStatusGuard from '@app/hooks/use10DlcAccountStatusGuard';
import { PermissionButton } from '@app/components/next/atoms/PermissionsComponents/WithPermission';
import { PermissionActions, PermissionFeatures } from '@app/acl';
import { useContactsUploadContext } from '../../../../providers/ContactsUploadProvider';
import { ContactVideoButton } from '../../components/ContactVideoButton';
import { CreateGroupModal } from '../../components/CreateGroupModal';
import { SelectPageLimit } from '../../components/SelectPageLimit';
import { GroupsTableList } from '../../components/TableList/GroupTable';

import { AllGroupBody } from './AllGroupBody';
import { SkeletonGroup } from './SkeletonGroup';

const LOG = getLogger('AllGroups');

const AllGroups: FC = () => {
  const navigate = useNavigate();
  const createGroupModal = useDisclosure();
  const removeNotPossibleModal = useDisclosure();
  const { providerType } = useDialerContext();
  const { showConfirmPopup, closeConfirmPopup } = usePopupsContext();
  const { dropModal } = useContactsUploadContext();

  const {
    select,
    selectAll,
    reset: resetSelectedItems,
    items: selectedItems,
  } = useEntitiesPicker<string>([]);

  const [sort, setSort] = useSortState();
  const [pageLimit, setPageLimit] = useState<number>(DEFAULT_LIMIT);
  const [searchValue, setSearchValue] = useState<string>();

  const guardDlcAction = use10DlcAccountStatusGuard(() =>
    navigate(ROUTES.campaignCreate, {
      state: { contactListIds: selectedItems },
    }),
  );

  const [removeGroupsMutation, { loading }] = useRemoveGroupsMutation({
    refetchQueries: [FindGroupsDocument, GetContactsCountersDocument],
    update: (cache, { data }, { variables }) => {
      if (data?.removeGroups?.success) {
        const removedGroupIds = variables?.input?.ids ?? [];
        cache.modify({
          fields: {
            findGroups(existingGroups, { readField }) {
              return {
                ...(existingGroups as FindGroupsQuery['findGroups']),
                items: (
                  existingGroups as FindGroupsQuery['findGroups']
                ).items.filter(
                  (groupRef) =>
                    !removedGroupIds.includes(readField('id', groupRef)),
                ),
              };
            },
          },
        });
      }
    },
  });

  const params = {
    pagination: {
      limit: pageLimit,
    },
    order: {
      direction: sortDefaultForRequest[sort],
    },
    filter: {
      name: searchValue || undefined,
    },
  };

  const {
    data: { findGroups } = {},
    refetch,
    fetchMore,
    networkStatus,
  } = useFindGroupsQuery({
    variables: params,
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  const { isLoading, isLoadingData, isFetchingNextPage, isReady } =
    useApolloLoadingStatus(networkStatus);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    void refetch(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, refetch]);

  const groupItems = useMemo(
    () => findGroups?.items ?? [],
    [findGroups?.items],
  );

  const fetchNextPage = () => {
    void fetchMore(createPaginationVariables(findGroups, pageLimit));
  };

  const selectedContacts = useMemo(
    () => groupItems.filter((group) => selectedItems.includes(group.id)),
    [groupItems, selectedItems],
  );

  const handleChangePageLimit = useCallback(
    async ({ value }: { value: number }) => {
      setPageLimit(value);
      await refetch({
        pagination: {
          limit: value,
        },
      });
    },
    [refetch],
  );

  const deleteSelected = async (id?: string, name?: string) => {
    const isMultipleDelete = selectedItems.length > 1;
    try {
      await showConfirmPopup({
        title: 'Delete',
        description: (
          <Text color="main.400" fontSize="14px" mb="25px" textAlign="center">
            {isMultipleDelete ? (
              'Are you sure you want to delete these contact groups?'
            ) : (
              <>
                Are you sure you want to delete the <br />
                <Text as="span" color="secondary.400">
                  {name}
                </Text>{' '}
                contact group?
              </>
            )}
          </Text>
        ),
        confirmButtonText: 'Confirm',
      });
      await removeGroupsMutation({
        variables: {
          input: {
            ids: id ? [id] : selectedItems,
          },
        },
        context: {
          useApolloNetworkStatus: true,
          notify: {
            success: () => `Group${id ? '' : 's'} removed successfully`,
            error: () => `Failed to remove group${id ? '' : 's'}`,
          },
        },
      });
      closeConfirmPopup();
      resetSelectedItems();
    } catch (error) {
      LOG.error(error);
    }
  };

  const handleGroupDeleteValidation = async (
    id?: string,
    name?: string,
    canBeRemoved = true,
  ) => {
    const contactsCanBeRemoved = selectedContacts.some(
      (contact) => !contact.canBeRemoved,
    );
    try {
      if (contactsCanBeRemoved || !canBeRemoved) {
        removeNotPossibleModal.onOpen();
        return;
      }

      await deleteSelected(id, name);
    } catch (error) {
      LOG.error(error);
    }
  };

  const handleGroupDelete = async (
    id: string,
    name: string,
    canBeRemoved: boolean,
  ) => {
    await handleGroupDeleteValidation(id, name, canBeRemoved);
  };

  const handleSelectAll = () => {
    if (!isEmpty(selectedItems)) {
      resetSelectedItems();
      return;
    }

    selectAll(groupItems.map((el) => el.id));
  };

  const isDisabledPushToCampaign = useMemo(() => {
    if (isEmpty(selectedItems) || selectedItems.length > 10) {
      return true;
    }

    if (selectedContacts.some((c) => !c.verified || c.contacts === 0)) {
      return true;
    }

    return false;
  }, [selectedContacts, selectedItems]);

  const pushToContactTooltipLabel = useMemo(() => {
    if (selectedContacts.length > 10) {
      return (
        <>
          You must select less than{' '}
          <Text as="span" color="secondary.400">
            10 groups
          </Text>
        </>
      );
    }
    if (selectedContacts.some((c) => !c.verified)) {
      return 'Please select a verified group of contacts';
    }
    if (selectedContacts.some((c) => c.contacts === 0)) {
      return 'Please select a verified group with at least 1 contact';
    }

    return 'Please select a verified group of contacts';
  }, [selectedContacts]);

  return (
    <Flex direction="column" overflow="hidden" width="100%">
      <PageTitle title="Groups" />

      {isLoading ? (
        <Flex direction="column" overflow="hidden" width="100%">
          <SkeletonGroup />
        </Flex>
      ) : (
        <AllGroupBody
          isEmpty={
            isReady &&
            isNil(searchValue) &&
            !isLoadingData &&
            isEmpty(groupItems)
          }>
          <>
            <Flex
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              p="19px 17px 0px 16px">
              <Text m="0 0 0 14px" variant="sub-heading">
                Groups
              </Text>

              <Flex direction="row">
                <ContactVideoButton />
                <SearchField onChange={setSearchValue} />
              </Flex>
            </Flex>

            <Flex margin="20px auto 21px" padding="0 17px 0 30px" width="100%">
              <ButtonGroup spacing="5">
                <PermissionButton
                  action={PermissionActions.UPLOAD}
                  px="4"
                  subject={PermissionFeatures.contacts.contacts}
                  variant="primary"
                  onSuccess={dropModal.onOpen}>
                  Upload contacts
                </PermissionButton>
                <PermissionButton
                  action={PermissionActions.CREATE}
                  leftIcon={<PlusIcon />}
                  subject={PermissionFeatures.contacts.group}
                  variant="outlined"
                  onSuccess={createGroupModal.onOpen}>
                  Create group
                </PermissionButton>
                <Tooltip
                  hasArrow
                  shouldWrapChildren
                  isDisabled={!isDisabledPushToCampaign}
                  label={pushToContactTooltipLabel}
                  padding="10px"
                  placement="right">
                  <PermissionButton
                    action={PermissionActions.CREATE}
                    isDisabled={
                      providerType === MessagingProviderType.TWILIO ||
                      isDisabledPushToCampaign
                    }
                    leftIcon={<PlusInCircleIcon />}
                    subject={PermissionFeatures.contacts.pushToCampaign}
                    variant="outlined"
                    onSuccess={guardDlcAction}>
                    Push to campaign
                  </PermissionButton>
                </Tooltip>
              </ButtonGroup>
              <Spacer />
              {!isEmpty(groupItems) && (
                <Box height="40px" width="135px" zIndex="11">
                  <SelectPageLimit
                    handleChangePageLimit={handleChangePageLimit}
                    pageLimit={pageLimit}
                  />
                </Box>
              )}
            </Flex>

            <Flex direction="column">
              {!isEmpty(selectedItems) ? (
                <Box>
                  <IconButtonRemove
                    isDisabled={loading}
                    isLoading={loading}
                    mb="14px"
                    ml="22px"
                    size="small"
                    onClick={() => handleGroupDeleteValidation()}
                  />
                </Box>
              ) : (
                <Flex mb="16px" ml="14px" position="relative">
                  <SortBy labels={groupSort} value={sort} onChange={setSort} />
                </Flex>
              )}
            </Flex>
            {isLoadingData ? (
              <TableSkeleton />
            ) : (
              <GroupsTableList
                fetchNextPage={fetchNextPage}
                hasNextPage={findGroups?.hasNext}
                isFetchingNextPage={isFetchingNextPage}
                isLoadingGroups={isLoadingData}
                // @ts-expect-error Campaign and GroupView circular dependency
                items={groupItems}
                params={params.filter}
                selectedItems={selectedItems}
                onRemoveContactsList={handleGroupDelete}
                onSelectAll={handleSelectAll}
                onSelectItem={select}
              />
            )}
          </>
        </AllGroupBody>
      )}

      <CreateGroupModal
        isOpen={createGroupModal.isOpen}
        title="Create new group"
        onClose={createGroupModal.onClose}
        onCreateGroup={createGroupModal.onClose}
      />
      {removeNotPossibleModal.isOpen && (
        <RemoveNotPossibleModal
          isOpen={removeNotPossibleModal.isOpen}
          text="You cannot delete the selected group(s). Some are selected in Active or Paused campaigns."
          title="Delete groups"
          onClose={removeNotPossibleModal.onClose}
        />
      )}
    </Flex>
  );
};

export default AllGroups;
