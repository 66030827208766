import { Box, Grid } from '@chakra-ui/react';
import React, { FC } from 'react';

import Popup from '@app/components/Popup';
import { ScriptTemplate } from './TextTemplate';

interface CallScriptModalProps {
  isOpenModal: boolean;
  onCloseModal: (id?: string) => void;
}

export const CallScriptModal: FC<CallScriptModalProps> = ({
  isOpenModal,
  onCloseModal,
}) => {
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpenModal}
      maxW="770px"
      title="Add new script"
      width="770px"
      onClose={onCloseModal}>
      <Grid
        gap={3}
        justifyContent="center"
        p="25px 40px 40px"
        templateColumns={{
          base: 'repeat(1, 1fr)',
        }}>
        <Box>
          <ScriptTemplate onCloseModal={onCloseModal} />
        </Box>
      </Grid>
    </Popup>
  );
};
