import { Box, Flex, Text } from '@chakra-ui/react';
import React, { memo } from 'react';
import { Link, Outlet, useMatch, useNavigate } from 'react-router-dom';

import { useAccountIsAdmin } from '@app/hooks/useAccountIsAdmin';
import EnvironmentLabel from '@app/navigation/components/EnvironmentLabel';
import { MenuDropdown } from '@app/navigation/components/MenuDropdown';

import { NotificationsSection } from '@app/components/next/organisms/NotificationsSection/NotificationsSection';
import { DialerStatusDropdown } from '@app/navigation/components/DialerStatusDropdown';
import { usePowerDialerContext } from '@app/providers/PowerDialerProvider';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import { TTextVariant } from '@app/theme/components/text';
import { hasDialerSubscription } from '@app/utils/subscriptionHelpers';
import { colors } from '@app/theme/colors';
import SCLogo from '../icons/LogoSmarterContactIcon.svg?react';
import ROUTES, { ROOT_ROUTES } from '../utils/routes';

import { AvatarPopover } from './components/AvatarPopover';
import { SettingsSection } from './SettingsSection';
import { NavLink, LinkSettings, DialerNav } from './styles';

const DialerNavLink: React.FC<{
  disabled: boolean;
}> = ({ disabled }) => {
  const navigate = useNavigate();

  const redirectHandler = () => {
    navigate(ROUTES.settingsMembership);
  };

  return disabled ? (
    <Flex
      justifyContent="center"
      marginRight="20px"
      position="relative"
      title="Dialer"
      width="71px"
      onClick={redirectHandler}>
      <Box
        background="linen"
        borderRadius="full"
        cursor="pointer"
        left="calc(100% - 24px)"
        p="3px 10px"
        position="absolute"
        right="-20px"
        textAlign="center"
        top="-18px">
        <Text color="orangeBlaze" fontSize="12px" lineHeight="13px">
          New
        </Text>
      </Box>
      <Box
        height="13px"
        left="calc(100% - 14px)"
        position="absolute"
        top="3px"
        width="12px">
        <svg fill="none" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11 1C11 7.5 6.5 11 0.5 11M0.5 11L3.5 8M0.5 11L5 12.5"
            stroke={colors.orangeBlaze}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
      <Text
        _hover={{ color: 'secondary.600' }}
        cursor="pointer"
        fontWeight="500"
        lineHeight="24px"
        variant={TTextVariant.enum['secondary-text']}>
        Dialer
      </Text>
    </Flex>
  ) : (
    <DialerNav to={ROUTES.dialer} width="71px">
      <Box title="Dialer">Dialer</Box>
    </DialerNav>
  );
};

const UINavigationPanel: React.FC = () => {
  const settingsMatch = useMatch(`${ROUTES.settings}/*`);
  const activeDialingCampaignMatch = useMatch(`${ROUTES.powerDialer}/*`);
  const activeCampaignMatch = useMatch(`${ROOT_ROUTES.campaign}/*`);
  const messengerFull = useMatch(`${ROUTES.messengerFull}/*`);

  const isAdmin: boolean = useAccountIsAdmin();
  const account = useCurrentAccountData();
  const promoMaterialsVisible = !hasDialerSubscription(account);

  const {
    isAgentOnline,
    setIsAgentOnline,
    isCampaignComplete,
    isConnectedToProvider,
  } = usePowerDialerContext();

  return (
    <>
      <Flex
        direction="row"
        justifyContent="space-between"
        p="20px"
        width="100%">
        <Flex alignItems="center" direction="row">
          <Box cursor="pointer" m="0 20px 0 23px" position="relative">
            <Link to={ROUTES.messenger}>
              <SCLogo />

              <EnvironmentLabel />
            </Link>
          </Box>
          <Flex height="25px">
            <NavLink
              className={messengerFull?.pattern?.end && 'active activeFull'}
              to={ROUTES.messenger}>
              <Box title="Messenger">Messenger</Box>
            </NavLink>
            <NavLink
              className={activeCampaignMatch?.pattern?.end && 'active'}
              to={ROUTES.campaigns}>
              <Box title="Campaigns">Campaigns</Box>
            </NavLink>
            <NavLink to={ROUTES.contacts}>
              <Box title="Contacts">Contacts</Box>
            </NavLink>
            <DialerNavLink disabled={promoMaterialsVisible} />
            <NavLink to={`${ROUTES.calendar}/month`}>
              <Box title="Calendar">Calendar</Box>
            </NavLink>
            <NavLink to={ROUTES.skiptrace}>
              <Box title="Skiptrace">Skiptrace</Box>
            </NavLink>
            <NavLink to={ROUTES.reporting}>
              <Box title="Reporting">Reporting</Box>
            </NavLink>
            {isAdmin && (
              <>
                <NavLink to={ROUTES.userSetup}>
                  <Box title="User setup">User setup</Box>
                </NavLink>
                <MenuDropdown />
              </>
            )}
          </Flex>
        </Flex>
        <Flex alignItems="center" gap="30px" ml="20px">
          {activeDialingCampaignMatch && (
            <DialerStatusDropdown
              isConnectedToProvider={isConnectedToProvider}
              isDisabled={isCampaignComplete}
              isOnline={isAgentOnline}
              onChange={setIsAgentOnline}
            />
          )}

          <Flex alignItems="center" height="20px">
            <NotificationsSection />
            <LinkSettings
              className={settingsMatch?.pattern?.end && 'active'}
              mr="22px"
              to={ROUTES.settingsProfile}>
              <SettingsSection />
            </LinkSettings>
            <AvatarPopover />
          </Flex>
        </Flex>
      </Flex>
      <Outlet />
    </>
  );
};

export default memo(UINavigationPanel);
