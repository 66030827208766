import React, { FC, useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';

import CalendarIcon from '@app/icons/calendar-icon.svg?react';
import { DATE_FORMAT_LLL_DD_YYYY, formatDate } from '@app/utils/formatDate';

import { TriggerProps } from '../types';

export const Select: FC<TriggerProps> = ({ isOpen, start, end }) => {
  const value = useMemo(
    () =>
      `${formatDate({
        date: start,
        format: DATE_FORMAT_LLL_DD_YYYY,
      })} - ${formatDate({
        date: end,
        format: DATE_FORMAT_LLL_DD_YYYY,
      })}`,
    [start, end],
  );

  const isShowValue = useMemo(() => !!start && !!end, [end, start]);

  return (
    <Box
      backgroundColor="white"
      border="1px solid"
      borderColor={isOpen ? 'primary.500' : 'secondary.200'}
      borderRadius="22px"
      cursor="pointer"
      height="40px"
      p="12px 40px 12px 20px"
      position="relative">
      <Text
        color="secondary.400"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="normal"
        lineHeight="16px"
        overflow="hidden">
        {isShowValue ? value : 'Choose date'}
      </Text>

      <Box
        _hover={{
          color: 'primary.600',
        }}
        color="secondary.400"
        position="absolute"
        right="13px"
        top="11px">
        <CalendarIcon />
      </Box>
    </Box>
  );
};
