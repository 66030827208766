import { Collapse, Flex, Text, useDisclosure } from '@chakra-ui/react';
import React, {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import SearchField from '@app/components/SearchField';

import { DEFAULT_LIMIT } from '@app/api/queries/utils';
import SortBy from '@app/components/Filter/SortBy';
import { LabelFilterList } from '@app/components/LabelList/LabelFilterList';
import { RemoveNotPossibleModal } from '@app/components/Modals/RemoveNotPossibleModal';
import { PageTitle } from '@app/components/PageTitle';
import { usePopupsContext } from '@app/hooks/usePopupsContext';
import { AllContactBody } from '@app/pages/Contacts/content/AllContacts/AllContactBody';
import { getLogger } from '@app/utils/logger';
import ROUTES, { generateUrl } from '@app/utils/routes';

import { createPaginationVariables } from '@app/api/apollo-pagination';
import {
  Contact,
  ContactStatus,
  FindContactsDocument,
  GetContactsCountersDocument,
  useFindContactsQuery,
  useRemoveContactsMutation,
} from '@app/api/gql/generated-types';
import { sortContactsForRequest } from '@app/components/Filter/SortBy/interface';
import { TableSkeleton } from '@app/components/Table/Skeleton';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import { fullTextInputValidation } from '@app/utils/fullTextInputValidation';
import FilterByLabel from '../../../../components/Filter/FilterByLabel';
import { ContactGroupActions } from '../../components/ContactGroupActions';
import { ContactVideoButton } from '../../components/ContactVideoButton';
import {
  ConfirmRemoveContactDescription,
  ConfirmRemoveContactsDescription,
} from '../../components/Modal/ContactRemoveDescription';
import { TableList } from '../../components/TableList';
import { useContacts } from '../../hooks/useContacts';
import { SkeletonGroup } from '../GroupsContacts/SkeletonGroup';

import { AllContactsTopSection } from './GroupButtons';

const LOG = getLogger('AllContacts');

const AllContacts: FC = () => {
  const navigate = useNavigate();
  const {
    select,
    selectAll,
    reset: resetSelectedItems,
    items: selectedItems,
    sort,
    setSort,
    searchValue,
    setSearchValue,
    resetSelected,
  } = useContacts();

  const location = useLocation();

  const { contactId, tabId } = useParams<{
    contactId?: string;
    tabId?: string;
  }>();
  const client = useApolloClient();

  const [labels, setLabels] = useState<Array<string>>([]);
  const [pageLimit, setPageLimit] = useState<number>(DEFAULT_LIMIT);

  const params = {
    filter: {
      ...(fullTextInputValidation(searchValue) && {
        fullSearch: searchValue,
      }),
      ...(labels.length && { labels }),
      status: ContactStatus.ACTIVE,
    },
    pagination: {
      limit: pageLimit,
    },
    order: {
      ...sortContactsForRequest[sort],
    },
  };

  const {
    data: { findContacts: contactsData } = {},
    fetchMore,
    refetch,
    networkStatus,
  } = useFindContactsQuery({
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables: params,
  });
  const { isLoading, isLoadingData, isFetchingNextPage, isReady } =
    useApolloLoadingStatus(networkStatus);

  const fetchNextPage = () =>
    fetchMore(createPaginationVariables(contactsData, pageLimit));

  const [mutateRemove] = useRemoveContactsMutation({
    context: {
      useApolloNetworkStatus: true,
      notify: {
        success: () => 'Contacts removed successfully',
        error: () => 'Failed to remove contacts',
      },
    },
    refetchQueries: [GetContactsCountersDocument],
    onCompleted: async () => {
      await client.refetchQueries({
        include: [FindContactsDocument],
        updateCache: (cache) => {
          cache.evict({
            fieldName: 'findContacts',
            broadcast: false,
          });
        },
      });
    },
  });

  const { showConfirmPopup, closeConfirmPopup } = usePopupsContext();
  const removeNotPossibleModal = useDisclosure();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = (contactsData?.items as Contact[]) ?? [];

  const canRemoveContacts = useMemo(
    () =>
      !items
        ?.filter((contact) => selectedItems.includes(contact.id))
        ?.some((contact) => !contact.canBeRemoved),
    [items, selectedItems],
  );

  const handleChangePageLimit = useCallback(
    async ({ value }: { value: number }) => {
      setPageLimit(value);
      await refetch({
        pagination: {
          limit: value,
        },
      });
    },
    [refetch],
  );

  const handleRemoveContact = useCallback(
    async (contactItem: Contact) => {
      try {
        if (!contactItem.canBeRemoved) {
          removeNotPossibleModal.onOpen();
          return;
        }
        await showConfirmPopup({
          title: 'Delete',
          description: ConfirmRemoveContactDescription(contactItem),
          confirmButtonText: 'Delete',
        });

        await mutateRemove({
          variables: {
            input: {
              ids: [contactItem.id],
            },
          },
          context: {
            useApolloNetworkStatus: true,
            notify: {
              success: () => 'Contact removed successfully',
              error: () => 'Failed to remove contact',
            },
          },
        });

        closeConfirmPopup();
        resetSelected();
        await refetch();
      } catch (error) {
        LOG.error(error);
      }
    },
    [
      showConfirmPopup,
      mutateRemove,
      closeConfirmPopup,
      resetSelected,
      removeNotPossibleModal,
      refetch,
    ],
  );

  const handleSelectAll = () => {
    if (!isEmpty(selectedItems)) {
      resetSelectedItems();
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    selectAll(items.map((item) => item.id));
  };

  const handleContactClick = useCallback(
    (contact: Contact) => {
      if (contactId === contact.id) {
        navigate(`${ROUTES.contactsAll}`);
        return;
      }
      navigate(generateUrl(ROUTES.contactsAllInfo, { contactId: contact.id }));
    },
    [contactId, navigate],
  );

  const removeContactsFromList = useCallback(async () => {
    try {
      if (!canRemoveContacts) {
        removeNotPossibleModal.onOpen();
        return;
      }
      await showConfirmPopup({
        title: 'Delete',
        description: ConfirmRemoveContactsDescription(),
        confirmButtonText: 'Delete',
      });

      await mutateRemove({
        variables: {
          input: {
            ids: selectedItems,
          },
        },
      });

      if (selectedItems.includes(contactId)) {
        let paths: string[] = location.pathname.split('/');
        if (tabId) {
          paths = paths.slice(0, paths.length - 2);
        } else {
          paths.pop();
        }
        navigate(paths.join('/'));
      }
      closeConfirmPopup();
      resetSelected();
      if (selectedItems.length === DEFAULT_LIMIT) {
        await refetch();
      }
    } catch (error) {
      LOG.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showConfirmPopup,
    mutateRemove,
    selectedItems,
    resetSelected,
    tabId,
    refetch,
  ]);

  const removeContacts = useCallback(
    async (event?: MouseEvent<HTMLElement>, contactItem?: Contact) => {
      event?.stopPropagation();

      try {
        if (contactItem) {
          await handleRemoveContact(contactItem);
        } else {
          await removeContactsFromList();
        }
      } catch (error) {
        LOG.error(error);
      }
    },
    [handleRemoveContact, removeContactsFromList],
  );

  useEffect(() => {
    refetch()
      .then(() => {
        LOG.debug('Contacts refetched');
      })
      .catch((error) => {
        LOG.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Flex direction="column" overflow="hidden" width="100%">
        <SkeletonGroup />
      </Flex>
    );
  }

  return (
    <Flex direction="column" overflow="hidden" width="100%">
      <PageTitle title="All Contacts" />

      <AllContactBody
        isEmpty={
          isReady && isNil(searchValue) && isEmpty(labels) && isEmpty(items)
        }>
        <>
          <Flex
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            pt="19px"
            px="16px">
            <Text
              color="main.400"
              fontSize="18px"
              fontWeight={500}
              lineHeight="26px"
              m="2px 0 0 14px">
              Contacts
            </Text>

            <Flex direction="row">
              <ContactVideoButton />
              <SearchField onChange={setSearchValue} />
            </Flex>
          </Flex>
          <AllContactsTopSection
            handleChangePageLimit={handleChangePageLimit}
            pageLimit={pageLimit}
            selectedItems={selectedItems}
          />
          {!selectedItems?.length ? (
            <Flex flexDirection="column">
              <Flex mb="16px" ml="14px" mt="20px" position="relative">
                <SortBy value={sort} onChange={setSort} />
                <FilterByLabel value={labels} onChange={setLabels} />
              </Flex>
              <Collapse animateOpacity in={!isEmpty(labels)}>
                {!isEmpty(labels) && (
                  <Flex ml="23px">
                    <LabelFilterList
                      isMonochrome
                      labels={labels}
                      visibleItemsCount={Infinity}
                      onChange={setLabels}
                    />
                  </Flex>
                )}
              </Collapse>
            </Flex>
          ) : (
            <ContactGroupActions
              selectedContacts={selectedItems}
              onAddToGroup={resetSelected}
              onDelete={removeContacts}
            />
          )}
          {isLoadingData ? (
            <TableSkeleton mt="10px" />
          ) : (
            <TableList
              contactListData={items}
              fetchNextPage={fetchNextPage}
              handleContactClick={handleContactClick}
              handleSelectAll={handleSelectAll}
              hasNextPage={contactsData?.hasNext}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoading}
              params={params}
              removeContactFromList={removeContacts}
              selectedContacts={selectedItems}
              onSelectChange={select}
            />
          )}
        </>
      </AllContactBody>

      {removeNotPossibleModal.isOpen && (
        <RemoveNotPossibleModal
          isOpen={removeNotPossibleModal.isOpen}
          text={
            selectedItems.length
              ? 'You cannot delete these selected contacts because they are part of an active or paused campaign.'
              : 'You cannot delete this contact because it is part of an active or paused campaign.'
          }
          title={selectedItems.length ? 'Delete Contacts' : 'Delete Contact'}
          onClose={removeNotPossibleModal.onClose}
        />
      )}
    </Flex>
  );
};

export default AllContacts;
