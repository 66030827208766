import { Icon } from '@chakra-ui/react';
import { Timestamp } from 'firebase/firestore';
import React, { FC } from 'react';

import CallIncomingBlackIcon from '@app/icons/messenger/call-incoming-black-icon.svg?react';
import CallMissedBlackIcon from '@app/icons/messenger/call-missed-black-icon.svg?react';
import CallOutgoingBlackIcon from '@app/icons/messenger/call-outgoing-black-icon.svg?react';

import {
  ConversationFragment,
  MessageContentType,
  MessageDirection,
  MessageType,
} from '@app/api/gql/generated-types';
import { LastMessageText } from './LastMessageText';

interface LastMessageProps {
  contactItem: ConversationFragment;
  negativeBalanceSince?: Timestamp;
  isInactiveAccount?: boolean;
  isPaymentFailure?: boolean;
}

const messageTexts: Partial<Record<MessageContentType, string>> = {
  [MessageContentType.INCOMING_CALL]: 'Incoming call',
  [MessageContentType.OUTGOING_CALL]: 'Outbound call',
  [MessageContentType.MISSED_CALL]: 'Missed call',
  [MessageContentType.VOICEMAIL]: '🎤 Voice message',
};

const messageIcons: Partial<Record<MessageContentType, JSX.Element>> = {
  [MessageContentType.INCOMING_CALL]: <Icon as={CallIncomingBlackIcon} />,
  [MessageContentType.OUTGOING_CALL]: <Icon as={CallOutgoingBlackIcon} />,
  [MessageContentType.MISSED_CALL]: (
    <Icon as={CallMissedBlackIcon} h="18px" mb="-3px" />
  ),
};

export const LastMessage: FC<LastMessageProps> = ({
  contactItem,
  isInactiveAccount,
  isPaymentFailure,
}) => {
  const isPaymentFailureBlur =
    isPaymentFailure &&
    contactItem.lastMessage?.direction === MessageDirection.INCOMING;

  const hasBlur = isInactiveAccount || isPaymentFailureBlur;

  if (contactItem.lastMessage?.type === MessageType.SMS) {
    return (
      <LastMessageText
        data-id="messenger-message-text-text"
        hasBlur={hasBlur}
        py="2px"
        read={contactItem.isRead}>
        {contactItem?.lastMessage.content}
      </LastMessageText>
    );
  }

  if (
    contactItem.lastMessage?.type === MessageType.MMS &&
    contactItem.lastMessage?.contentType === MessageContentType.MEDIA_IMAGE
  ) {
    return (
      <LastMessageText
        data-id="messenger-message-text-media-image"
        hasBlur={hasBlur}
        py="2px"
        read={contactItem.isRead}>
        🌃 picture
      </LastMessageText>
    );
  }

  if (
    contactItem.lastMessage?.type === MessageType.MMS &&
    contactItem.lastMessage?.contentType === MessageContentType.MEDIA_AUDIO
  ) {
    return (
      <LastMessageText
        data-id="messenger-message-text-media-audio"
        hasBlur={hasBlur}
        py="2px"
        read={contactItem.isRead}>
        🔈 Audio message
      </LastMessageText>
    );
  }

  if (contactItem.lastMessage?.type === MessageType.CALL) {
    const contentType = contactItem.lastMessage?.contentType;

    return (
      <LastMessageText
        data-id={`messenger-message-text-${contentType}`}
        hasBlur={isPaymentFailureBlur}
        read={contactItem.isRead}>
        {messageIcons[contentType]} {messageTexts[contentType]}
      </LastMessageText>
    );
  }

  switch (contactItem.lastMessage?.contentType) {
    case MessageContentType.UNSUPPORTED_FILE:
      return (
        <LastMessageText
          data-id="messenger-message-text-text"
          hasBlur={hasBlur}
          py="2px"
          read={contactItem.isRead}>
          Unsupported file
        </LastMessageText>
      );

    case MessageContentType.VOICEMAIL:
      return (
        <LastMessageText
          data-id="messenger-message-text-voice"
          hasBlur={isPaymentFailureBlur}
          py="2px"
          read={contactItem.isRead}>
          🎤 Voice message
        </LastMessageText>
      );
    case MessageContentType.VOICE:
      return (
        <LastMessageText
          data-id="messenger-message-text-voice"
          hasBlur={isPaymentFailureBlur}
          py="2px"
          read={contactItem.isRead}>
          🎤 Voice message
        </LastMessageText>
      );

    default:
      return (
        <LastMessageText
          read
          color="secondary.400"
          data-id="messenger-no-message"
          py="2px">
          No messages yet
        </LastMessageText>
      );
  }
};
