import { Text } from '@chakra-ui/react';
import React, { FC } from 'react';

import { TooltipCell } from '@app/components/Table/Cells/TooltipCell';
import { cellTextValidation } from '@app/pages/Contacts/utils/cellTextValidation';

interface CellTextTooltipProps {
  text: string;
  textLength?: number;
  width?: string;
}

export const CellTextTooltip: FC<CellTextTooltipProps> = ({
  text,
  textLength = 16,
  width,
}) => {
  const { isTooLong, textCutting } = cellTextValidation(text, textLength);

  return isTooLong ? (
    <TooltipCell message={text}>{textCutting}</TooltipCell>
  ) : (
    <Text
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      width={width}>
      {text}
    </Text>
  );
};
