import { useAuth } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/react';
import { User } from 'firebase/auth';
import { useTracking } from 'react-tracking';
import ROUTES from '@app/utils/routes';
import { unsetPromoCode } from '@app/utils/promoCodeUtils';

export const useSignOut = (): {
  signOut: () => Promise<void>;
  redirectToLogout: () => void;
  currentUser: User | null;
} => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { trackEvent } = useTracking();

  const signOut = async (): Promise<void> => {
    try {
      trackEvent({ event: 'sign_out', user_id: '' });

      unsetPromoCode();

      await auth.signOut();

      window.location.replace(ROUTES.login);
    } catch (error) {
      captureException(error);
    }
  };

  const redirectToLogout = () => {
    navigate(ROUTES.logout);
  };

  return {
    signOut,
    redirectToLogout,
    currentUser: auth.currentUser,
  };
};
