import { Avatar, Box, Flex, Icon, Text } from '@chakra-ui/react';
import React, { FC, MouseEvent } from 'react';

import { useUpdateContactFragment } from '@app/api/hooks/useUpdateContactFragment';
import { useUpdateContactMutation } from '@app/api/mutations/useUpdateContactMutation';
import DateFormat from '@app/components/DateFormat';
import StarIcon from '@app/icons/star-icon.svg?react';
import UnstarIcon from '@app/icons/unstar-icon.svg?react';
import { LastMessage } from '@app/pages/Messenger/components/LastMessage';
import { MessageItemProps } from '@app/pages/Messenger/components/MessageItem/types';
import { UnreadMessageCount } from '@app/pages/Messenger/components/MessageItem/UnreadMessageCount';
import {
  DATE_FORMAT_LL_DD_YYYY,
  TIME_FORMAT_HH_MM_A,
} from '@app/utils/formatDate';
import PhoneNumberFormat from '@app/shared/ui/PhoneNumberFormat';
import { colors } from '@app/theme/colors';

export const MessageItemMobile: FC<MessageItemProps> = ({
  isActive,
  onClick,
  contactItem,
  canUpdateFavorite,
}) => {
  const [mutate] = useUpdateContactMutation();
  const { updateContactFragment } = useUpdateContactFragment();

  const handleSetFavorite = async (event: MouseEvent<HTMLElement>) => {
    if (!canUpdateFavorite) {
      return;
    }
    event.stopPropagation();
    updateContactFragment({
      contactId: contactItem.id,
      favorite: !contactItem.isFavorite,
    });
    await mutate({
      variables: {
        id: contactItem.id,
        input: {
          favorite: !contactItem.isFavorite,
        },
      },
    });
  };

  const fullName = contactItem.name;
  const showMessageCounter = contactItem?.lastMessage && !contactItem.isRead;
  const showMessageCounterEmpty =
    contactItem?.lastMessage &&
    !contactItem.isRead &&
    !contactItem.unreadMessages;

  return (
    <Box
      key={contactItem?.id}
      background={isActive && 'zircon'}
      borderBottom="1px"
      borderBottomColor={colors.mystic}
      borderLeft={isActive && '3px solid switchedBlue.500'}
      cursor="pointer"
      padding="7px 0"
      width="100%"
      onClick={() => onClick(contactItem)}>
      <Flex alignItems="center">
        <Flex
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          ml="15px"
          mr="10px">
          <Box
            data-cy="messenger-avatar-icon"
            id="messenger-avatar-icon"
            m="0 2px 0 -5px"
            position="relative">
            <Avatar name={fullName} />
            {showMessageCounter && (
              <UnreadMessageCount
                count={Number(contactItem.unreadMessages)}
                empty={showMessageCounterEmpty}
                size={15}
                style={{ position: 'absolute', right: '-2px', bottom: '-2px' }}
              />
            )}
          </Box>
        </Flex>

        <Flex alignItems="start" flexDirection="column" width="60%">
          <Flex alignItems="center">
            <Box
              cursor={canUpdateFavorite ? 'pointer' : 'default'}
              ml="2px"
              mr="10px"
              mt="4px"
              onClick={handleSetFavorite}>
              {contactItem?.isFavorite ? (
                <Icon as={StarIcon} />
              ) : (
                <Icon as={UnstarIcon} />
              )}
            </Box>
            <Text
              color="main.400"
              data-cy="messenger-author-name-text"
              fontSize="16px"
              fontWeight={contactItem.isRead ? '500' : '700'}
              id="messenger-author-name-text"
              mr="15px"
              width="165px">
              {fullName}
            </Text>
          </Flex>
          <Box width="100%">
            <Text
              color="main.400"
              data-cy="messenger-author-name-text"
              fontSize="14px"
              fontWeight={contactItem.isRead ? '500' : '700'}
              id="messenger-author-name-text"
              mr="15px"
              mt="-3px"
              width="165px">
              <PhoneNumberFormat value={contactItem.phone} />
            </Text>
          </Box>
          <Box width="100%">
            <LastMessage contactItem={contactItem} />
          </Box>
        </Flex>
        <Flex alignItems="center" direction="column" mr="10px">
          <Text
            color="main.400"
            data-cy="messenger-date-of-message-text"
            fontSize="12px"
            id="messenger-date-of-message-text"
            lineHeight="14px"
            mb="3px">
            <DateFormat
              date={contactItem?.updatedAt && new Date(contactItem?.updatedAt)}
              format={DATE_FORMAT_LL_DD_YYYY}
            />
          </Text>
          <Text
            color="cadetBlue"
            data-cy="messenger-time-of-message-text"
            fontSize="12px"
            id="messenger-time-of-message-text"
            lineHeight="14px">
            <DateFormat
              date={contactItem?.updatedAt && new Date(contactItem?.updatedAt)}
              format={TIME_FORMAT_HH_MM_A}
            />
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
