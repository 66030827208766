import React, { FC } from 'react';
import {
  Button,
  Flex,
  Text,
  Input,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { FormikHelpers, useFormik } from 'formik';
import { object, string } from 'yup';

import Popup from '@app/components/Popup';
import { catchErrorLog } from '@app/utils/logger';
import {
  CreateFieldsTemplateInput,
  FindFieldsTemplatesDocument,
  useCreateFieldsTemplateMutation,
} from '@app/api/gql/generated-types';
import { isSubmitDisabled } from '@app/utils/validation';
import { apolloErrorsMapHelper } from '@app/utils/apolloErrorsMapHelper';
import { ExceptionCode } from '@app/types/ApolloErrors';

const templateErrors = {
  [ExceptionCode.ENTITY_NAME_ERROR]: 'name',
};

interface AddTemplateProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  fields: string[];
  onCreate: (val: string) => void;
}

const validationSchema = object().shape({
  name: string().trim().label('Template Name').min(1).required(''),
});

export const AddTemplateModal: FC<AddTemplateProps> = ({
  isOpen,
  onClose,
  fields,
  onCreate,
}) => {
  const [createMutation, { loading }] = useCreateFieldsTemplateMutation({
    refetchQueries: [
      {
        query: FindFieldsTemplatesDocument,
        fetchPolicy: 'no-cache',
      },
    ],
  });

  const initialValues: CreateFieldsTemplateInput = {
    name: '',
    fields: [],
  };

  const onSubmit = async (
    values: CreateFieldsTemplateInput,
    helpers: FormikHelpers<CreateFieldsTemplateInput>,
  ) => {
    try {
      const { data: { createFieldsTemplate } = {} } = await createMutation({
        variables: {
          input: {
            fields,
            name: values.name?.trim(),
          },
        },
        context: {
          notify: {
            success: () => 'Successfully created new template',
            error: () => 'Error creating template for headers',
          },
        },
        onError(error) {
          const errorsMap = apolloErrorsMapHelper(
            error.graphQLErrors,
            templateErrors,
          );
          helpers.setErrors(errorsMap);
        },
      });
      onCreate(createFieldsTemplate?.id);
      onClose();
    } catch (error) {
      catchErrorLog(error, 'AddTemplate/onSubmit');
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    validationSchema,
    onSubmit,
  });

  const isDisabled = isSubmitDisabled(formik, false, []);

  const { values, errors, handleChange, handleSubmit } = formik;

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="350px"
      size="3xl"
      title="Add new template"
      onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Flex alignItems="center" direction="column">
          <Text
            color="main.400"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="18px"
            mt="25px"
            textAlign="center"
            width="270px">
            The templates for reuse columns names with one click whenever
            needed.
          </Text>

          <FormControl
            isInvalid={!!errors.name}
            maxWidth="270px"
            mb="20px"
            mt="20px"
            width="100%">
            <Input
              height="40px"
              name="name"
              placeholder="Type something memorable"
              value={values.name}
              variant="primary"
              onChange={handleChange}
            />
            <FormErrorMessage>{errors.name}</FormErrorMessage>
          </FormControl>
          <Button
            isDisabled={isDisabled}
            isLoading={loading}
            loadingText="Create header template"
            mb="40px"
            type="submit"
            variant="primary"
            width="270px">
            Create header template
          </Button>
        </Flex>
      </form>
    </Popup>
  );
};
