import React, { FC } from 'react';
import { Box, Icon, Text } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import CrossIcon from '@app/icons/CrossIcon.svg?react';
import { PopupConstructor } from '@app/pages/PopupConstructor/types';
import { PreviewBody } from '@app/pages/PopupConstructor/components/PreviewBody';

const DEFAULT_TEXT = 'Title';

export const Preview: FC = () => {
  const {
    values: {
      template: { type },
      title,
    },
  } = useFormikContext<PopupConstructor>();

  if (!type) {
    return null;
  }

  return (
    <Box>
      <Box
        borderRadius="20px"
        boxShadow="0px 8px 44px rgba(66, 66, 66, 0.16)"
        mr="60px"
        mt="75px"
        p="40px"
        position="relative"
        width="590px">
        <Box position="absolute" right="20px" top="20px">
          <Icon as={CrossIcon} height="8px" width="8px" />
        </Box>
        <Text pb="20px" textAlign="center" variant="tertiary-title" w="100%">
          {!!title ? title : DEFAULT_TEXT}
        </Text>
        <PreviewBody />
      </Box>
    </Box>
  );
};
