import React, { FC } from 'react';
import {
  Flex,
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

import ROUTES from '@app/utils/routes';
import { NavLink, LinkSubRoutes } from '@app/navigation/styles';
import ArrowDownIcon from '@app/icons/arrow-down.svg?react';
import { colors } from '@app/theme/colors';

interface MenuDropdownProps {
  isDisabled?: boolean;
}

export const MenuDropdown: FC<MenuDropdownProps> = ({ isDisabled = false }) => {
  const location = useLocation();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const isMenuActive = () =>
    location.pathname.includes(ROUTES.affiliates) ||
    location.pathname.includes(ROUTES.popupConstructor);

  return (
    <Popover
      closeOnBlur
      matchWidth
      isOpen={isOpen}
      offset={[28, 8]}
      trigger="hover"
      onClose={onClose}
      onOpen={!isDisabled && onOpen}>
      <PopoverTrigger>
        <Flex position="relative" textAlign="center" w="90px">
          <NavLink
            className={isMenuActive() ? 'active' : ''}
            to="/#"
            onClick={(e) => e.preventDefault()}>
            <Box title="Marketing">Marketing</Box>
          </NavLink>
          <IconButton
            _active={{ bg: colors.transparent }}
            _focus={{ outline: 'none' }}
            _hover={{ bg: colors.transparent }}
            aria-label="Arrow Icon"
            bg={colors.transparent}
            color="secondary.400"
            icon={<ArrowDownIcon height="16px" width="16px" />}
            mt="1px"
            position="absolute"
            right="-12px"
            size="xs"
            transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
            transition="all .3s ease"
          />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        _focus={{ outline: '0 none' }}
        backgroundColor="white"
        border={`1px solid ${colors.secondary[200]}`}
        borderRadius="20px"
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.08)"
        width="120px"
        zIndex="10000000">
        <Flex direction="column" m="10px 8px">
          <LinkSubRoutes to="affiliates">Affiliates</LinkSubRoutes>
          <LinkSubRoutes to="popup-constructor">Popups</LinkSubRoutes>
        </Flex>
      </PopoverContent>
    </Popover>
  );
};
