import { useContext } from 'react';

import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';

import { usePopupsContext } from './usePopupsContext';

/**
 *  @deprecated Use the new permission Features & permission modal. .waiting for new modal designs from design team
 * */
const useActivateMessageSubscriptionGuard = (
  handler: (...args: any) => void | Promise<void>,
) => {
  const {
    isActiveWithActiveMessagePlan,
    isActiveWithInactiveMessagePlan,
    isInactiveWithInactiveMessagePlan,
    isMessagePlanCancelled,
  } = useContext(CurrentAccountContext);

  const {
    activateAccountModal: { onOpen },
    activateMessagingSubscriptionModal: {
      onOpen: onOpenMessageSubscriptionModal,
    },
  } = usePopupsContext();

  if (
    isActiveWithInactiveMessagePlan ||
    isInactiveWithInactiveMessagePlan ||
    isMessagePlanCancelled
  ) {
    return onOpenMessageSubscriptionModal;
  }

  if (isActiveWithActiveMessagePlan) {
    return handler;
  }

  return onOpen;
};

export default useActivateMessageSubscriptionGuard;
