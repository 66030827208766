import { PermissionActions, Permissions } from '@app/acl';
import ROUTES from '@app/utils/routes';
import { usePermissionAbility } from './usePermissionAbility';

export const useBrandRegistrationRoute = () => {
  const { can: canSubmitBrandRegistration } = usePermissionAbility(
    PermissionActions.CREATE,
    Permissions.BrandRegistration,
  );
  let route = `${ROUTES.settings10DlcRegistration}`;
  if (canSubmitBrandRegistration) {
    route = `${ROUTES.settingsBrandRegistration}`;
  }

  return {
    route,
  };
};
