import React, { FC } from 'react';
import { Box, ButtonProps, IconButton } from '@chakra-ui/react';

import BucketIcon from '@app/icons/bucket-icon.svg?react';

const IconButtonRemove: FC<ButtonProps> = ({ ...props }) => {
  return (
    <Box position="relative">
      {props.isDisabled && (
        <Box
          backgroundColor="primary.200"
          borderRadius="12px"
          cursor="not-allowed"
          h="24px"
          opacity="0.8"
          position="absolute"
          right="0"
          w="24px"
        />
      )}
      <IconButton
        isRound
        aria-label="remove"
        size="xs"
        variant="iconButton"
        {...props}>
        <BucketIcon />
      </IconButton>
    </Box>
  );
};

export default IconButtonRemove;
