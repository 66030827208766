import React from 'react';
import { Box } from '@chakra-ui/react';

interface IVerticalResizerLineProps {
  handleMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
  style?: React.CSSProperties;
}

export const VerticalResizerLine: React.FC<IVerticalResizerLineProps> = ({
  handleMouseDown,
  style,
}) => (
  <Box
    _hover={{
      opacity: 1,
    }}
    backgroundColor="primary.400"
    cursor="ew-resize"
    height="100vh"
    left="-1px"
    opacity={0}
    position="absolute"
    style={{ ...style }}
    transition="opacity 0.3s ease-in-out"
    width="2px"
    zIndex={2}
    onMouseDown={handleMouseDown}
  />
);
