import { countSmsSegments } from '@smarter-contact-team/messages';
import { addMethod, string as yupString, StringSchema } from 'yup';
import { DEFAULT_MAX_SMS_SEGMENTS } from '@app/utils/smsUtils';

// Helpful pointers: https://github.com/jquense/yup/issues/312

export interface MaxSmsSegmentsOptions {
  /** Default value: 2 */
  maxSegments?: number;

  /**
   * Default value: "Message may not exceed {maxSegments} SMS segments"
   *
   * Placeholder: `{maxSegments}`
   */
  message?: string;
}

function testSmsSegments(this: StringSchema, opts?: MaxSmsSegmentsOptions) {
  const maxSegments = opts?.maxSegments ?? DEFAULT_MAX_SMS_SEGMENTS;
  const messageTemplate =
    opts?.message ?? 'Message may not exceed {maxSegments} SMS segments';

  const message = messageTemplate.replaceAll(
    '{maxSegments}',
    maxSegments.toString(),
  );

  return this.test('maxSmsSegments', message, (val, ctx) =>
    countSmsSegments(val) > maxSegments ? ctx.createError({ message }) : true,
  );
}

// Avoid TS problems -- declare the validation method.
declare module 'yup' {
  interface StringSchema {
    /**
     * Ensure the string is limited to a number of SMS segments.
     */
    maxSmsSegments(opts?: MaxSmsSegmentsOptions): StringSchema;
  }
}

export function registerMaxSmsSegmentsValidator() {
  addMethod(yupString, 'maxSmsSegments', testSmsSegments);
}
