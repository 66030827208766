import ToastNotify from '@app/components/ToastNotifier';
const parseFileNameFromUrl = (url: string) => {
  let fileName = '';
  try {
    fileName = window.decodeURIComponent(url).split('/').pop();

    if (fileName) {
      const fileNameWithoutQueryParams = fileName.split('?')[0];
      return fileNameWithoutQueryParams;
    }
  } catch (error) {
    console.error('🚀 ~ parseFileNameFromUrl ~ error', error);
  }

  return fileName;
};

export const downloadFileFromUrl = (url: string, fileName?: string) => {
  const link = document.createElement('a');

  if (link.download !== undefined) {
    let downloadFileName = fileName;

    if (!downloadFileName) {
      downloadFileName = parseFileNameFromUrl(url);
    }

    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', downloadFileName);

    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    ToastNotify({
      status: 'error',
      position: 'top-right',
      title: 'Error exporting file',
    });
  }
};
