import React, { FC } from 'react';

import { formatNumber } from './utils';

interface FormatNumberProps {
  number: number;
  locale?: string;
  fractionDigits?: number;
  maxFractionDigits?: number;
  cutFraction?: boolean;
  preventNegative?: boolean;
}

export const FormatNumber: FC<FormatNumberProps> = ({
  number,
  locale = 'en-US',
  fractionDigits,
  preventNegative,
  cutFraction = false,
  maxFractionDigits,
}) => {
  return (
    <>
      {formatNumber({
        value: number,
        locale,
        fractionDigits,
        maxFractionDigits,
        preventNegative,
        cutFraction,
      })}
    </>
  );
};
