import React, { FC } from 'react';
import { Button, Flex, useDisclosure } from '@chakra-ui/react';

import { ApolloError } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { downloadFile } from '@app/pages/Skiptrace/utils/downloadFile';
import UploadIcon from '@app/icons/upload-icon.svg?react';

import {
  PushToGroupSkiptraceType,
  Skiptrace,
  useExportSkiptraceLazyQuery,
  usePushSkiptraceToGroupMutation,
  ExportSkiptraceType,
} from '@app/api/gql/generated-types';
import ROUTES, { generateUrl } from '@app/utils/routes';
import { PermissionButton } from '@app/components/next/atoms/PermissionsComponents/WithPermission';
import { PermissionActions, PermissionFeatures } from '@app/acl';
import UploadModal from '../Modal/UploadModal';
import ExportModal from '../Modal/ExportModal';

interface ResultActionsProps {
  contactListId: string;
  skiptraceData: Skiptrace;
  onClearState: () => void;
}

export const ResultActions: FC<ResultActionsProps> = ({
  contactListId,
  skiptraceData,
  onClearState,
}) => {
  const exportResultModal = useDisclosure();
  const pushToListModal = useDisclosure();
  const uploadModalProps = useDisclosure();
  const navigate = useNavigate();

  const [pushSkiptraceToGroupMutation, { loading: isLoadingPushToGroup }] =
    usePushSkiptraceToGroupMutation();

  const [mutation, { loading: isLoadingExport }] = useExportSkiptraceLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const onExportSkiptracingResults = async (
    exportType: ExportSkiptraceType,
  ) => {
    const { data } = await mutation({
      variables: {
        input: {
          id: contactListId,
          exportType,
        },
      },
    });
    downloadFile(skiptraceData?.fileName, data?.exportSkiptrace?.signedUrl);
    exportResultModal.onClose();
  };

  const onPushToListsFromServer = async (type: PushToGroupSkiptraceType) => {
    const { data } = await pushSkiptraceToGroupMutation({
      variables: {
        input: {
          id: contactListId,
          type,
        },
      },
      context: {
        notify: {
          success: () => 'The data was successfully updated',
          error: (error: ApolloError[]) => error[0]?.message,
        },
      },
    });
    pushToListModal.onClose();
    navigate(
      generateUrl(ROUTES.contactEdit, {
        contactId: data?.pushSkiptraceToGroup?.id,
      }),
    );
  };

  return (
    <>
      <Flex pl="30px">
        <PermissionButton
          action={PermissionActions.CREATE}
          mr="20px"
          subject={PermissionFeatures.skiptrace.pushToContacts}
          variant="primary"
          width="150px"
          onSuccess={pushToListModal.onOpen}>
          Push to contacts
        </PermissionButton>
        <Button
          leftIcon={<UploadIcon />}
          mr="20px"
          variant="outlined"
          width="125px"
          onClick={exportResultModal.onOpen}>
          Export
        </Button>
        <Button
          variant="outlined"
          width="145px"
          onClick={uploadModalProps.onOpen}>
          Upload new file
        </Button>
      </Flex>

      <ExportModal
        isDownloadExport
        confirm={onExportSkiptracingResults}
        disableCancel={true}
        isLoading={isLoadingExport}
        isOpen={exportResultModal.isOpen}
        skiptraceData={skiptraceData}
        title="Export"
        onClose={exportResultModal.onClose}
      />
      <ExportModal
        confirm={onPushToListsFromServer}
        disableCancel={true}
        hideAllOption={true}
        isLoading={isLoadingPushToGroup}
        isOpen={pushToListModal.isOpen}
        skiptraceData={skiptraceData}
        title="Push to contacts"
        onClose={pushToListModal.onClose}
      />

      <UploadModal
        isOpen={uploadModalProps.isOpen}
        title="Upload file"
        onClearState={onClearState}
        onClose={uploadModalProps.onClose}
      />
    </>
  );
};
