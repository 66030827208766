import { Box } from '@chakra-ui/react';
import React, { FC, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useLabelsQueryData } from '@app/api/queries/useLabelsQueryData';
import MultiSelectField from '@app/components/MultiSelectField';
import { SelectedLabel } from '../../../../types/Export';

interface ExportMultiselectLabelProps {
  onChange: (items: SelectedLabel[]) => void;
}

export const ExportMultiselectLabel: FC<ExportMultiselectLabelProps> = ({
  onChange,
}) => {
  const {
    data: labelsOption = [],
    isLoading,
    total,
    fetchNextPage,
  } = useLabelsQueryData();

  const [labelsState, setLabelsState] = useState<SelectedLabel[]>([]);

  const options: SelectedLabel[] = useMemo(() => {
    const selectedLabels = labelsState || [];
    const labels = labelsOption || [];

    if (labels?.length < 1) {
      return [];
    }

    // @ts-expect-error missing "selected" fields
    const filteredLabels: SelectedLabel[] = labels.filter(
      ({ id }) => !selectedLabels?.map((val) => val.id)?.includes(id),
    );
    //.map((label) => ({ ...label, label: label.title, value: label.id }))

    return [
      ...(selectedLabels || []).map((label) => ({
        ...label,
        isChecked: true,
      })),
      ...filteredLabels,
    ];
  }, [labelsState, labelsOption]);

  const handleChange = (newValue: readonly SelectedLabel[]) => {
    const values: SelectedLabel[] = cloneDeep([...newValue]);
    setLabelsState(values);
    onChange(values);
  };

  return (
    <Box maxWidth="300px" width="100%">
      <MultiSelectField
        isMulti
        autoFocus={false}
        checkedItemSize={total}
        closeMenuOnSelect={false}
        getOptionLabel={(option) => option.title}
        getOptionValue={(option) => option.id}
        hideSelectedOptions={false}
        isClearable={false}
        isLoading={isLoading}
        isSearchable={false}
        itemsLength={1}
        maxWidth="75%"
        options={options}
        placeholder="Select"
        value={labelsState}
        onChange={handleChange}
        onMenuScrollToBottom={fetchNextPage}
      />
    </Box>
  );
};
