import { isEmpty } from 'lodash/fp';

import { wordPluralize } from '@app/utils/string';
import { isValidUrl } from '@app/utils/isUrl';

/**
 * Regular expression pattern for validating alphanumeric characters.
 * The pattern ensures that a string contains only letters (both uppercase and lowercase)
 * and numbers (0-9), with at least one character.
 */
export const alphanumericValidationPattern = /^[a-zA-Z0-9]+$/;

/**
 * Regular expression for validating strings containing alphabets, spaces,
 * hyphens, and apostrophes, starting with an uppercase letter.
 * The pattern ensures the string starts with an uppercase letter and allows
 * spaces, apostrophes, and hyphens between alphabetic characters.
 *
 * @type {RegExp}
 * @example
 * // Returns true
 * nameRegex.test("John Doe");
 * @example
 * // Returns false
 * nameRegex.test("john doe!");
 */
export const nameRegex = /^[A-Z][a-zA-Z' -]*$/;

/**
 * Regular expression that matches strings containing only alphabetic characters.
 * @type {RegExp}
 * @example
 * // Returns true
 * onlyAlphabetRegex.test('Hello');
 * @example
 * // Returns false
 * onlyAlphabetRegex.test('Hello123');
 */
export const onlyAlphabetRegex: RegExp =
  /^([A-Za-z]*([.,]|[-'])?)+[A-Za-z]+\.?\s*$/;

/**
 * Regular expression that matches strings containing only alphabetic characters and spaces.
 * @type {RegExp}
 * @example
 * // Returns true
 * onlyAlphabetWithSpacesRegex.test('Hello World');
 * @example
 * // Returns false
 * onlyAlphabetWithSpacesRegex.test('Hello123');
 */
export const onlyAlphabetWithSpacesRegex: RegExp =
  /^([A-Za-z]*([.,]|[-'\s])?)+[A-Za-z]+\.?$/;

//https://smartercontact.atlassian.net/wiki/spaces/SC/pages/83525646/Macros+template
/**
 * Regular expression that matches strings containing only alphabetic characters and spaces.
 *
 * @type {RegExp}
 * @example
 * // Returns true
 * onlyAlphabetWithSpacesRegex.test('Hello World');
 * @example
 *  const validTitle = "Title123"; // Valid string
 *  const specialTitle = "Special title with symbols: ?#@%^*-',."; // Invalid string constains :
 *  const invalidTitle = "Invalid Title ?,"; // Valid string
 *  const emtpyTitle = "     " // valid string
 */
export const macrosTitleSpecialCharactersSupportedRegex: RegExp =
  /^(?!.* {?,})[a-zA-Z0-9- #@%^*.,?']*$/;

//https://smartercontact.atlassian.net/wiki/spaces/SC/pages/83525646/Macros+template
/**
 * Regular expression pattern to validate titles allowing specific special characters.
 * This regex pattern is designed to match titles containing alphanumeric characters,
 * spaces, hyphens, and certain symbols commonly found in titles.
 *
 * The regex pattern disallows strings that start or end with whitespace,
 * and prohibits sequences containing space, comma, and question mark in succession.
 *
 * @constant {RegExp} stdTitleSpecialCharactersSupportedRegex
 * @example
 *  const validTitle = "Title123"; // Valid string
 *  const specialTitle = "Special title with symbols: #@%^*-,."; // Invalid string constains :
 *  const invalidTitle = "Invalid Title *,"; // Valid string
 */
export const stdTitleSpecialCharactersSupportedRegex: RegExp =
  /^(?!.* {?,})(?=\S)(?=.*\S$)[a-zA-Z0-9- #@%^*.,?']*$/;

/**
 * Constant defining special characters supported in a standard title.
 * @type {string}
 */
export const stdTitleSpecialCharactersSupported = "?#@%^*-',.";

/**
 * Constant defining the minimum length for standard input text.
 * @type {number}
 */
export const STD_INPUT_TEXT_MIN_LENGTH = 1;

/**
 * Constant defining the maximum length for a standard title.
 * @type {number}
 */
export const STD_TITLE_MAX_LENGTH = 35;

/**
 * Constant defining the maximum length for a standard keyword.
 * @type {number}
 */
export const STD_KEYWORD_MAX_LENGTH = 35;

/**
 * Constant defining the maximum length for standard input.
 * @type {number}
 */
export const STD_INPUT_MAX_LENGTH = 160;

/**
 * Constant defining the normal length for a standard title.
 * @type {number}
 */
export const STD_TITLE_NORMAL_LENGTH = 160;

/**
 * Constant defining the maximum length for macros input.
 * @type {number}
 */
export const MACROS_MAX_LENGTH = 320;

/**
 * Validates the template message content by checking if it contains any URLs.
 * @param {string} content - The content of the template message.
 * @returns {boolean} - Returns true if the content is empty or does not contain URLs, false otherwise.
 */
export const validateTemplateMessage = (content: string): boolean => {
  return isEmpty(content?.split(/\s+/g).filter(isValidUrl));
};

/**
 * Message indicating the special characters supported for a standard input title.
 * @type {string}
 */
export const stdTitleSpecialCharactersSupportedMessage: string = `Special characters supported: ${stdTitleSpecialCharactersSupported}`;

/**
 * A constant string representing a validation message for alphanumeric characters.
 * It is used to indicate that a value or input should contain only alphanumeric characters.
 * Alphanumeric characters include letters (A-Z, a-z) and numbers (0-9).
 */
export const alphanumericMessage: string = 'Alphanumeric characters only';

/**
 * Generates a message specifying the maximum length for an entity.
 *
 * @param {string} entityName - The name of the entity.
 * @param {number} [maxCharactersCount=stdInputMaxLength] - The maximum number of characters allowed.
 * @returns {string} A message indicating the maximum character limit for the entity.
 */
export const stdMaxLengthMessage = (
  entityName: string,
  maxCharactersCount: number = STD_INPUT_MAX_LENGTH,
): string =>
  `${entityName} must be at most ${maxCharactersCount} ${wordPluralize(
    maxCharactersCount,
    'character',
  )}`;

/**
 * Generates a message indicating the minimum length for a standard title.
 * @param {number} minCharactersCount - The minimum number of characters allowed for a title.
 * @returns {string} - Returns the generated message indicating the minimum length for a title.
 */
export const stdTitleMinLengthMessage = (
  minCharactersCount: number = STD_INPUT_TEXT_MIN_LENGTH,
): string =>
  `Name must be at least ${minCharactersCount} ${wordPluralize(
    minCharactersCount,
    'character',
  )}`;

/**
 * Generates a message indicating the maximum length for a standard input message.
 * @param {number} maxCharactersCount - The maximum number of characters allowed for a message.
 * @returns {string} - Returns the generated message indicating the maximum length for a message.
 */
export const stdMaxMessageLengthMessage = (
  maxCharactersCount: number = STD_INPUT_MAX_LENGTH,
): string => stdMaxLengthMessage('Message', maxCharactersCount);

/**
 * Generates a message indicating the maximum length for a standard title.
 * @param {number} maxCharactersCount - The maximum number of characters allowed for a title.
 * @returns {string} - Returns the generated message indicating the maximum length for a title.
 */
export const stdTitleMaxLengthMessage = (
  maxCharactersCount: number = STD_TITLE_MAX_LENGTH,
): string => stdMaxLengthMessage('Name', maxCharactersCount);
