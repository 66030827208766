import Switch from './switch';
import Input from './input';
import { Button } from './button';
import Avatar from './avatar';
import Select from './select';
import { Text } from './text';
import Table from './table';
import Checkbox from './checkbox';
import { Badge } from './badge';
import Textarea from './textarea';
import { FormError } from './formControl';
import { Popover } from './popover';
import { Link } from './link';
import { Modal } from './modal';
import { AudioPlayer } from './audioPlayer';
import { Radio } from './radio';
import { Tooltip } from './tooltip';
import { Progress } from './progress';
import { NumberInput } from './numberInput';
import { Tabs } from './tabs';
import { CloseButton } from './closeButton';

export const components = {
  Switch,
  Input,
  Button,
  Avatar,
  Select,
  Text,
  Table,
  Checkbox,
  Badge,
  Textarea,
  Popover,
  Link,
  FormError,
  Modal,
  Radio,
  AudioPlayer,
  Tooltip,
  Progress,
  Tabs,
  NumberInput,
  CloseButton,
};
