import { ApolloError } from '@apollo/client';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React from 'react';

import { Features } from '@app/acl';
import {
  useAccountQuery,
  useUpdateMessagingProfileMutation,
} from '@app/api/gql/generated-types';
import {
  PhoneNumberInput,
  preparingForSendingPhoneNumber,
} from '@app/components/PhoneNumberInput';
import { useDisableFeatureAbility } from '@app/hooks/useDisableFeatureAbility';
import { incomingPhoneSchema } from '@app/schemas/incoming-phone-schema';
import { formatPhone } from '@app/utils/formatPhone';
import { useUpdateMessagingProfileFragment } from '@app/api/hooks/useUpdateMessagingProfileFragment';
import { SettingsLayout } from '@app/layouts/SettingsLayout';
import Tooltip from './Tooltip';

export const ActiveIncoming = () => {
  const { data: { account } = {} } = useAccountQuery();
  const { messagingProfile } = account;
  const { updatePhoneSettingsFragment } = useUpdateMessagingProfileFragment();

  const [updateMessagingProfile] = useUpdateMessagingProfileMutation();

  const { can: canEditActiveIncoming } = useDisableFeatureAbility(
    Features.ActiveIncoming,
  );

  const handleBlurReceivePhoneNumber = async ({ phone }: { phone: string }) => {
    const activeIn = phone ? preparingForSendingPhoneNumber(phone) : null;

    if (activeIn === messagingProfile?.phoneSettings?.activeIn) {
      return;
    }
    updatePhoneSettingsFragment({
      id: messagingProfile?.id,
      phoneSettings: {
        activeIn,
      },
    });
    await updateMessagingProfile({
      variables: {
        input: {
          phoneSettings: {
            activeIn,
          },
        },
      },
      context: {
        notify: {
          success: () => 'The phone has been successfully changed',
          error: (error: ApolloError[]) => error?.[0]?.message,
        },
      },
    });
  };

  const formikReceivePhone = useFormik({
    initialValues: {
      phone: formatPhone(messagingProfile?.phoneSettings?.activeIn) ?? '',
    },
    validateOnChange: true,
    validationSchema: incomingPhoneSchema,
    onSubmit: handleBlurReceivePhoneNumber,
    enableReinitialize: true,
  });

  return (
    <SettingsLayout
      alignItems="baseline"
      childComponent={
        <form onSubmit={formikReceivePhone.handleSubmit}>
          <FormControl isInvalid={!!formikReceivePhone.errors.phone}>
            <PhoneNumberInput
              data-cy="phone-number-setup-input"
              hasError={!!formikReceivePhone.errors.phone}
              id="phone-number-setup"
              isDisabled={!canEditActiveIncoming}
              mb={formikReceivePhone.errors.phone ? '0rem' : '1rem'}
              name="phone"
              placeholder="Phone number"
              value={formikReceivePhone.values.phone}
              variant="primary"
              w="230px"
              onBlur={() => formikReceivePhone.handleSubmit()}
              onChange={formikReceivePhone.handleChange}
            />
            {!!formikReceivePhone.errors.phone && (
              <FormErrorMessage>
                {formikReceivePhone.errors.phone}
              </FormErrorMessage>
            )}
          </FormControl>
        </form>
      }
      iconComponent={
        <Tooltip
          text="This is your number receiving mobile text alerts and redirected calls"
          widthContainer="255px"
        />
      }
      marginTop="30px"
      titleLabel="Active incoming &#35;"
    />
  );
};
