import { Box, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { showHubSpotConversationAgent } from '@app/utils/hubSpotConversationUtils';
import { DisclaimerBox } from '../next/moleculas/DisclaimerBox';

export const ContactSupportCallToAction: FC = () => {
  return (
    <DisclaimerBox
      style={{
        marginTop: '20px',
        gap: '12px',
      }}>
      <Text color="main.400" fontSize="14px" fontWeight="400">
        Need more numbers or a different area code?{' '}
        <Box
          as="span"
          color="primary.600"
          style={{ cursor: 'pointer' }}
          onClick={showHubSpotConversationAgent}>
          Contact support
        </Box>{' '}
        to order them.
      </Text>
    </DisclaimerBox>
  );
};
