import Cookies from 'js-cookie';

export function initFP() {
  const params = new URLSearchParams(window.location.search);

  const fpr = params.get('fpr');

  if (fpr) {
    Cookies.set('__refer', fpr, { domain: 'smartercontact.com' });
  }
}
