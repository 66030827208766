import { AbilityBuilder, defineAbility, PureAbility } from '@casl/ability';
import { PlanPermissionType } from '@app/hooks/usePlanPermission';

import {
  Account,
  AccountStatus,
  AccountType,
} from '@app/api/gql/generated-types';
import {
  PermissionModalPropsBrandRegistration,
  PermissionModalPropsCallingPlanActivate,
  PermissionModalPropsMessagingPlanActivate,
  PermissionModalPropsSelectYourPlan,
  PermissionModalPropsSendMessagePhoneNumber,
} from '@app/components/next/organisms/CustomModal';
import {
  PermissionFeatures,
  cannotWithModal,
  conditionsMatcher,
  FeatureActions,
  Features,
  FeaturesAbilityType,
  FeaturesBrandingType,
  PermissionActions,
  Permissions,
  PermissionsAbilityType,
  SCAbility,
} from './types';

export * from './types';

export function defineFeatureRules(
  { isSubAccount, status }: Account,
  {
    isAdvanced,
    isMiddleAdvanced,
    isNewPro,
    isOldElite,
    isAllNewPlans,
    isElite,
    isNewStarter,
    isBasic,
    isDialerPlan,
  }: PlanPermissionType,
  { isApproved10Dlc, isTollFree }: FeaturesBrandingType,
) {
  return defineAbility<FeaturesAbilityType>((can, cannot) => {
    can(FeatureActions.ENABLED, Features.Dialer);
    can(FeatureActions.ENABLED, Features.ShowDialer);
    // there is not a cannot for this one. let's remove it, we need to test it fi
    can(FeatureActions.ENABLED, Features.CampaignRules);
    can(FeatureActions.ENABLED, Features.CampaignRulesActive);
    can(FeatureActions.ENABLED, Features.ShowSubAccounts);
    can(FeatureActions.ENABLED, Features.ActiveIncoming);
    can(FeatureActions.ENABLED, Features.IncomingCalls);
    can(FeatureActions.ENABLED, Features.CampaignThrottlingActive);
    can(FeatureActions.ENABLED, Features.CampaignPhoneNumber);
    can(FeatureActions.ENABLED, Features.CampaignSchedulingActive);
    can(FeatureActions.ENABLED, Features.MessageSchedule);
    cannot(FeatureActions.ENABLED, Features.MobileTextAlerts);
    cannot(FeatureActions.ENABLED, Features.ShowMobileTextAlerts);
    cannot(FeatureActions.ENABLED, Features.APIIntegration);
    cannot(FeatureActions.ENABLED, Features.DripCampaigns);
    cannot(FeatureActions.ENABLED, Features.CallAutoReply);
    cannot(FeatureActions.ENABLED, Features.Voicemail);

    if (isAdvanced && (isApproved10Dlc || isTollFree)) {
      can(FeatureActions.ENABLED, Features.RVM);
    }

    if (isAdvanced) {
      can(FeatureActions.ENABLED, Features.TextSuppressions);
      can(FeatureActions.ENABLED, Features.CampaignThrottling);
      can(FeatureActions.ENABLED, Features.CampaignScheduling);
    }

    if (isMiddleAdvanced) {
      can(FeatureActions.ENABLED, Features.DripCampaigns);
      can(FeatureActions.ENABLED, Features.APIIntegration);
    }

    if (isAllNewPlans || isOldElite || isDialerPlan) {
      can(FeatureActions.ENABLED, Features.FreeLookup);
    }

    if (isNewStarter || isNewPro) {
      can(FeatureActions.ENABLED, Features.CallAutoReply);
      can(FeatureActions.ENABLED, Features.Voicemail);
    }

    if (isElite) {
      can(FeatureActions.ENABLED, Features.SubAccounts);
      can(FeatureActions.ENABLED, Features.MobileTextAlerts);
      can(FeatureActions.ENABLED, Features.ShowMobileTextAlerts);
      can(FeatureActions.ENABLED, Features.CallAutoReply);
      can(FeatureActions.ENABLED, Features.Voicemail);
    }

    if (status === AccountStatus.INACTIVE) {
      cannot(FeatureActions.ENABLED, Features.Dialer);
    }

    if (isBasic) {
      cannot(FeatureActions.ENABLED, Features.Dialer);
      cannot(FeatureActions.ENABLED, Features.IncomingCalls);
    }

    if (status === AccountStatus.TRIAL) {
      cannot(FeatureActions.ENABLED, Features.SubAccounts);
      cannot(FeatureActions.ENABLED, Features.MobileTextAlerts);
      cannot(FeatureActions.ENABLED, Features.CallAutoReply);
      cannot(FeatureActions.ENABLED, Features.Voicemail);
      cannot(FeatureActions.ENABLED, Features.ActiveIncoming);
      cannot(FeatureActions.ENABLED, Features.IncomingCalls);
      cannot(FeatureActions.ENABLED, Features.DripCampaigns);
      cannot(FeatureActions.ENABLED, Features.CampaignSchedulingActive);
      cannot(FeatureActions.ENABLED, Features.CampaignThrottlingActive);
      cannot(FeatureActions.ENABLED, Features.CampaignPhoneNumber);
      cannot(FeatureActions.ENABLED, Features.CampaignRulesActive);
      cannot(FeatureActions.ENABLED, Features.ShowDialer);
      cannot(FeatureActions.ENABLED, Features.MessageSchedule);
    }

    if (isDialerPlan) {
      can(FeatureActions.ENABLED, Features.SubAccounts);
      can(FeatureActions.ENABLED, Features.CallAutoReply);
      can(FeatureActions.ENABLED, Features.Voicemail);
    }

    if (isSubAccount) {
      cannot(FeatureActions.ENABLED, Features.SubAccounts);
      cannot(FeatureActions.ENABLED, Features.ShowSubAccounts);
    }
  });
}

export function definePermissionRules({ type, status, isSubAccount }: Account) {
  return defineAbility<PermissionsAbilityType>((can, cannot) => {
    cannot(PermissionActions.CREATE, Permissions.KeywordCampaign);
    cannot(PermissionActions.CREATE, Permissions.BrandRegistration);
    if (status !== AccountStatus.ABANDONED) {
      can(PermissionActions.CREATE, Permissions.Skiptrace);
      can(PermissionActions.INTERACTION, Permissions.Membership);
      can(PermissionActions.CREATE, Permissions.Template);
      can(PermissionActions.UPDATE, Permissions.Template);
      can(PermissionActions.DELETE, Permissions.Template);
      can(PermissionActions.UPDATE, Permissions.KeywordCampaign);
      can(PermissionActions.INTERACTION, Permissions.WelcomePopup);
    }
    if (status === AccountStatus.ACTIVE) {
      can(PermissionActions.CREATE, Permissions.BrandRegistration);
      can(PermissionActions.INTERACTION, Permissions.WelcomePopup);
      can(PermissionActions.CREATE, Permissions.KeywordCampaign);
    }
    if (status === AccountStatus.ACTIVE || status === AccountStatus.TRIAL) {
      can(PermissionActions.CREATE, Permissions.Campaign);
      can(PermissionActions.CREATE, Permissions.ReplyMessenger);
      can(PermissionActions.CREATE, Permissions.Conversation);

      can(PermissionActions.BUY, Permissions.PhoneNumber);

      can(PermissionActions.UPDATE, Permissions.Label);
      can(PermissionActions.UPDATE, Permissions.Favorite);
    }
    if (status === AccountStatus.TRIAL) {
      cannot(PermissionActions.CREATE, Permissions.Template);
      cannot(PermissionActions.DELETE, Permissions.Template);
      if (type === AccountType.REAL_ESTATE) {
        cannot(PermissionActions.UPDATE, Permissions.Template);
      }
    }
    if (isSubAccount) {
      if (status === AccountStatus.ACTIVE) {
        can(PermissionActions.CREATE, Permissions.Dlc);
        can(PermissionActions.CREATE, Permissions.BrandRegistration);
      }

      cannot(PermissionActions.INTERACTION, Permissions.WelcomePopup);
      cannot(PermissionActions.INTERACTION, Permissions.Membership);
      cannot(PermissionActions.CREATE, Permissions.BrandRegistration);
    }
    // TODO: uncomment when we implement SC-4959
    //if (status === AccountStatus.INACTIVE || status === AccountStatus.TRIAL) {
    //  can(PermissionActions.CREATE, Permissions.BrandRegistration);
    //  can(PermissionActions.CREATE, Permissions.Dlc);
    //}
  });
}

// Define accesbility abilities based on the table
//https://smartercontact.atlassian.net/wiki/spaces/SC/pages/751140897/BA+Modal+Permission+for+each+subscription+status+on+each+feature

const restrictionsDeniedModalMessagingNone = (
  pureAbility: AbilityBuilder<SCAbility>,
  hasNoneMessagingSubscription: boolean,
  hasActiveCallingSubscription: boolean,
  hasCancelledCallingSubscription: boolean,
  hasNoneCallingSubscription: boolean,
  is10DlcApproved: boolean,
  isSubAccount: boolean,
) => {
  if (hasNoneMessagingSubscription) {
    if (
      hasActiveCallingSubscription ||
      (isSubAccount && hasActiveCallingSubscription)
    ) {
      //settings
      pureAbility.can(
        PermissionActions.CREATE,
        PermissionFeatures.settings.labels,
      );
      pureAbility.can(
        PermissionActions.UPDATE,
        PermissionFeatures.settings.labels,
      );
      pureAbility.can(
        PermissionActions.DELETE,
        PermissionFeatures.settings.labels,
      );

      pureAbility.can(
        PermissionActions.INTERACTION,
        PermissionFeatures.settings.apiIntegrations,
      );

      //contacts
      pureAbility.can(
        PermissionActions.CREATE,
        PermissionFeatures.contacts.singleContact,
      );
      pureAbility.can(
        PermissionActions.UPLOAD,
        PermissionFeatures.contacts.contacts,
      );
      pureAbility.can(
        PermissionActions.CREATE,
        PermissionFeatures.contacts.group,
      );
      pureAbility.can(
        PermissionActions.CREATE,
        PermissionFeatures.contacts.pushToCampaign,
      );

      //callingphones
      pureAbility.can(
        PermissionActions.BUY,
        PermissionFeatures.settingsCallingSetup.phoneNumber,
      );

      //dialer
      pureAbility.can(
        PermissionActions.CREATE,
        PermissionFeatures.dialer.campaigns,
      );
      pureAbility.can(
        PermissionActions.CREATE,
        PermissionFeatures.dialer.script,
      );
      pureAbility.can(
        PermissionActions.INTERACTION,
        PermissionFeatures.settings.apiIntegrations,
      );
    }

    //messenger
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.messenger.conversation,
      PermissionModalPropsSelectYourPlan,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.messenger.macro,
      PermissionModalPropsSelectYourPlan,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.SEND,
      PermissionFeatures.messenger.sms,
      PermissionModalPropsSelectYourPlan,
    );

    //campaigns
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.standard,
      PermissionModalPropsSelectYourPlan,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.keyword,
      PermissionModalPropsSelectYourPlan,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.messageTemplate,
      PermissionModalPropsSelectYourPlan,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.UPDATE,
      PermissionFeatures.campaigns.messageTemplate,
      PermissionModalPropsSelectYourPlan,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.keywordTemplate,
      PermissionModalPropsSelectYourPlan,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.UPDATE,
      PermissionFeatures.campaigns.keywordTemplate,
      PermissionModalPropsSelectYourPlan,
    );

    //contacts
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.contacts.singleContact,
      PermissionModalPropsSelectYourPlan,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.UPLOAD,
      PermissionFeatures.contacts.contacts,
      PermissionModalPropsSelectYourPlan,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.contacts.group,
      PermissionModalPropsSelectYourPlan,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.contacts.pushToCampaign,
      PermissionModalPropsSelectYourPlan,
    );

    //calendar
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.calendar.event,
      PermissionModalPropsSelectYourPlan,
    );

    cannotWithModal(
      pureAbility,
      PermissionActions.UPDATE,
      PermissionFeatures.calendar.event,
      PermissionModalPropsSelectYourPlan,
    );

    //skiptrace
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.skiptrace.pushToContacts,
      PermissionModalPropsSelectYourPlan,
    );

    if (hasNoneCallingSubscription || hasCancelledCallingSubscription) {
      //settings
      cannotWithModal(
        pureAbility,
        PermissionActions.CREATE,
        PermissionFeatures.settings.labels,
        PermissionModalPropsSelectYourPlan,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.DELETE,
        PermissionFeatures.settings.labels,
        PermissionModalPropsSelectYourPlan,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.UPDATE,
        PermissionFeatures.settings.labels,
        PermissionModalPropsSelectYourPlan,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.INTERACTION,
        PermissionFeatures.settings.apiIntegrations,
        PermissionModalPropsSelectYourPlan,
      );
      //calling setup
      cannotWithModal(
        pureAbility,
        PermissionActions.BUY,
        PermissionFeatures.settingsCallingSetup.phoneNumber,
        PermissionModalPropsCallingPlanActivate,
      );
      //dialer
      cannotWithModal(
        pureAbility,
        PermissionActions.CREATE,
        PermissionFeatures.dialer.campaigns,
        PermissionModalPropsCallingPlanActivate,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.CREATE,
        PermissionFeatures.dialer.script,
        PermissionModalPropsCallingPlanActivate,
      );
    }

    //messaging setup
    cannotWithModal(
      pureAbility,
      PermissionActions.BUY,
      PermissionFeatures.settingsMessagingSetup.phoneNumber,
      PermissionModalPropsSelectYourPlan,
    );

    //if wants to response an incoming message of a calling number and it does not have a messaging plan
    //https://smartercontact.atlassian.net/browse/SC-9273
    if (hasActiveCallingSubscription) {
      cannotWithModal(
        pureAbility,
        PermissionActions.SEND,
        PermissionFeatures.messenger.sms,
        PermissionModalPropsSendMessagePhoneNumber,
      );
    }
    //same case but if it has a messaging plan but 10dlc is not approved yet
    if (is10DlcApproved) {
      cannotWithModal(
        pureAbility,
        PermissionActions.SEND,
        PermissionFeatures.messenger.sms,
        PermissionModalPropsBrandRegistration,
      );
    }

    // if it gets bigger we should move it to its own method for subaccounts
    if (isSubAccount) {
      //https://smartercontact.atlassian.net/browse/SC-9451
      //TODO should be moved to a new function for subaccounts when we have more idea of the cases from BA
      if (hasActiveCallingSubscription || !hasCancelledCallingSubscription) {
        cannotWithModal(
          pureAbility,
          PermissionActions.SEND,
          PermissionFeatures.messenger.sms,
          PermissionModalPropsSendMessagePhoneNumber,
        );
      }
    }
  }
};

const restrictionsDeniedModalMessagingTrial = (
  pureAbility: AbilityBuilder<SCAbility>,
  hasTrialingMessagingSubscription: boolean,
  hasActiveDialerSubscription: boolean,
  hasCancelledCallingSubscription: boolean,
  hasNoneCallingSubscription: boolean,
) => {
  if (hasTrialingMessagingSubscription) {
    //messanger
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.messenger.conversation,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.messenger.macro,
    );
    pureAbility.can(PermissionActions.SEND, PermissionFeatures.messenger.sms);
    //campaigns
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.standard,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.contacts.singleContact,
    );
    //contacts
    pureAbility.can(
      PermissionActions.UPLOAD,
      PermissionFeatures.contacts.contacts,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.contacts.group,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.contacts.pushToCampaign,
    );
    //Calendar
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.calendar.event,
    );
    pureAbility.can(
      PermissionActions.UPDATE,
      PermissionFeatures.calendar.event,
    );
    //skiptrace
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.skiptrace.pushToContacts,
    );
    //setting labels
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.settings.labels,
    );
    pureAbility.can(
      PermissionActions.UPDATE,
      PermissionFeatures.settings.labels,
    );
    pureAbility.can(
      PermissionActions.DELETE,
      PermissionFeatures.settings.labels,
    );

    pureAbility.can(
      PermissionActions.INTERACTION,
      PermissionFeatures.settings.apiIntegrations,
      PermissionModalPropsMessagingPlanActivate,
    );

    if (hasActiveDialerSubscription) {
      //calling setup
      pureAbility.can(
        PermissionActions.BUY,
        PermissionFeatures.settingsCallingSetup.phoneNumber,
      );
      //dialer
      pureAbility.can(
        PermissionActions.CREATE,
        PermissionFeatures.dialer.campaigns,
      );
      pureAbility.can(
        PermissionActions.CREATE,
        PermissionFeatures.dialer.script,
      );
      pureAbility.can(
        PermissionActions.INTERACTION,
        PermissionFeatures.settings.apiIntegrations,
      );
    }

    //campaigns
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.keyword,
      PermissionModalPropsMessagingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.messageTemplate,
      PermissionModalPropsMessagingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.UPDATE,
      PermissionFeatures.campaigns.messageTemplate,
      PermissionModalPropsMessagingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.keywordTemplate,
      PermissionModalPropsMessagingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.UPDATE,
      PermissionFeatures.campaigns.keywordTemplate,
      PermissionModalPropsMessagingPlanActivate,
    );

    //messaging setup
    cannotWithModal(
      pureAbility,
      PermissionActions.BUY,
      PermissionFeatures.settingsMessagingSetup.phoneNumber,
      PermissionModalPropsMessagingPlanActivate,
    );

    //calling setup
    if (hasNoneCallingSubscription || hasCancelledCallingSubscription) {
      cannotWithModal(
        pureAbility,
        PermissionActions.BUY,
        PermissionFeatures.settingsCallingSetup.phoneNumber,
        PermissionModalPropsCallingPlanActivate,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.CREATE,
        PermissionFeatures.dialer.campaigns,
        PermissionModalPropsCallingPlanActivate,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.CREATE,
        PermissionFeatures.dialer.script,
        PermissionModalPropsCallingPlanActivate,
      );
    }
  }
};

const restrictionsDeniedModalMessagingActive = (
  pureAbility: AbilityBuilder<SCAbility>,
  hasActiveMessagingSubscription: boolean,
  hasCancelledCallingSubscription: boolean,
  hasActiveCallingSubscription: boolean,
  hasNoneCallingSubscription: boolean,
) => {
  if (hasActiveMessagingSubscription) {
    //messenger
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.messenger.conversation,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.messenger.macro,
    );
    pureAbility.can(PermissionActions.SEND, PermissionFeatures.messenger.sms);
    //campaigns
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.standard,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.keyword,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.messageTemplate,
    );
    pureAbility.can(
      PermissionActions.UPDATE,
      PermissionFeatures.campaigns.messageTemplate,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.keywordTemplate,
    );
    pureAbility.can(
      PermissionActions.UPDATE,
      PermissionFeatures.campaigns.keywordTemplate,
    );
    //contacts
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.contacts.singleContact,
    );
    pureAbility.can(
      PermissionActions.UPLOAD,
      PermissionFeatures.contacts.contacts,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.contacts.group,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.contacts.pushToCampaign,
    );
    //calendar
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.calendar.event,
    );
    pureAbility.can(
      PermissionActions.UPDATE,
      PermissionFeatures.calendar.event,
    );
    //skiptrace
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.skiptrace.pushToContacts,
    );
    //settings
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.settings.labels,
    );
    pureAbility.can(
      PermissionActions.UPDATE,
      PermissionFeatures.settings.labels,
    );
    pureAbility.can(
      PermissionActions.DELETE,
      PermissionFeatures.settings.labels,
    );
    pureAbility.can(
      PermissionActions.INTERACTION,
      PermissionFeatures.settings.apiIntegrations,
    );
    //messaging setup
    pureAbility.can(
      PermissionActions.BUY,
      PermissionFeatures.settingsMessagingSetup.phoneNumber,
    );

    if (hasActiveCallingSubscription) {
      //calling setup
      pureAbility.can(
        PermissionActions.BUY,
        PermissionFeatures.settingsCallingSetup.phoneNumber,
      );
      //dialer
      pureAbility.can(
        PermissionActions.CREATE,
        PermissionFeatures.dialer.campaigns,
      );
      pureAbility.can(
        PermissionActions.CREATE,
        PermissionFeatures.dialer.script,
      );
    }
  }

  //calling setup
  if (
    hasActiveMessagingSubscription &&
    (hasNoneCallingSubscription || hasCancelledCallingSubscription)
  ) {
    cannotWithModal(
      pureAbility,
      PermissionActions.BUY,
      PermissionFeatures.settingsCallingSetup.phoneNumber,
      PermissionModalPropsCallingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.dialer.campaigns,
      PermissionModalPropsCallingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.dialer.script,
      PermissionModalPropsCallingPlanActivate,
    );
  }
};

const restrictionsDeniedModalMessagingCancelledIncomplete = (
  pureAbility: AbilityBuilder<SCAbility>,
  hasCancelledOrIncompleteMessagingSubscription: boolean,
  hasCancelledCallingSubscription: boolean,
  hasNoneCallingSubscription: boolean,
  hasActiveCallingSubscription: boolean,
  isSubAccount: boolean,
) => {
  if (hasCancelledOrIncompleteMessagingSubscription) {
    //contacts
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.contacts.singleContact,
    );
    pureAbility.can(
      PermissionActions.UPLOAD,
      PermissionFeatures.contacts.contacts,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.contacts.group,
    );
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.contacts.pushToCampaign,
    );
    //calendar
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.calendar.event,
    );
    pureAbility.can(
      PermissionActions.UPDATE,
      PermissionFeatures.calendar.event,
    );
    //settings
    pureAbility.can(
      PermissionActions.CREATE,
      PermissionFeatures.settings.labels,
    );
    pureAbility.can(
      PermissionActions.UPDATE,
      PermissionFeatures.settings.labels,
    );
    pureAbility.can(
      PermissionActions.DELETE,
      PermissionFeatures.settings.labels,
    );
    pureAbility.can(
      PermissionActions.INTERACTION,
      PermissionFeatures.settings.apiIntegrations,
    );

    //messenger
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.messenger.conversation,
      PermissionModalPropsMessagingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.messenger.macro,
      PermissionModalPropsMessagingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.SEND,
      PermissionFeatures.messenger.sms,
      PermissionModalPropsMessagingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.SEND,
      PermissionFeatures.messenger.sms,
      PermissionModalPropsMessagingPlanActivate,
    );

    //campaigns
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.standard,
      PermissionModalPropsMessagingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.messageTemplate,
      PermissionModalPropsMessagingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.UPDATE,
      PermissionFeatures.campaigns.messageTemplate,
      PermissionModalPropsMessagingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.campaigns.keywordTemplate,
      PermissionModalPropsMessagingPlanActivate,
    );
    cannotWithModal(
      pureAbility,
      PermissionActions.UPDATE,
      PermissionFeatures.campaigns.keywordTemplate,
      PermissionModalPropsMessagingPlanActivate,
    );

    if (hasNoneCallingSubscription || hasCancelledCallingSubscription) {
      //contacts
      cannotWithModal(
        pureAbility,
        PermissionActions.CREATE,
        PermissionFeatures.contacts.singleContact,
        PermissionModalPropsMessagingPlanActivate,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.UPLOAD,
        PermissionFeatures.contacts.contacts,
        PermissionModalPropsMessagingPlanActivate,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.CREATE,
        PermissionFeatures.contacts.group,
        PermissionModalPropsMessagingPlanActivate,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.CREATE,
        PermissionFeatures.contacts.pushToCampaign,
        PermissionModalPropsMessagingPlanActivate,
      );

      //calendar
      cannotWithModal(
        pureAbility,
        PermissionActions.CREATE,
        PermissionFeatures.calendar.event,
        PermissionModalPropsMessagingPlanActivate,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.UPDATE,
        PermissionFeatures.calendar.event,
        PermissionModalPropsMessagingPlanActivate,
      );

      //calling setup
      cannotWithModal(
        pureAbility,
        PermissionActions.BUY,
        PermissionFeatures.settingsCallingSetup.phoneNumber,
        PermissionModalPropsCallingPlanActivate,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.CREATE,
        PermissionFeatures.dialer.campaigns,
        PermissionModalPropsCallingPlanActivate,
      );
      cannotWithModal(
        pureAbility,
        PermissionActions.CREATE,
        PermissionFeatures.dialer.script,
        PermissionModalPropsCallingPlanActivate,
      );
    }

    //skiptrace
    cannotWithModal(
      pureAbility,
      PermissionActions.CREATE,
      PermissionFeatures.skiptrace.pushToContacts,
      PermissionModalPropsMessagingPlanActivate,
    );

    //messaging setup
    cannotWithModal(
      pureAbility,
      PermissionActions.BUY,
      PermissionFeatures.settingsMessagingSetup.phoneNumber,
      PermissionModalPropsMessagingPlanActivate,
    );

    //https://smartercontact.atlassian.net/browse/SC-9451
    //TODO should be moved to a new function for subaccounts when we have more idea of the cases from BA
    if (
      isSubAccount &&
      (hasActiveCallingSubscription || !hasCancelledCallingSubscription)
    ) {
      cannotWithModal(
        pureAbility,
        PermissionActions.SEND,
        PermissionFeatures.messenger.sms,
        PermissionModalPropsSendMessagePhoneNumber,
      );
    }
  }
};

export function definePermissionAbilityFor(
  hasActiveMessagingSubscription: boolean,
  hasTrialingMessagingSubscription: boolean,
  hasCancelledOrIncompleteMessagingSubscription: boolean,
  hasNoneMessagingSubscription: boolean,
  hasActiveCallingSubscription: boolean,
  hasCancelledCallingSubscription: boolean,
  hasNoneCallingSubscription: boolean,
  is10DlcApproved: boolean,
  isSubaccount: boolean,
) {
  const pureAbility = new AbilityBuilder<SCAbility>(PureAbility);

  restrictionsDeniedModalMessagingNone(
    pureAbility,
    hasNoneMessagingSubscription,
    hasActiveCallingSubscription,
    hasCancelledCallingSubscription,
    hasNoneCallingSubscription,
    is10DlcApproved,
    isSubaccount,
  );
  restrictionsDeniedModalMessagingTrial(
    pureAbility,
    hasTrialingMessagingSubscription,
    hasActiveCallingSubscription,
    hasCancelledCallingSubscription,
    hasNoneCallingSubscription,
  );
  restrictionsDeniedModalMessagingActive(
    pureAbility,
    hasActiveMessagingSubscription,
    hasCancelledCallingSubscription,
    hasActiveCallingSubscription,
    hasNoneCallingSubscription,
  );

  restrictionsDeniedModalMessagingCancelledIncomplete(
    pureAbility,
    hasCancelledOrIncompleteMessagingSubscription,
    hasCancelledCallingSubscription,
    hasNoneCallingSubscription,
    hasActiveCallingSubscription,
    isSubaccount,
  );

  return pureAbility.build({
    conditionsMatcher,
  });
}
