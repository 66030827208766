import { useCallback, useState } from 'react';
import { SortLabel } from '@app/components/Filter/SortBy/interface';
import { useEntitiesPicker } from '@app/hooks/useEntitiesPicker';

export const useContacts = () => {
  const { select, selectAll, reset, items } = useEntitiesPicker<string>([]);

  const [selectedContact, setSelectedContact] = useState<string[]>([]);
  const [sort, setSort] = useState<SortLabel>(SortLabel.NEWEST);

  const [searchValue, setSearchValue] = useState<string>(null);

  const resetSelected = useCallback(() => {
    setSelectedContact(null);
    reset();
  }, [reset]);

  return {
    select,
    selectAll,
    reset,
    items,
    selectedContact,
    setSelectedContact,
    sort,
    setSort,
    searchValue,
    setSearchValue,
    resetSelected,
  };
};
