export enum AcceptedImageFormats {
  ALL = 'image/*',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  GIF = 'image/gif',
}

export enum AcceptedAudioFormats {
  AUDIO = 'audio/*',
}

export enum AcceptedPdfFormat {
  PDF = 'application/pdf',
}

export enum AcceptedCsvFormat {
  CSV = 'text/csv',
}
