import { Button, Flex, Td, Tr } from '@chakra-ui/react';
import React, { FC } from 'react';

import PhoneNumberFormat from '@app/shared/ui/PhoneNumberFormat';
import Checked from '@app/icons/check-done-current.svg?react';

import { formatInCents } from '@app/utils/priceFormatUtils';
import { Scope } from '@app/api/gql/generated-types';
import { colors } from '@app/theme/colors';
import { PhoneItem, PhoneItemStatus } from '../types';

interface RowProps extends PhoneItem {
  onBuyNumber: () => void;
  isDisabled?: boolean;
  isLocalType?: boolean;
  price: number;
  scope: Scope;
}

export const Row: FC<RowProps> = ({
  phone,
  status,
  onBuyNumber,
  isDisabled,
  isLocalType,
  price,
  scope,
}) => {
  const isLoading = status === PhoneItemStatus.LOADING;
  const isDialer = scope === Scope.DIALER;
  return (
    <Tr>
      <Td>
        <PhoneNumberFormat value={phone} />
      </Td>
      {!isDialer && <Td>SMS{isLocalType && ', MMS'} & Voice</Td>}
      {isDialer && <Td>Voice</Td>}

      <Td>${formatInCents(price)}/m</Td>
      <Td>
        {status === PhoneItemStatus.DISALLOW ? (
          <Flex
            alignItems="center"
            color={colors.notFound[2300]}
            h="36px"
            justifyContent="flex-end"
            mr="27px">
            <Checked />
          </Flex>
        ) : (
          <Flex justifyContent="flex-end" minW="80px">
            <Button
              h="36px"
              isDisabled={isLoading || isDisabled}
              isLoading={isLoading}
              variant="outlined"
              w="67px"
              onClick={onBuyNumber}>
              Buy
            </Button>
          </Flex>
        )}
      </Td>
    </Tr>
  );
};
