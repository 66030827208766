import { useApolloClient } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as typenames from '@app/api/typenames';

import {
  Contact,
  ContactFragmentDoc,
  ConversationFragment,
  ConversationFragmentDoc,
  UpdateContactMutation,
  UpdateContactMutationVariables,
  useUpdateContactMutation as useUpdateContactMutationGql,
} from '../gql/generated-types';

export const useUpdateContactMutation = (
  options?: Apollo.MutationHookOptions<
    UpdateContactMutation,
    UpdateContactMutationVariables
  >,
) => {
  const client = useApolloClient();

  return useUpdateContactMutationGql({
    optimisticResponse(vars) {
      const contact = client.cache.readFragment<Contact>({
        id: `${typenames.Contact}:${vars.id}`,
        fragmentName: typenames.Contact,
        fragment: ContactFragmentDoc,
      });

      return {
        updateContact: {
          ...contact,
          favorite: vars.input.favorite ?? contact?.favorite,
          firstName: vars.input.firstName ?? contact?.firstName,
          lastName: vars.input.lastName ?? contact?.lastName,
        },
      };
    },

    update(cache, _result, { variables }) {
      cache.updateFragment<ConversationFragment>(
        {
          id: `${typenames.Conversation}:${variables.id}`,
          fragment: ConversationFragmentDoc,
          fragmentName: typenames.Conversation,
        },
        (data) => ({
          ...data,
          isFavorite: variables.input.favorite ?? data?.isFavorite,
        }),
      );
    },
    ...options,
  });
};
