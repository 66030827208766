import { DEFAULT_LIMIT } from '@app/api/queries/utils';

export interface LimitOptionProps {
  id: string;
  value: number;
  label: number | string;
}

export const tablePageLimitOption: LimitOptionProps[] = [
  {
    id: '1',
    value: DEFAULT_LIMIT,
    label: DEFAULT_LIMIT,
  },
  {
    id: '2',
    value: 50,
    label: '50',
  },
  {
    id: '3',
    value: 100,
    label: '100',
  },
];
