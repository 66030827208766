import { FeatureType, Plan } from '@app/api/gql/generated-types';
import {
  getMessageSubscription,
  getDialerSubscription,
} from '@app/utils/subscriptionHelpers';
import { useCurrentAccountData } from './useCurrentAccountData';

export const useAccountPlansDetails = (): {
  messagePlan: Plan | undefined;
  dialerPlan: Plan | undefined;
  skiptracePlan: number | undefined;
} => {
  const account = useCurrentAccountData();
  const messageSubscription = getMessageSubscription(account);
  const dialerSubscription = getDialerSubscription(account);

  const skiptracePlan =
    account?.features?.find(({ type }) => type === FeatureType.SKIPTRACE)
      ?.price / 100;

  return {
    messagePlan: messageSubscription?.plan,
    dialerPlan: dialerSubscription?.plan,
    skiptracePlan,
  };
};
