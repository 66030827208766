import { lazy } from 'react';
import { lazyRetry } from '@app/utils/lazyRetry';

// Lazy-loaded route components
export const Messenger = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Messenger" */ '@app/pages/Messenger'),
  ),
);
export const Calendar = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Calendar" */ '@app/pages/Calendar'),
  ),
);
export const MessengerFull = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MessengerFull" */ '@app/pages/MessengerFull'
      ),
  ),
);
export const Contacts = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Contacts" */ '@app/pages/Contacts'),
  ),
);
export const Macros = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Macros" */ '@app/pages/Macros')),
);
export const Skiptrace = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Skiptrace" */ '@app/pages/Skiptrace'),
  ),
);
export const Reporting = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Reporting" */ '@app/pages/Reporting'),
  ),
);
export const UserSetup = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "UserSetup" */ '@app/pages/UserSetup'),
  ),
);
export const Affiliate = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Affiliate" */ '@app/pages/Affiliate'),
  ),
);
export const OAuth = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "OAuth" */ '@app/pages/OAuth')),
);
export const Logout = lazy(() =>
  lazyRetry(() => import(/* webpackChunkName: "Logout" */ '@app/pages/Logout')),
);
export const PopupConstructor = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PopupConstructor" */ '@app/pages/PopupConstructor'
      ),
  ),
);
export const NotFound = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "NotFound" */ '@app/components/NotFound'),
  ),
);
export const EnvInfo = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "EnvInfo" */ '@app/pages/EnvInfo').then(
      (m) => ({ default: m.EnvInfo }),
    ),
  ),
);

// Campaign
export const Campaigns = lazy(() =>
  lazyRetry(() =>
    import(/* webpackChunkName: "Campaigns" */ '@app/pages/Campaigns').then(
      (m) => ({ default: m.Campaigns }),
    ),
  ),
);
export const CampaignsList = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "CampaignsList" */ '@app/pages/Campaigns/content/CampaignsList'
      ),
  ),
);
export const CreateCampaign = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "CreateCampaign" */ '@app/pages/Campaigns/content/CreateCampaign'
      ),
  ),
);
export const CreateKeywordCampaign = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "CreateKeywordCampaign" */ '@app/pages/Campaigns/content/CreateKeywordCampaign'
      ),
  ),
);
export const MessageTemplate = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "MessageTemplate" */ '@app/pages/Campaigns/content/MessageTemplate'
      ),
  ),
);
export const KeywordTemplate = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "KeywordTemplate" */ '@app/pages/Campaigns/content/KeywordTemplate'
      ),
  ),
);

// PowerDialer
export const DialerCampaigns = lazy(() =>
  import('@app/pages/Dialer').then((m) => ({ default: m.DialerCampaigns })),
);
export const DialerCampaignsList = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "CampaignsList" */ '@app/pages/Campaigns/content/DialerCampaignsList'
      ),
  ),
);
export const CreateDialerCampaign = lazy(() =>
  import('@app/pages/Dialer/content/CreateDialerCampaign').then((m) => ({
    default: m.CreateDialerCampaign,
  })),
);
export const DialerCallPage = lazy(() =>
  import('@app/pages/Dialer/content/DialerCallPage').then((m) => ({
    default: m.DialerCallPage,
  })),
);

// Settings
export const GeneralSettings = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "GeneralSettings" */ '@app/pages/Settings/content/GeneralSettings'
      ),
  ),
);
export const Profile = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "Profile" */ '@app/pages/Settings/content/Profile'
      ),
  ),
);
export const PhoneSetup = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PhoneSetup" */ '@app/pages/Settings/content/MessagingSetup'
      ),
  ),
);
export const DlcRegistration = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DlcRegistration" */ '@app/pages/Settings/content/DlcRegistration'
      ),
  ),
);
export const BrandRegistration = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "BrandRegistration" */ '@app/pages/Settings/content/BrandRegistration'
      ),
  ),
);
export const ApiSettings = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ApiSettings" */ '@app/pages/Settings/content/ApiSettings'
      ),
  ),
);
export const Membership = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "Membership" */ '@app/pages/Settings/content/Membership'
      ),
  ),
);
export const UserInfoPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "UserInfoPage" */ '@app/pages/Settings/content/Membership/content/UserInfoPage'
      ),
  ),
);
export const AddNewCardPage = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AddNewCardPage" */ '@app/pages/Settings/content/Membership/content/AddNewCardPage'
      ),
  ),
);

export const Settings = lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "Settings" */ '@app/pages/Settings'),
  ),
);

export const ContactManagement = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ContactManagement" */ '@app/pages/Settings/content/ContactManagement'
      ),
  ),
);
export const HelpSupport = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "HelpSupport" */ '@app/pages/Settings/content/HelpSupport'
      ),
  ),
);
export const AffiliateProgram = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AffiliateProgram" */ '@app/pages/Settings/content/AffiliateProgram'
      ),
  ),
);
export const Resources = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "Resources" */ '@app/pages/Settings/content/Resources'
      ),
  ),
);
export const MobileApp = lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "Resources" */ '@app/pages/Settings/content/MobileApp'
      ),
  ),
);
