import React, { FC, useCallback, MouseEvent } from 'react';
import {
  Button,
  ButtonProps,
  useDisclosure,
  Flex,
  Text,
} from '@chakra-ui/react';

import LoomPlayer from '@app/components/LoomPlayer';
import PlayIcon from '@app/icons/play-circle-icon.svg?react';
import video from '@app/utils/videoLinks';

export const ContactVideoButton: FC<ButtonProps> = ({ ...props }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onOpen();
    },
    [onOpen],
  );

  return (
    <>
      <Button
        variant="outlined"
        {...props}
        _hover={{
          backgroundColor: 'white',
        }}
        backgroundColor="zircon"
        height="36px"
        maxW="143px"
        mr="20px"
        onClick={onClick}>
        <Flex alignItems="center">
          <PlayIcon />
          <Text ml="10px">Watch video</Text>
        </Flex>
      </Button>
      <LoomPlayer
        isOpenModal={isOpen}
        link={video.contacts.groups}
        onCloseModal={onClose}
      />
    </>
  );
};
