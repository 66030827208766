import React, { FC, useCallback, MouseEvent } from 'react';
import { Flex, Button, useDisclosure } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { ApolloError } from '@apollo/client';
import IconButtonRemove from '@app/components/IconButtonRemove';
import {
  FindContactsDocument,
  FindGroupsDocument,
  useAddGroupToContactsMutation,
} from '@app/api/gql/generated-types';
import AddToGroupIcon from '@app/icons/add-to-group-icon.svg?react';
import CreateGroupIcon from '@app/icons/createGroupIcon.svg?react';
import { ExceptionCode } from '@app/types/ApolloErrors';
import { CreateGroupModal } from './CreateGroupModal';
import { AddToGroupModal } from './AddToGroupModal';

interface ContactGroupActionsProps {
  selectedContacts: string[];
  onAddToGroup: () => void;
  onDelete: () => void | Promise<void>;
}

export const ContactGroupActions: FC<ContactGroupActionsProps> = ({
  selectedContacts,
  onAddToGroup,
  onDelete,
}) => {
  const addToGroupModal = useDisclosure();
  const createGroupModal = useDisclosure();

  const [addGroupToContactsMutation, { loading: isLoading }] =
    useAddGroupToContactsMutation({
      refetchQueries: [FindContactsDocument, FindGroupsDocument],
      awaitRefetchQueries: true,
    });

  const onAddContactToGroup = useCallback(
    async (groupId: string) => {
      if (!isEmpty(selectedContacts)) {
        await addGroupToContactsMutation({
          variables: {
            input: {
              ids: selectedContacts,
              groupId,
            },
          },
          context: {
            notify: {
              success: () => 'Added to group successfully',
              error: (error: ApolloError['graphQLErrors']) => {
                if (
                  error[0].extensions.code ===
                  ExceptionCode.INTERNAL_SERVER_ERROR
                ) {
                  return 'One or more of the contacts selected already belongs to the group and won’t be added';
                }
                return error?.[0]?.message;
              },
            },
          },
        });
      }

      onAddToGroup();
      addToGroupModal.onClose();
    },
    [
      selectedContacts,
      onAddToGroup,
      addGroupToContactsMutation,
      addToGroupModal,
    ],
  );

  const onCreateGroup = useCallback(
    async (groupId: string) => {
      await onAddContactToGroup(groupId);
      onAddToGroup();
      createGroupModal.onClose();
    },
    [onAddContactToGroup, onAddToGroup, createGroupModal],
  );

  const handleDeleteContactFromList = async (
    event: MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    await onDelete();
  };

  return (
    <>
      <Flex
        alignItems="center"
        flexDirection="row"
        justifyContent="flex-start"
        m="21px 0 13px 0px"
        p="0 23px">
        <IconButtonRemove size="small" onClick={handleDeleteContactFromList} />
        <Button
          h="28px"
          leftIcon={<AddToGroupIcon />}
          maxW="135px"
          ml="12px"
          variant="outlined"
          onClick={addToGroupModal.onOpen}>
          Add to group
        </Button>
        <Button
          h="28px"
          leftIcon={<CreateGroupIcon />}
          maxW="166px"
          ml="20px"
          variant="outlined"
          onClick={createGroupModal.onOpen}>
          Create new group
        </Button>
      </Flex>
      <CreateGroupModal
        isLoading={isLoading}
        isOpen={createGroupModal.isOpen}
        title="Create new group"
        onClose={createGroupModal.onClose}
        onCreateGroup={onCreateGroup}
      />
      <AddToGroupModal
        isLoading={isLoading}
        isOpen={addToGroupModal.isOpen}
        title="Add to group"
        onAddContactToGroup={onAddContactToGroup}
        onClose={addToGroupModal.onClose}
      />
    </>
  );
};
