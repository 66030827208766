import { useEffect, useState } from 'react';
import { applyActionCode, Auth } from 'firebase/auth';
import { useAuth } from 'reactfire';
import { FirebaseError } from 'firebase/app';
import { useSearchParams } from 'react-router-dom';
import ToastNotify from '@app/components/ToastNotifier';
import { handleFirebaseError } from '@app/utils/apolloErrorsMapHelper';

const verifyEmail = async (auth: Auth, code: string) => {
  await applyActionCode(auth, code);
  await auth.currentUser?.reload();
};

export const useEmailVerification = () => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();

  const oobCode: string | null = searchParams.get('oobCode');
  const isVerificationMode = searchParams.get('mode') === 'verifyEmail';

  const [status, setStatus] = useState<string>(
    isVerificationMode ? 'loading' : undefined,
  );

  useEffect(() => {
    if (isVerificationMode && oobCode) {
      const performVerification = async () => {
        try {
          await verifyEmail(auth, oobCode);
          setStatus('success');
        } catch (error) {
          setStatus('error');
          ToastNotify({
            status: 'error',
            title: handleFirebaseError(error as FirebaseError),
          });
        }
      };

      void performVerification();
    }
  }, [isVerificationMode, oobCode, auth]);

  return { status };
};
