import { useEffect } from 'react';
import { getLogger } from '@app/utils/logger';
import { upsertReferralCode } from '@app/utils/affiliateUtils';
import { upsertPromoCode } from '@app/utils/promoCodeUtils';

const LOG = getLogger('hooks/useInitialQueryParams');

const queryParams = new URLSearchParams(window.location.search);

export const useProcessInitialQueryParams = () => {
  useEffect(() => {
    LOG.debug('setting initial query params', queryParams);
    upsertReferralCode();
    upsertPromoCode();
  }, []);
};
