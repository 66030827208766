import React, { FC } from 'react';
import {
  Flex,
  ButtonGroup,
  useDisclosure,
  Box,
  Spacer,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';

import UploadIcon from '@app/icons/upload-icon.svg?react';

import { useTrialMaxContactsGuard } from '@app/hooks/useTrialMaxContactsGuard';
import { PermissionButton } from '@app/components/next/atoms/PermissionsComponents/WithPermission';
import { PermissionActions, PermissionFeatures } from '@app/acl';
import { AddNewContact } from '../../components/Modal/AddNewContact';
import { SelectPageLimit } from '../../components/SelectPageLimit';
import { useContactsUploadContext } from '../../../../providers/ContactsUploadProvider';

interface AllContactsTopSectionProps {
  selectedItems?: string[];
  isContactsEmpty?: boolean;
  pageLimit?: number;
  handleChangePageLimit?: ({ value }: { value: number }) => void;
}

export const AllContactsTopSection: FC<AllContactsTopSectionProps> = ({
  selectedItems,
  isContactsEmpty,
  pageLimit,
  handleChangePageLimit,
}) => {
  const { dropModal } = useContactsUploadContext();
  const { checkAccountContacts, isValidating } = useTrialMaxContactsGuard();
  const addNewContactModal = useDisclosure();

  const handleNewContactAdd = () =>
    checkAccountContacts(addNewContactModal.onOpen);

  return (
    <Flex alignItems="center" direction="column" px="16px" width="100%">
      <Flex mb="1px" mt="20px" w="100%">
        <ButtonGroup pl="14px" spacing="5">
          <PermissionButton
            action={PermissionActions.CREATE}
            data-cy="add-new-contact-button"
            isDisabled={!isEmpty(selectedItems)}
            isLoading={isValidating}
            px="4"
            subject={PermissionFeatures.contacts.singleContact}
            variant="primary"
            onSuccess={handleNewContactAdd}>
            Add new contact
          </PermissionButton>
          <PermissionButton
            action={PermissionActions.UPLOAD}
            data-cy="upload-file-button"
            isDisabled={!isEmpty(selectedItems)}
            leftIcon={<UploadIcon />}
            subject={PermissionFeatures.contacts.contacts}
            variant="outlined"
            onSuccess={dropModal.onOpen}>
            Upload file
          </PermissionButton>
        </ButtonGroup>
        <Spacer />
        {!isContactsEmpty && (
          <Box height="40px" width="135px" zIndex="11">
            <SelectPageLimit
              handleChangePageLimit={handleChangePageLimit}
              pageLimit={pageLimit}
            />
          </Box>
        )}
      </Flex>

      <AddNewContact
        isOpen={addNewContactModal.isOpen}
        maxW="420px"
        title="Create a new contact"
        onClose={addNewContactModal.onClose}
      />
    </Flex>
  );
};
