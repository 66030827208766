import React, { FC } from 'react';
import { Stack, Spinner } from '@chakra-ui/react';

interface LoaderProps {
  size?: 'sm' | 'md' | 'lg' | 'xs' | 'xl';
  color?: string;
  thickness?: string;
}

const Loader: FC<LoaderProps> = ({ size, color, thickness }): JSX.Element => (
  <Stack alignItems="center" height="100%" justifyContent="center">
    <Spinner color={color} size={size} thickness={thickness} />
  </Stack>
);

export default Loader;
