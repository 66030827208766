import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import Popup from '@app/components/Popup';
import Loader from '@app/components/Loader';
import { DisclaimerBox } from '@app/components/next/moleculas/DisclaimerBox';

export interface PreparingListModalProps {
  isOpen: boolean;
  contactsCount: number;
}

/**
 * @deprecated This will be unified into a global shared "waiting" component at some point
 */
export const PreparingListModal = ({
  isOpen,
  contactsCount,
}: PreparingListModalProps) => {
  return (
    <Popup
      hideCloseIcon
      isOpen={isOpen}
      title="Preparing your list"
      width="464px"
      onClose={() => {}}>
      <Flex
        alignItems="center"
        flexDirection="column"
        gap="16px"
        mb="40px"
        mt="16px"
        textAlign="center">
        <Text color="main.300" fontSize="14px">
          Preparing {contactsCount} contacts. This might take a while&hellip;
        </Text>
        <Loader color="primary.600" size="lg" thickness="3px" />
        <DisclaimerBox backgroundColor="paleGamboge" gap="8px" p="8px 16px">
          <Text color="main.400" fontSize="14px">
            Please don&apos;t close or refresh this page
          </Text>
        </DisclaimerBox>
      </Flex>
    </Popup>
  );
};
