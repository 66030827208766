import React, { FC } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { DefaultLayout } from '@app/layouts/DefaultLayout';

const NotFound: FC = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-3);

  return (
    <DefaultLayout>
      <Flex
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        w="100%">
        <Text
          color="secondary.400"
          fontSize="165px"
          fontStyle="normal"
          letterSpacing="2px"
          lineHeight="130px">
          404
        </Text>
        <Text lineHeight="24px" mb="20px" mt="50px" variant="heading-fifth">
          It looks like we could not find the page you were looking for.
        </Text>
        <Button variant="outlined" w="132px" onClick={goBack}>
          Go Back
        </Button>
      </Flex>
    </DefaultLayout>
  );
};

export default NotFound;
