import { object } from 'yup';

import {
  emptyPhoneNumber,
  phoneNumberValidation,
} from '@app/components/PhoneNumberInput';

export const incomingPhoneSchema = object().shape({
  phone: phoneNumberValidation
    .transform((v: string) => (v === emptyPhoneNumber || v === '' ? null : v))
    .nullable()
    .notRequired(),
});
