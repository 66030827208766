export enum MessengerFolderType {
  ALL,
  UNREAD,
  SENT,
  DELETED,
  LABELS,
  GROUPS,
  CAMPAIGNS,
  OPTED_OUT,
  MISSED_CALLS,
}

export enum UpdateMultiLabels {
  ADD,
  REMOVE,
}
