import React, { FC, useCallback } from 'react';
import { Box, Image, Text, UseDisclosureProps } from '@chakra-ui/react';

import YoutubeIcon from '@app/assets/images/trial-youtube-icon.png';
import Popup from '@app/components/Popup';
import video from '@app/utils/videoLinks';

import {
  AccountSettingsPopup,
  useUpdateAccountMutation,
} from '@app/api/gql/generated-types';
import Player from '../10Dlc/Player';

const SecondDayTrialPopup: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const [updateAccount] = useUpdateAccountMutation();

  const onSubmitPopup = useCallback(async () => {
    await updateAccount({
      variables: {
        data: {
          viewedPopup: AccountSettingsPopup.popupTrialSecondDay,
        },
      },
    });

    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="455px"
      title=""
      titlePadding="40px 56px 50px"
      onClose={onSubmitPopup}>
      <Box p="0 40px 40px">
        <Box>
          <Text
            color="main.400"
            fontSize="22px"
            fontWeight="500"
            lineHeight="25px"
            m="40px auto 18px"
            textAlign="center">
            Congrats on your second day with Smarter Contact and hopefully
            you`re getting the hang of things.
          </Text>
        </Box>
        <Box cursor="pointer" mb="23px" position="relative">
          <Player
            image={<Image src={YoutubeIcon} />}
            type="loom"
            video={video.trial.welcome}
          />
        </Box>
        <Box lineHeight="22px" m="10px auto" maxW="371px" textAlign="center">
          <Text as="span" fontSize="14px">
            Today we&apos;ll be doing a deep dive into strategy 🤠... (probably
            the most important). This is truly CAN&apos;T MISS and if you follow
            these steps in this video, you`ll be on your way to closing your
            next deals 🏡
          </Text>
        </Box>
      </Box>
    </Popup>
  );
};

export default SecondDayTrialPopup;
