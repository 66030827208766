import React, { FC, PropsWithChildren } from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';
import BgSignUp from '@app/icons/bg-sign-up.svg';

interface BackgroundContainerProps extends ContainerProps {
  backgroundImageUrl?: string;
}

export const BackgroundContainer: FC<
  PropsWithChildren<BackgroundContainerProps>
> = ({ backgroundImageUrl = BgSignUp, children, ...props }) => {
  return (
    <Container
      backgroundColor="white"
      backgroundImage={`url(${backgroundImageUrl})`}
      backgroundPosition="bottom"
      backgroundRepeat="no-repeat"
      backgroundSize={['', 'contain']}
      height="100vh"
      maxWidth="100%"
      overflow="hidden"
      {...props}>
      {children}
    </Container>
  );
};
