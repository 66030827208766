import React, { FC, memo, useState } from 'react';
import { Checkbox, CheckboxGroup, Flex, useDisclosure } from '@chakra-ui/react';

import {
  defaultSort,
  SortLabel,
} from '@app/components/Filter/SortBy/interface';

import RenderPopoverContent from '../RenderPopoverContent';

interface SortByProps {
  onChange: (value: SortLabel) => void;
  labels?: SortLabel[];
  value?: SortLabel;
  isDisabled?: boolean;
}

const SortBy: FC<SortByProps> = ({
  onChange,
  labels = defaultSort,
  value,
  isDisabled = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [checkbox, setCheckbox] = useState<SortLabel>(value || defaultSort[0]);

  const onChangeItem = (label: SortLabel) => {
    setCheckbox(label);
    onChange(label);
  };

  return (
    <Flex
      align="center"
      bg="white"
      borderStyle="solid"
      color="gray.600"
      ml="16px"
      position="relative">
      <Flex alignItems="flex-start" direction="row" id="filter-box">
        <RenderPopoverContent
          filterTitle="Sort by:"
          filterValue={checkbox}
          isDisabled={isDisabled}
          isOpen={isOpen}
          offset={[25, 0]}
          onClose={onClose}
          onOpen={!isDisabled ? onOpen : undefined}>
          <CheckboxGroup value={[checkbox]}>
            {labels?.map((label) => (
              <Checkbox
                key={label}
                value={label}
                variant="sortFilter"
                onChange={() => onChangeItem(label)}>
                {label}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </RenderPopoverContent>
      </Flex>
    </Flex>
  );
};

export default memo(SortBy);
