import { MessagingProviderType } from '@app/api/gql/generated-types';
import { useDialerContext } from '@app/providers/DialerProvider';

export const useDisableMessaging = () => {
  const { providerType } = useDialerContext();

  const isDisabled =
    providerType === MessagingProviderType.TWILIO ||
    providerType === MessagingProviderType.TELNYX ||
    providerType === MessagingProviderType.PLIVO;

  return {
    isDisabled,
  };
};
