/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Box, Skeleton, Stack, BoxProps } from '@chakra-ui/react';

import { colors } from '@app/theme/colors';
import { TableSkeleton } from '../Table/Skeleton';

interface Props extends BoxProps {
  showControls?: boolean;
}

const PageSkeleton: FC<Props> = ({ showControls = true, ...props }) => {
  return (
    <Box width="100%" {...props}>
      {showControls && (
        <Stack flexDirection="row" m="20px">
          <Skeleton
            endColor={colors.cultured}
            height="40px"
            startColor={colors.mystic}
            width="140px"
          />
        </Stack>
      )}

      <TableSkeleton />
    </Box>
  );
};

export default PageSkeleton;
