import { isEmpty } from 'lodash/fp';
import { getQueryParam } from '@app/utils/queryParamUtils';
import {
  PROMO_CODE_LOCALSTORAGE_PARAM,
  PROMO_CODE_QUERY_PARAM,
} from '@app/constants/configuration';
import {
  getLocalStorageItem,
  setLocalStorageItem,
  unsetLocalStorageItem,
} from '@app/utils/localStorageUtils';
import { getLogger } from '@app/utils/logger';

const LOG = getLogger('utils/promoCodeUtils');

/**
 * Saves the promo code in the local storage.
 *
 * @param {string} promoCode - The promo code to be saved.
 * @returns {void}
 */
export const savePromoCode = (promoCode: string): void =>
  setLocalStorageItem(PROMO_CODE_LOCALSTORAGE_PARAM, promoCode);

/**
 * Function to unset the promo code from the local storage.
 *
 * @returns {void} This function does not return any value.
 */
export const unsetPromoCode = (): void =>
  unsetLocalStorageItem(PROMO_CODE_LOCALSTORAGE_PARAM);

/**
 * Returns the promo code query parameter value from the current URL.
 *
 * @returns {string | undefined} The promo code query parameter value if found, else undefined.
 */
export const getPromoCodeQueryParam = (): string | undefined =>
  getQueryParam(PROMO_CODE_QUERY_PARAM, undefined);

/**
 * Retrieves the promo code value from local storage.
 *
 * @returns {string | undefined} The promo code value from local storage, or undefined if it does not exist.
 */
export const getPromoCodeLocalStorage = (): string | undefined =>
  getLocalStorageItem<string | undefined>(
    PROMO_CODE_LOCALSTORAGE_PARAM,
    undefined,
  );

/**
 * Upserts the promo code.
 *
 * @returns {void}
 */
export const upsertPromoCode = (): void => {
  const code = getPromoCodeQueryParam();

  if (!isEmpty(code)) {
    LOG.debug('Setting promo code', code);
    savePromoCode(code);

    return;
  }

  LOG.debug('No promo code found');
};

/**
 * Retrieves the promo code.
 *
 * @return {string | undefined} The retrieved promo code. Returns `undefined` if no promo code is found.
 */
export const getPromoCode = (): string | undefined => {
  return getPromoCodeQueryParam() || getPromoCodeLocalStorage();
};
