import React, { FC } from 'react';
import {
  Popover,
  PopoverTrigger,
  Icon,
  Portal,
  PopoverContent,
  PopoverArrow,
  Text,
  Link,
  PopoverBody,
} from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import ROUTES from '@app/utils/routes';

import InfoIcon from '@app/icons/InfoIcon.svg?react';

type Props = {
  text?: string;
  linkText?: string;
  width?: string;
};

export const PopoverWithLink: FC<Props> = ({ text, linkText, width }) => (
  <Popover computePositionOnMount isLazy placement="top" trigger="hover">
    <PopoverTrigger>
      <Icon as={InfoIcon} color="secondary.400" cursor="pointer" ml="5px" />
    </PopoverTrigger>

    <Portal>
      <PopoverContent
        _focus={{
          outline: '0',
        }}
        borderRadius="8px"
        boxShadow="0px 6px 18px rgba(139, 139, 139, 0.25)"
        outline="0"
        width={width}>
        <PopoverBody padding="12px 10px">
          <Text variant="heading-fifth">
            {text}{' '}
            <Link
              isExternal
              _hover={{ textDecoration: 'underline' }}
              as={RouteLink}
              textDecoration="none"
              to={`${ROUTES.settingsContactManagement}`}
              variant="link">
              {linkText}
            </Link>
          </Text>
        </PopoverBody>
        <PopoverArrow />
      </PopoverContent>
    </Portal>
  </Popover>
);
