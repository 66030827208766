import { colors } from '@app/theme/colors';

export const customScrollbarCssProps = {
  '&::-webkit-scrollbar': {
    w: '1',
  },
  '&::-webkit-scrollbar-track': {
    w: '2',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '20',
    bg: `americanSilver`,
  },
};

export const customScrollbarSecondaryCssProps = {
  '&::-webkit-scrollbar': {
    w: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '3px',
    bg: colors.main[100],
  },
};

export const customScrollbarDropdown = {
  '::-webkit-scrollbar': {
    width: '5px',
    height: '0px',
  },
  '::-webkit-scrollbar-track': {
    background: 'white',
    margin: '12px 0',
  },
  '::-webkit-scrollbar-thumb': {
    background: colors.main[100],
    borderRadius: '3px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: colors.main[100],
  },
};
