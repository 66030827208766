import React, { FC, useState } from 'react';
import { Center, Flex, PopoverHeader, Box, Button } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import SetttingsIcon from '@app/icons/settings-notification-icon.svg?react';
import ROUTES from '@app/utils/routes';
import {
  FindNotificationsDocument,
  FindNotificationsQuery,
  useMarkNotificationsReadMutation,
} from '@app/api/gql/generated-types';
import { colors } from '@app/theme/colors';

interface NotificationsPopoverHeaderProps {
  isUnreadItems: boolean;
  clickedUnread: boolean;
  setClickedUnread: (arg: boolean) => void;
}

export const NotificationsPopoverHeader: FC<
  NotificationsPopoverHeaderProps
> = ({ isUnreadItems, clickedUnread, setClickedUnread }) => {
  const filterUnreadHandler = () => {
    setClickedUnread(!clickedUnread);
  };
  const [mutateSetRead] = useMarkNotificationsReadMutation();
  const [isLoading, setIsLoading] = useState(false);

  const markAllReadHandler = () => {
    if (isUnreadItems) {
      setIsLoading(true);
      void mutateSetRead({
        variables: {
          input: {
            id: null,
          },
        },
        update(cache) {
          const cacheData = cache.readQuery<FindNotificationsQuery>({
            query: FindNotificationsDocument,
          });
          setIsLoading(false);
          if (!cacheData) {
            return;
          }
          const { findNotifications } = cacheData;
          cache.writeQuery<FindNotificationsQuery>({
            query: FindNotificationsDocument,
            data: {
              findNotifications: {
                ...findNotifications,
                items: findNotifications.items?.map((notification) => {
                  return { ...notification, isRead: true };
                }),
              },
            },
          });
        },
      });
    }
  };

  return (
    <PopoverHeader padding="16px 16px 9px 16px">
      <Flex alignItems="flex-start" justifyContent="space-between">
        <Flex direction="column">
          <Center
            color="main.400"
            fontSize="18px"
            fontWeight="500"
            lineHeight="normal">
            Notifications
          </Center>
          <Flex
            fontSize="12px"
            fontWeight="500"
            gap="12px"
            height="24px"
            lineHeight="normal"
            mt="8px">
            {clickedUnread && (
              <Center
                color="secondary.400"
                cursor="pointer"
                padding="5px 12px"
                onClick={filterUnreadHandler}>
                All
              </Center>
            )}
            {!clickedUnread && (
              <Center
                bg="primary.100"
                borderRadius="12px"
                color="primary.700"
                padding="5px 12px">
                All
              </Center>
            )}
            {!clickedUnread && (
              <Center
                color="secondary.400"
                cursor="pointer"
                padding="5px 12px"
                onClick={filterUnreadHandler}>
                Unread
              </Center>
            )}
            {clickedUnread && (
              <Center
                bg="primary.100"
                borderRadius="12px"
                color="primary.700"
                padding="5px 12px">
                Unread
              </Center>
            )}
          </Flex>
        </Flex>
        <Flex alignItems="end" direction="column">
          <Box
            alignItems="center"
            display="flex"
            flexGrow="0"
            flexShrink="0"
            height={21}
            marginRight="4px">
            <RouteLink to={ROUTES.settingsNotifications}>
              <SetttingsIcon
                cursor="pointer"
                height={16}
                stroke={colors.main[100]}
                width={16}
              />
            </RouteLink>
          </Box>
          {isUnreadItems && (
            <Button
              _active={{
                background: colors.transparent,
              }}
              _hover={{
                color: 'primary.700',
              }}
              bg={colors.transparent}
              color="secondary.400"
              cursor="pointer"
              fontSize="12px"
              fontWeight="400"
              gap="12px"
              height="24px"
              isLoading={isLoading}
              lineHeight="normal"
              mt="8px"
              padding="0px"
              onClick={markAllReadHandler}>
              Mark all as read
            </Button>
          )}
        </Flex>
      </Flex>
    </PopoverHeader>
  );
};
