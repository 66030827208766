import React from 'react';
import { Box } from '@chakra-ui/react';
import { isProduction } from '@app/utils/envUtils';

const envShotTitle: Partial<NodeJS.ProcessEnv> = {
  development: 'DEV',
  staging: 'STG',
};

const EnvironmentLabel = () => {
  if (isProduction) {
    return null;
  }

  return (
    <Box
      backgroundColor="red"
      borderRadius="13px"
      bottom="11px"
      color="white"
      fontSize="0.75rem"
      minWidth="30px"
      p="4px"
      position="absolute"
      right="-22px">
      {envShotTitle[import.meta.env.MODE]}
    </Box>
  );
};

export default EnvironmentLabel;
