import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Popup from '@app/components/Popup';

interface IUnableDeleteModalProps {
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  content?: React.ReactNode;
  action?: React.ReactNode;
}

export const UnableDeleteModal: React.FC<IUnableDeleteModalProps> = ({
  title = 'Unable to delete',
  onClose,
  isOpen,
  content,
  action,
}) => {
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="480px"
      title={title}
      onClose={onClose}>
      <Box p="28px 0 40px">
        <Flex alignItems="center" justifyContent="center">
          <Box w={350}>{content}</Box>
        </Flex>
        <Flex flexDirection="row" justifyContent="center" mt={25}>
          {action}
        </Flex>
      </Box>
    </Popup>
  );
};
