import { object, string } from 'yup';

import { citySchema, stateSchema, streetSchema } from './address-schema';

export const addCardSchema = object().shape({
  name: string()
    .trim()
    .matches(/\b[A-Za-z]+?\b/, 'Name should contain only letters')
    .label('Full Name')
    .required(''),
  address_line1: streetSchema.required(''),
  address_city: citySchema.required(''),
  address_state: stateSchema.required(''),
  address_zip: string().required(''),
});
