import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';
import React, { createRef, FC, useCallback, useEffect, useState } from 'react';

import IconButtonEdit from '@app/components/IconButtonEdit';
import { useYupValidation } from '@app/hooks/useYupValidation';
import { VerifyBadge } from '@app/pages/Contacts/components/VerifyBadge';
import { entityNameSchema } from '@app/schemas/entity-name-schema';

import { GroupViewFragment, ListView } from '@app/api/gql/generated-types';
import { colors } from '@app/theme/colors';
import { SkeletonHeader } from './SkeletonHeader';

type ContactListTypes = {
  contactList: ListView;
  group: GroupViewFragment;
  onChange: (name: string) => void;
  isLoading: boolean;
};

export const ContactHeader: FC<ContactListTypes> = ({
  group,
  onChange,
  isLoading,
}) => {
  const [entityName, setContactHeaderName] = useState<string>('');
  const { errors } = useYupValidation(entityName, entityNameSchema);

  useEffect(() => {
    setContactHeaderName(group?.name ?? '');
  }, [group?.name]);

  const handleBlur = useCallback(() => {
    try {
      if (errors.entityName) {
        throw new Error(errors.entityName);
      }
      onChange(entityName);
    } catch (err) {
      console.error('🚀 ~ handleBlur ~ err', err);
    }
  }, [entityName, onChange, errors.entityName]);

  const ref = createRef<HTMLInputElement>();

  const onInputEdit = () => {
    ref.current.focus();
  };

  if (isLoading) {
    return <SkeletonHeader />;
  }
  return (
    <Flex alignItems="center" justifyContent="space-between" mb="30px" w="100%">
      <Flex alignItems="center">
        <FormControl isInvalid={!!errors.entityName && entityName !== ''}>
          <Input
            ref={ref}
            _focus={{ shadow: 'none' }}
            _focusVisible={{ shadow: 'outline' }}
            border="none"
            color="main.400"
            errorBorderColor={colors.transparent}
            flex="0 0 auto"
            fontSize="18px"
            fontWeight="500"
            maxLength={50}
            minW={!entityName ? '200px' : 'auto'}
            p="0"
            placeholder="Enter Group Name"
            transition="width 0.1s"
            value={entityName}
            width={`${entityName.length * 1.2}ch`}
            onBlur={handleBlur}
            onChange={(event) => setContactHeaderName(event.target.value)}
          />
          {errors.entityName && (
            <Box bottom="-7px" left="0" position="absolute">
              <FormErrorMessage>{errors.entityName}</FormErrorMessage>
            </Box>
          )}
        </FormControl>

        <Flex alignItems="center">
          <IconButtonEdit onClick={onInputEdit} />

          {!group?.verified && <VerifyBadge />}
        </Flex>
      </Flex>
    </Flex>
  );
};
