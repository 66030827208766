import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import React, { FC, MouseEvent, useCallback, useMemo } from 'react';
import {
  ConversationFilter,
  useRemoveContactsMutation,
} from '@app/api/gql/generated-types';
import { ErrorWithCode } from '@app/api/queries/types';
import Popup from '@app/components/Popup';
import { ErrorCode } from '@app/utils/errorMessage';
import { wordPluralize } from '@app/utils/string';
import { removeConversationFromCache } from '../../utils/cacheHelpers';

interface RemoveBatchMessagesModalProps {
  ids: string[];
  onDelete: (deletedContactIds?: string[]) => void;
  onRemoveError: () => void;
  params: ConversationFilter;
  removeBatchMessagesDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: any) => any;
    getDisclosureProps: (props?: any) => any;
  };
}

export const RemoveBatchMessagesModal: FC<RemoveBatchMessagesModalProps> = ({
  ids,
  onDelete,
  onRemoveError,
  removeBatchMessagesDisclosure,
}) => {
  const isMultiRemove = useMemo(() => ids?.length > 1, [ids?.length]);

  const [mutate, { loading: isLoading }] = useRemoveContactsMutation({
    variables: {
      input: {
        ids,
      },
    },
    context: {
      notify: {
        success: () =>
          `${wordPluralize(ids?.length, 'Conversation')} removed successfully`,
      },
    },

    update(cache) {
      //   remove from cache all selected conversation ids
      ids.forEach((id) => {
        removeConversationFromCache(cache, id);
      });
    },
  });

  const onDeleteContactsFromList = useCallback(
    async (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      try {
        await mutate();

        onDelete(ids);
      } catch (error) {
        if (
          ((error as ErrorWithCode)?.details?.code as ErrorCode) ===
          ErrorCode.CONTACTS_CANNOT_BE_REMOVED
        ) {
          onRemoveError();
        }
      } finally {
        removeBatchMessagesDisclosure.onClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ids],
  );

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={removeBatchMessagesDisclosure.isOpen}
      maxW="370px"
      size="3xl"
      title="Delete"
      onClose={removeBatchMessagesDisclosure.onClose}>
      <Flex alignItems="center" direction="column">
        <Text fontStyle="normal" mt="25px" variant="confirm-text" width="270px">
          Are you sure you want to delete {isMultiRemove ? 'these' : 'this'}{' '}
          {wordPluralize(ids?.length, 'conversation')}?
        </Text>
        <Stack direction="row" mt="30px" spacing={4}>
          <Button
            mb="40px"
            variant="outlined"
            width="83px"
            onClick={removeBatchMessagesDisclosure.onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            loadingText="Deleting…"
            mb="40px"
            variant="primary"
            onClick={onDeleteContactsFromList}>
            Delete
          </Button>
        </Stack>
      </Flex>
    </Popup>
  );
};
