import React, { FC, useState, useCallback } from 'react';
import { Td, Input, Box } from '@chakra-ui/react';
import { colors } from '@app/theme/colors';

interface TableListProps {
  indexCell: number;
  cell: string;
  isEdit: boolean;
  onEditCell: (indexCell: number, cell: string) => void;
}

const RenderTable: FC<TableListProps> = ({
  indexCell,
  cell,
  onEditCell,
  isEdit,
}: TableListProps) => {
  const [inputValue, setInputValue] = useState<string>(cell);

  const onInputBlur = useCallback(
    () => onEditCell(indexCell, inputValue),
    [indexCell, inputValue, onEditCell],
  );

  return (
    <Td
      key={`cell-${indexCell.toString()}`}
      borderBottom={`1px solid ${colors.notFound[1900]}`}
      color={colors.main[400]}
      fontFamily="'Roboto', sans-serif;"
      fontSize="14px"
      lineHeight="16px"
      maxWidth="220px"
      padding="2px 20px"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      width="210px">
      {isEdit ? (
        <Input
          _focus={{ border: `1px solid ${colors.primary[600]}` }}
          value={inputValue}
          variant="primary"
          onBlur={() => onInputBlur()}
          onChange={({ target: { value } }) => setInputValue(value)}
        />
      ) : (
        <Box
          color={colors.main[400]}
          fontSize="14px"
          lineHeight="16px"
          overflow="hidden"
          padding="3px 6px"
          textOverflow="ellipsis">
          {cell}
        </Box>
      )}
    </Td>
  );
};

export default RenderTable;
