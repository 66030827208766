import React, { memo } from 'react';
import { chakra } from '@chakra-ui/react';

interface SvgImageIconProps {
  color?: string;
  svgIcon: React.FunctionComponent;
}

const SvgImageIcon = ({ color, svgIcon }: SvgImageIconProps) => {
  const ChakraIcon = chakra(svgIcon);
  return <ChakraIcon color={color || 'currentColor'} />;
};

const MemoSvgImageIcon = memo(SvgImageIcon);
export default MemoSvgImageIcon;
