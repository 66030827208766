/* eslint no-restricted-syntax:0 */

export const colors = {
  transparent: 'transparent',
  white: '#fff',
  cultured: '#f5f5f5',
  catskillWhite: '#F3F6F9', // Figma: Alice Blue
  zircon: '#F9FCFF',
  linen: '#FEF1EA',
  paleGamboge: '#FFF6E5',
  mystic: '#EAEDF3',
  gallery: '#F0F0F0',
  aquaSpring: '#FBFEFC',
  soapstone: '#FFFBFA',
  bombay: '#B0B6BD',
  silver: '#BCBCBC',
  americanSilver: '#D1D1D1',
  cadetBlue: '#ACB4BF',
  dustyGray: '#9B9B9B',
  mineShaft: '#3D3D3D',
  darkCharcoal: '#333333',
  osloGray: '#838A91',
  blackSqueeze: '#F9FBFD',
  mediumAquamarine: '#6FCF97',
  error: '#FF5630',
  apple: '#55CD53',
  maximumYellowRed: '#F5BC4C',
  deepLemon: '#ECC619',
  tartOrange: '#F54C4C',
  pressedAzure: '#1B65C8',
  orangeBlaze: '#F17732',
  jaffa: '#F17833',
  baliHai: '#90A2B1',
  cottonBall: '#F3F7FF',
  pipPin: '#FFDDD5',
  switchedBlue: {
    500: '#067EFE',
    600: '#2F80ED',
  },
  primary: {
    100: '#F1F5FB',
    200: '#E6EFFA', // Figma: Linen
    300: '#A1CEFD',
    400: '#74B7FE',
    500: '#47A0FE', // Figma: Blueberry
    600: '#1A89FF', // Figma: Primary_Azure
    700: '#1F76EB', // Figma: Hover_Azure
    800: '#54A7FF',
  },
  secondary: {
    100: '#EAECEF', // Figma Blue_Bayoux/Secondary 10
    200: '#D8DDE2', // Figma Blue_Bayoux/Secondary 20
    300: '#B4BDC8', // Figma Blue_Bayoux/Secondary 40
    400: '#8F9EAF', // Figma Bali_Hai
    500: '#6B7E95',
    600: '#475F7B', // Figma Blue Bayoux
    700: '#8F9BB3',
  },
  main: {
    100: '#ABB0B5', // Figma: Arsenic 40
    300: '#5A646E', // Figma: Arsenic 80
    400: '#323E4A', // Figma: Arsenic
    500: '#5A607F',
  },
  notFound: {
    100: '#dbdbdb',
    200: '#596469',
    300: '#D2D2D2',
    400: '#4F8CED',
    500: '#828A92',
    600: '#EFEFEF',
    700: '#69727B',
    800: '#BDC2C8',
    900: '#F2F2F2',
    1000: '#DDD',
    1100: '#b2b2b2',
    1200: '#222',
    1300: '#F9F9FC',
    1400: '#6F6F6F',
    1500: '#007bff',
    1600: '#999999',
    1700: '#1989FF',
    1800: '#aeb5bd',
    1900: '#f1f4f7',
    2000: '#F6616A',
    2100: '#C2E2FF',
    2200: '#E9E9E9',
    2300: '#1F8B24',
    2400: '#F0F4F9',
    2500: '#C5F2C7',
    2600: '#FBE5C9',
    2700: '#FCD0CF',
    2800: '#3C8BF4',
    2900: '#FDFEFE',
    3000: '#E56D6D',
    3100: '#E74715',
    3200: '#F07631',
    3300: '#D15814',
    3400: '#51606d',
    3500: '#f5faff',
    3600: '#DFE3E9',
    3700: '#ABB0B6',
    3800: '#f0f4f7',
    3900: '#1D262C',
    4000: '#e2e8f0',
    4100: '#888888',
    4200: '#8D97A5',
    4300: '#377DFF',
    4400: '#38CB89',
    4500: '#FFAB00',
    4600: '#3b5999',
    4700: '#5DA5F1',
    4800: '#E4E4EB',
    4900: '#081F4D',
    5000: '#F7F9FB',
    5100: '#FCEDEC',
    5200: '#FDF6DD',
    5300: '#8be16d',
    5400: '#DD604C',
    5500: '#F4F8FF',
    5600: '#1A202C',
    5700: '#3E7BFA',
    5800: '#DFE1E5',
    5900: '#9CA0A8',
    6000: '#E6EBF0',
    6100: '#487ef5',
    6200: '#FFE8C2',
    6300: '#BBF7DE',
    6400: '#CBBBF7',
    6500: '#273030',
    6600: '#0000000f',
  },
};
