import {
  Box,
  Button,
  Flex,
  Image,
  keyframes,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import React, { FC, useCallback, useState } from 'react';

import RotateIcon from '@app/icons/rotate-icon.svg?react';

interface RecoverButtonProps {
  recoverMutation: (ids: string[]) => Promise<void>;
  selectedItems: string[];
  resetSelectedItems: () => void;
}

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(-360deg); }
`;

export const RecoverButton: FC<RecoverButtonProps> = ({
  selectedItems,
  recoverMutation,
  resetSelectedItems,
}) => {
  const prefersReducedMotion = usePrefersReducedMotion();

  const [isLoading, setIsLoading] = useState(false);
  const durationAnimation = isLoading ? '1s' : '0s';
  const animation = prefersReducedMotion
    ? undefined
    : `${spin} ${durationAnimation} linear infinite`;

  const onRecover = useCallback(async () => {
    setIsLoading(true);
    try {
      await recoverMutation(selectedItems);

      resetSelectedItems();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [recoverMutation, resetSelectedItems, selectedItems]);

  return (
    <Flex m="0 30px 15px">
      <Button
        height="28px"
        isDisabled={isLoading}
        leftIcon={
          <Box animation={animation}>
            <Image as={RotateIcon} />
          </Box>
        }
        p="5px 13px"
        variant="outlined"
        onClick={onRecover}>
        Recover
      </Button>
    </Flex>
  );
};
