import { Box, Divider, Link, PopoverContent, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import ROUTES from '@app/utils/routes';

import { CrmIntegrationFieldsFragment } from '@app/api/gql/generated-types';
import ExportButton from './ExportButton';

interface ExportPopoverContentProps {
  data: CrmIntegrationFieldsFragment[];
  onSubmit: (app: CrmIntegrationFieldsFragment) => void;
  isLoading?: string | boolean;
}

export const ExportPopoverContent: FC<ExportPopoverContentProps> = ({
  data,
  onSubmit,
  isLoading,
}) => {
  return (
    <PopoverContent borderRadius="20px" py="12px">
      <Box pb="8px" px="20px">
        <Text lineHeight="20px" variant="secondary-text">
          Export to CRM
        </Text>
        {data?.map((app) => (
          <ExportButton
            key={app.id}
            isDisabled={app.id === isLoading}
            isLoading={app.id === isLoading}
            name={app.name}
            onClick={() => onSubmit(app)}
          />
        ))}
      </Box>
      <Divider />
      <Box pt="11px" px="20px">
        <Link
          as={RouteLink}
          fontSize="14px"
          textDecoration="none"
          to={`${ROUTES.settingsApi}`}
          variant="textLink">
          Set up API
        </Link>
      </Box>
    </PopoverContent>
  );
};
