import { Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';

export const TableHead = () => {
  return (
    <Thead position="sticky" top="0" zIndex="10">
      <Tr h="49px">
        <Th>Numbers</Th>
        <Th>Capabilities</Th>
        <Th>Price</Th>
        <Th />
      </Tr>
    </Thead>
  );
};
