import React, { FC, useMemo, useContext } from 'react';
import { Divider, Flex, Switch, Text } from '@chakra-ui/react';

import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';
import {
  useAccountQuery,
  useUpdateMessagingProfileMutation,
} from '@app/api/gql/generated-types';
import { PageTitle } from '@app/components/PageTitle';
import { usePlanPermission } from '@app/hooks/usePlanPermission';
import { UpdatePlanDialer } from '@app/shared/ui/CommonTooltips/UpdatePlanDialer';

import { useUpdateMessagingProfileFragment } from '@app/api/hooks/useUpdateMessagingProfileFragment';
import { SettingsLayout } from '@app/layouts/SettingsLayout';
import { ActiveIncoming } from './components/ActiveIncoming';
import { CallAutoReply } from './components/CallAutoReply';

import { PhoneNumbers } from './components/PhoneNumbers';
import Tooltip from './components/Tooltip';
import { VoiceMail } from './components/Voicemail';

const CallingSetup: FC = () => {
  const { data: { account } = {} } = useAccountQuery({
    fetchPolicy: 'network-only',
  });

  const { hasDialerSubscriptionAsInactive, isWithoutDialerSubscription } =
    useContext(CurrentAccountContext);

  const isDialerInactive =
    isWithoutDialerSubscription || hasDialerSubscriptionAsInactive;

  const { updateCallSettingsFragment } = useUpdateMessagingProfileFragment();
  const [updateMessagingProfile] = useUpdateMessagingProfileMutation();

  const { isPrimitivePlans } = usePlanPermission();

  const isSipEnabled = useMemo(
    () => account?.messagingProfile?.callSettings?.isSipEnabled ?? false,
    [account?.messagingProfile?.callSettings?.isSipEnabled],
  );

  const handleIncomingCall = async () => {
    updateCallSettingsFragment({
      id: account?.messagingProfile?.id,
      callSettings: {
        isSipEnabled: !isSipEnabled,
      },
    });
    await updateMessagingProfile({
      variables: {
        input: {
          callSettings: {
            isSipEnabled: !isSipEnabled,
          },
        },
      },
    });
  };

  return (
    <>
      <PageTitle title="Calling setup" />
      <Flex
        direction="column"
        maxW="680px"
        padding={['8px 11px 0', '0 30px 0']}>
        <Text variant="sub-heading">Calling setup</Text>
        <ActiveIncoming />
        <SettingsLayout
          alignItems="baseline"
          childComponent={
            <Switch
              colorScheme="switchedBlue"
              h="20px"
              isChecked={isSipEnabled}
              isDisabled={isDialerInactive}
              w="38px"
              onChange={handleIncomingCall}
            />
          }
          iconComponent={
            isPrimitivePlans ? (
              <Tooltip text={<UpdatePlanDialer />} widthContainer="200px" />
            ) : (
              <Tooltip
                text="You can receive incoming calls in the web application"
                widthContainer="200px"
              />
            )
          }
          marginTop="10px"
          titleLabel="Incoming calls"
        />
        <>
          <VoiceMail isDialerPlanInactive={isDialerInactive} />
          <CallAutoReply isDialerPlanInactive={isDialerInactive} />
        </>
        <Divider bg="mystic" mt="30px" width="620px" />
      </Flex>
      <Flex direction="column" maxW="644px" padding={['0 11px', '10px 30px 0']}>
        <PhoneNumbers isDialerPlanInactive={isDialerInactive} />
      </Flex>
    </>
  );
};

export default CallingSetup;
