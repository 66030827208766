import {
  Avatar,
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'reactfire';

import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import { usePlanPermission } from '@app/hooks/usePlanPermission';
import LogOutIcon from '@app/icons/logout-icon.svg?react';
import UserIcon from '@app/icons/user-icon.svg?react';
import { PopoverLinkProfile } from '@app/navigation/styles';
import ROUTES from '@app/utils/routes';
import MemoSvgImageIcon from '@app/utils/svgImageIcon';
import { getContactNames } from '@app/utils/contact';
import {
  formatBusinessPlanName,
  formatEnterprisePlanName,
} from '@app/utils/enterprisePlanUtils';
import { getMessageSubscription } from '@app/utils/subscriptionHelpers';
import { useSignOut } from '@app/hooks/useSignOut';

export const AvatarPopover: FC = () => {
  const { signOut } = useSignOut();
  const navigate = useNavigate();
  const { isEnterprisePlan } = usePlanPermission();

  const { data: user } = useUser();
  const account = useCurrentAccountData();
  const messageSubscription = getMessageSubscription(account);

  const { fullName } = getContactNames(account?.name);

  const planName = useMemo<string | undefined>(() => {
    if (!messageSubscription) {
      return;
    }

    return isEnterprisePlan ? 'Enterprise' : messageSubscription?.plan?.title;
  }, [messageSubscription, isEnterprisePlan]);

  return (
    <Popover isLazy matchWidth offset={[-200, 9]} placement="bottom-end">
      <PopoverTrigger>
        <Avatar
          cursor="pointer"
          height="40px"
          name={fullName?.trim()}
          src={user?.photoURL}
          width="40px"
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          border="0"
          filter="drop-shadow(0px 4px 30px rgba(94, 94, 94, 0.25))"
          width="240px">
          <PopoverHeader>
            <Flex
              direction="column"
              justifyContent="center"
              my="10px"
              textAlign="center"
              width="100%">
              <Text
                color="secondary.600"
                lineHeight="16px"
                mb="2px"
                variant="heading-5">
                {fullName}
              </Text>

              {planName && (
                <Text
                  color="secondary.300"
                  fontSize="12px"
                  fontWeight={400}
                  lineHeight="14px"
                  textTransform="capitalize">
                  {isEnterprisePlan
                    ? formatEnterprisePlanName(planName)
                    : formatBusinessPlanName(planName)}{' '}
                  plan
                </Text>
              )}
            </Flex>
          </PopoverHeader>

          <PopoverArrow
            boxShadow="0 0 0 transparent!important"
            ml="-8px"
            zIndex="2"
          />
          <PopoverBody
            data-cy="avatar-popover-window"
            p="16px 20px 2px"
            zIndex="10000000">
            <PopoverLinkProfile
              onClick={() => navigate(`${ROUTES.settingsProfile}`)}>
              <Flex alignItems="center">
                <Box data-cy="my-profile-button" mr="10px">
                  <MemoSvgImageIcon color="main.400" svgIcon={UserIcon} />
                </Box>
                My Profile
              </Flex>
            </PopoverLinkProfile>
            <PopoverLinkProfile onClick={signOut}>
              <Flex alignItems="center">
                <Box data-cy="sign-out-button" mr="10px">
                  <LogOutIcon />
                </Box>
                Sign Out
              </Flex>
            </PopoverLinkProfile>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
