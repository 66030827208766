import React, { FC } from 'react';
import { Flex } from '@chakra-ui/react';

import { wysiwygActions } from '../config/actions';
import { WysiwygHeaderProps } from '../config/types';

import { WysiwygControl } from './WysiwygAction';

export const WysiwygHeader: FC<WysiwygHeaderProps> = ({
  onCommandClick,
  disabled,
}) => {
  return (
    <Flex
      alignItems="center"
      backgroundColor={disabled ? 'catskillWhite' : 'white'}
      borderBottom="1px solid"
      borderBottomColor="secondary.200"
      height="40px"
      padding="0 15px">
      {wysiwygActions.map((action) => (
        <WysiwygControl
          key={action.command}
          action={action}
          disabled={disabled}
          onCommandClick={onCommandClick}
        />
      ))}
    </Flex>
  );
};
