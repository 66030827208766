import { Skeleton, Td } from '@chakra-ui/react';
import React from 'react';
import { colors } from '@app/theme/colors';

export const EditSkeletonRow = () => {
  return (
    <Td>
      <Skeleton
        endColor={colors.cultured}
        height="14px"
        startColor={colors.mystic}
      />
    </Td>
  );
};
