import { useCallback, useState } from 'react';

interface UseEntitiesPicker<T> {
  select: (value: T) => void;
  selectAll: (values: T[]) => void;
  reset: () => void;
  items: T[];
}

export const useEntitiesPicker = <T extends string>(
  defaultItems: T[] = [],
): UseEntitiesPicker<T> => {
  const [items, setItems] = useState<T[]>(defaultItems);

  const select = useCallback((value: T) => {
    setItems((prevItems: T[]) =>
      prevItems.find((item) => item === value)
        ? [...prevItems.filter((item) => item !== value)]
        : [...prevItems, value],
    );
  }, []);

  const selectAll = useCallback((values: T[]) => {
    setItems(values);
  }, []);

  const reset = useCallback(() => setItems([]), []);

  return {
    select,
    selectAll,
    reset,
    items,
  };
};
