import { useContext } from 'react';

import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';

import { usePopupsContext } from './usePopupsContext';

/**
 *  @deprecated Use the new permission Features & permission modal. .waiting for new modal designs from design team
 * */
const useActivateAccountGuard = (
  handler: (...args: any) => void | Promise<void>,
) => {
  const {
    isActiveWithActiveDialerPlan,
    isActiveWithActiveMessagePlan,
    isMessagePlanTrialing,
  } = useContext(CurrentAccountContext);
  const {
    activateAccountModal: { onOpen },
  } = usePopupsContext();

  if (
    isMessagePlanTrialing ||
    isActiveWithActiveMessagePlan ||
    isActiveWithActiveDialerPlan
  ) {
    return handler;
  }

  return onOpen;
};

export default useActivateAccountGuard;
