/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import React, { FC, useCallback, useMemo } from 'react';
import { Button, Flex, Image } from '@chakra-ui/react';

import CheckDoneBlueIcon from '@app/icons/check-done-blue.svg';
import { Header } from '@app/types/Contact';
import { Field, SkiptraceField } from '@app/api/gql/generated-types';
import { normalizeNameString } from '@app/utils/string';
import { colors } from '@app/theme/colors';

interface HeaderSelectItemType {
  item: Field | SkiptraceField;
  headers: Header[] | string[];
  onSelect: () => void;
  onClose: () => void;
}

export const HeaderSelectItem: FC<HeaderSelectItemType> = ({
  item,
  headers,
  onSelect,
  onClose,
}) => {
  const selected = useMemo(
    () =>
      headers.some(
        (header) => header === item.id && item.name !== Header.PHONE,
      ),
    [headers, item.id, item.name],
  );

  const selectedPhoneCount = useMemo(
    () =>
      headers.filter(
        (header) => header === item.id && item.name === Header.PHONE,
      )?.length ?? null,
    [headers, item.id, item.name],
  );

  const isDisabled = useMemo(
    () => selected || selectedPhoneCount > 3,
    [selected, selectedPhoneCount],
  );

  const handleClick = useCallback(() => {
    onSelect();
    onClose();
  }, [onClose, onSelect]);

  const renderRightIcon = useMemo(() => {
    if (selectedPhoneCount) {
      return (
        <Flex
          alignItems="center"
          bg="primary.200"
          borderRadius="full"
          color="secondary.400"
          fontSize="12px"
          fontWeight="700"
          height="24px"
          justifyContent="center"
          width="24px">
          {selectedPhoneCount}
        </Flex>
      );
    }

    if (selected) {
      return (
        <Image
          alt="check done"
          bg="primary.200"
          borderRadius="full"
          boxSize="24px"
          p="5px"
          src={CheckDoneBlueIcon}
        />
      );
    }

    return null;
  }, [selected, selectedPhoneCount]);

  return (
    <Button
      _active={{ bg: colors.transparent, color: 'primary.700' }}
      _focus={{ bg: colors.transparent }}
      _hover={{ bg: colors.transparent, color: 'primary.700' }}
      _last={{ marginBottom: '0px' }}
      alignItems="center"
      background={colors.transparent}
      color={isDisabled ? 'secondary.300' : 'main.400'}
      cursor="pointer"
      fontSize="14px"
      fontWeight={400}
      height="20px"
      justifyContent="space-between"
      lineHeight="21px"
      margin="0 2px 20px"
      p="0"
      rightIcon={renderRightIcon}
      transition="all .3s ease"
      width="100%"
      onClick={handleClick}>
      {normalizeNameString(item.name)}
    </Button>
  );
};
