import React, { createContext, FC, ReactNode, useContext } from 'react';
import { useLocation } from 'react-router-dom';

interface CampaignsContextValue {
  isDuplicated: boolean;
}

const CampaignsContext = createContext<CampaignsContextValue | undefined>(
  undefined,
);

export const useCampaignsContext = () => {
  const context = useContext(CampaignsContext);
  if (!context) {
    throw new Error('useCampaigns must be used within a CampaignsProvider');
  }
  return context;
};

export const CampaignsProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const isDuplicated = location.pathname.includes('duplicate');

  return (
    <CampaignsContext.Provider value={{ isDuplicated }}>
      {children}
    </CampaignsContext.Provider>
  );
};
