import styled from '@emotion/styled';
import { colors } from '@app/theme/colors';

export const StylesTable = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.main[400]};
  overflow-y: auto;

  table {
    flex-direction: column;
    border-collapse: collapse;
    width: 100%;

    * {
      font-style: normal;
      font-weight: normal;
      line-height: 16px;
    }
    tr {
      height: 50px;

      td {
        color: ${colors.darkCharcoal};
        font-size: 14px;
        &:first-child {
          padding-right: 15px;
        }
      }
      td:nth-child(2) {
        padding-left: 0px;
      }
    }
  }

  th {
    white-space: nowrap;
    background: ${colors.catskillWhite};
    font-size: 14px;
    color: ${colors.osloGray};
    text-align: left;
    padding: 16px 24px;
    position: sticky;
    top: 0;
    z-index: 10;
    &:first-child {
      padding-right: 15px;
    }
  }
  th:nth-child(2) {
    padding-left: 0px;
  }
`;
