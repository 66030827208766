import React, { FC, SyntheticEvent, useCallback } from 'react';
import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverFooter,
  PopoverBody,
  Portal,
  Button,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash/fp';

import { Label } from '@app/api/gql/generated-types';

import { UpdateMultiLabels } from '@app/pages/Messenger/types';
import { MultiLabelsCheckbox } from './MultiLabelsCheckbox';

interface MultiLabelViewContainerProps {
  type: UpdateMultiLabels;
  labels: Label[];
  selectedLabels: Label[];
  isLoading: boolean;
  children: MultiLabelViewChildren;
  onChange: (labels: Label) => void;
  onSave: () => Promise<void>;
  onClose: () => void;
  fetchNextPage: () => void;
}

interface MultiLabelViewChildren {
  (props: { isOpen: boolean; onClose: () => void }): JSX.Element;
}

export const MultiLabelViewContainer: FC<MultiLabelViewContainerProps> = ({
  type,
  labels,
  selectedLabels,
  isLoading,
  children,
  onChange,
  onSave,
  onClose,
  fetchNextPage,
}) => {
  const loadMore = useCallback(
    (e: SyntheticEvent) => {
      const isBottomReached =
        Math.ceil(e.currentTarget.scrollTop + e.currentTarget.clientHeight) >=
        e.currentTarget.scrollHeight;

      if (isBottomReached) {
        fetchNextPage();
      }
    },
    [fetchNextPage],
  );

  const handleSave = async () => {
    await onSave();
    onClose();
  };

  return (
    <Popover
      isLazy
      matchWidth
      offset={[2, 2]}
      placement="bottom"
      trigger="click"
      onClose={onClose}>
      {({ isOpen, onClose: localOnClose }) => (
        <>
          <PopoverTrigger>
            <Box onClick={(event) => event.stopPropagation()}>
              {children({ isOpen, onClose: localOnClose })}
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              _focus={{ outline: 'none' }}
              border="1px solid"
              borderColor="secondary.200"
              borderRadius="9px"
              cursor="default"
              maxHeight="327px"
              maxWidth="260px"
              outline="none"
              padding="15px 10px"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onScrollCapture={loadMore}>
              <PopoverBody overflowY="auto">
                <MultiLabelsCheckbox
                  defaultValues={selectedLabels}
                  options={labels}
                  onChange={onChange}
                />
              </PopoverBody>
              <PopoverFooter borderTop="none" paddingX="5px">
                <Button
                  isDisabled={isEmpty(selectedLabels)}
                  isLoading={isLoading}
                  maxHeight="27px"
                  variant="outlined"
                  width="220px"
                  onClick={handleSave}>
                  {`${
                    type === UpdateMultiLabels.ADD ? 'Add' : 'Remove'
                  } labels`}
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
