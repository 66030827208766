import React, { FC, useMemo } from 'react';
import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import { FormikProps, useFormikContext } from 'formik';

import { usePlanPermission } from '@app/hooks/usePlanPermission';
import video from '@app/utils/videoLinks';

import { ListOptions } from '@app/api/gql/generated-types';
import StepDoneIcon from '@app/icons/step-done-icon.svg?react';
import { DisclaimerBox } from '@app/components/next/moleculas/DisclaimerBox';
import { CheckboxWithPopover } from './CheckboxWithPopover';

export const ContactFilters: FC = () => {
  const { isOldElite, isAllNewPlans, isDialerPlan } = usePlanPermission();

  const formik: FormikProps<ListOptions> = useFormikContext();

  const resetFlags = async () => {
    const fields = [
      'removeInternalDnc',
      'removeFiltered',
      'removeExisting',
      'removeNationalDnc',
      'removeGlobalUnsubscribed',
    ];
    const fieldsValues = fields.reduce(
      (acc: Record<string, boolean>, field) => {
        acc[field] = false;
        return acc;
      },
      {},
    );

    await formik.setValues({
      ...formik.values,
      ...fieldsValues,
    });
  };

  const description = useMemo(() => {
    if (isAllNewPlans || isDialerPlan) {
      return '';
    }
    if (isOldElite) {
      return 'Free for Elite users. ';
    }
    return '$0.005 per phone. ';
  }, [isAllNewPlans, isOldElite, isDialerPlan]);

  return (
    <Box>
      <DisclaimerBox
        backgroundColor="catskillWhite"
        color="arsenic"
        icon={StepDoneIcon}
        p="15px">
        <Flex flexDirection="column" pl="10px">
          <Box>
            <Text
              as="span"
              color="arsenic.60"
              fontSize="14px"
              fontWeight="bold"
              lineHeight="normal">
              Phone number verification
            </Text>{' '}
            <Text
              as="span"
              color="arsenic.60"
              fontSize="14px"
              lineHeight="16px">
              (Required)
            </Text>
          </Box>
          <Box>
            <Text
              color="main.400"
              fontSize="12px"
              fontStyle="normal"
              fontWeight="normal"
              lineHeight="14px">
              {description}
              <Link
                color="primary.600"
                href={video.contacts.phoneVerification}
                rel="noopener noreferrer"
                target="_blank">
                Watch tutorial video
              </Link>{' '}
              to understand how it works.
            </Text>
          </Box>
        </Flex>
      </DisclaimerBox>

      <Text
        color="main.400"
        fontSize="14px"
        fontStyle="normal"
        fontWeight="normal"
        mb="24px"
        mt="20px">
        Select your desired contact filters.
      </Text>

      <Flex direction="row" justify="between">
        <Flex direction="column" gap="20px" whiteSpace="nowrap" width="180px">
          <CheckboxWithPopover
            isDisabled
            isChecked={formik.values.removeUnsubscribed}
            name="removeUnsubscribed"
            popoverText="Remove phone numbers that previously opted out in one of your previous campaigns."
            text="Opted out"
          />
          <CheckboxWithPopover
            isDisabled
            isChecked={formik.values.removeLitigators}
            name="removeLitigators"
            popoverText="Remove phone numbers of previous litigators, their associates, and relatives."
            text="Litigators"
          />
          <CheckboxWithPopover
            isDisabled
            isChecked={formik.values.removeCarrierBlocked}
            name="carrierBlocked"
            popoverText="Remove phone numbers that have been blocked by carriers."
            text="Carrier blocked"
          />

          <CheckboxWithPopover
            isDisabled
            isChecked={formik.values.removeUndeliverable}
            name="undeliverable"
            popoverText="Remove phone numbers previously marked as undeliverable."
            text="Undeliverable"
          />

          <CheckboxWithPopover
            defaultChecked={false}
            isChecked={formik.values.removeGlobalUnsubscribed}
            name="removeGlobalUnsubscribed"
            popoverText="Remove phone numbers that have opted out of campaigns from all Smarter Contact customer accounts."
            text="Global opted out"
            onChange={formik.handleChange}
          />
        </Flex>

        <Flex direction="column" gap="20px" pl="30px">
          <CheckboxWithPopover
            isChecked={formik.values.removeNationalDnc}
            name="removeNationalDnc"
            popoverText="Remove phone numbers that have been added to the National DNC list."
            text="National DNC list"
            onChange={formik.handleChange}
          />

          <CheckboxWithPopover
            isChecked={formik.values.removeInternalDnc}
            name="removeInternalDnc"
            popoverLinkText="Internal DNC list."
            popoverText="Remove phone numbers that you’ve added to your"
            text="Internal DNC list"
            onChange={formik.handleChange}
          />

          <CheckboxWithPopover
            isChecked={formik.values.removeFiltered}
            name="removeFiltered"
            popoverLinkText="keywords."
            popoverText="Remove phone numbers from campaigns that have responded to your messages with any of your predefined"
            text="Response filtration"
            onChange={formik.handleChange}
          />

          <CheckboxWithPopover
            isChecked={formik.values.removeExisting}
            name="removeExisting"
            popoverText="Remove phone numbers that belong to existing contacts."
            text="Existing contacts"
            onChange={formik.handleChange}
          />

          <Flex alignItems="center">
            <Button variant="link" onClick={resetFlags}>
              Clear All
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
