import React, { FC } from 'react';
import { Button } from '@chakra-ui/react';

import { LoadMoreSecondaryButtonProps } from './types';

export const LoadMoreSecondaryButton: FC<LoadMoreSecondaryButtonProps> = ({
  isLoading,
  fetchNextPage,
  hasNextPage,
  isFullWidth = false,
}) => {
  if (hasNextPage && !isLoading) {
    return (
      <Button
        h="37px"
        loadingText="Load more"
        variant="outlined"
        width={isFullWidth ? 'full' : '190px'}
        onClick={fetchNextPage}>
        Load more
      </Button>
    );
  }

  return null;
};
