import { Box, Flex, Switch, Text } from '@chakra-ui/react';
import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';

import ChargeCustom from '@app/components/ChargeModal/ChargeCustom';
import ChargeSelect, {
  Option,
  SingleValue,
} from '@app/components/ChargeModal/ChargeSelect';
import { rechargeOptions } from '@app/components/ChargeModal/data';
import Label from '@app/components/FormItem/Label';
import Popup from '@app/components/Popup';
import SelectField from '@app/components/SelectField';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import { autoRechargeSchema } from '@app/schemas/auto-recharge-schema';

import {
  AccountDocument,
  useScheduleBalanceMutation,
} from '@app/api/gql/generated-types';
import { TrialWarning } from '@app/components/ChargeModal/TrialWarning';
import Actions from './Actions';
import {
  AutoRechargeFormProps,
  AutoRechargeModalProps,
  LowOptionsMap,
  OptionProps,
} from './types';

const lowOptions: OptionProps[] = Array(20)
  .fill(0)
  .map((_, i) => ({
    label: `${(i + 1) * 10}`,
    value: (i + 1) * 10,
  }));

const lowOptionsMap: LowOptionsMap = lowOptions.reduce(
  (result, item) => ({ ...result, [item.value]: item }),
  {},
);

const customAmountValue = { value: 5, label: 'Custom Amount' };

export const AutoRechargeModal: FC<AutoRechargeModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [mutate, { loading: isLoading }] = useScheduleBalanceMutation({
    refetchQueries: [AccountDocument],
  });
  const account = useCurrentAccountData();
  const { billingProfile } = account;

  const customAmount =
    billingProfile?.balance?.schedule?.amount &&
    !rechargeOptions.find(
      (val) => val.value === billingProfile?.balance?.schedule?.amount / 100,
    );

  const toBalanceDefaultValue = (toBalance: number) =>
    customAmount
      ? customAmountValue
      : rechargeOptions.find((val) => val.value === toBalance);

  const initialValues: AutoRechargeFormProps = {
    limit: billingProfile?.balance?.schedule?.limit / 100 ?? null,
    amount: billingProfile?.balance?.schedule?.amount / 100 ?? null,
    enabled: billingProfile?.balance?.schedule?.enabled ?? false,
    custom: customAmount,
  };

  const onSubmit = useCallback(
    async (values: AutoRechargeFormProps) => {
      await mutate({
        variables: {
          input: {
            limit: values.limit * 100,
            amount: values.amount * 100,
            enabled: values.enabled,
          },
        },
        context: {
          notify: {
            success: () =>
              values?.enabled
                ? 'Auto recharge turned on'
                : 'Auto recharge turned off',
            error: () => 'Error enabling auto-recharge',
          },
        },
      });
      onClose();
    },
    [mutate, onClose],
  );

  return (
    <Popup
      blockScrollOnMount
      isOpen={isOpen}
      maxW="380px"
      modalOverplayProps={{ backdropFilter: 'blur(10px)' }}
      title="Confirm auto recharge"
      onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={autoRechargeSchema}
        onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Box margin={['0px 20px', '20px 45px 32px']}>
            <Text
              mb={['18px', '22px']}
              textAlign="center"
              variant="secondary-text">
              We’ll charge your only payment method when your balance falls
              bellow the amount you set.
            </Text>
            <Flex
              alignItems="center"
              justifyContent={['space-between', 'start']}
              mb="20px">
              <Text variant="heading-fifth">Auto recharge</Text>
              <Switch
                _focus={{ outline: 'none', boxShadow: 'none' }}
                isChecked={values.enabled}
                ml="30px"
                variant="general"
                onChange={() => setFieldValue('enabled', !values.enabled)}
              />
            </Flex>
            <Label>Recharge my balance with</Label>
            <ChargeSelect
              isShowBonusLabel
              defaultValue={toBalanceDefaultValue(values.amount)}
              fieldName="amount"
              isDisabled={!values.enabled}
            />

            {values.custom && (
              <>
                <Label>Add custom amount</Label>
                <ChargeCustom
                  showBonusLabel
                  fieldName="amount"
                  isDisabled={!values.enabled}
                />
              </>
            )}
            <Label>When balance falls below</Label>
            <Box marginBottom="18px">
              <SelectField
                components={{ Option, SingleValue }}
                isDisabled={!values.enabled}
                options={lowOptions}
                value={lowOptionsMap[values.limit]}
                // @ts-expect-error Select mismatch between value and onChange and options
                onChange={(value) => setFieldValue('limit', value.value)}
              />
            </Box>

            <TrialWarning onClose={onClose} />

            <Actions isLoading={isLoading} onClose={onClose} />
          </Box>
        )}
      </Formik>
    </Popup>
  );
};
