import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Flex, Text } from '@chakra-ui/react';
import { extendWithCurrentQueryParams } from '@app/utils/navigationUtils';
import { useSignOut } from '@app/hooks/useSignOut';
import ROUTES from '../../utils/routes';

export const AuthHeader: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut, currentUser } = useSignOut();

  const handleNavigate = (route: string) => async () => {
    if (!!currentUser) {
      await signOut();
      return;
    }

    navigate(extendWithCurrentQueryParams(route));
  };

  const headerConfig = {
    [ROUTES.login]: {
      text: "Don't have an account?",
      buttonLabel: 'Sign up',
      onClick: handleNavigate(ROUTES.signUp),
    },
    [ROUTES.signUp]: {
      text: 'Already have an account?',
      buttonLabel: 'Log in',
      onClick: handleNavigate(ROUTES.login),
    },
    [ROUTES.securityPhoneVerification]: {
      text: 'Already have an account?',
      buttonLabel: 'Log in',
      onClick: handleNavigate(ROUTES.login),
    },
    [ROUTES.forgotPassword]: {
      text: 'Remember your password?',
      buttonLabel: 'Log in',
      onClick: handleNavigate(ROUTES.login),
    },
    [ROUTES.changePassword]: {
      text: 'Remember password?',
      buttonLabel: 'Sign In',
      onClick: handleNavigate(ROUTES.login),
    },
  };

  const config = headerConfig[location.pathname];
  if (!config) return null;

  return (
    <Flex alignItems="center">
      <Text
        color="main.400"
        display={['none', 'block']}
        fontSize="12px"
        fontWeight="400"
        mb={['10px', '0']}
        mr={['0', '20px']}>
        {config.text}
      </Text>
      <Button
        border="1px"
        borderColor="primary.600"
        borderRadius="20px"
        className="gtm-go_event_button"
        color="primary.600"
        data-click-location="header"
        data-go-event-type={
          config.buttonLabel === 'Sign up' ? 'go_registration' : 'go_sign_in'
        }
        variant="outline"
        width="102px"
        onClick={config.onClick}>
        {config.buttonLabel}
      </Button>
    </Flex>
  );
};
