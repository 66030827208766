export enum TStdSymbol {
  DASH = '-',
  UNDERSCORE = '_',
  DOT = '.',
  COMMA = ',',
  SEMICOLON = ';',
  COLON = ':',
  SPACE = ' ',
  SLASH = '/',
  BACKSLASH = '\\',
  PIPE = '|',
  AMPERSAND = '&',
  AT = '@',
  HASH = '#',
  DOLLAR = '$',
  PERCENT = '%',
  CARET = '^',
  ASTERISK = '*',
  PLUS = '+',
  EQUALS = '=',
  EXCLAMATION = '!',
  QUESTION = '?',
  TILDE = '~',
  BACKTICK = '`',
  QUOTE = '"',
  SINGLE_QUOTE = "'",
  OPEN_PARENTHESIS = '(',
  CLOSE_PARENTHESIS = ')',
  OPEN_BRACKET = '[',
  CLOSE_BRACKET = ']',
  OPEN_BRACE = '{',
  CLOSE_BRACE = '}',
  LESS_THAN = '<',
  GREATER_THAN = '>',
  SLASH_ASTERISK = '/*',
  ASTERISK_SLASH = '*/',
  SLASH_SLASH = '//',
  NEW_LINE = '\n',
  CARRIAGE_RETURN = '\r',
  TAB = '\t',
  VERTICAL_TAB = '\v',
  FORM_FEED = '\f',
  BACKSPACE = '\b',
  NULL = '\0',
  ESCAPE = '\x1B',
}

export const concatenateStrings = (
  separator: string | TStdSymbol = TStdSymbol.SPACE as string,
  ...strings: Array<string | null | undefined>
): string =>
  strings
    .filter((str) => str !== undefined && str !== null && str !== '')
    .map((s) => s.trim())
    .join(separator);
