import React, { FC } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { Box, Flex, Link, ListIcon, Text } from '@chakra-ui/react';
import Popover from '@app/components/Popover';
import ROUTES from '@app/utils/routes';
import PhoneMissed from '@app/icons/phone-missed-icon.svg?react';
import { usePlanPermission } from '@app/hooks/usePlanPermission';
import InfoIcon from '@app/icons/InfoIcon.svg?react';
import { CounterBadge } from '@app/components/next/moleculas/CounterBadge';
import { INBOX } from '../../routes';
import { SideBarLinkSubMenu } from './styles';

type IMissedCallsLinkProps = {
  accountId: string;
  missedCallsConversationsCounter: number;
};

const popoverContent = (
  <Text variant="heading-fifth" width="200px">
    Our calling features are available on{' '}
    <Link as={RouteLink} to={`${ROUTES.settingsMembership}`} variant="link">
      upgraded plans
    </Link>
  </Text>
);

export const MissedCallsLink: FC<IMissedCallsLinkProps> = ({
  accountId,
  missedCallsConversationsCounter,
}) => {
  const { isBasicPlan } = usePlanPermission();

  return (
    <Box position="relative">
      <SideBarLinkSubMenu
        className={`showCounter${isBasicPlan ? ' isDisabled' : ''}`}
        to={`${INBOX.missedCalls}${accountId}`}>
        <Flex>
          <ListIcon as={PhoneMissed} />
          <Text>Missed calls</Text>
        </Flex>
        <CounterBadge counter={missedCallsConversationsCounter} />
      </SideBarLinkSubMenu>
      {isBasicPlan && (
        <Popover
          arrowPadding={20}
          arrowShadowColor="0px 6px 18px rgba(139, 139, 139, 0.25)"
          borderRadius="5px"
          offset={[-26, 143]}
          p="10px"
          placement="right"
          popoverText={popoverContent}
          trigger="hover"
          triggerElement={
            <Box
              color="secondary.300"
              cursor="pointer"
              position="absolute"
              right="58px"
              top="12px">
              <InfoIcon />
            </Box>
          }
          width="25px"
          widthContainer="200px"
        />
      )}
    </Box>
  );
};
