import React, { CSSProperties, FC } from 'react';

import { RenderElementProps } from 'slate-react';

export const WysiwygElement: FC<RenderElementProps> = ({
  attributes,
  children,
  element,
}) => {
  const style: CSSProperties = { textAlign: element.align };

  return (
    <p style={style} {...attributes}>
      {children}
    </p>
  );
};
