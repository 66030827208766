import { Auth, PhoneAuthProvider, RecaptchaVerifier } from 'firebase/auth';
import { getLogger } from '@app/utils/loggerUtils';

const LOG = getLogger('Verification');

/**
 * The ID of the HTML element where reCAPTCHA will be rendered.
 */
export const RECAPTCHA_NODE_ID = 'recaptcha-container';

/**
 * Initializes and returns a RecaptchaVerifier instance.
 * @param auth - Firebase Auth instance.
 * @param callback - Optional callback function to run on successful verification.
 * @returns A RecaptchaVerifier instance.
 */
export const getRecaptchaVerifier = (
  auth: Auth,
  cb?: () => void,
): RecaptchaVerifier => {
  return new RecaptchaVerifier(
    RECAPTCHA_NODE_ID,
    {
      size: 'invisible',
      callback: () => cb?.(),
    },
    auth,
  );
};

export const getPhoneAuthProvider = (auth: Auth) => new PhoneAuthProvider(auth);

export const verifyPhoneNumber = async (
  recaptcha: RecaptchaVerifier,
  auth: Auth,
  phoneNumber: string,
): Promise<string> => {
  const provider = getPhoneAuthProvider(auth);

  LOG.debug('verifying phone number', phoneNumber);

  return provider.verifyPhoneNumber(phoneNumber, recaptcha);
};
