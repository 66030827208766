import { Box, Grid, Skeleton } from '@chakra-ui/react';
import React from 'react';
import { colors } from '@app/theme/colors';

export const Header = () => {
  return (
    <Box
      background={colors.notFound[2400]}
      h="50px"
      padding="15px 30px"
      width="100%">
      <Grid columnGap="30px" gridTemplateColumns="repeat(8, 1fr)" rowGap="15px">
        {Array(8)
          .fill(0)
          .map((_, i) => (
            <Skeleton
              key={`header-row-${i.toString()}`}
              endColor={colors.cultured}
              height="14px"
              startColor={colors.mystic}
              width="100%"
            />
          ))}
      </Grid>
    </Box>
  );
};
