import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { convertToPastRelativeDate } from '@app/utils/formatDate';
import {
  useMarkNotificationsReadMutation,
  Notification,
  NotificationFragmentDoc,
} from '@app/api/gql/generated-types';
import * as typenames from '@app/api/typenames';
import { colors } from '@app/theme/colors';
import { NotificationMetaItem } from '../NotificationsPopoverContent/utils';

interface NotificationsPopoverContentItemProps {
  item: NotificationMetaItem;
  clickedUnread: boolean;
}

export const NotificationsPopoverContentItem: FC<
  NotificationsPopoverContentItemProps
> = ({ item, clickedUnread }) => {
  const navigate = useNavigate();

  //it checks the color of a read item in the all list
  const getTitleItemColor = (metaItem: NotificationMetaItem): string => {
    return !clickedUnread && metaItem.isRead ? colors.osloGray : 'main.400';
  };
  const getTitleItemFontWeight = (metaItem: NotificationMetaItem): string => {
    return !clickedUnread && metaItem.isRead ? '400' : '500';
  };
  const getDescriptionItemColor = (metaItem: NotificationMetaItem): string => {
    return !clickedUnread && metaItem.isRead ? 'main.100' : 'main.300';
  };
  const getIconItemColor = (metaItem: NotificationMetaItem): string => {
    //in all tab and it is read
    return !clickedUnread && metaItem?.isRead
      ? colors.secondary[200]
      : colors.osloGray;
  };

  const IconComponent = item.icon;

  const [mutateSetRead] = useMarkNotificationsReadMutation();

  const redirectTo = () => {
    if (!item.isRead) {
      void mutateSetRead({
        variables: {
          input: {
            id: item.id,
          },
        },
        update(cache) {
          cache.updateFragment<Notification>(
            {
              id: `${typenames.Notification}:${item.id}`,
              fragment: NotificationFragmentDoc,
              fragmentName: typenames.Notification,
            },
            (data) => ({
              ...data,
              isRead: true,
            }),
          );
        },
      });
    }
    if (item.linkTo) {
      navigate(item.linkTo);
    }
  };

  return (
    <Flex
      key={item.id}
      _hover={{
        bg: item.isRead ? 'primary.100' : 'primary.200',
        borderRadius: '12px',
      }}
      color={!clickedUnread && item.isRead ? 'osloGray' : ''}
      cursor="pointer"
      gap="10px"
      p="12px"
      onClick={redirectTo}>
      {IconComponent && (
        <Box flexGrow="0" flexShrink="0" width="24px">
          <IconComponent color={getIconItemColor(item)} />
        </Box>
      )}

      <Box flexGrow="1" lineHeight="normal">
        <Text
          color={getTitleItemColor(item)}
          fontSize="14px"
          fontWeight={getTitleItemFontWeight(item)}
          mb="4px">
          {item.title}
        </Text>
        <Text
          color={getDescriptionItemColor(item)}
          fontSize="12px"
          fontWeight="400"
          mb="6px">
          {item.description}
        </Text>
        <Flex alignItems="center" gap="8px">
          {!item.isRead && (
            <Box bg="primary.700" borderRadius="5px" height="7px" width="7px" />
          )}
          <Text
            color="main.100"
            fontSize="12px"
            fontWeight="400"
            lineHeight="normal">
            {convertToPastRelativeDate(item.createdAt)}
          </Text>
        </Flex>
      </Box>
    </Flex>
  );
};
