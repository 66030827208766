import React, { FC, ReactNode } from 'react';
import { Flex, Icon } from '@chakra-ui/react';
import Popover from '@app/components/Popover';
import InfoIcon from '@app/icons/info.svg?react';

interface TooltipProps {
  text: ReactNode;
  widthContainer: string;
  arrowPadding?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

const Tooltip: FC<TooltipProps> = ({
  text,
  widthContainer,
  placement = 'top',
}) => {
  return (
    <Popover
      computePositionOnMount
      borderRadius="8px"
      offset={[0, 12]}
      placement={placement}
      popoverText={text}
      trigger="hover"
      triggerElement={
        <Flex alignItems="center">
          <Icon as={InfoIcon} h="16px" w="16px" />
        </Flex>
      }
      widthContainer={widthContainer}
    />
  );
};

export default Tooltip;
