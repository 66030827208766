import React, { FC } from 'react';

import { ConversationCategory } from '@app/api/gql/generated-types';
import { InboxMessages } from '../components/InboxMessages';
import { MessengerFolderType } from '../types';

const MissedCalls: FC = () => {
  return (
    <InboxMessages
      path="inbox/missed-calls"
      queryParams={{
        category: ConversationCategory.MISSED_CALL,
      }}
      title="Missed calls"
      type={MessengerFolderType.MISSED_CALLS}
    />
  );
};

export default MissedCalls;
