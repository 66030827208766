import { FormControl } from '@chakra-ui/react';
import React, { FC, useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';

import Label from '@app/components/FormItem/Label';
import SelectField from '@app/components/SelectField';
import { OwnPhoneType, Scope } from '@app/api/gql/generated-types';

import { phoneTypes } from './initialValues';
import { BuyNumberForm, SelectPhoneTypeOption } from './types';
import { useMaxAllowedPhones } from './useMaxAllowedPhones';

interface SelectTypeProps {
  onClear: () => void;
  isDisabled: boolean;
  scope: Scope;
}

export const SelectType: FC<SelectTypeProps> = ({
  onClear,
  isDisabled,
  scope,
}) => {
  const { setValues, values } = useFormikContext<BuyNumberForm>();
  const { byType } = useMaxAllowedPhones(scope);

  const options = useMemo(
    () =>
      phoneTypes?.map((type) => {
        return {
          ...type,
          isDisabled: byType(type?.value),
        };
      }),
    [byType],
  );
  const defaultValue = useMemo(() => {
    if (scope === Scope.DIALER) {
      return {
        label: 'Dialer',
        value: 'DIALER' as OwnPhoneType,
      };
    } else {
      return phoneTypes.find((item) => item.value === values.type);
    }
  }, [values.type, scope]);
  const onChange = useCallback(
    (value: SelectPhoneTypeOption) => {
      void setValues({
        areaCode: null,
        type: value?.value,
      });
      onClear();
    },
    [onClear, setValues],
  );

  return (
    <FormControl maxW="190px">
      <Label>Type</Label>
      <SelectField
        isDisabled={isDisabled || options?.length < 2}
        isOptionDisabled={(option: SelectPhoneTypeOption) => option.isDisabled}
        name="type"
        options={options}
        value={defaultValue}
        onChange={onChange}
      />
    </FormControl>
  );
};
