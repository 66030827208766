import React, { FC } from 'react';
import { Box, Input, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Formik, Form, FormikHelpers } from 'formik';

import { useApolloClient } from '@apollo/client';
import Popup from '@app/components/Popup';
import { entityNameSchema } from '@app/schemas/entity-name-schema';
import {
  FindGroupsDocument,
  GetContactsCountersDocument,
  useCreateGroupMutation,
} from '@app/api/gql/generated-types';
import { catchErrorLog } from '@app/utils/logger';

import { FormSubmit } from './FormSubmit';

interface CreateGroupModalFormData {
  entityName: string;
}

interface CreateGroupModalProps {
  title: string;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  onCreateGroup?: (id: string) => void;
}

export const CreateGroupModal: FC<CreateGroupModalProps> = ({
  isOpen,
  title,
  isLoading,
  onClose,
  onCreateGroup,
}) => {
  const client = useApolloClient();
  const [createGroupMutation, { loading: isCreateGroupLoading }] =
    useCreateGroupMutation({
      refetchQueries: [GetContactsCountersDocument],
      context: {
        notify: {
          success: () => 'The group is successfully created',
        },
      },
      onCompleted: async () => {
        await client.refetchQueries({
          include: [FindGroupsDocument],
          updateCache: (cache) => {
            cache.evict({
              fieldName: 'findGroups',
              broadcast: false,
            });
          },
        });
      },
    });

  const handleCreate = async (
    values: CreateGroupModalFormData,
    { resetForm }: FormikHelpers<CreateGroupModalFormData>,
  ) => {
    try {
      const { data } = await createGroupMutation({
        variables: {
          input: {
            name: values.entityName,
          },
        },
      });

      if (onCreateGroup) {
        onCreateGroup(data.createGroup.id);
      }
      onClose();
      resetForm();
    } catch (error) {
      catchErrorLog(error, 'CreateGroupModal');
    }
  };

  const isLoadingButton = isLoading || isCreateGroupLoading;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        entityName: '',
      }}
      validationSchema={entityNameSchema}
      onSubmit={handleCreate}>
      {({ errors, touched, values, handleChange, handleSubmit, resetForm }) => (
        <Popup
          closeOnOverlayClick
          isOpen={isOpen}
          maxW="380px"
          size="lg"
          title={title}
          width="380px"
          onClose={() => {
            onClose();
            resetForm();
          }}>
          <Form onSubmit={handleSubmit}>
            <Box margin="25px 40px 40px">
              <FormControl
                isInvalid={!!touched.entityName && !!errors.entityName}
                mb="20px">
                <Input
                  autoFocus
                  isInvalid={touched.entityName && !!errors.entityName}
                  name="entityName"
                  placeholder="Group name"
                  value={values.entityName}
                  variant="primary"
                  width="100%"
                  onChange={handleChange}
                />
                <FormErrorMessage>{errors.entityName}</FormErrorMessage>
              </FormControl>
              <FormSubmit isLoading={isLoadingButton} />
            </Box>
          </Form>
        </Popup>
      )}
    </Formik>
  );
};
