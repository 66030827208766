import { useEffect, useMemo, useReducer } from 'react';
import { ApolloError } from '@apollo/client';
import throttle from 'lodash/throttle';
import { AccountClaimRole } from '@app/types/Account';
import Bootstrap from '@app/types/Bootstrap';
import { catchErrorLog } from '@app/utils/logger';
import {
  Account,
  ChangesOperation,
  useAccountQuery,
  useAccountUpdatedSubscription,
} from '@app/api/gql/generated-types';
import { RESET_PASSWORD_REQUIRED_CODE } from '@app/utils/getGraphQLErrorCode';
import { getLogger } from '@app/utils/loggerUtils';
import { useEmailVerification } from './useEmailVerification';
import { CLAIMS_KEY, useInitialUserDetection } from './useInitialUserDetection';

const LOG = getLogger('useBootstrap');

// initial state
const initialState = {
  account: undefined as Account,
  registered: false,
  bootstrapped: false,
  role: undefined as AccountClaimRole,
};

type Action =
  | { type: 'guest' }
  | {
      type: 'login';
      account: Account;
      role: AccountClaimRole;
    }
  | { type: 'accountUpdate'; account: Account }
  | { type: 'logout' };

type RoleAction = { role: AccountClaimRole };
type ResetPasswordAction = {
  password_reset_required: boolean;
  impersonated?: boolean;
};

const bootstrapReducer = (state: typeof initialState, action: Action) => {
  switch (action.type) {
    case 'guest':
      return { ...state, bootstrapped: true };
    case 'login':
      return {
        registered: true,
        bootstrapped: true,
        account: action.account,
        role: action.role,
      };
    case 'accountUpdate':
      return {
        ...state,
        account: action.account,
      };
    case 'logout':
      return { ...initialState, bootstrapped: true };
    default:
      return state;
  }
};

export const useBootstrap = (): Bootstrap => {
  const { status, data } = useInitialUserDetection();
  const { status: verificationStatus } = useEmailVerification();
  const [state, dispatch] = useReducer(bootstrapReducer, initialState);

  const resetPasswordRequired = useMemo<boolean>(() => {
    const errors = data?.errors as Record<symbol, ResetPasswordAction>;

    const isFlagged = !!errors?.[CLAIMS_KEY]?.password_reset_required;
    const isImpersonated = !!errors?.[CLAIMS_KEY]?.impersonated;

    return isFlagged && !isImpersonated;
  }, [data]);

  const role = useMemo<AccountClaimRole>(() => {
    const errors = data?.errors as Record<symbol, RoleAction>;
    return errors?.[CLAIMS_KEY]?.role;
  }, [data]);

  let retryAttempts = 0;
  const MAX_ATTEMPTS = 10;

  const { refetch } = useAccountQuery({
    skip: !data?.user,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ account }) => {
      dispatch({
        type: 'login',
        role,
        account: account as Account,
      });
      retryAttempts = 0;
    },
    onError: (error: ApolloError) => {
      catchErrorLog(error, 'useBootstrap/useAccountQuery/onError');

      const codes = error.graphQLErrors.flatMap<number>((e) => {
        if (
          typeof e.extensions?.exception === 'object' &&
          'httpCode' in e.extensions.exception
        ) {
          return (e.extensions.exception as { httpCode: number }).httpCode;
        }
        return [];
      });

      if (error.graphQLErrors[0].message === RESET_PASSWORD_REQUIRED_CODE) {
        dispatch({
          type: 'logout',
        });

        return;
      }

      if (codes.some((code) => [401].includes(code))) {
        if (data?.user) {
          if (retryAttempts < MAX_ATTEMPTS) {
            setTimeout(async () => {
              retryAttempts++;
              try {
                await refetch();
              } catch (refetchError) {
                catchErrorLog(
                  refetchError,
                  `useBootstrap/useAccountQuery/refetchError attempt ${retryAttempts}`,
                );
              }
            }, 1000);
          } else {
            retryAttempts = 0;
            dispatch({ type: 'logout' });
          }
        } else {
          dispatch({ type: 'logout' });
        }
      }
    },
  });

  const throttledRefetch = throttle(refetch, 3000);

  useAccountUpdatedSubscription({
    skip: !state.account,
    onData: ({ data: { data: accountData } = {} }) => {
      const operationData = accountData?.accountUpdated ?? null;
      if (
        operationData?.operation === ChangesOperation.UPDATED &&
        operationData?.entityId === state.account.id
      ) {
        void throttledRefetch();
      }
    },
  });

  const isImpersonated = useMemo(
    () => role === AccountClaimRole.IMPERSONATE,
    [role],
  );

  useEffect(() => {
    // guest case
    if (
      status === 'success' &&
      (!data?.user || verificationStatus === 'error') &&
      state.bootstrapped === false
    ) {
      dispatch({ type: 'guest' });
    }

    // logout case
    if (!data?.user && state.registered === true) {
      dispatch({ type: 'logout' });
    }
  }, [data, state, status, verificationStatus]);

  LOG.debug('state', state);
  LOG.debug('firebaseAuthUser', data?.user);

  return {
    resetPasswordRequired,
    account: state.account,
    role: state.role,
    firebaseAuthUser: data?.user,
    phoneVerified: !!data?.user?.phoneNumber,
    registered: !!state.registered,
    emailVerified: !!data?.user?.emailVerified,
    isSocialSignUp: !data?.user?.providerData?.some(
      (provider) => provider.providerId === 'password',
    ),
    isImpersonated,
    bootstrapped:
      status !== 'loading' &&
      verificationStatus !== 'loading' &&
      state.bootstrapped,
  };
};
