import React, { FC } from 'react';
import { Box, Image } from '@chakra-ui/react';

interface Props {
  image: string;
}

const ImgWithPlaceholder: FC<Props> = ({ image }) => {
  if (!image) {
    return <Box bg="mystic" borderRadius="12px" height="100%" width="100%" />;
  }

  return <Image height="100%" src={image} width="100%" />;
};

export default ImgWithPlaceholder;
