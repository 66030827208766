import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { TTextVariant } from '@app/theme/components/text';
import { PhoneNumberVerificationInput } from '@app/components/next/organisms/PhoneNumberVerificationInput';
import { TosConfirmCheckbox } from '@app/widgets/TosConfirmCheckbox';
import { signUpSchema } from '@app/pages/SignUp/schema';
import RootContainerForm from '@app/components/RootContainerForm';
import { DisclaimerBox } from '@app/components/next/moleculas/DisclaimerBox';
import { colors } from '@app/theme/colors';

interface IPhoneNumberVerificationProps {
  setIsPhoneVerificationInProgress?: (value: boolean) => void;
}

export const PhoneNumberVerification: React.FC<
  IPhoneNumberVerificationProps
> = ({ setIsPhoneVerificationInProgress }) => {
  const [codeVerified, setCodeVerified] = useState(false);

  useEffect(() => {
    setIsPhoneVerificationInProgress(true);
  }, [setIsPhoneVerificationInProgress, codeVerified]);

  const onVerificationComplete = () => {
    setIsPhoneVerificationInProgress(false);
  };

  const handleSignUp = () => {};

  return (
    <RootContainerForm>
      <Flex direction="column" gap="20px" mb="50px">
        <Text textAlign="center" variant={TTextVariant.enum['tertiary-title']}>
          Phone number verification
        </Text>
      </Flex>
      <Formik
        initialValues={{
          isAgree: false,
        }}
        validationSchema={signUpSchema}
        onSubmit={handleSignUp}>
        {({ values }) => (
          <Form>
            <Box alignItems="baseline" mb="20px">
              <PhoneNumberVerificationInput
                codeVerified={codeVerified}
                setCodeVerified={setCodeVerified}
              />

              <Box m="24px 0 0">
                <TosConfirmCheckbox />
              </Box>
              <Box mt="17px">
                <Button
                  data-cy="signup-sign-up-page-button"
                  fontSize="17px"
                  fontWeight="700"
                  height="48px"
                  id="sign-up-button"
                  isDisabled={!codeVerified || !values.isAgree}
                  variant="primary"
                  width="100%"
                  onClick={onVerificationComplete}>
                  Sign up
                </Button>
              </Box>
              {!codeVerified && (
                <DisclaimerBox
                  style={{
                    marginTop: '20px',
                    gap: '8px',
                    backgroundColor: colors.zircon,
                  }}>
                  <Text
                    color="main.400"
                    lineHeight="18px"
                    variant={TTextVariant.enum['arsenic-60']}>
                    A valid phone number is required to authenticate and
                    activate your account
                  </Text>
                </DisclaimerBox>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </RootContainerForm>
  );
};
