import last from 'lodash/last';
import { useEffect } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';

import ROUTES from '@app/utils/routes';
import { useQueryParams } from '@app/hooks/useQueryParams';

import { SideBarTab } from '../types';

export const useTabs = () => {
  const navigate = useNavigate();
  const match = useMatch(ROUTES.messenger);
  const location = useLocation().pathname;

  const [, accountId] = useQueryParams<{
    accountId: string;
  }>();
  const paths: string[] = location.split('/');

  const setActive = (tab: SideBarTab) => {
    const clearPaths = location.split('/').slice(0, paths.length - 1);
    navigate(`${clearPaths.join('/')}/${tab}${accountId}`);
  };

  const isActive = (activeTab: SideBarTab) =>
    last(paths) === (activeTab as string);

  useEffect(() => {
    const tabUrl = last(paths);
    if (
      !Object.values(SideBarTab).find((path) => path === (tabUrl as SideBarTab))
    ) {
      navigate(
        `${paths.join('/')}/${
          match ? SideBarTab.MESSAGES : SideBarTab.INFO
        }${accountId}`,
      );
    }
  }, [accountId, match, navigate, paths]);

  return { setActive, isActive };
};
