import { ApplicationCall } from 'infobip-rtc';
import React, {
  createContext,
  FC,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BlockerFunction, useBlocker, useNavigate } from 'react-router-dom';
import { ApolloError, useApolloClient } from '@apollo/client';
import {
  PowerDialerFailedErrorType,
  PowerDialerFailedSubscription,
  PowerDialerStateUpdatedSubscription,
  useAddLabelToCallAndContactMutation,
  useHangUpPowerDialerCallMutation,
  useManagePowerDialerCallRecordingMutation,
  usePowerDialerFailedSubscription,
  usePowerDialerStateUpdatedSubscription,
  useStartPowerDialingMutation,
  useSwitchDialerOfflineMutation,
  useSwitchDialerOnlineMutation,
  useResumeDialerCampaignMutation,
  GetCampaignDocument,
  FindDialerCampaignsDocument,
} from '@app/api/gql/generated-types';
import { CallRecordingAction } from '@app/api/gql/graphql';
import ringtone from '@app/assets/audio/ringtone.mp3';
import { getLogger, catchErrorLog } from '@app/utils/logger';
import ROUTES from '@app/utils/routes';
import { useCurrentDialerContext } from '@app/providers/CurrentDialerProvider';
import { useDebounce } from '@app/utils/useDebounce';
import ConfirmModal from '@app/pages/Dialer/components/ConfirmPauseCampaign/ConfirmPauseCampaign';
import { usePopupsContext } from '@app/hooks/usePopupsContext';
import { SideBarTab } from '@app/components/SideBarMessenger/types';
import {
  getApolloGraphQLErrorCode,
  getGraphQLErrorMessage,
} from '@app/utils/apolloErrorsMapHelper';
import { ExceptionCode } from '@app/types/ApolloErrors';
import { useConditionalCustomModal } from '@app/hooks/useConditionalCustomModal';
import { ModalAccessMicrophoneContent } from '@app/components/next/organisms/CustomModal';

const LOG = getLogger('Dialer/PowerDialerProvider');

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const ringtoneAudio = new Audio(ringtone);

// const playRingtone = async () => {
//   ringtoneAudio.loop = true;
//   ringtoneAudio.currentTime = 0;
//   await ringtoneAudio.play();
//   ringtoneAudio.muted = false;
// };

const stopRingtone = () => {
  ringtoneAudio.pause();
  ringtoneAudio.currentTime = 0;
  ringtoneAudio.muted = true;
};

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  return 'truthy';
};

export type PowerDialerLine =
  PowerDialerStateUpdatedSubscription['powerDialerStateUpdated']['lines'][0];

export interface PowerDialerContextValues {
  lines: PowerDialerLine[];
  /** Answered, human. */
  connectedLine?: PowerDialerLine;
  /** Answered, human, and currently both sides can hear each other. Can be hung up. Unset only on next batch. */
  connectedCallId?: string;
  /** Has been answered at some point, human. Set if batch had a connectedCallId. Unset only on next batch. */
  currentContactId?: string;
  showOutcomeLabels: boolean;
  currentOutboundNumber?: string;
  switchDialerOffline: (inPowerDialer: boolean) => void;
  applicationCallRef: MutableRefObject<ApplicationCall | null>;
  setCampaignId: (id: string) => void;
  hangup: () => void;
  isMuted: boolean;
  toggleMute: () => void;
  isRecording: boolean;
  toggleRecording: () => void;
  // stopRecording: () => void;
  sendDTMF: (digit: string) => void;
  isAgentOnline: boolean;
  setIsAgentOnline: (online: boolean) => void;
  stopRingtone: () => void;
  isConnecting: boolean;
  isDialing: boolean;
  isCampaignComplete: boolean;
  establishedAt: string | undefined;
  /** In seconds. **/
  duration: number | undefined;
  setOutcomeLabel: (labelId: string) => void;
  showContactRateLimitModal: boolean;
  closeContactRateLimitModal: () => void;
  checkBalanceForNextBatchModal: boolean;
  closeCheckBalanceForNextBatchModal: () => void;
  hangupIsDisabled: boolean;
  /** Connection to provider **/
  isConnectedToProvider: boolean;
  setIsConnectedToProvider: (val: boolean) => void;
  resumeCampaignAfterRecharge?: () => void;
}

const PowerDialerContext = createContext<PowerDialerContextValues | undefined>(
  undefined,
);

export const PowerDialerProvider: FC<PropsWithChildren> = ({
  children,
}): ReactElement => {
  // TODO set this to infobip
  // const { messagingProfile } = useCurrentAccountData();
  const { isPowerDialerDisabled: isDisabled } = useCurrentDialerContext();

  // STATE

  const applicationCallRef = useRef<ApplicationCall>(null);
  const [campaignId, setCampaignId] = useState<string | null>(null);

  const [isConnectedToProvider, setIsConnectedToProvider] = useState(false);

  const [isAgentOnline, setIsAgentOnline] = useState(false);

  const [isMuted, setIsMuted] = useState(false);

  const [state, setState] = useState<
    PowerDialerStateUpdatedSubscription['powerDialerStateUpdated'] | null
  >(null);

  const connectedLine = state?.connectedLineId
    ? state.lines.find((line) => line.id === state.connectedLineId)
    : undefined;
  const connectedCallId = connectedLine?.callId;

  const navigate = useNavigate();

  const shouldBlockNavigationRef = useRef(false);

  // MUTATIONS

  const [manageCallRecordingMutation] =
    useManagePowerDialerCallRecordingMutation();
  const [hangUpPowerDialerCallMutation, { loading: isHangingUpCall }] =
    useHangUpPowerDialerCallMutation();
  const [addLabelToCallAndContactMutation] =
    useAddLabelToCallAndContactMutation();
  const [startPowerDialingMutation] = useStartPowerDialingMutation();

  const [switchDialerOnlineMutation, { loading: isSwitchingOnline }] =
    useSwitchDialerOnlineMutation();

  const startDialer = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (applicationCallId: string, dialerCampaignId: string) => {
      await switchDialerOnlineMutation({
        variables: { campaignId: dialerCampaignId },
        onCompleted: async () => {
          await startPowerDialingMutation({
            variables: {
              input: {
                applicationCallId,
                dialerCampaignId,
              },
            },
          });
        },
        onError: (error) => {
          if (
            getApolloGraphQLErrorCode(error.graphQLErrors) ===
            ExceptionCode.OUTSIDE_BUSINESS_HOURS
          ) {
            setIsAgentOnline(false);
          }
        },
      });
    },
    [switchDialerOnlineMutation, startPowerDialingMutation],
  );

  const enableMicrophoneAccess = useCallback(async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await startDialer(applicationCallRef.current.id(), campaignId);
      setIsAgentOnline(true);
    } catch {
      setIsAgentOnline(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, startDialer]);

  const { toggleModal: showAccessMicrophoneModal } = useConditionalCustomModal({
    ...ModalAccessMicrophoneContent,
    primaryAction: enableMicrophoneAccess,
  });

  const switchDialerOnline = useDebounce(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (applicationCallId: string, campaignId: string) => {
      window.addEventListener('beforeunload', handleBeforeUnload);
      if (!isSwitchingOnline) {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasMicrophone = devices.some(
          (device) => device.kind === 'audioinput' && device.label !== '',
        );
        if (!hasMicrophone) {
          setIsAgentOnline(false);
          showAccessMicrophoneModal(true);
          return;
        }
        await startDialer(applicationCallId, campaignId);
      }
    },
    100,
    [isSwitchingOnline, startDialer],
  );

  const [switchDialerOfflineMutation, { loading: isSwitchingOffline }] =
    useSwitchDialerOfflineMutation();
  const switchDialerOffline = useDebounce(
    (inPowerDialer: boolean) => {
      if (!isSwitchingOffline) {
        void switchDialerOfflineMutation({
          refetchQueries: [FindDialerCampaignsDocument],
          variables: {
            inPowerDialer,
          },
        });
      }
      window.removeEventListener('beforeunload', handleBeforeUnload);
    },
    100,
    [isSwitchingOffline, switchDialerOfflineMutation],
  );

  usePowerDialerStateUpdatedSubscription({
    shouldResubscribe: true,
    onData: ({
      data: {
        data: { powerDialerStateUpdated: payload },
      },
    }) => {
      if (payload?.campaignIsCompleted) {
        setState(null);
        void switchDialerOffline(false);
        shouldBlockNavigationRef.current = false;
        navigate(ROUTES.dialerAll);
      } else {
        setState(payload);
      }
    },
  });

  useEffect(() => {
    LOG.debug(`Resetting power dialer state for campaign ${campaignId} ...`);
    shouldBlockNavigationRef.current = !!campaignId;
    setIsAgentOnline(false);
    setIsMuted(false);
    setState(null);
  }, [campaignId]);

  const [failedState, setFailedState] =
    useState<PowerDialerFailedSubscription['powerDialerFailed']>(null);

  const [showContactRateLimitModal, setShowContactRateLimitModal] =
    useState(false);
  const closeContactRateLimitModal = useCallback(() => {
    setShowContactRateLimitModal(false);
    void switchDialerOffline(false);
    navigate(ROUTES.dialerAll);
  }, [navigate, switchDialerOffline]);

  const [checkBalanceForNextBatchModal, setCheckBalanceForNextBatchModal] =
    useState(false);
  const closeCheckBalanceForNextBatchModal = useCallback(() => {
    void switchDialerOffline(false);
    navigate(ROUTES.dialerAll);
  }, [navigate, switchDialerOffline]);

  const client = useApolloClient();
  const evictCaches = useCallback(() => {
    client.cache.evict({ fieldName: 'getDialerCampaign' });
  }, [client.cache]);

  const [resumeCampaign] = useResumeDialerCampaignMutation({
    context: {
      notify: {
        error: (error: ApolloError[]) => getGraphQLErrorMessage(error),
        success: null,
      },
    },
  });

  const resumeCampaignAfterRecharge = useCallback(async () => {
    try {
      setCheckBalanceForNextBatchModal(false);

      await resumeCampaign({
        variables: {
          id: campaignId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [GetCampaignDocument, FindDialerCampaignsDocument],
      });

      evictCaches();

      navigate(ROUTES.powerDialer.replace(':campaignId', campaignId), {
        replace: true,
      });
      await startPowerDialingMutation({
        variables: {
          input: {
            applicationCallId: applicationCallRef.current.id(),
            dialerCampaignId: campaignId,
          },
        },
      });
    } catch (error) {
      catchErrorLog(error, 'handleResumeDialerCampaign');
    }
  }, [
    campaignId,
    evictCaches,
    navigate,
    resumeCampaign,
    startPowerDialingMutation,
  ]);

  useEffect(() => {
    if (!failedState) {
      return;
    }

    switch (failedState.errorType) {
      case PowerDialerFailedErrorType.LABEL_NOT_APPLIED:
        // TODO PowerDialerFailedErrorType.LABEL_NOT_APPLIED
        break;
      case PowerDialerFailedErrorType.ALREADY_ONLINE:
        // TODO PowerDialerFailedErrorType.ALREADY_ONLINE
        break;
      case PowerDialerFailedErrorType.BAD_MESSAGING_PROVIDER_TYPE:
        // TODO PowerDialerFailedErrorType.BAD_MESSAGING_PROVIDER_TYPE
        break;
      case PowerDialerFailedErrorType.CAMPAIGN_COULDNT_START:
        // TODO PowerDialerFailedErrorType.CAMPAIGN_COULDNT_START
        break;
      case PowerDialerFailedErrorType.INSUFFICIENT_BALANCE:
        setCheckBalanceForNextBatchModal(true);
        break;
      case PowerDialerFailedErrorType.CAMPAIGN_NOT_FOUND:
        // TODO PowerDialerFailedErrorType.CAMPAIGN_NOT_FOUND
        break;
      case PowerDialerFailedErrorType.DAILY_CONTACT_LIMIT_REACHED:
        setShowContactRateLimitModal(true);
        break;
      case PowerDialerFailedErrorType.INTERNAL_SERVER_ERROR:
        // TODO PowerDialerFailedErrorType.INTERNAL_SERVER_ERROR
        break;
      default:
        break;
    }
  }, [failedState]);

  usePowerDialerFailedSubscription({
    shouldResubscribe: true,
    onData: ({
      data: {
        data: { powerDialerFailed: payload },
      },
    }) => {
      setFailedState(payload);
    },
  });

  const [hangupIsDisabled, setHangupIsDisabled] = useState<boolean>(true);
  const hangup = useCallback(() => {
    stopRingtone();

    // Note: setShowOutcome(true) happens on the CONFERENCE_LEFT handler

    // Hang up
    if (applicationCallRef.current && !isHangingUpCall) {
      setHangupIsDisabled(true);
      void hangUpPowerDialerCallMutation({
        variables: {
          batchId: state.batchId,
        },
      });
    }
  }, [state, isHangingUpCall, hangUpPowerDialerCallMutation]);

  /**
   * Should only be enabled when the BE confirms that it's OK to enable.
   * This avoids a flicker caused by the following sequence:
   * 1. hangup() => isHangingUp = true => hangupIsDisabled = true
   * 2. hangup() finishes => isHangingUp = false => hangupIsDisabled = false
   * 3. back end says hangupIsDisabled = true => hangupIsDisabled = true
   */
  useEffect(() => {
    if (state) {
      setHangupIsDisabled(state.hangupIsDisabled);
    }
  }, [state]);

  const toggleMute = useCallback(() => {
    setIsMuted((prevState) => !prevState);
  }, [setIsMuted]);

  // Mute/unmute the call
  useEffect(() => {
    void applicationCallRef.current?.mute(isMuted);
  }, [isMuted]);

  const sendDTMF = useCallback(async (digit: string) => {
    await applicationCallRef.current?.sendDTMF(digit);
  }, []);

  const toggleRecording = useCallback(async () => {
    if (!connectedCallId || !campaignId || isDisabled) {
      return;
    }

    await manageCallRecordingMutation({
      variables: {
        input: {
          campaignId,
          action: !state?.isRecording
            ? CallRecordingAction.START
            : CallRecordingAction.STOP,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled, campaignId, state?.isRecording, connectedCallId]);

  const setOutcomeLabel = useCallback(
    (labelId: string) => {
      if (isDisabled) {
        return;
      }

      void addLabelToCallAndContactMutation({
        variables: {
          input: {
            batchId: state.batchId,
            labelId,
            applicationCallId: applicationCallRef.current.id(),
          },
        },
      });
    },
    [addLabelToCallAndContactMutation, isDisabled, state?.batchId],
  );

  // Handle offline status being set while inside the dialer page
  const lastStatusWasOnlineRef = useRef(false);
  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const justWentOnline = isAgentOnline && !lastStatusWasOnlineRef.current;
    const justWentOffline = !isAgentOnline && lastStatusWasOnlineRef.current;

    if (justWentOnline) {
      lastStatusWasOnlineRef.current = true;
      void switchDialerOnline(applicationCallRef.current.id(), campaignId);
    } else if (justWentOffline) {
      lastStatusWasOnlineRef.current = false;
      switchDialerOffline(true);
    }
  }, [
    connectedCallId,
    isDisabled,
    isAgentOnline,
    campaignId,
    hangup,
    state?.isDialing,
    switchDialerOffline,
    switchDialerOnline,
    state,
  ]);

  // Set initial offline status when going into dialer campaign or out of it
  const [recordedOfflineForCampaignId, setRecordedOfflineForCampaignId] =
    useState<string | null>(null);
  useEffect(() => {
    if (isDisabled) {
      if (recordedOfflineForCampaignId === 'outside') {
        return;
      }
      setRecordedOfflineForCampaignId('outside');
      switchDialerOffline(false);
    } else {
      if (recordedOfflineForCampaignId === campaignId) {
        return;
      }
      setRecordedOfflineForCampaignId(campaignId);
      switchDialerOffline(true);
    }
  }, [
    recordedOfflineForCampaignId,
    campaignId,
    isDisabled,
    switchDialerOffline,
  ]);

  // Block navigation

  const shouldBlockNavigation = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      shouldBlockNavigationRef.current &&
      isAgentOnline &&
      currentLocation.pathname !== nextLocation.pathname &&
      (() => {
        // Need to check the case where it's just a change between `/dial/messages` <=> `/dial/info` <=> `/dial/notes` <=> `/dial`
        // If it is, then we ignore this change for this change. Meaning we don't block navigation for this case.
        // If it isn't, then we block the change.

        const extractLastPathSegment = (path: string) =>
          path.split('/').filter(Boolean).pop();

        const allowedPaths = [
          // These are changed by the `useTabs()` hook used inside SideBarMessenger/Content.tsx, we have no control over it in this code
          SideBarTab.MESSAGES,
          SideBarTab.INFO,
          SideBarTab.NOTES,
          // This part is stable
          extractLastPathSegment(ROUTES.powerDialer),
        ];

        if (
          allowedPaths.includes(
            extractLastPathSegment(currentLocation.pathname),
          ) &&
          allowedPaths.includes(extractLastPathSegment(nextLocation.pathname))
        ) {
          // We're changing between `/dial/messages` <=> `/dial/info` <=> `/dial/notes` <=> `/dial`, so it's OK we don't need to block.
          return false;
        }

        // We're changing from one of these, to something else entirely, so we should block navigation.
        return true;
      })(),
    [isAgentOnline],
  );
  const routingBlocker = useBlocker(shouldBlockNavigation);

  const { showConfirmPopup, closeConfirmPopup } = usePopupsContext();
  useEffect(() => {
    if (
      routingBlocker.state === 'unblocked' ||
      !shouldBlockNavigationRef.current
    ) {
      return;
    } else if (checkBalanceForNextBatchModal) {
      setCheckBalanceForNextBatchModal(false);
      shouldBlockNavigationRef.current = false;
      routingBlocker.proceed();
      return;
    } else {
      showConfirmPopup({
        title: 'Active dialer campaign',
        description: (
          <ConfirmModal message="You are engaged in an active dialer campaign. If you leave, your campaign will be paused and all ongoing calls will be disconnected. Are you sure you want to leave this page?" />
        ),
        confirmButtonText: 'Confirm',
      })
        .then(() => {
          shouldBlockNavigationRef.current = false;
          closeConfirmPopup();
          routingBlocker.proceed();
        })
        .catch(() => {
          closeConfirmPopup();
          routingBlocker.reset();
        });
    }
  }, [
    closeConfirmPopup,
    routingBlocker,
    shouldBlockNavigation,
    showConfirmPopup,
    checkBalanceForNextBatchModal,
  ]);

  const providerValue = useMemo(
    () =>
      ({
        lines: state?.lines ?? [],
        connectedLine,
        showOutcomeLabels: state?.showOutcomeLabels,
        connectedCallId,
        currentContactId: state?.currentContactId,
        currentOutboundNumber: state?.currentOutboundNumber,
        switchDialerOffline,
        applicationCallRef,
        setCampaignId,
        hangup,
        isHangingUpCall,
        isMuted,
        toggleMute,
        isRecording: !!state?.isRecording,
        toggleRecording,
        sendDTMF,
        isAgentOnline,
        setIsAgentOnline,
        stopRingtone,
        isConnecting: !!state?.isConnecting,
        isDialing: !!state?.isDialing,
        isCampaignComplete: state?.campaignIsCompleted ?? false,
        establishedAt: state?.establishedAt,
        duration: state?.duration,
        setOutcomeLabel,
        showContactRateLimitModal,
        closeContactRateLimitModal,
        closeCheckBalanceForNextBatchModal,
        checkBalanceForNextBatchModal,
        hangupIsDisabled: hangupIsDisabled || isHangingUpCall,
        isConnectedToProvider,
        setIsConnectedToProvider,
        resumeCampaignAfterRecharge,
      }) as PowerDialerContextValues,
    [
      state?.lines,
      connectedLine,
      state?.showOutcomeLabels,
      state?.campaignIsCompleted,
      state?.establishedAt,
      state?.duration,
      state?.currentOutboundNumber,
      connectedCallId,
      state?.currentContactId,
      switchDialerOffline,
      applicationCallRef,
      setCampaignId,
      hangup,
      isHangingUpCall,
      isMuted,
      toggleMute,
      toggleRecording,
      sendDTMF,
      isAgentOnline,
      setIsAgentOnline,
      state?.isConnecting,
      state?.isDialing,
      state?.isRecording,
      setOutcomeLabel,
      showContactRateLimitModal,
      closeContactRateLimitModal,
      checkBalanceForNextBatchModal,
      closeCheckBalanceForNextBatchModal,
      hangupIsDisabled,
      isConnectedToProvider,
      setIsConnectedToProvider,
      resumeCampaignAfterRecharge,
    ],
  );

  return (
    <PowerDialerContext.Provider value={providerValue}>
      {children}
    </PowerDialerContext.Provider>
  );
};

export const usePowerDialerContext = () => {
  return useContext(PowerDialerContext);
};
