import { Box, GridItem } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { colors } from '@app/theme/colors';
import { EventItemBoxProps } from '../types';

export const BodyMonthElementGridItem = styled(GridItem)`
  position: relative;
  width: 100%;
  min-width: 50px;
  height: 100%;
  min-height: 50px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  border-right: 1px solid ${colors.secondary[100]};
  border-bottom: 1px solid ${colors.secondary[100]};
  padding: 35px 7px 7px;
  background: ${colors.white};
  font-size: 12px;
  font-weight: 600;

  &:nth-of-type(7n + 1) {
    border-left: 1px solid ${colors.secondary[100]};
  }
`;

const getEventItemPadding = (mode: string) => {
  if (mode === 'weekView') {
    return '5px 8px';
  }
  if (mode === 'slim') {
    return '0px 8px';
  }

  return '10px';
};

const getEventItemMaxHeight = (mode: string) => {
  if (mode === 'weekView') {
    return '68px';
  }

  if (mode === 'slim') {
    return '26px';
  }

  return '100%';
};

export const EventItemBox = styled(Box)<EventItemBoxProps>`
  flex-basis: 100%;
  cursor: pointer;
  padding: ${(props) => getEventItemPadding(props.mode)};
  font-size: 12px;
  line-height: ${(props) =>
    props.mode === 'slim'
      ? '26px'
      : props.mode === 'default'
        ? '15px'
        : 'inherit'};
  max-height: ${(props) => getEventItemMaxHeight(props.mode)};
  margin-bottom: ${(props) => (props.mode === 'weekView' ? '0px' : '2px')};
  background: linear-gradient(
      0deg,
      ${colors.primary[100]},
      ${colors.primary[100]}
    ),
    ${colors.white};
  position: relative;
  top: ${(props) => (props.mode === 'weekView' ? '4px' : '0')};
  border-radius: 6px;
  color: ${colors.primary[600]};
  overflow: hidden;
  font-weight: 400;
  display: ${(props) => (props.mode === 'slim' ? 'flex' : 'block')};
  justify-content: space-between;
`;
