import {
  Box,
  Collapse,
  Flex,
  Spacer,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { DEFAULT_LIMIT } from '@app/api/queries/utils';
import FilterByLabel from '@app/components/Filter/FilterByLabel';
import SortBy from '@app/components/Filter/SortBy';
import { sortContactsForRequest } from '@app/components/Filter/SortBy/interface';
import { LabelFilterList } from '@app/components/LabelList/LabelFilterList';
import PageSkeleton from '@app/components/PageSkeleton';
import { usePopupsContext } from '@app/hooks/usePopupsContext';
import PlusInCircleIcon from '@app/icons/plus-in-circle-icon.svg?react';
import { getLogger } from '@app/utils/logger';
import ROUTES, { generateUrl } from '@app/utils/routes';

import { createPaginationVariables } from '@app/api/apollo-pagination';
import {
  Contact,
  ContactStatus,
  FindContactsDocument,
  useFindContactsQuery,
  useGetGroupQuery,
  useRemoveGroupFromContactsMutation,
} from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import { useUpdateContactMutation } from '@app/api/mutations/useUpdateContactMutation';
import { RemoveNotPossibleModal } from '@app/components/Modals/RemoveNotPossibleModal';
import { TableSkeleton } from '@app/components/Table/Skeleton';
import use10DlcAccountStatusGuard from '@app/hooks/use10DlcAccountStatusGuard';
import { useDisableMessaging } from '@app/hooks/useDisableMessaging';
import { fullTextInputValidation } from '@app/utils/fullTextInputValidation';
import { PermissionButton } from '@app/components/next/atoms/PermissionsComponents/WithPermission';
import { PermissionActions, PermissionFeatures } from '@app/acl';
import { ContactGroupActions } from '../../components/ContactGroupActions';
import { GroupListHeader } from '../../components/GroupListHeader';
import {
  ConfirmRemoveContactFromGroupDescription,
  ConfirmRemoveContactsFromGroupDescription,
} from '../../components/Modal/ContactRemoveDescription';
import { SelectPageLimit } from '../../components/SelectPageLimit';
import { TableList } from '../../components/TableList';
import { useContacts } from '../../hooks/useContacts';

const LOG = getLogger('AllGroupContacts');

export const AllGroupContacts: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    select,
    selectAll,
    reset: resetSelectedItems,
    items: selectedItems,
    sort,
    setSort,
    searchValue,
    setSearchValue,
    resetSelected,
  } = useContacts();
  const { showConfirmPopup, closeConfirmPopup } = usePopupsContext();
  const { isDisabled } = useDisableMessaging();
  const removeNotPossibleModal = useDisclosure();

  const { contactListId } = useParams<{
    contactListId: string;
  }>();

  const match = useMatch({
    path: ROUTES.contacts,
  });

  const [labels, setLabels] = useState<Array<string>>([]);
  const [pageLimit, setPageLimit] = useState<number>(DEFAULT_LIMIT);

  const guardDlcAction = use10DlcAccountStatusGuard(() =>
    navigate(ROUTES.campaignCreate, {
      state: {
        contactListIds: [contactListId],
      },
    }),
  );

  const { data: { getGroup: group } = {}, loading } = useGetGroupQuery({
    variables: {
      id: contactListId,
    },
  });

  const [removeGroupFromContactsMutation] = useRemoveGroupFromContactsMutation({
    refetchQueries: [FindContactsDocument],
  });

  const [updateContactMutation] = useUpdateContactMutation({
    context: {
      useApolloNetworkStatus: true,
      notify: {
        success: () => 'Removed from group successfully',
        error: () => 'Failed to remove contact from group',
      },
    },
    refetchQueries: [FindContactsDocument],
  });

  const params = {
    filter: {
      ...(fullTextInputValidation(searchValue) && {
        fullSearch: searchValue,
      }),
      ...(labels.length && { labels }),
      groups: [contactListId],
      status: ContactStatus.ACTIVE,
    },
    pagination: {
      limit: pageLimit,
    },
    order: {
      ...sortContactsForRequest[sort],
    },
  };

  const {
    data: { findContacts: contactsData } = {},
    networkStatus,
    fetchMore,
    refetch: refetchContacts,
  } = useFindContactsQuery({
    variables: params,
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    void refetchContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactListId, sort]);

  const fetchNextPage = () =>
    fetchMore(createPaginationVariables(contactsData, pageLimit));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contacts: Contact[] = (contactsData?.items as Contact[]) ?? [];

  const removeModalText = useMemo(() => {
    return selectedItems.length > 1
      ? 'You cannot delete these selected contacts because they are part of an active or paused campaign.'
      : 'You cannot delete this contact because it is part of an active or paused campaign.';
  }, [selectedItems]);

  const {
    isLoading: contactsLoading,
    isLoadingData,
    isFetchingNextPage,
  } = useApolloLoadingStatus(networkStatus);

  const isLoading = (contactsLoading || loading) && isEmpty(contacts);

  const isAllSelected = useMemo(
    () => selectedItems.length === contacts.length,
    [selectedItems, contacts],
  );

  const handleChangePageLimit = useCallback(
    async ({ value }: { value: number }) => {
      setPageLimit(value);
      await refetchContacts({
        pagination: {
          limit: value,
        },
      });
    },
    [refetchContacts],
  );

  const handleSelectAll = () => {
    if (isAllSelected) {
      resetSelectedItems();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      selectAll(contacts.map((item) => item.id));
    }
  };

  const handleContactClick = useCallback((contact: Contact) => {
    navigate(
      generateUrl(ROUTES.contactsGroupsContactInfo, {
        groupId: contactListId,
        contactId: contact.id,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    let paths: string[] = location.pathname.split('/');
    if (match && paths.length > 5) {
      paths = paths.slice(0, paths.length - 2);
      navigate(paths.join('/'));
    }
  };

  const handleRemoveContact = async (
    event: MouseEvent<HTMLElement>,
    contactItem: Contact,
  ) => {
    event.stopPropagation();
    try {
      if (!contactItem.canBeRemoved) {
        removeNotPossibleModal.onOpen();
        return;
      }
      await showConfirmPopup({
        title: 'Remove',
        description: ConfirmRemoveContactFromGroupDescription(contactItem),
        confirmButtonText: 'Confirm',
      });

      await updateContactMutation({
        variables: {
          input: {
            groups: contactItem.groups
              .filter((g) => g.id !== contactListId)
              .map((el) => el.id),
          },
          id: contactItem.id,
        },
      });
      closeConfirmPopup();
      onClose();
    } catch (error) {
      LOG.error(error);
    } finally {
      void refetchContacts();
    }
  };

  const removeContacts = async () => {
    await showConfirmPopup({
      title: 'Remove',
      description: ConfirmRemoveContactsFromGroupDescription(),
      confirmButtonText: 'Confirm',
    });

    await removeGroupFromContactsMutation({
      variables: {
        input: {
          ids: selectedItems,
          groupId: contactListId,
        },
      },
      context: {
        useApolloNetworkStatus: true,
        notify: {
          success: () => 'Contacts removed from group',
          error: () => 'Failed to remove contacts',
        },
      },
    });

    closeConfirmPopup();
    resetSelectedItems();
    if (selectedItems.length === DEFAULT_LIMIT) {
      await refetchContacts();
    }
    onClose();
  };

  const handleRemoveContacts = async () => {
    try {
      const contactsCanBeRemoved = contacts.some(
        (item) => selectedItems.includes(item.id) && item.canBeRemoved,
      );

      if (!contactsCanBeRemoved) {
        removeNotPossibleModal.onOpen();
        return;
      }
      await removeContacts();
    } catch (error) {
      LOG.error(error);
    }
  };

  if (isLoading) {
    return <PageSkeleton mt="25px" />;
  }

  return (
    <Flex direction="column" mt="10px" overflow="hidden" width="100%">
      <GroupListHeader
        name={group?.name || ''}
        verified={group?.verified}
        onSearchChange={setSearchValue}
      />

      <Flex mb="21px" mt="20px" pl="30px" pr="16px" width="100%">
        <Tooltip
          hasArrow
          shouldWrapChildren
          isDisabled={isLoading || !isEmpty(contacts)}
          label="Please select a verified group with at least 1 contact"
          padding="10px"
          placement="right">
          <PermissionButton
            action={PermissionActions.CREATE}
            isDisabled={isDisabled || (!isLoading && isEmpty(contacts))}
            leftIcon={<PlusInCircleIcon />}
            subject={PermissionFeatures.contacts.pushToCampaign}
            variant="outlined"
            onSuccess={guardDlcAction}>
            Push to campaign
          </PermissionButton>
        </Tooltip>
        <Spacer />
        {!isEmpty(contacts) && (
          <Box height="40px" width="135px" zIndex="11">
            <SelectPageLimit
              handleChangePageLimit={handleChangePageLimit}
              pageLimit={pageLimit}
            />
          </Box>
        )}
      </Flex>

      {isEmpty(selectedItems) ? (
        <Flex flexDirection="column">
          <Flex mb="15px" ml="14px" mt="20px" position="relative">
            <SortBy value={sort} onChange={setSort} />
            <FilterByLabel value={labels} onChange={setLabels} />
          </Flex>
          <Collapse animateOpacity in={!isEmpty(labels)}>
            {!isEmpty(labels) && (
              <Flex ml="23px">
                <LabelFilterList
                  isMonochrome
                  labels={labels}
                  visibleItemsCount={Infinity}
                  onChange={setLabels}
                />
              </Flex>
            )}
          </Collapse>
        </Flex>
      ) : (
        <ContactGroupActions
          selectedContacts={selectedItems}
          onAddToGroup={resetSelected}
          onDelete={handleRemoveContacts}
        />
      )}
      {isLoadingData ? (
        <TableSkeleton mt="10px" />
      ) : (
        <TableList
          contactListData={contacts}
          fetchNextPage={fetchNextPage}
          handleContactClick={handleContactClick}
          handleSelectAll={handleSelectAll}
          hasNextPage={contactsData?.hasNext}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          labels={labels}
          params={params}
          removeContactFromList={handleRemoveContact}
          selectedContacts={selectedItems}
          onSelectChange={select}
        />
      )}
      {removeNotPossibleModal.isOpen && (
        <RemoveNotPossibleModal
          isOpen={removeNotPossibleModal.isOpen}
          text={removeModalText}
          title="Delete groups"
          onClose={removeNotPossibleModal.onClose}
        />
      )}
    </Flex>
  );
};
