import {
  Box,
  Checkbox,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Center,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useCallback } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { ApolloError, useApolloClient } from '@apollo/client';
import DateFormat from '@app/components/DateFormat';
import EmptyTable from '@app/components/EmptyTable';
import { LabelList } from '@app/components/LabelList';
import PageSkeleton from '@app/components/PageSkeleton';
import { PageTitle } from '@app/components/PageTitle';
import SearchField from '@app/components/SearchField';
import { GroupCell } from '@app/components/Table/Cells/GroupCell';
import LoadMoreButton from '@app/components/LoadMoreButton';
import CheckboxChecked from '@app/icons/checkbox-checked.svg?react';
import CheckboxPartial from '@app/icons/checkbox-partial.svg?react';
import PhoneNumberFormat from '@app/shared/ui/PhoneNumberFormat';
import ROUTES, { generateUrl } from '@app/utils/routes';

import { createPaginationVariables } from '@app/api/apollo-pagination';
import {
  Contact,
  ContactStatus,
  GetContactsCountersDocument,
  Label,
  UpdateContactMutationVariables,
  useFindContactsQuery,
  useRecoverContactsMutation,
} from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import { useUpdateContactMutation } from '@app/api/mutations/useUpdateContactMutation';
import { DEFAULT_LIMIT } from '@app/api/queries/utils';

import { sortContactsForRequest } from '@app/components/Filter/SortBy/interface';
import { useUpdateContactFragment } from '@app/api/hooks/useUpdateContactFragment';
import { fullTextInputValidation } from '@app/utils/fullTextInputValidation';
import { catchErrorLog, getLogger } from '@app/utils/logger';
import SortBy from '../../../../components/Filter/SortBy';
import { RecoverButton } from '../../components/ActionButtons/RecoverButton';
import { CellTextTooltip } from '../../components/TableList/CellTextTooltip';
import { FavoriteCell } from '../../components/TableList/FavoriteCell';
import { useContacts } from '../../hooks/useContacts';
import { TableWrapper } from '../../utils/styles';
import { RecoverLinkButton } from './components/RecoverLinkButton';

const LOG = getLogger('DeletedContacts');

const DeletedContacts: FC = () => {
  const { contactId } = useParams();
  const client = useApolloClient();
  const navigate = useNavigate();

  const {
    select,
    selectAll,
    reset: resetSelectedItems,
    items: selectedItems,
    sort,
    setSort,
    searchValue,
    setSearchValue,
  } = useContacts();

  const params = {
    filter: {
      ...(fullTextInputValidation(searchValue) && {
        fullSearch: searchValue,
      }),
      status: ContactStatus.DELETED,
    },
    pagination: {
      limit: DEFAULT_LIMIT,
    },
    order: {
      ...sortContactsForRequest[sort],
    },
  };

  const {
    data: { findContacts: contactsData } = {},
    fetchMore,
    refetch: refetchContacts,
    networkStatus,
  } = useFindContactsQuery({
    variables: params,
    notifyOnNetworkStatusChange: true,
  });

  const { isLoading, isLoadingData, isFetchingNextPage } =
    useApolloLoadingStatus(networkStatus);

  const items = contactsData?.items ?? [];

  LOG.debug('items', items);

  const fetchNextPage = () =>
    fetchMore(createPaginationVariables(contactsData, DEFAULT_LIMIT));

  const [mutateRecover] = useRecoverContactsMutation({
    context: {
      notify: {
        success: () => 'Successfully recovered',
        error: (error: ApolloError[]) => error[0].message,
      },
    },
    refetchQueries: [GetContactsCountersDocument],
  });

  const [mutate] = useUpdateContactMutation({
    context: {
      notify: {
        success: () => 'The data was successfully updated',
      },
    },
  });
  const { updateContactFragment } = useUpdateContactFragment();

  const onContactUpdate = useCallback(
    async (values: UpdateContactMutationVariables) => {
      updateContactFragment({
        contactId,
        favorite: values.input?.favorite,
      });
      await mutate({
        variables: values,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactId],
  );

  const handleSelectAll = () => {
    if (!isEmpty(selectedItems)) {
      resetSelectedItems();
      return;
    }

    selectAll(items.map((item) => item.id));
  };

  const handleContactClick = useCallback(
    (id: Contact['id']) => {
      if (contactId === id) {
        navigate(`${ROUTES.contactsDeleted}`);
        return;
      }

      navigate(generateUrl(ROUTES.contactsDeletedInfo, { contactId: id }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactId],
  );

  const recoverContacts = async (ids: string[]) => {
    try {
      await mutateRecover({
        variables: {
          input: {
            ids,
          },
        },
        update(_, _result, { variables }) {
          variables?.input?.ids.forEach((id) => {
            client.cache.evict({
              id: `Conversation:${id}`,
            });
          });
        },
      });
      await refetchContacts();
    } catch (err) {
      catchErrorLog(err, 'DeletedContacts/recoverContacts');
    }
  };

  const getCellBg = (id: Contact['id']) => {
    let background = null;

    if (id === contactId && !selectedItems.includes(id)) {
      background = 'zircon';
    } else if (selectedItems.includes(id)) {
      background = 'primary.100';
    }

    return background;
  };
  return (
    <Box overflow="hidden" width="100%">
      <PageTitle title="Deleted Contacts" />
      <Flex direction="column" overflow="hidden" pt="17px" width="100%">
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          pl="30px"
          pr="16px"
          w="100%"
          width="100%">
          <Flex alignItems="flex-start" mb="20px">
            <Text mt="10px" variant="sub-heading">
              Deleted
            </Text>
          </Flex>
          <Flex direction="row" mb="20px" mt="2px">
            <SearchField onChange={setSearchValue} />
          </Flex>
        </Flex>
        <Flex h="100%">
          {isLoading ? (
            <Box w="100%">
              <PageSkeleton />
            </Box>
          ) : (
            <Box w="100%">
              {!isEmpty(selectedItems) ? (
                <RecoverButton
                  recoverMutation={recoverContacts}
                  resetSelectedItems={resetSelectedItems}
                  selectedItems={selectedItems}
                />
              ) : (
                <Flex height="28px" mb="15px" ml="14px" position="relative">
                  <SortBy
                    isDisabled={isEmpty(items)}
                    value={sort}
                    onChange={setSort}
                  />
                </Flex>
              )}
              <TableWrapper height="calc(100vh - 205px)">
                <Table>
                  <Thead bg="white" position="sticky" top="0" zIndex="10">
                    <Tr>
                      <Th p="0 15px 0 30px" width="45px">
                        {!isEmpty(items) && (
                          <Flex
                            alignItems="center"
                            direction="row"
                            justifyContent="space-between">
                            <Checkbox
                              icon={
                                !isEmpty(selectedItems) ? (
                                  <CheckboxPartial />
                                ) : (
                                  <CheckboxChecked />
                                )
                              }
                              isChecked={!isEmpty(selectedItems)}
                              variant={
                                !isEmpty(selectedItems)
                                  ? 'primaryPartial'
                                  : 'primary'
                              }
                              onChange={() => handleSelectAll()}
                            />
                          </Flex>
                        )}
                      </Th>
                      <Th p="0" />
                      <Th>First name</Th>
                      <Th>Last name</Th>
                      <Th>Phone</Th>
                      <Th>Groups</Th>
                      <Th display={contactId && 'none'}>Date created</Th>
                      <Th display={contactId && 'none'}>Labels</Th>
                      <Th>Action</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items?.map((contact) => (
                      <Tr
                        key={contact?.id}
                        background={getCellBg(contact?.id)}
                        cursor="pointer"
                        onClick={() => handleContactClick(contact?.id)}>
                        <Td p="0 15px 0 30px">
                          <Flex
                            alignItems="center"
                            direction="row"
                            justifyContent="space-between"
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              select(contact?.id);
                            }}>
                            <Checkbox
                              isChecked={selectedItems.includes(contact?.id)}
                              variant="primary"
                            />
                          </Flex>
                        </Td>
                        <Td p="0">
                          <FavoriteCell
                            id={contact?.id}
                            isFavorite={contact?.favorite}
                            onChangeFavorite={onContactUpdate}
                          />
                        </Td>
                        <Td>
                          <CellTextTooltip text={contact.firstName} />
                        </Td>
                        <Td>
                          <CellTextTooltip text={contact.lastName} />
                        </Td>
                        <Td whiteSpace="nowrap">
                          <PhoneNumberFormat value={contact.phone} />
                        </Td>
                        <Td>
                          <GroupCell groups={contact?.groups} />
                        </Td>
                        <Td display={contactId && 'none'}>
                          <DateFormat date={new Date(contact?.createdAt)} />
                        </Td>
                        <Td display={contactId && 'none'}>
                          {isEmpty(contact?.labels) ? (
                            '-'
                          ) : (
                            <Flex h="26px">
                              <LabelList
                                contactId={contact?.id}
                                justifyContent="flex-start"
                                labels={contact?.labels as Label[]}
                                visibleItemsCount={2}
                              />
                            </Flex>
                          )}
                        </Td>
                        <Td>
                          <RecoverLinkButton
                            id={contact?.id}
                            recoverMutation={recoverContacts}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
                {isEmpty(items) && (
                  <Flex
                    position="absolute"
                    top="50%"
                    transform="translate(0, -50%)"
                    w="100%">
                    <EmptyTable />
                  </Flex>
                )}
                {!isEmpty(items) && (
                  <Center>
                    <LoadMoreButton
                      fetchNextPage={fetchNextPage}
                      hasNextPage={contactsData?.hasNext}
                      isFetchingNextPage={isFetchingNextPage}
                      isLoading={isLoadingData}
                    />
                  </Center>
                )}
              </TableWrapper>
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default DeletedContacts;
