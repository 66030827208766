import _truncate from 'lodash/truncate';

const MAX_TEXT_LENGTH = 60;

export const cellTextValidation = (text?: string, length = MAX_TEXT_LENGTH) => {
  const isTooLong = text?.length > length;

  const textCutting = _truncate(text, {
    length,
    separator: '…',
  });

  return { isTooLong, textCutting };
};
