import { OwnPhoneType, Scope } from '@app/api/gql/generated-types';
import { useDlcStatus } from '@app/hooks/useDlcStatus';

import { BuyNumberForm } from './types';
import { useMaxAllowedPhones } from './useMaxAllowedPhones';

export const phoneTypes = [
  {
    label: 'Local number',
    value: OwnPhoneType.LOCAL,
  },
];

export const useInitialValues = (
  isLocalNumbers: boolean,
  scope: Scope,
): BuyNumberForm => {
  const dlcStatus = useDlcStatus();

  const isApproved = scope === Scope.DIALER ? true : dlcStatus.isApproved;

  const { canBuyLocalNumber, canBuyTollFreeNumber } =
    useMaxAllowedPhones(scope);

  let type = OwnPhoneType.LOCAL;
  if (
    !isLocalNumbers &&
    (!isApproved || !canBuyLocalNumber) &&
    canBuyTollFreeNumber
  ) {
    type = OwnPhoneType.TOLL_FREE;
  }

  return {
    type,
    areaCode: null,
  };
};
