import styled from '@emotion/styled';
import { colors } from '@app/theme/colors';
import { Mode } from '../types';

interface TimeTemplateTypes {
  isSelected?: boolean;
  disabled?: boolean;
}

interface TimeWrapStyleTypes {
  mode?: number;
}

const SelectWrap = styled.div<TimeWrapStyleTypes>`
  display: grid;
  width: auto;
  grid-template-columns: 56px 56px 56px;
  height: auto;
  background: ${colors.white};
  border-left: 1px solid ${colors.secondary[200]};
  box-sizing: border-box;
  padding: 15px 0;
  overflow: auto;
  border-radius: ${({ mode }) =>
    (mode as Mode) === Mode.TIME ? '22px' : '0 22px 22px 0'};
`;

const SelectColumn = styled.div`
  border-right: 1px dashed ${colors.secondary[200]};
  padding: 0 12px;
  color: ${colors.main[400]};
  font-size: 14px;
  height: auto;
  overflow: auto;
  text-align: center;

  &:last-child {
    border: none;
    padding: 0 12px;
  }

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const TimeElementStyles = styled.div<TimeTemplateTypes>`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border: ${({ isSelected }) =>
    isSelected ? `1px solid ${colors.primary[600]}` : 'none'};
  border-radius: ${({ isSelected }) => (isSelected ? '50%' : 'none')};
  border-radius: 50%;
  user-select: none;
  color: ${({ isSelected, disabled }) =>
    isSelected
      ? colors.primary[600]
      : disabled
        ? colors.secondary[200]
        : colors.main[400]};
  font-size: 14px;
  line-height: 32px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export { SelectWrap, SelectColumn, TimeElementStyles };
