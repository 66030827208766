import { colors } from '@app/theme/colors';

export const CUSTOM_MENTION_DROPDOWN_STYLES = {
  '&multiLine': {
    control: {
      height: 132,
      fontSize: '14px',
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: '12px 20px',
      borderRadius: '22px',
      color: colors.main[400],
    },
  },

  suggestions: {
    backgroundColor: 'white',
    border: `1px solid ${colors.secondary[200]}`,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)',
    borderRadius: '22px',
    marginTop: '24px',
    list: {
      maxHeight: '224px',
      overflowY: 'auto',
      fontSize: 14,
      fontWeight: 400,
      fontFamily: 'inherit',
      margin: '10px 0px',
      padding: '0px 8px',
    },
    item: {
      color: colors.main[400],
      padding: '10px 12px',
      '&focused': {
        backgroundColor: colors.primary[200],
        borderRadius: 22,
        color: colors.primary[700],
      },
    },
  },
};

export const TRIGGER = '<';
