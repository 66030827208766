import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { Enum } from '@app/utils/enum';
import { colors } from '../colors';

export const TCloseButtonVariant = new Enum('modal');

export const CloseButton = defineStyleConfig({
  variants: {
    [TCloseButtonVariant.enum.modal]: defineStyle({
      background: 'white',
      color: colors.osloGray,
      _hover: {
        background: colors.notFound[6600],
      },
    }),
  },
});
