import React, { FC, useMemo } from 'react';
import {
  Box,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Icon,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import isNil from 'lodash/isNil';

import CrossIcon from '@app/icons/CrossIcon.svg?react';
import FilterArrowIcon from '@app/icons/filter-arrow-icon.svg?react';
import { Header } from '@app/types/Contact';
import { Field, SkiptraceField } from '@app/api/gql/generated-types';

import { colors } from '@app/theme/colors';
import { HeaderSelectItem } from './HeaderSelectItem';

interface OptionsListProps {
  columnId: number | null;
  headerLabel: string;
  childrenOptions: Field[][] | SkiptraceField[][];
  onSelect: (label: string, key: number) => void;
  headers: Header[] | string[];
  editable: boolean;
}

const HeaderSelect: FC<OptionsListProps> = ({
  columnId,
  headerLabel,
  headers,
  childrenOptions,
  onSelect,
  editable,
}) => {
  const isNotSelected = useMemo(
    () => headers[columnId] === `Head${columnId}` || isNil(headers[columnId]),
    [columnId, headers],
  );
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleOnChange = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    onSelect(headers[columnId], columnId);
  };

  if (!editable) {
    return (
      <Box display="block" role="group">
        <Flex
          bg="none"
          borderRadius="24px"
          color="main.400"
          fontSize="14px"
          justifyContent="flex-start"
          marginInlineStart="0px !important"
          padding="10px 15px"
          transition="all .3s ease"
          width="100%">
          {headerLabel}
        </Flex>
      </Box>
    );
  }

  return (
    <Box display="block" role="group">
      <Flex>
        {!childrenOptions && (
          <Flex alignItems="center">
            <Box
              color="main.400"
              fontSize="14px"
              fontWeight={500}
              p="6px 10px"
              transition="all .3s ease">
              {headerLabel}
            </Box>
          </Flex>
        )}
        {childrenOptions && (
          <Popover
            closeOnBlur
            gutter={3}
            isOpen={isOpen}
            placement="bottom-start"
            returnFocusOnClose={false}
            onClose={onClose}>
            {() => (
              <>
                <PopoverTrigger>
                  <Flex
                    alignItems="center"
                    bg={isOpen ? 'white' : 'none'}
                    borderRadius="24px"
                    color={isOpen ? 'main.400' : 'main.300'}
                    cursor="pointer"
                    fontSize="14px"
                    justifyContent="flex-start"
                    padding="10px 15px"
                    transition="all .3s ease"
                    userSelect="none"
                    onClick={onToggle}>
                    {headerLabel}
                    <IconButton
                      _active={{ background: 'unset' }}
                      _focus={{ bg: 'unset' }}
                      _hover={{ color: 'primary.600' }}
                      aria-label="Select"
                      color="main.100"
                      icon={
                        isNotSelected ? (
                          <FilterArrowIcon />
                        ) : (
                          <Icon as={CrossIcon} height="10px" width="10px" />
                        )
                      }
                      size="xs"
                      transform={isOpen ? 'rotate(270deg)' : 'rotate(90deg)'}
                      transition="all .3s ease"
                      variant="ghost"
                      onClick={isNotSelected ? null : handleOnChange}
                    />
                  </Flex>
                </PopoverTrigger>

                <PopoverContent
                  _focus={{
                    outline: '0',
                    boxShadow: '0px 6px 18px rgba(139, 139, 139, 0.25)',
                  }}
                  border={0}
                  boxShadow="0px 6px 18px rgba(139, 139, 139, 0.25)"
                  outline="0"
                  rounded="xl"
                  width="100%">
                  <Flex p="20px">
                    {childrenOptions.map((labels, index) => (
                      <Flex
                        key={`header_${index}`}
                        _notLast={{
                          paddingRight: '20px',
                          marginRight: '20px',
                          borderRight: `1px solid ${colors.mystic}`,
                        }}
                        flexDirection="column"
                        minWidth="115px">
                        {labels.map((item) => (
                          <HeaderSelectItem
                            key={`header_${item.id}`}
                            headers={headers}
                            item={item}
                            onClose={onClose}
                            onSelect={() => onSelect(item.id, columnId)}
                          />
                        ))}
                      </Flex>
                    ))}
                  </Flex>
                </PopoverContent>
              </>
            )}
          </Popover>
        )}
      </Flex>
    </Box>
  );
};

export default HeaderSelect;
