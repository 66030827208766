import React, { FC } from 'react';
import { ConversationCategory } from '@app/api/gql/generated-types';

import { InboxMessages } from '../components/InboxMessages';
import { MessengerFolderType } from '../types';

const InboxSent: FC = () => {
  return (
    <InboxMessages
      path="inbox/sent"
      queryParams={{
        category: ConversationCategory.SENT,
      }}
      title="Sent"
      type={MessengerFolderType.SENT}
    />
  );
};

export default InboxSent;
