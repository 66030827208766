import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import { isSubmitDisabled } from '@app/utils/validation';

interface FormSubmitProps {
  isLoading: boolean;
  isModal: boolean;
  title: string;
  width?: string;
}

export const FormSubmit: FC<FormSubmitProps> = ({
  isLoading,
  isModal,
  title,
  width = '130px',
}) => {
  const formik = useFormikContext();
  const isButtonDisabled = isSubmitDisabled(formik, false, []);

  const { handleSubmit } = formik;

  return (
    <Button
      height="40px"
      isDisabled={isButtonDisabled}
      isLoading={isLoading}
      variant="primary"
      width={isModal ? '100%' : width}
      onClick={() => handleSubmit()}>
      {title}
    </Button>
  );
};
