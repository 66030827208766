import { useContext } from 'react';

import { CurrentAccountContext } from '@app/providers/CurrentAccountProvider';

import { usePopupsContext } from './usePopupsContext';

/**
 *  @deprecated Use the new permission Features & permission modal. waiting for new modal designs from design team
 * */
const useActivateDialerSubscriptionGuard = (
  handler: (...args: any) => void | Promise<void>,
) => {
  const {
    isActiveWithActiveDialerPlan,
    isActiveWithInactiveDialerPlan,
    isInactiveWithInactiveDialerPlan,
    isDialerPlanCancelled,
  } = useContext(CurrentAccountContext);

  const {
    activateAccountModal: { onOpen },
    activateDialerSubscriptionModal: {
      onOpen: onOpenActivateDialerSubscriptionModal,
    },
    subscriptionAccessDeniedModal: {
      onOpen: onOpenSubscriptionAccessDeniedModal,
    },
  } = usePopupsContext();

  if (isActiveWithInactiveDialerPlan || isInactiveWithInactiveDialerPlan) {
    return onOpenSubscriptionAccessDeniedModal;
  }

  if (isDialerPlanCancelled) {
    return onOpenActivateDialerSubscriptionModal;
  }

  if (isActiveWithActiveDialerPlan) {
    return handler;
  }

  return onOpen;
};

export default useActivateDialerSubscriptionGuard;
