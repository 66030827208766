export enum ExceptionCode {
  INTERNAL_SERVER = 'INTERNAL_SERVER',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  AUTHORIZATION = 'AUTHORIZATION',
  AUTHENTICATION = 'AUTHENTICATION',
  BAD_REQUEST = 'BAD_REQUEST',
  CONFLICT = 'CONFLICT',
  NOT_FOUND = 'NOT_FOUND',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED',
  VALIDATION = 'VALIDATION',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  ENTITY_NAME_ERROR = 'ENTITY_NAME_ERROR',
  TEMPLATE_CONTENT_ERROR = 'TEMPLATE_CONTENT_ERROR',
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  TEMPLATE_MESSAGE_TYPE_ERROR = 'TEMPLATE_MESSAGE_TYPE_ERROR',
  FORBIDDEN = 'FORBIDDEN',
  MONTHLY_LIMIT_EXCEEDED_HIGHER_PLAN = 'MONTHLY_LIMIT_EXCEEDED_HIGHER_PLAN',
  PROMO_CODE_NOT_FOUND_ERROR = 'PROMO_CODE_NOT_FOUND_ERROR',
  PROMO_CODE_EXPIRED_ERROR = 'PROMO_CODE_EXPIRED_ERROR',
  PROMO_CODE_CUSTOMER_ERROR = 'PROMO_CODE_CUSTOMER_ERROR',
  PROMO_CODE_PRODUCT_ERROR = 'PROMO_CODE_PRODUCT_ERROR',
  PROMO_CODE_ORDER_ERROR = 'PROMO_CODE_ORDER_ERROR',
  OUTSIDE_BUSINESS_HOURS = 'OUTSIDE_BUSINESS_HOURS',
}

export const GlobalExceptionCode = {
  [ExceptionCode.INTERNAL_SERVER]: 'Internal server error',
  [ExceptionCode.AUTHORIZATION]: 'Authorization error',
  [ExceptionCode.AUTHENTICATION]: 'Authentication error',
  [ExceptionCode.BAD_REQUEST]: 'Bad request',
  [ExceptionCode.CONFLICT]: 'Conflict',
  [ExceptionCode.NOT_FOUND]: 'Not found',
  [ExceptionCode.NOT_IMPLEMENTED]: 'Not implemented',
  [ExceptionCode.VALIDATION]: 'Validation error',
  [ExceptionCode.GRAPHQL_VALIDATION_FAILED]: 'GraphQL validation failed',
  [ExceptionCode.MONTHLY_LIMIT_EXCEEDED_HIGHER_PLAN]:
    'monthly-limit-exceeded-higher-plan',
  [ExceptionCode.PROMO_CODE_NOT_FOUND_ERROR]: 'code',
  [ExceptionCode.PROMO_CODE_EXPIRED_ERROR]: 'code',
  [ExceptionCode.PROMO_CODE_CUSTOMER_ERROR]: 'code',
  [ExceptionCode.PROMO_CODE_PRODUCT_ERROR]: 'code',
  [ExceptionCode.PROMO_CODE_ORDER_ERROR]: 'code',
};
