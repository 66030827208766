import React, { FC } from 'react';

import { formatPhone } from '@app/utils/formatPhone';

interface PhoneNumberFormatProps {
  value: string;
}

const PhoneNumberFormat: FC<PhoneNumberFormatProps> = ({ value }) => (
  <>{formatPhone(value)}</>
);

export default PhoneNumberFormat;
