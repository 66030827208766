import React, { FC } from 'react';
import { Box, IconButton, PopoverTrigger, Tooltip } from '@chakra-ui/react';
import DownloadIcon from '@app/icons/download-icon.svg?react';

export const ExportPopoverTrigger: FC = () => {
  return (
    <PopoverTrigger>
      <Box>
        <Tooltip hasArrow label="Export contact" placement="top">
          <IconButton
            isRound
            aria-label="export-to-button"
            icon={<DownloadIcon />}
            size="sm"
            variant="iconButton"
          />
        </Tooltip>
      </Box>
    </PopoverTrigger>
  );
};
