import { Flex, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { BrowserView } from 'react-device-detect';

import { isNil } from 'lodash/fp';
import { useLabelsQueryData } from '@app/api/queries/useLabelsQueryData';
import Details from '@app/components/Details';
import EmptyList from '@app/components/EmptyList';
import { useQueryParams } from '@app/hooks/useQueryParams';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import DeletedIcon from '@app/icons/deleted-messages-icon.svg?react';
import GroupCirclesIcon from '@app/icons/group-circles-icon.svg?react';
import InboxIcon from '@app/icons/inbox-icon.svg?react';
import LabelIcon from '@app/icons/label-icon.svg?react';
import Loudspeaker from '@app/icons/loud-speaker-icon.svg?react';
import OptedOutIcon from '@app/icons/opted-out-icon.svg?react';
import SentIcon from '@app/icons/sent-icon.svg?react';
import UnreadIcon from '@app/icons/unread-icon.svg?react';
import { INBOX } from '@app/pages/Messenger/routes';
import { MacrosTitleLink } from '@app/pages/Messenger/styles';
import ROUTES from '@app/utils/routes';

import { createPaginationVariables } from '@app/api/apollo-pagination';
import {
  CampaignStatus,
  OrderQuery,
  useFindAlertGroupsQuery,
  useFindListCampaignsQuery,
  useGetUnreadConversationsCountersQuery,
  useUnreadConversationsMessagesCounterUpdatedSubscription,
  useMissedConversationsCallsCounterUpdatedSubscription,
} from '@app/api/gql/generated-types';
import { customScrollbarCssProps } from '@app/utils/customScrollbar';
import { MAX_COUNTER_UNREAD_MESSAGES } from '@app/constants/configuration';
import { CounterBadge } from '@app/components/next/moleculas/CounterBadge';
import { getLogger } from '@app/utils/logger';
import { MissedCallsLink } from './MissedCallsLink';
import {
  SideBarLinkGroupSubMenu,
  SideBarLinkLabelSubMenu,
  SideBarLinkSubMenu,
} from './styles';

const MENU_ITEM_MAX_INLINE_LENGTH = 17;

const LOG = getLogger('Menu');

export const Menu = () => {
  const [unreadConversationsCounter, setUnreadConversationsCounter] =
    useState(0);
  const [missedCallsConversationsCounter, setMissedCallsConversationsCounter] =
    useState(0);

  const account = useCurrentAccountData();

  const [{ profileId }, queryString] = useQueryParams<{
    profileId: string;
  }>();

  const {
    data: labels,
    fetchNextPage,
    nextPageToken,
    isFetchingNextPage,
    isLoading,
  } = useLabelsQueryData();

  LOG.debug('labels', labels);
  LOG.debug('unreadConversationsCounter', unreadConversationsCounter);
  LOG.debug('missedCallsConversationsCounter', missedCallsConversationsCounter);

  const {
    data: { findCampaigns } = {},
    fetchMore: fetchMoreCampaigns,
    loading: isLoadingCampaigns,
  } = useFindListCampaignsQuery({
    variables: {
      filter: {
        status: [
          CampaignStatus.ACTIVE,
          CampaignStatus.PAUSED,
          CampaignStatus.ENDED,
        ],
      },
    },
  });

  const fetchNextCampaigns = () =>
    fetchMoreCampaigns(createPaginationVariables(findCampaigns));

  const labelsQueryProps = {
    fetchNextPage,
    hasNextPage: !isNil(nextPageToken),
    isFetchingNextPage,
    isLoading,
  };

  const campaigns = findCampaigns?.items ?? [];

  const params = {
    filter: {
      verified: true,
    },
    order: {
      direction: OrderQuery.DESC,
    },
  };

  const {
    data: { findGroups } = {},
    loading: isLoadingGroups,
    fetchMore,
  } = useFindAlertGroupsQuery({
    variables: params,
  });

  const groupItems = findGroups?.items ?? [];

  const fetchNextPageGroups = () =>
    fetchMore(createPaginationVariables(findGroups));

  const { data: { getUnreadConversationsCounters } = {} } =
    useGetUnreadConversationsCountersQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        profileId,
      },
    });

  useUnreadConversationsMessagesCounterUpdatedSubscription({
    onData: ({ data }) => {
      const updateProfileId =
        data.data.unreadConversationsMessagesCounterUpdated.profileId;
      if (
        profileId === updateProfileId ||
        (!profileId && account.id === updateProfileId)
      ) {
        setUnreadConversationsCounter(
          Math.min(
            MAX_COUNTER_UNREAD_MESSAGES,
            data.data.unreadConversationsMessagesCounterUpdated.counter,
          ),
        );
      }
    },
    variables: {
      profileId,
    },
  });

  useMissedConversationsCallsCounterUpdatedSubscription({
    onData: ({ data }) => {
      const updateProfileId =
        data.data.missedConversationsCallsCounterUpdated.profileId;
      if (
        profileId === updateProfileId ||
        (!profileId && account.id === updateProfileId)
      ) {
        setMissedCallsConversationsCounter(
          Math.min(
            MAX_COUNTER_UNREAD_MESSAGES,
            data.data.missedConversationsCallsCounterUpdated.counter,
          ),
        );
      }
    },
    variables: {
      profileId,
    },
  });

  useEffect(() => {
    const { withUnreadMessages, withMissedCalls } =
      getUnreadConversationsCounters || {};

    if (getUnreadConversationsCounters) {
      setUnreadConversationsCounter(
        Math.min(MAX_COUNTER_UNREAD_MESSAGES, withUnreadMessages),
      );
      setMissedCallsConversationsCounter(
        Math.min(MAX_COUNTER_UNREAD_MESSAGES, withMissedCalls),
      );
    }
  }, [getUnreadConversationsCounters]);

  return (
    <Flex
      direction="column"
      height="100%"
      justifyContent="space-between"
      overflow="hidden">
      <List
        __css={customScrollbarCssProps}
        mt={['0px', '22px']}
        overflowY="auto"
        pb="15px"
        pr="10px"
        style={{ listStyleType: 'none', overflowX: 'hidden' }}>
        <Details isOpen title="Inbox">
          <ListItem>
            <SideBarLinkSubMenu
              className="centered"
              to={`${INBOX.all}${queryString}`}>
              <ListIcon as={InboxIcon} />
              <Text>All</Text>
            </SideBarLinkSubMenu>
          </ListItem>
          <ListItem>
            <SideBarLinkSubMenu
              className="centered showCounter"
              to={`${INBOX.unread}${queryString}`}>
              <Flex align="center" justify="center">
                <ListIcon as={UnreadIcon} />
                <Text>Unread</Text>
              </Flex>
              <CounterBadge counter={unreadConversationsCounter} />
            </SideBarLinkSubMenu>
          </ListItem>
          <ListItem>
            <MissedCallsLink
              accountId={queryString}
              missedCallsConversationsCounter={missedCallsConversationsCounter}
            />
          </ListItem>
          <ListItem>
            <SideBarLinkSubMenu
              className="centered"
              to={`${INBOX.sent}${queryString}`}>
              <ListIcon as={SentIcon} />
              <Text>Sent</Text>
            </SideBarLinkSubMenu>
          </ListItem>
          <ListItem>
            <SideBarLinkSubMenu
              className="centered"
              to={`${INBOX.optedOut}${queryString}`}>
              <ListIcon as={OptedOutIcon} id="OptedOutIcon" />
              <Text>Opted out</Text>
            </SideBarLinkSubMenu>
          </ListItem>
          <ListItem>
            <SideBarLinkSubMenu
              className="centered"
              to={`${INBOX.deleted}${queryString}`}>
              <ListIcon as={DeletedIcon} />
              <Text>Deleted</Text>
            </SideBarLinkSubMenu>
          </ListItem>
        </Details>
        <Details
          isAnimate
          isLoadMore
          fetchNextPage={fetchNextCampaigns}
          hasNextPage={findCampaigns?.hasNext}
          isFetchingNextPage={isLoadingCampaigns}
          isLoading={isLoadingCampaigns}
          title="Campaigns">
          {campaigns?.map((entity) => {
            return (
              <ListItem key={entity.id}>
                <SideBarLinkSubMenu
                  className={
                    entity.name.length >= MENU_ITEM_MAX_INLINE_LENGTH
                      ? ''
                      : 'centered'
                  }
                  to={`${INBOX.campaigns}/${entity.id}${queryString}`}>
                  <ListIcon as={Loudspeaker} />
                  <Text>{entity.name}</Text>
                </SideBarLinkSubMenu>
              </ListItem>
            );
          })}
          {isEmpty(campaigns) && <EmptyList type="campaigns" />}
        </Details>
        <Details isAnimate isLoadMore title="Labels" {...labelsQueryProps}>
          {labels?.map((entity) => {
            return (
              <ListItem key={entity.id}>
                <SideBarLinkLabelSubMenu
                  className={
                    entity.title.length >= MENU_ITEM_MAX_INLINE_LENGTH
                      ? ''
                      : 'centered'
                  }
                  to={`${INBOX.labels}/${entity?.id}${queryString}`}>
                  <ListIcon as={LabelIcon} />
                  <Text>{entity.title}</Text>
                </SideBarLinkLabelSubMenu>
              </ListItem>
            );
          })}
          {isEmpty(labels) && <EmptyList type="labels" />}
        </Details>
        <Details
          isAnimate
          isLoadMore
          fetchNextPage={fetchNextPageGroups}
          hasNextPage={findGroups?.hasNext}
          isFetchingNextPage={isLoadingGroups}
          isLoading={isLoadingGroups}
          title="Groups">
          {groupItems.map((entity) => (
            <ListItem key={entity.id}>
              <SideBarLinkGroupSubMenu
                className={
                  entity.name.length >= MENU_ITEM_MAX_INLINE_LENGTH
                    ? ''
                    : 'centered'
                }
                to={`${INBOX.groups}/${entity.id}${queryString}`}>
                <ListIcon as={GroupCirclesIcon} />
                <Text>{entity.name}</Text>
              </SideBarLinkGroupSubMenu>
            </ListItem>
          ))}
          {isEmpty(groupItems) && <EmptyList type="groups" />}
        </Details>
      </List>
      <BrowserView>
        <Flex
          alignItems="center"
          borderTop="1px"
          borderTopColor="mystic"
          direction="row"
          justifyContent="space-between"
          p="10px 14px 10px 26px">
          <MacrosTitleLink
            data-cy="add-macros-icon-button"
            to={`/messenger${ROUTES.macros}`}>
            Macros
          </MacrosTitleLink>
        </Flex>
      </BrowserView>
    </Flex>
  );
};
