import React, { FC } from 'react';
import {
  BoxProps,
  Center,
  Flex,
  FormLabel,
  Spacer,
  Text,
} from '@chakra-ui/react';

interface NotificationSettingsLineProps extends BoxProps {
  name: string;
  label: string;
  description: string;
}

const NotificationSettingsLine: FC<NotificationSettingsLineProps> = ({
  name,
  label,
  description,
  children,
}) => {
  return (
    <Flex justifyContent="space-between" marginBottom="20px" width="400px">
      <Flex width="331px">
        <FormLabel
          fontSize="14px"
          fontStyle="normal"
          fontWeight="400px"
          htmlFor={name}
          lineHeight="normal"
          mb="0">
          <Text mb="10px">{label}</Text>
          <Text color="secondary.400">{description}</Text>
        </FormLabel>
        <Spacer />
      </Flex>
      <Center flex="1" maxWidth="170px" ml="31px">
        {children}
      </Center>
    </Flex>
  );
};

export default NotificationSettingsLine;
