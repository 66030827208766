import { useTollFreeStatus } from '@app/pages/Settings/content/BrandRegistration/hooks/useTollFreeStatus';
import {
  AccountStatus,
  Product,
  SubscriptionStatus,
} from '@app/api/gql/generated-types';
import { useCurrentAccountContext } from '@app/providers/CurrentAccountProvider';
import { useDlcStatus } from './useDlcStatus';
import { usePopupsContext } from './usePopupsContext';

/**
 * @deprecated Use the new permission Features & permission modal. waiting for new modal designs from design team
 */
const use10DlcAccountStatusGuard = (
  handler: (...args: any) => void | Promise<void>,
  isIgnore = false,
) => {
  const { isPending, isApproved } = useDlcStatus();
  const { hasSubscriptionStatus } = useCurrentAccountContext();

  const hasTrialingMessagingSubscription = hasSubscriptionStatus({
    subscription: Product.MESSAGING,
    status: [SubscriptionStatus.TRIALING],
    accountStatus: [AccountStatus.TRIAL, AccountStatus.ACTIVE],
  });

  const { isPending: isPendingTollFree, isApproved: isApprovedTollFree } =
    useTollFreeStatus();

  const {
    dlcSubmitModal: { onOpen },
    dlcPendingStatusModal: { onOpen: onOpenPendingModal },
  } = usePopupsContext();

  if (isIgnore || isPendingTollFree || isApprovedTollFree || isApproved) {
    return handler;
  }

  if (hasTrialingMessagingSubscription) {
    return handler;
  }

  if (isPending) {
    return onOpenPendingModal;
  }

  return onOpen;
};

export default use10DlcAccountStatusGuard;
