import { useMemo } from 'react';
import { A2PInfoStatus, useAccountQuery } from '@app/api/gql/generated-types';
import {
  ExtraFeature,
  ExtraFeatureLimitType,
  extraFeaturesTypes,
} from '@app/pages/Settings/content/DlcRegistration/components/DlcForm/types';

const a2pNoBrandStatuses = [A2PInfoStatus.NONE];
const pendingStatuses = [
  A2PInfoStatus.PENDING,
  A2PInfoStatus.REVIEW,
  A2PInfoStatus.REACTIVATION_SUBMITTED,
];
const a2pExistingBrandStatuses = [
  A2PInfoStatus.APPROVED,
  A2PInfoStatus.PENDING,
  A2PInfoStatus.REVIEW,
];

export const useDlcStatus = () => {
  const { data: { account: accountData } = {}, refetch } = useAccountQuery();

  const a2pStatus = accountData?.messagingProfile?.a2pInfo?.status;
  const a2pInfoStatus = useMemo(() => a2pStatus, [a2pStatus]);

  const extraFeatures: ExtraFeature[] = useMemo(() => {
    const filteredFeatures = accountData?.features
      ?.filter((feature) => extraFeaturesTypes.includes(feature.type))
      ?.map((feature) => ({
        type: feature.type as ExtraFeatureLimitType,
        title: feature.title,
        price: Number(feature.price),
      }));

    // Sort features based on the order defined in extraFeaturesTypes
    const sortedFeatures = extraFeaturesTypes
      .map((featureType) =>
        filteredFeatures?.find((feature) => feature.type === featureType),
      )
      .filter(Boolean);
    return sortedFeatures;
  }, [accountData?.features]);

  const isSecondaryAccount = useMemo(
    () => accountData?.isSubAccount,
    [accountData?.isSubAccount],
  );

  const isNoBrand = a2pStatus && a2pNoBrandStatuses.includes(a2pStatus);
  const statusExists =
    a2pStatus && a2pExistingBrandStatuses.includes(a2pStatus);

  const isPending = useMemo(
    () => pendingStatuses.includes(a2pStatus),
    [a2pStatus],
  );

  const isApproved = useMemo(
    () => a2pStatus === A2PInfoStatus.APPROVED,
    [a2pStatus],
  );

  const isDeactivated = useMemo(
    () => a2pStatus === A2PInfoStatus.DEACTIVATED,
    [a2pStatus],
  );

  const isDeclined = useMemo(
    () => a2pStatus === A2PInfoStatus.DECLINED,
    [a2pStatus],
  );

  return {
    a2pMaxTpm: accountData?.messagingProfile?.a2pInfo?.maxTpm,
    isNoBrand,
    isPending,
    isDeclined,
    isApproved,
    statusExists,
    a2pInfoStatus,
    isSecondaryAccount,
    isDeactivated,
    isDocRequired: accountData?.messagingProfile?.isEINDocRequired,
    refetch,
    extraFeatures,
  };
};
