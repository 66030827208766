import React, { FC, PropsWithChildren } from 'react';
import { Tooltip, Text, useDisclosure } from '@chakra-ui/react';

interface TooltipCellProps extends PropsWithChildren {
  ids?: string[];
  message?: string;
}

export const TooltipCell: FC<TooltipCellProps> = ({ children, message }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const label = (
    <Text color="main.400" fontSize="14px">
      {message}
    </Text>
  );

  return (
    <Tooltip
      hasArrow
      isOpen={isOpen}
      label={label}
      p="10px"
      placement="top"
      onClose={onClose}
      onOpen={onOpen}>
      <Text color="main.400" fontSize="14px" whiteSpace="nowrap">
        {children}
      </Text>
    </Tooltip>
  );
};
