import map from 'lodash/map';

export type PhoneCodesType = {
  areaCode: string;
  state?: string;
  stateCode?: string;
};

export const tollFreePhoneCodes: PhoneCodesType[] = [
  {
    areaCode: '800',
    state: 'US',
  },
  {
    areaCode: '888',
    state: 'US',
  },
  {
    areaCode: '877',
    state: 'US',
  },
  {
    areaCode: '866',
    state: 'US',
  },
  {
    areaCode: '855',
    state: 'US',
  },
  {
    areaCode: '844',
    state: 'US',
  },
  {
    areaCode: '833',
    state: 'US',
  },
];

export const localPhonesByAreaCode: Record<string, string[]> = {
  '201': ['New Jersey', 'NJ'],
  '202': ['Washington, District Of Columbia', 'DC'],
  '203': ['Connecticut', 'CT'],
  '205': ['Alabama', 'AL'],
  '206': ['Washington', 'WA'],
  '207': ['Maine', 'ME'],
  '208': ['Idaho', 'ID'],
  '209': ['California', 'CA'],
  '210': ['Texas', 'TX'],
  '212': ['New York', 'NY'],
  '213': ['California', 'CA'],
  '214': ['Texas', 'TX'],
  '215': ['Pennsylvania', 'PA'],
  '216': ['Ohio', 'OH'],
  '217': ['Illinois', 'IL'],
  '218': ['Minnesota', 'MN'],
  '219': ['Indiana', 'IN'],
  '220': ['Ohio', 'OH'],
  '223': ['Pennsylvania', 'PA'],
  '224': ['Illinois', 'IL'],
  '225': ['Louisiana', 'LA'],
  '227': ['Maryland', 'MD'],
  '228': ['Mississippi', 'MS'],
  '229': ['Georgia', 'GA'],
  '231': ['Michigan', 'MI'],
  '234': ['Ohio', 'OH'],
  '235': ['Missouri', 'MO'],
  '239': ['Florida', 'FL'],
  '240': ['Maryland', 'MD'],
  '248': ['Michigan', 'MI'],
  '251': ['Alabama', 'AL'],
  '252': ['North Carolina', 'NC'],
  '253': ['Washington', 'WA'],
  '254': ['Texas', 'TX'],
  '256': ['Alabama', 'AL'],
  '260': ['Indiana', 'IN'],
  '262': ['Wisconsin', 'WI'],
  '267': ['Pennsylvania', 'PA'],
  '269': ['Michigan', 'MI'],
  '270': ['Kentucky', 'KY'],
  '272': ['Pennsylvania', 'PA'],
  '274': ['Wisconsin', 'WI'],
  '276': ['Virginia', 'VA'],
  '279': ['California', 'CA'],
  '281': ['Texas', 'TX'],
  '283': ['Ohio', 'OH'],
  '301': ['Maryland', 'MD'],
  '302': ['Delaware', 'DE'],
  '303': ['Colorado', 'CO'],
  '304': ['West Virginia', 'WV'],
  '305': ['Florida', 'FL'],
  '307': ['Wyoming', 'WY'],
  '308': ['Nebraska', 'NE'],
  '309': ['Illinois', 'IL'],
  '310': ['California', 'CA'],
  '312': ['Illinois', 'IL'],
  '313': ['Michigan', 'MI'],
  '314': ['Missouri', 'MO'],
  '315': ['New York', 'NY'],
  '316': ['Kansas', 'KS'],
  '317': ['Indiana', 'IN'],
  '318': ['Louisiana', 'LA'],
  '319': ['Iowa', 'IA'],
  '320': ['Minnesota', 'MN'],
  '321': ['Florida', 'FL'],
  '323': ['California', 'CA'],
  '324': ['Florida', 'FL'],
  '325': ['Texas', 'TX'],
  '326': ['Ohio', 'OH'],
  '327': ['Arkansas', 'AR'],
  '329': ['New York', 'NY'],
  '330': ['Ohio', 'OH'],
  '331': ['Illinois', 'IL'],
  '332': ['New York', 'NY'],
  '334': ['Alabama', 'AL'],
  '336': ['North Carolina', 'NC'],
  '337': ['Louisiana', 'LA'],
  '339': ['Massachusetts', 'MA'],
  '340': ['Virgin Islands', 'VI'],
  '341': ['California', 'CA'],
  '346': ['Texas', 'TX'],
  '347': ['New York', 'NY'],
  '350': ['California', 'CA'],
  '351': ['Massachusetts', 'MA'],
  '352': ['Florida', 'FL'],
  '353': ['Wisconsin', 'WI'],
  '360': ['Washington', 'WA'],
  '361': ['Texas', 'TX'],
  '363': ['New York', 'NY'],
  '364': ['Kentucky', 'KY'],
  '369': ['California', 'CA'],
  '380': ['Ohio', 'OH'],
  '385': ['Utah', 'UT'],
  '386': ['Florida', 'FL'],
  '401': ['Rhode Island', 'RI'],
  '402': ['Nebraska', 'NE'],
  '404': ['Georgia', 'GA'],
  '405': ['Oklahoma', 'OK'],
  '406': ['Montana', 'MT'],
  '407': ['Florida', 'FL'],
  '408': ['California', 'CA'],
  '409': ['Texas', 'TX'],
  '410': ['Maryland', 'MD'],
  '412': ['Pennsylvania', 'PA'],
  '413': ['Massachusetts', 'MA'],
  '414': ['Wisconsin', 'WI'],
  '415': ['California', 'CA'],
  '417': ['Missouri', 'MO'],
  '419': ['Ohio', 'OH'],
  '423': ['Tennessee', 'TN'],
  '424': ['California', 'CA'],
  '425': ['Washington', 'WA'],
  '430': ['Texas', 'TX'],
  '432': ['Texas', 'TX'],
  '434': ['Virginia', 'VA'],
  '435': ['Utah', 'UT'],
  '436': ['Ohio', 'OH'],
  '440': ['Ohio', 'OH'],
  '442': ['California', 'CA'],
  '443': ['Maryland', 'MD'],
  '445': ['Pennsylvania', 'PA'],
  '447': ['Illinois', 'IL'],
  '448': ['Florida', 'FL'],
  '458': ['Oregon', 'OR'],
  '463': ['Indiana', 'IN'],
  '464': ['Illinois', 'IL'],
  '469': ['Texas', 'TX'],
  '470': ['Georgia', 'GA'],
  '472': ['North Carolina', 'NC'],
  '475': ['Connecticut', 'CT'],
  '478': ['Georgia', 'GA'],
  '479': ['Arkansas', 'AR'],
  '480': ['Arizona', 'AZ'],
  '484': ['Pennsylvania', 'PA'],
  '501': ['Arkansas', 'AR'],
  '502': ['Kentucky', 'KY'],
  '503': ['Oregon', 'OR'],
  '504': ['Louisiana', 'LA'],
  '505': ['New Mexico', 'NM'],
  '507': ['Minnesota', 'MN'],
  '508': ['Massachusetts', 'MA'],
  '509': ['Washington', 'WA'],
  '510': ['California', 'CA'],
  '512': ['Texas', 'TX'],
  '513': ['Ohio', 'OH'],
  '515': ['Iowa', 'IA'],
  '516': ['New York', 'NY'],
  '517': ['Michigan', 'MI'],
  '518': ['New York', 'NY'],
  '520': ['Arizona', 'AZ'],
  '530': ['California', 'CA'],
  '531': ['Nebraska', 'NE'],
  '534': ['Wisconsin', 'WI'],
  '539': ['Oklahoma', 'OK'],
  '540': ['Virginia', 'VA'],
  '541': ['Oregon', 'OR'],
  '551': ['New Jersey', 'NJ'],
  '557': ['Missouri', 'MO'],
  '559': ['California', 'CA'],
  '561': ['Florida', 'FL'],
  '562': ['California', 'CA'],
  '563': ['Iowa', 'IA'],
  '564': ['Washington', 'WA'],
  '567': ['Ohio', 'OH'],
  '570': ['Pennsylvania', 'PA'],
  '571': ['Virginia', 'VA'],
  '572': ['Oklahoma', 'OK'],
  '573': ['Missouri', 'MO'],
  '574': ['Indiana', 'IN'],
  '575': ['New Mexico', 'NM'],
  '580': ['Oklahoma', 'OK'],
  '582': ['Pennsylvania', 'PA'],
  '585': ['New York', 'NY'],
  '586': ['Michigan', 'MI'],
  '601': ['Mississippi', 'MS'],
  '602': ['Arizona', 'AZ'],
  '603': ['New Hampshire', 'NH'],
  '605': ['South Dakota', 'SD'],
  '606': ['Kentucky', 'KY'],
  '607': ['New York', 'NY'],
  '608': ['Wisconsin', 'WI'],
  '609': ['New Jersey', 'NJ'],
  '610': ['Pennsylvania', 'PA'],
  '612': ['Minnesota', 'MN'],
  '614': ['Ohio', 'OH'],
  '615': ['Tennessee', 'TN'],
  '616': ['Michigan', 'MI'],
  '617': ['Massachusetts', 'MA'],
  '618': ['Illinois', 'IL'],
  '619': ['California', 'CA'],
  '620': ['Kansas', 'KS'],
  '623': ['Arizona', 'AZ'],
  '624': ['New York', 'NY'],
  '626': ['California', 'CA'],
  '628': ['California', 'CA'],
  '629': ['Tennessee', 'TN'],
  '630': ['Illinois', 'IL'],
  '631': ['New York', 'NY'],
  '636': ['Missouri', 'MO'],
  '640': ['New Jersey', 'NJ'],
  '641': ['Iowa', 'IA'],
  '645': ['Florida', 'FL'],
  '646': ['New York', 'NY'],
  '650': ['California', 'CA'],
  '651': ['Minnesota', 'MN'],
  '656': ['Florida', 'FL'],
  '657': ['California', 'CA'],
  '659': ['Alabama', 'AL'],
  '660': ['Missouri', 'MO'],
  '661': ['California', 'CA'],
  '662': ['Mississippi', 'MS'],
  '667': ['Maryland', 'MD'],
  '669': ['California', 'CA'],
  '670': ['Northern Mariana Islands', 'MP'],
  '671': ['Guam', 'GU'],
  '678': ['Georgia', 'GA'],
  '680': ['New York', 'NY'],
  '681': ['West Virginia', 'WV'],
  '682': ['Texas', 'TX'],
  '684': ['American Samoa', 'AS'],
  '686': ['Virginia', 'VA'],
  '689': ['Florida', 'FL'],
  '701': ['North Dakota', 'ND'],
  '702': ['Nevada', 'NV'],
  '703': ['Virginia', 'VA'],
  '704': ['North Carolina', 'NC'],
  '706': ['Georgia', 'GA'],
  '707': ['California', 'CA'],
  '708': ['Illinois', 'IL'],
  '712': ['Iowa', 'IA'],
  '713': ['Texas', 'TX'],
  '714': ['California', 'CA'],
  '715': ['Wisconsin', 'WI'],
  '716': ['New York', 'NY'],
  '717': ['Pennsylvania', 'PA'],
  '718': ['New York', 'NY'],
  '719': ['Colorado', 'CO'],
  '720': ['Colorado', 'CO'],
  '724': ['Pennsylvania', 'PA'],
  '725': ['Nevada', 'NV'],
  '726': ['Texas', 'TX'],
  '727': ['Florida', 'FL'],
  '728': ['Florida', 'FL'],
  '730': ['Illinois', 'IL'],
  '731': ['Tennessee', 'TN'],
  '732': ['New Jersey', 'NJ'],
  '734': ['Michigan', 'MI'],
  '737': ['Texas', 'TX'],
  '740': ['Ohio', 'OH'],
  '743': ['North Carolina', 'NC'],
  '747': ['California', 'CA'],
  '754': ['Florida', 'FL'],
  '757': ['Virginia', 'VA'],
  '760': ['California', 'CA'],
  '762': ['Georgia', 'GA'],
  '763': ['Minnesota', 'MN'],
  '765': ['Indiana', 'IN'],
  '769': ['Mississippi', 'MS'],
  '770': ['Georgia', 'GA'],
  '771': ['Washington, District Of Columbia', 'DC'],
  '772': ['Florida', 'FL'],
  '773': ['Illinois', 'IL'],
  '774': ['Massachusetts', 'MA'],
  '775': ['Nevada', 'NV'],
  '779': ['Illinois', 'IL'],
  '781': ['Massachusetts', 'MA'],
  '785': ['Kansas', 'KS'],
  '786': ['Florida', 'FL'],
  '787': ['Puerto Rico', 'PR'],
  '801': ['Utah', 'UT'],
  '802': ['Vermont', 'VT'],
  '803': ['South Carolina', 'SC'],
  '804': ['Virginia', 'VA'],
  '805': ['California', 'CA'],
  '806': ['Texas', 'TX'],
  '808': ['Hawaii', 'HI'],
  '810': ['Michigan', 'MI'],
  '812': ['Indiana', 'IN'],
  '813': ['Florida', 'FL'],
  '814': ['Pennsylvania', 'PA'],
  '815': ['Illinois', 'IL'],
  '816': ['Missouri', 'MO'],
  '817': ['Texas', 'TX'],
  '818': ['California', 'CA'],
  '820': ['California', 'CA'],
  '821': ['South Carolina', 'SC'],
  '826': ['Virginia', 'VA'],
  '828': ['North Carolina', 'NC'],
  '830': ['Texas', 'TX'],
  '831': ['California', 'CA'],
  '832': ['Texas', 'TX'],
  '835': ['Pennsylvania', 'PA'],
  '838': ['New York', 'NY'],
  '839': ['South Carolina', 'SC'],
  '840': ['California', 'CA'],
  '843': ['South Carolina', 'SC'],
  '845': ['New York', 'NY'],
  '847': ['Illinois', 'IL'],
  '848': ['New Jersey', 'NJ'],
  '850': ['Florida', 'FL'],
  '854': ['South Carolina', 'SC'],
  '856': ['New Jersey', 'NJ'],
  '857': ['Massachusetts', 'MA'],
  '858': ['California', 'CA'],
  '859': ['Kentucky', 'KY'],
  '860': ['Connecticut', 'CT'],
  '861': ['Illinois', 'IL'],
  '862': ['New Jersey', 'NJ'],
  '863': ['Florida', 'FL'],
  '864': ['South Carolina', 'SC'],
  '865': ['Tennessee', 'TN'],
  '870': ['Arkansas', 'AR'],
  '872': ['Illinois', 'IL'],
  '878': ['Pennsylvania', 'PA'],
  '901': ['Tennessee', 'TN'],
  '903': ['Texas', 'TX'],
  '904': ['Florida', 'FL'],
  '906': ['Michigan', 'MI'],
  '907': ['Alaska', 'AK'],
  '908': ['New Jersey', 'NJ'],
  '909': ['California', 'CA'],
  '910': ['North Carolina', 'NC'],
  '912': ['Georgia', 'GA'],
  '913': ['Kansas', 'KS'],
  '914': ['New York', 'NY'],
  '915': ['Texas', 'TX'],
  '916': ['California', 'CA'],
  '917': ['New York', 'NY'],
  '918': ['Oklahoma', 'OK'],
  '919': ['North Carolina', 'NC'],
  '920': ['Wisconsin', 'WI'],
  '925': ['California', 'CA'],
  '928': ['Arizona', 'AZ'],
  '929': ['New York', 'NY'],
  '930': ['Indiana', 'IN'],
  '931': ['Tennessee', 'TN'],
  '934': ['New York', 'NY'],
  '936': ['Texas', 'TX'],
  '937': ['Ohio', 'OH'],
  '938': ['Alabama', 'AL'],
  '939': ['Puerto Rico', 'PR'],
  '940': ['Texas', 'TX'],
  '941': ['Florida', 'FL'],
  '943': ['Georgia', 'GA'],
  '945': ['Texas', 'TX'],
  '947': ['Michigan', 'MI'],
  '948': ['Virginia', 'VA'],
  '949': ['California', 'CA'],
  '951': ['California', 'CA'],
  '952': ['Minnesota', 'MN'],
  '954': ['Florida', 'FL'],
  '956': ['Texas', 'TX'],
  '959': ['Connecticut', 'CT'],
  '970': ['Colorado', 'CO'],
  '971': ['Oregon', 'OR'],
  '972': ['Texas', 'TX'],
  '973': ['New Jersey', 'NJ'],
  '975': ['Missouri', 'MO'],
  '978': ['Massachusetts', 'MA'],
  '979': ['Texas', 'TX'],
  '980': ['North Carolina', 'NC'],
  '983': ['Colorado', 'CO'],
  '984': ['North Carolina', 'NC'],
  '985': ['Louisiana', 'LA'],
  '986': ['Idaho', 'ID'],
  '989': ['Michigan', 'MI'],
};

export const localPhoneCodes: PhoneCodesType[] = map(
  localPhonesByAreaCode,
  (value, key) => ({
    areaCode: key,
    state: value[0],
    stateCode: value[1],
  }),
);
