import React, { FC } from 'react';
import { DATE_FORMAT_LL_DD_YYYY, formatDate } from '@app/utils/formatDate';

interface DateFormatProps {
  date: Date;
  format?: string;
  timeZone?: string;
}

const DateFormat: FC<DateFormatProps> = ({
  date,
  format = DATE_FORMAT_LL_DD_YYYY,
  timeZone,
}) => {
  return <>{formatDate({ date, format, timeZone })}</>;
};

export default DateFormat;
