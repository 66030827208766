import React from 'react';
import { Box, Switch, Text } from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';

import {
  NotificationSettings,
  NotificationSettingsFragmentDoc,
  useGetNotificationSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from '@app/api/gql/generated-types';
import * as typenames from '@app/api/typenames';
import NotificationSettingsLine from '@app/components/next/moleculas/NotificationSettingsLine/NotificationSetttingsLine';

const enum NotificationTypeNames {
  CAMPAIGNS = 'enabledCampaigns',
  PAYMENTS = 'enabledPayments',
  CALENDAR = 'enabledCalendar',
}

const Notifications = () => {
  const { data: { getNotificationSettings } = {} } =
    useGetNotificationSettingsQuery({
      variables: {},
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    });

  const [mutateNotificationSettings] = useUpdateNotificationSettingsMutation(
    {},
  );

  const handleChange = async (fieldName: string, fieldValue: boolean) => {
    await mutateNotificationSettings({
      variables: {
        input: {
          [fieldName]: fieldValue,
        },
      },
      update(cache) {
        cache.updateFragment<NotificationSettings>(
          {
            id: `${typenames.NotificationSettings}:${getNotificationSettings.__typename}`,
            fragment: NotificationSettingsFragmentDoc,
            fragmentName: typenames.NotificationSettings,
          },
          (data) => ({
            ...data,
            settings: {
              [fieldName]: fieldValue,
            },
          }),
        );
      },
      context: {
        notify: {
          success: () => 'The data was successfully updated',
        },
      },
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        enabledCampaigns: getNotificationSettings?.enabledCampaigns ?? true,
        enabledPayments: getNotificationSettings?.enabledPayments ?? true,
        enabledCalendar: (getNotificationSettings?.enabledCalendar ??
          true) as boolean,
      }}
      onSubmit={null}>
      {({ values }) => (
        <Form>
          <Box ml="30px">
            <Text mb="28px" variant="sub-heading">
              Notifications
            </Text>
            <NotificationSettingsLine
              description="Stay on top of your active campaigns by enabling campaign status notifications"
              label="Campaigns"
              mt="30px"
              name={NotificationTypeNames.CAMPAIGNS}>
              <Field name={NotificationTypeNames.CAMPAIGNS} width="38px">
                {({ field }: FieldProps) => (
                  <Switch
                    variant="general"
                    {...field}
                    isChecked={values.enabledCampaigns}
                    onChange={(event) => {
                      field.onChange(event);
                      void handleChange(
                        NotificationTypeNames.CAMPAIGNS,
                        event.target.checked,
                      );
                    }}
                  />
                )}
              </Field>
            </NotificationSettingsLine>
            <NotificationSettingsLine
              description="Get 10-minute reminders for your scheduled events to stay organized"
              label="Calendar events"
              mt="30px"
              name={NotificationTypeNames.CALENDAR}>
              <Field name={NotificationTypeNames.CALENDAR} width="38px">
                {({ field }: FieldProps) => (
                  <Switch
                    variant="general"
                    {...field}
                    isChecked={values.enabledCalendar}
                    onChange={(event) => {
                      field.onChange(event);
                      void handleChange(
                        NotificationTypeNames.CALENDAR,
                        event.target.checked,
                      );
                    }}
                  />
                )}
              </Field>
            </NotificationSettingsLine>
            <NotificationSettingsLine
              description="Keep your account in good standing by enabling payment notifications"
              label="Payments"
              mt="30px"
              name={NotificationTypeNames.PAYMENTS}>
              <Field name={NotificationTypeNames.PAYMENTS}>
                {({ field }: FieldProps) => (
                  <Switch
                    isDisabled={true}
                    variant="general"
                    {...field}
                    isChecked={values.enabledPayments}
                    onChange={(event) => {
                      field.onChange(event);
                      void handleChange(
                        NotificationTypeNames.PAYMENTS,
                        event.target.checked,
                      );
                    }}
                  />
                )}
              </Field>
            </NotificationSettingsLine>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default Notifications;
