export const extractErrorMessage = (error: unknown): string => {
  let errorMessage =
    'Unable to complete request. Please try again or contact customer support.';

  if (
    Array.isArray(error) &&
    error.length > 0 &&
    typeof error[0] === 'object' &&
    'message' in error[0]
  ) {
    errorMessage = (error[0] as { message: string }).message;
  } else if (
    typeof error === 'object' &&
    error !== null &&
    'message' in error
  ) {
    errorMessage = (error as { message: string }).message;
  }

  return errorMessage;
};
