import { Badge } from '@chakra-ui/react';
import React from 'react';
import { MAX_COUNTER_UNREAD_MESSAGES } from '@app/constants/configuration';

interface ICounterBadgeProps {
  counter: number;
  style?: React.CSSProperties;
}

export const CounterBadge: React.FC<ICounterBadgeProps> = ({
  counter,
  style,
}) => {
  if (counter <= 0) {
    return null;
  }

  return (
    <Badge
      style={{
        minWidth: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style,
      }}>
      {counter}
      {counter === MAX_COUNTER_UNREAD_MESSAGES && '+'}
    </Badge>
  );
};
