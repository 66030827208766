import { Text } from '@chakra-ui/react';
import React, { FC } from 'react';

interface EmptyListType {
  type: string;
}

const EmptyList: FC<EmptyListType> = ({ type }) => {
  return (
    <Text
      color="secondary.400"
      fontSize="11px"
      lineHeight="13px"
      m="10px 0"
      textAlign="center">
      No {type} yet
    </Text>
  );
};

export default EmptyList;
