import { useMemo } from 'react';
import { ContactFields } from '@smarter-contact-team/messages';
import {
  CampaignScheduledMessageFragment,
  Field,
  PredefinedField,
  useFindFieldsQuery,
} from '@app/api/gql/generated-types';

import { useQueryParams } from './useQueryParams';

export type PredefinedFieldData = {
  fieldId: string;
  value: string;
  name?: string;
};

export type PredefinedFieldsMap = Record<string, PredefinedFieldData>;

const reduceFields = (
  fields: Field[],
  contact: CampaignScheduledMessageFragment['contact'],
  useKey = false,
) => {
  return fields?.reduce((acc: Record<string, PredefinedFieldData>, value) => {
    const attrField = contact?.attrs?.find(
      (field) => field.fieldId === value.id,
    );
    const key = useKey ? value?.key : value.id;

    let valueAttr = attrField?.value;
    switch (key as PredefinedField) {
      case PredefinedField.PHONE: {
        valueAttr = contact?.phone;
        break;
      }
      case PredefinedField.FIRST_NAME: {
        valueAttr = contact?.firstName;
        break;
      }

      case PredefinedField.LAST_NAME: {
        valueAttr = contact?.lastName;
        break;
      }

      case PredefinedField.EMAIL: {
        valueAttr = contact?.email;
        break;
      }
    }
    acc[key] = {
      fieldId: value.id,
      value: valueAttr ?? '',
      name: value.name,
    };

    return acc;
  }, {}) as PredefinedFieldsMap;
};

export const useFields = (
  contact: CampaignScheduledMessageFragment['contact'],
  predefined = true,
) => {
  const [{ profileId }] = useQueryParams<{
    profileId: string;
  }>();

  const { data: { findFields: fields } = {}, loading } = useFindFieldsQuery({
    variables: {
      filter: {
        ...(profileId && { subAccountId: profileId }),
        ...(!predefined && { predefined }),
      },
    },
  });

  const predefinedFields = useMemo(() => {
    const filtered = fields?.filter((field) => field.predefined);
    return reduceFields(filtered, contact, true);
  }, [contact, fields]);

  const customFields = useMemo(() => {
    const filtered = fields?.filter((field) => !field.predefined);
    return reduceFields(filtered, contact);
  }, [contact, fields]);

  const mappedFields = useMemo(() => {
    const map: { [key: string]: any } = {};

    for (const [, field] of Object.entries(customFields ?? {})) {
      map[field.name] = field.value;
    }

    for (const [, field] of Object.entries(predefinedFields ?? {})) {
      let name = field.name;
      if (name.startsWith('Property')) {
        name = name.replace('Property', '').trim();
      }
      map[name] = field.value;
    }

    return map as ContactFields;
  }, [customFields, predefinedFields]);

  return {
    predefinedFields,
    customFields,
    mappedFields,
    loading,
  };
};
