import { PopoverContentProps } from '@chakra-ui/react';

export const Popover = {
  parts: ['popover'],
  baseStyle: (props: PopoverContentProps) => {
    return {
      popover: {
        zIndex: 100,
        maxW: props.width ? props.width : 'xs',
        w: '100%',
      },
    };
  },
  variants: {
    responsive: {
      content: {
        maxWidth: 'unset',
        width: 'unset',
      },
    },
  },
};
