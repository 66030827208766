import isNumber from 'lodash/isNumber';
import {
  STANDARD_FRACTION_DIGITS,
  STANDARD_LOCALE,
} from '@app/constants/configuration';
import { TStdSymbol } from '@app/utils/stringUtils';

interface FormatNumberArgs {
  value: number;
  locale?: string;
  fractionDigits?: number;
  maxFractionDigits?: number;
  preventNegative?: boolean;
  cutFraction?: boolean;
}

/**
 * Format a number according to specified options.
 *
 * @param {Object} formatNumberArgs - The arguments for formatting the number.
 * @param {number} formatNumberArgs.value - The number to format.
 * @param {string} [formatNumberArgs.locale='en-US'] - The locale to use for formatting.
 * @param {number} [formatNumberArgs.fractionDigits] - The number of fraction digits to display.
 * @param {boolean} [formatNumberArgs.preventNegative=false] - Whether to prevent negative values.
 * @param {boolean} [formatNumberArgs.cutFraction=false] - Whether to cut off fraction digits.
 * @param {number} [formatNumberArgs.maxFractionDigits] - The maximum number of fraction digits to display.
 *
 * @returns {string} The formatted number as a string.
 */
export const formatNumber = ({
  value,
  locale = STANDARD_LOCALE,
  fractionDigits,
  preventNegative = false,
  cutFraction = false,
  maxFractionDigits,
}: FormatNumberArgs): string => {
  if (!value || !isNumber(value) || value === 0) {
    return '0';
  }

  let localValue = value;

  if (preventNegative) {
    localValue = localValue >= 0 ? localValue : 0;
  }

  if (isNumber(fractionDigits)) {
    return localValue.toLocaleString(locale, {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: maxFractionDigits ?? fractionDigits,
    });
  }

  if (cutFraction) {
    localValue = Math.trunc(localValue);
  }

  return localValue.toLocaleString(locale);
};

/**
 * Formats a number with dash '-' if value is falsy, not a number, or equal to 0.
 * Otherwise, formats the number using the formatNumber function.
 *
 * @param {number} value - The number to format.
 * @return {string} - The formatted number or dash '-'.
 */
export const formatDash = (value: number): string => {
  if (!value || !isNumber(value) || value === 0) {
    return TStdSymbol.DASH;
  }
  return formatNumber({ value });
};

/**
 * Formats a number value in cents to a string representation.
 *
 * @param {number} value - The value in cents to be formatted.
 * @returns {string} - The formatted string representation of the value in cents.
 */
export const formatInCents = (value: number): string =>
  formatNumber({ value, fractionDigits: STANDARD_FRACTION_DIGITS }).replace(
    /^0/,
    '',
  );

/**
 * Formats seconds (time) to a string representation.
 *
 * @param {number} value seconds- The value in seconds to be formatted.
 * @returns {string} - The formatted string representation of the value.
 */
export const formatTime = (value: number): string => {
  const minutes = Math.floor(value / 60);
  const seconds = Math.floor(value % 60);
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${minutes}m ${formattedSeconds}s`;
};
