import { Flex, Text, Link } from '@chakra-ui/react';
import React from 'react';
import { components, NoticeProps } from 'react-select';
import { Link as RouteLink } from 'react-router-dom';
import { OwnPhoneFragment } from '@app/api/gql/generated-types';
import ROUTES from '@app/utils/routes';

export const NoOptionsMessage = <OptionType extends OwnPhoneFragment>(
  props: NoticeProps<OptionType>,
) => {
  const linkTo = window.location.pathname.includes('dialer')
    ? ROUTES.settingsCallingSetup
    : ROUTES.settingsMessagingSetup;
  return (
    <components.NoOptionsMessage {...props}>
      <Flex alignItems="center" h="65px" justifyContent="center">
        <Text color="osloGray" fontStyle="normal" lineHeight="normal">
          Before creating a campaign, you must <br />
          first{' '}
          <Link
            as={RouteLink}
            textDecoration="none"
            to={`${linkTo}`}
            variant="link">
            purchase a phone number
          </Link>
          .
        </Text>
      </Flex>
    </components.NoOptionsMessage>
  );
};
