import isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { createCursorPaginationVariables } from '../apollo-pagination';
import { LabelFilter, useFindLabelsQuery } from '../gql/generated-types';

export const useLabelsQueryData = (filter?: LabelFilter, limit = 25) => {
  const {
    data: { findLabels } = {},
    loading,
    fetchMore,
    networkStatus,
  } = useFindLabelsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      filter,
      pagination: {
        limit,
      },
    },
  });

  const fetchNextPage = () =>
    !isNil(findLabels?.nextPageToken) && !(findLabels?.items?.length && loading)
      ? fetchMore(createCursorPaginationVariables(findLabels))
      : {};

  const labels = useMemo(() => findLabels?.items ?? [], [findLabels?.items]);
  return {
    data: labels,
    fetchNextPage,
    nextPageToken: findLabels?.nextPageToken,
    isFetchingNextPage: findLabels?.items?.length && loading,
    isLoading: loading,
    total: findLabels?.total ?? 0,
    networkStatus,
  };
};
