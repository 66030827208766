import React, {
  useState,
  FC,
  useEffect,
  useCallback,
  useMemo,
  ChangeEvent,
} from 'react';
import {
  Flex,
  Text,
  EditableInput,
  EditablePreview,
  Editable,
  Box,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';

import { useParams } from 'react-router-dom';
import SearchField from '@app/components/SearchField';
import EditableError from '@app/components/Editable/EditableError';
import { entityNameSchema } from '@app/schemas/entity-name-schema';
import { useYupValidation } from '@app/hooks/useYupValidation';
import StepDoneIcon from '@app/icons/step-done-icon.svg?react';
import StepDoneUncheckedIcon from '@app/icons/step-done-unchecked-icon.svg?react';
import { PageTitle } from '@app/components/PageTitle';
import { NAME_MAX_INPUT_VALUE } from '@app/pages/Campaigns/content/CreateCampaign/utils/initialValues';

import { useUpdateGroupMutation } from '@app/api/gql/generated-types';
import { catchErrorLog } from '@app/utils/logger';
import { EditableControls } from './EditControlVerify';
import { GroupNameWrapper } from './GroupListHeader/GroupNameWrapper';

interface GroupListHeaderProps {
  name: string;
  verified: boolean;
  onSearchChange: (value: string) => void;
}

export const GroupListHeader: FC<GroupListHeaderProps> = ({
  name,
  verified,
  onSearchChange,
}) => {
  const { contactListId, contactId } = useParams<{
    contactListId: string;
    contactId?: string;
  }>();

  const [updateGroupMutation, { loading: isLoading }] =
    useUpdateGroupMutation();

  const [entityName, setGroupName] = useState(name);
  const { errors } = useYupValidation(entityName, entityNameSchema);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    // character at the start of a string that is not whitespace.
    const regex = /^\S.*/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setGroupName(e.target.value);
    }
  };

  useEffect(() => {
    if (!name) {
      setGroupName(name);
    }
  }, [name]);

  const handleBlur = useCallback(async () => {
    try {
      if (errors.entityName || isEmpty(entityName)) {
        setGroupName(name);
        throw new Error(errors.entityName);
      }
      if (name !== entityName && !isEmpty(entityName)) {
        await updateGroupMutation({
          variables: {
            input: { name: entityName },
            id: contactListId,
          },
          context: {
            useApolloNetworkStatus: true,
            notify: {
              success: () => 'Group successfully renamed',
              error: () => 'Failed to remove group',
            },
          },
        });
      }
    } catch (error) {
      catchErrorLog(error, 'GroupListHeader/updateGroup');
    }
  }, [contactListId, entityName, errors.entityName, updateGroupMutation, name]);

  const isTouched = useMemo(() => entityName !== name, [entityName, name]);

  return (
    <Flex
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      m="0 25px"
      mb="20px"
      mr="16px"
      pl="5px">
      <PageTitle title={`${name} Group`} />
      <Box w="100%">
        <Flex
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          mb="2px">
          <Flex
            alignItems="center"
            direction="row"
            justifyContent="space-between">
            <Text variant="secondary-text">Group</Text>
            {verified ? (
              <Flex alignItems="center" ml="15px">
                <StepDoneIcon />
                <Text color="primary.500" fontSize="12px" ml="5px">
                  Verified
                </Text>
              </Flex>
            ) : (
              <Flex alignItems="center" ml="15px">
                <StepDoneUncheckedIcon />
                <Text color="secondary.400" fontSize="12px" ml="5px">
                  Unverified
                </Text>
              </Flex>
            )}
          </Flex>
          <Flex>
            <SearchField onChange={onSearchChange} />
          </Flex>
        </Flex>
        <Flex alignItems="center" justifyContent="space-between">
          <Editable
            color="main.400"
            defaultValue={entityName}
            fontSize="18px"
            fontWeight="500"
            height="32px"
            isPreviewFocusable={false}
            mr="5px"
            py="0"
            selectAllOnFocus={false}
            submitOnBlur={false}
            value={entityName}
            whiteSpace="nowrap"
            onCancel={() => setGroupName(name)}
            onSubmit={handleBlur}>
            <GroupNameWrapper>
              {({ isEditing, getEditButtonProps }) => (
                <Flex direction="column" position="relative">
                  <Flex
                    alignItems={isEditing ? 'center' : 'flex-end'}
                    width="100%">
                    <EditablePreview
                      maxWidth={!!contactId ? '250px' : '100%'}
                      overflow={!!contactId ? 'hidden' : 'overlay'}
                    />
                    <EditableInput
                      _focus={{
                        boxShadow: 'unset',
                        borderColor: isEmpty(errors)
                          ? 'primary.600'
                          : 'secondary.200',
                      }}
                      border={isEditing ? '1px' : '0'}
                      borderColor={isEditing ? 'secondary.200' : 'none'}
                      borderRadius="22px"
                      fontSize={isEditing ? '14px' : '18px'}
                      height="32px"
                      maxLength={NAME_MAX_INPUT_VALUE}
                      maxWidth={!!contactId ? '300px' : '100%'}
                      minWidth="300px"
                      overflow={!!contactId ? 'hidden' : 'overlay'}
                      px="20px"
                      py={isEditing ? '4.5px' : '3px'}
                      whiteSpace="nowrap"
                      width="100%"
                      onBlur={handleChange}
                      onChange={handleChange}
                    />

                    <EditableControls
                      getEditButtonProps={getEditButtonProps}
                      isEditing={isEditing}
                      isSubmitDisabled={
                        isLoading || !isTouched || isEmpty(entityName)
                      }
                    />
                  </Flex>
                  {isEditing && (
                    <Box
                      bottom="-20px"
                      left="10px"
                      minW="340px"
                      position="absolute">
                      <EditableError error={errors.entityName} />
                    </Box>
                  )}
                </Flex>
              )}
            </GroupNameWrapper>
          </Editable>
        </Flex>
      </Box>
    </Flex>
  );
};
