import { useMemo } from 'react';
import { TollFreeInfoStatus } from '@app/api/gql/generated-types';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';

export const useTollFreeStatus = () => {
  const { messagingProfile } = useCurrentAccountData();
  const tollFreeInfoStatus = messagingProfile?.tollFreeInfo?.status;

  const isPending = useMemo(
    () => tollFreeInfoStatus === TollFreeInfoStatus.PENDING,
    [tollFreeInfoStatus],
  );

  const isDeclined = useMemo(
    () => tollFreeInfoStatus === TollFreeInfoStatus.DECLINED,
    [tollFreeInfoStatus],
  );

  const isApproved = useMemo(
    () => tollFreeInfoStatus === TollFreeInfoStatus.APPROVED,
    [tollFreeInfoStatus],
  );

  const isSubmitted = useMemo(
    () => tollFreeInfoStatus === TollFreeInfoStatus.SUBMITTED,
    [tollFreeInfoStatus],
  );

  const statusExists = useMemo(
    () =>
      !!(tollFreeInfoStatus && tollFreeInfoStatus !== TollFreeInfoStatus.NONE),
    [tollFreeInfoStatus],
  );

  return {
    isSubmitted,
    isApproved,
    isPending,
    isDeclined,
    statusExists,
    status: tollFreeInfoStatus,
    tollFreeMaxTpm: messagingProfile?.tollFreeInfo?.maxTpm,
  };
};
