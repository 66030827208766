import React, { FC } from 'react';

import {
  ContactFragment,
  UpdateContactMutationVariables,
} from '@app/api/gql/generated-types';
import { useUpdateContactFragment } from '@app/api/hooks/useUpdateContactFragment';
import { useUpdateContactMutation } from '@app/api/mutations/useUpdateContactMutation';
import { FavoriteCell } from '@app/pages/Contacts/components/TableList/FavoriteCell';

interface ContactFavoriteProps {
  conversation: ContactFragment;
}

export const ContactFavorite: FC<ContactFavoriteProps> = ({ conversation }) => {
  const [mutate] = useUpdateContactMutation();
  const { updateContactFragment } = useUpdateContactFragment();

  const handleFavoriteChange = async (
    variables: UpdateContactMutationVariables,
  ) => {
    updateContactFragment({
      contactId: conversation.id,
      favorite: variables.input?.favorite,
    });
    await mutate({
      variables,
    });
  };

  return (
    <FavoriteCell
      id={conversation.id}
      isFavorite={conversation.favorite}
      onChangeFavorite={handleFavoriteChange}
    />
  );
};
