import {
  Ability,
  AbilityBuilder,
  ConditionsMatcher,
  PureAbility,
} from '@casl/ability';
import { CustomModalProps } from '@app/components/next/organisms/CustomModal';

export type ValueOf<T> = T[keyof T];

//Features on this list --> buttons disabled will be migrated slowly to PermissionFeatures
/**
 * @deprecated Use the new PermissionFeatures instead or should be migrated to permissions.
 */
export const Features = {
  RVM: 'rvm',
  FreeLookup: 'free-lookup',
  APIIntegration: 'api-integration',
  TextSuppressions: 'text-suppressions',
  CampaignThrottling: 'campaign-throttling',
  CampaignScheduling: 'campaign-scheduling',
  CampaignThrottlingActive: 'campaign-throttling-active',
  CampaignSchedulingActive: 'campaign-scheduling-active',
  CampaignPhoneNumber: 'campaign-phone-number',
  DripCampaigns: 'drip-campaigns',
  CampaignRules: 'campaign-rules',
  CampaignRulesActive: 'campaign-rules-active',
  MobileTextAlerts: 'mobile-text-alerts',
  ShowMobileTextAlerts: 'show-mobile-text-alerts',
  SubAccounts: 'sub-accounts',
  ShowSubAccounts: 'show-sub-accounts',
  Dialer: 'dialer',
  ShowDialer: 'show-dialer',
  ActiveIncoming: 'active-incoming',
  IncomingCalls: 'incoming-calls',
  MessageSchedule: 'message-schedule',
  CallAutoReply: 'call-auto-reply',
  Voicemail: 'voicemail',
} as const;

//new implementation after dialer Features on this list & permission custom modal
//Source of truth for now https://docs.google.com/spreadsheets/d/1k8TbI8Eod8KhBUG_BqEXwHS0ckaAH7z4o8cIBDUvnTw/edit?gid=2086662141#gid=2086662141
export const PermissionFeatures = {
  messenger: {
    conversation: 'messenger:conversation',
    macro: 'messenger:macro',
    sms: 'messenger:sms',
  },
  campaigns: {
    standard: 'campaigns:standard',
    keyword: 'campaigns:keyword',
    messageTemplate: 'campaigns:messageTemplate',
    keywordTemplate: 'campaigns:keywordTemplate',
  },
  contacts: {
    singleContact: 'contacts:singleContact',
    contacts: 'contacts:contacts',
    group: 'contacts:group',
    pushToCampaign: 'contacts:pushToCampaign',
  },
  calendar: {
    event: 'calendar:event',
  },
  skiptrace: {
    pushToContacts: 'skiptrace:pushToContacts',
  },
  settings: {
    labels: 'settings:labels',
    apiIntegrations: 'settings:apiIntegrations',
  },
  settingsMessagingSetup: {
    phoneNumber: 'settingsMessagingSetup:phoneNumber',
  },
  settingsCallingSetup: {
    phoneNumber: 'settingsCallingSetup:phoneNumber',
  },
  dialer: {
    campaigns: 'dialer:campaigns',
    script: 'dialer:scripts',
  },
} as const;

export const PermissionActions = {
  INTERACTION: 'interaction',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  BUY: 'buy',
  SEND: 'send',
  UPLOAD: 'upload',
} as const;

export type PermissionActionsType = ValueOf<typeof PermissionActions>;

type FlattenAccesbilityFeatures<T> =
  T extends Record<string, any>
    ? { [K in keyof T]: FlattenAccesbilityFeatures<T[K]> }[keyof T]
    : T;

export type PermissionFeatureKeys = FlattenAccesbilityFeatures<
  typeof PermissionFeatures
>;
// Result: 'messenger:conversation' | 'messenger:macro' | ...etc.

export type SCAbility = PureAbility<
  [PermissionActionsType, PermissionFeatureKeys]
>;

export interface ConditionsModal {
  meta?: {
    modalProps?: CustomModalProps;
  };
}

/***
 * I want to override a modal (rules) if a existing rules was set before
 * for cases like
 *   if (nonmessagingsubcription){
 *      cannotWithModalAbility(
 *       pureAbility,
 *       PermissionActions.SEND,
 *       PermissionFeatures.messenger.sms,
 *       PermissionModalPropsSelectYourPlan,
 *     );
 *      // and here override the same rule with a different modal
 *     if (hasActiveCallingSubscription) {
 *         cannotWithModalAbility(
 *           pureAbility,
 *           PermissionActions.SEND,
 *           PermissionFeatures.messenger.sms,
 *           PermissionModalPropsSendMessagePhoneNumber,
 *         );
 *       }
 * }
 * CASL to do that I need to override the rules directly in the ability objects but
 * for setting the rule the first time we need to use cannot
 *
 * We should receive the permission from the BE some day and avoid this.
 */
export const cannotWithModal = (
  pureAbility: AbilityBuilder<SCAbility>,
  action: PermissionActionsType,
  subject: PermissionFeatureKeys,
  denialModalContent: CustomModalProps,
) => {
  // Find the existing rule
  const existingRule = pureAbility.rules.find(
    (rule) => rule.action === action && rule.subject === subject,
  ) as PermissionRuleWithModal | undefined;

  if (existingRule?.conditions?.meta) {
    // If rule exists, modify the existing rule by adding modalProps using the ability object
    existingRule.conditions.meta = { modalProps: denialModalContent }; // Modify the rule directly
  } else {
    // If rule doesn't exist, create a new rule with modalProps, using the cannot which manages the map
    pureAbility.cannot(action, subject, {
      meta: { modalProps: denialModalContent },
    });
  }
};

export interface PermissionRuleWithModal {
  action: PermissionActionsType;
  subject: PermissionFeatureKeys;
  conditions: ConditionsModal;
}

export const conditionsMatcher: ConditionsMatcher<Record<string, any>> = (
  conditions,
) => {
  return (object) => {
    const conditionRule = conditions as PermissionRuleWithModal;
    const objectRule = object as PermissionRuleWithModal;

    return (
      conditionRule.action === objectRule.action &&
      conditionRule.subject === objectRule.subject
    );
  };
};

export const FeatureActions = {
  ENABLED: 'enabled',
} as const;

export const Permissions = {
  Skiptrace: 'skiptrace',
  Campaign: 'campaign',
  KeywordCampaign: 'keyword-campaign',
  PhoneNumber: 'phone-number',
  Conversation: 'conversation',
  Label: 'label',
  ReplyMessenger: 'reply-messenger',
  Favorite: 'favorite',
  Dlc: 'dlc',
  BrandRegistration: 'brand-registration',
  WelcomePopup: 'welcome-popup',
  Membership: 'membership',
  Template: 'template',
} as const;

/*********************
 * OLD VERSION
 ********************/

export type FeatureActionsType = ValueOf<typeof FeatureActions>;
export type FeaturesType = ValueOf<typeof Features>;
export type FeaturesAbilityType = Ability<[FeatureActionsType, FeaturesType]>;

export type PermissionsType = ValueOf<typeof Permissions>;
export type PermissionsAbilityType = Ability<
  [PermissionActionsType, PermissionsType]
>;

export interface FeaturesBrandingType {
  isApproved10Dlc: boolean;
  isTollFree: boolean;
}
