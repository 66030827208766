import { Divider, Flex, Switch, Text } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';

import { Features } from '@app/acl';
import {
  Scope,
  useAccountQuery,
  useUpdateMessagingProfileMutation,
} from '@app/api/gql/generated-types';
import { PageTitle } from '@app/components/PageTitle';
import { useDisableFeatureAbility } from '@app/hooks/useDisableFeatureAbility';
import { usePlanPermission } from '@app/hooks/usePlanPermission';
import { UpdatePlanDialer } from '@app/shared/ui/CommonTooltips/UpdatePlanDialer';

import { useUpdateMessagingProfileFragment } from '@app/api/hooks/useUpdateMessagingProfileFragment';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import { useUserPhoneNumbersReport } from '@app/hooks/useUserPhoneNumbersReport';
import { SettingsLayout } from '@app/layouts/SettingsLayout';
import { ActiveIncoming } from './components/ActiveIncoming';
import { CallAutoReply } from './components/CallAutoReply';
import { MobileTextAlerts } from './components/MobileTextAlerts';
import { PhoneNumbers } from './components/PhoneNumbers';
import Tooltip from './components/Tooltip';
import { VoiceMail } from './components/Voicemail';

const MessagingSetup: FC = () => {
  const { data: { account } = {} } = useAccountQuery({
    fetchPolicy: 'network-only',
  });

  const { updatePhoneSettingsFragment } = useUpdateMessagingProfileFragment();
  const [updateMessagingProfile] = useUpdateMessagingProfileMutation();

  const { isPrimitivePlans } = usePlanPermission();

  const { can: canEnableIncomingCalls } = useDisableFeatureAbility(
    Features.IncomingCalls,
  );
  const {
    hasPhoneNumbers,
    hasEnoughPhoneNumbers,
    loading: isLoadingPhoneNumbers,
    ownedNumbers,
    refetch: refetchUserPhones,
    minimumPhoneNumbersCount,
  } = useUserPhoneNumbersReport(Scope.MESSAGE);

  const { isTrial: isTrialAccount } = useCurrentAccountData();
  const isSipEnabled = useMemo(
    () => account?.messagingProfile?.phoneSettings?.isSipEnabled ?? false,
    [account?.messagingProfile?.phoneSettings?.isSipEnabled],
  );

  const handleIncomingCall = async () => {
    updatePhoneSettingsFragment({
      id: account?.messagingProfile?.id,
      phoneSettings: {
        isSipEnabled: !isSipEnabled,
      },
    });
    await updateMessagingProfile({
      variables: {
        input: {
          phoneSettings: {
            isSipEnabled: !isSipEnabled,
          },
        },
      },
    });
  };

  return (
    <>
      <PageTitle title="Phone setup" />
      <Flex
        direction="column"
        maxW="680px"
        padding={['8px 11px 0', '0 30px 0']}>
        <Text variant="sub-heading">Phone setup</Text>
        <ActiveIncoming />
        <SettingsLayout
          alignItems="baseline"
          childComponent={
            <Switch
              colorScheme="switchedBlue"
              h="20px"
              isChecked={isSipEnabled}
              isDisabled={!canEnableIncomingCalls}
              w="38px"
              onChange={handleIncomingCall}
            />
          }
          iconComponent={
            isPrimitivePlans ? (
              <Tooltip text={<UpdatePlanDialer />} widthContainer="200px" />
            ) : (
              <Tooltip
                text="You can receive incoming calls in the web application"
                widthContainer="200px"
              />
            )
          }
          marginTop="10px"
          titleLabel="Incoming calls"
        />
        <>
          <VoiceMail />
          <CallAutoReply />
          <MobileTextAlerts
            account={account}
            messagingProfile={account?.messagingProfile}
          />
        </>
        <Divider bg="mystic" mt="30px" width="620px" />
      </Flex>
      <Flex direction="column" maxW="644px" padding={['0 11px', '10px 30px 0']}>
        <PhoneNumbers
          hasEnoughPhoneNumbers={hasEnoughPhoneNumbers}
          isLoadingPhoneNumbers={isLoadingPhoneNumbers}
          isTrialAccount={isTrialAccount}
          ownedNumbers={ownedNumbers}
          refetchUserPhones={refetchUserPhones}
          requiredUserPhonesNumbers={minimumPhoneNumbersCount}
          showTrialNumbers={isTrialAccount && !hasPhoneNumbers}
        />
      </Flex>
    </>
  );
};

export default MessagingSetup;
