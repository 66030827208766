import React, { createContext, FC, ReactNode, useContext } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { DropModal } from '../pages/Contacts/components/DropModal';

interface ContactsUploadContextProps {
  dropModal: ReturnType<typeof useDisclosure>;
}

const ContactsUploadContext = createContext<ContactsUploadContextProps>(null);

export const useContactsUploadContext = () => {
  const context = useContext(ContactsUploadContext);

  if (!context) {
    throw new Error(
      'useContactsUploadContext must be used within a ContactsUploadProvider',
    );
  }

  return context;
};

export const ContactsUploadProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const dropModal = useDisclosure();

  return (
    <ContactsUploadContext.Provider
      value={{
        dropModal,
      }}>
      {children}

      <DropModal
        isOpen={dropModal.isOpen}
        title="Upload file"
        onClose={dropModal.onClose}
      />
    </ContactsUploadContext.Provider>
  );
};
