import { useMemo } from 'react';

import { defineFeatureRules, FeatureActions, FeaturesType } from '@app/acl';

import { useTollFreeStatus } from '@app/pages/Settings/content/BrandRegistration/hooks/useTollFreeStatus';
import { useCurrentAccountData } from './useCurrentAccountData';
import { usePlanPermission } from './usePlanPermission';
import { useDlcStatus } from './useDlcStatus';

export const useDisableFeatureAbility = (subject: FeaturesType) => {
  const account = useCurrentAccountData();
  const {
    isAdvanced,
    isMiddleAdvanced,
    isNewPro,
    isOldElite,
    isNewStarter,
    isAllNewPlans,
    isElite,
    isBasicPlan,
    isDialerPlan,
  } = usePlanPermission();

  const { isApproved: isApproved10Dlc } = useDlcStatus();
  const { isApproved: isApprovedTollFree, isPending: isPendingTollFree } =
    useTollFreeStatus();

  const isTollFree = isApprovedTollFree || isPendingTollFree;

  const ability = useMemo(
    () =>
      defineFeatureRules(
        account,
        {
          isAdvanced,
          isMiddleAdvanced,
          isNewPro,
          isOldElite,
          isNewStarter,
          isAllNewPlans,
          isElite,
          isBasic: isBasicPlan,
          isDialerPlan,
        },
        {
          isApproved10Dlc,
          isTollFree,
        },
      ),
    [
      account,
      isAdvanced,
      isMiddleAdvanced,
      isNewPro,
      isOldElite,
      isNewStarter,
      isAllNewPlans,
      isElite,
      isBasicPlan,
      isApproved10Dlc,
      isTollFree,
      isDialerPlan,
    ],
  );

  return {
    can: ability.can(FeatureActions.ENABLED, subject),
  };
};
