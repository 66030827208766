import { useMemo } from 'react';

import { useFindMergeTagsQuery } from '@app/api/gql/generated-types';

export const useMentionFields = () => {
  const { data: { findMergeTags } = {} } = useFindMergeTagsQuery();

  const fields = useMemo(
    () =>
      findMergeTags?.map((field) => ({
        id: field.id,
        display: field.mergeTag,
      })) ?? [],
    [findMergeTags],
  );

  return {
    fields,
  };
};
