import React, { FC } from 'react';
import { Info } from 'luxon';

import { HeaderWrapStyle } from '../styles';
import { MONTH } from '../utils/utils';

import { HeaderItem } from './HeaderItem';

interface HeaderWrapTemplateTypes {
  mode: string;
}

const HeaderWrap: FC<HeaderWrapTemplateTypes> = ({ mode }) => (
  <HeaderWrapStyle>
    {mode === MONTH &&
      Info.weekdays('short').map((weekDay, i) => {
        const weekId = `weekDay_${i}`;
        return <HeaderItem key={weekId}>{weekDay.slice(0, 3)}</HeaderItem>;
      })}
  </HeaderWrapStyle>
);

export default HeaderWrap;
