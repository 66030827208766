import { CallSettingsVoiceType } from '@app/api/gql/generated-types';

export const voiceTypeOptions = [
  {
    label: 'Male voice',
    value: CallSettingsVoiceType.MALE,
  },
  {
    label: 'Female voice',
    value: CallSettingsVoiceType.FEMALE,
  },
];

export const voiceTypeMap: {
  [key in CallSettingsVoiceType]: string;
} = {
  [CallSettingsVoiceType.FEMALE]: 'Female voice',
  [CallSettingsVoiceType.MALE]: 'Male voice',
};
