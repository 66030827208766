import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQueryParams } from '@app/hooks/useQueryParams';
import ArrowRightIcon from '@app/icons/arrow-right-icon-sm.svg?react';
import { ResizableBox } from '@app/components/next/organisms/ResizableBox';
import {
  DEFAULT_MAX_WIDTH_MESSENGER_SLIDE_WRAPPER,
  DEFAULT_MIN_WIDTH_MESSENGER_SLIDE_WRAPPER,
  DEFAULT_SLIDE_WRAPPER_ANIMATION_TIMEOUT,
} from '@app/constants/configuration';
import SideBarContent from './Content';
import { SideBarTab } from './types';

const SlideWrapper = styled(CSSTransition<HTMLElement>)`
  transition: 0.6s ease-in-out;
`;

interface ISideBarWrapperProps {
  resizable?: boolean;
  maxWidth?: number;
  minWidth?: number;
}

const SideBarWrapper: React.FC<ISideBarWrapperProps> = ({
  resizable,
  minWidth = DEFAULT_MIN_WIDTH_MESSENGER_SLIDE_WRAPPER,
  maxWidth = DEFAULT_MAX_WIDTH_MESSENGER_SLIDE_WRAPPER,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isCaller = location.pathname.includes('dial');
  const { contactId, tabId } = useParams<{
    contactId: string;
    tabId: SideBarTab;
  }>();

  const [, queryParams] = useQueryParams();

  const handleClose = () => {
    let paths: string[] = location.pathname.split('/');
    // For backward compatibility
    if (tabId) {
      paths = paths.slice(0, paths.length - 2);
    } else {
      paths.pop();
    }

    navigate(`${paths.join('/')}${queryParams}`);
  };

  return (
    <SlideWrapper timeout={DEFAULT_SLIDE_WRAPPER_ANIMATION_TIMEOUT}>
      <ResizableBox
        enabled={resizable}
        maxWidth={maxWidth}
        minWidth={minWidth}
        storageKey="messangerNav"
        visible={!!contactId}>
        <Flex
          backgroundColor="white"
          borderLeft="1px solid"
          borderLeftColor="mystic"
          direction="column"
          flex="1"
          height="full"
          maxWidth={isCaller ? '100%' : maxWidth}
          minWidth={contactId ? minWidth : '0'}
          position="relative"
          right={contactId ? '0' : '-450px'}
          width="full">
          <Flex
            alignItems="center"
            background="white"
            borderColor="mystic"
            borderRadius="50px"
            borderStyle="solid"
            borderWidth="1px"
            color="primary.600"
            cursor="pointer"
            h="24px"
            justifyContent="center"
            left="-13px"
            position="absolute"
            top="29px"
            w="24px"
            zIndex="2"
            onClick={handleClose}>
            <ArrowRightIcon
              style={{
                marginLeft: '1px',
              }}
            />
          </Flex>
          {contactId && <SideBarContent id={contactId} />}
        </Flex>
      </ResizableBox>
    </SlideWrapper>
  );
};

export default SideBarWrapper;
