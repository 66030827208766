import React, { FC } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

const Tab: FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <Button mr="20px" variant="tab" {...props}>
      {children}
    </Button>
  );
};

export default Tab;
