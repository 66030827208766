import React, { FC, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { DateTime } from 'luxon';
import { Box, Flex, Text } from '@chakra-ui/react';

import { DateTimePicker } from '@app/widgets/DTPicker';

import { Mode } from '@app/widgets/DTPicker/types';
import { PopupConstructor } from '../types';
import { disabledTimes } from '../utils';

const utcDateNow = DateTime.utc()
  .minus({
    days: 1,
  })
  .set({
    minute: 0,
  });

export const DateFields: FC<{
  isEditDisabled: boolean;
}> = ({ isEditDisabled }) => {
  const { setFieldValue, values } = useFormikContext<PopupConstructor>();

  const disabledStartTimes = useMemo(() => {
    return disabledTimes({
      startDate: utcDateNow,
      endDate: DateTime.fromJSDate(values.startDate),
      fallBackDate: utcDateNow,
      timeZone: 'utc',
    });
  }, [values.startDate]);

  const disabledEndTimes = useMemo(() => {
    return disabledTimes({
      startDate: DateTime.fromJSDate(values.startDate),
      endDate: DateTime.fromJSDate(values.endDate),
      fallBackDate: utcDateNow,
      timeZone: 'utc',
    });
  }, [values.endDate, values.startDate]);

  return (
    <>
      <Flex alignItems="center" justify="space-between" w="100%">
        <Text color="main.400" fontSize="14px" fontWeight="400" my="20px">
          Start Date
        </Text>
        <Box width="345px">
          <DateTimePicker
            defaultValue={
              !values.startDate ? 'Select date and time' : undefined
            }
            disabled={isEditDisabled || !values.template?.type?.value}
            disabledEndDate={DateTime.fromJSDate(values.endDate)}
            disabledHours={disabledStartTimes.disabledHours}
            disabledMinutes={disabledStartTimes.disabledMinutes}
            disabledStartDate={utcDateNow}
            mode={Mode.DATETIME}
            timeZone="utc"
            value={
              values.startDate &&
              DateTime.fromJSDate(values.startDate).setZone('utc')
            }
            onChange={async (e) => {
              await setFieldValue('startDate', e.toJSDate());
              if (e.toJSDate() > values.endDate) {
                await setFieldValue('endDate', undefined);
              }
            }}
          />
        </Box>
      </Flex>
      <Flex alignItems="center" justify="space-between" w="100%">
        <Text color="main.400" fontSize="14px" fontWeight="400" my="20px">
          End Date
        </Text>
        <Box width="345px">
          <DateTimePicker
            defaultValue={!values.endDate ? 'Select date and time' : undefined}
            disabled={isEditDisabled || !values.startDate}
            disabledHours={disabledEndTimes.disabledHours}
            disabledMinutes={disabledEndTimes.disabledMinutes}
            disabledStartDate={DateTime.fromJSDate(values.startDate)}
            mode={Mode.DATETIME}
            timeZone="utc"
            value={
              values.endDate &&
              DateTime.fromJSDate(values.endDate).setZone('utc')
            }
            onChange={(e) => setFieldValue('endDate', e.toJSDate())}
          />
        </Box>
      </Flex>
    </>
  );
};
