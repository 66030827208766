import { useLocation } from 'react-router-dom';
import ROUTES from '@app/utils/routes';

const ROUTES_NOT_USED_DEFAULT_LAYOUT = [ROUTES.messenger, ROUTES.settings];

export const useDefaultLayoutInMobile = () => {
  const pathname = useLocation().pathname;

  const isMatch = ROUTES_NOT_USED_DEFAULT_LAYOUT.some((route) => {
    return pathname.startsWith(route);
  });

  return isMatch;
};
