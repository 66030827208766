import { upperFirst } from 'lodash/fp';
import {
  OwnPhone,
  OwnPhoneFragment,
  OwnPhoneReportFragment,
  OwnPhoneStatus,
  OwnPhoneType,
} from '@app/api/gql/generated-types';
import { TPhoneNumberStatus } from '@app/types/PhoneNumber';

export type PhoneFragment = OwnPhoneFragment | OwnPhoneReportFragment;

/**
 * Returns the text representation of a given phone type.
 *
 * @param {OwnPhone['type']} phoneType - The phone type to be converted to text.
 * @returns {string} The text representation of the phone type.
 */
export const phoneRegexp =
  /((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;

/**
 * Determines the status of a phone based on the given phone object.
 *
 * @param {OwnPhoneFragment} phone - The phone object to determine the status for.
 * @returns {string} - The status of the phone.
 */
export const phoneNumberStatus = (phone: PhoneFragment): string => {
  if (
    phone.type === OwnPhoneType.TOLL_FREE &&
    phone.status === OwnPhoneStatus.NONE
  ) {
    return upperFirst(TPhoneNumberStatus.enum.submitted);
  }
  if (
    phone.status === OwnPhoneStatus.DECLINED ||
    phone.status === OwnPhoneStatus.NONE
  ) {
    return upperFirst(TPhoneNumberStatus.enum.inactive);
  }
  return upperFirst(phone.status.toLowerCase());
};
export const getPhoneNumberTypeText = (phoneType: OwnPhone['type']): string => {
  if (phoneType === OwnPhoneType.TOLL_FREE) {
    return 'Toll-free';
  }
  return upperFirst(phoneType?.toLowerCase());
};

/**
 * Checks whether a phone number can be deleted through limits.
 *
 * @param {string} status - The status of the phone number.
 * @returns {boolean} - True if the phone number can be deleted through limits, false otherwise.
 */
export const canDeletePhoneNumberThroughLimits = (status: string): boolean =>
  TPhoneNumberStatus.enum.inactive === status.toLowerCase();

export const validatePhoneNumber = (phone: string): boolean =>
  phoneRegexp.test(phone);

export const formatPhoneNumbers = (
  phone: string,
  countryCode = '+1',
): string => {
  phone = phone.replace(/[\s-()]/g, '');
  return `${countryCode}${phone}`;
};
