import {
  PermissionFeatureKeys,
  PermissionActionsType,
  PermissionRuleWithModal,
} from '@app/acl';
import {
  CustomModalProps,
  PermissionModalPropsSelectYourPlan,
} from '@app/components/next/organisms/CustomModal';
import { usePermissionModalContext } from '../contexts/providers/usePermissionModalProvider';
import { usePermissionContext } from '../contexts/providers/usePermissionAbilityProvider';

/**
 * For permissionModals, It is comparing the ACL feature permission and showing the right modal using the context
 */

export const useCustomModalPermissionFeatureCheck = () => {
  const { abilityCannot, ability } = usePermissionContext();
  const { openModal } = usePermissionModalContext();

  const checkPermission = (
    action: PermissionActionsType,
    subject: PermissionFeatureKeys,
    successHandler: () => void,
    deniedContentProps?: CustomModalProps,
  ) => {
    const isForbidden = abilityCannot(action, subject);

    if (isForbidden) {
      const pickRule = ability?.rules.find(
        (rule) => rule.action === action && rule.subject === subject,
      ) as PermissionRuleWithModal;

      const modalProps =
        deniedContentProps || pickRule?.conditions?.meta?.modalProps;

      openModal({
        title: modalProps?.title || PermissionModalPropsSelectYourPlan.title,
        primaryAction:
          modalProps?.primaryAction ||
          PermissionModalPropsSelectYourPlan.primaryAction,
        primaryButtonText:
          modalProps?.primaryButtonText ||
          PermissionModalPropsSelectYourPlan.primaryButtonText,
        children:
          modalProps?.children || PermissionModalPropsSelectYourPlan.children,
        showSecondayAction: false,
      });
    } else {
      successHandler();
    }
  };

  return { checkPermission };
};
