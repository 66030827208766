import { Enum } from '@app/utils/enum';
import { colors } from '../colors';

const basicInvertedStyle = {
  invertedContainer: {
    border: `1px solid ${colors.primary[600]}`,
    backgroundColor: colors.white,
  },
  progressBar: {
    backgroundColor: colors.primary[200],
  },
};

export const AudioPlayerVariant = new Enum(
  'audioTemplate',
  'templatesTable',
  'RVM',
  'recorder',
);

export const AudioPlayer = {
  parts: [
    'container',
    'play',
    'pause',
    'timer',
    'progressBar',
    'progressBarRange',
    'icon',
    'title',
  ],

  baseStyle: {
    container: {
      border: `1px solid ${colors.primary[500]}`,
      paddingLeft: '15px',
      paddingRight: '15px',
      backgroundColor: colors.primary[500],
    },
    invertedContainer: {
      border: `1px solid ${colors.mystic}`,
      paddingLeft: '15px',
      paddingRight: '15px',
      backgroundColor: colors.mystic,
    },
    invertedCampaignContainer: {
      border: `1px solid ${colors.mystic}`,
      paddingLeft: '15px',
      paddingRight: '15px',
      backgroundColor: 'white',
    },
    playButton: {
      color: colors.primary[200],
    },
    invertedPlayButton: {
      color: colors.primary[600],
    },
  },

  variants: {
    [AudioPlayerVariant.enum.audioTemplate]: basicInvertedStyle,
    [AudioPlayerVariant.enum.templatesTable]: {
      container: {
        backgroundColor: colors.white,
        border: `1px solid ${colors.mystic}`,
        overflow: 'hidden',
      },
      icon: {
        color: colors.primary[600],
      },
      title: {
        color: colors.main[400],
      },
      progressBar: {
        backgroundColor: 'primary.200',
      },
      progressBarRange: {
        backgroundColor: 'primary.600',
      },
    },
    [AudioPlayerVariant.enum.RVM]: {
      container: {
        paddingLeft: '10px',
        paddingRight: '18px',
      },
    },
    [AudioPlayerVariant.enum.recorder]: {
      invertedContainer: {
        border: `1px solid ${colors.primary[200]}`,
        backgroundColor: colors.white,
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      playButton: {
        color: colors.primary[600],
      },
    },
  },
};
