import React from 'react';
import { Box, Skeleton, Grid, Stack } from '@chakra-ui/react';

import { TableSkeleton } from '@app/components/Table/Skeleton';
import { colors } from '@app/theme/colors';

export const SkeletonGroup = (): JSX.Element => {
  return (
    <Box>
      <Stack my="10px" padding="20px" width="306px">
        <Grid
          columnGap="30px"
          gridTemplateColumns="repeat(2, 1fr)"
          rowGap="15px">
          {Array(2)
            .fill(0)
            .map((_, i) => (
              <Skeleton
                key={`header-row-${i.toString()}`}
                endColor={colors.cultured}
                height="40px"
                startColor={colors.mystic}
                width="100%"
              />
            ))}
        </Grid>
        <Skeleton
          endColor={colors.cultured}
          height="20px"
          startColor={colors.mystic}
          width="100%"
        />
      </Stack>

      <TableSkeleton />
    </Box>
  );
};
