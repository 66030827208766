import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { Account, AccountStatus } from '@app/api/gql/generated-types';
import { AccountClaimRole } from '@app/types/Account';
import {
  hasActiveDialerSubscription,
  hasActiveMessageSubscription,
  hasDialerSubscription,
  hasInactiveDialerSubscription,
  hasInactiveMessageSubscription,
  isDialerSubscriptionTrialing,
  isMessageSubscriptionTrialing,
  isSubscribed,
  hasPastDueDialerSubscription,
  hasSubscriptionStatus as hasSubscriptionStatusUtil,
  SubscriptionStatusCheckRequirements,
  hasMessageSubscription,
} from '@app/utils/subscriptionHelpers';

interface AccountContextProps {
  currentAccount: Account;
  isActiveAccount: boolean;
  isMessagePlanTrialing: boolean;
  isMessagePlanCancelled: boolean;
  isDialerPlanTrialing: boolean;
  isDialerPlanCancelled: boolean;
  isActiveWithActiveMessagePlan: boolean;
  isActiveWithActiveDialerPlan: boolean;
  isActiveWithInactiveMessagePlan: boolean;
  isActiveWithInactiveDialerPlan: boolean;
  isAdmin: boolean;
  isInactiveWithNoPlans: boolean;
  isActiveWithNoDialerPlan: boolean;
  isInactiveWithNoDialerPlan: boolean;
  isInactiveWithActiveMessagePlan: boolean;
  isInactiveWithActiveDialerPlan: boolean;
  isInactiveWithInactiveMessagePlan: boolean;
  isInactiveWithInactiveDialerPlan: boolean;
  isInactiveWithPlanAccount: boolean;
  isImpersonate: boolean;
  isTrial: boolean;
  isWithoutDialerSubscription: boolean;
  hasDialerSubscriptionAsInactive: boolean;
  hasAccountMessageSubscription: boolean;
  hasSubscriptionStatus: (
    status: SubscriptionStatusCheckRequirements,
  ) => boolean;
}

interface AccountProviderProps extends PropsWithChildren {
  role: AccountClaimRole;
  account: Account;
}

export const CurrentAccountContext = createContext<AccountContextProps>(null);

export const useCurrentAccountContext = () => {
  const context = useContext(CurrentAccountContext);

  if (!context) {
    throw new Error(
      'useCurrentAccountContext must be used within a CurrentAccountProvider',
    );
  }

  return context;
};

const CurrentAccountProvider: FC<AccountProviderProps> = ({
  role,
  account,
  children,
}) => {
  const hasSubscriptionStatus = useCallback(
    (requirements: SubscriptionStatusCheckRequirements) =>
      hasSubscriptionStatusUtil(account, requirements),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isActiveAccount = useMemo(
    () =>
      account?.status === AccountStatus.ACTIVE &&
      (isSubscribed(account) || !account?.isSubAccount) &&
      (hasActiveMessageSubscription(account) ||
        hasActiveDialerSubscription(account)),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isMessagePlanTrialing = useMemo(
    () =>
      !!isSubscribed(account) &&
      !!hasMessageSubscription(account) &&
      !!isMessageSubscriptionTrialing(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isMessagePlanCancelled = useMemo(
    () => !!isSubscribed(account) && !hasActiveMessageSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isDialerPlanTrialing = useMemo(
    () =>
      !!isSubscribed(account) &&
      !!hasDialerSubscription(account) &&
      !!isDialerSubscriptionTrialing(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isDialerPlanCancelled = useMemo(
    () => !!isSubscribed(account) && !hasActiveDialerSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isActiveWithActiveMessagePlan = useMemo(
    () =>
      account.status === AccountStatus.ACTIVE &&
      !!isSubscribed(account) &&
      !!hasMessageSubscription(account) &&
      !!hasActiveMessageSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isActiveWithInactiveMessagePlan = useMemo(
    () =>
      account.status === AccountStatus.ACTIVE &&
      !!isSubscribed(account) &&
      !!hasMessageSubscription(account) &&
      !!hasInactiveMessageSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isActiveWithActiveDialerPlan = useMemo(
    () =>
      account?.status === AccountStatus.ACTIVE &&
      !!isSubscribed(account) &&
      !!hasDialerSubscription(account) &&
      hasActiveDialerSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isActiveWithInactiveDialerPlan = useMemo(
    () =>
      account?.status === AccountStatus.ACTIVE &&
      !!isSubscribed(account) &&
      !!hasDialerSubscription(account) &&
      hasInactiveDialerSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isInactiveWithPlanAccount = useMemo(
    () =>
      !!(
        account?.status === AccountStatus.INACTIVE &&
        !account?.isSubAccount &&
        isSubscribed(account)
      ),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isInactiveWithNoPlans = useMemo(
    () => account?.status === AccountStatus.INACTIVE && !isSubscribed(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isInactiveWithActiveMessagePlan = useMemo(
    () =>
      account?.status === AccountStatus.INACTIVE &&
      !!isSubscribed(account) &&
      !!hasMessageSubscription(account) &&
      !!hasActiveMessageSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isInactiveWithInactiveMessagePlan = useMemo(
    () =>
      account?.status === AccountStatus.INACTIVE &&
      !!isSubscribed(account) &&
      !!hasDialerSubscription(account) &&
      !!hasActiveDialerSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isActiveWithNoDialerPlan = useMemo(
    () =>
      account?.status === AccountStatus.ACTIVE &&
      !hasDialerSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isInactiveWithNoDialerPlan = useMemo(
    () =>
      account?.status === AccountStatus.INACTIVE &&
      !hasDialerSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isInactiveWithActiveDialerPlan = useMemo(
    () =>
      account?.status === AccountStatus.INACTIVE &&
      !!isSubscribed(account) &&
      !!hasDialerSubscription(account) &&
      !!hasActiveDialerSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isInactiveWithInactiveDialerPlan = useMemo(
    () =>
      account?.status === AccountStatus.INACTIVE &&
      isSubscribed(account) &&
      hasDialerSubscription(account) &&
      !hasActiveDialerSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const hasDialerSubscriptionAsInactive = useMemo(
    () =>
      !!hasDialerSubscription(account) &&
      !hasActiveDialerSubscription(account) &&
      !hasPastDueDialerSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const hasAccountMessageSubscription = useMemo(
    () =>
      account?.status === AccountStatus.ACTIVE &&
      !!isSubscribed(account) &&
      !!hasMessageSubscription(account),
    [account],
  );

  /**
   *  @deprecated use hasSubscriptionStatus
   **/
  const isWithoutDialerSubscription = useMemo(
    () => !hasDialerSubscription(account),
    [account],
  );

  const isImpersonate = useMemo(
    () => role === AccountClaimRole.IMPERSONATE,
    [role],
  );

  const isAdmin = useMemo(() => role === AccountClaimRole.ADMIN, [role]);
  const isTrial = useMemo(
    () => account?.status === AccountStatus.TRIAL,
    [account],
  );

  return (
    <CurrentAccountContext.Provider
      value={{
        currentAccount: account,
        isActiveAccount,
        isMessagePlanTrialing,
        isMessagePlanCancelled,
        isDialerPlanTrialing,
        isDialerPlanCancelled,
        isActiveWithActiveMessagePlan,
        isActiveWithActiveDialerPlan,
        isActiveWithInactiveMessagePlan,
        isActiveWithInactiveDialerPlan,
        isInactiveWithPlanAccount,
        isInactiveWithNoPlans,
        isInactiveWithActiveMessagePlan,
        isInactiveWithInactiveMessagePlan,
        isActiveWithNoDialerPlan,
        isInactiveWithNoDialerPlan,
        isInactiveWithActiveDialerPlan,
        isInactiveWithInactiveDialerPlan,
        isImpersonate,
        isAdmin,
        isTrial,
        isWithoutDialerSubscription,
        hasDialerSubscriptionAsInactive,
        hasAccountMessageSubscription,
        hasSubscriptionStatus,
      }}>
      {children}
    </CurrentAccountContext.Provider>
  );
};

export default CurrentAccountProvider;
