import { colors } from '@app/theme/colors';

export const rechargeOptions = [
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 300,
    label: '300',
    bonusLabel: 'Get $20 bonus',
    bonusBg: colors.linen,
    color: colors.orangeBlaze,
  },
  {
    value: 500,
    label: '500',
    bonusLabel: 'Get $50 bonus',
    bonusBg: colors.linen,
    color: colors.orangeBlaze,
  },
];
