import React, { FC, useCallback } from 'react';
import {
  Flex,
  Button,
  useDisclosure,
  Tooltip,
  Box,
  Text,
} from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { SearchNumbersModal } from '@app/components/SearchNumbersModal';
import ROUTES from '@app/utils/routes';
import { useUserPhoneNumbersReport } from '@app/hooks/useUserPhoneNumbersReport';
import { Scope } from '@app/api/gql/generated-types';

export interface BuyNumberProps {
  isDialerPlanInactive: boolean;
}

export const BuyNumber: FC<BuyNumberProps> = ({ isDialerPlanInactive }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    hasEnoughPhoneNumbersDialer,
    refetch: refetchUserPhones,
    minimunPhoneNumberDialerCount,
  } = useUserPhoneNumbersReport(Scope.DIALER);

  const isDisabled = isDialerPlanInactive;

  const navigate = useNavigate();

  const goToPage = useCallback(() => {
    onClose();
    navigate(ROUTES.settingsMembership);
  }, [navigate, onClose]);

  return (
    <Flex alignItems="center">
      <Tooltip
        hasArrow
        closeDelay={2000}
        isDisabled={!isDisabled}
        label={
          <Text>
            To buy a phone number you must first
            <Text
              color="primary.600"
              cursor="pointer"
              display="inline"
              marginLeft="4px"
              textDecoration="underline"
              variant="link"
              onClick={goToPage}>
              activate your subscription.
            </Text>
          </Text>
        }
        maxW="233px"
        placement="top"
        pointerEvents="all">
        <Box>
          <Button
            data-cy="buy-number-btn"
            isDisabled={isDisabled}
            mr="15px"
            variant="primary"
            width="116px"
            onClick={onOpen}>
            Buy number
          </Button>
        </Box>
      </Tooltip>
      {isOpen && (
        <SearchNumbersModal
          hasEnoughPhoneNumbers={hasEnoughPhoneNumbersDialer}
          isOpen={isOpen}
          refetchUserPhones={refetchUserPhones}
          requiredUserPhonesNumbers={minimunPhoneNumberDialerCount}
          onClose={onClose}
        />
      )}
    </Flex>
  );
};
