import React, { CSSProperties } from 'react';
import { Text } from '@chakra-ui/react';
import { wordPluralize } from '@app/utils/string';
import { colors } from '@app/theme/colors';
import { DisclaimerBox } from '../DisclaimerBox';

interface ILackPhoneNumbersWarningProps {
  lackPhoneNumbersCount?: number;
  customMessage?: React.ReactNode;
  onPurchaseClick?: () => void;
  rounded?: boolean;
  style?: CSSProperties | undefined;
  width?: string;
  backgroundColor?: string;
}

export const LackPhoneNumbersWarning: React.FC<
  ILackPhoneNumbersWarningProps
> = ({
  rounded,
  style,
  onPurchaseClick,
  lackPhoneNumbersCount = 0,
  customMessage,
  width = '100%',
  backgroundColor = 'zircon',
}) => {
  const purchaseElStyle = onPurchaseClick
    ? {
        color: colors.primary[600],
        cursor: 'pointer',
      }
    : {};

  return (
    <DisclaimerBox
      bg={backgroundColor}
      borderRadius={rounded ? '8px' : '3px'}
      gap="10px"
      margin="10px 0"
      style={style}
      width={width}>
      {customMessage || (
        <Text lineHeight="19px" textAlign="left" variant="list-style">
          To optimize delivery and response rates, you`ll need to{' '}
          <span style={purchaseElStyle} onClick={onPurchaseClick}>
            purchase
          </span>
          <br />a minimum of {lackPhoneNumbersCount} phone{' '}
          {wordPluralize(lackPhoneNumbersCount, 'number')} to launch your
          campaigns.
        </Text>
      )}
    </DisclaimerBox>
  );
};
