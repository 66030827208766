import { Box } from '@chakra-ui/react';
import isBoolean from 'lodash/isBoolean';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { MultiValue } from 'react-select';
import {
  AccountDocument,
  LabelFragment,
  useAccountQuery,
  useFindLabelsQuery,
  useUpdateMessagingProfileMutation,
} from '@app/api/gql/generated-types';
import MultiSelectField from '@app/components/MultiSelectField';
import { useFilterLabels } from '@app/hooks/useFilterLabels';

export const Labels: FC = () => {
  const { data: { account } = {} } = useAccountQuery();
  const { messagingProfile } = account;

  const [updateMessagingProfile] = useUpdateMessagingProfileMutation({
    refetchQueries: [{ query: AccountDocument }],
    context: {
      useApolloNetworkStatus: true,
    },
  });

  const alertLabels = useMemo(() => {
    return isBoolean(messagingProfile?.phoneSettings?.alertFilter?.labels)
      ? []
      : messagingProfile?.phoneSettings?.alertFilter?.labels;
  }, [messagingProfile?.phoneSettings?.alertFilter?.labels]);

  const {
    data: { findLabels: findLabelsByIds } = { findLabels: null },
    loading: selectedIsLoading,
  } = useFindLabelsQuery({
    variables: {
      filter: {
        ids: alertLabels ?? [],
      },
    },
    skip: !alertLabels?.length,
  });

  const [labelsState, setLabelsState] = useState<MultiValue<LabelFragment>>([]);

  useEffect(() => {
    if (findLabelsByIds?.total) {
      setLabelsState(findLabelsByIds?.items);
    }
  }, [findLabelsByIds?.items, findLabelsByIds?.total]);

  const { options, fetchMore, isLoading } = useFilterLabels(labelsState);

  const selectedLabels = useMemo(
    () => options?.filter((label) => label.isChecked),
    [options],
  );

  const onSubmit = () => {
    void updateMessagingProfile({
      variables: {
        input: {
          phoneSettings: {
            alertFilter: {
              labels: labelsState?.map((item) => item.id),
            },
          },
        },
      },
    });
  };

  return (
    <Box maxWidth="408px" width="100%">
      <MultiSelectField
        isMulti
        autoFocus={false}
        closeMenuOnSelect={false}
        getOptionLabel={(option) => option.title}
        getOptionValue={(option) => option.id}
        hideSelectedOptions={false}
        isClearable={false}
        isLoading={isLoading || selectedIsLoading}
        isSearchable={false}
        options={options}
        placeholder="Select label"
        value={selectedLabels}
        onChange={setLabelsState}
        onMenuClose={onSubmit}
        onMenuScrollToBottom={fetchMore}
      />
    </Box>
  );
};
