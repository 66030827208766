import { Location } from 'react-router-dom';

export const SUPPORT_LINK = 'https://support.smartercontact.com';

export const SUPPORT_ARTICLES = {
  a2p10dlcRegistrationWithCarriers: `${SUPPORT_LINK}/a2p-10dlc-registration-with-carriers`,
  callRecordingExplained: `${SUPPORT_LINK}/call-recording-explained-know-your-rights`,
  creatingMessageTemplate: `${SUPPORT_LINK}/creating-a-message-template`,
};

export const ROOT_ROUTES = {
  campaign: '/campaign',
  settings: '/settings',
  contacts: '/contacts',
  messenger: '/messenger',
  skiptrace: '/skiptrace',
};

const ROUTES = {
  root: '/',
  calendar: '/calendar',
  calendarView: '/calendar/:nextView',
  campaigns: '/campaigns',
  campaignCreate: `${ROOT_ROUTES.campaign}/create`,
  campaignEdit: `${ROOT_ROUTES.campaign}/edit/:id`,
  campaignKeywordCreate: `${ROOT_ROUTES.campaign}/keyword/create`,
  campaignDuplicate: `${ROOT_ROUTES.campaign}/duplicate/:id`,
  campaignKeywordEdit: `${ROOT_ROUTES.campaign}/keyword/edit/:id`,
  campaignKeywordDuplicate: `${ROOT_ROUTES.campaign}/keyword/duplicate/:id`,
  contacts: `${ROOT_ROUTES.contacts}`,
  contactsAll: `${ROOT_ROUTES.contacts}/all-contacts`,
  contactsAllInfo: `${ROOT_ROUTES.contacts}/all-contacts/:contactId/info`,
  contactsGroups: `${ROOT_ROUTES.contacts}/groups/:groupId`,
  contactsGroupsContactInfo: `${ROOT_ROUTES.contacts}/groups/:groupId/:contactId/info`,
  contactEdit: `${ROOT_ROUTES.contacts}/edit/:contactId`,
  contactsDeleted: `${ROOT_ROUTES.contacts}/deleted`,
  contactsDeletedInfo: `${ROOT_ROUTES.contacts}/deleted/:contactId/info`,
  forgotPassword: '/forgot-password',
  login: '/login',
  logout: '/logout',
  macros: '/macros',
  messenger: '/messenger',
  messengerFull: '/messenger-full',
  messengerFullInfo: '/messenger-full/:id/info',
  messengerFullScheduled: '/messenger-full/:id/scheduled',
  messengerMacros: '/messenger/macros',
  reporting: '/reporting',
  signUp: '/signup',
  skiptrace: '/skiptrace',
  skiptraceEdit: '/skiptrace/edit/:route',
  changePassword: '/change-password',
  questionnaire: '/questionnaire',
  securityResetPasswordInfo: '/security-reset-password-info',
  securityPhoneVerification: '/security-phone-verification',
  securityResetPassword: '/security-reset-password',
  settings: `${ROOT_ROUTES.settings}`,
  settingsSettings: `${ROOT_ROUTES.settings}/settings`,
  settingsMembership: `${ROOT_ROUTES.settings}/membership`,
  settingsMembershipAddCard: `${ROOT_ROUTES.settings}/membership/add-card`,
  settingsMessagingSetup: `${ROOT_ROUTES.settings}/phone-setup`,
  settingsCallingSetup: `${ROOT_ROUTES.settings}/calling-setup`,
  settingsProfile: `${ROOT_ROUTES.settings}/profile`,
  settingsApi: `${ROOT_ROUTES.settings}/api`,
  settingsAffiliate: `${ROOT_ROUTES.settings}/affiliate-program`,
  settingsBrandRegistration: `${ROOT_ROUTES.settings}/brand-registration`,
  settings10DlcRegistration: `${ROOT_ROUTES.settings}/dlc-registration`,
  settingsContactManagement: `${ROOT_ROUTES.settings}/contact-management`,
  settingsNotifications: `${ROOT_ROUTES.settings}/notifications`,
  settingsMobileApp: `${ROOT_ROUTES.settings}/mobile-app`,
  settingsResources: `${ROOT_ROUTES.settings}/resources`,
  oauth: '/oauth',
  notFound: '/404',
  userSetup: '/user-setup',
  affiliates: '/affiliates',
  labels: '/labels',
  groups: '/groups',
  impersonate: '/impersonate',
  popupConstructor: '/popup-constructor',
  popupConstructorEdit: '/popup-constructor/edit/:id',
  popupConstructorCreate: '/popup-constructor/create',
  envInfo: '/env',
  dialer: '/dialer',
  dialerAll: '/dialer/all',
  createDialerCampaign: '/dialer/create',
  editDialerCampaign: '/dialer/edit/:id',
  powerDialer: '/dialer/:campaignId/dial',
};

export const PUBLIC_ROUTES = [
  ROUTES.login,
  ROUTES.logout,
  ROUTES.signUp,
  ROUTES.forgotPassword,
  ROUTES.changePassword,
  ROUTES.securityResetPassword,
  ROUTES.notFound,
];

/**
 * Generates a path by replacing placeholders with actual values and optionally appends query parameters.
 * @param {string} route - The route template containing placeholders.
 * @param {Object} params - An object containing parameter values.
 * @param {Object} [queryParams] - An optional object containing query parameters.
 * @returns {string} - The generated path with parameters replaced and query parameters appended.
 */
export const generateUrl = (
  route: string,
  params: Record<string, string>,
  queryParams?: Record<string, string>,
): string => {
  let path = route;
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      path = path.replace(`:${key}`, params[key]);
    }
  }

  // Append query parameters if they exist
  if (queryParams) {
    const queryString = new URLSearchParams(queryParams).toString();
    path += `?${queryString}`;
  }

  return path;
};

export const isCurrentRouteSecurityPassword = (location: Location): boolean =>
  location.pathname.startsWith(ROUTES.securityResetPassword) ||
  location.pathname.startsWith(ROUTES.securityResetPasswordInfo);

export const isCurrentRouteLogin = (location: Location): boolean =>
  location.pathname.startsWith(ROUTES.login);

export const isCurrentRouteLogout = (location: Location): boolean =>
  location.pathname.startsWith(ROUTES.logout);

export const isCurrentRouteSignUp = (location: Location): boolean =>
  location.pathname.startsWith(ROUTES.signUp);

export default ROUTES;
