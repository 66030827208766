import React, { useRef, useState } from 'react';
import { Box, Button, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useAuth } from 'reactfire';
import {
  Auth,
  AuthError,
  AuthErrorCodes,
  linkWithCredential,
  PhoneAuthProvider,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { getLogger } from '@app/utils/loggerUtils';
import {
  formatPhoneNumbers,
  validatePhoneNumber,
} from '@app/utils/phoneNumberUtils';
import {
  getRecaptchaVerifier,
  RECAPTCHA_NODE_ID,
} from '@app/utils/recaptchaUtils';
import { useUpdateAccountMutation } from '@app/api/gql/generated-types';
import ToastNotify from '@app/components/ToastNotifier';
import { extractAuthErrorMessage } from '@app/utils/apolloErrorsMapHelper';
import InfoIcon from '@app/icons/InfoIcon.svg?react';

const LOG = getLogger('Verification');
import { ShortCodeVerificationInput } from '@app/components/next/organisms/ShortCodeVerificationInput';
import { TButtonVariant } from '@app/theme/components/button';
import CheckMarkIcon from '@app/icons/check-mark-icon.svg?react';
import PhoneNumberInput from '../../../PhoneNumberInput/PhoneNumberInput';
import { DisclaimerBox } from '../../moleculas/DisclaimerBox';

interface PhoneNumberVerificationInputProps {
  codeVerified?: boolean;
  setCodeVerified?: (codeVerified: boolean) => void;
}

export const PhoneNumberVerificationInput: React.FC<
  PhoneNumberVerificationInputProps
> = ({ codeVerified, setCodeVerified }) => {
  const [update] = useUpdateAccountMutation();

  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberValid, setPhoneNumberValid] = useState<null | boolean>(
    null,
  );
  const [codeVerificationId, setCodeVerificationId] = useState<null | string>(
    null,
  );

  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [verificationCodeError, setVerificationCodeError] = useState<
    null | string
  >(null);

  const [phoneNumberCodeError, setPhoneNumberCodeError] = useState<
    null | string
  >(null);

  const recaptchaWrapperRef = useRef<HTMLDivElement | null>(null);

  const auth: Auth = useAuth();

  const onChange = (value: string) => {
    if (!value.length) {
      LOG.debug('Phone number is empty');
      return;
    }

    setVerificationCodeSent(false);
    setVerificationCodeError(null);
    setPhoneNumber(value);
    setPhoneNumberValid(validatePhoneNumber(value));
    setPhoneNumberCodeError(null);
  };

  const onConfirmVerificationCode = async (code: string) => {
    try {
      setVerificationCodeError(null);
      setLoading(true);

      const phoneCredential = PhoneAuthProvider.credential(
        codeVerificationId,
        code,
      );

      await linkWithCredential(auth.currentUser, phoneCredential);

      await update({
        variables: {
          data: {
            phone: phoneNumber,
          },
        },
      });

      ToastNotify({
        title: 'Phone number verified',
        status: 'success',
      });

      setCodeVerified(true);
    } catch (err) {
      LOG.error('Error verifying code', err);
      if (err instanceof FirebaseError) {
        let message = 'Something went wrong';
        switch (err.code) {
          case AuthErrorCodes.NEED_CONFIRMATION:
            message = 'Mobile number already exists in the system.';
            break;
          case AuthErrorCodes.INVALID_CODE:
            message = 'Wrong code. Try again.';
            break;
          default:
            break;
        }
        setVerificationCodeError(message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onSendVerificationCode = async () => {
    setVerificationCodeError(null);

    const recaptcha = getRecaptchaVerifier(auth);
    const provider = new PhoneAuthProvider(auth);

    try {
      setLoading(true);
      const verifyId = await provider.verifyPhoneNumber(
        formatPhoneNumbers(phoneNumber),
        recaptcha,
      );

      ToastNotify({
        title: 'Verification code sent',
        status: 'success',
      });

      setCodeVerificationId(verifyId);
      setVerificationCodeSent(true);
    } catch (err) {
      let message = 'Something went wrong';

      if (err instanceof FirebaseError) {
        message = err.message;

        switch (err.code) {
          case AuthErrorCodes.INVALID_PHONE_NUMBER:
          case AuthErrorCodes.INVALID_APP_CREDENTIAL:
            message = 'Please enter a valid phone number';
            break;
          default:
            break;
        }
      }
      setPhoneNumberCodeError(message);
      if (
        err instanceof FirebaseError &&
        err.code !== AuthErrorCodes.INVALID_PHONE_NUMBER &&
        err.code !== AuthErrorCodes.INVALID_APP_CREDENTIAL
      ) {
        ToastNotify({
          status: 'error',
          position: 'top-right',
          title: extractAuthErrorMessage(err as AuthError),
        });
      }

      LOG.error('Error sending verification code', err);
    } finally {
      if (recaptchaWrapperRef.current) {
        recaptchaWrapperRef.current.innerHTML = `<div id="${RECAPTCHA_NODE_ID}"></div>`;
      }
      setLoading(false);
      recaptcha.clear();
    }
  };

  const onReSendVerificationCode = async () => {
    try {
      if (recaptchaWrapperRef.current) {
        recaptchaWrapperRef.current.innerHTML = `<div id="${RECAPTCHA_NODE_ID}"></div>`;
      }
      await onSendVerificationCode();
    } catch (err) {
      LOG.error('Error resending verification code', err);
    }
  };
  const showCodeVerification = verificationCodeSent && !codeVerified;
  const showSubmitButton = phoneNumberValid && !codeVerified;

  return (
    <Box position="relative">
      <div ref={recaptchaWrapperRef}>
        <div id={RECAPTCHA_NODE_ID} />
      </div>
      <InputGroup>
        <PhoneNumberInput
          hasError={phoneNumberValid === false}
          isDisabled={codeVerified}
          placeholder="Phone: (321) 456-7890"
          style={{
            pointerEvents: loading ? 'none' : 'auto',
            height: '48px',
          }}
          value={phoneNumber}
          onChange={(e) => onChange(e.target.value)}
        />
        {showSubmitButton && (
          <InputRightElement m="0 2px" top="4px" width="auto">
            <Button
              disabled={loading}
              h="44px"
              isLoading={loading}
              variant={TButtonVariant.enum.primary}
              onClick={
                verificationCodeSent
                  ? onReSendVerificationCode
                  : onSendVerificationCode
              }>
              {verificationCodeSent ? 'Resend code' : 'Confirm'}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      {codeVerified && (
        <Box position="absolute" right="-28px" top="12px">
          <CheckMarkIcon height="20px" width="20px" />
        </Box>
      )}
      {showCodeVerification && (
        <Box mt="20px">
          <ShortCodeVerificationInput
            codeVerificationId={codeVerificationId}
            error={verificationCodeError}
            loading={loading}
            onVerifyCode={onConfirmVerificationCode}
          />
        </Box>
      )}
      {phoneNumberCodeError && (
        <DisclaimerBox
          alignItems="center"
          color="error"
          display="flex"
          fontSize="12px"
          gap="8px"
          icon={InfoIcon}
          lineHeight="normal"
          ml="16px"
          mt="4px"
          p="0">
          {phoneNumberCodeError}
        </DisclaimerBox>
      )}
    </Box>
  );
};
