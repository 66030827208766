import React, { FC, useCallback } from 'react';
import { useFormikContext } from 'formik';

import { DragAndDropInput } from '@app/components/DragAndDrop/DragAndDropInput';
import { PopupConstructor } from '@app/pages/PopupConstructor/types';
import { useUploadImageWithSignedUrl } from '@app/api/mutations/useUploadImageWithSignedUrl';

interface ImageInputProps {
  onUpload: (path: string) => void;
  disabled?: boolean;
}

export const ImageInput: FC<ImageInputProps> = ({
  onUpload,
  disabled = false,
}) => {
  const { setFieldValue } = useFormikContext<PopupConstructor>();
  const { loading, upload } = useUploadImageWithSignedUrl();

  const handleDrop = useCallback(
    async (files: File[]) => {
      await setFieldValue('template.imageSrc', URL.createObjectURL(files[0]));
      const path = await upload(files[0]);
      onUpload(path);
    },
    [setFieldValue, upload, onUpload],
  );

  return (
    <DragAndDropInput
      disabled={disabled}
      loading={loading}
      onDrop={handleDrop}
    />
  );
};
