import React, { FC } from 'react';
import {
  Box,
  TagLabel,
  TagCloseButton,
  Flex,
  Tag,
  Tooltip,
} from '@chakra-ui/react';
import truncate from 'lodash/truncate';

import CloseIcon from '@app/icons/close-icon.svg?react';
import ArrowUp from '@app/icons/arrow-up-icon.svg?react';
import ArrowDown from '@app/icons/arrow-down-icon.svg?react';
import { tinyColor, toneColor } from '@app/utils/toneColor';
import { Label } from '@app/api/gql/generated-types';

interface LabelItemProps {
  label: Label;
  isGroup?: boolean;
  isMonochrome?: boolean;
  editable?: boolean;
  editVisible?: boolean;
  onDelete?: (id: string) => void;
}

export const LabelItem: FC<LabelItemProps> = ({
  label,
  isGroup,
  isMonochrome,
  editable,
  editVisible,
  onDelete,
}) => {
  const { title, color, id } = label;

  const bg = isMonochrome
    ? 'secondary.100'
    : color || tinyColor(color).toHex8String();

  const isDisabled = !(isMonochrome && title?.length > 15);
  const displayColor = isMonochrome ? 'main.300' : toneColor(color);
  const displayTitle = truncate(title, {
    length: isMonochrome ? 15 : 10,
  });

  return (
    <Tag
      bg={bg}
      borderRadius="12px"
      justifyContent="center"
      margin={isGroup ? '0px 5px' : '3px 3px'}
      maxWidth={isGroup || isMonochrome ? '100%' : '99px'}
      minWidth="auto"
      padding={isMonochrome ? '2px 10px' : '4px 12px'}
      size="md"
      variant="solid"
      width={isGroup ? '100%' : 'auto'}>
      <Flex alignItems="center">
        <Tooltip
          hasArrow
          isDisabled={isDisabled}
          label={title}
          offset={[0, 15]}
          placement="top">
          <TagLabel
            color={displayColor}
            fontSize={isMonochrome ? '14px' : '12px'}
            fontWeight="500"
            lineHeight="16px"
            maxW={isMonochrome ? '120px' : '70px'}
            noOfLines={1}
            overflow="hidden"
            textOverflow="ellipsis"
            userSelect="none"
            whiteSpace="nowrap">
            {displayTitle}
          </TagLabel>
        </Tooltip>
        {onDelete && (
          <TagCloseButton color="secondary.300" onClick={() => onDelete(id)}>
            <CloseIcon />
          </TagCloseButton>
        )}
        {editable && (
          <Box color={displayColor} ml="8px">
            {editVisible ? <ArrowUp /> : <ArrowDown />}
          </Box>
        )}
      </Flex>
    </Tag>
  );
};
