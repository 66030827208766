import { useMemo, useState } from 'react';
import chunk from 'lodash/chunk';

import {
  Field,
  useFindSkiptraceFieldsQuery,
} from '@app/api/gql/generated-types';
import { TableHeader } from '@app/api/queries/types';

const phoneSkiptraceFields = {
  PHONE: {
    name: 'Phone',
    id: 'PHONE',
    predefined: true,
  },
  PHONE_TYPE: {
    name: 'Phone Type',
    id: 'PHONE_TYPE',
    predefined: true,
  },
};

export const useSkiptraceHeaders = () => {
  const [headers, setHeaders] = useState<TableHeader>([]);

  const { data: { findSkiptraceFields: fieldsData } = {}, loading } =
    useFindSkiptraceFieldsQuery();

  const headersMap = useMemo(() => {
    const data =
      fieldsData?.reduce(
        (obj, field) => ({
          ...obj,
          [field.id]: {
            ...field,
          },
        }),
        {},
      ) ?? {};
    return { ...data, ...phoneSkiptraceFields };
  }, [fieldsData]) as Record<string, Field>;

  const headerOptions = useMemo(() => chunk(fieldsData, 5), [fieldsData]);

  return {
    loading,
    headersMap,
    headerOptions,
    headers,
    setHeaders,
  };
};
