import {
  Account,
  AccountStatus,
  FeatureType,
  Scope,
} from '@app/api/gql/generated-types';
import { centsToDollars } from '@app/utils/formatNumber';
import { isProductDialer, isProductMessage } from './subscriptionHelpers';

export const getPhoneNumberPrice = (
  account: Account,
  scope?: Scope,
): number => {
  const messagingFeaturePhone = account.features?.find(
    ({ type, product }) =>
      type === FeatureType.LOCAL_PHONE && isProductMessage(product),
  );

  const dialerFeaturePhone = account.features?.find(
    ({ type, product }) =>
      type === FeatureType.LOCAL_PHONE && isProductDialer(product),
  );

  const priceCents =
    scope === Scope.DIALER
      ? dialerFeaturePhone.price
      : (messagingFeaturePhone?.price ?? 100);

  return centsToDollars(priceCents);
};

export const isAccountActive = (account: Account): boolean =>
  account.status === AccountStatus.ACTIVE;

/**
 * Checks if the account is considered abandoned.
 * An account is considered abandoned if it is active but the question area is not passed.
 *
 * @param account - The account to evaluate.
 * @returns True if the account is abandoned, false otherwise.
 */
export const isAccountAbandoned = (account: Account): boolean =>
  account?.status === AccountStatus.ABANDONED;
