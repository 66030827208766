import { FormikContextType } from 'formik';

// it should check for one uppercase character in the string
export const hasUppercaseChar = (value: string): boolean => {
  const regex = /[A-Z]/;
  return regex.test(value);
};

// it should check for one lowercase character in the string
export const hasLowercaseChar = (value: string): boolean => {
  const regex = /[a-z]/;
  return regex.test(value);
};

// it should check for one digit in the string
export const hasDigitChar = (value: string): boolean => {
  const regex = /[0-9]/;
  return regex.test(value);
};

const isSubmitDisabled = (
  form: { isValid?: boolean; dirty?: boolean },
  initial = false,
  deps: boolean[] = [],
): boolean => {
  if (deps.some((item) => !!item)) {
    return true;
  }
  return initial ? !form.isValid : !(form.dirty && form.isValid);
};

export const isInvalid = <V>(formik: FormikContextType<V>, name: keyof V) =>
  !!(formik.errors?.[name] && formik.touched?.[name]);

export { isSubmitDisabled };
