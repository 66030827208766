import { string } from 'yup';
import { phoneRegexp } from '@app/utils/phoneNumberUtils';

export const phoneNumberValidation = string()
  .label('Phone Number')
  .trim()
  .matches(phoneRegexp, 'Phone number must be 10 digits')
  .required('');

export const emptyPhoneNumber = '(___) ___-____';
