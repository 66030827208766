import React, { FC, ReactNode } from 'react';
import { Box, Button, Flex, Text, UseDisclosureProps } from '@chakra-ui/react';

import Popup from '@app/components/Popup';

interface ConfirmDeletionModalProps extends UseDisclosureProps {
  title?: string;
  onConfirm: () => void;
  isLoading: boolean;
  width?: number;
  actionButton?: { label: string };
  children: ReactNode;
}

export const ConfirmDeletionModal: FC<ConfirmDeletionModalProps> = ({
  title = 'Delete',
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  width = 374,
  children,
  actionButton = { label: 'Delete' },
}) => {
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW={`${width}px`}
      title={title}
      onClose={onClose}>
      <Box p="35px 40px 40px">
        <Text
          as="div"
          color="main.400"
          fontSize="14px"
          fontWeight={400}
          mb="30px"
          textAlign="center">
          {children}
        </Text>
        <Flex flexDirection="row" gap={1} justifyContent="right">
          <Button isLoading={isLoading} variant="cancel" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            variant="primary"
            width="114px"
            onClick={onConfirm}>
            {actionButton.label}
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};
