import React from 'react';
import { Box, Flex, Text, Link, useDisclosure, Button } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';

import { SUPPORT_LINK } from '@app/utils/routes';
import LoomPlayer from '@app/components/LoomPlayer';
import video from '@app/utils/videoLinks';

interface EmptyTableFilterProps {
  videoLink?: string;
}

export const EmptyTableFilter: React.FC<EmptyTableFilterProps> = ({
  videoLink = video.contacts.groups,
}) => {
  const videoModal = useDisclosure();

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      h="100%"
      justifyContent="center"
      textAlign="center"
      w="100%">
      <Text mb="15px" variant="tertiary-title">
        No data available
      </Text>
      <Box width="285px">
        <Text as="p" variant="list-style">
          There is no available data to show. Please choose different filters
          and try again.&nbsp;
        </Text>
        <Text>
          <Button
            as={Link}
            display="inline"
            variant="linkSecondary"
            onClick={videoModal.onOpen}>
            Watch this video
          </Button>
          &nbsp;or visit our&nbsp;
          <Link
            as={RouteLink}
            target="_blank"
            to={SUPPORT_LINK}
            variant="inline">
            Help Center
          </Link>
        </Text>
      </Box>
      <LoomPlayer
        isOpenModal={videoModal.isOpen}
        link={videoLink}
        onCloseModal={videoModal.onClose}
      />
    </Flex>
  );
};
