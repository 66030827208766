export const unsubscribeMessagingCalls = {
  basicStarter: 'https://meetings.hubspot.com/ariane-manzano/help?embed=true',
  elite: 'https://meetings.hubspot.com/ariane-manzano/help?embed=true',
};

export const unsubscribeDialerCalls = {
  onlyPlan: 'https://meetings.hubspot.com/ariane-manzano/help?embed=true',
};

export const onboardingCalls = {
  basicStarter:
    'https://meetings.hubspot.com/ariane-manzano/group-onboarding-call?embed=true',
  elite:
    'https://meetings.hubspot.com/ariane-manzano/personalized-onboarding-call?embed=true',
  enterprise:
    'https://meetings.hubspot.com/ariane-manzano/dedicated-onboarding-call?embed=true',
  createCampaigns: 'https://meetings.hubspot.com/kannon/ia',
};

export const problemCalls = {
  basicStarter:
    'https://meetings.hubspot.com/ariane-manzano/group-onboarding-call?embed=true',
  elite: 'https://meetings.hubspot.com/ariane-manzano/audit?embed=true',
  enterprise: 'https://meetings.hubspot.com/ariane-manzano/audit?embed=true',
  createCampaigns: 'https://meetings.hubspot.com/kannon/ia',
};

export const HUBSPOT_MEETING_URL = 'https://meetings.hubspot.com/kannon/app';
