import { Box, Center, Flex } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useCallback, useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  Contact,
  ContactFragmentDoc,
  Label,
  useUpdateContactMutation,
} from '@app/api/gql/generated-types';
import * as typenames from '@app/api/typenames';

import LabelsGroupImg from '@app/icons/labels-group.svg?react';
import { useUpdateContactFragment } from '@app/api/hooks/useUpdateContactFragment';
import { useDebounce } from '@app/utils/useDebounce';
import { colors } from '@app/theme/colors';
import { LabelItem } from './LabelItem';
import { LabelViewContainer } from './LabelViewContainer';

interface LabelListProps {
  labels: Label[];
  accountId?: string;
  isMonochrome?: boolean;
  visibleItemsCount?: number;
  editable?: boolean;
  contactId?: string;
  justifyContent?: 'space-between' | 'flex-start';
}

const CircleLabel: React.FC<{
  isOpen: boolean;
}> = ({ isOpen }) => {
  return (
    <Center
      border="1px"
      borderColor={isOpen ? 'mystic' : colors.secondary[400]}
      borderRadius="50%"
      cursor="pointer"
      height="24px"
      width="24px">
      <LabelsGroupImg
        style={{ color: isOpen ? 'mystic' : colors.secondary[400] }}
      />
    </Center>
  );
};

export const LabelList: FC<LabelListProps> = ({
  labels,
  isMonochrome = false,
  visibleItemsCount = 2,
  editable = false,
  contactId,
  accountId,
  justifyContent = 'space-between',
}) => {
  const client = useApolloClient();
  const [mutate] = useUpdateContactMutation();
  const { updateContactFragment } = useUpdateContactFragment();
  const headLabels = labels?.slice(0, visibleItemsCount);
  const hiddenLabels = labels?.slice(visibleItemsCount);
  const isSubAccount = !!accountId;

  const handler = useCallback(
    async (options: Label[]) => {
      if (isSubAccount) {
        return;
      }

      updateContactFragment({
        contactId,
        labels: options,
      });

      const existingContact = client.cache.readFragment<Contact>({
        id: `${typenames.Contact}:${contactId}`,
        fragmentName: typenames.Contact,
        fragment: ContactFragmentDoc,
      });

      await mutate({
        variables: {
          id: contactId,
          input: {
            labels: options.map((option) => option.id),
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateContact: {
            ...existingContact,
            id: contactId,
            labels: options || [],
          },
        },
      });
    },
    [client.cache, contactId, isSubAccount, mutate, updateContactFragment],
  );

  const handleChange = useDebounce(handler, 1000, [handler]);

  const boundaryRef = useRef<HTMLDivElement>(null);

  return (
    <Flex
      alignItems="center"
      justifyContent={justifyContent}
      padding="0 30px 0 0"
      w="full">
      <Flex ref={boundaryRef} alignItems="center">
        {headLabels?.map((label) => (
          <LabelViewContainer
            key={label.id}
            boundaryRef={boundaryRef}
            selectedLabels={labels}
            onChange={handleChange}>
            {({ isOpen }) => (
              <LabelItem
                editable={editable}
                editVisible={isOpen}
                isMonochrome={isMonochrome}
                label={label}
              />
            )}
          </LabelViewContainer>
        ))}
      </Flex>

      {!isEmpty(hiddenLabels) && (
        <Box marginLeft="4px">
          <LabelViewContainer
            boundaryRef={boundaryRef}
            selectedLabels={labels}
            onChange={handleChange}>
            {({ isOpen }) => <CircleLabel isOpen={isOpen} />}
          </LabelViewContainer>
        </Box>
      )}
    </Flex>
  );
};
