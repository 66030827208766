import React, { FC } from 'react';

import RightIcon from '@app/widgets/DTPicker/DatePicker/icons/right-arrow-icon.svg?react';
import MemoSvgImageIcon from '@app/utils/svgImageIcon';

import { NavigationButtonStyle } from '../styles';

interface NextButtonTemplateTypes {
  onClick: () => void;
}

const NextPeriod: FC<NextButtonTemplateTypes> = ({ onClick }) => {
  return (
    <NavigationButtonStyle onClick={onClick}>
      <MemoSvgImageIcon svgIcon={RightIcon} />
    </NavigationButtonStyle>
  );
};

export default NextPeriod;
