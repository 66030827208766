import { isEmpty } from 'lodash/fp';
import {
  OwnPhoneStatus,
  OwnPhoneType,
  useFindOwnPhonesQuery,
  Scope,
} from '@app/api/gql/generated-types';
import { PhoneFragment } from '@app/utils/phoneNumberUtils';

type UserPhoneNumbersFilters = {
  messagingEnabled?: boolean;
  scope?: Scope;
};

const MIN_PHONE_NUMBERS_COUNT = 3;
const PAGINATION_OWN_PHONE = 50;

export const processNumbers = (phones: PhoneFragment[]) => {
  const ownedNumbers = phones || [];
  const isAccountHasPhones = !!ownedNumbers?.length;
  const phoneNumbersCount = ownedNumbers.filter((item) => {
    return (
      [OwnPhoneStatus.APPROVED, OwnPhoneStatus.PENDING].includes(item.status) &&
      [OwnPhoneType.TOLL_FREE, OwnPhoneType.LOCAL].includes(item.type)
    );
  }).length;
  const lackPhoneNumbersCount =
    phoneNumbersCount <= MIN_PHONE_NUMBERS_COUNT
      ? MIN_PHONE_NUMBERS_COUNT - phoneNumbersCount
      : 0;
  const lackOwnedPhoneNumbersCount =
    ownedNumbers.length <= MIN_PHONE_NUMBERS_COUNT
      ? MIN_PHONE_NUMBERS_COUNT - ownedNumbers.length
      : 0;
  return {
    ownedNumbers,
    isAccountHasPhones,
    lackPhoneNumbersCount,
    lackOwnedPhoneNumbersCount,
    phoneNumbersCount,
  };
};

export const useUserPhoneNumbers = (filters?: UserPhoneNumbersFilters) => {
  const {
    data: { findOwnPhones } = {},
    loading,
    refetch,
  } = useFindOwnPhonesQuery({
    variables: {
      filter: {
        callingEnabled: true,
        messagingEnabled: filters?.messagingEnabled,
        scope: filters?.scope,
      },
      pagination: {
        limit: PAGINATION_OWN_PHONE,
      },
    },
  });

  const {
    ownedNumbers,
    isAccountHasPhones,
    lackPhoneNumbersCount,
    lackOwnedPhoneNumbersCount,
    phoneNumbersCount,
  } = processNumbers(findOwnPhones?.items);

  return {
    refetch,
    loading,
    ownedNumbers,
    isAccountHasPhones,
    lackPhoneNumbersCount,
    lackOwnedPhoneNumbersCount,
    phoneNumbersCount,
    ownedNumbersCount: ownedNumbers.length,
    minimumPhoneNumbersCount: MIN_PHONE_NUMBERS_COUNT,
    hasEnoughPhoneNumbers: phoneNumbersCount >= MIN_PHONE_NUMBERS_COUNT,
    hasEnoughOwnedPhoneNumbers: ownedNumbers.length >= MIN_PHONE_NUMBERS_COUNT,
    hasPhoneNumbers: !isEmpty(ownedNumbers),
  };
};
