import React, {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
  UIEvent,
} from 'react';
import { Box, PopoverArrow, PopoverContent } from '@chakra-ui/react';
import { debounce, isEmpty } from 'lodash';
import { useApolloClient } from '@apollo/client';
import {
  FindNotificationsDocument,
  FindNotificationsQuery,
  Notification,
  useAccountQuery,
  useFindNotificationsQuery,
  useNotificationCreatedSubscription,
} from '@app/api/gql/generated-types';
import { useApolloLoadingStatus } from '@app/api/hooks/useApolloLoadingStatus';
import { createPaginationVariables } from '@app/api/apollo-pagination';
import { NotificationsPopoverHeader } from '../NotificationsPopoverHeader/NotificationsPopoverHeader';
import { NotificationsPopoverBody } from '../NotificationsPopoverBody/NotificationsPopoverBody';
import {
  getNotificationMetadataBy,
  NotificationMetaItem,
  transformItemToMetaItem,
} from './utils';

const SCROLL_THRESHOLD = 10;
const DEBOUNCE_THRESHOLD = 50;

interface NotificationsPopoverContentProps {
  isOpen: boolean;
  itemsUnreadLength: number;
}

export const NotificationsPopoverContent: FC<
  NotificationsPopoverContentProps
> = ({ isOpen, itemsUnreadLength }: NotificationsPopoverContentProps) => {
  const [items, setItems] = useState<NotificationMetaItem[]>([]);
  const [clickedUnread, setClickedUnread] = useState(true);
  const [unreadItemsEmpty, setUnreadItemsEmpty] = useState(false);
  const popoverBodyRef = useRef<HTMLDivElement | null>(null);

  const client = useApolloClient();

  const { data: { account } = {} } = useAccountQuery();

  const metaData = getNotificationMetadataBy({
    isSubAccount: account.isSubAccount,
  });

  const {
    data: { findNotifications } = { findNotifications: null },
    fetchMore,
    networkStatus,
  } = useFindNotificationsQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const { isLoading, isFetchingNextPage, isReady } =
    useApolloLoadingStatus(networkStatus);

  const fetchNextPage = debounce(async () => {
    if (!isLoading && !isFetchingNextPage && findNotifications?.hasNext) {
      await fetchMore(createPaginationVariables(findNotifications));
    }
  }, DEBOUNCE_THRESHOLD);

  const isShowNoResults = isReady && isEmpty(items);
  const isShowNoResultsUnread = unreadItemsEmpty && clickedUnread;
  const isShowNoResultsAll = isShowNoResults && !clickedUnread;

  useNotificationCreatedSubscription({
    onData: ({ data: { data: notificationData } = {} }) => {
      const newNotification = notificationData?.notificationCreated ?? null;

      const cacheData = client.cache.readQuery<FindNotificationsQuery>({
        query: FindNotificationsDocument,
      });

      if (!cacheData) {
        return;
      }
      //if there is a new notification, should be added to the cache list
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { findNotifications } = cacheData;
      client.cache.writeQuery<FindNotificationsQuery>({
        query: FindNotificationsDocument,
        data: {
          findNotifications: {
            ...findNotifications,
            items: [{ ...newNotification }, ...findNotifications.items],
          },
        },
      });
    },
  });

  //transform items from BE, adding wording, icons, links, things FE knows about
  useEffect(() => {
    if (findNotifications?.items.length > 0) {
      const updatedMetadDataItems: NotificationMetaItem[] =
        findNotifications.items.map((item: Notification) => {
          return transformItemToMetaItem(item, metaData);
        });
      setItems(updatedMetadDataItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findNotifications]);

  //filter out items unread here FE, better performance
  const filteredItems: NotificationMetaItem[] = useMemo(() => {
    const unreadItems =
      clickedUnread && !isEmpty(items)
        ? items.filter((item) => !item.isRead)
        : items;

    setUnreadItemsEmpty(unreadItems.length === 0);
    return unreadItems;
  }, [clickedUnread, items]);

  const isUnreadItems = useMemo(() => {
    return !!items?.find((item) => !item.isRead);
  }, [items]);

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget as HTMLTableElement;

    const isNearBottom =
      Math.ceil(target.scrollTop + target.clientHeight) >=
      target.scrollHeight - SCROLL_THRESHOLD;

    if (isNearBottom && !isFetchingNextPage) {
      void fetchNextPage();
    }
  };

  // Reset clickedUnread to true when the popover is closed
  useEffect(() => {
    if (!isOpen) {
      setClickedUnread(true);
    }
  }, [isOpen]);

  return (
    <PopoverContent borderRadius="12px" width="338px">
      <NotificationsPopoverHeader
        clickedUnread={clickedUnread}
        isUnreadItems={isUnreadItems}
        setClickedUnread={setClickedUnread}
      />
      <NotificationsPopoverBody
        clickedUnread={clickedUnread}
        filteredItems={filteredItems}
        handleScroll={handleScroll}
        isLastPage={!findNotifications?.hasNext}
        isShowNoResults={isShowNoResults}
        isShowNoResultsAll={isShowNoResultsAll}
        isShowNoResultsUnread={isShowNoResultsUnread}
        itemsUnreadLength={itemsUnreadLength}
        popoverBodyRef={popoverBodyRef}
      />
      <Box left="215px" position="absolute">
        <PopoverArrow />
      </Box>
    </PopoverContent>
  );
};
