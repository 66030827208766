import React, { FC } from 'react';
import {
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  PropsValue,
} from 'react-select';
import {
  OwnPhone,
  OwnPhoneFragment,
  OwnPhoneType,
} from '@app/api/gql/generated-types';
import SelectField from '../SelectField';
import { SelectCustomMenu } from '../next/moleculas/SelectCustomMenu/SelectCustomMenu';
import { NoOptionsMessage } from './NoOptionsMessage';
import { SingleValue } from './SingleValue';
import Option from './Option';

interface PhoneNumberSelectProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  options: OptionsOrGroups<OwnPhoneFragment, GroupBase<OwnPhoneFragment>>;
  value:
    | OwnPhone[]
    | {
        __typename?: 'OwnPhone';
        id: string;
        type: OwnPhoneType;
        number: string;
        location?: {
          __typename?: 'PhoneNumberLocation';
          abbreviation: string;
          state: string;
        };
      };
  onChange: (newValue: OwnPhoneFragment | MultiValue<OwnPhoneFragment>) => void;
  placeholder?: string;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
  showDefaultMenu?: boolean;
  onCreateNewEntity?: () => void;
  requiredInfoMessages?: React.ReactNode;
}

const PhoneNumberSelect: FC<PhoneNumberSelectProps> = ({
  isLoading,
  options,
  value,
  onChange,
  placeholder = 'Select',
  isDisabled,
  onMenuOpen,
  onMenuClose,
  onCreateNewEntity,
  requiredInfoMessages,
}) => {
  const componentsSets = {
    Option,
    SingleValue,
    NoOptionsMessage: requiredInfoMessages
      ? (): null => null
      : NoOptionsMessage,
  };
  return (
    <SelectField
      components={{
        ...componentsSets,
        Menu: (props) =>
          SelectCustomMenu({
            menuProps: props,
            buttonText: 'Buy phone number',
            requiredInfoMessages,
          }),
      }}
      getOptionLabel={(phone) => phone.number}
      getOptionValue={(phone) => phone.id}
      isDisabled={isDisabled}
      isLoading={isLoading}
      options={options}
      placeholder={placeholder}
      value={value as PropsValue<OwnPhoneFragment>}
      onChange={onChange}
      onCreateNewEntity={() => onCreateNewEntity?.()}
      onMenuClose={() => onMenuClose?.()}
      onMenuOpen={() => onMenuOpen?.()}
    />
  );
};

export default PhoneNumberSelect;
