import { Button, Text } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';

import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import { FormatNumber } from '../FormatNumber';

interface AddFundsMessageSkiptraceProps {
  cost?: number;
  onOpenPayment?: () => void;
}

export const AddFundsMessageSkiptrace: FC<AddFundsMessageSkiptraceProps> = ({
  onOpenPayment,
  cost,
}) => {
  const account = useCurrentAccountData();

  const isSecondaryAccount = useMemo(
    () => !!account?.isSubAccount,
    [account?.isSubAccount],
  );

  return (
    <Text mt="15px" textAlign="center" variant="heading-fifth">
      To skip trace this file please have a minimum $
      <FormatNumber fractionDigits={2} number={cost} /> in{' '}
      {isSecondaryAccount ? (
        <Text as="span">your wallet. Please contact your primary account.</Text>
      ) : (
        <Button
          _hover={{ textDecoration: 'none' }}
          variant="link"
          onClick={onOpenPayment}>
          your wallet
        </Button>
      )}
    </Text>
  );
};
