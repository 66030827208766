import React, { FC, ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';

import { GroupsEmpty } from '../../ui-state/GroupsEmpty';

interface AllGroupBodyProps {
  isEmpty: boolean;
  children: ReactElement;
}

export const AllGroupBody: FC<AllGroupBodyProps> = ({ isEmpty, children }) => {
  return isEmpty ? (
    <Flex direction="column" height="100%" justifyContent="center">
      <GroupsEmpty />
    </Flex>
  ) : (
    children
  );
};
