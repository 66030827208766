import React, { memo, useCallback, useEffect, useState } from 'react';
import { Portal } from '@chakra-ui/react';

import {
  useFindActiveMarketingPopupsQuery,
  useMarkAccountPopupViewedMutation,
} from '@app/api/gql/generated-types';
import { GenericModal } from '@app/components/Modals/GenericModal';

const MarketingPopupViewerBase = () => {
  const { data: { findActiveMarketingPopups: marketingPopups = [] } = {} } =
    useFindActiveMarketingPopupsQuery({
      fetchPolicy: 'no-cache',
    });

  const [mutation] = useMarkAccountPopupViewedMutation();

  const [closedPopupIds, setClosedPopupIds] = useState<string[]>([]);
  const [activePopupId, setActivePopupId] = useState<string | null>(null);

  const filteredMarketingPopups = marketingPopups.filter(
    (popup) => !closedPopupIds.includes(popup.id),
  );

  const handleClose = useCallback(
    async (popupId: string) => {
      setClosedPopupIds((ids) => [...ids, popupId]);
      await mutation({
        variables: {
          input: {
            popupId,
          },
        },
      });
    },
    [mutation],
  );

  useEffect(() => {
    if (filteredMarketingPopups.length) {
      setActivePopupId(filteredMarketingPopups[0].id);

      return;
    }

    setActivePopupId(null);
  }, [filteredMarketingPopups]);

  return (
    <Portal>
      {filteredMarketingPopups.map((marketingPopup) => (
        <GenericModal
          key={marketingPopup.id}
          buttonLink={marketingPopup.template?.buttonLink}
          buttonText={marketingPopup.template?.buttonText}
          description={marketingPopup.description}
          id={marketingPopup.id}
          image={marketingPopup.template?.imageSrc}
          isOpen={activePopupId === marketingPopup.id}
          title={marketingPopup.title}
          video={marketingPopup.template?.videoSrc}
          onClose={handleClose}
        />
      ))}
    </Portal>
  );
};

export const MarketingPopupViewer = memo(MarketingPopupViewerBase);
