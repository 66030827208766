import React, { FC, useCallback, Dispatch, SetStateAction } from 'react';
import TableList from '@app/components/ContactTableList';
import { TableHeader } from '@app/api/queries/types';
import { getContactListHeader } from '@app/utils/getHeader';
import {
  Field,
  SkiptraceField,
  useUpdateListMutation,
} from '@app/api/gql/generated-types';
import { clearHeaders } from '@app/pages/Contacts/utils/prepareHeaders';
import { catchErrorLog } from '@app/utils/logger';
import { Header } from '@app/types/Contact';

interface TableListDataProps {
  data: Map<string, string[]>;
  headers: TableHeader | Header[];
}

interface TableSectionProps {
  contactListId: string;
  headerOptions: SkiptraceField[][];
  headerOptionsMap?: Record<string, Field>;
  headers: string[];
  loading: boolean;
  setHeaders: Dispatch<SetStateAction<TableHeader>>;
  tableData: TableListDataProps;
  skiptracingStarted: boolean;
  isSkiptracing: boolean;
}

const TableSection: FC<TableSectionProps> = ({
  contactListId,
  headerOptions,
  headerOptionsMap,
  headers,
  loading,
  tableData,
  setHeaders,
  skiptracingStarted,
  isSkiptracing,
}) => {
  const [mutateUpdateList] = useUpdateListMutation();

  const onSaveHeaders = useCallback(
    async (headersArray?: string[]) => {
      try {
        const headersData = headersArray?.length ? headersArray : headers;
        const preparedData = clearHeaders(headersData);

        await mutateUpdateList({
          variables: {
            id: contactListId,
            input: {
              fields: preparedData,
            },
          },
        });
      } catch (error) {
        catchErrorLog(error, 'Skiptrace/onSaveHeaders');
      }
    },
    [contactListId, headers, mutateUpdateList],
  );

  const handleHeaderChanged = useCallback(
    async (val: string, index: number) => {
      const headerArr = getContactListHeader(
        headers,
        headerOptionsMap,
        val,
        index,
      );

      setHeaders(headerArr);

      await onSaveHeaders(headerArr);
    },
    [headers, headerOptionsMap, onSaveHeaders, setHeaders],
  );

  return (
    <TableList
      contactListId={contactListId}
      editable={!skiptracingStarted}
      headerOptions={headerOptions}
      headerOptionsMap={headerOptionsMap}
      isSkiptracing={isSkiptracing}
      loading={loading}
      setHeader={handleHeaderChanged}
      tableData={tableData}
    />
  );
};

export default TableSection;
