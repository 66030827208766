import { Td, useDisclosure } from '@chakra-ui/react';
import React, { FC, MouseEvent, useCallback } from 'react';

import IconButtonRemove from '@app/components/IconButtonRemove';

import { RemoveContactListModal } from '../Modal/RemoveContactList';

interface TableListRowProps {
  id: string;
  onSelectedLists: (items: string[]) => void;
}

const TableListRow: FC<TableListRowProps> = ({ id, onSelectedLists }) => {
  const deleteContactList = useDisclosure();

  const onRemoveContactList = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      deleteContactList.onOpen();
    },
    [deleteContactList],
  );
  return (
    <Td>
      <IconButtonRemove onClick={onRemoveContactList} />

      <RemoveContactListModal
        id={id}
        isOpen={deleteContactList.isOpen}
        onClose={deleteContactList.onClose}
        onSelectedLists={onSelectedLists}
      />
    </Td>
  );
};

export default TableListRow;
