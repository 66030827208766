import {
  Box,
  Collapse,
  Table,
  TableCaption,
  Tbody,
  Text,
} from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import isNil from 'lodash/isNil';
import React, { FC, useMemo } from 'react';

import { OwnPhoneType, Scope } from '@app/api/gql/generated-types';
import { showHubSpotConversationAgent } from '@app/utils/hubSpotConversationUtils';
import { BuyNumberForm, PhoneItem } from '../types';
import { useMaxAllowedPhones } from '../useMaxAllowedPhones';

import { Row } from './Row';
import { TableHead } from './TableHead';

interface PhonesTableProps {
  data: PhoneItem[];
  onSubmit: (item: PhoneItem, type: OwnPhoneType) => void;
  isLoading: boolean;
  isDisabled?: boolean;
  price: number;
  scope: Scope;
}

export const PhonesTable: FC<PhonesTableProps> = ({
  data,
  onSubmit,
  isLoading,
  price,
  isDisabled = false,
  scope,
}) => {
  const {
    values: { type },
  } = useFormikContext<BuyNumberForm>();
  const { byType } = useMaxAllowedPhones(scope);

  const disabled = useMemo(
    () => byType(type) || isDisabled,
    [byType, isDisabled, type],
  );

  return (
    <Collapse animateOpacity in={!isNil(data)}>
      <Box
        border="1px"
        borderColor="mystic"
        borderRadius="9px"
        h="335px"
        mt="30px"
        overflow="hidden"
        overflowY="auto"
        position="relative">
        <Table size="medium" variant="phones">
          <TableHead />

          <Tbody w="100%">
            {data?.map((value) => (
              <Row
                key={value.id}
                id={value.id}
                isDisabled={isLoading || disabled}
                isLocalType={type === OwnPhoneType.LOCAL}
                phone={value.phone}
                price={price}
                scope={scope}
                status={value.status}
                onBuyNumber={() => onSubmit(value, type)}
              />
            ))}
          </Tbody>
          {!data?.length && (
            <TableCaption mt="120px">
              <Text
                color="osloGray"
                px={scope === Scope.DIALER ? '60px' : undefined}
                variant="secondary-text">
                No phone numbers with this area code available.{' '}
                <Box
                  as="span"
                  color="primary.600"
                  style={{ cursor: 'pointer' }}
                  onClick={showHubSpotConversationAgent}>
                  Order now
                </Box>
              </Text>
            </TableCaption>
          )}
        </Table>
      </Box>
    </Collapse>
  );
};
