import React, { FC, ReactNode, useCallback } from 'react';
import { Button, Flex, UseDisclosureProps } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { isString } from 'lodash';
import Popup from '@app/components/Popup';

/******************
 *
 * it will control the access modal of any feature. you should customize it with the children you want.
 *
 */

export interface CustomModalProps extends Partial<UseDisclosureProps> {
  title: string;
  primaryAction?: string | (() => void);
  primaryButtonText?: string;
  secondaryButtonText?: string;
  showSecondayAction?: boolean;
  children?: ReactNode;
}

export const CustomModal: FC<CustomModalProps> = ({
  isOpen,
  onClose,
  title,
  primaryAction = onClose,
  primaryButtonText = 'Confirm',
  secondaryButtonText = 'Cancel',
  showSecondayAction = false,
  children,
}) => {
  const navigate = useNavigate();

  const handlePrimaryAction = useCallback(() => {
    if (isString(primaryAction)) {
      navigate(primaryAction);
    } else {
      primaryAction();
    }
    onClose();
  }, [primaryAction, navigate, onClose]);

  const handleSecondaryAction = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="400px"
      title={title}
      width="400px"
      onClose={onClose}>
      <Flex
        alignItems="center"
        flexDirection="column"
        fontSize="14px"
        fontWeight="400"
        m="25px 40px 40px 40px">
        {children}
        <Button
          fontWeight="500"
          maxW="190px"
          minW={showSecondayAction ? 'none' : '190px'}
          variant="primary"
          onClick={handlePrimaryAction}>
          {primaryButtonText}
        </Button>
        {showSecondayAction && (
          <Button maxW="190px" variant="cancel" onClick={handleSecondaryAction}>
            {secondaryButtonText}
          </Button>
        )}
      </Flex>
    </Popup>
  );
};
