/* eslint no-restricted-syntax:0 */
import React, {
  createContext,
  FC,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMatch } from 'react-router-dom';
import ROUTES from '@app/utils/routes';
import { getLogger } from '@app/utils/logger';

export interface CurrentDialerContextValues {
  isDialerDisabled: boolean;
  isPowerDialerDisabled: boolean;
  isDialerDisabledRef: MutableRefObject<boolean>;
  isPowerDialerDisabledRef: MutableRefObject<boolean>;
  powerDialerDisabledCallbackRef: MutableRefObject<() => void>;
}

const CurrentDialerContext = createContext<
  CurrentDialerContextValues | undefined
>(undefined);

const LOG = getLogger('Dialer/CurrentDialer');

export const CurrentDialerProvider: FC<PropsWithChildren> = ({
  children,
}): ReactElement => {
  const powerDialerMatch = useMatch(`${ROUTES.powerDialer}/*`);
  const isDialerDisabledRef = useRef(true);
  const isPowerDialerDisabledRef = useRef(true);
  const [isDialerDisabled, setIsDialerDisabled] = useState(true);
  const [isPowerDialerDisabled, setIsPowerDialerDisabled] = useState(true);
  const powerDialerDisabledCallbackRef = useRef<() => void>(null);

  useEffect(() => {
    const isPowerDialer = !!powerDialerMatch;

    isDialerDisabledRef.current = isPowerDialer;
    isPowerDialerDisabledRef.current = !isPowerDialer;

    LOG.debug({
      isDialerDisabled: isDialerDisabledRef.current,
      isPowerDialerDisabled: isPowerDialerDisabledRef.current,
    });

    setIsDialerDisabled(isPowerDialer);
    setIsPowerDialerDisabled(!isPowerDialer);

    const intercom = Array.from(
      document.getElementsByClassName('intercom-lightweight-app-launcher'),
    );
    const intercomLauncher = intercom[0] as HTMLElement;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const hubspot = document.querySelector(
      '#hubspot-messages-iframe-container',
    ) as HTMLElement | undefined;

    if (isPowerDialer) {
      // Dialer bar is 70px

      if (intercomLauncher) {
        intercomLauncher.style.setProperty('bottom', '90px', 'important');
      }
      if (hubspot) {
        hubspot.style.setProperty('bottom', '70px', 'important');
      }
    } else {
      LOG.debug(`Calling power dialer disabled callback`);
      powerDialerDisabledCallbackRef.current?.();

      if (intercomLauncher) {
        intercomLauncher.style.setProperty('bottom', '20px', 'important');
      }
      if (hubspot) {
        hubspot.style.removeProperty('bottom');
      }
    }
  }, [powerDialerMatch]);

  const providerValue = useMemo(
    () => ({
      isDialerDisabled,
      isPowerDialerDisabled,
      isDialerDisabledRef,
      isPowerDialerDisabledRef,
      powerDialerDisabledCallbackRef,
    }),
    [
      isDialerDisabled,
      isPowerDialerDisabled,
      isDialerDisabledRef,
      isPowerDialerDisabledRef,
      powerDialerDisabledCallbackRef,
    ],
  );

  return (
    <CurrentDialerContext.Provider value={providerValue}>
      {children}
    </CurrentDialerContext.Provider>
  );
};

export const useCurrentDialerContext = () => {
  return useContext(CurrentDialerContext);
};
