import {
  Box,
  Button,
  Center,
  Flex,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Popup from '@app/components/Popup';
import ROUTES from '@app/utils/routes';
import WarningTriangleIcon from '@app/icons/common/warning-triangle-icon.svg?react';
import { useUpdateAccountMutation } from '@app/api/gql/generated-types';
import { AccountSettingsPopup } from '@app/api/gql/graphql';

const ResetPasswordPopup: FC<UseDisclosureProps> = ({ onClose, isOpen }) => {
  const navigate = useNavigate();

  const [updateAccount] = useUpdateAccountMutation();
  const goToPage = useCallback(async () => {
    await updateAccount({
      variables: {
        data: {
          viewedPopup: AccountSettingsPopup.passwordResetPopup,
        },
      },
    });
    onClose();
    navigate(ROUTES.settingsProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  return (
    <Popup
      hideCloseIcon
      isOpen={isOpen}
      maxW="526px"
      title="Password update required"
      titlePadding="45px 56px 25px"
      onClose={onClose}>
      <Box p="0 40px">
        <Text color="main.400" fontSize="14px" textAlign="center">
          We&apos;ve implemented security upgrades to protect your account.
        </Text>
        <Text color="main.400" fontSize="14px" mb="18px" textAlign="center">
          To activate these new measures, you must update your password.
        </Text>

        <Flex
          bg="paleGamboge"
          borderRadius="10px"
          color="main.400"
          fontSize="14px">
          <Box
            color="deepLemon"
            display="inline-block"
            ml="8px"
            mr="10px"
            mt="8px"
            width="14px">
            <WarningTriangleIcon />
          </Box>
          <Text
            color="main.400"
            fontSize="14px"
            lineHeight="18px"
            py="8px"
            textAlign="left">
            If you don&apos;t update your password voluntarily, you will be
            required to do so within the next few days on your next login. Take
            action now to ensure uninterrupted access to your account.
          </Text>
        </Flex>
        <Text
          color="main.400"
          fontSize="14px"
          mb="24px"
          mt="20px"
          textAlign="center">
          Updating your password is quick and easy. It will significantly
          enhance your account&apos;s security and allow you to benefit from our
          latest protection features.
        </Text>
        <Center>
          <Button mb="40px" variant="primary" w="220px" onClick={goToPage}>
            Update password now
          </Button>
        </Center>
      </Box>
    </Popup>
  );
};

export default ResetPasswordPopup;
