import React, { FC, useCallback } from 'react';
import { Box, Button, Center, UseDisclosureProps } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import ROUTES from '@app/utils/routes';

import Popup from '../Popup';

/**
 * @deprecated Use the new accesbilityFeatures instead or should be migrated to permissions.
 */
const ActivateDialerSubscriptionModal: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const navigate = useNavigate();

  const goToPage = useCallback(() => {
    onClose();
    navigate(`${ROUTES.settingsMembership}`);
  }, [navigate, onClose]);

  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="340px"
      title="Activate your subscription"
      width="340px"
      onClose={onClose}>
      <Box m="21px 30px 25px 30px">
        <Center fontSize="14px" textAlign="center">
          To gain access to this feature you must
        </Center>
        <Center fontSize="14px" mb="25px" textAlign="center">
          first activate a Calling plan subscription.
        </Center>
        <Button mr="20px" variant="primary" w="100%" onClick={goToPage}>
          See Calling plans
        </Button>
        <Button height="40px" variant="cancel" w="100%" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Popup>
  );
};

export default ActivateDialerSubscriptionModal;
