import React, { FC } from 'react';
import { ConversationCategory } from '@app/api/gql/generated-types';

import { InboxMessages } from '../components/InboxMessages';
import { MessengerFolderType } from '../types';

const InboxOptedOut: FC = () => {
  return (
    <InboxMessages
      path="inbox/opted-out"
      queryParams={{
        category: ConversationCategory.OPTED_OUT,
      }}
      title="Opted Out"
      type={MessengerFolderType.OPTED_OUT}
    />
  );
};

export default InboxOptedOut;
