/* eslint-disable react/jsx-key */
import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';

import { UseTableRowProps } from 'react-table';
import { colors } from '@app/theme/colors';
import { BaseTableProps } from './types';
import { getStickyProps } from './utils';
import SkeletonRow from './Rows/SkeletonRow';

// eslint-disable-next-line @typescript-eslint/ban-types
export const BaseTable = <T extends object = {}>({
  getCellBg,
  getTableBodyProps,
  tableHeadProps,
  getTableProps,
  getTableRowProps,
  tableHeadBackground = undefined,
  headerGroups,
  prepareRow,
  rows,
  isLoadingNextPage,
  tableRef,
}: BaseTableProps<T>) => {
  const getCellHoverBg = (row: UseTableRowProps<T>) => {
    const { isHoverRow, isSettingsRow } = getTableRowProps(row);
    const bg = isHoverRow || isSettingsRow ? 'zircon' : 'none';
    const borderLeftColor = isHoverRow
      ? colors.switchedBlue[500]
      : 'catskillWhite';
    return { bg, borderLeftColor };
  };

  return (
    <Table {...getTableProps()} ref={tableRef}>
      <Thead
        backgroundColor="white"
        borderTop="solid 2px aliceBlue"
        {...tableHeadProps}>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()} height="45px">
            {headerGroup.headers.map((column) => (
              <Th
                bg={tableHeadBackground}
                color="secondary.400"
                fontSize="14px"
                fontWeight={400}
                letterSpacing="normal"
                textTransform="none"
                {...getStickyProps<T>(column)}
                {...column.getHeaderProps()}>
                {column.render('Header')}
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const { backgroundColor } = getTableRowProps(row);
          return (
            <Tr
              {...row.getRowProps()}
              {...(backgroundColor && { backgroundColor })}
              _hover={getCellHoverBg(row)}
              borderBottom="1px"
              borderBottomColor="catskillWhite"
              textAlign="center">
              {row.cells.map((cell) => {
                return (
                  <Td
                    color="main.400"
                    fontSize="14px"
                    fontWeight={400}
                    h="50px"
                    {...getStickyProps<T>(cell.column)}
                    {...cell.getCellProps()}
                    bgColor={getCellBg(cell)}>
                    {cell.render('Cell')}
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
      {isLoadingNextPage && (
        <SkeletonRow
          columns={headerGroups[0].headers.length}
          isFetching={isLoadingNextPage}
          rows={3}
        />
      )}
    </Table>
  );
};
