import { useMemo, useState } from 'react';
import chunk from 'lodash/chunk';

import { Field, useFindFieldsQuery } from '@app/api/gql/generated-types';
import { TableHeader } from '@app/api/queries/types';

export const useHeaders = () => {
  const [headers, setHeaders] = useState<TableHeader>([]);

  const { data: { findFields: fieldsData } = {}, loading } =
    useFindFieldsQuery();

  const customHeaders = useMemo(
    () => fieldsData?.filter((header) => !header.predefined) ?? [],
    [fieldsData],
  );
  const predefinedHeaders = useMemo(
    () => fieldsData?.filter((header) => !!header.predefined) ?? [],
    [fieldsData],
  );

  const isCustomHeadersExists = useMemo(
    () => !!customHeaders?.length,
    [customHeaders?.length],
  );
  const headersCount = useMemo(
    () => fieldsData?.length ?? 0,
    [fieldsData?.length],
  );

  const headersMap = useMemo(
    () =>
      fieldsData?.reduce(
        (obj, field) => ({
          ...obj,
          [field.id]: {
            ...field,
          },
        }),
        {},
      ) ?? {},
    [fieldsData],
  ) as Record<string, Field>;

  const predefinedHeadersMap = useMemo(
    () =>
      predefinedHeaders?.reduce(
        (obj, field) => ({
          ...obj,
          [field.name]: {
            ...field,
          },
        }),
        {},
      ),
    [predefinedHeaders],
  ) as Record<string, Field>;

  const chunkSize = useMemo(() => {
    if (isCustomHeadersExists) {
      return Math.floor(headersCount / 4) + (headersCount % 4 > 0 ? 1 : 0);
    }
    return 4;
  }, [isCustomHeadersExists, headersCount]);

  const headerOptions = useMemo(
    () => chunk(fieldsData, chunkSize),
    [chunkSize, fieldsData],
  );

  return {
    loading,
    headersMap,
    headerOptions,
    headers,
    predefinedHeadersMap,
    setHeaders,
  };
};
