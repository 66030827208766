import { Box, Button } from '@chakra-ui/react';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { createPaginationVariables } from '@app/api/apollo-pagination';
import { useFindAlertGroupsQuery } from '@app/api/gql/generated-types';
import Popup from '@app/components/Popup';
import SelectField from '@app/components/SelectField';

interface AddToGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  isLoading: boolean;
  onAddContactToGroup: (id: string) => void;
}

export const AddToGroupModal: FC<AddToGroupModalProps> = ({
  isOpen,
  onClose,
  title,
  isLoading,
  onAddContactToGroup,
}) => {
  const [groupId, setGroupId] = useState<{ value: string }>();

  const {
    data: { findGroups: groupsData } = {},
    loading: isLoadingGroups,
    fetchMore,
  } = useFindAlertGroupsQuery({
    variables: {
      filter: {
        verified: true,
      },
    },
  });

  const groupOptions = useMemo(
    () =>
      groupsData?.items.map((group) => ({
        value: group.id,
        label: group.name,
      })),
    [groupsData],
  );

  const fetchNextPage = () => fetchMore(createPaginationVariables(groupsData));

  const handleContactsGroupAdd = useCallback(() => {
    onAddContactToGroup(groupId.value);
  }, [onAddContactToGroup, groupId]);

  return (
    <Popup
      closeOnOverlayClick
      isOpen={isOpen}
      maxW="380px"
      size="lg"
      title={title}
      width="380px"
      onClose={onClose}>
      <Box margin="35px 40px">
        <SelectField
          isSearchable
          isLoading={isLoadingGroups}
          menuHeight={300}
          options={groupOptions}
          placeholder="Select group"
          onChange={setGroupId}
          onMenuScrollToBottom={() => fetchNextPage()}
        />
        <Button
          isLoading={isLoading}
          loadingText="Add to group"
          mt="20px"
          variant="primary"
          width="100%"
          onClick={handleContactsGroupAdd}>
          Add to group
        </Button>
      </Box>
    </Popup>
  );
};
