import React, { FC, Fragment, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import ROUTES from '@app/utils/routes';
import { useAccountIsAdmin } from '@app/hooks/useAccountIsAdmin';

export const AdminRouteGuard: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const isAdmin = useAccountIsAdmin();
  const location = useLocation();

  if (isAdmin) {
    return <Fragment key={location.key}>{children}</Fragment>;
  } else {
    return (
      <Navigate replace state={{ from: location }} to={ROUTES.messenger} />
    );
  }
};
