import { Box, HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { OwnPhone, PhoneNumberLocation } from '@app/api/gql/generated-types';
import PhoneNumberFormat from '@app/shared/ui/PhoneNumberFormat';

interface SelectLabelProps extends Partial<OwnPhone> {
  label: string;
  location: PhoneNumberLocation;
}

const SelectLabel: FC<SelectLabelProps> = ({ label, location }) => {
  return (
    <HStack alignItems="center" gap="5px">
      <Text fontSize="14px" lineHeight="14px" variant="secondary-text">
        {location && location?.abbreviation}
      </Text>
      <Box whiteSpace="nowrap">
        <PhoneNumberFormat value={label} />
      </Box>
    </HStack>
  );
};

export default SelectLabel;
