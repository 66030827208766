import React, { FC, useCallback } from 'react';
import { DateTime as DT } from 'luxon';

import { useDisabledDates } from '../hooks/useDisabledDates';

import { MONTH, getDifferenceByMode } from './utils/utils';
import BodyWrap from './body/BodyWrap';
import HeaderWrap from './header/HeaderWrap';
import CurrentPeriod from './navigation/CurrentPeriod';
import NextPeriod from './navigation/NextPeriod';
import PrevPeriod from './navigation/PrevPeriod';
import { DatePickerWrapStyle, NavigationWrapStyle } from './styles';

interface DatePickerTemplateTypes {
  date: DT;
  onSelect: (value: DT) => void;
  selectedDate: DT;
  disabledStartDate?: DT;
  disabledEndDate?: DT;
}

export const DatePicker: FC<DatePickerTemplateTypes> = ({
  date,
  onSelect,
  selectedDate,
  disabledStartDate,
  disabledEndDate,
}) => {
  const { isDisabledStartDate, isDisabledEndDate } = useDisabledDates();
  const today = DT.fromJSDate(new Date());
  const onClickPrev = useCallback(() => {
    let day = date.minus(getDifferenceByMode(MONTH));
    if (isDisabledStartDate(day, disabledStartDate)) day = today;
    if (isDisabledEndDate(day, disabledEndDate)) day = disabledEndDate;
    onSelect(day);
  }, [
    date,
    disabledEndDate,
    disabledStartDate,
    isDisabledEndDate,
    isDisabledStartDate,
    onSelect,
    today,
  ]);

  const onClickNext = useCallback(() => {
    let day = date.plus(getDifferenceByMode(MONTH));
    if (isDisabledStartDate(day, disabledStartDate)) day = today;
    if (isDisabledEndDate(day, disabledEndDate)) day = disabledEndDate;
    onSelect(day);
  }, [
    date,
    disabledEndDate,
    disabledStartDate,
    isDisabledEndDate,
    isDisabledStartDate,
    onSelect,
    today,
  ]);

  const onChange = (day: DT) => {
    if (
      isDisabledStartDate(day, disabledStartDate) ||
      isDisabledEndDate(day, disabledEndDate)
    )
      return;
    onSelect(day);
    if (onSelect) onSelect(day);
  };

  return (
    <DatePickerWrapStyle>
      <NavigationWrapStyle>
        <PrevPeriod onClick={onClickPrev} />
        <CurrentPeriod cursorDate={date} mode={MONTH} />
        <NextPeriod onClick={onClickNext} />
      </NavigationWrapStyle>
      <HeaderWrap mode={MONTH} />
      <BodyWrap
        cursorDate={date}
        disabledEndDate={disabledEndDate}
        disabledStartDate={disabledStartDate}
        mode={MONTH}
        selectedDate={selectedDate}
        onSelect={onChange}
      />
    </DatePickerWrapStyle>
  );
};
