import React, { FC, useEffect, useState, useCallback } from 'react';
import { Box, Button, Flex, Tooltip, useDisclosure } from '@chakra-ui/react';

import use10DlcAccountStatusGuard from '@app/hooks/use10DlcAccountStatusGuard';
import { useDlcStatus } from '@app/hooks/useDlcStatus';

import { useTollFreeStatus } from '@app/pages/Settings/content/BrandRegistration/hooks/useTollFreeStatus';
import { SearchNumbersModal } from '@app/components/SearchNumbersModal';
import { useMaxAllowedPhones } from '@app/components/SearchNumbersModal/useMaxAllowedPhones';
import useActivateMessageSubscriptionGuard from '@app/hooks/useActivateMessageSubscriptionGuard';
import { useCurrentAccountData } from '@app/hooks/useCurrentAccountData';
import { ChargeModal } from '@app/components/ChargeModal';
import { AutoRechargeModal } from '@app/pages/Settings/content/Membership/components/modals/AutoRecharge';
import { Scope } from '@app/api/gql/generated-types';

interface IBuyNumberProps {
  actionTitle?: string;
  customAction?: () => void;
  hasEnoughPhoneNumbers: boolean;
  requiredUserPhonesNumbers: number;
  refetchUserPhones: () => void;
}

export const BuyNumber: FC<IBuyNumberProps> = ({
  actionTitle = 'Buy number',
  customAction,
  hasEnoughPhoneNumbers,
  requiredUserPhonesNumbers,
  refetchUserPhones,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isNoBrand } = useDlcStatus();
  const { all: allowBuyPhones } = useMaxAllowedPhones(Scope.MESSAGE);
  const { isSubmitted } = useTollFreeStatus();

  const guardDlcAction = use10DlcAccountStatusGuard(onOpen, isSubmitted);
  const guardAction = useActivateMessageSubscriptionGuard(guardDlcAction);
  const { phoneNumberPrice, balance, isSubAccount } = useCurrentAccountData();
  const [isRechargeModalOpen, setIsRechargeModalOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [shouldOpenSearchModal, setShouldOpenSearchModal] = useState(false);
  const [
    shouldDisableActionForSubAccount,
    setShouldDisableActionForSubAccount,
  ] = useState(false);
  const [message, setMessage] = useState(
    `Current balance: $${balance.toFixed(2)} \n \n To buy phone numbers, you need $${phoneNumberPrice} per number in your wallet.\n \n Please recharge.`,
  );
  const autoRechargeModal = useDisclosure();

  const handleInsufficientBalance = useCallback(() => {
    if (isSubAccount) {
      setMessage(
        `Current balance: $${balance.toFixed(2)} \n \n To buy phone numbers, you need $${phoneNumberPrice} per number in your wallet.\n \n Please contact your primary account.`,
      );
      setShouldDisableActionForSubAccount(true);
    }
    setIsRechargeModalOpen(true);
    setShouldOpenSearchModal(true);
  }, [isSubAccount, balance, phoneNumberPrice]);

  const openSearchModal = useCallback(() => {
    setIsSearchModalOpen(true);
    setShouldOpenSearchModal(false);
  }, []);

  const handleCloseRechargeModal = () => {
    setIsRechargeModalOpen(false);
  };
  const handleCloseSearchModal = () => {
    setIsSearchModalOpen(false);
    onClose();
  };
  const handleClick = async () => {
    await guardAction();
    if (!isOpen) return;

    if (balance < phoneNumberPrice) {
      handleInsufficientBalance();
    } else {
      openSearchModal();
    }
  };

  useEffect(() => {
    if (!isOpen) return;

    if (balance < phoneNumberPrice) {
      handleInsufficientBalance();
    } else {
      openSearchModal();
    }
  }, [
    balance,
    shouldOpenSearchModal,
    phoneNumberPrice,
    isOpen,
    isSubAccount,
    handleInsufficientBalance,
    openSearchModal,
  ]);

  return (
    <Flex alignItems="center">
      <Tooltip
        hasArrow
        isDisabled={isNoBrand || allowBuyPhones}
        label="Limit reached of phone numbers that can be purchased."
        maxW="230px"
        placement="top">
        <Box>
          <Button
            data-cy="buy-number-btn"
            isDisabled={!allowBuyPhones}
            variant="primary"
            w="190px"
            onClick={customAction || handleClick}>
            {actionTitle}
          </Button>
        </Box>
      </Tooltip>
      {isRechargeModalOpen && (
        <ChargeModal
          disableAction={shouldDisableActionForSubAccount}
          isOpen={true}
          isWarning={true}
          message={message}
          title="Recharge your wallet"
          onClose={handleCloseRechargeModal}
          onEnableAutoRechargeClick={() => {
            autoRechargeModal.onOpen();
          }}
        />
      )}
      <AutoRechargeModal
        isOpen={autoRechargeModal.isOpen}
        onClose={autoRechargeModal.onClose}
      />
      {isSearchModalOpen && (
        <SearchNumbersModal
          hasEnoughPhoneNumbers={hasEnoughPhoneNumbers}
          isOpen={true}
          refetchUserPhones={refetchUserPhones}
          requiredUserPhonesNumbers={requiredUserPhonesNumbers}
          onClose={handleCloseSearchModal}
        />
      )}
    </Flex>
  );
};
