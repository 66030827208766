import { useApolloClient } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import * as typenames from '@app/api/typenames';
import {
  ContactFragment,
  ContactFragmentDoc,
  ConversationFragment,
  ConversationFragmentDoc,
  Label,
} from '../gql/generated-types';

interface UpdateContactFragmentProps {
  contactId: string;
  labels?: Label[];
  favorite?: boolean;
}

export const useUpdateContactFragment = () => {
  const client = useApolloClient();
  const updateContactFragment = (values: UpdateContactFragmentProps) => {
    client.cache.updateFragment<ContactFragment>(
      {
        id: `${typenames.Contact}:${values.contactId}`,
        fragment: ContactFragmentDoc,
        fragmentName: typenames.Contact,
      },
      (data) => ({
        ...data,
        ...(values.labels && { labels: values.labels }),
        ...(!isEmpty(values.favorite) && { favorite: values.favorite }),
      }),
    );
    client.cache.updateFragment<ConversationFragment>(
      {
        id: `${typenames.Conversation}:${values.contactId}`,
        fragment: ConversationFragmentDoc,
        fragmentName: typenames.Conversation,
      },
      (data) => ({
        ...data,
        ...(values.labels && { labels: values.labels }),
        ...(!isEmpty(values.favorite) && { isFavorite: values.favorite }),
      }),
    );
  };
  return {
    updateContactFragment,
  };
};
