import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';

import { PageTitle } from '@app/components/PageTitle';
import ROUTES from '@app/utils/routes';

import PopupsTable from '../components/PopupsTable';

const PopupList = () => {
  return (
    <Box height="100%" w="100%">
      <PageTitle title="Popups List" />
      <Flex
        backgroundColor="white"
        justifyContent="space-between"
        p="30px"
        w="100%">
        <Button
          as={RouteLink}
          data-id="create-new-popup-button"
          height="37px"
          px="20px"
          py="12px"
          to={`${ROUTES.popupConstructorCreate}`}
          variant="primary">
          Create New Popup
        </Button>
      </Flex>
      <PopupsTable />
    </Box>
  );
};

export default PopupList;
