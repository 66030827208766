import React, { FC } from 'react';
import { Button, Flex } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';

import LabelView from './LabelView';

const SHOW_CLEAR_ALL_AFTER_LABEL = 2;

interface LabelListProps {
  labels: string[];
  isMonochrome?: boolean;
  editable?: boolean;
  visibleItemsCount?: number;
  contactId?: string;
  onChange?: (labels: string[]) => void;
}

export const LabelFilterList: FC<LabelListProps> = ({
  labels,
  onChange,
  isMonochrome = false,
  editable = false,
}) => {
  const deleteHandler = (id: string) =>
    onChange?.(labels.filter((label) => label !== id));
  const clearHandler = () => onChange?.([]);
  const showClearAll =
    !isEmpty(labels) && labels.length > SHOW_CLEAR_ALL_AFTER_LABEL && onChange;

  return (
    <Flex alignItems="center" flexWrap="wrap" w="100%">
      {labels.map((label) => (
        <LabelView
          key={label}
          editable={editable}
          id={label}
          isMonochrome={isMonochrome}
          onDelete={onChange && deleteHandler}
        />
      ))}
      {showClearAll && (
        <Button ml="15px" variant="text" w="auto" onClick={clearHandler}>
          Clear all
        </Button>
      )}
    </Flex>
  );
};
