import React from 'react';
import { components, GroupBase, OptionProps } from 'react-select';
import { Box } from '@chakra-ui/react';
import { OwnPhoneFragment } from '@app/api/gql/generated-types';
import SelectLabel from './SelectLabel';

const Option = <
  T extends OwnPhoneFragment,
  M extends boolean = false,
  G extends GroupBase<T> = GroupBase<T>,
>(
  props: OptionProps<T, M, G>,
) => {
  return (
    <components.Option {...props}>
      <Box px="3px">
        <SelectLabel label={props.label} location={props.data?.location} />
      </Box>
    </components.Option>
  );
};

export default Option;
