import { ApolloError, ServerError } from '@apollo/client';
import { GraphQLError, GraphQLFormattedError } from 'graphql';
import zipObjectDeep from 'lodash/zipObjectDeep';
import { AuthError, AuthErrorCodes } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { ExceptionCode } from '@app/types/ApolloErrors';

type GraphQLErrors = GraphQLError | GraphQLFormattedError;

interface ValidationError {
  property?: string;
  constraints?: { [key: string]: string };
  children?: ValidationError[];
}

interface GraphQLValidationError extends ServerError {
  result: {
    errors?: Array<Record<string, any>>;
  };
}

export const apolloErrorsMapHelper = (
  errors: readonly GraphQLErrors[],
  errorsMap: Record<string, string>,
): Record<string, string> => {
  return errors.reduce((result, error) => {
    const code = error?.extensions?.code as string;
    const errorPath = errorsMap?.[code];

    return {
      ...result,
      ...(errorsMap?.[code] && zipObjectDeep([errorPath], [error?.message])),
    };
  }, {}) as Record<string, string>;
};

const mapValidationError = (validationError: ValidationError) => {
  if (validationError.constraints) {
    return [
      [
        validationError?.property,
        Object.values(validationError?.constraints)[0],
      ],
    ];
  }
  if (
    Array.isArray(validationError.children) &&
    validationError.children.length
  ) {
    return validationError?.children.map((child) => [
      child?.property,
      Object.values(child?.constraints)[0],
    ]);
  }
  return [];
};

// 10Dlc and TollFree validation
export const extractValidationErrors = (
  errors: readonly GraphQLErrors[],
): {
  [k: string]: any;
} => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return Object.fromEntries(
    errors
      .map((err) =>
        (
          err?.extensions?.exception as { validationErrors?: ValidationError[] }
        )?.validationErrors?.map(mapValidationError),
      )
      .flat(2),
  );
};

/**
 * Utility function to extract the error message from a GraphQL error object.
 * @param {ApolloError[]} error - The ApolloError object representing the GraphQL error.
 * @returns {string | undefined} The error message extracted from the GraphQL error,
 * or undefined if no message is found.
 */
export const getGraphQLErrorMessage = (error: ApolloError[]) => {
  return error[0].message;
};

export const getApolloGraphQLErrorMessage = (
  error: ApolloError['graphQLErrors'],
) => error[0].message;

export const getApolloGraphQLErrorCode = (
  error: ApolloError['graphQLErrors'],
) => error[0].extensions.code;
export const extractAuthErrorMessage = (err: AuthError): string => {
  let message: string;

  switch (err.code) {
    case AuthErrorCodes.NEED_CONFIRMATION:
      message = 'Mobile number already exists in the system';
      break;
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      message = 'Too many attempts. Try again later';
      break;
    case AuthErrorCodes.INVALID_CODE:
      message = 'Wrong code. Try again';
      break;
    case AuthErrorCodes.EMAIL_EXISTS:
      message = 'The email address is already in use by another account';
      break;
    case AuthErrorCodes.INVALID_EMAIL:
      message = 'The email address is not valid';
      break;
    case AuthErrorCodes.USER_DISABLED:
      message = 'The user account has been disabled by an administrator';
      break;
    case AuthErrorCodes.WEAK_PASSWORD:
      message = 'The password is too weak';
      break;
    case AuthErrorCodes.OPERATION_NOT_ALLOWED:
      message = 'This operation is not allowed';
      break;
    case AuthErrorCodes.INVALID_PASSWORD:
      message = 'The password is invalid';
      break;
    case AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE:
      message =
        'This credential is already associated with a different user account';
      break;
    case AuthErrorCodes.POPUP_BLOCKED:
      message = 'The popup was blocked by the browser';
      break;
    case AuthErrorCodes.POPUP_CLOSED_BY_USER:
      message =
        'The popup was closed by the user before finalizing the operation';
      break;
    case AuthErrorCodes.TIMEOUT:
      message = 'The operation has timed out';
      break;
    case AuthErrorCodes.NETWORK_REQUEST_FAILED:
      message = 'A network error has occurred';
      break;
    case AuthErrorCodes.INVALID_PHONE_NUMBER:
      message = 'The phone number is not valid';
      break;
    default:
      message = 'Oops something went wrong';
      break;
  }

  return message;
};

/**
 * Given a FirebaseError, return a user-friendly error message.
 * @param {FirebaseError} err
 * @returns {string}
 */
export const handleFirebaseError = (err: FirebaseError): string => {
  let message: string;

  switch (err.code) {
    case AuthErrorCodes.INVALID_OOB_CODE:
      message =
        'This verification link is no longer valid. Please request a new verification email.';
      break;

    default:
      message = 'Oops something went wrong';
      break;
  }

  return message;
};

export const portNumberErrorHandler = (
  error: ApolloError['graphQLErrors'] | GraphQLValidationError,
  errorMessage: string,
) => {
  if ('result' in error && error.result?.errors?.length > 0) {
    const graphQLErrors = error.result.errors as GraphQLFormattedError[];

    return graphQLErrors?.[0]?.extensions?.code ===
      ExceptionCode.GRAPHQL_VALIDATION_FAILED
      ? errorMessage
      : graphQLErrors?.[0]?.message;
  }

  if (Array.isArray(error) && error.length > 0) {
    return (error as ApolloError['graphQLErrors'])?.[0].message || errorMessage;
  }

  return errorMessage;
};

export const subaccountErrorHandler = (
  error: ApolloError['graphQLErrors'] | GraphQLValidationError,
  errorMessage: string,
): string => {
  // Type check for ServerError type with result.errors
  if ('result' in error && error.result?.errors?.length > 0) {
    const serverError = error.result.errors[0];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return serverError.message || errorMessage;
  }

  // Handle ApolloError.graphQLErrors
  if (Array.isArray(error) && error.length > 0) {
    return (error as ApolloError['graphQLErrors'])?.[0].message || errorMessage;
  }
  // Fallback for other error types
  return errorMessage;
};
