import { object, string, boolean, TestContext } from 'yup';

import {
  hasUppercaseChar,
  hasLowercaseChar,
  hasDigitChar,
} from '@app/utils/validation';

interface LocalTestContext extends TestContext {
  parent: Record<string, string>;
}

const testFunction = (value: unknown, { path, parent }: LocalTestContext) => {
  const { password } = parent;

  const validationMap: {
    [key: string]: () => boolean;
  } = {
    isLengthCheckPassed: () => password?.length >= 8,
    isUppercaseCheckPassed: () => hasUppercaseChar(password),
    isLowercaseCheckPassed: () => hasLowercaseChar(password),
    isDigitsCheckPassed: () => hasDigitChar(password),
  };

  if (password && validationMap[path]) {
    return validationMap[path]();
  }

  return false;
};

export const signUpSchema = object().shape({
  email: string().email('Please enter a valid email address').required(''),
  displayName: string().min(1).max(50).required(),
  isAgree: boolean().oneOf([true], 'You must agree with terms'),
  password: string()
    .test(
      'is-length-enough',
      'Must be at least 8 characters long',
      (value) => value?.length >= 8,
    )
    .test(
      'is-contains-uppercase',
      'Must contain at least one uppercase letter',
      (value) => hasUppercaseChar(value),
    )
    .test(
      'is-contains-lowercase',
      'Must contain at least one lowercase letter',
      (value) => hasLowercaseChar(value),
    )
    .test('is-contains-digits', 'Must contain at least one digit', (value) =>
      hasDigitChar(value),
    )
    .required(),
  isLengthCheckPassed: boolean().test(
    'is-length-enough',
    'Must be at least 8 characters long',
    testFunction,
  ),
  isUppercaseCheckPassed: boolean().test(
    'is-contains-uppercase',
    'Must contain at least one uppercase letter',

    testFunction,
  ),
  isLowercaseCheckPassed: boolean().test(
    'is-contains-lowercase',
    'Must contain at least one lowercase letter',
    testFunction,
  ),
  isDigitsCheckPassed: boolean().test(
    'is-contains-digits',
    'Must contain at least one digit',
    testFunction,
  ),
});
