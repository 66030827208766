import React, { FC } from 'react';
import { useFormikContext } from 'formik';
import { Box } from '@chakra-ui/react';

import { cloneDeep } from 'lodash';
import { audienceOptions } from '@app/pages/PopupConstructor/content/selectOptions';
import { OptionData } from '@app/components/SelectButtonsField/OptionData';
import MultiSelectField from '@app/components/MultiSelectField';
import { PopupConstructor } from '@app/pages/PopupConstructor/types';
import { MarketingPopupAudience } from '@app/api/gql/generated-types';

enum Action {
  SELECT = 'select-option',
  DESELECT = 'deselect-option',
  REMOVE = 'remove-value',
}

interface CurrentValueProp {
  action: Action;
  name: string;
  option: OptionData;
  removedValue?: OptionData;
}

export const AudienceSelect: FC<{
  isDisabled: boolean;
}> = ({ isDisabled }) => {
  const { values, setFieldValue } = useFormikContext<PopupConstructor>();

  const handleChange = async (
    newValue: readonly OptionData[],
    currentValue: CurrentValueProp,
  ) => {
    const value: OptionData[] = cloneDeep([...newValue]);
    if (
      currentValue.action === Action.SELECT &&
      (currentValue.option.value as MarketingPopupAudience) ===
        MarketingPopupAudience.ALL
    ) {
      await setFieldValue('audiences', audienceOptions);
      return;
    }

    if (
      currentValue.action === Action.DESELECT &&
      (currentValue.option.value as MarketingPopupAudience) ===
        MarketingPopupAudience.ALL
    ) {
      await setFieldValue('audiences', []);
      return;
    }

    if (
      currentValue.action === Action.REMOVE &&
      (currentValue.removedValue.value as MarketingPopupAudience) ===
        MarketingPopupAudience.ALL
    ) {
      await setFieldValue('audiences', []);

      return;
    }

    if (
      currentValue.action === Action.DESELECT ||
      currentValue.action === Action.REMOVE
    ) {
      const notAll = value.filter(
        (i) =>
          (i.value as MarketingPopupAudience) !== MarketingPopupAudience.ALL,
      );
      await setFieldValue('audiences', notAll);

      return;
    }

    if (value.length === audienceOptions.length - 1) {
      await setFieldValue('audiences', audienceOptions);
      return;
    }

    await setFieldValue('audiences', value);
  };

  return (
    <Box width="345px">
      <MultiSelectField
        isMulti
        autoFocus={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        isDisabled={isDisabled}
        isSearchable={false}
        itemsLength={
          values.audiences.some(
            (audience) =>
              (audience.value as MarketingPopupAudience) ===
              MarketingPopupAudience.ALL,
          )
            ? 1
            : 3
        }
        name="audiences"
        options={audienceOptions}
        placeholder="Select audience"
        value={values.audiences}
        onChange={handleChange}
      />
    </Box>
  );
};
