import React, { FC, useMemo } from 'react';
import {
  Button,
  Flex,
  Box,
  Text,
  Center,
  HStack,
  Tag,
  TagLabel,
  TagLeftIcon,
} from '@chakra-ui/react';

import { useAccountBalance } from '@app/hooks/useAccountBalance';
import { AddFundsMessage } from '@app/components/Payment/AddFundsMessage';
import { FormatNumber } from '@app/components/FormatNumber';
import DefaultCard from '@app/icons/cardTypes/DefaultCard.svg?react';
import Popup from '@app/components/Popup';

interface PaymentProps {
  isOpen: boolean;
  onClose: () => void;
  contacts: number;
  onConfirmLookup: () => void;
}

export const PaymentModal: FC<PaymentProps> = ({
  isOpen,
  onClose,
  contacts,
  onConfirmLookup,
}) => {
  const { balance, rawBalance } = useAccountBalance();
  const total = useMemo(() => contacts * 0.005, [contacts]);

  const notEnoughBalance = useMemo(
    () => total * 100 > rawBalance,
    [rawBalance, total],
  );

  const handleConfirm = () => {
    onClose();
    onConfirmLookup();
  };

  return (
    <Popup
      closeOnOverlayClick
      isOpen={isOpen}
      title="Payment"
      width="450px"
      onClose={onClose}>
      <Box margin="20px 40px">
        <Text
          borderBottom="1px dashed"
          borderBottomColor="secondary.400"
          color="main.400"
          fontSize="14px"
          padding="15px 0">
          Phones: <strong>{contacts}</strong>
        </Text>
        <Text
          borderBottom="1px dashed"
          borderBottomColor="secondary.400"
          color="main.400"
          fontSize="14px"
          padding="15px 0">
          Lookup: <strong>$.005</strong>
        </Text>
        <Text
          borderBottom="1px dashed"
          borderBottomColor="secondary.400"
          color="main.400"
          fontSize="14px"
          padding="15px 0">
          Total:{' '}
          <strong>
            $<FormatNumber fractionDigits={2} number={total} />
          </strong>
        </Text>
        <Flex
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
          mt="15px">
          <Text color="main.400" fontSize="14px" padding="15px 0">
            Payment method
          </Text>
          <HStack spacing={4}>
            <Tag
              bg="white"
              border="1px"
              borderColor={notEnoughBalance ? 'error' : 'secondary.200'}
              borderRadius="20px"
              padding="9px 25px"
              w="242px">
              <TagLeftIcon as={DefaultCard} boxSize="20px" />
              <TagLabel color="main.400" fontSize="14px" fontWeight="normal">
                Current balance: {balance}
              </TagLabel>
            </Tag>
          </HStack>
        </Flex>
        {notEnoughBalance && <AddFundsMessage onClose={onClose} />}
        <Center margin="25px 0 0">
          <Button
            isDisabled={notEnoughBalance}
            loadingText="Next"
            variant="primary"
            width="160px"
            onClick={handleConfirm}>
            Next
          </Button>
        </Center>
        <Center>
          <Button height="30px" m="5px 0" variant="cancel" onClick={onClose}>
            Cancel
          </Button>
        </Center>
      </Box>
    </Popup>
  );
};
