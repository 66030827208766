import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { isDevelopment } from '@app/utils/envUtils';

const bannedUrls = /(firestore.googleapis.com|clarity.ms)/g;

const initSentry = (): void => {
  if (!import.meta.env.VITE_SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    allowUrls: [/https?:\/\/((app|next)\.)?smartercontact\.com/],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ChunkLoadError',
      'Missing or insufficient permissions',
      'NotSupportedError',
      'Rage Click',
      'ApolloError',
      /Unexpected token '<'/,
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /chrome-extension:\//i,
      /^chrome:\/\//i,
    ],
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          'localhost',
          'smartercontact.com',
          'app.smartercontact.com',
          /^\//,
        ],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: isDevelopment ? 0 : 0.1,
    sampleRate: isDevelopment ? 0 : 0.25,
    beforeBreadcrumb(breadcrumb) {
      if (
        breadcrumb.category === 'fetch' &&
        bannedUrls.test(breadcrumb?.data?.url as string)
      ) {
        return null;
      }

      return breadcrumb;
    },
    // Sentry Replay Config
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const handleException = (error: Error): void => {
  Sentry.captureException(error);
};

export const handleErrorMessage = (errorMessage: string): void => {
  Sentry.captureMessage(errorMessage);
};

export default initSentry;
