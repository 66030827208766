import React, { FC } from 'react';
import { Box, Button, Center, Text } from '@chakra-ui/react';
import { ApolloError } from '@apollo/client';
import Popup from '@app/components/Popup';
import { useAccountPlansDetails } from '@app/hooks/useAccountPlansDetails';
import { formatInCents } from '@app/components/FormatNumber/utils';
import { RunSkiptraceMutationFn } from '@app/api/gql/generated-types';

interface ConfirmSkiptraceProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  contacts: number;
  contactListId: string;
  useCache: boolean;
  contactExcludeFirstRow: boolean;
  runSkiptraceMutation: RunSkiptraceMutationFn;
  openVerificationModal: () => void;
}

const ConfirmSkiptrace: FC<ConfirmSkiptraceProps> = ({
  isOpen,
  onClose,
  title,
  contacts,
  contactListId,
  useCache,
  contactExcludeFirstRow,
  runSkiptraceMutation,
  openVerificationModal,
}) => {
  const { skiptracePlan } = useAccountPlansDetails();

  const onSkiptraceStart = async () => {
    onClose();
    openVerificationModal();

    await runSkiptraceMutation({
      variables: {
        input: {
          useCache,
          id: contactListId,
          excludeFirstRow: contactExcludeFirstRow,
        },
      },
      context: {
        notify: {
          success: () => 'The data was successfully updated',
          error: (error: ApolloError[]) => error[0]?.message,
        },
      },
    });

    // TODO: after migrate add analytics
    // trackEvent({
    //   event: 'purchase',
    //   affiliation: 'Skiptrace',
    //   transaction_id: skiptraceOrder?.chargeId,
    //   value: (skiptraceOrder?.amount ?? 0) / 100,
    // });
  };

  return (
    <Popup isOpen={isOpen} title={title} width="450px" onClose={onClose}>
      <Box margin="35px 40px">
        <Text color="main.400" fontSize="14px" marginBottom="10px">
          Please confirm that you would like to Skip Trace{' '}
          <strong>{contacts}</strong> records for a total of{' '}
          <strong>$ {formatInCents(contacts * skiptracePlan)}</strong>
        </Text>
        <Text color="main.400" fontSize="14px" mt="25px">
          All skip trace requests are final upon submission. Requests are
          non-refundable and cannot be paused or canceled regardless of hit rate
          or user error. By proceeding, you agree to these terms.
        </Text>
        <Center margin="25px 0 15px">
          <Button variant="primary" width="160px" onClick={onSkiptraceStart}>
            Skiptrace now
          </Button>
        </Center>
        <Center>
          <Button height="30px" variant="cancel" onClick={onClose}>
            Cancel
          </Button>
        </Center>
      </Box>
    </Popup>
  );
};

export default ConfirmSkiptrace;
