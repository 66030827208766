import { HeaderGroup, ColumnInstance } from 'react-table';

import { stickyRowStyles } from './styles';

interface CustomHeaderGroup<T extends object> extends HeaderGroup<T> {
  sticky?: boolean;
}

interface CustomColumnInstance<T extends object> extends ColumnInstance<T> {
  sticky?: boolean;
}

export const getStickyProps = <T extends object>(
  column: CustomHeaderGroup<T> | CustomColumnInstance<T>,
) => (column.sticky ? stickyRowStyles : {});
