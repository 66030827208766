import React, { FC, useMemo } from 'react';
import { Flex, IconButton, Text } from '@chakra-ui/react';

import FilterArrowIcon from '@app/icons/filter-arrow-icon.svg?react';
import { DATE_FORMAT_LL_DD_YYYY, formatDate } from '@app/utils/formatDate';

import { colors } from '@app/theme/colors';
import { TriggerProps } from '../types';

export const Filter: FC<TriggerProps> = ({ isOpen, start, end }) => {
  const value = useMemo(
    () =>
      `${formatDate({
        date: start,
        format: DATE_FORMAT_LL_DD_YYYY,
      })} - ${formatDate({
        date: end,
        format: DATE_FORMAT_LL_DD_YYYY,
      })}`,
    [start, end],
  );

  const isShowValue = useMemo(() => !!start && !!end, [end, start]);

  return (
    <Flex
      alignItems="center"
      cursor="pointer"
      fontSize="14px"
      fontWeight={400}
      justifyContent="space-between"
      ml="15px"
      transition="all .3s ease">
      <Text as="span" color="osloGray" mr="2px">
        Date:
      </Text>
      <Text color="main.400">{isShowValue ? value : 'All Time'}</Text>
      <IconButton
        _active={{ bg: colors.transparent }}
        _focus={{ outline: 'none' }}
        _hover={{ bg: colors.transparent }}
        aria-label="filter by date"
        bg={colors.transparent}
        icon={<FilterArrowIcon />}
        ml="0px"
        mt="2px"
        size="xs"
        transform={isOpen ? 'rotate(270deg)' : 'rotate(90deg)'}
        transition="all .3s ease"
      />
    </Flex>
  );
};
