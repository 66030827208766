import { getLogger } from '@app/utils/logger';
import { OnDropProps } from '@app/types/DragDrop';

const LOG = getLogger('dragDropUtils');

export const onDropStatic = <T extends { id: string }>(
  list: T[],
  item: T,
): OnDropProps => {
  const id = item.id;
  const currentIndex = list?.findIndex((cell) => cell.id === id);

  const beforeIndex = currentIndex + 1;
  const beforeId = beforeIndex >= 0 ? list?.[beforeIndex]?.id : null;

  const afterIndex = currentIndex - 1;
  const afterId = afterIndex >= 0 ? list?.[afterIndex]?.id : null;

  LOG.debug('on drop static', { id, beforeId, afterId }, list, item);

  return {
    id,
    beforeId,
    afterId,
  } as OnDropProps;
};
