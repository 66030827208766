import { Button } from '@chakra-ui/react';
import React, { FC, useCallback, useState, MouseEvent } from 'react';
import { catchErrorLog } from '@app/utils/logger';

interface RecoverLinkButtonProps {
  id: string;
  recoverMutation: (ids: string[]) => Promise<void>;
}

export const RecoverLinkButton: FC<RecoverLinkButtonProps> = ({
  id,
  recoverMutation,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = useCallback(
    async (ids: string[], event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation();
      setIsLoading(true);
      try {
        await recoverMutation(ids);
      } catch (error) {
        catchErrorLog(error, 'RecoverLinkButton/handleClick');
      } finally {
        setIsLoading(false);
      }
    },
    [recoverMutation],
  );

  return (
    <Button
      _hover={{ bg: 'none', color: 'primary.600' }}
      fontWeight="500"
      isDisabled={isLoading}
      isLoading={isLoading}
      variant="text"
      onClick={(event) => handleClick([id], event)}>
      Recover
    </Button>
  );
};
