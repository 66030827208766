import { Call as TwilioCall, Device } from '@twilio/voice-sdk';
import { IncomingApplicationCall, InfobipRTC } from 'infobip-rtc';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { Contact, MessagingProviderType } from '@app/api/gql/generated-types';

export enum CallType {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export interface Call {
  fullName: string;
  phoneNumber: string;
  outgoingPhoneNumber?: string;
  contactId: Contact['id'];
  type: CallType;
  externalId?: string;
  callId?: string;
}

export enum DialerStatus {
  IDLE = 'IDLE',
  CALLING = 'CALLING',
  ONCALL = 'ONCALL',
}

export interface DialerContextValues {
  isMicMuted: boolean;
  isLoggedIn: boolean;
  status: DialerStatus;
  currentCall?: Call | null;
  toggleMic: () => void;
  isRecording: boolean;
  toggleRecording: () => void;
  stopRecording: () => void;
  setCurrentCallDetails: Dispatch<SetStateAction<Call>>;
  setDialerStatus: (status: DialerStatus) => void;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  sendDTMF?: (digit: string) => void;
  twilioInstance: MutableRefObject<Device>;
  twilioCallInstance: MutableRefObject<TwilioCall | null>;
  infobipInstance: MutableRefObject<InfobipRTC & { token?: string }>;
  infobipCallInstance: MutableRefObject<IncomingApplicationCall>;
  providerType: MessagingProviderType;
}

export type DialerProps = Pick<
  DialerContextValues,
  'toggleMic' | 'isMicMuted' | 'currentCall' | 'status' | 'sendDTMF'
> & {
  isLoggedIn: boolean;
  makeCall: (callInfo: Call) => void;
  hangupCall: () => void;
  rejectCall: () => void;
  answerCall: () => void;
};
