import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { colors } from '@app/theme/colors';

const TableWrapper = styled(Box)`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.main[400]};
  overflow-y: auto;
  position: relative;
  height: ${(props) => props.height};

  table {
    tr {
      height: 50px;
    }
    th {
      white-space: nowrap;
      background: ${(props) => props.background};
      color: ${colors.silver};
      text-align: left;
    }
  }
`;

export { TableWrapper };
