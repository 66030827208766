import { Text } from '@chakra-ui/react';
import React from 'react';

import { colors } from '@app/theme/colors';
import { WysiwygAction } from './types';

export const wysiwygActions: WysiwygAction[] = [
  {
    type: 'mark',
    label: 'Bold',
    command: 'toggleBoldMark',
    content: (
      <Text as="strong" color={colors.notFound[5900]} fontSize="13px">
        B
      </Text>
    ),
  },
  {
    type: 'mark',
    label: 'Italic',
    command: 'toggleItalicMark',
    content: (
      <Text
        as="em"
        color={colors.notFound[5900]}
        fontSize="13px"
        fontWeight="400">
        i
      </Text>
    ),
  },
  {
    type: 'mark',
    label: 'Underline',
    command: 'toggleUnderlineMark',
    content: (
      <Text
        as="u"
        color={colors.notFound[5900]}
        fontSize="13px"
        fontWeight="400">
        U
      </Text>
    ),
  },
  {
    type: 'mark',
    label: 'Strikethrough',
    command: 'toggleStrikethroughMark',
    content: (
      <Text
        as="s"
        color={colors.notFound[5900]}
        fontSize="13px"
        fontWeight="400">
        abc
      </Text>
    ),
  },
  {
    type: 'align',
    icon: 'align-left-icon.svg?react',
    label: 'Align left',
    command: 'alignLeft',
  },
  {
    type: 'align',
    icon: 'align-center-icon.svg?react',
    label: 'Align center',
    command: 'alignCenter',
  },
  {
    type: 'align',
    icon: 'align-right-icon.svg?react',
    label: 'Align right',
    command: 'alignRight',
  },
];
