import React, { FC, useCallback } from 'react';
import { Button, Box, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import throttle from 'lodash/throttle';

import { ApolloError } from '@apollo/client';
import { useTracking } from 'react-tracking';
import Popup from '@app/components/Popup';
import Label from '@app/components/FormItem/Label';
import { rechargeSchema } from '@app/schemas/recharge-schema';

import {
  AccountDocument,
  useTopUpBalanceMutation,
} from '@app/api/gql/generated-types';
import { CUSTOM_THRESHOLD } from '@app/constants/configuration';
import { colors } from '@app/theme/colors';
import { LackPhoneNumbersWarning } from '../next/moleculas/LackPhoneNumbersWarning/LackPhoneNumbersWarning';
import ChargeSelect from './ChargeSelect';
import ChargeCustom from './ChargeCustom';
import { ChargeFormProps, ChargeModalProps } from './types';
import { TrialWarning } from './TrialWarning';

const initialValues: ChargeFormProps = {
  amount: 0,
  custom: false,
};

export const ChargeModal: FC<ChargeModalProps> = ({
  isOpen,
  onClose,
  title,
  onEnableAutoRechargeClick,
  message,
  disableAction,
  isWarning = false,
  onRechargeSuccess,
}) => {
  const { trackEvent } = useTracking();
  const [mutate, { loading: isLoading }] = useTopUpBalanceMutation({
    refetchQueries: [AccountDocument],
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,
    context: {
      notify: {
        error: (error: ApolloError[]) => error[0].message,
      },
    },
  });

  const trackRechargeEvent = (id: string, value: number) => {
    trackEvent({
      event: 'purchase',
      affiliation: 'Recharge',
      transaction_id: id,
      value,
    });
  };

  const handler = async ({ amount }: ChargeFormProps) => {
    const converted = amount * 100;
    try {
      await mutate({
        variables: {
          input: {
            amount: converted,
          },
        },
      });
      onRechargeSuccess();
      //TODO add trackRechargeEvent when backend will be ready
      // trackRechargeEvent(id, amount);
    } catch {
      trackRechargeEvent(undefined, amount);
    }
    onClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = useCallback(throttle(handler, 1000), []);
  let width = '380px';
  if (isWarning) {
    width = '435px';
  }
  return (
    <Popup
      blockScrollOnMount
      closeOnOverlayClick
      isOpen={isOpen}
      maxW={width}
      modalOverplayProps={{ backdropFilter: 'blur(10px)' }}
      title={title}
      onClose={onClose}>
      <Box margin="20px 40px">
        {onEnableAutoRechargeClick && !isWarning && (
          <Text
            fontSize="14px"
            mb="20px"
            style={{ whiteSpace: 'pre-line' }}
            textAlign="center">
            How many credits would you like to add {'\n'} to your balance? To
            receive free bonus credits,{' '}
            <Text
              color={colors.primary[600]}
              fontSize="14px"
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={onEnableAutoRechargeClick}>
              enable auto recharge now.
            </Text>
          </Text>
        )}
        {message && isWarning && (
          <LackPhoneNumbersWarning
            rounded
            backgroundColor="paleGamboge"
            customMessage={
              <Text
                color="main.400"
                style={{ whiteSpace: 'pre-line' }}
                variant="secondary-text">
                {message}
              </Text>
            }
            style={{
              cursor: 'pointer',
            }}
          />
        )}
        {message && !isWarning && (
          <Text
            color="main.400"
            style={{ whiteSpace: 'pre-line' }}
            variant="secondary-text">
            {message}
          </Text>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={rechargeSchema}
          onSubmit={onSubmit}>
          {({ values, dirty }) => (
            <Form>
              <Label>Select amount</Label>
              <ChargeSelect isDisabled={disableAction} />
              {values.custom && (
                <>
                  <Label>Add custom amount</Label>
                  <ChargeCustom isDisabled={disableAction} />
                </>
              )}

              <TrialWarning onClose={onClose} />
              {onEnableAutoRechargeClick && isWarning && !disableAction && (
                <Text display="flex" fontSize="14px" mb="20px" textAlign="left">
                  To receive free bonus credits,&nbsp;
                  <Text
                    color={colors.primary[600]}
                    fontSize="14px"
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={onEnableAutoRechargeClick}>
                    enable auto recharge now.
                  </Text>
                </Text>
              )}

              <Button
                isDisabled={
                  !dirty || values.amount < CUSTOM_THRESHOLD || disableAction
                }
                isLoading={isLoading}
                type="submit"
                variant="primary"
                width="100%">
                Confirm
              </Button>
              <Button variant="cancel" width="100%" onClick={onClose}>
                Cancel
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Popup>
  );
};
