import Cookies from 'js-cookie';
import { isEmpty } from 'lodash/fp';
import { getLogger } from '@app/utils/logger';
import { getEnvRootDomain, isLocalhost } from '@app/utils/envUtils';
import ROUTES from '@app/utils/routes';

const LOG = getLogger('utils/cookieStorageUtils');

/**
 * The default time-to-live (TTL) value, in days, for a cookie's value.
 *
 * @type {number}
 * @constant
 */
export const DEFAULT_COOKIE_VALUE_TTL: number = 365;
export const COOKIE_DOMAIN: string = `.${getEnvRootDomain()}`;

/**
 * Default options for cookies.
 *
 * @type {Cookies.CookieAttributes}
 * @property {boolean} secure - Specifies if the cookie should only be sent over HTTPS.
 * @property {string} domain - The domain for which the cookie is valid.
 * @property {number} expires - The number of milliseconds until the cookie expires.
 */
const DEFAULT_COOKIE_OPTIONS: Cookies.CookieAttributes = {
  secure: !isLocalhost,
  domain: COOKIE_DOMAIN,
  expires: DEFAULT_COOKIE_VALUE_TTL,
  path: ROUTES.root,
};

/**
 * Sets a cookie with the given name, value and options.
 *
 * @param {string} name - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {Cookies.CookieAttributes} options - The options for the cookie.
 */
export const setCookie = (
  name: string,
  value: string,
  options?: Cookies.CookieAttributes,
) => {
  const opts = {
    ...DEFAULT_COOKIE_OPTIONS,
    ...options,
  };
  Cookies.set(name, value, opts);
  LOG.debug(`Set cookie: ${name}=${value}`, opts);
};

/**
 * Retrieves the value of a specific cookie parameter by name.
 *
 * @param {string} paramName - The name of the cookie parameter to retrieve.
 * @returns {?string} The value of the cookie parameter, or null if it does not exist.
 */
export const getCookieParam = (paramName: string): string | null => {
  const cookieValue = Cookies.get(paramName);
  const paramValue = isEmpty(cookieValue) ? null : cookieValue;

  LOG.debug(`Retrieved cookie value: ${paramName}=${paramValue}`);

  return paramValue;
};

/**
 * Removes a cookie with the specified name.
 *
 * @param {string} name - The name of the cookie to remove.
 * @returns {void}
 */
export const removeCookieParam = (name: string): void => {
  Cookies.remove(name, DEFAULT_COOKIE_OPTIONS);
  LOG.debug(`Removed cookie: ${name}`);
};
