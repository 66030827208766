import React, { FC } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  UseDisclosureProps,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { useBrandRegistrationRoute } from '@app/hooks/useBrandRegistrationRoute';
import Popup from '../Popup';

/**
 *  @deprecated Use the new permission Features & permission custom modal.
 * */
export const UpdateBrandModal: FC<Partial<UseDisclosureProps>> = ({
  isOpen,
  onClose,
}) => {
  const { route } = useBrandRegistrationRoute();
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="566px"
      title="Please upgrade to 10DLC Standard brand"
      onClose={onClose}>
      <Box p="20px 40px 40px">
        <Box
          borderRadius="12px"
          m="0 0 25px"
          overflow="hidden"
          position="relative">
          <Image height="270px" src="/modals/10dlc-update-account.png" />
        </Box>
        <Text
          color="main.400"
          mb="20px"
          textAlign="center"
          variant="list-style">
          We are requesting all our customers to upgrade to 10DLC Standard
          brand. All your campaigns and messages will be on pause until your
          brand is updated.
        </Text>
        <Flex justifyContent="center" mt="25px">
          <Button
            as={Link}
            to={route}
            variant="primary"
            w="197px"
            onClick={onClose}>
            Upgrade
          </Button>
        </Flex>
      </Box>
    </Popup>
  );
};
