import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import { CallScript } from '@app/api/gql/generated-types';
import { isSubmitDisabled } from '@app/utils/validation';

export const FormSubmit: FC = () => {
  const formik = useFormikContext<CallScript>();

  const { handleSubmit, isSubmitting, values } = formik;

  const isDisabled = isSubmitDisabled(formik, !!formik?.values?.id, []);

  return (
    <Button
      borderRadius="20px"
      data-cy="save-script-button"
      isDisabled={isDisabled || isSubmitting || values?.content === ' '}
      isLoading={isSubmitting}
      ml="237px"
      mt="20px"
      variant="primary"
      width="218px"
      onClick={() => handleSubmit()}>
      Save script
    </Button>
  );
};
