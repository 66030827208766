import { Flex, Text, Stack, Button } from '@chakra-ui/react';
import React, { FC } from 'react';

import Popup from '@app/components/Popup';

interface LessContactsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LessContactsModal: FC<LessContactsModalProps> = ({ isOpen, onClose }) => {
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="370px"
      size="3xl"
      title="Notification"
      onClose={onClose}>
      <Flex alignItems="center" direction="column">
        <Text
          color="main.400"
          fontSize="14px"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="18px"
          mt="25px"
          textAlign="center"
          width="270px">
          You should have a minimum of 5 contacts in the list
        </Text>
        <Stack direction="row" mt="30px" spacing={4}>
          <Button mb="40px" variant="primary" width="83px" onClick={onClose}>
            Confirm
          </Button>
        </Stack>
      </Flex>
    </Popup>
  );
};

export default LessContactsModal;
