import React, { FC, useMemo, useRef, useState } from 'react';
import {
  Box,
  ButtonGroup,
  Center,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Column } from 'react-table';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import { DateTime } from 'luxon';

import {
  MarketingPopup,
  MarketingPopupStatus,
  useFindMarketingPopupsQuery,
} from '@app/api/gql/generated-types';
import TableList from '@app/components/Table';
import ROUTES, { generateUrl } from '@app/utils/routes';
import { TableSkeleton } from '@app/components/Table/Skeleton';
import IconButtonRemove from '@app/components/IconButtonRemove';
import IconButtonEdit from '@app/components/IconButtonEdit';
import EmptyTable from '@app/components/EmptyTable';
import { createPaginationVariables } from '@app/api/apollo-pagination';
import LoadMoreButton from '@app/components/LoadMoreButton';

import {
  DATE_FORMAT_LL_DD_YYYY,
  TIME_FORMAT_HH_MM_A,
} from '@app/utils/formatDate';
import { colors } from '@app/theme/colors';
import { RemoveMarketingPopup } from '../RemoveMarketingPopup';
import { OverflownText } from '../OverflownText';

const cleanText = /<\/?[^>]+(>|$)/g;

const PopupsTable: FC = () => {
  const navigate = useNavigate();

  const removeConfirmModal = useDisclosure();
  const tableRef = useRef<HTMLTableElement>(null);
  const [selectedPopup, setSelectedPopup] = useState<MarketingPopup>(null);

  const {
    data: { findMarketingPopups: popupsData } = {},
    loading: isLoading,
    fetchMore,
  } = useFindMarketingPopupsQuery({ notifyOnNetworkStatusChange: true });

  const items = useMemo(() => popupsData?.items ?? [], [popupsData?.items]);

  const hasNextPage = popupsData?.hasNext ?? false;

  const fetchNextPage = () => fetchMore(createPaginationVariables(popupsData));

  const columns: Column<MarketingPopup>[] = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: (cell) => {
          return (
            <OverflownText color="main.400" title="Popup title" width="160px">
              {cell?.row?.original?.title || '-'}
            </OverflownText>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (cell) => (
          <OverflownText
            color="main.400"
            title="Popup description"
            width="160px">
            {cell?.row?.original?.description.replace(cleanText, '') || '-'}
          </OverflownText>
        ),
      },
      {
        Header: 'Type of users',
        accessor: 'audiences',
        Cell: (cell) => (
          <Text
            maxWidth="130px"
            overflow="hidden"
            textOverflow="ellipsis"
            textTransform="capitalize"
            whiteSpace="nowrap"
            width="100px">
            {capitalize(cell?.row?.original?.audiences.join('; ')) || '-'}
          </Text>
        ),
      },
      {
        Header: 'Popup Status',
        accessor: 'status',
        Cell: (cell) => (
          <Flex
            alignItems="center"
            color="main.400"
            flexDirection="row"
            minW="100px"
            textTransform="capitalize"
            whiteSpace="nowrap">
            <Box
              background={
                cell.row.original.status === MarketingPopupStatus.ACTIVE
                  ? 'mediumAquamarine'
                  : 'secondary.200'
              }
              borderRadius="50%"
              h="11px"
              mr="8px"
              w="11px"
            />
            {cell.row.original.status.toLowerCase() || '-'}
          </Flex>
        ),
      },
      {
        Header: 'Start Date',
        accessor: 'startDate',
        Cell: (cell) => (
          <Flex direction="column" fontSize="12px" maxW="70px" w="fit-content">
            {DateTime.fromISO(cell.row.original.startDate)
              .setZone('utc')
              .toFormat(DATE_FORMAT_LL_DD_YYYY)}

            <Text color="secondary.400" lineHeight="14px" textAlign="center">
              {DateTime.fromISO(cell.row.original.startDate)
                .setZone('utc')
                .toFormat(TIME_FORMAT_HH_MM_A)}
            </Text>
          </Flex>
        ),
      },
      {
        Header: 'End date',
        accessor: 'endDate',
        Cell: (cell) => (
          <Flex direction="column" fontSize="12px" maxW="70px" w="fit-content">
            {DateTime.fromISO(cell.row.original.endDate)
              .setZone('utc')
              .toFormat(DATE_FORMAT_LL_DD_YYYY)}

            <Text color="secondary.400" lineHeight="14px" textAlign="center">
              {DateTime.fromISO(cell.row.original.endDate)
                .setZone('utc')
                .toFormat(TIME_FORMAT_HH_MM_A)}
            </Text>
          </Flex>
        ),
      },
      {
        Header: 'Actions',
        id: 'action',
        accessor: 'id',
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Cell: (cell) => (
          <ButtonGroup alignItems="center">
            <IconButtonEdit
              onClick={() =>
                navigate(
                  generateUrl(ROUTES.popupConstructorEdit, {
                    id: cell.row?.original?.id,
                  }),
                )
              }
            />
            <IconButtonRemove
              onClick={() => {
                setSelectedPopup(cell.row.original);
                removeConfirmModal.onOpen();
              }}
            />
          </ButtonGroup>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeConfirmModal],
  );

  return (
    <Box
      bg={colors.transparent}
      borderTop={`2px solid ${colors.mystic}`}
      height="100%"
      overflowY="scroll"
      pb="100px"
      transform="translateZ(0)">
      {isLoading && isEmpty(items) ? (
        <TableSkeleton />
      ) : (
        <TableList<MarketingPopup>
          isInfiniteList
          isStickyHead
          columns={columns}
          data={items}
          isLoadingNextPage={isLoading}
          tableHeadBackground="white"
          tableRef={tableRef}
        />
      )}

      {!isEmpty(items) && (
        <Center mb="10px">
          <LoadMoreButton
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isLoading}
            isLoading={isLoading}
          />
        </Center>
      )}

      {!isLoading && isEmpty(items) && (
        <Box height="300px">
          <EmptyTable imageMargin="0 0 15px 0" />
        </Box>
      )}

      <RemoveMarketingPopup
        entity={selectedPopup}
        removeConfirmModal={removeConfirmModal}
      />
    </Box>
  );
};

export default PopupsTable;
