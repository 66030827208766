/**
 * Represents the default page size for pagination.
 * @type {Readonly<number>}
 */
export const DEFAULT_PAGE_SIZE: Readonly<number> = 25;

/**
 * Represents the custom threshold amount in dollars.
 * @type {Readonly<number>}
 */
export const CUSTOM_THRESHOLD: Readonly<number> = 100;

/**
 * Represents the custom threshold amount in dollars for trial accounts.
 * @type {Readonly<number>}
 */
export const TRAIL_THRESHOLD: Readonly<number> = 50;

/**
 * Represents the minimum length of input for the search field across various pages (messenger, contacts, groups, campaigns).
 * @type {Readonly<number>}
 */
export const MIN_LENGTH_INPUT_FULL_SEARCH: Readonly<number> = 3;
export const MIN_LENGTH_INPUT_SHORT_FULL_SEARCH: Readonly<number> = 2;

/**
 * Represents the query parameter key for the affiliate ID.
 * @type {Readonly<string>}
 */
export const AFFILIATE_ID_QUERY_PARAM: Readonly<string> = 'fpr';

/**
 * Represents the parameter name used for the affiliate ID cookie.
 * @type {Readonly<string>}
 */
export const AFFILIATE_ID_COOKIE_PARAM: Readonly<string> = '__refer';

/**
 * Represents the query parameter name for the promo code.
 * @type {Readonly<string>}
 * @default 'promo'
 */
export const PROMO_CODE_QUERY_PARAM: Readonly<string> = 'promo';

/**
 * Represents the parameter in local storage used to store the promo code.
 * @type {Readonly<string>}
 */
export const PROMO_CODE_LOCALSTORAGE_PARAM: Readonly<string> = 'promoCode';

/**
 * Represents the placeholder string used for empty values.
 * @type {string}
 */
export const EMPTY_VALUE_PLACEHOLDER: Readonly<string> = '-';

/**
 * Key used to retrieve the promo code from query parameters.
 * @type {Readonly<string>}
 */
export const PROMO_CODE_KEY: Readonly<string> = 'code';
/**
 * Represents the maximum counter for unread messages.
 * @type {Readonly<number>}
 */
export const MAX_COUNTER_UNREAD_MESSAGES: Readonly<number> = 999;

/**
 * Represents the long debounce time in milliseconds.
 * @type {Readonly<number>}
 */
export const LONG_DEBOUNCE_TIME: Readonly<number> = 500;

/**
 * Represents the search debounce time in milliseconds.
 * @type {Readonly<number>}
 */
export const STANDARD_SEARCH_DEBOUNCE_DELAY: Readonly<number> = 1000;

/**
 * Represents the default width for the contact's slide wrapper.
 * @type {Readonly<number>}
 */
export const DEFAULT_WIDTH_CONTACTS_SLIDE_WRAPPER: Readonly<number> = 450;

/**
 * Represents the minimum width for the messenger slide wrapper.
 * @type {Readonly<number>}
 */
export const DEFAULT_MIN_WIDTH_MESSENGER_SLIDE_WRAPPER: Readonly<number> = 398;

/**
 * Represents the maximum width for the messenger slide wrapper.
 * @type {Readonly<number>}
 */
export const DEFAULT_MAX_WIDTH_MESSENGER_SLIDE_WRAPPER: Readonly<number> = 681;

/**
 * Represents the animation timeout for the slide wrapper in milliseconds.
 * @type {Readonly<number>}
 */
export const DEFAULT_SLIDE_WRAPPER_ANIMATION_TIMEOUT: Readonly<number> = 200;

/**
 * Represents the max size of uploading file in Bytes.
 * @type {Readonly<number>}
 */
export const MAX_UPLOAD_FILE_SIZE: Readonly<number> = 300000;

export const MIN_PASSWORD_LENGTH: Readonly<number> = 8;

export const STANDARD_FRACTION_DIGITS: Readonly<number> = 2;

export const STANDARD_LOCALE: Readonly<string> = 'en-US';
export const PHONE_VERIFICATION_CODE_LENGTH: Readonly<number> = 6;
