import { Skeleton } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

export interface SkeletonCellArgs {
  isLoading: boolean;
  children: ReactNode;
}

export const SkeletonCell: FC<SkeletonCellArgs> = ({
  isLoading,
  children,
}: SkeletonCellArgs) => {
  if (isLoading) {
    return <Skeleton height="15px" />;
  }

  return <>{children}</>;
};
