import { UseDisclosureProps } from '@chakra-ui/react';

export enum DateRangeType {
  SELECT = 'select',
  FILTER = 'filter',
}

export interface TriggerProps extends UseDisclosureProps {
  start?: Date;
  end?: Date;
}
