import React, { FC, ReactNode } from 'react';
import { Box, Text, UseDisclosureProps } from '@chakra-ui/react';

import Popup from '@app/components/Popup';

interface Props {
  title?: string;
  description?: string | ReactNode;
  isOpen: UseDisclosureProps['isOpen'];
  onClose: UseDisclosureProps['onClose'];
  buttons?: ReactNode;
}

export const ConfirmModal: FC<Props> = ({
  title,
  description,
  isOpen,
  onClose,
  buttons,
}) => {
  return (
    <Popup
      closeOnOverlayClick
      hideCloseIcon={false}
      isOpen={isOpen}
      maxW="374px"
      title={title}
      onClose={onClose}>
      <Box margin="0 auto" p="20px" w="300px">
        <Text
          color="main.400"
          fontSize="14px"
          fontWeight={400}
          mb="20px"
          textAlign="center"
          whiteSpace="pre">
          {description}
        </Text>

        {buttons}
      </Box>
    </Popup>
  );
};
